import * as React from 'react'

import { Theme, Modal, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { DefaultButton } from '.'

import { constants } from 'assets'

interface Props {
  children?: React.ReactNode
  open: boolean
  setOpen: (next: boolean) => void
  align?: 'center' | 'left'
}

export const DefaultModal: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { children, open, setOpen, align } = props

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={classes.modalBackdrop}>
          <div
            className={classes.modalCard}
            data-testid="modalCard"
            style={{
              textAlign: align || 'center',
            }}
          >
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    margin: '0 16px',
  },

  modalIcon: {
    width: 34,
    height: 34,
    marginBottom: 30,
  },

  modalBackdrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    outline: 'none',
  },

  modalCard: {
    backgroundColor: constants.COLOR_WHITE,
    padding: 30,
    width: '100%',
    maxWidth: 760,
    borderRadius: 8,
  },
}))

interface DefaultErrorModalProps {
  children?: React.ReactNode
  open: boolean
  setOpen: (next: boolean) => void
  iconColor?: 'primary' | 'danger'
}

export const DefaultErrorModal: React.FC<DefaultErrorModalProps> = (props) => {
  const classes = useStylesError()
  const { children, open, setOpen, iconColor = 'primary' } = props

  return (
    <DefaultModal open={open} setOpen={setOpen}>
      <>
        <img
          className={classes.modalIcon}
          src={process.env.PUBLIC_URL + `/assets/svg/teams/cautionIcon${iconColor === 'primary' ? '' : '-danger'}.svg`}
          alt={'caution'}
        />
        {children}
      </>
    </DefaultModal>
  )
}

const useStylesError = makeStyles((theme: Theme) => ({
  modalIcon: {
    width: 34,
    height: 34,
    marginBottom: 8,
  },
}))

interface HooksErrorModalProps {
  open: boolean
  setOpen: (value: boolean) => void
  errors: string[]
}

export const HooksErrorModal: React.FC<HooksErrorModalProps> = (props) => {
  const classes = useStylesHooksError()
  const { open, setOpen, errors } = props

  const onClose = () => {
    setOpen(false)
  }

  return (
    <DefaultErrorModal open={open} setOpen={setOpen}>
      <div className={classes.modalDescription}>エラーが発生しました</div>

      <div className={errors.length > 1 ? classes.modalScrollView : classes.modalView}>
        {errors.map((d, index) => (
          <div key={`error-team-modal-item-${index}`} className={classes.modalDescription}>
            {d}
          </div>
        ))}
      </div>

      <DefaultButton title={'了解'} color={'team_main'} onClick={onClose} />
    </DefaultErrorModal>
  )
}

const useStylesHooksError = makeStyles((theme: Theme) => ({
  modalDescription: {
    fontSize: 16,
    marginBottom: 20,
  },

  modalView: {
    //
  },

  modalScrollView: {
    height: 100,
    overflow: 'scroll',
    border: '1px solid #e3e3e3',
    marginBottom: 20,
    textAlign: 'left',
    padding: '0px 20px',
  },
}))
