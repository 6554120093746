import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { queryGetMasters } from 'pages/teams/hooks/graphql'
import { MasterType, TeamBuilding, TeamBuildingMember, TeamBuildingStatus } from 'utils/generated'

import {
  InputCard,
  QuestionContainer,
  Button,
  FormLabel,
  ButtonContainer,
  WaitingForLeader,
  Ranking,
} from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q2Result02: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q2End,
      timeQ2End: new Date().toISOString(),
    })
  }

  React.useEffect(() => {
    if (teamBuilding && teamBuildingMembers) {
      if (!teamBuilding.resultQ2 && teamBuildingMembers.length > 0) {
        extractResult(teamBuildingMembers).then((r) =>
          updateTeamBuilding({ id: props.match.params.teamId, resultQ2: JSON.stringify(r) })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding, teamBuildingMembers])

  return (
    <QuestionContainer active={2.5} questionHeadBar progressDotsProps={{ maxLength: 6, currentProgress: 6 }}>
      <div className={!isLeader ? classes.rootWaitingControl : undefined}>
        <div className={classes.cardContainer}>
          <InputCard noRadius>
            <FormLabel>結果発表</FormLabel>
            <Ranking items={teamBuilding && teamBuilding.resultQ2 ? JSON.parse(teamBuilding.resultQ2) : []} />
          </InputCard>
        </div>

        {/* Disable ordered by kiriyama */}
        {/* {isLeader && <EditRanking />} */}
        {isLeader ? (
          <ButtonContainer
            fixed
            buttons={[
              <Button key="q2-result-2" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        ) : (
          <WaitingForLeader wrapperBottom={10} />
        )}
      </div>
    </QuestionContainer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rootWaitingControl: {
    width: '100%',
    minHeight: 'calc(100vh - 174px)', // -150px is Container padding. 24px is progressDots.
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 166px)', // -128px is Container padding. -72px is header. 16px is progressDots. 50px is bottom adjustment.
      marginBottom: -50,
    },
  },

  cardContainer: {
    width: '100%',
    marginBottom: 20,
  },

  inputContainer: {
    marginBottom: 16,
  },
}))

const extractResult = async (members: TeamBuildingMember[]) => {
  const justArray: { id: string; answer: string; count: number }[] = []

  // マスターの分を配列に追加
  const response = await queryGetMasters({ type: MasterType.TeamBuildingQ2Keyword, limit: 100 })
  if (response) {
    for (const m of response.items) {
      if (m.title) {
        justArray.push({ id: m.id, answer: m.title, count: 0 })
      }
    }
  }

  // 回答の分を配列に追加
  for (const m of members) {
    if (m.resultQ2) {
      const resultQ2 = JSON.parse(m.resultQ2) as { label: string; name: string }[]
      resultQ2.forEach((r) => justArray.push({ id: r.name, answer: r.label, count: 1 }))
    }
  }

  // group by
  const groupBy = justArray.reduce(
    (res: { id: string; answer: string; count: number }[], current: { id: string; answer: string; count: number }) => {
      const element = res.find((p) => {
        return p.id === current.id
      })
      if (element) {
        element.count += current.count
      } else {
        res.push({
          id: current.id,
          answer: current.answer,
          count: current.count,
        })
      }
      return res
    },
    []
  )

  return groupBy.sort(function (a, b) {
    return a.count < b.count ? 1 : -1
  })
}
