import React, { useEffect, useState, useContext } from 'react'

import Pagination from '@mui/material/Pagination'
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns'

import { SlideArrow } from 'pages/onboarding/components'
import { SearchBar } from 'pages/onboarding/components/search-bar'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { OnboardingPost } from 'utils/generated'

import * as constants from '../../../../assets/constants'
import { useOverView, MemberOverview, SupporterOverview } from '../../hooks/overView'
import { OnbContext } from '../PagesRoot'

import descriptionArrow from './assets/description.svg'
import downArrow from './assets/downArrow.svg'
import NoAvatarSvg from './assets/noAvatar.svg'
import upArrow from './assets/upArrow.svg'

export const OverviewPage: React.FC = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const [isSelectedMemberTab, SetIsSelectedMemberTab] = React.useState(true)
  const [isHovered, setIsHoverd] = React.useState<string>()

  const toggleMember = () => {
    SetIsSelectedMemberTab(true)
  }
  const toggleTrainer = () => {
    SetIsSelectedMemberTab(false)
  }

  const memberContainerId = 'memberContainerId'
  const supporterContainerId = 'supporterContainerId'

  const { teamId } = useContext(OnbContext)
  const { teamMembers } = useTeamMembers(teamId)
  const { loading, memberInfosContainer, supportersInfosContainer } = useOverView(teamId, teamMembers)

  const toJapanTime = (time: string) => {
    const IOS = format(new Date(time), 'yyyy.MM.dd')
    return IOS
  }

  const MemberInfos = memberInfosContainer.map((item: MemberOverview) => ({
    id: item.id,
    imageUrl: item.imageUrl ?? undefined,
    name: item.name,
    allAction: item.allAction,
    doneAction: item.doneAction,
    allDaily: item.allDaily,
    badCondition: item.badCondition,
    post: item.post,
    good: item.good,
    commented: item.commented ?? 0,
    lastVisit: item.lastVisit ? toJapanTime(item.lastVisit) : 'データなし',
  }))

  const [keyword, setKeyword] = useState('')
  const [searchKeywords, setSearchKeywords] = useState<string[]>([])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (keyword === '') {
        setSearchKeywords([])
        return
      }

      const searchKeywords = keyword
        .trim()
        .toLowerCase()
        .match(/[^\s]+/g)

      //入力されたキーワードが空白のみの場合
      if (searchKeywords === null) {
        setSearchKeywords([])
        return
      }
      setSearchKeywords(searchKeywords)
    }, 500)
    return () => clearTimeout(timer)
  }, [keyword])

  const filteredProducts = MemberInfos.filter(({ name }) =>
    searchKeywords.every((kw) => name?.toLowerCase().indexOf(kw) !== -1)
  )

  const [sortStateMember, setSortStateMember] = React.useState<{
    key: 'name' | 'doneAction' | 'badCondition' | 'post' | 'commented' | 'good' | 'lastVisit'
    sort: 'up' | 'down'
  } | null>(null)

  type MemberInfo = {
    id: string
    name: string
    allAction: number
    doneAction: number
    badCondition: number
    post: number
    good: number
    commented: number
    lastVisit: number | string
  }

  const sortFuncsMember = {
    name: {
      up: (a: MemberInfo, b: MemberInfo) => (a.name > b.name ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.name > b.name ? -1 : 1),
    },
    doneAction: {
      up: (a: MemberInfo, b: MemberInfo) => (a.doneAction > b.doneAction ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.doneAction > b.doneAction ? -1 : 1),
    },
    badCondition: {
      up: (a: MemberInfo, b: MemberInfo) => (a.badCondition > b.badCondition ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.badCondition > b.badCondition ? -1 : 1),
    },
    post: {
      up: (a: MemberInfo, b: MemberInfo) => (a.post > b.post ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.post > b.post ? -1 : 1),
    },
    good: {
      up: (a: MemberInfo, b: MemberInfo) => (a.good > b.good ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.good > b.good ? -1 : 1),
    },
    commented: {
      up: (a: MemberInfo, b: MemberInfo) => (a.commented > b.commented ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.commented > b.commented ? -1 : 1),
    },
    lastVisit: {
      up: (a: MemberInfo, b: MemberInfo) => (a.lastVisit > b.lastVisit ? 1 : -1),
      down: (a: MemberInfo, b: MemberInfo) => (a.lastVisit > b.lastVisit ? -1 : 1),
    },
  }
  const sortedInfosMember =
    sortStateMember === null
      ? filteredProducts
      : filteredProducts.sort(sortFuncsMember[sortStateMember.key][sortStateMember.sort])

  // ページネーション系(メンバー)
  const [pageForMember, setPageMember] = useState(1) //ページ番号
  const [pageCountMember, setPageCountMember] = useState<number>() //ページ数
  const displayNum = isSmUp ? 1000 : 10 //1ページあたりの項目数
  const displayedItemsMember = sortedInfosMember.slice((pageForMember - 1) * displayNum, pageForMember * displayNum)
  useEffect(() => {
    const pagenatetionMaxLengthForMember = Math.ceil(MemberInfos.length / displayNum)
    setPageCountMember(pagenatetionMaxLengthForMember)
  }, [MemberInfos.length, displayNum])

  const handleChangeForMember = (_: any, index: number) => {
    //ページ移動時にページ番号を更新
    setKeyword(keyword)
    setPageMember(index)
  }

  const TrainerInfos = supportersInfosContainer.map((item: SupporterOverview) => {
    return {
      id: item.id,
      imageUrl: item.imageUrl ?? undefined,
      name: item.name,
      good: item.good,
      commented: item.commentIng, //コメントした数
      lastVisit: item.lastVisit.slice(0, 10).replace(/-/g, '.') ?? 'データなし',
    };
  })

  const filteredProductsForTrainer = TrainerInfos.filter(({ name }) =>
    searchKeywords.every((kw) => name.toLowerCase().indexOf(kw) !== -1)
  )

  const [sortStateTrainer, setSortStateTrainer] = React.useState<{
    key: 'name' | 'good' | 'lastVisit' | 'commented'
    sort: 'up' | 'down'
  } | null>(null)

  type TrainerInfo = {
    name: string
    good: number
    lastVisit: string
    commented: number | string | OnboardingPost[]
  }

  const sortFuncsTrainer = {
    name: {
      up: (a: TrainerInfo, b: TrainerInfo) => (a.name > b.name ? 1 : -1),
      down: (a: TrainerInfo, b: TrainerInfo) => (a.name > b.name ? -1 : 1),
    },
    good: {
      up: (a: TrainerInfo, b: TrainerInfo) => (a.good > b.good ? 1 : -1),
      down: (a: TrainerInfo, b: TrainerInfo) => (a.good > b.good ? -1 : 1),
    },
    lastVisit: {
      up: (a: TrainerInfo, b: TrainerInfo) => (a.lastVisit > b.lastVisit ? 1 : -1),
      down: (a: TrainerInfo, b: TrainerInfo) => (a.lastVisit > b.lastVisit ? -1 : 1),
    },
    commented: {
      up: (a: TrainerInfo, b: TrainerInfo) => (a.commented > b.commented ? 1 : -1),
      down: (a: TrainerInfo, b: TrainerInfo) => (a.commented > b.commented ? -1 : 1),
    },
  }

  const sortedInfosForTrainer =
    sortStateTrainer === null
      ? filteredProductsForTrainer
      : filteredProductsForTrainer.slice().sort(sortFuncsTrainer[sortStateTrainer.key][sortStateTrainer.sort])

  // ページネーション系(サポーター)
  const [page, setPage] = useState(1) //ページ番号
  const [pageCount, setPageCount] = useState<number>() //ページ数
  const displayedItems = sortedInfosForTrainer.slice((page - 1) * displayNum, page * displayNum)

  useEffect(() => {
    const pagenatetionMaxLength = Math.floor(TrainerInfos.length / displayNum)
    setPageCount(pagenatetionMaxLength)
  }, [TrainerInfos.length, displayNum])

  const handleChange = (_: any, index: number) => {
    //ページ移動時にページ番号を更新
    setPage(index)
  }

  return (
    <div>
      {!loading ? (
        <div className={classes.allRoot}>
          <div>
            <div className={classes.titleWraper}>
              <div className={classes.title}>全体実施状況</div>
              <div className={classes.searchBar}>
                <SearchBar
                  placeholder={isSelectedMemberTab ? 'メンバーを検索' : 'サポーターを検索'}
                  borderRadius={8}
                  iconMarginLeft={16}
                  iconMarginRight={21}
                  onChangeInput={(e) => setKeyword(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className={classes.tagWrapper}>
                <div className={isSelectedMemberTab ? classes.active : classes.unActive} onClick={toggleMember}>
                  メンバー
                </div>
                <div className={isSelectedMemberTab ? classes.unActive : classes.active} onClick={toggleTrainer}>
                  サポーター
                </div>
              </div>
            </div>
            <div style={{ position: 'relative' }}>
              {isSelectedMemberTab ? (
                <div className={classes.containerRoot} id={memberContainerId}>
                  <div className={classes.borderForMember} />
                  <div className={classes.tableHeader}>
                    <div className={classes.tableWrapper}>
                      <div className={classes.itemNameWrapper}>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'name',
                              sort: prev?.key === 'name' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('nameM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'name' && sortStateMember.sort === 'up'
                              ? classes.itemNameGreen
                              : classes.itemName
                          }
                        >
                          <div className={classes.innerText}>名前</div>
                          {isMdUp && isHovered === 'nameM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasi} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'name' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.tableItem}>
                        <div className={classes.itemNameBodyWrapper}>
                          {displayedItemsMember.map((item, id) => (
                            <div key={id} className={classes.itemNameBody}>
                              <div className={classes.userAvatar}>
                                <img src={item.imageUrl || NoAvatarSvg} alt={'no-avatar'} />
                              </div>
                              <div className={classes.userProfiles}>{item.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className={classes.tablebody}>
                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'doneAction',
                              sort: prev?.key === 'doneAction' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('actionM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'doneAction' && sortStateMember.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            アクション
                            <br /> 達成率
                          </div>
                          {isMdUp && isHovered === 'actionM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'doneAction' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>

                        <div className={classes.itemsEachWrapper}>
                          <div>
                            {displayedItemsMember.map((item, index) => (
                              <div key={`${item.id}-${index}`} className={classes.itemsEachContainer}>
                                {item.doneAction}/{item.allAction}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'badCondition',
                              sort: prev?.key === 'badCondition' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('badActionM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'badCondition' && sortStateMember.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            デイリー
                            <br /> 不調数
                          </div>
                          {isMdUp && isHovered === 'badActionM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'badCondition' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>

                        <div className={classes.itemsEachWrapper}>
                          <div>
                            {displayedItemsMember.map((item, id) => (
                              <div key={id} className={classes.itemsEachContainer}>
                                {item.badCondition}/{item.allDaily}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'post',
                              sort: prev?.key === 'post' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('postM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'post' && sortStateMember.sort === 'up'
                              ? classes.itemsEachOneLineGreen
                              : classes.itemsEachOneLine
                          }
                        >
                          <div className={classes.itemText}>投稿数</div>
                          {isMdUp && isHovered === 'postM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          {sortStateMember?.key === 'post' && sortStateMember.sort === 'up' ? (
                            <img src={upArrow} alt="up" />
                          ) : (
                            <img src={downArrow} alt="down" />
                          )}
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          <div>
                            {displayedItemsMember.map((item, id) => (
                              <div key={id} className={classes.itemsEachContainer}>
                                {item.post}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'commented',
                              sort: prev?.key === 'commented' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('commentedM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'commented' && sortStateMember.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            コメント
                            <br /> された数
                          </div>
                          {isMdUp && isHovered === 'commentedM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'commented' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          {displayedItemsMember.map((item, id) => (
                            <div key={id} className={classes.itemsEachContainer}>
                              {item.commented}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'good',
                              sort: prev?.key === 'good' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('goodM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'good' && sortStateMember.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            いいね
                            <br /> 押した数
                            {isMdUp && isHovered === 'goodM' && (
                              <div className={classes.fukidasiWrapper}>
                                <img className={classes.fukidasiGood} src={descriptionArrow} alt="吹き出し" />
                              </div>
                            )}
                          </div>
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'good' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>

                        <div className={classes.itemsEachWrapper}>
                          <div>
                            {displayedItemsMember.map((item, id) => (
                              <div key={id} className={classes.itemsEachContainer}>
                                {item.good}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateMember((prev) => ({
                              key: 'lastVisit',
                              sort: prev?.key === 'lastVisit' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('lastVisitM')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'lastVisit' && sortStateMember.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            最終
                            <br /> ログイン日
                          </div>
                          {isMdUp && isHovered === 'lastVisitM' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'lastVisit' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          <div>
                            {displayedItemsMember.map((item, id) => (
                              <div key={id} className={classes.itemsEachContainer}>
                                {item.lastVisit}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.containerRoot} id={supporterContainerId}>
                  <div className={classes.borderForTrainer} />
                  <div className={classes.tableHeader}>
                    <div className={classes.tableWrapper}>
                      <div className={classes.itemNameWrapper}>
                        <div
                          onClick={() =>
                            setSortStateTrainer((prev) => ({
                              key: 'name',
                              sort: prev?.key === 'name' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('nameT')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateMember?.key === 'name' && sortStateMember.sort === 'up'
                              ? classes.itemNameGreen
                              : classes.itemName
                          }
                        >
                          <div className={classes.innerText}>名前</div>
                          {isMdUp && isHovered === 'nameT' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasi} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateMember?.key === 'name' && sortStateMember.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.tableItem}>
                        <div className={classes.itemNameBodyWrapper}>
                          {displayedItems.map((item, id) => (
                            <div key={id} className={classes.itemNameBody}>
                              <div className={classes.userAvatar}>
                                <img src={item.imageUrl || NoAvatarSvg} alt={'no-avatar'} />
                              </div>
                              <div className={classes.userProfiles}>{item.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className={classes.tablebody}>
                      <div>
                        <div
                          onClick={() =>
                            setSortStateTrainer((prev) => ({
                              key: 'good',
                              sort: prev?.key === 'good' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('goodT')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateTrainer?.key === 'good' && sortStateTrainer.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            いいね
                            <br /> 押した数
                          </div>
                          {isMdUp && isHovered === 'goodT' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateTrainer?.key === 'good' && sortStateTrainer.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          {displayedItems.map((item, id) => (
                            <div key={id} className={classes.itemsEachContainer}>
                              {item.good}
                            </div>
                          ))}
                        </div>

                        <div className={classes.itemsEachWrapper}>
                          <div className={classes.itemsEachContainer}></div>
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateTrainer((prev) => ({
                              key: 'commented',
                              sort: prev?.key === 'commented' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('commentedT')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateTrainer?.key === 'commented' && sortStateTrainer.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            コメント
                            <br /> した数
                          </div>
                          {isMdUp && isHovered === 'commentedT' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateTrainer?.key === 'commented' && sortStateTrainer.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          {displayedItems.map((item, id) => (
                            <div key={id} className={classes.itemsEachContainer}>
                              {item.commented}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <div
                          onClick={() =>
                            setSortStateTrainer((prev) => ({
                              key: 'lastVisit',
                              sort: prev?.key === 'lastVisit' && prev.sort === 'up' ? 'down' : 'up',
                            }))
                          }
                          onMouseEnter={() => setIsHoverd('lastVisitT')}
                          onMouseLeave={() => setIsHoverd('')}
                          className={
                            sortStateTrainer?.key === 'lastVisit' && sortStateTrainer.sort === 'up'
                              ? classes.itemsEachGreen
                              : classes.itemsEach
                          }
                        >
                          <div>
                            最終
                            <br /> ログイン日
                          </div>
                          {isMdUp && isHovered === 'lastVisitT' && (
                            <div className={classes.fukidasiWrapper}>
                              <img className={classes.fukidasiItems} src={descriptionArrow} alt="吹き出し" />
                            </div>
                          )}
                          <div className={classes.iconArrow}>
                            {sortStateTrainer?.key === 'lastVisit' && sortStateTrainer.sort === 'up' ? (
                              <img src={upArrow} alt="up" />
                            ) : (
                              <img src={downArrow} alt="down" />
                            )}
                          </div>
                        </div>
                        <div className={classes.itemsEachWrapper}>
                          {displayedItems.map((item, id) => (
                            <div key={id} className={classes.itemsEachContainer}>
                              {item.lastVisit ? item.lastVisit : 'データなし'}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <SlideArrow visible={isSmUp} windowId={isSelectedMemberTab ? memberContainerId : supporterContainerId} />
            </div>
            {!isSmUp ? (
              <div>
                {isSelectedMemberTab ? (
                  <div className={classes.pagenateWrapper}>
                    <div className={classes.pagenateContainer}>
                      <Pagination
                        count={pageCountMember}
                        page={pageForMember}
                        variant="outlined"
                        onChange={handleChangeForMember}
                        className={classes.pagenate}
                      />
                    </div>
                    <div className={classes.pagenateInfo}>
                      {pageForMember}/{pageCountMember}ページ
                    </div>
                  </div>
                ) : (
                  <div className={classes.pagenateWrapper}>
                    <div className={classes.pagenateContainer}>
                      <Pagination
                        count={pageCount}
                        page={page}
                        variant="outlined"
                        onChange={handleChange}
                        className={classes.pagenate}
                      />
                    </div>
                    <div className={classes.pagenateInfo}>
                      {page}/{pageCount}ページ
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <>
          <p className={classes.progress}>
            ロード中です。このままお待ちください<span className={classes.point1}>.</span>
            <span className={classes.point2}>.</span>
            <span className={classes.point3}>.</span>
            <p className={classes.progressSecond}>(処理には数分かかる場合がございます。)</p>
          </p>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    allRoot: {
      margin: '0 16px 40px 16px',
      [theme.breakpoints.up(1000)]: {
        width: 770,
      },
      [theme.breakpoints.down('md')]: {
        width: 'initial',
      },
    },
    progress: {
      margin: '32px 0 0',
      textAlign: 'center',
      fontSize: 14,
      '& span': {
        fontSize: 16,
      },
    },
    progressSecond: {
      marginTop: -2,
      textAlign: 'center',
      fontSize: 14,
      '& span': {
        fontSize: 16,
      },
    },
    point1: {
      animation: '$textOn 3s cubic-bezier(0.33,0,0,1) 200ms infinite normal backwards running',
      '@global': {
        '@keyframes textOn': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
    point2: {
      animation: '$textOn 3s cubic-bezier(1,0,0,1) 200ms infinite normal backwards running',
    },
    point3: {
      animation: '$textOn 3s cubic-bezier(1,-0.35,0.8,1) 200ms infinite normal backwards running',
    },

    title: {
      fontWeight: 'bold',
      size: 16,
      marginBottom: 40,
      marginTop: 25,
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        marginBottom: 0,
      },
    },
    searchBar: {
      marginBottom: 24,
      marginTop: 25,
      width: 250,

      [theme.breakpoints.up('sm')]: {
        border: 'solid 1px #E9E9E9',
        borderRadius: 8,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
        paddingBottom: 24,
        paddingTop: 25,
        width: '100%',
      },
    },
    titleWraper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },

    innerText: {
      marginLeft: 5,
    },

    tagWrapper: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        textAlign: 'center',
        borderRadius: 4,
        marginBottom: 16,
      },
    },
    active: {
      color: constants.COLOR_ONBOARDING_MAIN,
      borderRadius: 4,
      backgroundColor: constants.COLOR_WHITE,
      padding: '11px 25px',
      fontWeight: 'bold',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 4,
        borderRadius: 4,
      },
    },
    unActive: {
      color: constants.COLOR_GRAY_DARK,
      borderRadius: 4,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      padding: '11px 25px',
      fontWeight: 'bold',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 4,
        padding: '15px 25px',
      },
    },
    borderForMember: {
      borderTop: 'solid 1px #E9E9E9',
      position: 'relative',
      top: 85,
      zIndex: 10,
      marginLeft: 15,
      width: 970,
    },
    borderForTrainer: {
      borderTop: 'solid 1px #E9E9E9',
      position: 'relative',
      top: 85,
      zIndex: 10,
      marginLeft: 15,
      width: 740,
    },
    itemText: { cursor: 'pointer' },
    iconArrowMiddle: { marginTop: 15 },
    tableHeader: {
      display: 'flex',
      fontWeight: 'bold',
      padding: '20px 15px 20px 0',
    },
    tableWrapper: {
      position: 'sticky',
      [theme.breakpoints.up('sm')]: {
        left: 0,
        marginLeft: 10,
      },
    },
    tablebody: {
      display: 'flex',
    },
    tableItem: {
      marginTop: 20,
    },
    containerRoot: {
      background: constants.COLOR_WHITE,
      overflowY: 'scroll',
      height: 540,
    },
    itemNameWrapper: {
      zIndex: 11,
      backgroundColor: constants.COLOR_WHITE,
      position: 'sticky',
      padding: '10px 0 20px 10px',
      [theme.breakpoints.down('md')]: {
        alignItems: 'end',
        justifyContent: 'space-between',
        marginRight: 8,
        marginTop: -10,
        border: 8,
      },
    },
    itemName: {
      minWidth: 250,
      display: 'flex',
      backgroundColor: constants.COLOR_WHITE,
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_OFF,
        border: 8,
        alignItems: 'center',
        height: 55,
      },
    },
    itemNameGreen: {
      minWidth: 250,
      display: 'flex',
      backgroundColor: constants.COLOR_WHITE,
      cursor: 'pointer',
      color: constants.COLOR_MAIN_NEW,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_ON,
        border: 8,
        alignItems: 'center',
        height: 55,
      },
    },

    itemsEach: {
      display: 'flex',
      minWidth: 120,
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_OFF,
        marginRight: 8,
        padding: '6px 5px 10px 6px',
        border: 8,
        marginLeft: 10,
        alignItems: 'center',
      },
    },
    itemsEachGreen: {
      display: 'flex',
      minWidth: 120,
      alignItems: 'center',
      cursor: 'pointer',
      color: constants.COLOR_MAIN_NEW,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'space-between',
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_ON,
        marginRight: 8,
        padding: '6px 5px 10px 6px',
        border: 8,
        marginLeft: 10,
        alignItems: 'center',
      },
    },
    itemsEachOneLine: {
      display: 'flex',
      minWidth: 120,
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_OFF,
        height: 55,
        marginRight: 8,
        padding: '6px 0 10px 6px',
        border: 8,
        marginLeft: 10,
        justifyContent: 'space-between',
      },
    },
    itemsEachOneLineGreen: {
      display: 'flex',
      minWidth: 120,
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      cursor: 'pointer',
      color: constants.COLOR_MAIN_NEW,
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_LIGHT_GRAY_SP_ON,
        height: 55,
        marginRight: 8,
        padding: '6px 0 10px 6px',
        border: 8,
        marginLeft: 10,
        justifyContent: 'space-between',
      },
    },
    fukidasiWrapper: {
      position: 'relative',
    },
    fukidasi: {
      position: 'absolute',
      top: 20,
      left: -40,
      zZndex: 100,
    },
    fukidasiItems: {
      top: 15,
      left: -80,
      position: 'absolute',
      zIndex: 100,
    },
    fukidasiGood: {
      top: 0,
      left: -20,
      position: 'absolute',
      zIndex: 100,
    },

    itemsEachWrapper: {
      marginTop: 40,
      [theme.breakpoints.down('md')]: {
        marginLeft: 15,
      },
    },
    itemsEachContainer: {
      padding: '15px 15px 15px 0',
      fontSize: 12,
      marginBottom: 12.5,
    },
    iconArrow: {
      cursor: 'pointer',
      marginLeft: 3,
    },
    itemNameBodyWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    itemNameBody: {
      padding: 15,
      display: 'flex',
      width: 250,
      backgroundColor: constants.COLOR_WHITE,
    },
    userAvatar: {
      marginRight: 16,
      '& img': {
        width: 30,
        height: 30,
        borderRadius: 30,
        objectFit: 'cover',
        verticalAlign: 'top',
      },
    },
    userProfiles: {
      flex: 1,
      lineHeight: 1,
      marginTop: 10,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    bottomButtonWrapper: {
      display: 'flex',
      position: 'absolute',
      bottom: 60,
      right: 100,
      flexWrap: 'wrap',
      gap: '0 10px',
      [theme.breakpoints.down(1100)]: {
        bottom: 50,
        right: 10,
      },
      [theme.breakpoints.down(1000)]: {
        bottom: 50,
        right: -20,
      },
      [theme.breakpoints.down(940)]: {
        bottom: 50,
        right: 80,
      },
      [theme.breakpoints.down(890)]: {
        bottom: 50,
        right: 90,
      },
      [theme.breakpoints.down(860)]: {
        bottom: 50,
        right: 100,
      },
      [theme.breakpoints.down(760)]: {
        bottom: 50,
        right: 50,
      },
    },
    bottomButtonContainer: {
      cursor: 'pointer',
    },
    pagenateWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    pagenateContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    pagenateInfo: {
      display: 'flex',
      justifyContent: 'center',
    },
    pagenate: {
      margin: '24px 0',
      '& .MuiPaginationItem-root': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
      '& .Mui-selected': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        color: constants.TEXT_WHITE,
      },
      '& .MuiPaginationItem-page.Mui-selected:hover': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      },

      '& li:last-child': {
        '& .MuiPaginationItem-outlined': {
          border: 'initial',
        },
      },
      '& li:first-child': {
        '& .MuiPaginationItem-outlined': {
          border: 'initial',
        },
      },
      '& .MuiPaginationItem-outlined': {
        border: '1px solid #00838C',
      },
    },
  }),
  { name: 'OverViewPage' }
)
