import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton } from 'components/NoStyleButton'
import downloadIcon from 'pages/teams/pages/dashboard/assets/downloadGreen.svg'
import { exportCSVGhostIndividualList } from 'pages/teams/pages/dashboard/components/tabs/contents/ghost/hooks/exportCSVGhostIndividualList'
import { Ghost, GhostHistoryConnection, GhostResearch } from 'utils/generated'

import * as constants from 'assets/constants'

type ButtonCSVProps = {
  ghostItem: GhostHistoryConnection
  times: number
  ghosts: Ghost[]
  actionFilter: (
    nextActionList: GhostResearch[] | undefined | null
  ) => Array<GhostResearch & { fullName: string; imageUrl: string | undefined }> | undefined
}

export const ButtonCSV: React.FC<ButtonCSVProps> = ({ ghostItem, times, ghosts, actionFilter }) => {
  const classes = useStyles()
  const filteredActions = actionFilter(ghostItem?.research?.nextAction)
  const ghost = ghosts.find((ghost) => ghost.id === ghostItem?.history?.ghostId)
  const ghostName = ghost?.name

  const inputDate = {
    times: times,
    ghostName: ghostName,
    filteredActions: filteredActions,
    createdAt: ghostItem?.history?.createdAt,
  }

  const CsvDownload = async () => {
    await exportCSVGhostIndividualList(inputDate)
  }

  return (
    <div>
      <NoStyleButton type="button" onClick={CsvDownload} className={`${classes.button}`}>
        <img src={downloadIcon} alt="download" />
        <span>この回をDL</span>
      </NoStyleButton>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      width: 86,
      height: 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      backgroundColor: constants.COLOR_WHITE,
      padding: '0 6px',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      boxShadow: 'none',
      borderRadius: 40,
      fontWeight: 600,
      marginLeft: 'auto',
      fontSize: 10,
      [theme.breakpoints.down('md')]: {
        marginRight: 16,
      },
      '& img': {
        marginRight: 4,
        width: 12,
      },
    },
  }),
  { name: 'ButtonCSV' }
)
