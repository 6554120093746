import React, { useState } from 'react'

import { Theme } from '@mui/material/styles';
import { ClassNameMap } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns'

import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { OnboardingTeamMember } from 'utils/generated'

import { WhiteCard, HandleDetailOpenText } from './atoms'

import noAvatarSvg from '../assets/noAvatar.svg'
import * as constants from 'assets/constants'


type Props = {
  teamMember: OnboardingTeamMember
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const ProfileBox: React.FC<Props & StyleProps> = ({ teamMember, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  //  handleDetail
  const [visibleProfileDetail, setVisibleProfileDetail] = useState<boolean>(false)

  return (
    <WhiteCard ownStyles={{ padding: 0, ...ownStyles }}>
      <div className={classes.profileBoxSummary}>
        <img className={classes.avatar} src={teamMember.imageUrl || noAvatarSvg} alt={'icon'} />
        <div className={classes.nameWrapper}>
          <div className={classes.nickname}>
            {teamMember.nickname && teamMember.nickname !== '名無し' ? teamMember.nickname : teamMember.email ?? ''}
          </div>
          <div className={classes.role}>{teamMemberRoleLabel(teamMember.role)}</div>
        </div>
        <HandleDetailOpenText open={visibleProfileDetail} setOpen={setVisibleProfileDetail} />
      </div>
      {visibleProfileDetail ? <DetailBox teamMember={teamMember} classes={classes} /> : <></>}
    </WhiteCard>
  )
}

type DetailBoxProps = {
  teamMember: OnboardingTeamMember
  classes: ClassNameMap
}

const DetailBox: React.FC<DetailBoxProps> = ({ teamMember, classes }) => {
  return (
    <div className={classes.detailBoxRoot}>
      <div className={classes.detailText}>
        <div>参加登録日</div>
        <div>{format(new Date(teamMember?.startedAt || ''), 'Y年M月d日') || 'まだ未参加です'}</div>
      </div>
      <div className={classes.detailText}>
        <div>役職/部署など肩書</div>
        <div>{teamMember.position || '未記入'}</div>
      </div>
      <div>{teamMember.description || '未記入'}</div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    profileBoxSummary: {
      padding: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    nameWrapper: {
      margin: '0 auto 0 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    avatar: {
      width: 48,
      height: 48,
      borderRadius: 24,
    },
    nickname: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    role: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 10,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      borderRadius: 2,
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      margin: '8px 0 0',
      padding: '0 2px',
    },
    detailClickText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      margin: '0 0 0 8px',
      whiteSpace: 'nowrap',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    text: {
      fontWeight: 'bold',
      margin: '0 8px 0 0',
    },
    img: {
      display: 'block',
    },
    detailBoxRoot: {
      padding: '0 24px 24px',
    },
    detailText: {
      display: 'grid',
      gap: '0 8px',
      gridTemplateColumns: '1fr 2fr',
      gridTemplateRows: '40px',
      fontSize: 14,
    },
  }),
  { name: 'ProfileBox' }
)
