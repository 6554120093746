import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'
export const Header: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <header className={classes.header}>
      <div className={classes.headerWrapper}>
        <img
          className={classes.headerLogo}
          src={process.env.PUBLIC_URL + '/assets/svg/teams/logo_kickoff.svg'}
          alt="Cocolabo キックオフ"
        />
      </div>
    </header>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    height: '56px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  headerWrapper: {
    width: '100%',
    maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
    },
  },
  headerLogo: {
    width: '100px',
  },
}))
