import React from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button as MaterialButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
  onClick?: () => void
  buttonType?: 'primary' | 'secondary' | 'light'
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  full?: boolean
  style?: React.CSSProperties
  endIconStyle?: React.CSSProperties
  hoverStyle?: React.CSSProperties
  width?: string | number
  icon?: 'chevron-right'
}

export const Button: React.FC<Props> = ({
  children,
  full = false,
  buttonType = 'primary',
  onClick = () => undefined,
  type,
  disabled,
  style,
  endIconStyle,
  hoverStyle,
  width,
  icon,
}) => {
  const classes = useStyles({ full, width, endIconStyle, hoverStyle })

  return (
    <MaterialButton
      onClick={onClick}
      className={`${classes.base} ${classes[buttonType]}`}
      variant="contained"
      color="primary"
      type={type}
      disabled={disabled}
      style={style}
      endIcon={
        icon &&
        (() => {
          switch (icon) {
            case 'chevron-right':
              return <ChevronRightIcon />
          }
        })()
      }
    >
      {children}
    </MaterialButton>
  )
}

interface StyleProps {
  full: boolean
  width?: string | number
  endIconStyle?: React.CSSProperties
  hoverStyle?: React.CSSProperties
}

const useStyles = makeStyles((theme: Theme) => ({
  base: ({ full, width, endIconStyle, hoverStyle = {} }: StyleProps) => ({
    width: full ? '100%' : width || 288,
    height: 48,
    fontWeight: 'bold',
    borderRadius: 8,
    boxShadow: 'none',
    whiteSpace: 'nowrap',

    '&:hover': {
      boxShadow: 'none',
      ...hoverStyle,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    '& .MuiButton-endIcon': endIconStyle,
  }),
  primary: {
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    color: '#fff',

    '&:hover': {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
    },
  },
  secondary: {
    border: `solid 1px ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    backgroundColor: '#fff',
    '&.Mui-disabled': {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
    },
    '&:hover': {
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },
  light: {
    backgroundColor: constants.COLOR_WHITE,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    '&.Mui-disabled': {
      backgroundColor: constants.COLOR_WHITE,
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
    },
    '&:hover': {
      color: constants.COLOR_WHITE,
    },
  },
}))
