import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Switch } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { format } from 'date-fns'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'
import { getBasePeriod, getWeeksByBasePeriod } from '../../../services/utils/weekday'
import { ISetup } from '../../../stores/setup/reducer'
import ModalStartSetup from '../landing/components/FormModalStartSetup.component'

import ModalChangeSetupStartedAt from './components/ModalChangeSetupStartedAt.component'
import PointComponent from './components/Point.component'
import RadarComponent from './components/Radar.component'
import Slider from './components/Slider'
import { connector, ContainerProps } from './index.container'


interface IOwnProps {
  setup: ISetup
}

type Props = IOwnProps & ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const { classes, updateAccount } = props

  const handlePageSetupList = () => {
    props.history.push(pages.PAGE_SETUP_LIST)
    window.scrollTo(0, 0)
  }

  // weekSlider
  const [weekSlider, setWeekSlider] = React.useState(-1)

  // email notification
  const onChangeEmailNotification = React.useCallback(
    (e) => {
      updateAccount({
        emailNotifySetup: Number(!!e.target.checked) as 0 | 1,
      })
    },
    [updateAccount]
  )

  React.useEffect(() => {
    if (!props.user.fetched) return

    if (!props.user?.setupStartedAtList) {
      props.history.push(pages.PAGE_SETUP_LANDING)
      window.scrollTo(0, 0)
    }

    if (!props.setup.currentSetupStartedAt && props.user?.setupStartedAtList) {
      props.changeCurrentSetupStartedAt(props.user.setupStartedAtList[0])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.fetched])

  const weeks = React.useMemo(() => {
    if (props.setup.currentSetupStartedAt) {
      const basePeriod = getBasePeriod(new Date(props.setup.currentSetupStartedAt))
      const result = getWeeksByBasePeriod(basePeriod)
      return result
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.setup])

  const [openChangeSetupStartedAtModal, setOpenChangeSetupStartedAtModal] = React.useState(false)
  const [openStartSetupModal, setOpenStartSetupModal] = React.useState(false)

  return (
    <React.Fragment>
      <ModalStartSetup
        open={openStartSetupModal}
        handleClose={() => {
          setOpenStartSetupModal(false)
        }}
        handleSubmitCallback={props.changeCurrentSetupStartedAt}
      />

      <ModalChangeSetupStartedAt
        setOpenStartSetupModal={setOpenStartSetupModal}
        isOpen={openChangeSetupStartedAtModal}
        closeModal={() => setOpenChangeSetupStartedAtModal(false)}
      />

      <div className={classes.main}>
        {props.user.fetched && props.setup.currentSetupStartedAt && (
          <div className="__container">
            <div className="__content">
              <div className="__comment">
                <div className="__header">{props.user.username}　の成長チャート</div>
                <PointComponent week={weekSlider < 0 ? weeks || 0 : weekSlider} />
              </div>

              <div className="__radar">
                <div className="__chart">
                  <RadarComponent week={weekSlider < 0 ? weeks || 0 : weekSlider} />
                </div>
                <div className="__description">
                  <div>
                    <span className="secondColor" />
                    アクション進捗
                  </div>
                  <div>
                    <span className="mainColor" />
                    自己評価
                  </div>
                </div>
              </div>

              <div className="__graph">
                <div className="__upper">
                  <div className="__title">
                    チーム開始日（着任日）
                    {format(new Date(props.setup.currentSetupStartedAt), 'yyyy.MM.dd')}
                  </div>
                  <div className="__chart">
                    <Slider value={weeks || 0} max={9} setWeekSlider={setWeekSlider} />
                  </div>
                  <div className="__dates">
                    <div>0w</div>
                    <div>8w</div>
                  </div>
                </div>

                <div className="__teamChange">
                  <Button
                    bgColor={constants.COLOR_MAIN}
                    textColor={constants.COLOR_WHITE}
                    square={true}
                    body={<div className="__teamChangeButton">チームを切り替える</div>}
                    onClick={() => setOpenChangeSetupStartedAtModal(true)}
                  />
                </div>
              </div>

              <div className="__settings">
                <div className="__setting">
                  <div>新着アクションのメール通知</div>
                  <div>
                    <Switch
                      checked={!!props?.user?.emailNotifySetup}
                      onChange={onChangeEmailNotification}
                      name={'emailNotifySetup'}
                      color={'primary'}
                    />
                  </div>
                </div>
              </div>
              <div className="__links">
                <div className="__link">
                  <a
                    href="https://new-one.co.jp/doc/web/viewer.html?file=_custom.php&no=SKM_C55821010612300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    アクションリストの全リストをご覧になりたい方はこちら
                  </a>
                </div>
              </div>

              <Button
                bgColor={constants.COLOR_SECOND}
                textColor={constants.COLOR_WHITE}
                fullWidth={true}
                body={<div className="__actionButton">アクションリストを確認</div>}
                onClick={handlePageSetupList}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
      backgroundColor: constants.COLOR_WHITE,

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 64px',
          marginTop: '16px',

          '& .__comment': {
            marginBottom: '32px',
            '& .__header': {
              backgroundColor: constants.COLOR_MAIN,
              color: constants.COLOR_WHITE,
              padding: '8px 16px',
              fontWeight: 'bold',
              fontSize: '16px',
              borderTopRightRadius: '5px',
              borderTopLeftRadius: '5px',
            },
            '& .__body': {
              display: 'flex',
              alignItems: 'center',
              padding: '16px',
              backgroundColor: constants.COLOR_GRAY,
              borderBottomRightRadius: '5px',
              borderBottomLeftRadius: '5px',
              boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
              position: 'relative',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 'calc(100% - 10px)',
                left: '50%',
                backgroundColor: constants.COLOR_GRAY,
                height: '20px',
                width: '20px',
                boxShadow: '-2px 2px 2px 0 rgba( 178, 178, 178, .4 )',
                transform: 'rotate( -45deg )',
                mozTransform: 'rotate( 45deg )',
                msTransform: 'rotate( 45deg )',
              },
              '& .__point': {
                fontWeight: 'bold',
                marginRight: '8px',
                whiteSpace: 'nowrap',
                padding: '0 8px',
                '& span': {
                  fontSize: '30px',
                },
              },
              '& .__title': {
                backgroundColor: constants.COLOR_WHITE,
                display: 'flex',
                padding: '8px 16px',
                borderRadius: '5px',
                alignItems: 'center',
                width: '100%',
                '& span': {
                  fontSize: '10px',
                  marginRight: '8px',
                  color: constants.COLOR_MAIN,
                },
                '& div': {
                  fontWeight: 'bold',
                },
              },
            },
          },
          '& .__radar': {
            backgroundColor: constants.COLOR_GRAY,
            padding: '16px',
            marginBottom: '16px',
            borderRadius: '5px',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
            '& .__chart': {
              '& .__image': {
                width: '100%',
              },
              '& svg': {
                width: '100%',
                height: '100%',
              },
            },
            '& .__description': {
              backgroundColor: '#E8E8E8',
              padding: '4px 12px',
              display: 'inline-flex',
              fontSize: '10px',
              borderRadius: '5px',
              '& div': {
                display: 'flex',
                alignItems: 'center',
                marginRight: '8px',
                '& span': {
                  width: '10px',
                  height: '10px',
                  display: 'block',
                  borderRadius: '50%',
                  marginRight: '4px',
                  border: '1px solid',

                  '&.mainColor': {
                    backgroundColor: constants.COLOR_MAIN_LIGHT,
                    borderColor: constants.COLOR_MAIN,
                  },
                  '&.secondColor': {
                    backgroundColor: constants.COLOR_SECOND_LIGHT,
                    borderColor: constants.COLOR_SECOND,
                  },
                },
              },
            },
          },
          '& .__graph': {
            backgroundColor: constants.COLOR_GRAY,
            borderRadius: '5px',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
            marginBottom: '32px',
            '& .__upper': {
              padding: '16px',
            },
            '& .__title': {
              color: constants.COLOR_MAIN,
              borderBottom: '1px solid',
              fontWeight: 'bold',
            },
            '& .__chart': {
              //
            },
            '& .__dates': {
              color: constants.TEXT_GRAY,
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'space-between',
            },
            '& .__button': {
              border: 'solid 1px #fff',
            },
            '& .__teamChange': {
              display: 'flex',
              justifyContent: 'center',
              borderTop: 'solid 2px #fff',
              padding: '16px',
            },
          },
          '& .__teamChangeButton': {
            width: '248px',
          },
          '& .__settings': {
            backgroundColor: constants.COLOR_GRAY,
            padding: '16px',
            borderRadius: '5px',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
            marginBottom: '32px',

            '& .__setting': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
          },

          '& .__links': {
            backgroundColor: constants.COLOR_GRAY,
            padding: '16px',
            borderRadius: '5px',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
            marginBottom: '32px',
            '& .__link': {
              color: constants.COLOR_MAIN,
              textDecoration: 'underline',
            },
          },

          '& .__actionButton': {
            padding: '4px 0',
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
