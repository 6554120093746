import { FeatureCategory } from './generated'

export const strLimit = (limit: number, str?: string | null) => {
  if (!str || str.length < limit) return str

  const sliceStr = str.substring(0, limit)
  return `${sliceStr}...`
}

export const makeFeatureSkPrefix = (category: FeatureCategory) => {
  return `${category}_`
}

export const makeFeatureSk = (category: FeatureCategory, ulid: string) => {
  return `${category}_${ulid}`
}

export const extractFeatureSkId = (sk: string, category: FeatureCategory) => {
  return sk.replace(`${category}_`, '')
}

export const getFeatureSkFromSkId = (id: string, category: FeatureCategory) => {
  return `${category}_${id}`
}
