import React from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Theme, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { TableConst, TeamBuildingAction } from 'utils/generated'

import { constants } from 'assets'

interface Props {
  item: Partial<TeamBuildingAction>
  onClick?: () => void
}

export const ActionListItem: React.FC<Props> = ({ item, onClick = () => undefined }) => {
  const classes = useStyles()
  const isAssigned = item.assignedTeamMemberId !== TableConst.Dummy

  return (
    <Button className={classes.root} onClick={onClick}>
      <div className={classes.label}>{item.name}</div>
      <div className={classes.icon}>
        {isAssigned ? (
          <img src={`${process.env.PUBLIC_URL}/assets/svg/teamBuilding/selectItem/success.svg`} alt="" />
        ) : (
          <ChevronRightIcon />
        )}
      </div>
    </Button>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    borderRadius: 8,
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    '& + &': {
      marginTop: '16px',
    },
  },

  label: {
    textAlign: 'left',
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 1.5,
    color: constants.COLOR_ONBOARDING_MAIN,
    textTransform: 'none',
  },

  icon: {
    display: 'flex',
    justifyContent: 'center',

    '& > svg': {
      fill: constants.COLOR_ONBOARDING_MAIN,
      marginRight: '-6px',
    },
  },
}))
