import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { OnboardingActionCategory } from 'utils/generated'

import IconsSearchFilter from '../../member-manage/assets/Icons-Search-Filter.svg'

import IconArrowDown from '../assets/arrow-down.svg'
import IconArrowUp from '../assets/arrow-up.svg'
import IconCheck from '../assets/check.svg'
import * as constants from 'assets/constants'

interface Props {
  isOpen: boolean
  category?: OnboardingActionCategory
  filterShowen?: boolean
  setCategory: (category?: OnboardingActionCategory) => void
  setFilterShowen: (filterShowen: boolean) => void
}

export const Filter: React.FC<Props> = ({ isOpen, category, filterShowen, setCategory, setFilterShowen }) => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'sm')
  const labels = [undefined, ...Object.values(OnboardingActionCategory)]

  const ActionList = ({ label }: { label?: OnboardingActionCategory }) => (
    <li
      className={classes.item}
      onClick={() => setCategory(label)}
      style={{ color: `${category === label ? constants.COLOR_ONBOARDING_MAIN : constants.TEXT_GRAY_DARK}` }}
    >
      {onboardingActionCategoryLabel(label) || '全て'}
      {category === label && <img src={IconCheck} alt="icon" className={classes.icon} />}
    </li>
  )

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        {isXsDown ? (
          <img
            data-testid="filter-icon"
            src={IconsSearchFilter}
            alt="icon"
            onClick={() => setFilterShowen(!filterShowen)}
            style={{ cursor: 'pointer' }}
          />
        ) : (
          <div className={classes.pcMenu} onClick={() => setFilterShowen(!filterShowen)}>
            {onboardingActionCategoryLabel(category) || '全て'}
            <img src={isOpen ? IconArrowUp : IconArrowDown} alt="" />
          </div>
        )}
      </div>
      {isOpen ? (
        <ul className={classes.list}>
          {labels.map((label) => (
            <ActionList key={`key-of-${label ?? 'All'}`} label={label} />
          ))}
        </ul>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  pcMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 150,
    height: 42,
    padding: '0 14px 0 16px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    backgroundColor: constants.COLOR_WHITE,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    cursor: 'pointer',
    '& img': {
      width: 12,
    },
  },
  list: {
    listStyle: 'none',
    backgroundColor: constants.COLOR_WHITE,
    padding: 0,
    margin: '0 0 24PX',
    boxShadow: `2px 2px 7px -2px ${constants.COLOR_ONBOARDING_GRAY_DARK}`,
    borderRadius: 4,
    fontSize: 14,
  },
  item: {
    position: 'relative',
    padding: '17px 68px 17px 37px',
    cursor: 'pointer',
  },
  icon: {
    position: 'absolute',
    left: 16,
    top: 20,
  },
}))
