import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'inline',
    cursor: 'pointer',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  articleId?: number
  onClick: () => void
}

// -----------------------------
// Component
// -----------------------------
export const Draft = (props: IProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root} onClick={props.onClick}>
      <img src="/assets/svg/hint/draft_icn.svg" alt="下書き" />
    </div>
  )
}
