import * as React from 'react'

import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostResearch } from 'utils/generated'

import likeOff from '../../assets/svg/likeOff.svg'
import likeOn from '../../assets/svg/likeOn.svg'
import { Balloon, GoodCount } from '../atoms'

type Props = {
  thumbUpIconBehavior: 'clickable' | 'onlyView'
  balloonStyles?: React.CSSProperties
  ownStyles?: React.CSSProperties
  researches: GhostResearch[]
  selected?: string[]
  modal?: boolean
  isLike?: boolean
  menuItem?: string
  selectLike?: (id: string) => void
  balloonPadding?: number
  noBalloonTitle?: boolean
  isReason?: boolean
}

export const GhostReported: React.FC<Props> = ({ modal = false, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const mediaQuery = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div style={props.ownStyles}>
      {props.researches.map((r, i) => (
        <div key={r.id} style={{ ...props.balloonStyles }} className={classes.research}>
          <Balloon
            ownStyles={{
              width: !modal || props.noBalloonTitle ? '100%' : mediaQuery ? 428 : '100%',
              padding: props.balloonPadding ?? 16,
            }}
          >
            {props.menuItem && i < 1 && !props.noBalloonTitle ? (
              <div style={{ color: constGhost.COLOR_BLACK3, fontWeight: 'bold', marginBottom: 10 }}>
                {props.menuItem}
              </div>
            ) : (
              <></>
            )}
            {r.report}
          </Balloon>
          {!modal && props.isLike && (
            <>
              {props.thumbUpIconBehavior === 'clickable' ? (
                <LikeButton
                  id={r.id}
                  liked={props.selected?.includes(r.id)}
                  isReason={props.isReason}
                  onPress={props.selectLike}
                />
              ) : (
                <GoodCount count={r.like} ownStyles={{ marginTop: 8 }} />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  research: {
    marginBottom: 24,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))

const LikeButton = ({
  id,
  liked,
  isReason,
  onPress,
}: {
  id: string
  liked?: boolean
  isReason?: boolean
  onPress?: (id: string) => void
}) => {
  if (!onPress) return null
  return (
    (<div style={{ marginTop: 4, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
      {liked ? (
        <></>
      ) : (
        <span style={{ color: constGhost.COLOR_MAIN_NEW, fontSize: 12, marginRight: 4 }}>
          {isReason ? 'この原因に共感' : 'この対処方法に共感'}
        </span>
      )}
      <IconButton
        aria-label="like-button"
        style={{ width: 32, height: 32, marginRight: -4 }}
        onClick={() => onPress(id)}
        size="large">
        <img src={liked ? likeOn : likeOff} alt="like" />
      </IconButton>
    </div>)
  );
}
