import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { EmblaOptionsType, EmblaPluginType } from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'

import { EmblaCarousel } from 'components/EmblaCarousel'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { Pages, constants } from 'assets'

export const IntroductionCocolabo: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')

  const OPTIONS: EmblaOptionsType = { loop: true }
  const PLUGINS: EmblaPluginType[] = [Autoplay({ playOnInit: true, delay: 15000 })]

  return (
    <>
      <div className={classes.root}>
        <div className={classes.demand}>会社やチームでも診断をしたい！</div>
        <p className={classes.recommend}>
          チームの対話支援ツール「Cocolabo」なら、チームでの診断・対話やPDFデータでの印刷など、
          {isMdUp && <br />}
          すべての機能がお使いいただけます！
        </p>
        <p className={classes.detail}>
          はたらく価値観診断は、管理職と従業員の対話支援ツール「Cocolabo」の「カルテ」を、無料でお試し利用することができる診断サービスです。
          「Cocolabo」では「カルテ」「キックオフ」「オバケ」の3つの対話ツールを用意してあり、
          組織やチームのシーンに応じて使い分けることができ、エンゲージメント向上に必要な仕事の進め方や関係性に特化した「対話」を気軽に実現することができます。
          <br />
          正式版のCocolaboカルテでは、チーム全員の診断結果の管理や、カルテを活用した対話を行うことができます。
        </p>
        <EmblaCarousel options={OPTIONS} plugins={PLUGINS} />
      </div>
      <div className={classes.link}>
        <a href={Pages.Hataraku} target="_blank" rel="noopener noreferrer">
          新しく診断をはじめる
        </a>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 40,
      backgroundColor: '#fff',
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        padding: 24,
      },
    },

    demand: {
      marginBottom: 16,
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 24,
      fontWeight: 'bold',
      textAlign: 'center',
    },

    recommend: {
      margin: '0 0 16px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },

    detail: {
      margin: '0 0 40px',
      padding: 16,
      backgroundColor: constants.COLOR_GRAY,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: '1.4px',
      whiteSpace: 'pre-wrap',
    },

    link: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 80,
      width: '100%',
      '& a': {
        display: 'grid',
        placeItems: 'center',
        width: 340,
        height: 60,
        background: '#fff',
        color: constants.COLOR_BLUE_DARK2,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 30,
        '@media (hover: hover)': {
          '&:hover': {
            opacity: '80%',
          },
        },
      },
    },
  }),
  { name: 'IntroductionCocolabo' }
)
