import React from 'react'
import { useHistory } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles';

import { dateStringToLPassedTime } from 'utils/date'
import { Notifycation } from 'utils/generated'

import { NotificationMark } from '../../../components/notification'

import * as constants from 'assets/constants'

type NotificationProps = {
  id: string
  time: string
  description: string
  avatarSrc: string
  title: string
  tab: string
  url: string
  getBatch: (id: string) => Promise<Notifycation | undefined> | undefined // delete undefined if create newsNotification Table
  updateBatch: (id: string) => void
}

export const Notification: React.FC<NotificationProps> = ({
  id,
  time,
  description,
  avatarSrc,
  title,
  tab,
  url,
  getBatch,
  updateBatch,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [batchFlg, setBatchFlg] = React.useState<number>(0)

  const onClick = async () => {
    if (batchFlg) {
      await updateBatch(id)
    }
    history.push(url)
  }

  React.useEffect(() => {
    if (tab === 'news') return
    ;(async () => {
      const res = await getBatch(id)
      res && res.checkedAt ? setBatchFlg(0) : setBatchFlg(1)
    })()
  }, [id, tab, getBatch])

  return (
    <div onClick={onClick}>
      <div className={classes.notificationContainer}>
        <div className={classes.avatarBox}>
          <div className={classes.mark}>
            <NotificationMark count={batchFlg} />
          </div>
          <img className={classes.avatar} src={avatarSrc} alt={'no-avatar'} />
        </div>
        <div className={classes.infoBox}>
          <div className={classes.time}>{dateStringToLPassedTime(time)}</div>
          <div className={classes.nickname}>{title}</div>
          <div className={classes.message}>{description}</div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  notificationContainer: {
    display: 'flex',
    backgroundColor: constants.COLOR_WHITE,
  },
  avatarBox: {
    position: 'relative',
    margin: '24px 16px',
    width: 52,
    height: 52,
    borderRadius: 26,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      verticalAlign: 'top',
    },
  },
  mark: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  avatar: {
    borderRadius: '50%',
  },
  infoBox: {
    padding: '17px 16px 17px 0',
    flex: 1,
    lineHeight: 1,
    wordBreak: 'break-all',
  },
  time: {
    fontSize: 12,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    textAlign: 'right',
  },
  nickname: {
    marginTop: 1,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 16,
    fontWeight: 'bold',
  },
  message: {
    marginTop: 10,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
  },
}))
