import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  fullVh?: boolean
  children: React.ReactNode
}

export const GrayBackGround: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <>
      <div className={classNames([classes.grayBackGround, props.fullVh && classes.fullVh])}>{props.children}</div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    grayBackGround: {
      background: constGhost.COLOR_LIGHTGRAY2,
      margin: 0,
      padding: 0,
    },
    fullVh: {
      minHeight: 'calc(100vh - 78px)', // 78px is header height.
    },
  }),
  { name: 'GrayBackGround' }
)
