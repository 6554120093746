import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { dateStringToLPassedTime } from 'utils/date'
import { OnboardingActionComment } from 'utils/generated'

import NoImage from '../../../../assets/noImage.svg'

import * as constants from 'assets/constants'

type Props = {
  item: OnboardingActionComment
}

export const CommentAvatar: React.FC<Props> = ({ item }) => {
  const classes = useStyles({})

  return (
    <>
      <div
        style={{
          flexBasis: 32,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={item.teamMember?.imageUrl ? item.teamMember?.imageUrl : NoImage}
          alt={'avatar'}
          className={classes.avatar}
        />
        <div style={{ marginLeft: 16, fontSize: 12, fontWeight: 'bold' }}>{item.teamMember?.nickname}</div>
        <div style={{ marginLeft: 8, fontSize: 10, color: constants.COLOR_ONBOARDING_GRAY_DARK }}>
          {item.createdAt ? dateStringToLPassedTime(item.createdAt) : ''}
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    borderRadius: 16,
    width: 32,
    height: 32,
    objectFit: 'cover',
  },
}))
