import React from 'react'
import { useLocation } from 'react-router-dom'

import { MenuItem, Select } from '@mui/material'
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { useForm, Controller } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { DefaultButton, Card, InputLabel } from 'pages/teams/components'
import { AdminSwitchTab } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useTransferTeamAdmin, useTeamMembers } from 'pages/teams/hooks'
import { TransferTeamAdminInput } from 'utils/generated'

import { constants, Pages, useHistory } from 'assets'
import { theme } from 'assets/theme'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsAdminTransferPage: React.FC<Props> = (props) => {
  const { match } = props
  const classes = useStyles()

  const { currentTeam, currentUser, currentTeamAdmin } = React.useContext(HooksContext)

  const { control, handleSubmit } = useForm<TransferTeamAdminInput>()

  const { transfer, loading: processing, errors } = useTransferTeamAdmin()
  const { teamMembers, loading, nextToken, more, filterMember } = useTeamMembers(match.params.teamId)
  React.useEffect(() => {
    if (!loading && nextToken) {
      more()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, nextToken])

  const [complete, setComplete] = React.useState(false)
  const onSubmit = async (input: TransferTeamAdminInput) => {
    const res = await transfer(input)
    if (res) {
      setComplete(true)
    }
  }
  const { route } = useHistory()
  const isMdUP = useMediaQuery(theme.breakpoints.up('md'))
  const params = useLocation().pathname
  const array = params.split('/')
  const url = array[3]

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {isMdUP ? <></> : <AdminSwitchTab url={url} />}
        <Card headerTitle={'管理者移譲'} loading={!currentTeamAdmin ? true : false}>
          {!complete ? (
            <>
              <p>管理者を移譲する相手を選択して確認メールを送信してください。</p>

              <Controller
                name={'id'}
                control={control}
                defaultValue={match.params.teamId}
                render={({ value }) => <input type={'hidden'} value={value} />}
              />

              <InputLabel>ユーザー名</InputLabel>
              <Controller
                name={'transferOwnerUserId'}
                control={control}
                defaultValue=""
                render={({ onChange, value }) => (
                  <Select className={classes.select} variant={'outlined'} value={value} onChange={onChange}>
                    {filterMember(teamMembers).map((teamMember, index) => (
                      <MenuItem key={`admin-transfer-user-selector-${index}`} value={teamMember.userId}>
                        {teamMember.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {errors && (
                <div className={classes.error}>
                  エラーが発生しました。{'\n'}
                  {errors.length > 0 ? (
                    <>{errors.map((e) => `${e.message}\n`)}</>
                  ) : (
                    'お手数ですが、時間を空けてお試しください。'
                  )}
                </div>
              )}

              {currentUser && currentUser.id === currentTeam?.ownerUserId ? (
                <div className={classes.btnContainer}>
                  <DefaultButton
                    title={'キャンセル'}
                    color={'team_white'}
                    loading={processing}
                    onClick={() => route.push(Pages.TeamsAdmin, { teamId: match.params.teamId })}
                  />
                  <div className={classes.btn}></div>
                  <DefaultButton title={'確認メールを送信'} color={'darkPrimary'} type="submit" style={{ boxShadow: 'none' }} loading={processing} />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <p
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                メールを送信しました
              </p>
            </>
          )}
        </Card>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {},

  backLabel: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  select: {
    width: '100%',
    marginBottom: 20,
    borderRadius: 8,
    border: 'solid 1px',
    borderColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
    height: 48,
    fontSize: 14,

    '& .MuiSelect-root': {
      padding: '10px 10px',
    },

    '& .MuiSvgIcon-root': {
      color: constants.COLOR_MAIN,
    },

    '& .MuiSelect-icon': {
      display: 'none',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  btn: {
    marginLeft: 24,
    [theme.breakpoints.down('md')]: {
      marginBottom: 24,
      width: '100%',
    },
  },

  error: {
    fontSize: 12,
    color: constants.COLOR_RED,
    marginBottom: 20,
  },
}))
