import React, { useEffect, useState, useContext } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { HooksContext } from 'pages/teams/contexts'

import { Movie } from './component/movie'

import { movie_source as source } from './assets/moviePools'
import * as constants from 'assets/constants'

export const TeamsUsageMovie: React.FC = () => {
  const { currentTeamMemberRole, isAdmin, isLeader } = useContext(HooksContext)
  const classes = useStyles()
  const [isMember, setIsMember] = useState<boolean>(false)
  useEffect(() => {
    if (currentTeamMemberRole === 'MEMBER') {
      setIsMember(true)
    }
  }, [isMember, currentTeamMemberRole])

  const ToLeaderRole = () => {
    setIsMember(false)
  }
  const ToMemberRole = () => {
    setIsMember(true)
  }

  const filterMovieByMEMBER = source.filter((item) => {
    return item.role === 'MEMBER'
  })

  // const isRoleIsMember = currentTeamMemberRole === 'MEMBER'

  return (
    <div className={classes.root}>
      <div className={classes.title}>Cocolaboお役立ち動画</div>
      {(isAdmin || isLeader) && (
        <div className={classes.tagWrapperNotMember}>
          <div
            className={!isMember ? classes.active : classes.unActive}
            onClick={() => {
              ToLeaderRole()
            }}
          >
            リーダー用
          </div>

          <div
            className={!isMember ? classes.unActive : classes.active}
            onClick={() => {
              ToMemberRole()
            }}
          >
            メンバー用
          </div>
        </div>
      )}
      <div className={classes.contentWrapper}>
        {!isMember ? (
          <div className={classes.movie}>
            {source.map((item, index) => {
              return <Movie item={item} key={index} />
            })}
          </div>
        ) : (
          <div className={classes.movie}>
            {filterMovieByMEMBER.map((item, index) => {
              return <Movie item={item} key={index} />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.up('sm')]: {
        margin: '0 16px',
      },
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      marginTop: 16,
      marginLeft: 20,
      paddingBottom: 26,
      [theme.breakpoints.down('md')]: {
        position: 'relative',
      },
    },
    tagWrapper: {
      display: 'flex',
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        textAlign: 'center',
        margin: '0 16px 16px 16px',
        borderRadius: 50,
      },
    },
    tagWrapperNotMember: {
      display: 'flex',
      cursor: 'pointer',
      borderRadius: 8,
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        textAlign: 'center',
        margin: '16px 0',
        borderRadius: 50,
      },
    },
    contentWrapper: {
      padding: 24,
      maxWidth: 940,
      [theme.breakpoints.up('sm')]: {
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    active: {
      color: constants.COLOR_ONBOARDING_MAIN,
      borderRadius: 4,
      backgroundColor: constants.COLOR_WHITE,
      padding: '11px 25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 4,
        borderRadius: 50,
      },
    },
    unActive: {
      color: constants.COLOR_GRAY_DARK,
      borderRadius: 4,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      padding: '11px 25px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        borderRadius: 50,
        padding: '15px 25px',
      },
    },
    movie: {
      display: 'flex',
      flexWrap: 'wrap',
      paddingBottom: 25,
      [theme.breakpoints.down('md')]: {
        display: 'inline',
      },
    },
  }),
  { name: 'TeamsUsageMovie' }
)
