import React, { useContext } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { Ghost, GhostMemberRole, GhostReport } from 'utils/generated'

type Props = {
  reports: GhostReport[]
  ghosts: Ghost[]
}

export const GhostVotingResultsList: React.FC<Props> = ({ reports, ghosts }) => {
  const { teamMember: currentTeamMember } = useContext(ghostTeamMemberContext)
  const classes = useStyles({ isLeader: currentTeamMember?.role === GhostMemberRole.Leader })
  const voteCounts = reports
    .filter((r) => r.scoreVotingStatus)
    .reduce((accumulator: { [key: string]: { UPVOTE: number; DOWNVOTE: number } }, report) => {
      const ghostId = report.ghostId
      const status = report.scoreVotingStatus
      if (!accumulator[ghostId]) {
        accumulator[ghostId] = {
          UPVOTE: 0,
          DOWNVOTE: 0,
        }
      }
      if (status === 'UPVOTE') {
        accumulator[ghostId].UPVOTE++
      } else if (status === 'DOWNVOTE') {
        accumulator[ghostId].DOWNVOTE++
      }
      return accumulator
    }, {})

  const voteCountsArray = Object.entries(voteCounts)
  const combineGhostAndVoteCount = ghosts.map((ghost) => {
    const voteCount = voteCountsArray.find(([ghostId]) => ghostId === ghost.id)
    return {
      ...ghost,
      voteCount: voteCount ? voteCount[1] : { UPVOTE: 0, DOWNVOTE: 0 },
    }
  }, {})

  return (
    <div className={classes.ghostVotingResultsList}>
      <div className={classes.ghostListBox}>
        <div className={classes.ghostList}>
          {combineGhostAndVoteCount.map((ghost) => (
            <div key={ghost.id} className={classes.ghostListItem}>
              <div>
                <div className={classes.ghostImageWrapper}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${ghost.id}.svg`}
                    alt={ghost.name}
                    className={classes.ghostImage}
                  />
                </div>
                <div className={classes.ghostDetails}>
                  <div className={classes.ghostNumber}>{`FILE NO.${ghost.fileNo}`}</div>
                  <div className={classes.ghostName}>{ghost.name}</div>
                  <div className={classes.voteCount}>
                    見かけた<span>{`${ghost.voteCount.UPVOTE}票`}</span>
                  </div>
                  <div className={classes.voteCount}>
                    見かけなかった<span>{`${ghost.voteCount.DOWNVOTE}票`}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles<Theme, { isLeader?: boolean }>(
  (theme: Theme) => ({
    ghostVotingResultsList: ({ isLeader }) => ({
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      borderRadius: 8,
      backgroundColor: constGhost.COLOR_WHITE,
      marginBottom: isLeader ? 86 : 16,
    }),
    ghostListBox: {
      paddingTop: 40,
      paddingBottom: 40,
      [theme.breakpoints.up('md')]: {
        paddingLeft: 120,
        paddingRight: 120,
      },
    },

    ghostList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    ghostListItem: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100% / 3)',
      padding: 24,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        padding: 16,
      },
    },
    ghostImage: {
      width: 148,
      height: 148,
      backgroundColor: constGhost.COLOR_WHITE,
      border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 148,
        height: 'auto',
      },
    },
    ghostNumber: {
      fontSize: 10,
      color: constGhost.COLOR_GRAY2,
      paddingTop: 8,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 4,
      },
    },
    ghostName: {
      fontSize: 13,
      fontWeight: 700,
      color: constGhost.COLOR_MAIN_NEW,
      paddingBottom: 12,
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    voteCount: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 150,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.6,
      color: constGhost.COLOR_BLACK3,
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        maxWidth: 130,
        width: '100%',
      },
      '& span': {
        color: constGhost.COLOR_MAIN_NEW,
      },
    },
  }),
  { name: 'GhostVotingResultsList' }
)
