import * as React from 'react'
import { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns'

import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { textToHtml } from 'utils/snitize'

import CocolaboAvatarSvg from '../../assets/cocolaboAvatar.svg'
import { notificationCtx } from '../PagesRoot'

import * as constants from 'assets/constants'




const Index: React.FC<RouteComponentProps<{ notificationId: string }>> = (props) => {
  const classes = useStyles()
  const notificationId = props.match.params.notificationId
  const { news, getNews, loading } = useContext(notificationCtx)

  React.useEffect(() => {
    getNews(notificationId)
  }, [notificationId, getNews])

  return (
    <>
      {news && !loading ? (
        <>
          <TitleWithBack title="お知らせ" />

          <div className={classes.container}>
            <div className={classes.headContainer}>
              <div className={classes.avatarBox}>
                <img src={CocolaboAvatarSvg} alt={'cocolaboIcon'} />
              </div>
              <div className={classes.infoBox}>
                <div className={classes.nickname}>{news.title}</div>
                <div className={classes.messageTitle}>{news.description}</div>
              </div>
            </div>
            <div className={classes.bodyContainer}>
              <div className={classes.time}>
                {news.createdAt ? format(new Date(news.createdAt), 'yyyy年MM月dd日 HH:mm') : '____'}
              </div>
              <div className={classes.messageBody}>{textToHtml(news.body)}</div>
            </div>
          </div>
        </>
      ) : (
        <CircularProgress size={34} style={{ position: 'absolute', top: '40%', left: '60%' }} />
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '24px 16px 0',
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },

  headContainer: {
    display: 'flex',
    backgroundColor: constants.COLOR_WHITE,
  },
  avatarBox: {
    position: 'relative',
    margin: '16px 16px 0',
    width: 52,
    height: 52,
    borderRadius: 26,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      verticalAlign: 'top',
    },
  },
  infoBox: {
    padding: '21px 16px 0 0',
    flex: 1,
    lineHeight: 1,
    wordBreak: 'break-all',
  },
  nickname: {
    marginTop: 1,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 16,
    fontWeight: 'bold',
  },
  messageTitle: {
    marginTop: 10,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
  },

  bodyContainer: {
    backgroundColor: constants.COLOR_WHITE,
    padding: '25px 16px 27px',
  },
  time: {
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 10,
  },
  messageBody: {
    marginTop: 17,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
  },
}))

export default Index
