import React from 'react'

import { Button, CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { constants } from 'assets'

type Props = {
  buttonBodyColor: 'white' | 'blue'
  type?: 'submit' | 'reset' | 'button'
  bodyText: React.ReactNode
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
}

export const DefaultSignUpButton: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { buttonBodyColor, type = 'button', bodyText, onClick, disabled, loading } = props

  return (
    <>
      <Button
        disabled={loading || disabled}
        type={type}
        variant="contained"
        className={classNames([classes.button, buttonBodyColor])}
        onClick={loading || disabled ? undefined : onClick}
        fullWidth
      >
        {!loading ? (
          <>{bodyText}</>
        ) : (
          <CircularProgress
            style={{
              color: constants.TEXT_WHITE,
            }}
            size={16}
          />
        )}
      </Button>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: (props: Props) => ({
      display: 'block',
      border: 'none',
      boxShadow: 'none',
      maxWidth: 250,
      height: 48,
      borderRadius: 30,
      fontSize: 14,
      fontWeight: 'bold',
      margin: '20px auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: props.type === 'submit' ? 250 : 200,
        margin: '0 12px',
      },
      '&.white': {
        backgroundColor: constants.COLOR_WHITE,
        color: constants.COLOR_MAIN_NEW,
        border: `2px solid ${constants.COLOR_MAIN_NEW}`,
        '&:hover': {
          backgroundColor: constants.COLOR_MAIN_NEW,
          color: constants.COLOR_WHITE,
        },
      },
      '&.blue': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: constants.COLOR_WHITE,
        border: `2px solid ${constants.COLOR_MAIN_NEW}`,
        '&:hover': {
          backgroundColor: constants.COLOR_WHITE,
          color: constants.COLOR_MAIN_NEW,
        },
      },
    }),
  }),
  { name: 'DefaultSignUpButton' }
)
