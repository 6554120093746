import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  number: number
}

export const QuestionNumber: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <span>Q{props.number}</span>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    width: 40,
    height: 40,
    borderRadius: 40,
    background: constants.COLOR_WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontFamily: 'Hiragino Kaku Gothic Pro',
      fontWeight: 'bold',
      fontSize: 16,
      paddingLeft: 2,
    },
  },
})
