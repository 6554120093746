import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { useOnbHistory } from 'pages/onboarding/navigation/route'

import { OnbContext } from '../pages/PagesRoot'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'




type Props = WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes }) => {
  const { teamId } = React.useContext(OnbContext)
  const history = useHistory()
  const onbHistory = useOnbHistory()

  return (
    <div className={classes.Hfooter}>
      <div className="logoHouseIcon">
        <img
          className={classes.mainlogo}
          src={process.env.PUBLIC_URL + '/assets/svg/onbordingIcon.svg'}
          alt={'close'}
          onClick={() => history.push(constants.PAGE_TOP)}
        />
        <span className={classes.backToTop} onClick={() => onbHistory.push(OnbPages.Timeline, { teamId })}>
          タイムラインへ戻る
        </span>

        <div className="footerIcons">
          <div className="facebookIcon">
            <a href="https://www.facebook.com/cocolabo.club/" target="_blank" rel="noopener noreferrer">
              <img src={process.env.PUBLIC_URL + '/assets/svg/facebook.svg'} alt={'close'} />
            </a>
          </div>

          <div className="twitterIcon">
            <a href="https://twitter.com/cocolabo_club" target="_blank" rel="noopener noreferrer">
              <img src={process.env.PUBLIC_URL + '/assets/svg/twitter.svg'} alt={'close'} />
            </a>
          </div>
        </div>
      </div>

      <div className="copyrightIcon">
        <span>&copy;NEWONE,Inc.</span>
      </div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    backToTop: {
      textDecoration: 'underline',
      textDecorationStyle: 'solid',
      textDecorationThickness: 2,
      fontWeight: 'bold',
      marginLeft: 30,
      cursor: 'pointer',
    },
    mainlogo: {
      cursor: 'pointer',
    },
    Hfooter: {
      height: '155px',
      '& .footerIcons': {
        display: 'flex',
        '& .facebookIcon': {
          cursor: 'pointer',
          marginLeft: 24,
          '& .img': {
            width: '24px',
          },
        },
        '& .twitterIcon': {
          cursor: 'pointer',
          marginLeft: 24,
          paddingTop: 5,
          '& .img': {
            width: '24px',
          },
        },
      },
      '& .logoHouseIcon': {
        marginBottom: 11,
        display: 'flex',
        paddingTop: 20,
        marginLeft: 50,
      },
      '& .copyrightIcon': {
        color: constants.TEXT_GRAY_DARK,
        marginLeft: 220,
        fontSize: '12px',
        '& span': {},
      },
    },
  })

export const FooterIcon = withStyles(useStyles)(Index)
