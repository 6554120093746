import * as React from 'react'

import { Control } from 'react-hook-form'

import { useCurrentTeamMember, useTeamMembers } from 'pages/teams/hooks'
import { Feature, FeatureStatus, TeamMember } from 'utils/generated'
import { IceBreakContents, IceBreakPreparePresentation } from 'utils/types'

import { useManageIceBreak, useStartIceBreak } from '../hooks/iceBreak'

type Props = {
  teamId: string | undefined
  iceBreakId: string | undefined
  teamMembers: TeamMember[]
  isLoadingMembers: boolean
  own: TeamMember | undefined
  isLoadingMember: boolean
  iceBreak: Feature | undefined
  iceBreakContents: IceBreakContents
  isRefreshed: boolean
  isLoading: boolean
  isLeader: boolean
  handleCancelLeader: () => Promise<void>
  handleCancelMember: () => Promise<void>
  handleUpdateByLeader: ({ status, contents }: { status?: FeatureStatus; contents?: IceBreakContents }) => Promise<void>
  iceBreakTheme: string | undefined
  setIceBreakTheme: React.Dispatch<React.SetStateAction<string | undefined>>
  control: Control<IceBreakPreparePresentation>
  memberCount: number
  minute: number
  selectUserIds: (existingIds?: string[]) => string[]
  isTimerEnd: boolean
  setIsTimerEnd: React.Dispatch<React.SetStateAction<boolean>>
  isCanceling: boolean
  histories: Feature[] | undefined
  isRefreshedHistories: boolean
}

export const useIceBreakContext = (ids: { teamId: string; iceBreakId: string }) => {
  const [teamId, setTeamId] = React.useState<string>()
  const [iceBreakId, setIceBreakId] = React.useState<string>()

  const { teamMember: own, loading: isLoadingMember } = useCurrentTeamMember(ids.teamId)
  // NOTE: 既存の limit 数である 500 を超える可能性が出たら、more 処理による teamMembers の取得を実装する。
  const { teamMembers, loading: isLoadingMembers } = useTeamMembers(ids.teamId)
  const { histories, isRefreshed: isRefreshedHistories } = useStartIceBreak({ teamId: ids.teamId })
  const {
    iceBreak,
    iceBreakContents,
    isRefreshed,
    isLoading,
    isLeader,
    handleCancelLeader,
    handleCancelMember,
    handleUpdateByLeader,
    iceBreakTheme,
    setIceBreakTheme,
    control,
    memberCount,
    minute,
    selectUserIds,
    isTimerEnd,
    setIsTimerEnd,
    isCanceling,
  } = useManageIceBreak({
    teamId: ids.teamId,
    iceBreakId: ids.iceBreakId,
    ownUserId: own?.userId,
  })

  React.useEffect(() => {
    setTeamId(ids.teamId)
    setIceBreakId(ids.iceBreakId)
  }, [ids])

  return {
    teamId,
    iceBreakId,
    teamMembers,
    isLoadingMembers,
    histories,
    isRefreshedHistories,
    own,
    isLoadingMember,
    iceBreak,
    iceBreakContents,
    isRefreshed,
    isLoading,
    isLeader,
    handleCancelLeader,
    handleCancelMember,
    handleUpdateByLeader,
    iceBreakTheme,
    setIceBreakTheme,
    control,
    memberCount,
    minute,
    selectUserIds,
    isTimerEnd,
    setIsTimerEnd,
    isCanceling,
  }
}

export const IceBreakContext = React.createContext<Props>({} as Props)
