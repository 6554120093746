import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { TeamMember } from 'utils/generated'

import { constants } from 'assets'

type MemberCardsProps = {
  members: TeamMember[]
  activeMemberIds: string[]
} & CardBaseProps

type CardProps = {
  member: TeamMember
  isActive: boolean
} & CardBaseProps

type CardBaseProps = {
  ownUserId: string | undefined
  leaderUserId: string
}

export const MemberCards: React.FC<MemberCardsProps> = ({ members, activeMemberIds, ...props }) => {
  const classes = useStyles()

  // アクティブなメンバーが先頭にくるようソート
  const sortedMembers = members.slice().sort((x, y) => {
    const xIndex = activeMemberIds.indexOf(x.userId)
    const yIndex = activeMemberIds.indexOf(y.userId)

    // activeMemberIds に含まれる要素は先頭に、含まれない要素はそのままの順番に並べる
    if (xIndex !== -1 && yIndex !== -1) {
      return xIndex - yIndex // 両方 activeMemberIds に含まれる場合はその順番に従う
    } else if (xIndex !== -1) {
      return -1 // x は activeMemberIds に含まれるので、先頭に移動
    } else if (yIndex !== -1) {
      return 1 // y は activeMemberIds に含まれるので、先頭に移動
    } else {
      return 0 // 両方含まれない場合はそのまま
    }
  })

  return (
    <div className={classes.container}>
      <div className={classes.countBox}>
        <div className={classes.countText}>参加メンバー</div>
        <div className={classes.countText}>{activeMemberIds.length}人</div>
      </div>
      <div className={classes.membersWrapper}>
        {sortedMembers.map((member) => (
          <Card key={member.userId} member={member} isActive={activeMemberIds.includes(member.userId)} {...props} />
        ))}
      </div>
    </div>
  )
}

const Card: React.FC<CardProps> = ({ member, isActive, ownUserId, leaderUserId }) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        classes.cardWrapper,
        isActive ? 'active' : 'inactive',
        member.userId === ownUserId && 'own'
      )}
    >
      <img
        className={classes.memberIcon}
        src={process.env.PUBLIC_URL + '/assets/ghost/personIcon.svg'}
        alt={'person icon'}
      />
      <div className={classes.memberInfo}>
        <p className={classes.memberName}>{member.fullName}</p>
        {leaderUserId === member.userId && <p className={classes.memberLabel}>テーマ設定担当</p>}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // MemberCards
    container: {
      padding: '24px 16px',
      backgroundColor: '#fff',
      borderRadius: 8,
      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
    },
    countBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    countText: {
      fontWeight: 'bold',
      paddingBottom: 16,
      color: constants.TEXT_GRAY_DARK,
    },
    membersWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
    },

    // Card
    cardWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: 8,
      padding: '16px',
      width: '100%',
      border: `solid 1px ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 8,
      [theme.breakpoints.up('sm')]: {
        width: 'calc(50% - 8px)', //8px is a half of gap
      },
      '&.active': {
        backgroundColor: '#fff',
        color: constants.TEXT_GRAY_DARK,
      },
      '&.inactive': {
        backgroundColor: constants.COLOR_GRAY4,
        color: constants.COLOR_GRAY_DARK2,
      },
      '&.own': {
        border: `solid 1px ${constants.COLOR_MAIN_NEW}80`,
      },
    },
    memberIcon: {
      width: 32,
    },
    memberInfo: {
      flex: 1,
    },
    memberName: {
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
      minWidth: 0,
      overflowWrap: 'anywhere',
    },
    memberLabel: {
      display: 'inline-block',
      margin: '4px 0 0',
      padding: '2px 8px',
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: '#fff',
      fontSize: 10,
      fontWeight: 'bold',
      borderRadius: 20,
    },
  }),
  { name: 'MemberCards' }
)
