import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { Team, TeamBuilding, TeamBuildingV1ResultQ3, TeamBuildingV1ResultQ4 } from 'utils/generated'

import { Logo, QuestionContainer, InputCard, InputCardTitle, ButtonContainer, Button } from '../../components'

import { constants } from 'assets'

type Props = { currentTeam?: Team; teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Completed: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuilding } = React.useContext(HooksContext)

  const handleOnClose = () => {
    if (props.currentTeam) {
      window.close()
    }
  }

  const resultQ3 = JSON.parse(teamBuilding?.resultQ3 ?? '') as TeamBuildingV1ResultQ3
  const resultQ4 = JSON.parse(teamBuilding?.resultQ4 ?? '') as TeamBuildingV1ResultQ4

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <QuestionContainer addPaddingSide addMarginBottom>
        <Logo container />

        <div style={{ marginBottom: 20 }}>
          <InputCard>
            <InputCardTitle>{props.currentTeam?.name}</InputCardTitle>
            <div className={classes.description}>
              <p>おつかれさまでした。キックオフの基本設定が完了しました。</p>
              <p>
                これからみんなで設定したテーマをスケジュールに合わせて実施し、理想的なチームを作り上げていってください。
              </p>
            </div>
          </InputCard>
        </div>

        <InputCard>
          <InputCardTitle>結果</InputCardTitle>
          <div className={classes.textWrapper}>
            <div className={classes.title}>チーム目標</div>
            <div className={classes.description}>
              <p>{resultQ3.answer}</p>
            </div>
          </div>
          <div className={classes.textWrapper}>
            <div className={classes.title}>行動規範</div>
            <div className={classes.description}>{resultQ4.items.map((i) => `・${i.answer}`).join('\n')}</div>
          </div>
        </InputCard>
        <ButtonContainer
          buttons={[
            <Button key={'complete-1'} onClick={handleOnClose}>
              終了する
            </Button>,
          ]}
          fixed
        />
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  logoContainer: {
    marginBottom: 40,
  },

  title: {
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold',
    marginBottom: 10,
  },

  description: {
    color: constants.TEXT_GRAY_DARK,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    letterSpacing: '0.01em',
    lineHeight: '24px',
    whiteSpace: 'pre-wrap',
  },
  textWrapper: {
    background: '#F9F9F9',
    padding: '19px 16px 10px 16px',
    borderRadius: 8,
    marginBottom: 13,
  },
}))
