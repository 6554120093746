import React, { FC } from 'react'

import { Fade, Modal } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

type Props = {
  isModalOpen: boolean
  handleModalClose: () => void
  children: JSX.Element
}

export const MuiModal: FC<Props> = ({ isModalOpen, handleModalClose, children }) => {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={handleModalClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Fade in={isModalOpen}>{children}</Fade>
    </Modal>
  )
}

const useStyles = makeStyles(
  () => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    },
  }),
  { name: 'MuiModal' }
)
