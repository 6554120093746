import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import checkedIcon from '../../assets/svg/checkedIcon.svg'
import { Flexbox, SpeechBubble, Imgcard, Font } from '../atoms'

type Props = {
  step: number
  order: number
  length: number
  content: string
}

export const ProgressItem: React.FC<Props> = ({ step, order, content, length }) => {
  const classes = useStyles({})
  const current = order === step
  const isNext = order > step
  const isLast = order === length

  return (
    <Flexbox ownStyles={{ marginBottom: 24, position: 'relative' }}>
      {current || isNext ? (
        <div className={classes.stepCircle}>
          <Font
            fontSize={9}
            ownStyles={{
              color: constGhost.COLOR_WHITE,
              margin: '5px 2px -15px', // this 2px is used by windows layout
            }}
          >
            STEP
          </Font>
          <Font fontSize={16} ownStyles={{ color: constGhost.COLOR_WHITE, fontWeight: 700, paddingTop: 5 }}>
            0{order}
          </Font>
        </div>
      ) : (
        <Imgcard src={checkedIcon} alt={'checked'} ownStyles={{ height: 40, width: 40 }} />
      )}
      {isLast ? <></> : <div className={classes.verticalLine}></div>}
      <SpeechBubble
        content={content}
        positionOfTail={'left'}
        backgroundColor={current ? constGhost.COLOR_MAIN_NEW : constGhost.COLOR_LIGHTGRAY2}
        ownStyles={{
          width: 'calc(100% - 48px)',
          margin: '0 0 0 auto',
          fontWeight: current ? 'bold' : undefined,
          color: current ? constGhost.COLOR_WHITE : isNext ? constGhost.COLOR_BLACK3 : constGhost.COLOR_GRAYTEXT,
        }}
      />
    </Flexbox>
  )
}

const useStyles = makeStyles(
  {
    stepCircle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      backgroundColor: constGhost.COLOR_MAIN_NEW,
      borderRadius: 20,
      height: 40,
      width: 40,
    },
    verticalLine: {
      position: 'absolute',
      height: 50,
      width: 1,
      top: 43,
      left: 20,
      backgroundColor: constGhost.COLOR_MAIN_NEW,
    },
  },
  { name: 'ProgressItem' }
)
