import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  children: React.ReactNode
}

export const DiagonalGradient: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.children}</div>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: constants.COLOR_MAIN,
    background: `linear-gradient(-${
      Math.atan2(window.innerHeight - constants.HEADER_HEIGHT, window.innerWidth) * (180 / Math.PI)
    }deg, ${constants.COLOR_MAIN_DARK} 0%, ${constants.COLOR_MAIN_DARK} 50%, transparent 50%)`,
  },
}))
