import * as React from 'react'
import { Link } from 'react-router-dom'

import IconButton from '@mui/material/IconButton'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { constants } from 'assets'

type StyleProps = {
  gap?: number
}

type Props = {
  path: string
  text: React.ReactNode
} & StyleProps

export const ArrowLinkButton: React.FC<Props> = ({ path, text, gap }) => {
  const classes = useStyles({ gap })

  return (
    (<Link to={path}>
      <div className={classNames([classes.linkBox])}>
        <IconButton className={classes.iconButton} size="large">
          <img
            src={`${process.env.PUBLIC_URL}/assets/landing/features/arrow_forward.svg`}
            alt="アローボタン"
            className={classes.arrow}
          />
        </IconButton>
        {text}
      </div>
    </Link>)
  );
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    linkBox: (props) => ({
      display: 'flex',
      alignItems: 'center',
      gap: props.gap ?? 16,

      '&:hover': {
        textDecoration: 'underline',
      },
    }),
    iconButton: {
      padding: 0,
      width: 36,
      height: 36,
    },
    arrow: {
      width: '100%',
    },
    linkText1: {
      margin: 0,
      fontSize: 14,
      lineHeight: 1,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
    linkText2: {
      margin: '10px 0 0',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1,
    },
  }),
  { name: 'TabContent' }
)
