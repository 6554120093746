import * as React from 'react'

import { Box } from '@mui/material'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
const ListArticleWrapper = (props: IProps) => {
  return (
    <Box style={{ listStyle: 'none' }} px={{ xs: '15px', lg: '24px' }} py={{ xs: '35px', lg: '32px' }}>
      {props.children}
    </Box>
  )
}

const ListInWrapper = (props: IProps) => {
  return (
    <Box width="100%" boxSizing="border-box" display="flex" alignItems="flex-start" justifyContent="space-between">
      {props.children}
    </Box>
  )
}

const ContentWrapper = (props: IProps) => {
  return <Box>{props.children}</Box>
}

export { ListArticleWrapper, ListInWrapper, ContentWrapper }
