import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { CircularProgress } from 'components/CircularProgress'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { useGhostTeam } from 'pages/ghost/contexts/ghostTeam'
import { useGetGhostHistoriesByMaster } from 'pages/ghost/hooks/getGhostHistoriesByMaster'
import { useTeamMembers } from 'pages/teams/hooks'

import { FilterMemberNameInput } from './FilterMemberNameInput'
import { GhostHistorySelect } from './GhostHistorySelect'
import { SelectedGhostsTableForPc } from './SelectedGhostsTableForPc'
import { SelectedGhostsTableForSp } from './SelectedGhostsTableForSp'
import { useFormatGhostHistories, useGetCompletedHistories } from './useFormatGhostHistories'

import { constants } from 'assets'

export type TableFilter = {
  targetMemberName: string
  allTargetHistoryId: string
  memberHistory: { memberId: string; targetHistoryId: string }
}

export const MembersList: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')
  const [filter, setFilter] = React.useState<TableFilter>({
    targetMemberName: '',
    allTargetHistoryId: '',
    memberHistory: {
      memberId: '',
      targetHistoryId: '',
    },
  })

  // ----------------------------
  // テーブル表示に必要なデータを用意
  const { teamId } = useParams<{ teamId: string }>()
  const { teamMembers, loading: isLoadingMembers } = useTeamMembers(teamId)
  const { team: ghostTeam, loading: isLoadingGhostTeam } = useGhostTeam(teamId)
  const { ghostHisotries, isLoadingHistories } = useGetGhostHistoriesByMaster({
    masterTeamId: teamId,
    fetchAllData: true,
  })
  const { completedHistories } = useGetCompletedHistories(ghostHisotries, ghostTeam)
  const membersGhostHistories = useFormatGhostHistories(teamMembers, completedHistories, filter)
  const isLoading = isLoadingMembers || isLoadingGhostTeam || isLoadingHistories
  // ----------------------------

  return (
    <div className={classes.root}>
      <div className={classes.tableMenuContainer}>
        <p>みんなが何を選んだのか閲覧できます。</p>
        <div className={classes.tableFilterMenus}>
          <FilterMemberNameInput memberName={filter.targetMemberName} setFilter={setFilter} />
          <GhostHistorySelect setFilter={setFilter} completedHistories={completedHistories} />
        </div>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className={classes.tableContainer}>
          {isMdUp ? (
            <SelectedGhostsTableForPc membersGhostHistories={membersGhostHistories} setFilter={setFilter} />
          ) : (
            membersGhostHistories.map((memberGhostHistories) => (
              <SelectedGhostsTableForSp
                key={memberGhostHistories.teamMemberId}
                memberGhostHistories={memberGhostHistories}
                setFilter={setFilter}
              />
            ))
          )}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
    },

    tableMenuContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: 16,
      marginBottom: 24,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: 34,
        marginBottom: 16,
      },
      '& p': {
        margin: 0,
        fontSize: 12,
        [theme.breakpoints.down('md')]: {
          margin: 0,
          fontSize: 10,
        },
      },
    },

    tableFilterMenus: {
      display: 'flex',
      gap: 16,
      marginLeft: 'auto',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 8,
        marginLeft: 0,
      },
    },

    tableContainer: {
      [theme.breakpoints.up('md')]: {
        maxHeight: 524,
        overflowY: 'auto',
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      },
    },
  }),
  { name: 'MembersList' }
)
