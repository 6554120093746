import React from 'react'
import { Link } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  title: string
  to: string
}

export const SimpleLink: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <Link to={props.to} className={classes.link}>
      {props.title}
    </Link>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: constants.COLOR_MAIN,
    textDecoration: 'underline',
  },
}))
