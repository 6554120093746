import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Logo from 'components/icon/Logo'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants, Pages } from 'assets'

export const headerHeight = { pc: 64, sp: 64 }

export const Header: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const isLanding = location.pathname.match(`${Pages.Hataraku}/?$`)
  const isResults = location.pathname.match(`${Pages.Hataraku}/results/?`)
  const isMdUp = useCustomMediaQuery('up', 'md')

  return (
    <>
      <header className={classes.header}>
        {isLanding ? (
          <div className={classes.landingMenu}>
            <Logo width={140} height={18} />
          </div>
        ) : (
          <div className={classes.diagnosisMenu}>
            {isMdUp && (
              <div>
                <Logo width={140} height={18} />
                <span>カルテ</span>
              </div>
            )}
            {isResults ? (
              <a href={Pages.Hataraku} target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/img/hataraku/logo-hataraku.svg`} alt="はたらく価値観診断" />
              </a>
            ) : (
              <img src={`${process.env.PUBLIC_URL}/img/hataraku/logo-hataraku.svg`} alt="はたらく価値観診断" />
            )}
          </div>
        )}
      </header>
      <div className={classes.headerSpace}></div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      position: 'fixed',
      top: '0',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: headerHeight.pc,
      padding: '0 24px',
      backgroundColor: '#fff',
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.HATARAKU_FONT_FAMILY,
      boxShadow: '0 2px 4px rgba(0, 0, 0, .16)',
      zIndex: 1100,
      [theme.breakpoints.down('md')]: {
        height: headerHeight.sp,
        padding: '0 16px',
      },
    },
    landingMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 24,
      width: '100%',
      maxWidth: 1000,
      height: '100%',
    },
    diagnosisMenu: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1000,
      height: '100%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      '& span': {
        position: 'relative',
        top: -2,
        marginLeft: 8,
        fontSize: 16,
        fontWeight: 'bold',
      },
      '& img': {
        width: 234,
        [theme.breakpoints.up('md')]: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
    },
    headerSpace: {
      height: headerHeight.pc,
      [theme.breakpoints.down('md')]: {
        height: headerHeight.sp,
      },
    },
  }),
  { name: 'Header' }
)
