import * as React from 'react'

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { requierdMarkStyles, selectArrowIconStyles } from '../assets/documents.data'
import * as constants from 'assets/constants'

type Props = {
  formId: string
}

export const BREAKPOINT = 1080 - 200
export const HubSpotForm: React.FC<Props> = ({ formId }) => {
  const classes = useStyles()
  const PORTAL_ID = process.env.REACT_APP_HUBSPOT_POST_ID
    ? JSON.parse(process.env.REACT_APP_HUBSPOT_POST_ID).toString()
    : '00000000'
  useHubspotForm({
    portalId: PORTAL_ID,
    formId: formId,
    target: '#my-hubspot-form',
  })

  React.useEffect(() => {
    let attempts = 0
    const maxAttempts = 20 // フォームが表示されるまで、最大10秒待機

    const changeButtonText = () => {
      // クラス名を使用して送信ボタンを取得
      const submitButton = document.querySelector('.hs-button.primary.large')
      if (submitButton) {
        try {
          // 送信ボタンとなっている input の value属性を変更
          submitButton.setAttribute('value', 'ダウンロード')
          return true
        } catch (e) {
          return false
        }
      }
      return false
    }

    const interval = setInterval(() => {
      attempts++
      if (changeButtonText()) {
        clearInterval(interval)
      } else if (attempts >= maxAttempts) {
        clearInterval(interval)
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <div>
      {process.env.REACT_APP_HUBSPOT_POST_ID ? (
        <div className={classes.hubSpotFormRoot} id="my-hubspot-form"></div>
      ) : (
        <div style={{ textAlign: 'center', width: '100%', margin: '54px 0 0', fontSize: 16 }}>
          ページを再読み込みしてください
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  hubSpotFormRoot: {
    margin: '0 24px 48px',

    // 必須ラベル左の「*」を非表示にする
    '& .hs-form-required': {
      display: 'none',
    },

    '& .hs-form': {
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
    },
    '& fieldset': {
      width: '100% !important',
      maxWidth: 'none !important',
    },
    '& .form-columns-1': {
      width: '100% !important',
    },
    '& .form-columns-2': {
      width: '100% !important',

      [theme.breakpoints.up('sm')]: {
        width: '100%',
        display: 'flex',
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },

    // 共通
    '& .field': {
      maxWidth: BREAKPOINT,
      marginTop: 40,
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: '100%',
      },
    },

    '& .hs_company': {
      '& .hs-input': {
        width: '100% !important',
      },
    },
    '& .hs_departmenttitle': {
      '& .hs-input': {
        width: '100% !important',
      },
    },
    '& .hs_email': {
      '& .hs-input': {
        width: '100% !important',
      },
    },
    '& .hs_phone': {
      '& .hs-input': {
        width: '100% !important',
      },
    },
    '& .hs_numemployees': {
      '& .hs-input': {
        width: '100% !important',
      },
    },

    '& label': {
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
      position: 'relative',
    },
    '& label[id^="label-"]::after': {
      ...requierdMarkStyles,
      position: 'absolute',
      top: 1,
      right: -32,
    },
    '& .hs-form-field .hs-field-desc.legend': {
      fontWeight: 300,
    },
    '& .hs-error-msg': {
      display: 'none',
    },
    '& .input': {
      margin: '16px 0 0',
    },
    '& .hs-input': {
      width: '100%',
      padding: 16,
      border: `1px solid ${constants.BORDER_LIGHTGRAY}`,
      borderRadius: 8,
      fontSize: 14,
      '&:focus-visible': {
        outlineColor: constants.COLOR_MAIN_NEW,
      },
    },
    '& .hs_jobtitle__c>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },
    '& .hs_lastname.field': {
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: 'calc(50% - 12px)',
        margin: '32px 12px 0 0',
      },
    },
    '& .hs_firstname.field': {
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: 'calc(50% - 12px)',
        margin: '32px 0 0 12px',
      },
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& .hs-form-radio-display': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&>span': {
        fontWeight: 'normal',
      },
    },
    '& select': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: ' none',
    },
    '& .hs_numemployees>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },
    '& .hs-input[id^="cocolabo_examination_situation"]': {
      width: 'auto',
      margin: '0 8px 0 0',
    },
    '& .hs-form-booleancheckbox-display': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&>span': {
        fontWeight: 'normal',
      },
      '& a': {
        color: constants.COLOR_MAIN_NEW,
      },
    },
    '& .hs-input[id^="agree_personal_information"]': {
      width: 'auto',
      margin: '0 8px 0 0',
    },
    '& .hs_background>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },

    '& li': {
      position: 'relative',
    },

    /// エラーメッセージ共通
    '& .hs-error-msgs label': {
      color: constants.COLOR_ALERT,
      marginRight: 'auto',
      marginBottom: 20,
    },

    /// 会社/組織名のエラーメッセージ
    '& .hs_company .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// 所属のエラーメッセージ
    '& .hs_departmenttitle .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// お役職のエラーメッセージ
    '& .hs_jobtitle__c .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// 姓のエラーメッセージ
    '& .hs_lastname .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// 名のエラーメッセージ
    '& .hs_firstname .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// メールアドレスのエラーメッセージ
    '& .hs_email .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 電話番号のエラーメッセージ
    '& .hs_phone .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 従業員数のエラーメッセージ
    '& .hs_numemployees .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// お申し込みのきっかけのエラーメッセージ
    '& .hs_background .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 個人情報の取り扱いのエラーメッセージ
    '& .hs_agree_personal_information .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
    },

    //「全ての必須項目に入力してください」を非表示にする
    '& .hs_error_rollup label': {
      display: 'none',
    },

    // 個人情報リンクを太字にする
    '& .hs_agree_personal_information a': {
      fontWeight: 'bold !important',
    },

    '& .hs-main-font-element': {
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 'calc(50% - 120px)',
    },

    // 「reCAPTCHA で保護されています」を非表示にする
    '& .hs_recaptcha': {
      display: 'none',
    },

    // 最下部の送信ボタン関連
    '& .hs_submit': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: 40,
    },
    '& .actions': {
      width: '100%',
      maxWidth: 252,
    },
    '& .hs-button': {
      display: 'block',
      width: 252,
      height: 50,
      margin: '0 auto',
      border: `2px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 25,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      boxShadow: '0 3px 6px 0 #0000001E',
      '&:hover': {
        cursor: 'pointer',
        animation: '$hoverAnime 300ms cubic-bezier(0,0.5,0.5,1) 0s 1 normal forwards running',
        '@global': {
          '@keyframes hoverAnime': {
            '0%': {},
            '100%': {
              color: constants.COLOR_ONBOARDING_MAIN,
              backgroundColor: constants.COLOR_WHITE,
            },
          },
        },
      },
    },
  },
}))
