import * as React from 'react'

import { Theme } from '@mui/material/styles'
import { WithStyles, StyleRules } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import 'dayjs/locale/ja'

import * as constants from '../../../../assets/constants'

interface IOwnProps {
  name: string
  value: string | any
  label?: string
  register: any
  setValue: any
  onChange?: any
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      props.setValue(props.name, date)
    }

    if (props.onChange) {
      props.onChange(date)
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DatePicker
        format="YYYY/MM/DD"
        label={props.label || '日付'}
        defaultValue={dayjs(new Date())}
        onChange={handleDateChange}
        name={props.name}
        ref={props.register}
        inputRef={props.register}
        className={classes.main}
        minDate={dayjs(new Date())}
        desktopModeMediaQuery="@media (min-width:0px)"
        slotProps={{
          textField: {
            sx: {
              height: '42px',
              margin: '16px 0px 20px',
              padding: '4px 4px 4xp 16px',
              '& .MuiIconButton-root': {
                marginRight: '2px',
                padding: '12px',
              },
            },
          },
          popper: {
            className: classes.popover,
            placement: 'bottom',
          },
        }}
      />
    </LocalizationProvider>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      width: '100%',

      '& .MuiFormLabel-root': {
        color: constants.COLOR_MAIN,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${constants.COLOR_MAIN} !important`,
      },
      '& .MuiButtonBase-root': {
        color: `${constants.COLOR_MAIN} !important`,
      },
    },
    popover: {
      '& .MuiPickersDay-today': {
        color: constants.COLOR_MAIN,
        border: 'none',
        boxShadow: 'none',
      },
      '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: constants.COLOR_MAIN,
        color: constants.TEXT_GRAY_DARK,
      },
      '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: constants.COLOR_MAIN,
        color: constants.TEXT_GRAY_DARK,
      },
    },
  })

export default withStyles(useStyles)(Index)
