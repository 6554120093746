import * as React from 'react'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton } from 'components/NoStyleButton'
import reactionsOpen from 'pages/onboarding/assets/reactions_open.svg'

import { useReactionSelections, ReactionProps } from './hooks/useReactionSelections'

import * as constants from 'assets/constants'

export const ReactionSelections = React.memo((props: ReactionProps) => {
  const classes = useStyles()
  const { open, visible, anchorRef, reactionNumbers, handleToggle, handleClose, handleReactionClick } =
    useReactionSelections({
      ...props,
    })

  if (!visible) {
    return <></>
  }

  return (
    <div>
      <div className={classes.openWrapper}>
        <div ref={props.ttOnLike ? (props.ttRef as React.MutableRefObject<HTMLDivElement>) : null}>
          <NoStyleButton onClick={handleToggle} ref={anchorRef}>
            <img className={classes.openIcon} src={reactionsOpen} alt="reactions open icon" />
          </NoStyleButton>
        </div>
      </div>
      <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps}>
              <div className={classes.selectionsWrapper}>
                {reactionNumbers.map((reactionNum) => (
                  <div key={`reaction-number-${reactionNum}`}>
                    <NoStyleButton className={classes.selectIcon} onClick={handleReactionClick} data-icon={reactionNum}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/svg/onboarding/reaction_${reactionNum}.svg`}
                        alt={`reaction icon No.${reactionNum}`}
                      />
                    </NoStyleButton>
                  </div>
                ))}
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  )
})

const useStyles = makeStyles(
  (theme: Theme) => ({
    openWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    openIcon: {
      verticalAlign: 'top',
    },
    popper: {
      zIndex: 12000, // more than tutorial tooltip ( z-index: 11400 - 11600 )
    },
    selectionsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '8px',
      padding: '14px 16px',
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      boxShadow: '0px 3px 6px #00000029',
    },
    selectIcon: {
      borderRadius: 4,
      '&:hover': {
        backgroundColor: `${constants.COLOR_MAIN_NEW}4D`,
      },
    },
  }),
  { name: 'ReactionSelections' }
)
