import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  backgroundColor: 'white' | 'lightGray' | 'lightYellow' | 'gray' | 'blue' | 'lightBlue' | 'lightGray2' | 'lightGray3' // only use lightGrey and white
  borderRadius?: number | 'none'
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  id?: string
  ownStyles?: React.CSSProperties
  noShadow?: boolean
  children: React.ReactNode
}

export const UsefulCard: React.FC<Props> = ({
  backgroundColor,
  borderRadius = 8,
  onClick,
  id,
  ownStyles,
  noShadow,
  children,
}) => {
  const classes = useStyles({ noShadow })

  return (
    <div
      className={classNames([classes.usefulCard, backgroundColor])}
      style={{ borderRadius: borderRadius, ...ownStyles }}
      onClick={(event) => {
        if (onClick) onClick(event)
      }}
      id={id}
    >
      {children}
    </div>
  )
}

interface StyleProps {
  noShadow?: boolean
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    usefulCard: (props: StyleProps) => ({
      boxShadow: props.noShadow ? undefined : `0px 1px 6px 0px ${constGhost.COLOR_BLACK}`,
      '&.white': {
        backgroundColor: constGhost.COLOR_WHITE,
        boxShadow: 'none',
      },
      '&.lightGray': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY2,
        boxShadow: 'none',
      },
      '&.lightYellow': {
        backgroundColor: constGhost.COLOR_LIGHTYELLOW,
        boxShadow: `0px 1px 6px 0px ${constGhost.COLOR_YELLOWSHADOW}`,
      },
      '&.gray': {
        backgroundColor: '#D3D3D3',
        boxShadow: 'none',
      },
      '&.blue': {
        backgroundColor: constGhost.COLOR_MAINBLUE,
      },
      '&.lightBlue': {
        backgroundColor: constGhost.COLOR_LIGHTBLUE,
        boxShadow: 'none',
      },
      '&.lightGray2': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY2,
      },
      '&.lightGray3': {
        backgroundColor: constGhost.COLOR_LIGHTGRAY3,
      },
    }),
  }),
  { name: 'UsefulCard' }
)
