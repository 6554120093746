import React, { useEffect, useState, useCallback, useMemo, SetStateAction } from 'react'

import { Theme, useMediaQuery, useTheme, ClickAwayListener } from '@mui/material';
import { ClassNameMap } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import format from 'date-fns/format'

import { OnboardingDailyChecks } from 'pages/onboarding/utils/onboardingDailyCheck'
import { OnboardingPost, OnboardingPostType } from 'utils/generated'
import { OnboardingDaily } from 'utils/generated'

import { WhiteCard } from '../../../components/atoms'

import { HandleDetailOpenText } from './detailBox/HandleDetailOpenText'

import checkedIcon from '../assets/checkedIcon.svg'
import filterIcon from '../assets/filterIcon.svg'
import upAndDownArrow from '../assets/upAndDownArrow.svg'
import * as constants from 'assets/constants'

type Props = {
  posts: OnboardingPost[]
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type DailyCheckSummary = {
  // use it in this page
  achivementDay: string
  dailyCheck: string
}

const actionsWrapperId = 'actionsWrapper'
const columns = ['達成日', 'デイリーチェック'] as const
const orders = ['昇順', '降順'] as const
type Columns = typeof columns[number]
type Orders = typeof orders[number]
const isTypeColumn = (arg: unknown): arg is Columns => Boolean(columns.find((item) => item === arg))
const isTypeOrders = (arg: unknown): arg is Orders => Boolean(orders.find((item) => item === arg))

export const DailyCheckBox: React.FC<Props & StyleProps> = ({ posts, ownStyles }) => {
  //helpers-->

  const calcDailyCheckData = useCallback((): DailyCheckSummary[] => {
    const returnValue = [] as DailyCheckSummary[]
    const filterItems = posts.filter((item) => item.type === OnboardingPostType.Daily)
    for (const filterItem of filterItems) {
      returnValue.push({
        achivementDay: filterItem?.createdAt ? format(new Date(filterItem?.createdAt), 'Y年MM月dd日') : '-',
        dailyCheck: filterItem?.daily ?? '-',
      })
    }
    return returnValue
  }, [posts])

  const initActionsData = useMemo(
    () => calcDailyCheckData(),
    //because of rejecting more re-calc
    // eslint-disable-next-line
    []
  )

  //<--helpers
  // handle data and UI -->
  //arrange actions data
  const [actionsData, setActionsData] = useState<DailyCheckSummary[]>([])
  useEffect(() => {
    setActionsData(initActionsData)
  }, [initActionsData])

  //handle appear details for tab
  const [visibleActionOverView, setVisibleActionOverView] = useState<boolean>(false)

  // handle filter action by categories
  const [visibleFilter, setVisibleFilter] = useState<boolean>(false)
  const handleVisibleFilter = () => {
    setVisibleFilter((prev) => !prev)
  }

  const categoriesText: string[] = [
    OnboardingDailyChecks(OnboardingDaily.GreatShade),
    OnboardingDailyChecks(OnboardingDaily.BadPrivateTime),
    OnboardingDailyChecks(OnboardingDaily.BadWorkProgress),
    OnboardingDailyChecks(OnboardingDaily.BadHumanRelation),
    OnboardingDailyChecks(OnboardingDaily.BadFeeling),
    OnboardingDailyChecks(OnboardingDaily.BadFeelingSomewhat),
  ]

  const [filters, setFilters] = useState<string[]>(categoriesText)

  useEffect(() => {
    const newValue: DailyCheckSummary[] = []
    if (filters?.length) {
      for (const filter of filters) {
        newValue.push(...calcDailyCheckData().filter((item) => OnboardingDailyChecks(item.dailyCheck) === filter))
      }
    }
    setActionsData(newValue)
  }, [filters, calcDailyCheckData])

  //handle sort of actions
  const [visibleSorts, setVisibleSorts] = useState<Array<Columns>>([])
  const [sort, setSort] = useState<{ column: Columns; order: Orders }>({} as { column: Columns; order: Orders })
  const onArrowClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentColumn = e.currentTarget.parentElement?.parentElement?.textContent
    if (currentColumn && isTypeColumn(currentColumn)) {
      setVisibleSorts((prev) => [...prev, currentColumn])
    }
  }
  useEffect(() => {
    const isUpDown = () => {
      if (sort.order === '昇順') {
        return { sign: 1 }
      } else {
        return { sign: -1 }
      }
    }
    switch (sort.column) {
      case '達成日': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.achivementDay === b.achivementDay) return 0
          return a.achivementDay < b.achivementDay ? isUpDown().sign * 1 : isUpDown().sign * -1
        })
        return setActionsData(newValue)
      }
      case 'デイリーチェック': {
        const willNewValue = actionsData.map((item) => item) // use it for re-render
        const newValue = willNewValue.sort((a, b) => {
          if (a.dailyCheck === b.dailyCheck) return 0
          return a.dailyCheck < b.dailyCheck ? isUpDown().sign * 1 : isUpDown().sign * -1
        })
        return setActionsData(newValue)
      }
    }
    //because of avoid circular dependency to update actionsData(itself)
    // eslint-disable-next-line
  }, [sort])
  // <-- handle data
  const classes = useStyles()

  const theme = useTheme()
  const isBreakpointsDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.allWrapper}>
      <WhiteCard
        ownStyles={{
          marginBottom: 22,
          overflowY: 'scroll',
          position: isBreakpointsDown ? 'relative' : 'static',
          width: isBreakpointsDown ? 'calc(100vw -32px)' : 'auto',
          minHeight: '370px',
          ...ownStyles,
        }}
      >
        <div className={classes.headerWrapper}>
          <div className={classes.title}>デイリーチェック</div>
          <HandleDetailOpenText open={visibleActionOverView} setOpen={setVisibleActionOverView} />
        </div>
        <div className={classes.SPViewportWrapper} id={actionsWrapperId}>
          <div className={classes.actionsWrapper}>
            {columns.map((item) => (
              <div key={`columns-${item}`} className={classes.titleTextWrapper}>
                <div className={classes.titleText}>
                  <div>{item}</div>
                  <div className={classes.arrowWrapper}>
                    <img src={upAndDownArrow} alt="arrowIcon" className={classes.arrowImg} onClick={onArrowClick} />
                    {/* if open sortPopup---> */}
                    {visibleSorts.includes(item) ? (
                      <SortList
                        parent={item}
                        items={orders}
                        sort={sort}
                        setSort={setSort}
                        setVisibleSorts={setVisibleSorts}
                        classes={classes}
                      />
                    ) : (
                      <></>
                    )}
                    {/* <---if open sortPopup */}
                  </div>
                </div>
              </div>
            ))}

            {actionsData.length > 0 ? (
              <>
                {actionsData.map((item, i) => {
                  if (!visibleActionOverView && i > 4) return
                  return <ActionStatusItem key={`${item.achivementDay}${i}`} {...item} classes={classes} />
                })}
              </>
            ) : (
              <>
                表示するデイリーチェックは
                <br />
                ありません。
              </>
            )}
          </div>
        </div>
      </WhiteCard>
      <div className={classes.filterWrapper}>
        <img src={filterIcon} alt="filter" className={classes.filterImg} onClick={handleVisibleFilter} />
        {/* if open filterPopup--->*/}
        {visibleFilter ? (
          <FiltersList
            items={categoriesText}
            filters={filters}
            setFilters={setFilters}
            setVisibleFilter={setVisibleFilter}
            classes={classes}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const ActionStatusItem: React.FC<{ classes: ClassNameMap } & DailyCheckSummary> = ({
  achivementDay,
  dailyCheck,
  classes,
}) => {
  return (
    <>
      <div className={classes.items}>{achivementDay}</div>
      <div className={classes.items}>{OnboardingDailyChecks(dailyCheck)}</div>
    </>
  )
}

const SortList: React.FC<{
  parent: string
  items: readonly string[]
  sort: { column: Columns; order: Orders }
  setSort: React.Dispatch<SetStateAction<{ column: Columns; order: Orders }>>
  setVisibleSorts: React.Dispatch<SetStateAction<Array<Columns>>>
  classes: ClassNameMap
}> = ({ parent, items, sort, setSort, setVisibleSorts, classes }) => {
  const onsortClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const currentOrders = e.currentTarget.textContent
    const currentColumn = e.currentTarget.parentElement?.parentElement?.parentElement?.textContent?.slice(0, -4)
    if (!isTypeOrders(currentOrders) || !isTypeColumn(currentColumn)) return
    setSort({ column: currentColumn, order: currentOrders })
  }
  const onClose = () => setVisibleSorts([])

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div className={classes.sortListRoot}>
          {items.map((item) => {
            const isChecked = sort.column === parent && sort.order === item
            return (
              <div
                key={`menuItem-${item}`}
                className={classNames([classes.sortListItemRoot, isChecked ? 'checked' : ''])}
                onClick={onsortClick}
              >
                {item}
              </div>
            )
          })}
        </div>
      </ClickAwayListener>
    </>
  )
}

const FiltersList: React.FC<{
  items: string[]
  filters: string[]
  setFilters: React.Dispatch<SetStateAction<string[]>>
  setVisibleFilter: React.Dispatch<SetStateAction<boolean>>
  classes: ClassNameMap
}> = ({ items, filters, setFilters, setVisibleFilter, classes }) => {
  const onFilterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const key: string = e.currentTarget.textContent ?? ''
    if (!items.includes(key)) return
    if (filters.includes(key)) {
      setFilters((prev) => prev.filter((item) => item !== key))
    } else {
      setFilters((prev) => [...prev, key])
    }
  }
  const onClose = () => setVisibleFilter(false)

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classes.filtersListRoot}>
        {items.map((item) => {
          const isChecked = filters.includes(item)
          return (
            <div
              key={`menuItem-${item}`}
              className={classNames([classes.filterListItemRoot, isChecked ? 'checked' : ''])}
              onClick={onFilterClick}
            >
              {item}
            </div>
          )
        })}
      </div>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    allWrapper: {
      position: 'relative',
    },
    title: {
      fontWeight: 'bold',
    },
    SPViewportWrapper: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        overflowX: 'scroll',
      },
    },
    spaceWith: {
      width: '50%',
    },
    actionsWrapper: {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '2fr 5fr',
      margin: '30px 0 0',
      [theme.breakpoints.down('sm')]: {
        margin: '60px 0 0',
        position: 'static',
        width: '100%',
        gridTemplateColumns: '3fr 5fr',
      },
    },
    titleTextWrapper: {
      fontSize: 12,
      fontWeight: 'bold',
      fontColor: constants.TEXT_GRAY_DARK,
      borderBottom: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      padding: '0 0 16px 0 ',
      margin: '0 0 18px 0 ',
    },
    titleText: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 4,
        padding: '13px 6px 8px 11px',
        margin: '0 4px 16px 4px',
        backgroundColor: constants.COLOR_ONBOARDING_WHITE,
      },
    },
    arrowWrapper: {
      position: 'relative',
      margin: '0 0 0 8px',
    },
    arrowImg: {
      display: 'block',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    filterWrapper: {
      position: 'absolute',
      top: 75,
      right: 20,
      [theme.breakpoints.down('sm')]: {
        top: 70,
        right: 20,
      },
    },
    filterImg: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    //for SortList
    sortListRoot: {
      position: 'absolute',
      top: 30,
      right: -40,
      width: 100,
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: '0px 3px 6px #00000029',
      zIndex: 1000,
      [theme.breakpoints.down('sm')]: {
        right: 0,
      },
    },
    sortListItemRoot: {
      padding: '14px 0 14px 37px',
      fontSize: 14,
      fontWeight: 'normal',
      '&.checked': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:before': {
          content: '" "',
          backgroundImage: `url(${checkedIcon})`,
          display: 'inline-block',
          height: '1rem',
          width: '1rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
    },
    //for FiltersList
    filtersListRoot: {
      position: 'absolute',
      top: 30,
      right: 0,
      width: 280,
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: '0px 3px 6px #00000029',
      overflow: 'hidden',
      zIndex: 1000,
    },
    filterListItemRoot: {
      padding: '14px 0 14px 37px',
      fontSize: 14,
      fontWeight: 'normal',
      '&.checked': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:before': {
          content: '" "',
          backgroundImage: `url(${checkedIcon})`,
          display: 'inline-block',
          height: '1rem',
          width: '1rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      },
    },
    // for ActionStatusItem
    items: {
      margin: '0 8px 12px 0',
      fontColor: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
    },
  }),
  { name: 'DailyCheckBox' }
)
