import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import fileRemoveIcon from '../../assets/fileTypeIcons/fileRemoveIcon.svg'
import { fileIcon } from '../../utils/attachmentFile'

import downloadIcon from './assets/download.svg'

type Props = {
  fileName: string
  src?: string
  boxType: 'post' | 'timeline'
  cancel?: () => void
}

export const AttachmentFileBox: React.FC<Props> = ({ fileName, src, boxType, cancel: remove }) => {
  const classes = useStyles()

  return boxType === 'post' ? (
    <div className={classes.root}>
      <img src={fileIcon(fileName)} alt={'attachmentFile'} className={classes.fileIcon} />
      <div className={classes.fileName}>{fileName}</div>
      <img src={fileRemoveIcon} alt={'remove'} onClick={remove} />
    </div>
  ) : (
    // timeline
    (<a
      className={classes.root}
      href={src}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <img src={fileIcon(fileName)} alt={'attachmentFile'} className={classes.fileIcon} />
      <div className={classes.fileName}>{fileName}</div>
      {!fileName.includes('.pdf') && <img src={downloadIcon} alt={'download'} className={classes.download} />}
    </a>)
  );
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2px auto 6px',
    border: '1px solid #E2E2E2',
    borderRadius: 8,
    width: '100%',
    height: 46,
  },
  fileIcon: {
    margin: '0 0 0 8px',
  },
  fileName: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 'bold',
    margin: '0 auto 0 8px',
  },
  download: {
    display: 'block',
    marginRight: 8,
  },
}))
