import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import 'dayjs/locale/ja'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'

type Props = {
  name: string
  value?: string | number | Date
  onChange: () => void
  readOnly?: boolean | undefined
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  readOnly?: boolean | undefined
}

export const FormDate: React.FC<Props & StyleProps & StyleSubProps> = ({
  name,
  value,
  onChange,
  readOnly = false,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles: ownStyles, readOnly: readOnly })
  const defaultDate = value ? dayjs(new Date(value)) : null

  const CustomOpenPickerIcon = () => <img src={CalendarSvg} alt="calendar" />

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DatePicker
        name={name}
        defaultValue={defaultDate ? dayjs(defaultDate) : undefined}
        onChange={onChange}
        readOnly={readOnly}
        format="YYYY年M月D日 dddd"
        desktopModeMediaQuery="@media (min-width:0px)"
        slots={{
          openPickerIcon: CustomOpenPickerIcon,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            margin: 'normal',
            className: classes.main,
            placeholder: '', // プレースホルダーを空に設定
          },
          popper: {
            className: classes.popover,
            placement: 'bottom-end',
          },
          desktopPaper: {
            sx: {
              borderRadius: '8px',
            },
          },
        }}
        className={classes.main}
        disablePast={true}
      />
    </LocalizationProvider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: ({ ownStyles, readOnly }: StyleProps & StyleSubProps) => ({
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: readOnly ? constants.COLOR_TEAMBUILDING_NEUTRAL_100 : '',
      ...ownStyles,

      '& .MuiFormLabel-root': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },

      '& .MuiInputBase-root': {
        paddingRight: 0,
        borderRadius: 8,
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        height: 42,
        [theme.breakpoints.down('sm')]: {
          height: 56,
        },
      },
      '& .MuiInputBase-input': {
        color: constants.COLOR_TEAMBUILDING_TEXT,
        fontSize: 14,
        fontWeight: 300,
        padding: '8px 8px 8px 16px',
        [theme.breakpoints.down('sm')]: {
          padding: 16,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0 !important`,
      },
      '& .MuiButtonBase-root': {
        color: `${constants.COLOR_TEAMBUILDING_PRIMARY} !important`,
        marginRight: 8,
        padding: 8,
        [theme.breakpoints.down('sm')]: {
          marginRight: 0,
          padding: 16,
        },
      },
      '& .MuiFormHelperText-root': {
        display: 'none',
      },
    }),
    popover: {
      '& .MuiPickersDay-today': {
        color: constants.COLOR_ONBOARDING_MAIN,
        border: 'none',
        boxShadow: 'none',
      },
      '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        color: constants.TEXT_WHITE,
      },
      '& .MuiPickersBasePicker-pickerView': {
        [theme.breakpoints.down('sm')]: {
          minWidth: 288,
        },
      },
      '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: '#fff',
      },
    },
  }),
  { name: 'FormDate' }
)
