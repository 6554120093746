import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { Breadcrumb } from 'components/Breadcrumb'
import { PaginationButtons } from 'components/PaginationButtons'
import { TitleRenewal } from 'components/TitleRenewal'
import { TopRenewalWrapper } from 'components/TopRenewalWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { NotificationList } from './components/NotificationList'
import { useNotification } from './hooks/useNotification'

import { constants } from 'assets'

export const linkStateKey = 'isLatestLink'
const numPerPage = 20

type LocationState = {
  isLatestLink?: boolean
}

const Index = () => {
  const classes = useStyles()
  const location = useLocation()
  const locationState = location.state as LocationState
  const isXsDown = useCustomMediaQuery('down', 'sm')
  const isSmDown = useCustomMediaQuery('down', 'md')
  const notification = useNotification()
  const { notifications, isLoading, error } = notification
  const [page, setPage] = React.useState(1)
  const [isLatestLink, setIsLatestLink] = React.useState(locationState?.[linkStateKey] ?? false)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // トップページの追従リンクから遷移してきたら、最新のコンテンツまでスクロールする。
  React.useEffect(() => {
    if (!isLoading && isLatestLink) {
      window.scrollTo({ top: isXsDown ? 220 : isSmDown ? 300 : 334, left: 0, behavior: 'smooth' })
      window.history.replaceState({}, '') // 画面遷移時に受け取った state をクリア
      setIsLatestLink(false)
    }
  }, [isLoading, isLatestLink, isXsDown, isSmDown])

  return (
    <TopRenewalWrapper>
      <div className={classes.container}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="お知らせ" />
        <TitleRenewal category="Notice" title="お知らせ" description={<>ココラボからのお知らせ</>} />

        <div className={classes.list}>
          <NotificationList page={page} numPerPage={numPerPage} isLatestLink={isLatestLink} {...notification} />
        </div>

        {!isLoading && !error ? (
          <div className={classes.pagination}>
            <PaginationButtons
              page={page}
              setPage={setPage}
              totalContentCount={notifications.length}
              numPerPage={numPerPage}
            />
          </div>
        ) : null}
      </div>
    </TopRenewalWrapper>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      minHeight: 'calc(100svh - 64px)', // loading 中も高さを確保（-64px はヘッダー分）
    },
    list: {
      marginTop: 40,
      [theme.breakpoints.up('md')]: {
        marginTop: 80,
      },
    },
    pagination: {
      marginTop: 40,
      paddingBottom: 80,
    },
  }),
  { name: 'notification-Index' }
)

export default Index
