import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

type Props = WithStyles<typeof useStyles> & {
  children: React.ReactNode
}

const Index: React.FC<Props> = ({ classes, children }) => {
  return (
    <div className={classes.bubbleContainer}>
      <div className={classes.bubble}>{children}</div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    bubbleContainer: {
      //
    },
    bubble: {
      backgroundColor: 'white',
      padding: '8px 16px',
      borderRadius: 20,
      border: '1px solid #C9C9C9',
      marginBottom: 16,
      position: 'relative',
      display: 'inline-block',
      '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 6,
        left: -8,
        marginTop: -15,
        border: '6px solid transparent',
        borderRight: '10px solid #C9C9C9',
        zIndex: 0,
        borderLeft: 0,
        borderTop: 0,
        transform: 'rotate(-25deg)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 6,
        left: -6,
        marginTop: -15,
        border: '6px solid transparent',
        borderRight: '10px solid #fff',
        zIndex: 0,
        borderLeft: 0,
        borderTop: 0,
        transform: 'rotate(-25deg)',
      },
    },
  })

export default withStyles(useStyles)(Index)
