import * as React from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import { GhostTeamMember } from '../../../../utils/generated'
import { UsefulCard, Flexbox } from '../../components/atoms'
import { MemberBox } from '../atoms'
interface Props {
  currentMembers: (GhostTeamMember | (GhostTeamMember & { currentId: string }))[] //current member counter
  page: 'break' | 'else'
  clickable?: boolean
  selectedValue?: string //if checkable is FALSE ,this prop is no use
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void //if checkable is FALSE ,this prop is no use
  ownStyles?: React.CSSProperties
}

export const SelectLeaderFromMember: React.FC<Props> = ({
  currentMembers,
  page,
  clickable,
  selectedValue,
  handleChange,
  ownStyles,
}) => {
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const classes = useStyles()

  return (
    <>
      <UsefulCard backgroundColor={'white'} ownStyles={{ padding: isBreakpointsUp ? 24 : '24px 16px', ...ownStyles }}>
        <Flexbox ownStyles={{ justifyContent: 'space-between' }}>
          <div className={classes.text}>参加メンバー</div>
          {currentMembers ? <div className={classes.text}>{currentMembers.length}人</div> : <></>}
        </Flexbox>
        <MemberBox
          currentMembers={currentMembers}
          selectedValue={selectedValue}
          handleChange={handleChange}
          page={page}
          clickable={clickable}
        />
      </UsefulCard>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    text: {
      fontWeight: 'bold',
      paddingBottom: 16,
      color: constGhost.COLOR_BLACK3,
    },
  }),
  { name: 'SelectLeaderFromMember' }
)
