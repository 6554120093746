import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Theme, TextField } from '@mui/material'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Slider from '@mui/material/Slider'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { useForm } from 'react-hook-form'

import * as constants from '../../../assets/constants'
import Button from '../../../components/Button'
import { ISetupAnketFeedbackUpdateInput } from '../../../services/api/updateSetupAnketFeedback'
import { IActionItem, IQuestionnaire } from '../../../stores/setupaction/reducer'

import { connector, ContainerProps } from './ModalQuestionnaire.container'

interface IOwnProps {
  open: boolean
  handleClose: any
  item: IActionItem
  values?: IQuestionnaire
}

const marks = [
  {
    value: 1,
    label: 'やや後退',
  },
  {
    value: 2,
    label: '変わりなし',
  },
  {
    value: 3,
    label: 'やや進展',
  },
  {
    value: 4,
    label: 'まぁまぁ進展',
  },
  {
    value: 5,
    label: 'かなり進展',
  },
]

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  //// Questionnaire
  const { register, handleSubmit, setValue } = useForm<ISetupAnketFeedbackUpdateInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = (data: ISetupAnketFeedbackUpdateInput) => {
    if (props.user?.setupStartedAtList && props.setup.currentSetupStartedAt) {
      props.submit(props.setup.currentSetupStartedAt, {
        ...data,
        setupStartedAt: props.setup.currentSetupStartedAt,
      })
      props.handleClose()
    }
  }

  React.useEffect(() => {
    if (props.setupaction.submit) {
      props.handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.setupaction])

  return (
    <Modal
      open={props.open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.handleClose(event, reason)
        }
      }}
      aria-labelledby="action-modal-title"
      aria-describedby="action-modal-description"
      className={classes.modal}
    >
      <Paper className="__modalPaper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            name="weekDate"
            ref={register({})}
            defaultValue={props.item.anketValues && props.item.anketValues.weekDate}
          />
          <div className="__modalPaper__title">{props.item.mission}に回答する</div>

          <div className="__modalPaper__description">
            <p>それぞれの関係性は先週と比べていかがですか？</p>
            <p>5段階でいうとどれか選択してください。</p>
          </div>

          <div className="__modalPaper__values">
            <div className="__modalPaper__values__title">1.メンバーとの関係性</div>
            <QuestionnaireSlider
              name="member"
              defaultValue={props.values?.member}
              setValue={setValue}
              register={register}
            />

            <div className="__modalPaper__values__title">2.チーム作り</div>
            <QuestionnaireSlider
              name="team"
              defaultValue={props.values?.team}
              setValue={setValue}
              register={register}
            />
            <div className="__modalPaper__values__title">3.上司との関係性</div>
            <QuestionnaireSlider
              name="boss"
              defaultValue={props.values?.boss}
              setValue={setValue}
              register={register}
            />
            <div className="__modalPaper__values__title">4.ビジョン浸透</div>
            <QuestionnaireSlider
              name="vision"
              defaultValue={props.values?.vision}
              setValue={setValue}
              register={register}
            />
            <div className="__modalPaper__values__title">5. 関係者とのつながり</div>
            <QuestionnaireSlider
              name="other"
              defaultValue={props.values?.other}
              setValue={setValue}
              register={register}
            />
          </div>

          <div className="__modalPaper__title">所感（気づいたこと）</div>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue={props.values?.comment}
            variant="outlined"
            classes={{ root: '__modalPaper__textField' }}
            name="comment"
            inputRef={register({})}
          />

          <div className="__modalPaper__buttons">
            <Button
              bgColor={constants.COLOR_WHITE}
              textColor={constants.COLOR_BLACK}
              fullWidth={true}
              body={<div>閉じる</div>}
              onClick={props.handleClose}
            />

            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              submit={true}
              body={<div>更新</div>}
            />
          </div>
        </form>
      </Paper>
    </Modal>
  )
}

interface IQuestionnaireSliderProps {
  name: string
  defaultValue?: number
  setValue: any
  register: any
}
const QuestionnaireSlider: React.FC<IQuestionnaireSliderProps> = (props) => {
  const handleChange = (event: any, newValue: number | number[]) => {
    if (props.setValue) {
      props.setValue(props.name, getAnketValue(newValue as number))
    }
  }

  function getAnketValue(value: number) {
    switch (value) {
      case 1:
        return -0.4
      case 2:
        return 0
      case 3:
        return 0.2
      case 4:
        return 0.4
      case 5:
        return 0.8
      default:
        return 0.2
    }
  }

  function getSliderValue(value?: number) {
    switch (value) {
      case -0.4:
        return 1
      case 0:
        return 2
      case 0.2:
        return 3
      case 0.4:
        return 4
      case 0.8:
        return 5
      default:
        return 3
    }
  }

  return (
    <div className="__modalPaper__values__value">
      <div className="__modalPaper__values__value__label">
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
      </div>
      <div className="__modalPaper__values__value__slider">
        <Slider
          defaultValue={getSliderValue(props.defaultValue)}
          aria-labelledby={props.name}
          valueLabelDisplay="off"
          step={1}
          marks={marks}
          min={1}
          max={5}
          classes={{
            thumb: '__thumb',
            track: '__track',
            rail: '__rail',
            mark: '__mark',
            markLabel: '__markLabel',
          }}
          onChange={handleChange}
          sx={{
            '& .MuiSlider-mark': {
              transition: 'none',
            },
            '& .MuiSlider-markActive': {
              transition: 'none',
            },
            '& .MuiSlider-thumb': {
              transition: 'none',
            },
            '& .MuiSlider-thumb:before': {
              '-webkit-box-shadow': 'none',
              boxShadow: 'none',
            },
            '& .MuiSlider-thumb:before:hover': {
              '-webkit-box-shadow': 'none',
              boxShadow: 'none',
            },
          }}
        />
        <input
          type="hidden"
          name={props.name}
          ref={props.register({})}
          defaultValue={props.defaultValue !== undefined ? (props.defaultValue as number) : 0.2}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .__modalPaper': {
        background: constants.COLOR_GRAY,
        padding: theme.spacing(2),
        // height: '80vh',
        width: '100%',
        margin: '0 16px',
        maxHeight: '90vh',
        overflow: 'scroll',

        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },
        '&:focus': {
          outline: 'none',
        },

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: '18px',
          fontWeight: 'bold',
          padding: '16px 0',
          textAlign: 'center',
        },
        '&__description': {
          textAlign: 'center',
          fontSize: '12px',
        },
        '&__values': {
          '&__title': {
            paddingBottom: '2px',
            fontWeight: 'bold',
            borderBottom: `1px solid ${constants.COLOR_MAIN}`,
          },

          '&__value': {
            '&__label': {
              display: 'flex',
              justifyContent: 'space-between',
              padding: '16px 14px 0',
              '& span': {
                display: 'block',
                fontSize: '8px',
              },
            },
            '&__slider': {
              padding: '0 16px 16px',
            },
            '& .__thumb': {
              height: 28,
              width: 28,
              backgroundColor: constants.COLOR_SECOND,
              '&:focus, &:hover, &$active': {
                boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                '@media (hover: none)': {},
              },
            },
            '& .__track': {
              opacity: 0,
            },
            '& .__rail': {
              height: 2,
              opacity: 1,
              backgroundColor: constants.COLOR_BLACK,
            },
            '& .__mark': {
              backgroundColor: constants.COLOR_BLACK,
              height: 16,
              width: 2,
            },
            '& .__markLabel': {
              fontSize: '8px',
            },
          },
        },

        '&__textField': {
          width: '100%',
          backgroundColor: constants.COLOR_WHITE,
          '& .MuiInputBase-root': {
            padding: '18px 14px',
            borderRadius: '0',
          },
        },

        '&__buttons': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 0',
          '& :first-child': {
            marginRight: '4px',
          },
          '& :last-child': {
            marginLeft: '4px',
          },
        },
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
