import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { Feature } from 'utils/generated'
import { getIceBreakThemeLabel } from 'utils/labels'

import { constants } from 'assets'

type Props = {
  histories: Feature[] | undefined
} & React.ComponentProps<'p'>

export const Histories: React.FC<Props> = ({ className, histories }) => {
  const classes = useStyles()

  if (!histories) return null

  return (
    <>
      <p className={classNames(classes.title, className)}>これまでに選んだテーマ履歴</p>
      <div className={classes.historiesBox}>
        {histories.map((history) => (
          <div key={history.sk} className={classes.card}>
            <span className="date">{dayjs(history.createdAt).format('YYYY/MM/DD')}</span>
            <span className="theme">{history.contents.theme}</span>
            <span className="label">{getIceBreakThemeLabel(history.contents.theme)}</span>
          </div>
        ))}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  () => ({
    title: {
      margin: 0,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
    },
    historiesBox: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      marginTop: 16,
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      padding: '8px 16px',
      backgroundColor: '#fff',
      border: `solid 1px ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 4,
      '& .date': {
        color: constants.COLOR_MAIN_NEW,
        fontSize: 12,
        fontFamily: constants.MUI_TABLE_FONT_FAMILY,
      },
      '& .theme': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
        fontWeight: 'bold',
      },
      '& .label': {
        flexShrink: 0,
        display: 'inline-block',
        padding: '2px 8px',
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: '#fff',
        fontSize: 10,
        fontWeight: 'bold',
        borderRadius: 20,
      },
    },
  }),
  { name: 'Histories' }
)
