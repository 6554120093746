import * as React from 'react'

import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell'
import makeStyles from '@mui/styles/makeStyles';

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { SortMembersKey } from 'pages/teams/pages/members/hooks/sort'

import descriptionArrow from '../../assets/description.svg'
import downArrow from '../../assets/downArrow.svg'
import upArrow from '../../assets/upArrow.svg'
import { SortedInfoKey, SortedInfoSort } from '../hooks/sortTeams'

import { constants } from 'assets'

type Props = {
  headerName: string
  keyName: SortedInfoKey | SortMembersKey
  sortKey: {
    key: SortedInfoKey | SortMembersKey
    sort: SortedInfoSort
  } | null
  setSortKey: React.Dispatch<React.SetStateAction<{ key: any; sort: any } | null>>
  isStickyStyle1?: boolean
  isStickyStyle2?: boolean
  isGraphHead?: boolean
  isMemberList?: boolean
  tableCellClassName?: string
}

export const TableHeadCell: React.FC<Props> = ({
  headerName,
  keyName,
  sortKey,
  setSortKey,
  isStickyStyle1,
  isStickyStyle2,
  isGraphHead,
  isMemberList,
  tableCellClassName,
}) => {
  const classes = useStyles()
  const [hoveringColumn, setHoveringColumn] = React.useState<string>()
  const isMdUp = useCustomMediaQuery('up', 'md')

  const columnName = `${keyName}M`

  return (
    <TableCell
      className={`${classes.tableHeaderCell} ${isStickyStyle1 ? classes.stickyHeaderCellCol1 : ''} ${
        isStickyStyle2 ? classes.stickyHeaderCellCol2 : ''
      } ${keyName === sortKey?.key ? classes.sortedHead : ''} ${
        isMemberList ? classes.memberTableHeaderCell : classes.teamTableHeaderCell
      } ${tableCellClassName}`}
      onClick={() =>
        setSortKey((prev) => ({
          key: keyName,
          sort: prev?.key === keyName && prev.sort === 'up' ? 'down' : 'up',
        }))
      }
      onMouseEnter={() => setHoveringColumn(columnName)}
      onMouseLeave={() => setHoveringColumn('')}
    >
      <div className={classes.headerNameWrapper}>
        {headerName}
        <div className={classes.iconArrow}>
          {sortKey?.key === keyName && sortKey.sort === 'up' ? (
            <img src={upArrow} alt="up" />
          ) : (
            <img src={downArrow} alt="down" />
          )}
        </div>
        {isMdUp && hoveringColumn === columnName && (
          <div className={classes.fukidasiWrapper}>
            <img
              className={isGraphHead ? classes.fukidasiGraph : classes.fukidasi}
              src={descriptionArrow}
              alt="吹き出し"
            />
          </div>
        )}
      </div>
    </TableCell>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    tableHeaderCell: {
      color: constants.TEXT_GRAY_DARK,
      backgroundColor: constants.COLOR_WHITE,
      height: 50,
      lineHeight: 1.5,
      padding: '0 16px',
      whiteSpace: 'pre',
      cursor: 'pointer',
    },
    stickyHeaderCellCol1: {
      width: 80,
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 0,
        zIndex: 3,
      },
    },
    stickyHeaderCellCol2: {
      borderRight: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      [theme.breakpoints.up('md')]: {
        position: 'sticky',
        left: 80,
        zIndex: 3,
      },
    },
    memberTableHeaderCell: {
      position: 'sticky',
      top: 0,
      background: '#fff',
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      borderBottom: 'none',
      padding: '0 15px',
      fontSize: 12,
      zIndex: 1,
      '&:first-of-type': {
        padding: '0 15px 0 24px',
        zIndex: 2,
      },
      '&:nth-of-type(2)': {
        zIndex: 2,
      },
    },
    teamTableHeaderCell: {
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    },
    sortedHead: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
    headerNameWrapper: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 'bold',
    },
    iconArrow: {
      position: 'relative',
      top: 3,
      cursor: 'pointer',
      marginLeft: 3,
    },
    fukidasiWrapper: {
      position: 'relative',
      top: 40,
    },
    fukidasiGraph: {
      position: 'absolute',
      top: -10,
      left: -70,
      zIndex: 10,
    },
    fukidasi: {
      position: 'absolute',
      top: -22,
      left: -65,
      zIndex: 10,
    },
  }),
  { name: 'TableHeadCell' }
)
