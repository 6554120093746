import * as React from 'react'

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { UseFormMethods } from 'react-hook-form'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { IndexedTeam } from 'pages/teams/pages/list'
import { scrollElements } from 'pages/teams/utils/scrollElements'

import { GraphListBodyRow } from '../atoms/GraphListBodyRow'
import { TableHeadCell } from '../atoms/TableHeadCell'
import { FilterProps } from '../Filter'
import { SortedInfoKey, SortedInfoSort } from '../hooks/sortTeams'

import { constants } from 'assets'

interface Props {
  teams: IndexedTeam[]
  filter: FilterProps
  setFilter: (value: FilterProps) => void
  sortKey: {
    key: SortedInfoKey
    sort: SortedInfoSort
  } | null
  setSortKey: React.Dispatch<
    React.SetStateAction<{
      key: SortedInfoKey
      sort: SortedInfoSort
    } | null>
  >
  statusChangeList: boolean
  register: UseFormMethods['register']
  setValue: UseFormMethods['setValue']
  watchedTeamIds: string[] | string | boolean
}

const headerItems: {
  headerName: string
  keyName: SortedInfoKey
  isStickyStyle1?: boolean
  isStickyStyle2?: boolean
}[] = [
  { headerName: 'NO.', keyName: 'teamNum', isStickyStyle1: true },
  { headerName: 'チーム名', keyName: 'teamName', isStickyStyle2: true },
  { headerName: 'キックオフ日', keyName: 'teamBuildingStartDate' },
  { headerName: 'アクション\n達成度', keyName: 'teamBuildingProgress' },
  { headerName: 'カルテ\n実施回数', keyName: 'karteCount' },
  { headerName: 'リフレクション\n実施回数', keyName: 'teamBuildingReviewHistoryCount' },
  { headerName: 'オバケ\n実施回数', keyName: 'ghostHistoryCount' },
  { headerName: 'オバケ\n最終実施日', keyName: 'ghostLastPlayedDate' },
  { headerName: '直近アンケート\n実施日', keyName: 'anketBuildingDate' },
  { headerName: '最新総合\n評価', keyName: 'anketBuildingAverage' },
  { headerName: '成長度\n（前回比）', keyName: 'anketBuildingGrowth' },
  { headerName: 'チームビル\nディング度', keyName: 'anketBuildingValue' },
]

export const GraphList: React.FC<Props> = (props) => {
  const { teams, sortKey, setSortKey, filter, setFilter, statusChangeList, register, setValue, watchedTeamIds } = props
  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')

  const table = (
    <Table id={scrollElements.graphListSP}>
      <TableHead>
        <TableRow className={classes.tableHeaderRow}>
          {headerItems.map(({ headerName, keyName, isStickyStyle1, isStickyStyle2 }) => (
            <TableHeadCell
              key={`${keyName}-key`}
              headerName={headerName}
              keyName={keyName}
              sortKey={sortKey}
              setSortKey={setSortKey}
              isStickyStyle1={isStickyStyle1}
              isStickyStyle2={isStickyStyle2}
              isGraphHead
            />
          ))}
          <TableCell className={classes.tableHeaderCell}>チーム別{'\n'}評価詳細グラフ</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {teams.map((team, index) => (
          <GraphListBodyRow
            key={`team-list-${index}`}
            team={team}
            filter={filter}
            setFilter={setFilter}
            statusChangeList={statusChangeList}
            register={register}
            setValue={setValue}
            watchedTeamIds={watchedTeamIds}
            sortKey={sortKey}
          />
        ))}
      </TableBody>
    </Table>
  )

  return (
    <div className={classes.topContainer}>
      {teams.length > 0 ? (
        <>
          <div className={classes.listContainer}>
            {isMdUp ? (
              <TableContainer component={Paper} className={classes.tableContainer} id={scrollElements.graphList}>
                {table}
              </TableContainer>
            ) : (
              table
            )}
          </div>
        </>
      ) : (
        <div className={classes.noData}>チームはまだ有りません</div>
      )}
      <div className={classes.messageContainer}>
        <div>※各種ツールの利用状況を一覧でご確認頂けます（該当ツールが未利用の場合は空欄となります）</div>
        <div>
          ※各項目の該当ツールやデータの見方についてはヘルプ「
          <a
            href="https://cocolabo.club/hint/tips/081f1e2c-b0a8-413e-9950-01caac3e0dc8"
            target="_cc-team-list-graph-helps"
            className={classes.helpLink}
          >
            チーム一覧表の見方
          </a>
          」をご覧ください
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    topContainer: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: constants.COLOR_WHITE,
      },
    },
    listContainer: {
      display: 'flex',
      margin: '0 -24px',
      overflowY: 'auto',
      borderTop: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    tableContainer: {
      overflowX: 'auto',
    },
    tableHeaderRow: {
      borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      zIndex: 11,
    },
    tableHeaderCell: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      backgroundColor: constants.COLOR_WHITE,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      whiteSpace: 'pre',
      cursor: 'pointer',
    },
    textRight: {
      textAlign: 'right',
    },
    stickyBodyCellCol1: {
      position: 'sticky',
      left: 0,
      backgroundColor: constants.COLOR_WHITE,
    },
    noData: {
      padding: 50,
      textAlign: 'center',
      color: '#999',
      border: '1px solid #CCCCCC',
    },
    messageContainer: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: '20px',
        padding: '16px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 25,
      },
    },
    helpLink: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'GraphListPC' }
)
