import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,

    '&:focus': {
      outline: 'none',
    },
  },

  icon: {
    display: 'flex',
    width: '16px',
    height: '16px',
    marginRight: '4px',

    '& svg': {
      display: 'flex',
      width: '100%',
      height: '100%',
      verticalAlign: 'middle',
    },
  },

  lead: {
    fontSize: '14px',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  onClick: () => void
}

// -----------------------------
// Component
// -----------------------------
export const BackButton = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <button onClick={props.onClick} className={classes.button} type="button">
        <span className={classes.icon}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="angle-left"
            className="svg-inline--fa fa-angle-left fa-w-8"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              fill="currentColor"
              d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
            ></path>
          </svg>
        </span>
        <span className={classes.lead}>戻る</span>
      </button>
    </Box>
  )
}
