import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    color: constants.COLOR_RED,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  message?: string
}

// -----------------------------
// Component
// -----------------------------
export const ErrorMessage = (props: IProps) => {
  const classes = useStyles()

  return <span className={classes.root}>{props.message}</span>
}
