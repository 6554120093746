import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { Ghost } from 'utils/generated'

import { BasicButton } from './BasicButton'

import { constants } from 'assets'

const cardExpandDelay = 400

type props = {
  ghostIdList: number[] | undefined
} & FindGhostById

type CardProps = {
  ghostId: number
} & FindGhostById

type FindGhostById = {
  findGhostById: (id: number) => Ghost | undefined
}

export const GhostCardList: React.FC<props> = ({ ghostIdList, findGhostById }) => {
  const classes = useStyles()

  if (!ghostIdList || !ghostIdList.length) return null

  return (
    <section className={classes.section}>
      <WidthControlWrapper>
        {ghostIdList.map((ghostId) => (
          <Card key={ghostId} ghostId={ghostId} findGhostById={findGhostById} />
        ))}
      </WidthControlWrapper>
    </section>
  )
}

const Card: React.FC<CardProps> = ({ ghostId, findGhostById }) => {
  const classes = useStyles()
  const [isCloseStarted, setIsCloseStarted] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const isSmDown = useCustomMediaQuery('down', 'md')

  const ghost = React.useMemo(() => findGhostById(ghostId), [ghostId, findGhostById])

  const handleExpand = () => {
    setIsExpanded(true)
  }
  const handleClose = () => {
    if (isCloseStarted) return

    setIsCloseStarted(true)
    setTimeout(() => {
      setIsExpanded(false)
      setIsCloseStarted(false)
    }, cardExpandDelay - 50) // cardExpandDelay のままだとちらつく場合があるので 50ms 短くする。
  }

  if (!ghost) return null

  return (
    <div key={ghostId} className={classes.cardWrapper}>
      <div>
        <div className={classes.ghostBack}>
          <h2 className={classes.ghostName}>
            <span className="fileNumber">
              <span className="pre">File No</span>
              <span className="num">{`0${ghost.fileNo}`.slice(-2)}</span>
            </span>
            <span className="name">{ghost.name}</span>
          </h2>
          <div className={classes.ghostWrapper}>
            <img
              className={classes.ghostImg}
              src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${ghostId}.svg`}
              alt={ghost.name}
            />
            <div className={classes.descBox}>
              <div className={classes.desc}>
                <h3>どんなオバケ？</h3>
                <p>{ghost.con}</p>
              </div>
              <div className={classes.desc}>
                <h3>こんなときに出てくるかも！</h3>
                <p>{ghost.scenes.join('\n')}</p>
              </div>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className={classNames(classes.expanded, { closing: isCloseStarted })}>
            <div className={classes.ghostAbout}>
              <h2>どうしてこのオバケがでてきたの？</h2>
              <p className={classes.ghostWhy}>{ghost.why}</p>

              <h2>どんな悪さとご利益があるの？</h2>
              <div className={classes.ghostConPro}>
                <div className={classNames('base', 'con')}>
                  <h3>
                    <span>
                      オバケの悪さ
                      <img
                        src={`${process.env.PUBLIC_URL}/img/obakesagashi/tie-mini-ghost-dark.svg`}
                        alt="角がある黒いオバケ"
                      />
                    </span>
                  </h3>
                  <p>{ghost.con}</p>
                </div>
                <div className={classNames('base', 'pro')}>
                  <h3>
                    <span>
                      オバケのご利益
                      <img
                        src={`${process.env.PUBLIC_URL}/img/obakesagashi/tie-mini-ghost-angel.svg`}
                        alt="天使の輪っかがある白いオバケ"
                      />
                    </span>
                  </h3>
                  <p>{ghost.pro}</p>
                </div>
              </div>
            </div>

            <div className={classes.ghostDetail}>
              <div className="title-box">
                <div className="title">
                  <h2>このオバケが出てきたときの退治方法</h2>
                  {!isSmDown && <p className="desc">{ghost.solution}</p>}
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/obakesagashi/woman-ghost.svg`}
                  alt="オバケをライトで照らす女性"
                />
                {isSmDown && <p className="desc">{ghost.solution}</p>}
              </div>
              <div className="info-list">
                {ghost.actions.map((action) => (
                  <p key={action}>{action}</p>
                ))}
              </div>
            </div>

            <div className={classes.ghostDetail}>
              <div className="title-box">
                <div className="title">
                  <h2>オバケがいなくなったときの副作用</h2>
                  {!isSmDown && <p className="desc">{ghost.anti}</p>}
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/img/obakesagashi/man-tired.svg`}
                  alt="デスクに座り疲れた様子の男性"
                />
                {isSmDown && <p className="desc">{ghost.anti}</p>}
              </div>
              <div className="info-list">
                {ghost.caveats.map((caveat) => (
                  <p key={caveat}>{caveat}</p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={classNames(classes.expandButtonBox, { closeButton: isExpanded })}>
        <BasicButton
          className={classes.expandButton}
          backColor="tertiary"
          onClick={isExpanded ? handleClose : handleExpand}
        >
          <span className={isExpanded ? 'expanded' : 'closed'}>
            {isExpanded ? (
              '閉じる'
            ) : (
              <>
                {ghost.name}を{isSmDown && <br />}
                くわしく調べる
              </>
            )}
          </span>
        </BasicButton>
      </div>

      <div className={classes.hr}></div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    section: {
      marginTop: 107,
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
    },
    cardWrapper: {
      '&:not(:first-of-type)': {
        marginTop: 80,
        [theme.breakpoints.down('md')]: {
          marginTop: 30,
        },
      },
    },
    ghostBack: {
      position: 'relative',
      zIndex: 2,
      padding: '24px 35px 76px',
      background: `no-repeat bottom / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/brick-card-back.png)`,
      borderRadius: 16,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
      [theme.breakpoints.down('md')]: {
        padding: '16px 16px 38px',
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/brick-card-back-sp.png)`,
      },
    },
    ghostName: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      columnGap: 30,
      margin: 0,
      padding: '0 24px',
      color: '#fff',
      fontSize: 40,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('md')]: {
        columnGap: 24,
        padding: 0,
        fontSize: 24,
      },
      '& .fileNumber': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexShrink: 0,
        width: 73,
        height: 98,
        color: '#000',
        fontFamily: '"MOBO SemiBold"',
        background: `no-repeat top / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/light-blue-fire.svg)`,
        [theme.breakpoints.up('md')]: {
          top: -38,
          left: -94,
        },
        '& .pre': {
          fontSize: 16,
          lineHeight: 1,
        },
        '& .num': {
          fontSize: 25,
          lineHeight: 1.2,
          paddingBottom: 4,
        },
      },
      '& .name': {
        paddingBottom: 4,
        [theme.breakpoints.down('md')]: {
          alignSelf: 'center',
          paddingBottom: 0,
          lineHeight: 1.3,
        },
      },
    },
    ghostWrapper: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: 40,
      marginTop: 20,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 24,
        marginTop: 0,
      },
    },
    ghostImg: {
      marginLeft: 60,
      width: 300,
      [theme.breakpoints.down('md')]: {
        width: 200,
        marginLeft: 0,
      },
    },
    descBox: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    desc: {
      width: '100%',
      maxWidth: 527,
      padding: 24,
      // mixBlendMode を使うとテキスト分量の変化に対応しにくいため、透過度の設定で対応。
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: 17,
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('md')]: {
        padding: 16,
      },
      '& h3': {
        margin: 0,
        color: constants.COLOR_YELLOW4,
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.4,
      },
      '& p': {
        margin: '14px 0 0',
        color: '#fff',
        fontSize: 14,
        lineHeight: 1.7,
      },
    },
    expanded: {
      position: 'relative',
      zIndex: 1,
      marginTop: -33,
      paddingBottom: 73,
      color: '#000',
      borderRadius: '0 0 16px 16px',
      background: '#FFF',
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
      overflow: 'hidden',
      animation: `$slideOpenToBottom ${cardExpandDelay}ms`,
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
        paddingBottom: 0,
        borderRadius: 16,
      },
      '&.closing': {
        animation: `$slideCloseToTop ${cardExpandDelay}ms`,
      },
      '& h2': {
        margin: 0,
        fontFamily: '"MOBO SemiBold"',
        fontWeight: 600,
        fontSize: 24,
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
          lineHeight: 1.4,
        },
      },
      '@global': {
        '@keyframes slideOpenToBottom': {
          '0%': {
            maxHeight: 33, // marginTop のマイナス分を調整
            transform: 'translateY(-100%)',
          },
          '100%': {
            maxHeight: 2000,
            transform: 'translateY(0)',
          },
        },
        '@keyframes slideCloseToTop': {
          '0%': {
            maxHeight: 2000,
            transform: 'translateY(0)',
          },
          '100%': {
            maxHeight: 33, // marginTop のマイナス分を調整
            transform: 'translateY(-100%)',
          },
        },
      },
    },
    ghostAbout: {
      padding: '112px 40px 0',
      [theme.breakpoints.down('md')]: {
        padding: '40px 16px 0',
      },
    },
    ghostWhy: {
      margin: '24px 0 38px',
      fontSize: 16,
    },
    ghostConPro: {
      display: 'flex',
      columnGap: 40,
      marginTop: 45,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 16,
        marginTop: 20,
      },
      '& .base': {
        flex: 1,
        borderRadius: 16,
        '& h3': {
          display: 'grid',
          placeItems: 'center',
          margin: 0,
          height: 40,
          fontSize: 16,
          fontWeight: 'bold',
          borderRadius: '16px 16px 0 0',
          '& span': {
            position: 'relative',
            [theme.breakpoints.down('md')]: {
              width: '100%',
              textAlign: 'center',
            },
          },
          '& img': {
            position: 'absolute',
            top: -16,
            left: 'calc(100% + 16px)',
            width: 50,
            height: 50,
            [theme.breakpoints.down('md')]: {
              left: 'calc(100% - 64px)',
            },
          },
        },
        '& p': {
          margin: 24,
          color: constants.TEXT_GRAY_DARK,
          fontSize: 16,
          lineHeight: 1.5,
          [theme.breakpoints.down('md')]: {
            margin: '16px 24px',
          },
        },
      },
      '& .con': {
        border: '1px solid #D4D4D4',
        '& h3': {
          background: '#D4D4D4',
        },
      },
      '& .pro': {
        border: `1px solid ${constants.COLOR_YELLOW4}`,
        '& h3': {
          background: constants.COLOR_YELLOW4,
        },
      },
    },
    ghostDetail: {
      marginTop: 90,
      padding: '42px 40px 54px',
      background: constants.COLOR_NAVY,
      [theme.breakpoints.down('md')]: {
        marginTop: 50,
        padding: '32px 16px 49px',
      },
      '& .title-box': {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: 24,
        },
        '& .title': {
          [theme.breakpoints.up('md')]: {
            width: '57%',
          },
          '& h2': {
            color: constants.COLOR_YELLOW4,
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          },
        },
        '& .desc': {
          margin: '30px 0 0',
          color: '#fff',
          fontSize: 16,
          [theme.breakpoints.down('md')]: {
            margin: '6px 0 0',
          },
        },
        '& img': {
          width: 210,
          [theme.breakpoints.up('md')]: {
            margin: '-116px 74px 0 0',
          },
        },
      },
      '& .info-list': {
        marginTop: 20,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '30px 40px',
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 16,
          marginTop: 30,
        },
        '& p': {
          margin: 0,
          padding: '22px 18px',
          background: '#fff',
          color: constants.TEXT_GRAY_DARK,
          fontSize: 16,
          lineHeight: '24px',
          border: '3px solid #B3B3B3',
          borderRadius: 20,
          [theme.breakpoints.down('md')]: {
            padding: '23px 16px 44px',
          },
        },
      },
    },
    expandButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 80,
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
      },
      '&.closeButton': {
        marginTop: 140,
        [theme.breakpoints.down('md')]: {
          marginTop: 40,
        },
      },
    },
    expandButton: {
      width: 450,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 16px',
      fontSize: 20,
      lineHeight: 1.2,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: 60,
        maxWidth: 350,
        fontSize: 16,
      },
      '& .expanded': {
        position: 'relative',
        paddingRight: 20,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 'calc(100% - 16px)',
          width: 15,
          height: 2,
          background: constants.COLOR_YELLOW4,
          borderRadius: 2,
          transform: 'translateY(-50%)',
        },
      },
      '& .closed': {
        position: 'relative',
        paddingRight: 20,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 'calc(100% - 16px)',
          width: 15,
          height: 2,
          background: constants.COLOR_YELLOW4,
          borderRadius: 2,
          transform: 'translateY(-50%)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 'calc(100% - 9.5px)',
          width: 2,
          height: 15,
          background: constants.COLOR_YELLOW4,
          borderRadius: 2,
          transform: 'translateY(-50%)',
        },
      },
    },
    hr: {
      marginTop: 120,
      width: '100%',
      height: 4,
      background: constants.COLOR_GRAY4,
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
    },
  }),
  { name: 'GhostCardList' }
)
