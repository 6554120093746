import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { mergeClasses } from 'pages/onboarding/utils/styles'

import CevronLeftIcon from './components/cevron-left-icon'
import ChatIcon from './components/chat-icon'
import LikeIcon from './components/like-icon'
import SearchIcon from './components/search-icon'


interface IOwnProps {
  className?: string
  iconClassName?: string
  text: string
  color?: string
  icon?: 'cevron-left' | 'chat' | 'like' | 'search'
  style?: React.CSSProperties
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, iconClassName, text, color, icon, style }) => {
  const colorStyle = {
    color: color || '#000000',
  }
  return (
    <div className={mergeClasses([classes.container, className])} style={style}>
      {icon === 'chat' ? (
        <ChatIcon className={mergeClasses([classes.icon, iconClassName])} color={colorStyle.color} />
      ) : null}
      {icon === 'like' ? (
        <LikeIcon className={mergeClasses([classes.icon, iconClassName])} color={colorStyle.color} />
      ) : null}
      {icon === 'cevron-left' ? (
        <CevronLeftIcon className={mergeClasses([classes.icon, iconClassName])} color={colorStyle.color} />
      ) : null}
      {icon === 'search' ? (
        <SearchIcon className={mergeClasses([classes.icon, iconClassName])} color={colorStyle.color} />
      ) : null}
      <div style={colorStyle}>{text}</div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    icon: {
      marginRight: 4,
    },
  })

export default withStyles(useStyles)(Index)
