import * as React from 'react'

import { Box } from '@mui/material'

import { AuthorName } from 'pages/hint/_shared/components/authorName/Index'
import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { Content } from 'pages/hint/_shared/components/content/Index'
import { Details } from 'pages/hint/_shared/components/details/Index'
import { ArticleHeading } from 'pages/hint/_shared/components/heading/articleHeading/Index'
import { IuseArticleHeading } from 'pages/hint/_shared/components/heading/articleHeading/useArticleHeading'
import { Category } from 'pages/hint/_shared/components/label/category/Index'
// import { Comment } from 'pages/hint/_shared/components/comment/Index'
import { IHintResult } from 'pages/hint/_shared/stores/apis/getHint'

import * as options from 'assets/options'
import { IOptions } from 'assets/options'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  data: IHintResult
  presenter?: IuseArticleHeading
}

// -----------------------------
// Component
// -----------------------------
export const Article = (props: IProps) => {
  return (
    <Box>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <Box mr="24px">
          <Box mb={{ xs: '12px', md: '24px' }}>
            {options.optionHintCategory.map((optionHintCategory: IOptions, index: number) => {
              return (
                <Box key={index}>
                  {optionHintCategory.value === props.data.category && <Category text={optionHintCategory.label} />}
                </Box>
              )
            })}
          </Box>
          <ArticleHeading text={props.data.title} presenter={props.presenter} />
        </Box>
        {props.data?.user?.imageUrl && (
          <Avatar src={props.data.user.imageUrl} alt="avator" size={76} path={props.data.username} />
        )}
      </Box>

      <Box mt="24px">
        <Details>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex">
              {props.data?.user?.firstName && props.data?.user?.lastName && (
                <AuthorName text={`${props.data.user.lastName} ${props.data.user.firstName}`} />
              )}
              {(!props.data?.user?.firstName || !props.data?.user?.lastName) && props.data?.user?.nickname && (
                <AuthorName text={props.data.user.nickname} />
              )}
            </Box>
            {/* <Comment count={props.data.commentCount} /> */}
          </Box>
        </Details>
      </Box>

      <Box>
        <Box mt={{ xs: '24px', lg: '64px' }} minHeight={'20vh'}>
          <Content text={props.data.content} ellipsis={false} lineHeight={1.85} />
        </Box>
      </Box>
    </Box>
  )
}
