import {
  mutationCreateFeature,
  mutationDeleteFeature,
  mutationUpdateFeatureWithVersion,
  queryGetFeature,
  queryGetFeaturesByPrefix,
} from 'services/api/graphql'

import { sliceArrayFromTop } from './array'
import { DynamoDB_ERRORS, ERROR_MESSAGES, isApiError } from './error'
import { Feature, FeatureCategory, FeatureStatus, TeamMember } from './generated'
import { makeFeatureSkPrefix } from './string'
import type { IceBreakContents } from './types'

export const handleApiCall = async <T>(
  apiCall: () => Promise<T>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErrors: React.Dispatch<React.SetStateAction<Error[] | undefined>>,
  alertMessage?: string
): Promise<T | null> => {
  setErrors(undefined)
  setIsLoading(true)

  try {
    return await apiCall()
  } catch (e) {
    if (isApiError(e)) {
      setErrors(
        e.errors?.map((err) => {
          const error = new Error(err.message)
          error.name = err.errorType ?? 'UnknownError' // エラータイプがなければデフォルト値を設定
          return error
        })
      )
      if (alertMessage) {
        alert(`${alertMessage}\nエラー内容: ${e.errors?.[0]?.message || '不明なエラー'}`)
      }
    } else {
      console.error('未知のエラーが発生しました', e)
      alert('未知のエラーが発生しました。時間を置いて再度お試しください。')
    }
    return null
  } finally {
    setIsLoading(false)
  }
}

const updateFeatureWithRetry = async <T>({
  pk,
  sk,
  currentVersion,
  contents,
  maxRetries = 10,
  updateLogic,
}: {
  pk: string
  sk: string
  currentVersion: number
  contents: T
  maxRetries?: number
  updateLogic: (contents: T) => {
    updatedContents: T
    additionalInput?: Record<string, any>
  }
}) => {
  let retryCount = 0
  let success = false
  let result = null
  // ************************************************************
  // 動作確認用の待機: 楽観的ロック
  // console.log('updateFeatureWithRetry waiting 3 seconds...')
  // await new Promise((resolve) => setTimeout(resolve, 3000))
  // console.log('updateFeatureWithRetry waiting done.')
  // ************************************************************

  // 排他制御:
  // 楽観的ロックにより condition check でエラーとなる可能性がある。その場合、最大で maxRetries の回数分リトライする。
  while (retryCount < maxRetries && !success) {
    try {
      const { updatedContents, additionalInput = {} } = updateLogic(contents)

      result = await mutationUpdateFeatureWithVersion({
        input: {
          pk,
          sk,
          currentVersion,
          contents: JSON.stringify(updatedContents),
          ...additionalInput,
        },
      })

      success = true
    } catch (e: any) {
      if (e.errors[0].errorType.includes(DynamoDB_ERRORS.conditionalCheck)) {
        retryCount++
        const updatedResponse = await queryGetFeature({ pk, sk })
        if (updatedResponse) {
          currentVersion = updatedResponse.version as number
          contents = JSON.parse(updatedResponse.contents) as T
        }
      } else {
        throw e
      }
    }
  }

  if (!success) {
    alert(ERROR_MESSAGES.dataUpdate)
  }

  return result
}

export const createIceBreak = async ({
  pk,
  sk,
  currentTeamMember,
}: {
  pk: string
  sk: string
  currentTeamMember: TeamMember
}) => {
  // ************************************************************
  // 動作確認用の待機: レースコンディション対応
  // console.log('createIceBreak waiting 3 seconds...')
  // await new Promise((resolve) => setTimeout(resolve, 3000))
  // console.log('createIceBreak waiting done.')
  // ************************************************************

  return await mutationCreateFeature({
    input: {
      pk,
      sk,
      category: FeatureCategory.IceBreak,
      status: FeatureStatus.Start,
      contents: JSON.stringify({
        activeUserIds: [currentTeamMember.userId],
        leaderUserId: currentTeamMember.userId,
      } as IceBreakContents),
    },
  })
}

export const updateIceBreakMember = async ({
  pk,
  sk,
  currentVersion,
  contents,
  ownUserId,
  action,
}: {
  pk: string
  sk: string
  currentVersion: number
  contents: IceBreakContents
  ownUserId: string
  action: 'entry' | 'cancel'
}) => {
  return updateFeatureWithRetry<IceBreakContents>({
    pk,
    sk,
    currentVersion,
    contents,
    updateLogic: (currentContents) => {
      // 'entry' または 'cancel' に応じて activeUserIds を更新
      const updatedActiveUserIds =
        action === 'entry'
          ? [...currentContents.activeUserIds, ownUserId] // 追加
          : currentContents.activeUserIds.filter((userId) => userId !== ownUserId) // 削除

      return {
        updatedContents: {
          ...currentContents,
          activeUserIds: updatedActiveUserIds,
        },
      }
    },
  })
}

export const updateIceBreakByLeader = async ({
  pk,
  sk,
  status,
  currentVersion,
  contents,
  isLeader,
}: {
  pk: string
  sk: string
  status: FeatureStatus
  currentVersion: number
  contents: IceBreakContents
  isLeader: boolean
}) => {
  if (!isLeader) return

  return updateFeatureWithRetry<IceBreakContents>({
    pk,
    sk,
    currentVersion,
    contents,
    updateLogic: (currentContents) => ({
      updatedContents: currentContents,
      additionalInput: { status },
    }),
  })
}

export const deleteIceBreakByLeader = async ({ pk, sk, isLeader }: { pk: string; sk: string; isLeader: boolean }) => {
  if (!isLeader) return
  return await mutationDeleteFeature({ pk, sk })
}

export const getIceBreakHistories = async ({ teamId }: { teamId: string }) => {
  const res = await queryGetFeaturesByPrefix({
    pk: teamId,
    skPrefix: makeFeatureSkPrefix(FeatureCategory.IceBreak),
    // 直近 100 件を取得（アクティブな item が含まれる場合もあるため、101 に設定）。
    limit: 101,
  })

  const hasActiveIceBreak = res?.items?.[0]?.status
    ? ![FeatureStatus.Completed, FeatureStatus.Cancel].includes(res.items[0].status)
    : false

  // status が Completed または Cancel の item を除外して返却。
  const histories = res?.items
    ? res.items
        .filter((item) => item && item.status === FeatureStatus.Completed)
        .map((item) => ({ ...item, contents: JSON.parse(item?.contents) }))
    : []
  const limitedHistories = sliceArrayFromTop(histories, 100)

  return {
    histories: limitedHistories as Feature[],
    hasActiveIceBreak,
  }
}
