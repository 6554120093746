import * as React from 'react'
import { Link, RouteComponentProps, useLocation } from 'react-router-dom'

import { CircularProgress, Modal, OutlinedInput, Fade } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import { makeEvenPoints } from 'pages/teams/pages/_tools/karte/pages/diagnose/utils'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import AlertDialog from '../../../../components/AlertDialog'
import Button from '../../../../components/Button'
import UsefulCollapse from '../../../../components/UsefulCollapse'
import Graph from '../../components/result/Graph'
import RankingCard from '../../components/result/RankingCard'
import { SocialsSnackbar } from '../../components/result/SocialsSnackbar'
import UsefulCircle from '../../components/result/UsefulCircle'
import SpeechBubbles from '../../components/SpeechBubbles'
import { StringKeyObject } from '../questionnaire/index.container'
import { bubbleTexts, circleGraph, questionnaireAnswerPatterns, questionnaireBubbles } from '../static'

import { connector, ContainerProps } from './index.container'
import { IKarteMemberResults } from './interface'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ leaderId: string; id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const [results, setResults] = React.useState<IKarteMemberResults | null>(null)
  const location = useLocation()
  React.useEffect(() => {
    props.onFetch(props.match.params.leaderId, props.match.params.id)
    const timeoutId = setTimeout(() => {
      setIsSnackbarOpen(!isSnackbarOpen)
    }, 7000)
    return () => {
      clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const buildResults = (data: any) => {
      const results = {
        about: {
          short: '',
          long: '',
        },
        motivation: '',
      }

      // Calculate about & motivation
      const formTotals: { group: string; weight: number; value: number }[] = []
      const formQuestionsTotals: {
        group: string
        key: string
        label: string
        weight: number
        value: number
      }[] = []
      const formCircleTotals: {
        circleGroup: string
        label: string
        description: string
        value: number
      }[] = circleGraph.map((item) => ({
        circleGroup: item.key,
        label: item.label,
        description: item.description,
        value: 0,
      }))

      const formValues: StringKeyObject = {}

      for (let formIndex = 0; formIndex < data.calculable.length; formIndex++) {
        const form = data.calculable[formIndex]

        for (let questionIndex = 0; questionIndex < form.questions.length; questionIndex++) {
          const question = form.questions[questionIndex]
          const addValue = parseInt(question.value, 0)
          const addedValue = formValues[form.group]
            ? formValues[form.group].value
              ? formValues[form.group].value + addValue
              : addValue
            : addValue
          formValues[form.group] = {
            value: addedValue,
          }
          formValues[form.group].value += parseInt(question.value, 0)

          formQuestionsTotals.push({
            group: form.group,
            key: question.key,
            label: question.label,
            weight: question.weight || 999,
            value: question.value,
          })

          const circleGraphIndex = circleGraph.findIndex((item) => item.groups.indexOf(form.group) !== -1)
          const circleDataGroupIndex = formCircleTotals.findIndex(
            (item) => item.circleGroup === circleGraph[circleGraphIndex].key
          )

          formCircleTotals[circleDataGroupIndex].value += parseInt(question.value, 0)
        }

        if (form.questions.length > 0) {
          formTotals.push({
            group: form.group,
            weight: form.weight || 999,
            value: formValues[form.group].value / form.questions.length,
          })
        }
      }

      formTotals.sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value === b.value) return a.weight > b.weight ? 1 : -1
        return 1
      })

      formQuestionsTotals.sort((a, b) => {
        if (a.value > b.value) return -1
        if (a.value === b.value) return a.weight > b.weight ? 1 : -1
        return 1
      })

      formCircleTotals.sort((a, b) => {
        return a.value < b.value ? 1 : -1
      })
      const patternIndex = questionnaireAnswerPatterns.findIndex(
        (pattern) => pattern.first === formTotals[0].group && pattern.second === formTotals[1].group
      )
      if (patternIndex !== -1) {
        const pattern = questionnaireAnswerPatterns[patternIndex]
        results.about = pattern.about
        results.motivation = pattern.motivation
      }

      // Calculate bubbles & points
      const bubbles = []
      let minPoint = 0
      const points: { title: string; description: string; value: number; color: string; link: string }[] = []
      for (let formIndex = 0; formIndex < formTotals.length; formIndex++) {
        const form = formTotals[formIndex]
        const bubble = questionnaireBubbles[form.group] as {
          label: string
          color: string
        }
        if (formIndex <= 2) {
          minPoint = form.value
        }
        bubbles.push({
          label: bubbleTexts[form.group] && bubbleTexts[form.group].shortLabel,
          color: bubble.color,
          size: form.value >= minPoint ? 'large' : 'small',
        })
        points.push({
          title: bubbleTexts[form.group] && bubbleTexts[form.group].label,
          description: bubbleTexts[form.group].description,
          value: form.value,
          color: bubble.color,
          link: bubbleTexts[form.group].linkMember,
        })
      }

      // Calculate characteristics
      const characteristicsGood: string[] = []
      const characteristicsBad: string[] = []

      for (let characteristicIndex = 0; characteristicIndex < formQuestionsTotals.length; characteristicIndex++) {
        // calculate good
        if (
          formQuestionsTotals[characteristicIndex].value === formQuestionsTotals[0].value &&
          characteristicsGood.length < 10
        ) {
          characteristicsGood.push(formQuestionsTotals[characteristicIndex].label)
        }

        // calculate bad
        if (
          formQuestionsTotals[characteristicIndex].value ===
            formQuestionsTotals[formQuestionsTotals.length - 1].value &&
          characteristicsBad.length < 10
        ) {
          characteristicsBad.push(formQuestionsTotals[characteristicIndex].label)
        }
      }

      const radomizer = (array: string[]) => {
        let count = array.length,
          randomnumber,
          temp
        while (count) {
          randomnumber = (Math.random() * count--) | 0
          temp = array[count]
          array[count] = array[randomnumber]
          array[randomnumber] = temp
        }
      }

      radomizer(characteristicsGood)
      radomizer(characteristicsBad)

      return {
        ...results,
        circleItems: formCircleTotals,
        bubbles,
        points,
        characteristics: {
          good: characteristicsGood,
          bad: characteristicsBad,
        },
      }
    }
    if (props.data) {
      setResults(buildResults(props.data))
    }
  }, [props.data])

  // TODO: make loading in common module
  const renderLoading = () => {
    if (!props.isLoading) return null
    return (
      <Modal
        open={props.isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress className={classes.loadingCircularProgress} />
      </Modal>
    )
  }

  // TODO: make error in common module
  const renderError = () => {
    if (!props.error) return null

    const onClose = () => {
      props.history.push(`/karte/member/${props.match.params.leaderId}`)
      props.onResetError()
    }

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)
  const [memberEmail, setMemberEmail] = React.useState('')
  const [isLeaderResultSend, setIsLeaderResultSend] = React.useState(false)
  const [openSentModal, setOpenSentModal] = React.useState(false)

  const renderContent = () => {
    if (!results) return null

    return (
      <div className="__container__content">
        <div className="__container__content__base">
          <SpeechBubbles
            bubbles={[
              <>
                あなたは…
                <br />
                <strong>{results.about.short}</strong>です！
              </>,
            ]}
          />

          <div className="__container__content__note">
            <div className="__container__content__note__title">あなたの大事にしていることは？</div>
            <p>{results.about.long}</p>
            <div className="__container__content__note__title">あなたはどんな人？</div>
            <p>{results.motivation}</p>
          </div>

          <div className="__container__content__title">動機傾向</div>

          <div className="__container__content__circlesContainer">
            <div className="__container__content__circlesContainer__content">
              <div className="__container__content__circlesContainer__content__circle">
                <UsefulCircle
                  first={results ? results.circleItems[0].label : ''}
                  second={results ? results.circleItems[1].label : ''}
                  third={results ? results.circleItems[2].label : ''}
                />
              </div>
              <UsefulCollapse
                body={
                  <div className={`${classes.userfulCircleBody} userfulCircleBody`}>
                    {results &&
                      results.circleItems.map((item, index) => (
                        <div key={index}>
                          <div className="userfulCircleBody__title">
                            <label className="one">
                              {index + 1}位 {item.label}
                            </label>
                          </div>
                          <p>{item.description}</p>
                          {results && index !== results.circleItems.length - 1 && (
                            <div className="userfulCircleBody__hr" />
                          )}
                        </div>
                      ))}
                  </div>
                }
                openLabel="各項目の説明を詳しくみる"
              />
            </div>
          </div>

          <div className="__container__content__bubblesContainer">
            <Graph
              data={results.points.map((item) => ({
                ...item,
                value: makeEvenPoints(item.value),
              }))}
            />
          </div>

          <div className="__container__content__rankingContainer">
            <div className="__container__content__rankingContainer__content">
              {results.points.map((point, index) => (
                <RankingCard
                  key={index}
                  index={index}
                  title={point.title}
                  point={makeEvenPoints(point.value)}
                  body={point.description}
                  color={point.color}
                  link={point.link}
                />
              ))}
            </div>
          </div>

          <div className="__container__content__title">特徴的なこだわり</div>

          <p>
            全36問の質問文で見たときに、特にこだわりが見られたのが以下の通りであり、上司や周りの人にも共有しましょう。
          </p>
        </div>
        <div className="__container__content__conclusionContainer">
          <div className="__container__content__conclusionContainer__title">重視していること</div>
          <ul>
            {results.characteristics.good.map((item, index) => (
              <li key={index}>
                <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte/dotYellow.svg'} alt={'dot'} />
                {item}
              </li>
            ))}
          </ul>
          <div className="__container__content__conclusionContainer__title">重視していないこと</div>
          <ul>
            {results.characteristics.bad.map((item, index) => (
              <li key={index}>
                <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte/dotBlue.svg'} alt={'dot'} />
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="__container__content__send_results">
          <div className="__container__content__send_results__title">この診断結果をメールで送る。</div>
          <div className="__container__content__send_results__description">
            この診断結果をメールでご覧になりたい方は下記メールアドレス記入欄に送りたいメールアドレスを記入し送信ボタンを押して下さい。その際、リーダーにカルテNo.を送信する場合は「リーダーにカルテNo.を送信する」にチェックを付けてください。
          </div>
          <OutlinedInput
            placeholder="メールアドレス"
            className="__container__content__send_results__input"
            value={memberEmail}
            onChange={(event) => setMemberEmail(event.target.value)}
            style={{ padding: 0 }}
          />
          <label className="__container__content__send_results__checkbox">
            <input onChange={() => setIsLeaderResultSend((v) => !v)} type="checkbox" checked={isLeaderResultSend} />
            <span>リーダーにカルテNo.を送信する</span>
          </label>
          <div className="__container__content__send_results__separator" />
          <Button
            bgColor={
              !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(memberEmail) || memberEmail.length === 0
                ? constants.COLOR_GRAY
                : constants.COLOR_BLUE
            }
            textColor={constants.COLOR_WHITE}
            fullWidth={true}
            body={<div>送信する</div>}
            disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(memberEmail) || memberEmail.length === 0}
            onClick={() => {
              props.onSendResults(props.match.params.id, props.match.params.leaderId, memberEmail, isLeaderResultSend)
              setMemberEmail('')
              setOpenSentModal(true)
            }}
          />
          {/* <span className="__container__content__history__caution">
            カルテNo.を入れて検索すると、過去の診断結果を見ることができます。
          </span> */}
          {/* {isKarteNotFound && <small style={{ color: 'red' }}>入力されたNoのカルテは見つかりませんでした</small>} */}
        </div>
        <div className="__container__content__link">
          <a
            href="https://cocolabo.club/hint/tips/8943dda5-996c-4093-8780-b74b2facede0"
            target="_blank"
            rel="noopener noreferrer"
          >
            更に詳しく特徴を知りたい方はこちら
          </a>
        </div>
        <div className="__container__content__link">
          <a
            href="https://cocolabo.club/hint/tips/459168f2-944f-43dd-b62a-53b9ca9d7db4"
            target="_blank"
            rel="noopener noreferrer"
          >
            診断結果を使ってチームメンバー同士でつながりを深めるには
          </a>
        </div>
        <div className="__container__content__link">
          <a
            href="https://cocolabo.club/hint/tips/37119684-4dc0-40ee-86dc-75a87cc6c15a"
            target="_blank"
            rel="noopener noreferrer"
          >
            カルテ対話を行う際の質問例
          </a>
        </div>
        <div style={{ padding: 16, paddingTop: 40 }}>
          <Link to={`${pages.KARTE_LANDING}/member/${location.pathname.split('/').slice(-4)[0]}`}>
            <Button
              bgColor={constants.COLOR_BLUE}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              body={<div>メンバー診断TOPへ戻る</div>}
            />
          </Link>
        </div>
        <div style={{ padding: 16 }}>
          <Link to={pages.KARTE_LANDING}>
            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              body={<div>他のメンバーを診断する</div>}
            />
          </Link>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__header">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ textAlign: 'center' }}>診断結果 カルテNo. {props.match.params.id}</div>
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: 8 }}>
                良い面談を行うためにこのカルテNoをリーダーに知らせてください。
                {'\n'}＊回答内容は公開されませんのでご安心ください。
              </div>
            </div>
          </div>

          {renderContent()}

          <div className="__container__footer">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ textAlign: 'center' }}>診断結果 カルテNo. {props.match.params.id}</div>
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: 8 }}>
                良い面談を行うためにこのカルテNoをリーダーに知らせてください。
                {'\n'}＊回答内容は公開されませんのでご安心ください。
              </div>
            </div>
          </div>
        </div>

        {renderLoading()}
        {renderError()}
        {isSnackbarOpen && <SocialsSnackbar />}
      </div>

      <Modal
        aria-labelledby="sent-modal"
        aria-describedby="mail-sent"
        className={classes.sentModal}
        open={openSentModal}
        onClose={() => setOpenSentModal(false)}
        closeAfterTransition
      >
        <Fade in={openSentModal}>
          <div className="__paper">
            <div className="__paper__text">送信しました</div>
            <Button
              bgColor={constants.COLOR_MAIN}
              textColor={constants.COLOR_WHITE}
              body={<div>閉じる</div>}
              onClick={() => {
                setOpenSentModal(false)
              }}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#F5F5F5',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__header': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 98,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },

        '&__content': {
          backgroundColor: '#fff',
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          borderBottomLeftRadius: '30px',
          padding: '16px 0 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__base': {
            padding: '0 16px',
          },

          '&__note': {
            backgroundColor: '#F5F5F5',
            padding: 16,
            margin: 16,
            marginTop: 0,

            '&__title': {
              color: constants.COLOR_MAIN,
              // fontSize: 20,
              fontWeight: 'bold',
            },
          },

          '&__circlesContainer': {
            marginBottom: 16,

            '&__content': {
              '&__circle': {
                //
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                padding: 16,
                backgroundColor: '#F5F5F5',
              },
            },
          },

          '&__bubblesContainer': {
            marginBottom: 16,
          },

          '&__rankingContainer': {
            marginBottom: 16,
          },

          '&__conclusionContainer': {
            padding: 16,
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/karte/bg_heart.svg)`,
            backgroundSize: 'cover',
            '&__title': {
              fontWeight: 'bold',
              fontSize: 18,
              marginBottom: 16,
            },

            '& ul': {
              paddingLeft: 8,
            },

            '& li': {
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              marginBottom: 8,

              '& img': {
                width: 8,
                height: 8,
                marginRight: 8,
              },
            },
          },

          '&__compareContainer': {
            backgroundColor: '#F5F5F5',
            padding: 16,
            '&__title': {
              fontSize: 18,
              fontWeight: 'bold',
              marginBottom: 8,
            },
          },

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 20,
            fontWeight: 'bold',
            padding: '16px 0',
            borderTop: '1px dotted',
          },

          '&__buttons': {
            display: 'flex',
            padding: 16,
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },

          '&__link': {
            padding: '4px 16px',
            color: constants.COLOR_MAIN,
            textDecoration: 'underline',
          },

          '&__send_results': {
            backgroundColor: constants.COLOR_BLUE_LIGHT,
            padding: 16,
            margin: 16,
            borderRadius: 5,

            '&__title': {
              color: '#fff',
              fontSize: 20,
              fontWeight: 'bold',
            },

            '&__description': {
              color: '#fff',
              fontSize: 16,
              fontWeight: 'normal',
            },

            '&__input': {
              width: '100%',
              background: '#fff',
              height: 40,
              borderRadius: 0,
              marginTop: 16,
              marginBottom: 16,

              '&__icon': {
                color: constants.COLOR_BLUE_LIGHT,
              },
            },

            '&__caution': {
              fontSize: 10,
              display: 'block',
              color: '#404040',
            },
            '&__checkbox': {
              paddingTop: 16,
              paddingBottom: 16,
            },
            '&__separator': {
              marginBottom: 16,
            },
          },
        },

        '&__footer': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 16px 8px',
          alignItems: 'center',
          marginTop: 8,
          height: 98,

          '&__button': {
            fontWeight: 'bold',
            '& i': {
              marginRight: 4,
            },
          },
        },
      },
    },

    userfulCircleBody: {
      padding: '8px 16px',
      borderLeft: '1px solid #f3f3f3',
      borderRight: '1px solid #f3f3f3',
      '& .userfulCircleBody': {
        '& p': {
          margin: 0,
          paddingBottom: 8,
        },

        '&__title': {
          display: 'block',
          fontSize: 16,
          fontWeight: 'bold',
          margin: '8px 0',

          '& label': {
            '&.one': {
              background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
              // fontWeight: 'bold',
            },
          },
        },

        '&__hr': {
          borderBottom: '1px solid #e5e5e5',
        },
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },

    sentModal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .__paper': {
        paddingTop: 38,
        paddingBottom: 24,
        width: 240,
        backgroundColor: '#fff',
        borderRadius: 10,
        outline: 0,
        textAlign: 'center',
        '&__text': {
          marginBottom: 24,
          fontSize: 16,
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
