import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import { Categories } from 'pages/hint/_shared/components/categories/Index'
import { IuseCategory } from 'pages/hint/_shared/components/categories/useCategory'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    position: 'relative',
    color: constants.COLOR_MAIN,
    fontSize: '14px',
    backgroundColor: constants.COLOR_WHITE,
    zIndex: 3,

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '50%',
      right: 0,
      width: '70%',
      height: '1px',
      backgroundColor: constants.COLOR_MAIN,
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseCategory
  currentCategory?: string
}

// -----------------------------
// Component
// -----------------------------
export const Search = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box>
      <h2 className={classes.heading}>SEARCH</h2>
      <Box>
        <Categories presenter={props.presenter} currentCategory={props.currentCategory} />
      </Box>
    </Box>
  )
}
