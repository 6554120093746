import React from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useForm } from 'react-hook-form'
import { createTeamAnketBuilding } from 'services/api/anket'
import { useTeamAnketBuildingSummaryListByTeam } from 'services/api/anket'
import {
  TeamBuildingQuestionTitle,
  FormInput,
  TeamBuildingAnketCommentInput,
  FreeCommentTarget,
} from 'stores/anket/types'

import { CreateTeamAnketBuildingInput, TeamAnketBuildingCommentInput, TeamTool } from 'utils/generated'

import { DefaultButton } from '../components'
import { LoadingCircular } from '../components/LoadingCircular'
import { TeamsHooksContext } from '../contexts/HooksContextTeams'
import { useCurrentUser } from '../hooks'
import { firstTab } from '../pages/dashboard/components/tabs/Tabs'
import { isLeader, isMember } from '../utils/teamMember'

import { QuestionForm, QuestionInfo } from './components/Question'

import checkSvg from '../assets/svg/check.svg'
import { Pages } from 'assets'
import * as constants from 'assets/constants'
import { replacePathParams } from 'assets/history'

enum Status {
  Open = 'OPEN',
  Complete = 'COMPLETE',
  Completed = 'COMPLETED',
  Closed = 'CLOSED',
}

const QUESTIONS: { [name: string]: QuestionInfo } = {
  q1: { questionNumber: 1, label: 'チームに貢献している実感はあるか' },
  q2: { questionNumber: 2, label: 'チーム内で、何でも率直に話せているか' },
  q3: { questionNumber: 3, label: 'チームの方針や方向性に、納得しているか' },
  q4: { questionNumber: 4, label: 'チームに対して、一体感を感じるか' },
  q5: { questionNumber: 5, label: '自分の力を十分発揮できているか' },
  q6: { questionNumber: 6, label: '働きがいを感じているか' },
}

const strictKeys = <T extends Record<string, any>>(object: T): (keyof T)[] => {
  return Object.keys(object)
}

const getFormattdInput = (teamId: string, summaryId: string, data: FormInput) => {
  const initComments: TeamBuildingAnketCommentInput[] = []
  const comment: TeamBuildingAnketCommentInput[] = strictKeys(TeamBuildingQuestionTitle).reduce((prev, key) => {
    const answer = data[key]
    if (answer && answer.comment?.message) {
      return [
        ...prev,
        {
          target: key,
          title: answer.comment.title,
          message: answer.comment.message,
        },
      ]
    }
    return prev
  }, initComments)

  const hasFreeComment = !!data.freeComment.message
  const freeComment: TeamAnketBuildingCommentInput[] | undefined = hasFreeComment
    ? [
        {
          targetId: FreeCommentTarget.all,
          message: data.freeComment.message ? data.freeComment.message : '',
        },
      ]
    : undefined

  const getFormattedValue = (value: number | undefined) => {
    if (typeof value === 'number') {
      return value + 5
    }
    return undefined
  }
  const input: CreateTeamAnketBuildingInput = {
    teamId,
    summaryId,
    q1: getFormattedValue(data.q1?.value),
    q2: getFormattedValue(data.q2?.value),
    q3: getFormattedValue(data.q3?.value),
    q4: getFormattedValue(data.q4?.value),
    q5: getFormattedValue(data.q5?.value),
    q6: getFormattedValue(data.q6?.value),
    // q7: getFormattedValue(data.q7?.value),
    comment,
    freeComment,
  }
  return input
}

export const TeamsAnketNewPage: React.FC = () => {
  const classes = useStyles()

  const { setTeamId, currentTeamMember, currentTeamMemberLoaded } = React.useContext(TeamsHooksContext)
  const { control, errors, handleSubmit } = useForm<FormInput>()
  const { teamId, anketId } = useParams<{ teamId: string; anketId: string }>()
  const { user } = useCurrentUser()
  const { teamSummaryItem: item, loadingEvent, loadingRefresh } = useTeamAnketBuildingSummaryListByTeam(teamId)

  const loading = loadingEvent || loadingRefresh || !currentTeamMemberLoaded

  const useFormProps = { control }

  const [status, setStatus] = React.useState<Status | undefined>(undefined)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>()

  const prevAnswer = React.useMemo(() => {
    if (item?.closedSummaryList?.length) {
      const prev = item.closedSummaryList[0]
      return prev.anketList?.find(({ userId }) => userId === user?.id)
    }
    return null
  }, [item?.closedSummaryList, user?.id])

  const onSubmit = async (data: FormInput) => {
    const input = getFormattdInput(teamId, anketId, data)
    await createTeamAnketBuilding(input)
      .then(() => {
        setStatus(Status.Complete)
      })
      .catch(({ errors }) => {
        if (errors.length > 0) {
          setErrorMessage(errors[0].message)
        }
      })
  }

  const onClose = () => window.close()

  React.useEffect(() => {
    if (item?.openSummary) {
      setStatus(Status.Open)
    }
  }, [item?.openSummary])

  React.useEffect(() => {
    if (teamId) {
      setTeamId(teamId)
    }
  }, [teamId, setTeamId])

  if (loading) {
    return <LoadingCircular loading />
  }

  if (!isLeader(currentTeamMember) && !isMember(currentTeamMember)) {
    return (
      <div className={classes.sorry}>
        <p>アンケートはリーダーとメンバーのみ回答できます</p>
        <Link
          to={{
            pathname: replacePathParams(Pages.TeamsDashboard, { teamId }),
            state: { [firstTab]: TeamTool.BuildingCheck },
          }}
        >
          ダッシュボードへ移動
        </Link>
      </div>
    )
  }

  return (
    <div className={classes.back}>
      <div className={classes.head}>
        <div className="headerContent">
          <h1>アンケート</h1>
          {item?.openSummary && <div className={classes.date}>{item?.openSummary.date}</div>}
        </div>
      </div>
      {status && status !== Status.Open && (
        <>
          <div className={classes.lockWrapper}>
            {status === Status.Complete && (
              <>
                <p className={classes.completed}>アンケート回答完了</p>
                <div>
                  <img className={classes.checkMark} src={checkSvg} alt="check mark" />
                </div>
              </>
            )}

            <Typography variant="h6" className={classes.lockMessage}>
              {(() => {
                switch (status) {
                  case Status.Complete:
                    return 'おつかれさまでした。\nアンケート回答終了しました。'
                  case Status.Completed:
                    return 'すでに回答済みです'
                  case Status.Closed:
                    return 'すでに回答が締め切られています'
                }
              })()}
            </Typography>
          </div>

          <div className={classes.closeWrapper}>
            <DefaultButton
              title="閉じる"
              color="team_main"
              onClick={onClose}
              style={{
                width: '100%',
                maxWidth: 343,
                height: 48,
                borderRadius: 24,
              }}
            />
          </div>
        </>
      )}
      {!item?.openSummary && (
        <Typography variant="h6" className={classes.lockMessage}>
          アンケートは開催されていません
        </Typography>
      )}
      {errorMessage && (
        <div className={classes.lockMessage}>
          <Typography variant="h5" color="error">
            {errorMessage}
          </Typography>
        </div>
      )}
      {status === Status.Open && !errorMessage && (
        <div className={classes.questionsContainer}>
          <div className={classes.description}>
            <p>
              とてもそう思う5、どちらでもない0、とてもそう思わない-5とした時に、各質問にご回答ください。2回目以降は前回と比較してのそれぞれの実感度合いをお答えください。
            </p>
          </div>
          {/* checkKeyDown によって、コメント欄で Enter キーを押したときに submit が実行されるのを抑止する。 */}
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <QuestionForm
              name={'q1'}
              {...QUESTIONS.q1}
              {...useFormProps}
              error={errors.q1}
              beforeValue={prevAnswer?.q1}
            />
            <QuestionForm
              name={'q2'}
              {...QUESTIONS.q2}
              {...useFormProps}
              error={errors.q2}
              beforeValue={prevAnswer?.q2}
            />
            <QuestionForm
              name={'q3'}
              {...QUESTIONS.q3}
              {...useFormProps}
              error={errors.q3}
              beforeValue={prevAnswer?.q3}
            />
            <QuestionForm
              name={'q4'}
              {...QUESTIONS.q4}
              {...useFormProps}
              error={errors.q4}
              beforeValue={prevAnswer?.q4}
            />
            <QuestionForm
              name={'q5'}
              {...QUESTIONS.q5}
              {...useFormProps}
              error={errors.q5}
              beforeValue={prevAnswer?.q5}
            />
            <QuestionForm
              name={'q6'}
              {...QUESTIONS.q6}
              {...useFormProps}
              error={errors.q6}
              beforeValue={prevAnswer?.q6}
            />
            {/* <QuestionForm
                  name={'q7'}
                  {...QUESTIONS.q7}
                  {...useFormProps}
                  error={errors.q7}
                  beforeValue={prevAnswer?.q7}
                  error={errors.q7?.comment}
                /> */}

            <div className={classes.hr}>
              <hr />
            </div>

            <div className={classes.question}>
              <Controller
                name={'freeComment.message'}
                control={control}
                defaultValue={''}
                render={({ onChange, value }) => (
                  <TextField
                    classes={{
                      root: classes.text,
                    }}
                    InputProps={{
                      classes: { input: classes.input },
                    }}
                    placeholder={'コメントを入力する'}
                    multiline
                    minRows={3}
                    onChange={onChange}
                    value={value}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <div className={classes.buttonsBox}>
                <DefaultButton
                  style={{
                    minWidth: 106,
                    width: 106,
                    height: 32,
                    fontSize: 12,
                    fontWeight: 'normal',
                    borderRadius: 16,
                  }}
                  color="cancel"
                  type="button"
                  onClick={() => window.close()}
                >
                  キャンセル
                </DefaultButton>
                <DefaultButton
                  style={{ minWidth: 106, width: 106, height: 32, fontSize: 12, borderRadius: 16 }}
                  color="team_main"
                  type="submit"
                >
                  送信する
                </DefaultButton>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    sorry: {
      padding: 16,

      '& p': {
        margin: '0 0 16px',
      },

      '& a': {
        color: constants.COLOR_MAIN_NEW,

        '@media (hover: hover)': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    back: {
      backgroundColor: '#F2F4F4',
      minHeight: '100vh',
      [theme.breakpoints.down('md')]: {
        marginTop: -48, // <App /> の <main /> で設定されている padding-top: 48px; を打ち消す
      },
    },
    head: {
      padding: 16,
      backgroundColor: constants.COLOR_MAIN_NEW,
      height: 60,

      '& .headerContent': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 600,
        margin: '0 auto',
        color: '#fff',
      },
      '& h1': {
        margin: 0,
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
    date: {
      borderRadius: 16,
      background: '#fff',
      padding: '4px 24px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },
    lockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    completed: {
      margin: '40px 0 28px',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
    },
    checkMark: {
      width: 80,
      height: 80,
      verticalAlign: 'top',
    },
    closeWrapper: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      display: 'grid',
      placeItems: 'center',
      padding: '0 16px',
      width: '100%',
      height: 80,
      backgroundColor: '#fff',
    },
    questionsContainer: {
      padding: '0 16px 24px',
      backgroundColor: '#fff',
    },
    description: {
      width: '100%',
      maxWidth: 600,
      margin: '0 auto',
      padding: '24px 0',

      '& p': {
        margin: 0,
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    lockMessage: {
      margin: '40px 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    text: {
      '& label.Mui-focused': {
        color: '#fff',
      },
      '& .MuiInput-underline:after': {
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
      },
      '& .MuiOutlinedInput-root': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
        borderRadius: 8,

        '& fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        },
        '&:hover fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
        },
        '&.Mui-focused fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
        },
      },
      '& .MuiOutlinedInput-multiline': {
        padding: 16,
      },
    },
    input: {
      '&::placeholder': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        fontSize: 12,
        opacity: 1,
      },
    },
    hr: {
      display: 'flex',
      justifyContent: 'center',

      '& hr': {
        margin: 0,
        width: '100%',
        maxWidth: 600,
        height: 1,
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
        border: 'none',
      },
    },
    question: {
      margin: '0 auto',
      width: '100%',
      maxWidth: 600,
    },
    buttonsBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 16,
      marginTop: 16,
    },
  }),
  { name: 'new' }
)
