import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    margin: '0 auto',
    maxWidth: '1080px',

    [theme.breakpoints.down('lg')]: {
      padding: 0,
      maxWidth: '100%',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  maxWidth: number
}

// -----------------------------
// Component
// -----------------------------
export const Container = (props: IProps) => {
  const classes = useStyles()

  return <section className={classes.root}>{props.children}</section>
}
