import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ModalConfirmForWarning } from 'pages/ghost/components/modules/ModalConfirmForWarning'
import { ReloadButton } from 'pages/ghost/components/modules/ReloadButton'
import { CommandContext } from 'pages/ghost/service/commands'
import { GhostMemberRole } from 'utils/generated'

import close from '../../assets/svg/closeGray.svg'
import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'
import { PageManagerContext } from '../../contexts/pageManger'
import { UsefulCard, Imgcard, Font } from '../atoms'

export const Header: React.FC = () => {
  const classes = useStyles()
  const { teamMember } = React.useContext(ghostTeamMemberContext)
  const { terminateGame } = React.useContext(CommandContext)
  const { checkPgeRange } = React.useContext(PageManagerContext)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <header className={classes.root}>
      <div className={classes.wrapper}>
        <div>
          <img src={process.env.PUBLIC_URL + '/assets/ghost/ghostLogo.svg'} alt="cocolabo-ghost" />
        </div>
        <div className={classes.headerRightBox}>
          <ReloadButton />
          {teamMember?.role === GhostMemberRole.Leader && checkPgeRange(teamMember, 5, 35) && (
            <UsefulCard
              backgroundColor="white"
              borderRadius="none"
              onClick={handleClick}
              ownStyles={{ cursor: 'pointer' }}
            >
              <Imgcard src={close} alt="close" />
              <Font fontSize={10} bold color={constGhost.COLOR_GRAY2} ownStyles={{ cursor: 'pointer' }}>
                オバケツール強制終了
              </Font>
            </UsefulCard>
          )}
        </div>
      </div>
      <ModalConfirmForWarning
        isOpen={isOpen}
        handleModalChange={handleClick}
        modalTitle="オバケツール強制終了"
        warningMessage={
          <>
            終了すると、今まで行ってきた<span className={classes.redText}>データは全て消滅</span>し、
            <span className={classes.redText}>次回は最初からスタート</span>することになります。よろしいですか？
          </>
        }
        firstButtonText="終了する"
        secondButtonText="キャンセル"
        onClickFirstButton={terminateGame}
      />
    </header>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 56,
      padding: '0 16px',
      backgroundColor: constGhost.COLOR_WHITE,
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up(constGhost.BACKCARD_WIDTH + 32)]: {
        maxWidth: constGhost.BACKCARD_WIDTH,
      },
    },
    headerRightBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 15,
    },
    redText: {
      color: constGhost.COLOR_WARNING_RED,
      fontWeight: 'bold',
    },
  }),
  { name: 'Header' }
)
