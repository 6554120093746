import React, { useEffect, useRef, useContext } from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { OnbContext } from '../../../pages/PagesRoot'

export type Props = {
  item: ITEM
}

interface ITEM {
  id: number
  movieOwnerId: string
  title: string
  secondTitle?: string
  role: string
  src: string
}

export const Movie: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const { teamId } = useContext(OnbContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    videoRef.current?.play()
  }, [])

  return item.movieOwnerId === teamId || item.movieOwnerId === 'COMMON' ? (
    <div className={classes.iframeWrapper}>
      <div className={classes.movieWrapper}>
        <iframe className={classes.iframe} src={item.src} allow="autoplay; fullscreen" />
        <div>{item.title}</div>
        <div>{item.secondTitle}</div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    iframeWrapper: {
      paddingBottom: 25,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        display: 'flex',
      },
    },

    movieWrapper: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
      },
    },

    iframe: {
      width: 225,
      height: '100%',
      borderRadius: 8,
      border: 'none',
      [theme.breakpoints.down('md')]: {
        width: 340,
        height: 195,
      },
    },
  }),
  { name: 'Movie' }
)
