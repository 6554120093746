import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Theme } from '@mui/material'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import { useForm } from 'react-hook-form'

import * as constants from '../../../../assets/constants'
import * as pages from '../../../../assets/pages'
import Button from '../../../../components/Button'

import FormDate from './FormDate'
import { connector, ContainerProps } from './FormModalStartSetup.container'

interface IOwnProps {
  open: boolean
  handleClose: any
  setSetupStartedAt?: any
  handleSubmitCallback?: any
}

interface IStartSetup {
  date: Dayjs
}

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  const { register, handleSubmit, setValue } = useForm<IStartSetup>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const handleRedirectToHome = () => {
    props.history.push(pages.PAGE_SETUP_HOME)
    window.scrollTo(0, 0)
  }

  const onSubmit = (data: IStartSetup) => {
    const { user, submit, handleClose, handleSubmitCallback } = props
    const params = {
      setupStartedAt: user.setupStartedAt ?? dayjs(data.date).format('YYYY-MM-DD'),
      setupStartedAtList: user.setupStartedAtList
        ? [...user.setupStartedAtList, dayjs(data.date).format('YYYY-MM-DD')]
        : [dayjs(data.date).format('YYYY-MM-DD')],
    }
    console.log('onSubmit params', params)
    submit(params)
    handleClose()

    if (typeof handleSubmitCallback === 'function') {
      handleSubmitCallback(dayjs(data.date).format('YYYY-MM-DD'))
    }
  }

  React.useEffect(() => {
    const hasSetupStartedAt = !!props.user.setupStartedAtList

    if (props.open && hasSetupStartedAt && props.history.location.pathname === pages.PAGE_SETUP_LANDING) {
      handleRedirectToHome()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <Modal
      open={props.open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.handleClose(event, reason)
        }
      }}
      aria-labelledby="action-modal-title"
      aria-describedby="action-modal-description"
      className={classes.modal}
    >
      <Paper className="__modalPaper">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormDate
            name="date"
            value=""
            label="チーム開始日（着任日）"
            register={register({ name: 'date' }, {})}
            setValue={setValue}
          />

          <div className={classes.buttons}>
            <Button
              bgColor={constants.COLOR_WHITE}
              textColor={constants.COLOR_BLACK}
              fullWidth={true}
              onClick={props.handleClose}
              body={<div>キャンセル</div>}
            />
            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              submit={true}
              body={<div>設定</div>}
            />
          </div>
        </form>
      </Paper>
    </Modal>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .__modalPaper': {
        background: constants.COLOR_GRAY,
        padding: theme.spacing(2),
        width: '100%',
        margin: '0 16px',
        maxHeight: '90vh',
        overflow: 'scroll',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },
        '&:focus': {
          outline: 'none',
        },

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: '18px',
          fontWeight: 'bold',
          padding: '16px 0',
          textAlign: 'center',
        },
      },
    },

    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 0',
      '& :first-child': {
        marginRight: '4px',
      },
      '& :last-child': {
        marginLeft: '4px',
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
