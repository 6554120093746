import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { ghostTeamContext } from 'pages/ghost/contexts/ghostTeam'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import { BackResponsiveCard, ButtonForGhost, GrayBackGround } from '../components/atoms'
import { WaitingContainer } from '../components/modules/WaitingContainer'
import { ProgressBoard } from '../components/organisms'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { CommandContext } from '../service/commands'

type Props = {}

export const Step1Start: React.FC<Props> = () => {
  const classes = useStyles()

  const { teamId } = useParams<{ teamId: string }>()
  const { teamMember } = useContext(ghostTeamMemberContext)
  const step = 1
  const { updateGhostTeam } = useContext(ghostTeamContext)
  const { runGoToNextPage } = useContext(CommandContext)

  const next = async () => {
    teamMember && (await runGoToNextPage(teamMember))
    await updateGhostTeam({
      input: { id: teamId, progress: GhostProgress.S1Started, timerS1Started: new Date().toISOString() },
    })
  }

  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard>
          <WidthControlContainer>
            <div className={classes.mainTitle}>オバケへようこそ</div>

            <div className={classes.textContent}>
              下記流れに沿って進めていきます。
              {teamMember?.role === GhostMemberRole.Leader && (
                <>
                  <br />
                  「次へ」を押下してください。ファシリテーターのあなたは途中で終了せざるを得ない場合に右上の「強制終了」で終了することができます。
                  <br />
                  <span className={classes.textAttention}>
                    ※終了すると、今まで行ってきたデータは全て消滅し、次回は最初からスタートすることになります。
                  </span>
                </>
              )}
            </div>
            <ProgressBoard step={step} />

            {teamMember?.role === GhostMemberRole.Leader ? (
              <ButtonContainer
                fixed
                buttons={[
                  <ButtonForGhost
                    key="ghost-rule"
                    buttonBodyColor="green"
                    bodyText="次へ"
                    onClick={next}
                    isResizeBasic
                  />,
                ]}
              />
            ) : (
              <WaitingContainer />
            )}
          </WidthControlContainer>
        </BackResponsiveCard>
      </GrayBackGround>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    mainTitle: {
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
      paddingBottom: 24,
      display: 'flex',
      justifyContent: 'flexStart',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    textContent: {
      color: constGhost.COLOR_BLACK3,
      lineHeight: 1.6,
    },
    textAttention: {
      color: constGhost.COLOR_WARNING_RED,
    },
  }),
  { name: 'Step1Start' }
)
