import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  numerator?: number
  pcSize?: number
  mdSize?: number
  spSize?: number
}

type StyleProps = {
  ratio: number
  pcSize: number
  mdSize: number
  spSize: number
}

export const PieChartForKickoffTab: React.FC<Props> = ({ numerator = 0, pcSize = 90, mdSize = 80, spSize = 70 }) => {
  const ratio = Math.round((numerator / 100) * 100)
  const classes = useStyles({ ratio, pcSize, mdSize, spSize })

  return (
    <div className={classes.circle}>
      <div className={classes.graphInfo}>
        <div className={classes.ratio}>
          <span className={classes.numerator}>{ratio}</span>
          <span>%</span>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    circle: ({ ratio, pcSize, mdSize, spSize }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: pcSize,
      width: pcSize,
      height: pcSize,
      backgroundImage: `radial-gradient(${constants.COLOR_WHITE} 52%, transparent 53%), conic-gradient(${constants.COLOR_MAIN_NEW} 0% ${ratio}%, ${constants.COLOR_TEAMBUILDING_NEUTRAL_200} ${ratio}% 100%)`,
      borderRadius: '50%',

      [theme.breakpoints.down('lg')]: {
        minWidth: mdSize,
        width: mdSize,
        height: mdSize,
      },

      [theme.breakpoints.down('md')]: {
        minWidth: spSize,
        width: spSize,
        height: spSize,
      },
    }),
    graphInfo: {
      textAlign: 'center',
    },
    ratio: {
      color: '#666',
      fontSize: 12,
      fontWeight: 'bold',
    },
    numerator: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      fontSize: 16,
    },
  }),
  { name: 'PieChartForKickoffTab' }
)
