import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constants.COLOR_MAIN,
    color: constants.COLOR_WHITE,
    width: '110px',
    padding: '10px 20px',
    fontSize: '16px',
    lineHeight: 1.125,
    borderRadius: '8px',

    [theme.breakpoints.down('lg')]: {
      width: '64px',
      padding: '5px 10px',
      fontSize: '10px',
      lineHeight: 0.9,
      borderRadius: '4px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
}

// -----------------------------
// Component
// -----------------------------
export const Category = (props: IProps) => {
  const classes = useStyles()

  return <span className={classes.root}>{props.text}</span>
}
