import React, { useContext, useEffect, useState } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { LoadingIndicator } from 'pages/ghost/components/organisms/LoadingIndicator'
import { CommandContext } from 'pages/ghost/service/commands'

import { GhostMemberRole, GhostMemberManage } from '../../../../utils/generated'
import { GrayBackGround, BackResponsiveCard, PageTitle, LeadText, ButtonForGhost, Font } from '../../components/atoms'
import {
  WaitingMessage,
  WhiteGhostImg,
  ButtonContainer,
  SelectLeaderFromMember,
  ButtonForGhostList,
} from '../../components/modules'
import { ghostTeamContext } from '../../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'

export const Waiting: React.FC = () => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const { team: ghostTeam } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList, updateGhostTeamMember, dropMembers } = useContext(ghostTeamMemberContext)
  const { runGoToSelectLeader, runDeleteGhostTeam, runcDeleteGhostTeamMember } = useContext(CommandContext)

  const isStart = teamMemberList.filter((member) => member.manager === GhostMemberManage.Accepted).length >= 2

  const [updating, setUpdating] = useState<boolean>(false)
  const forceForward = () => {
    if (!ghostTeam || updating) return
    setUpdating(true)
    const forward = async () => {
      await dropMembers('waiting')
      await runGoToSelectLeader(ghostTeam)
      setUpdating(false)
    }
    forward()
  }

  useEffect(() => {
    if (!ghostTeam || !ghostTeam.id || teamMember?.manager === GhostMemberManage.Accepted) {
      return
    }

    const performUpdate = async () => {
      await updateGhostTeamMember({
        input: { id: ghostTeam?.id, manager: GhostMemberManage.Accepted, masterTeamId: ghostTeam.masterTeamId },
      })
    }

    performUpdate()
  }, [ghostTeam, teamMember, updateGhostTeamMember])

  const isAdmin = teamMember?.role === GhostMemberRole.Admin
  const [adminName, isAdminName] = useState('Loading...')
  useEffect(() => {
    const name = teamMemberList.filter((item) => item.role === GhostMemberRole.Admin)[0]?.name
    if (!name) {
      isAdminName('Loading...')
    } else {
      isAdminName(name)
    }
  }, [teamMemberList])

  const onResetTeamClick = () => {
    if (window.confirm('このおばけ探索チームを終了しますか？')) {
      runDeleteGhostTeam()
    }
  }

  if (!teamMember || teamMember.manager !== GhostMemberManage.Accepted)
    return <LoadingIndicator snapshot={{ vars: { teamMember } }} />

  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard>
          <PageTitle>メンバーの参加を待っています</PageTitle>
          <LeadText>
            {isAdmin
              ? '2人以上のメンバーが揃ったら「開始する」をクリックしてください。'
              : 'メンバーが参加するまでしばらくお待ちください。'}
          </LeadText>
          <Font
            fontSize={14}
            ownStyles={{
              marginBottom: isSmDown ? '6px' : '0',
            }}
          >
            このおばけ探索を立ち上げている方は
            <span style={{ color: constGhost.COLOR_MAIN_NEW, fontWeight: 'bold' }}>{adminName}</span>
            です。
          </Font>
          {isAdmin ? (
            <Font
              fontSize={14}
              ownStyles={{
                marginBottom: isSmDown ? '6px' : '0',
              }}
            >
              おばけ探索を実施しない場合は、画面下の
              <span style={{ color: constGhost.COLOR_MAIN_NEW, fontWeight: 'bold' }}>「キャンセル」</span>
              ボタンを押してください。
            </Font>
          ) : (
            <>
              <Font
                fontSize={14}
                ownStyles={{
                  marginBottom: isSmDown ? '6px' : '0',
                }}
              >
                おばけ探索から抜ける時は、画面下の
                <span style={{ color: constGhost.COLOR_MAIN_NEW, fontWeight: 'bold' }}>「参加を辞退する」</span>
                ボタンを押してください。
              </Font>
              <Font
                fontSize={14}
                ownStyles={{
                  marginBottom: isSmDown ? '6px' : '0',
                }}
              >
                この探索を立ち上げている方が不在でこの画面から進められず、もう一度やり直したい場合は、こちらの
                <span
                  style={{
                    color: constGhost.COLOR_MAIN_NEW,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textDecorationColor: constGhost.COLOR_MAIN_NEW,
                  }}
                  onClick={onResetTeamClick}
                >
                  「一度おばけ探索を終了する」
                </span>
                を押して下さい。
              </Font>
            </>
          )}
          <Font fontSize={14}>
            現在表示されている参加メンバーでの実施となります。記載がないメンバーは途中から参加ができませんのでご注意下さい。
            {isSmDown && <br />}まだ記載がないメンバーがいる場合は、メンバーが画面上に表示されるまでお待ち下さい。
          </Font>

          <WaitingMessage ownStyles={isSmDown ? { margin: '45px 0 0' } : { margin: '24px 0 0' }} />
          {isSmDown ? <WhiteGhostImg ownStyles={{ margin: '24px auto -5px', width: '30%', minWidth: 150 }} /> : <></>}
          <SelectLeaderFromMember
            currentMembers={teamMemberList.filter((member) => member.manager === GhostMemberManage.Accepted)}
            page={'else'}
            ownStyles={isSmDown ? { margin: '0 0 24px' } : { margin: '24px 0' }}
          />
          <ButtonForGhostList visibleToGhostCounter ghostVisibleInPC ownStyles={{ margin: '0 0 150px' }} />

          {isAdmin ? (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="InvitePage-1-1"
                  buttonBodyColor={
                    teamMemberList.filter((member) => member.manager === GhostMemberManage.Accepted).length >= 2
                      ? 'green'
                      : 'gray'
                  }
                  bodyText={'開始する'}
                  onClick={forceForward}
                  disabled={!isStart}
                  isResizeBasic
                />,
                <ButtonForGhost
                  key="InvitePage-1-2"
                  buttonBodyColor={'white'}
                  bodyText={'キャンセル'}
                  onClick={runDeleteGhostTeam}
                  isResizeBasic
                />,
              ]}
            />
          ) : (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="InvitePage-2-1"
                  buttonBodyColor={'green'}
                  bodyText={'参加を辞退する'}
                  onClick={runcDeleteGhostTeamMember}
                  isResizeBasic
                />,
              ]}
            />
          )}
        </BackResponsiveCard>
      </GrayBackGround>
    </>
  )
}
