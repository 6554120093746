import * as React from 'react'

import { Grow } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from 'assets/constants'

type Props = {
  imgURL: string
  title: string
  name: string
  link: string
  isOpenNewTab: boolean
}

export const CaseCard: React.FC<Props> = ({ imgURL, title, name, link, isOpenNewTab }) => {
  const classes = useStyles({})
  const [imgWide, setImageWide] = React.useState<boolean>(false)

  const newTab = isOpenNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : undefined

  return (
    <Grow in timeout={1000}>
      <a href={link} target={newTab?.target} rel={newTab?.rel}>
        <div
          className={classes.caseCardRoot}
          onMouseOver={() => setImageWide(true)}
          onMouseOut={() => setImageWide(false)}
        >
          <div className={classes.imgWrap}>
            <img
              className={classNames([classes.img, imgWide ? 'imgWide' : ''])}
              src={`${process.env.PUBLIC_URL}/assets/landing/case/${imgURL}`}
              alt={imgURL}
            />
          </div>
          <div className={classes.textBox}>
            <div className={classes.title}>{title}</div>
            <div className={classes.name}>{name}</div>
          </div>
        </div>
      </a>
    </Grow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  caseCardRoot: {
    margin: 0,
    width: '100%',
    height: 'auto',
    boxShadow: '0 3px 6px 0 #0000001E',
    borderRadius: 8,
    backgroundColor: constants.COLOR_WHITE,
    aspectRatio: '1 / 1',
  },
  imgWrap: {
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    borderRadius: '8px 8px 0 0',
    aspectRatio: '380 / 232',
    objectFit: 'cover',
    '&.imgWide': {
      animation: `$scale forwards 300ms ${theme.transitions.easing.easeInOut}`,
      '@global': {
        '@keyframes scale': {
          '0%': {
            transform: 'scale(1)',
          },
          '100%': {
            transform: 'scale(1.2)',
          },
        },
      },
    },
  },
  title: {
    ...constTop.smallTopicTextStyles,
    margin: '16px 16px 0',
    lineHeight: 1.75,
  },
  name: {
    padding: '24px 16px',
    fontSize: 12,
    color: constTop.COLOR_TEXT_GRAY,
  },
  [theme.breakpoints.up(constTop.breakPointsSP)]: {
    caseCardRoot: {
      width: '100%',
      boxShadow: '0 3px 6px 0 #0000001E',
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
    },
    imgWrap: {
      width: '100%',
      borderRadius: '8px 8px 0 0',
    },
  },
}))
