import React from 'react'

import type { Mark } from '@mui/material/Slider/useSlider.types.d.ts'
import { Theme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { Control, Controller, DeepMap, FieldError, useWatch } from 'react-hook-form'
import { Question } from 'stores/anket/types'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { FormError } from 'pages/teams/components'

import Slider from './Slider'

import { constants } from 'assets'

const VALUES: Mark[] = [
  { value: -5, label: '-5' },
  { value: -4, label: '-4' },
  { value: -3, label: '-3' },
  { value: -2, label: '-2' },
  { value: -1, label: '-1' },
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
]
export type QuestionInfo = {
  questionNumber: number
  label: string
}
export type QuestionAnswer = {
  value: number
  comment: {
    title: string
    message: string
  }
}

const selectColor = (value: number | undefined) => {
  if (value === undefined) return constants.TEXT_GRAY_DARK
  if (value < 0) return constants.COLOR_RED4
  if (value > 0) return constants.COLOR_MAIN_NEW
  return constants.TEXT_GRAY_DARK
}

type Props = QuestionInfo & {
  name: 'q1' | 'q2' | 'q3' | 'q4' | 'q5' | 'q6' | 'q7'
  control: Control<any>
  error?: DeepMap<Question | undefined, FieldError>
  beforeValue: number | null | undefined
}

export const QuestionForm: React.FC<Props> = ({ questionNumber, label, name, beforeValue, control, error }) => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'sm')

  const formattedBeforeValue = React.useMemo(
    () => (typeof beforeValue === 'number' ? beforeValue - 5 : beforeValue),
    [beforeValue]
  )

  const current = useWatch<{
    value: number
    comment: {
      title: string
      message: string
    }
  }>({ name, control })

  return (
    <div className={classes.question}>
      <div className={classes.questionLabelBox}>
        <div className={classes.questionLabel}>
          <div className={classes.questionNumber}>{questionNumber}</div>
          {label}
        </div>
        {formattedBeforeValue || formattedBeforeValue === 0 ? (
          <div className={classes.beforeValue}>前回 : {formattedBeforeValue}</div>
        ) : null}
      </div>

      <div className={classes.sliderBox}>
        <div className={classes.slider}>
          <Controller
            name={`${name}.value`}
            control={control}
            defaultValue={0}
            value={current?.value ?? 0}
            rules={{ required: '入力してください' }}
            render={({ onChange, name }) => (
              <>
                <Slider
                  name={name}
                  options={VALUES}
                  onChange={onChange}
                  value={current?.value ?? 0}
                  aria-labelledby="input-slider"
                />
              </>
            )}
          />
        </div>

        <div className={classes.input}>
          <Controller
            name={`${name}.value`}
            control={control}
            defaultValue={0}
            value={current?.value ?? 0}
            render={({ onChange }) => (
              <>
                <TextField
                  classes={{
                    root: classes.text,
                  }}
                  type="number"
                  onChange={(e) => onChange(Number(e.target.value))}
                  value={current?.value ?? 0}
                  variant="outlined"
                  inputProps={{
                    step: 1,
                    min: -5,
                    max: 5,
                    style: {
                      padding: '0 2px',
                      textAlign: isXsDown ? 'center' : 'right',
                      color: selectColor(current?.value),
                    },
                  }}
                  aria-labelledby="input-slider"
                />
                <span className={`${classes.inputLabel} __point`}>点</span>
              </>
            )}
          />
        </div>
      </div>

      <Typography variant="body1" color="error">
        {error && <FormError error={error.value} />}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    questionLabelBox: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
      marginBottom: 38,

      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
      },
    },
    questionLabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 'bold',
    },
    questionNumber: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      marginRight: 10,
      borderRadius: 12,
      width: 24,
      height: 24,
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: '#fff',
    },
    beforeValue: {
      display: 'grid',
      placeItems: 'center',
      height: 26,
      padding: '0 12px',
      backgroundColor: '#fff',
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 13,
      whiteSpace: 'nowrap',
    },
    question: {
      margin: '0 auto',
      width: '100%',
      maxWidth: 600,
      padding: 24,
      backgroundColor: constants.COLOR_WHITE2,
      color: constants.TEXT_GRAY_DARK,
      borderRadius: 8,

      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
    },

    sliderBox: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 32,

      [theme.breakpoints.down('sm')]: {
        gap: 24,
      },
    },
    slider: {
      flex: 1,
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
    },
    inputLabel: {
      fontSize: 14,
      '&.__point': {
        marginLeft: 10,
      },
    },
    text: {
      '& label.Mui-focused': {
        color: '#fff',
      },
      '& .MuiInput-underline:after': {
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
      },
      '& .MuiOutlinedInput-root': {
        width: 44,
        height: 44,
        backgroundColor: '#fff',
        fontSize: 14,
        fontWeight: 'bold',
        borderRadius: 8,

        '& fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        },
        '&:hover fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
        },
        '&.Mui-focused fieldset': {
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_500}`,
        },
      },
    },
  }),
  { name: 'QuestionForm' }
)
