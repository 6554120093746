import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'
import { GhostContext } from 'pages/ghost/contexts/ghost'

import { GhostHistorySelectInTable } from './GhostHistorySelectInTable'
import { MemberInfo } from './MemberInfo'
import { MemberGhostHistories } from './useFormatGhostHistories'

import { TableFilter } from './index'

import { constants } from 'assets'

type Props = {
  membersGhostHistories: MemberGhostHistories[]
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
}

export const SelectedGhostsTableForPc: React.FC<Props> = ({ membersGhostHistories, setFilter }) => {
  const classes = useStyles()

  return (
    <table className={classes.pcTable}>
      <colgroup span={1}></colgroup>
      <colgroup span={1} className="__countColgroup"></colgroup>
      <colgroup span={3}></colgroup>
      <thead>
        <tr>
          <th>名前</th>
          <th>回</th>
          <th>オバケ1</th>
          <th>オバケ2</th>
          <th>オバケ3</th>
        </tr>
      </thead>
      <tbody>
        {membersGhostHistories.map((memberGhostHistories) => {
          return (
            <TableBodyRow
              key={`${memberGhostHistories.teamMemberId}`}
              memberGhostHistories={memberGhostHistories}
              setFilter={setFilter}
            />
          )
        })}
      </tbody>
    </table>
  )
}

const TableBodyRow = ({
  memberGhostHistories,
  setFilter,
}: {
  memberGhostHistories: MemberGhostHistories
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
}) => {
  const { ghosts } = React.useContext(GhostContext)
  const [isOpenList, setIsOpenList] = React.useState(false)
  const hasExpandData = memberGhostHistories.histories.length > 1
  const historyLength = memberGhostHistories.histories.length ?? 0
  const rowSpanValue = isOpenList ? (historyLength > 5 ? 5 : historyLength) : 1

  return (
    <>
      {memberGhostHistories.histories.map((history, i) => {
        const isLastMemberHistoryRow = i === rowSpanValue - 1

        // 選択したオバケがない場合は "ー" を表示
        const selectedGhostIds =
          history.selectedGhostIds.length > 0
            ? history.selectedGhostIds
            : Array.from({ length: 3 }, (_, index) => `dummy-${index}`)

        // 最新の5回分を表示
        // TODO: フィルタリング処理を追加する
        return i > 4 || (i > 0 && !isOpenList) ? null : (
          <tr
            key={`${memberGhostHistories.teamMemberId}-${history.playDate}`}
            className={classNames([(!isOpenList || isLastMemberHistoryRow) && '__bottomBorder'])}
          >
            {i === 0 && (
              <th rowSpan={rowSpanValue} className="__memberNameCellWrapper">
                <div className={classNames(['__cell', '__memberNameCell', isOpenList && '__activeCell'])}>
                  <div className="__memberNameCellBox">
                    <MemberInfo id={memberGhostHistories.teamMemberId} />
                    {hasExpandData && (
                      <NoStyleButton
                        onClick={() => setIsOpenList(!isOpenList)}
                        aria-label="過去のオバケ履歴を開く"
                        className="__arrowIconButton"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/svg/arrow-${isOpenList ? 'up' : 'down'}.svg`}
                          alt=""
                        />
                      </NoStyleButton>
                    )}
                  </div>
                </div>
              </th>
            )}
            <th>
              <div className={classNames(['__cell', '__countCell', isOpenList && '__activeCell'])}>
                {i === 0 ? (
                  <GhostHistorySelectInTable
                    histories={memberGhostHistories.histories}
                    memberId={memberGhostHistories.teamMemberId}
                    setFilter={setFilter}
                  />
                ) : (
                  `${history.times}回目`
                )}
              </div>
            </th>
            {selectedGhostIds.map((ghostId) => {
              const ghost = ghosts.find((g) => g.id === ghostId.toString())
              return (
                <td key={ghostId}>
                  <div className={classNames(['__cell', '__ghostNameCell', isOpenList && '__activeCell'])}>
                    <span>{ghost ? ghost.name : '－'}</span>
                  </div>
                </td>
              )
            })}
          </tr>
        )
      })}
    </>
  )
}

const useStyles = makeStyles(
  () => ({
    pcTable: {
      width: '100%',
      height: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      textAlign: 'left',

      '& thead th': {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        padding: 16,
        height: 56,
        fontSize: 12,
      },
      '& .__cell': {
        display: 'flex',
        alignItems: 'center',
        padding: 16,
        height: 56,
      },
      '& .__activeCell': {
        backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      },
      '& .__bottomBorder': {
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      '& .__memberNameCellWrapper': {
        verticalAlign: 'top',
      },
      '& .__memberNameCell': {
        display: 'flex',
        alignItems: 'flex-start',
        minWidth: 130,
        height: '100%',
      },
      '& .__memberNameCellBox': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        minWidth: 130,
        width: '100%',
      },
      '& .__arrowIconButton': {
        width: 16,
        height: 16,
      },
      '& .__countColgroup': {
        width: 95,
        borderRight: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
      '& .__countCell': {
        fontSize: 10,
        fontWeight: 'normal',
        width: 95,
      },
      '& .__ghostNameCell': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        fontSize: 12,
        fontWeight: 'bold',
        minWidth: 100,

        '& span': {
          overflow: 'hidden',
          display: '-webkit-box',
          '-webkit-box-orient': 'vertical',
          '-webkit-line-clamp': 2,
        },
      },
    },
  }),
  { name: 'SelectedGhostsTableForPc' }
)
