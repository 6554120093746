import React, { useEffect, useState, useCallback } from 'react'

import makeStyles from '@mui/styles/makeStyles';

import NoImage from 'pages/onboarding/assets/noImage.svg'
import { OnboardingTeamMember } from 'utils/generated'

import * as constants from 'assets/constants'

export const useMentions = <T,>(
  name: T,
  text: string,
  teamMembers: OnboardingTeamMember[],
  setValue: (name: T, value: unknown, config?: any) => void,
  element?: HTMLInputElement | null
) => {
  type TypeMentions = {
    teamMemberId: string
    nickname: string
    isMention: boolean
    imgURL?: string | null
  }
  //helpers --->
  const initValue = useCallback((): TypeMentions[] => {
    return teamMembers.map((items) => ({
      teamMemberId: items.id,
      nickname: items?.nickname ?? 'noNickname',
      isMention: false,
      imgURL: items.imageUrl,
    }))
  }, [teamMembers])

  const [mentions, setMentions] = useState<TypeMentions[]>([])

  const [isSuggestionOpen, setIsSuggestionOpen] = useState<boolean>(false)
  const openSuggestionBox = useCallback(() => setIsSuggestionOpen(true), [])
  const closeSuggestionBox = useCallback(() => setIsSuggestionOpen(false), [])

  const replaceFront = useCallback((text: string, pos: number | null) => {
    const dividedText = text.substring(0, pos ?? text.length)

    if (dividedText.endsWith('@')) {
      const slicedText = dividedText.slice(0, -1) // rm @ when a key is pressed.

      return slicedText ? `${slicedText} ` : '' // NOT add space when the mention is at the top.
    } else {
      return `${text.substring(0, pos ?? text.length)} `
    }
  }, [])

  const textWithMention = useCallback(
    (nickname: string, text: string, pos: number | null) => {
      const frontText = text.length && pos ? replaceFront(text, pos) : '' // [scene of ''] no text or cursor is at the top.
      const backText = pos !== text.length ? ` ${text.substring(pos ?? text.length, text.length)}` : '' // [scene of ''] no text or cursor is at the end.

      return `${frontText}@${nickname}${backText}`
    },
    [replaceFront]
  )

  const replaceTextValue = useCallback(
    (nickname: string) => {
      const pos = element?.selectionStart ?? null
      setValue(name, textWithMention(nickname, text, pos))
    },
    [text, name, setValue, element?.selectionStart, textWithMention]
  )
  const escapeRegExpText = useCallback((str: string) => str.replace('+', '\\+'), [])
  const isMentionsRegExp = useCallback(() => {
    const mentionsString = mentions.map((items) => escapeRegExpText(items.nickname)).join('|')
    return new RegExp(`@(${mentionsString})`, 'g')
  }, [mentions, escapeRegExpText])
  // <-- helpers

  const useMentionModels = () => {
    const checkHasMentionsInText = useCallback(async () => {
      if (!text) return
      const checkedHasMentions: string[] | null = text.match(isMentionsRegExp())
      const hasMentions = checkedHasMentions ? [...new Set(checkedHasMentions)] : ''

      if (!hasMentions) return setMentions(initValue())

      const newArray: TypeMentions[] = initValue()
      hasMentions.forEach((item) => {
        const index = newArray.findIndex((items) => items.nickname === item.slice(1))
        if (index === -1) return
        newArray[index].isMention = true
      })
      setMentions(newArray)
      // ↓ because of using text that is used like a global state
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text])

    const addNicknameToTextById = useCallback(
      (teamMemberId: string | undefined) => {
        if (!teamMemberId)
          return alert('メンション（カスタム通知）が登録できませんでした。お手数ですが再度お試しください。')
        mentions.map((items) => {
          if (items.teamMemberId === teamMemberId) {
            replaceTextValue(items?.nickname ?? 'noNickname')
          }
        })
      },
      // ↓ because of using mentions that is used like a global state
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [mentions, text]
    )

    const checkCorrectMentions = useCallback((): string[] | undefined => {
      const willUpdateMentions = mentions.filter((items) => items.isMention)
      if (!willUpdateMentions) return
      const teamMemberIdsToMention = willUpdateMentions.map((item) =>
        item.teamMemberId && item.nickname && text.match(escapeRegExpText(item.nickname) ?? '') ? item.teamMemberId : ''
      )
      return teamMemberIdsToMention
      // ↓ because of using mentions that is used like a global state
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mentions])

    const clearMentions = useCallback(() => {
      setMentions((prev) => prev.map((item) => ({ ...item, isMention: false })))
    }, [])

    const openSuggestionByTypeText = useCallback((e: KeyboardEvent) => {
      if (e.key === '@') {
        openSuggestionBox()
      } else {
        closeSuggestionBox()
      }
    }, [])

    const [atMarkCount, setAtMarkCount] = useState<number>(0)
    const closeSuggestionByTypeText = useCallback(() => {
      const newValue = text.match(new RegExp('@', 'g'))?.length ?? 0
      if (atMarkCount - newValue === 1) {
        setAtMarkCount(newValue)
        closeSuggestionBox()
      } else {
        setAtMarkCount(newValue)
      }
      // ↓ because of using text that is used like a global state
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, atMarkCount])

    return {
      checkHasMentionsInText,
      addNicknameToTextById,
      checkCorrectMentions,
      clearMentions,
      openSuggestionByTypeText,
      closeSuggestionByTypeText,
    }
  }

  const useMentionViews = () => {
    type SuggestBoxProps = {
      ownRootStyles?: { [key: string]: string | number | undefined }
      ownItemStyles?: { [key: string]: string | number | undefined }
    }

    const SuggestBox = ({ ownRootStyles, ownItemStyles }: SuggestBoxProps) => {
      // useful CSS
      const boxStyes = {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
        padding: '12px 16px',
        fontSize: 12,
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: constants.COLOR_ONBOARDING_WHITE_DARK,
        },
      }

      const useStyles = makeStyles(
        {
          suggestBoxRoot: {
            position: 'absolute',
            bottom: '-150px',
            zIndex: 3,
            backgroundColor: constants.COLOR_WHITE,
            overflowX: 'hidden',
            borderRadius: '8px',
            boxShadow: '0px 2px 5px 2px #00000015',
            width: '80%',
            maxHeight: '150px',
            ...ownRootStyles,
          },
          suggestBoxItem: {
            ...boxStyes,
            ...ownItemStyles,
          },
          suggestBoxEndItem: {
            ...boxStyes,
            fontSize: 10,
            ...ownItemStyles,
          },
          img: {
            display: 'block',
            height: 24,
            width: 24,
            margin: '0 8px 0 0',
          },
        },
        { name: 'SuggestBox' }
      )
      const classes = useStyles()
      const onMentionClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        addNicknameToTextById(e.currentTarget.dataset.teammemberid)
        closeSuggestionBox()
      }, [])
      const boxClose = useCallback(() => {
        closeSuggestionBox()
      }, [])

      return isSuggestionOpen ? (
        <div className={classes.suggestBoxRoot}>
          <>
            {mentions.map((item) => (
              <div
                key={`${item.teamMemberId}-mentions`}
                data-teammemberid={item.teamMemberId}
                onClick={onMentionClick}
                className={classes.suggestBoxItem}
              >
                <img alt="avatar" src={item.imgURL || NoImage} className={classes.img} />
                <div>{item.nickname}</div>
              </div>
            ))}
            <div onClick={boxClose} className={classes.suggestBoxEndItem}>
              メンションを飛ばさない
            </div>
          </>
        </div>
      ) : (
        <></>
      )
    }

    const convertMentionsStylesForInput = useCallback(() => {
      const mentionsReplacer = (nickname: string) => {
        return `<span style="background:${constants.COLOR_ONBOARDING_MAIN}2E; border-radius:4px">${nickname}</span>`
      }
      return text.replace(isMentionsRegExp(), mentionsReplacer)
      // ↓ because of using text that is used like a global state
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, isMentionsRegExp])

    const convertMentionsStylesForTimeline = useCallback((ownText: string) => {
      const mentionsReplacer = (nickname: string) => {
        return `<span style="color:${constants.COLOR_ONBOARDING_MAIN}">${nickname}</span>`
      }
      return ownText.replace(isMentionsRegExp(), mentionsReplacer)
    }, [])

    return { SuggestBox, convertMentionsStylesForInput, convertMentionsStylesForTimeline }
  }

  const {
    checkHasMentionsInText,
    addNicknameToTextById,
    checkCorrectMentions,
    clearMentions,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
  } = useMentionModels()
  const { SuggestBox, convertMentionsStylesForInput, convertMentionsStylesForTimeline } = useMentionViews()

  useEffect(() => {
    // init
    setMentions(initValue())
  }, [teamMembers, initValue])

  useEffect(() => {
    // update mentions
    checkHasMentionsInText()
  }, [text, checkHasMentionsInText])

  return {
    //models
    checkCorrectMentions,
    clearMentions,
    //controllers
    addNicknameToTextById,
    openSuggestionBox,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
    //views
    SuggestBox,
    convertMentionsStylesForInput,
    convertMentionsStylesForTimeline,
  }
}
