import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { Card, LoadingCircular } from 'pages/teams/components'
import { useTransferTeamAdmin } from 'pages/teams/hooks'

import { constants, Pages } from 'assets'
import { replacePathParams } from 'assets/history'

type Props = RouteComponentProps<{ teamId: string; transferToken: string }>

export const TeamsAdminTransferAcceptPage: React.FC<Props> = (props) => {
  const { match } = props
  const classes = useStyles()

  const { accept, errors } = useTransferTeamAdmin()

  const [loading, setLoading] = React.useState(true)
  const [complete, setComplete] = React.useState(false)

  const onSubmit = async () => {
    const res = await accept({ id: match.params.teamId, transferToken: match.params.transferToken })
    if (res) {
      setComplete(true)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    onSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (complete) {
      redirectToDashboard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete])

  const redirectToDashboard = async () => {
    await new Promise((resolve) => setTimeout(resolve, 5000))

    window.location.href = replacePathParams(Pages.TeamsDashboard, { teamId: match.params.teamId })
  }

  return (
    <>
      <Card headerTitle={'管理者移譲'}>
        <LoadingCircular loading={loading} />

        {errors ? (
          <>
            {errors.map((error, index) => (
              <p key={`transfer-accept-error-${index}`} className={classes.error}>
                {error.message}
              </p>
            ))}
          </>
        ) : (
          <></>
        )}
        {complete ? (
          <>
            <p
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              権限移譲が完了しました
            </p>

            <p className={classes.link} onClick={redirectToDashboard}>
              5秒後に自動でダッシュボードへリダイレクトします{'\n'}（リダイレクトされない方はこちらをクリック）
            </p>
          </>
        ) : (
          <></>
        )}
      </Card>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    fontSize: 12,
    color: constants.COLOR_RED,
  },

  link: {
    cursor: 'pointer',
    color: constants.COLOR_MAIN,
    textDecoration: 'underline',
    whiteSpace: 'pre-wrap',
  },
}))
