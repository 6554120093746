import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Button, Modal, Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useForm } from 'react-hook-form'

import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { Maybe, OnboardingNotificationSettingInput, OnboardingNotificationSettingsInput } from 'utils/generated'

import { CheckedIcon } from './checked'
import { UncheckedIcon } from './unchecked'

import * as constants from 'assets/constants'

type NotificationModalProps = {
  teamId: string
  open: boolean
  setOpen(open: boolean): void
}

const getInitState = (
  state: Maybe<OnboardingNotificationSettingInput> | undefined,
  only?: undefined | 'notice' | 'email'
) => {
  return (
    state || {
      notice: only === 'notice' || !only,
      email: only === 'notice' || !only,
    }
  )
}
export const NotificationModal: React.FC<NotificationModalProps> = ({ teamId, open, setOpen }) => {
  const classes = useStylesForModal()
  const { handleSubmit, control } = useForm<OnboardingNotificationSettingsInput>()
  const { updateTeamMember } = useTeamManage()
  const { teamMember } = useTeamMember(teamId)
  // mock
  const checkBoxItems = React.useMemo(() => {
    const settings = teamMember?.notificationSettings

    return [
      { type: 'comment', label: '自分の投稿へのコメント', ...getInitState(settings?.comment) },
      { type: 'mention', label: '自分へのメンション', ...getInitState(settings?.mention) },
      { type: 'like', label: '自分の投稿へのリアクション', ...getInitState(settings?.like) },
      // { type: 'action', label: 'アクション期限連絡', ...getInitState(settings?.action, 'email') },
      { type: 'supporter', label: 'サポーターの投稿', ...getInitState(settings?.supporter) },
      { type: 'leader', label: '運営事務局の投稿', ...getInitState(settings?.leader) },
      { type: 'member', label: 'チームへのメンバーの追加時', ...getInitState(settings?.member) },
    ]
  }, [teamMember])

  const close = () => setOpen(false)
  const onSubmit = async (data: OnboardingNotificationSettingsInput) => {
    await updateTeamMember({
      teamId,
      notificationSettings: data,
    })
    close()
  }
  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div className={classes.notificationModalBody}>
        <Button className={classes.closeButton} onClick={close}>
          <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
        </Button>
        <div className={classes.modalLabel}>通知設定</div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <table className={classes.checkBoxes}>
            <thead>
              <tr>
                <th />
                <th className={classes.tableLabel}>お知らせ</th>
                <th className={classes.tableLabel}>メール</th>
              </tr>
            </thead>
            <tbody>
              {checkBoxItems.map((item) => {
                return (
                  <tr key={`check-box-${item.type}`}>
                    <td>{item.label}</td>
                    <td className={classes.centerItem}>
                      <Controller
                        name={`${item.type}.notice`}
                        control={control}
                        defaultValue={item.notice}
                        render={({ onChange, value }) => (
                          <Checkbox
                            name={`${item.type}.notice`}
                            checked={item.type !== 'action' && value}
                            size="small"
                            color="primary"
                            icon={item.type === 'action' ? <div /> : <UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            onChange={() => onChange(!value)}
                            disabled={item.type === 'action'}
                          />
                        )}
                      />
                    </td>
                    <td className={classes.centerItem}>
                      <Controller
                        name={`${item.type}.email`}
                        control={control}
                        defaultValue={item.email}
                        render={({ onChange, value }) => (
                          <Checkbox
                            name={`${item.type}.email`}
                            checked={value}
                            size="small"
                            color="primary"
                            icon={<UncheckedIcon />}
                            checkedIcon={<CheckedIcon />}
                            onChange={() => onChange(!value)}
                          />
                        )}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className={classes.buttons}>
            <Button className={classes.cancelButton} variant="outlined" onClick={close}>
              キャンセル
            </Button>
            <Button className={classes.saveButton} variant="outlined" type="submit">
              保存
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
const useStylesForModal = makeStyles(() => ({
  notificationModalBody: {
    position: 'relative',
    width: '100%',
    maxWidth: 350,
    borderRadius: 8,
    padding: '40px 16px 20px',
    backgroundColor: constants.COLOR_WHITE,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    outline: 'none',
  },
  closeButton: {
    width: 40,
    minWidth: 40,
    height: 40,
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 0,
  },
  modalLabel: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 14,
    fontWeight: 'bold',
  },
  form: {
    width: '100%',
  },
  tableLabel: {
    fontWeight: 'normal',
  },
  centerItem: {
    textAlign: 'center',
  },
  checkBoxes: {
    width: '100%',
    fontSize: 12,
    padding: '20px 0 40px',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    gap: '16px',
  },
  cancelButton: {
    flex: 1,
    color: constants.TEXT_GRAY,
    border: `1px solid ${constants.TEXT_GRAY}`,
    borderRadius: 8,
    fontWeight: 'bold',
  },
  saveButton: {
    flex: 1,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    borderRadius: 8,
    fontWeight: 'bold',
  },
}))
