import React from 'react'

import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { LoadingCircular, ToggleSwitch } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useTeam, useTeamMember, useManageTeam } from 'pages/teams/hooks'
import { AnketStatus, TeamAnketBuildingSummary, TeamMemberRole, Maybe, Permissions } from 'utils/generated'

import { AnketComments } from '../AnketComments'
import CurrentDayChart from '../CurrentDayChart'
import { DateChart } from '../DateChart'
import { FilterAndCSV } from '../FilterAndCSV'

import { Tooltip } from './Tooltip'

import { constants } from 'assets'

type Props = {
  teamId: string
  summaryList: Maybe<(TeamAnketBuildingSummary & { label: string })[]> | undefined
  loading: boolean
}

const AnketBuildingSummary: React.FC<Props> = ({ teamId, summaryList, loading }) => {
  const { isAdmin } = React.useContext(HooksContext)
  const { team } = useTeam(teamId)
  const { currentUser } = React.useContext(HooksContext)
  const { teamMember } = useTeamMember(currentUser ? `${teamId}-${currentUser?.id}` : undefined)
  const { updateTeam, loading: isLoading } = useManageTeam()

  const isSmDown = useCustomMediaQuery('down', 'md')
  const [isSwitchOn, setIsSwitchOn] = React.useState<boolean>()

  const handleToggle = async (value: React.ChangeEvent<HTMLInputElement>) => {
    if (!team) {
      alert('チーム情報を取得できませんでした。')
      return
    }

    const updated = await updateTeam({
      id: team.id,
      statusViewTbCheckRespondents: value.target.checked ? Permissions.Enabled : Permissions.Disabled,
    })

    if (updated && updated.statusViewTbCheckRespondents) {
      setIsSwitchOn(updated.statusViewTbCheckRespondents === 'ENABLED' ? true : false)
    }
  }

  React.useEffect(() => {
    if (!loading) {
      setIsSwitchOn(team?.statusViewTbCheckRespondents === 'ENABLED' ? true : false)
    }
  }, [loading, team?.statusViewTbCheckRespondents])

  const showCSV = teamMember?.role.includes(TeamMemberRole.Admin) || teamMember?.role.includes(TeamMemberRole.Leader)
  const [currentAnketLabel, setCurrentAnketLabel] = React.useState<string | null | undefined>()
  const [currentAnket, setCurrentAnket] = React.useState<TeamAnketBuildingSummary>()
  const [previousAnket, setPreviousAnket] = React.useState<TeamAnketBuildingSummary>()
  const [filteredSummaryList, setFilteredSummaryList] = React.useState<
    Maybe<(TeamAnketBuildingSummary & { label: string })[]> | undefined
  >(summaryList?.slice(-5))

  React.useEffect(() => {
    if (summaryList) {
      const latest = summaryList.length > 0 ? summaryList[summaryList.length - 1] : null
      setCurrentAnketLabel(latest?.label)
      setFilteredSummaryList(summaryList)
    }
  }, [summaryList])

  React.useEffect(() => {
    if (filteredSummaryList && currentAnketLabel) {
      const currentIndex = filteredSummaryList.findIndex(({ label }) => label === currentAnketLabel)
      if (currentIndex !== -1) {
        setCurrentAnket(filteredSummaryList[currentIndex])
      }
      if (currentIndex !== -1 && currentIndex !== 0) {
        setPreviousAnket(filteredSummaryList[currentIndex - 1])
      }
      if (currentIndex === 0) {
        setPreviousAnket(undefined)
      }
    }
  }, [filteredSummaryList, currentAnketLabel])

  const lineChartWidth = React.useMemo(() => {
    if (!filteredSummaryList) {
      return '100%'
    } else {
      // デフォルトだと line chart の width が 100% になってしまい、"回答者を見る" ラベルの位置が可変して click event を管理しにくくなる。
      // よって、データの個数に応じて width を設定し、ラベル位置の固定およびスクロールによる全件表示を実現する。
      return filteredSummaryList.length * 120
    }
  }, [filteredSummaryList])

  const showDetail = React.useMemo(() => currentAnket?.status === AnketStatus.Close, [currentAnket])

  const dateSelectOptionWithAnswered = React.useCallback(
    () =>
      filteredSummaryList
        ? filteredSummaryList.map((summary) => ({
            value: summary.label,
            label: summary.label,
          }))
        : [],
    [filteredSummaryList]
  )

  const classes = useStyles({ lineChartWidth })

  if (loading) return <LoadingCircular loading={loading} />

  return (
    <div className={classes.summary}>
      <div className={classNames([classes.chartWrap, showDetail && classes.barChart, classes.barWrapper])}>
        <div className={classes.barChartHead}>
          <div className={classes.barChartTitleBox}>
            <Typography className="chartTitle mr">
              項目別評価　総合{' '}
              {typeof currentAnket?.average === 'number' ? Math.round(currentAnket.average * 10) / 10 : '_'}
            </Typography>
            <div className={classes.scoreInfoBox}>
              <p className={classes.scoreInfo}>
                <span className="plus tip"></span>
                <span className="minus tip"></span>
                今回のスコア
              </p>
              <p className={classes.scoreInfo}>
                <span className="previous tip"></span>前回のスコア
              </p>
            </div>
          </div>

          <FilterAndCSV
            dateSelectOption={dateSelectOptionWithAnswered()}
            currentAnketLabel={currentAnketLabel}
            currentAnket={currentAnket}
            setCurrentAnketLabel={setCurrentAnketLabel}
            showCSV={showCSV}
            team={team}
          />
        </div>
        {showDetail ? (
          <>{currentAnket && <CurrentDayChart currentAnket={currentAnket} previousAnket={previousAnket} />}</>
        ) : (
          <></>
        )}
      </div>

      <div className={classNames([classes.chartWrap, classes.lineWrapper])}>
        <div className={classes.titleWrapper}>
          <Typography className="chartTitle mb">総合評価推移</Typography>

          {isAdmin && isSwitchOn !== undefined ? (
            <div className={`${classes.headContent} ${classes.laterContent}`}>
              <p className={classes.switchLabel}>回答者閲覧可否</p>
              <Tooltip
                balloonId="tooltip-teams-switch"
                title="回答者閲覧可否"
                body="「ON」にするとリーダーが回答者を閲覧できるようになります"
                width={isSmDown ? 300 : 463}
                positions={{ top: 30, right: -60 }} // top 30 = 20（? icon の高さ） + 10（ballon の矢印の高さ）
              />

              <div className={classes.switchWrapper}>
                <ToggleSwitch onChange={handleToggle} checked={isSwitchOn} disabled={isLoading} />
              </div>
            </div>
          ) : null}
        </div>
        <div className={classes.lineChartBox}>
          <div className={classes.lineChartYLabel}>
            <span className="top">+5</span>
            <span className="zero">0</span>
            <span className="bottom">-5</span>
          </div>
          <div className={classes.lineChartScroller}>
            <div className={classes.lineChartInner}>
              <DateChart
                summaryList={filteredSummaryList}
                currentAnketLabel={currentAnketLabel}
                dateSelectOption={dateSelectOptionWithAnswered()}
                setCurrentAnketLabel={setCurrentAnketLabel}
                setCurrentAnket={setCurrentAnket}
                teamIdLabel={teamId}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.commentsWrap}>
        {showDetail ? (
          <div className={classes.comments}>
            <AnketComments comments={currentAnket?.freeComment} anketOpenDate={currentAnket?.createdAt} />
          </div>
        ) : (
          !loading && (
            <Typography className={classes.noComment}>開催中か未開催、回答がなかったアンケートです</Typography>
          )
        )}
      </div>
    </div>
  )
}

type StyleProps = {
  lineChartWidth: string | number
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    chartWrap: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      [theme.breakpoints.down('md')]: {
        backgroundColor: '#fff',
      },
      '& .chartTitle': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      '& .mr': {
        [theme.breakpoints.up('md')]: {
          marginRight: 44,
        },
      },
      '& .mb': {
        [theme.breakpoints.down('md')]: {
          marginBottom: 18,
        },
      },
      '& .filter': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        '& .selectWrap': {
          minWidth: 120,
        },
      },
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    headContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    laterContent: {
      [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-end',
      },
    },
    switchLabel: {
      margin: 0,
      fontSize: 12,
      fontWeight: 'bold',
    },
    switchWrapper: {
      marginLeft: 8,
    },
    lineChartBox: {
      display: 'flex',
    },
    lineChartYLabel: {
      width: 60,
      padding: '8px 24px 0 0',
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        width: 18,
        padding: '12px 4px 0 0',
        fontSize: 8,
      },
      '& span': {
        display: 'block',
        textAlign: 'right',
        '&:not(:last-child)': {
          marginBottom: 100,
          [theme.breakpoints.down('md')]: {
            marginBottom: 108,
          },
        },
      },
      '& .top': {
        color: constants.COLOR_MAIN_NEW,
      },
      '& .zero': {
        color: constants.TEXT_GRAY_DARK,
      },
      '& .bottom': {
        color: constants.COLOR_TEAMBUILDING_RED2,
      },
    },
    lineChartScroller: {
      width: '100%',
      maxWidth: '100%',
      overflowX: 'auto',
    },
    lineChartInner: {
      width: ({ lineChartWidth }) => lineChartWidth,
      height: 346,
    },
    barChart: {
      height: 450,
    },
    barWrapper: {
      padding: '24px 24px 60px',
      gap: 12,
      [theme.breakpoints.down('md')]: {
        padding: '17px 8px 116px',
      },
    },
    lineWrapper: {
      padding: '24px 24px 16px',
      gap: 16,
      [theme.breakpoints.down('md')]: {
        padding: '17px 8px 20px',
      },
    },
    barChartHead: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 8,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
      },
    },
    barChartTitleBox: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 8,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 16,
      },
    },
    scoreInfoBox: {
      display: 'flex',
      gap: 24,
    },
    scoreInfo: {
      margin: 0,
      fontSize: 10,
      fontWeight: 'bold',

      '& .plus': {
        marginRight: 2,
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '& .minus': {
        marginRight: 8,
        backgroundColor: constants.COLOR_TEAMBUILDING_RED2,
      },
      '& .previous': {
        marginRight: 8,
        backgroundColor: '#ccc',
      },
      '& .tip': {
        display: 'inline-block',
        width: 8,
        height: 8,
      },
    },
    commentsWrap: {
      display: 'flex',
      gap: '24px 8px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    comments: {
      flex: 1,
    },
    summary: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      margin: '0 auto',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        gap: '16px',
      },
    },
    noComment: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 14,
    },
  }),
  { name: 'AnketBuildingSummary' }
)

export default AnketBuildingSummary
