import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '800px',
    marginRight: '20px',
    paddingBottom: '136px',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  profile: {
    [theme.breakpoints.down('lg')]: {
      paddingTop: '18px',
      paddingBottom: 0,
      backgroundColor: constants.COLOR_GREEN,
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  route?: string
}

// -----------------------------
// Component
// -----------------------------
export const Column = (props: IProps) => {
  const classes = useStyles()

  return <Box className={`${classes.root} ${props.route === 'profile' ? classes.profile : ''}`}>{props.children}</Box>
}
