import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Theme, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton } from 'components/NoStyleButton'

import actionAdd from './assets/action-add.svg'
import ArrowLeft from './assets/arrow-left.svg'
import memberAdd from './assets/member-add.svg'
import * as constants from 'assets/constants'
import { replacePathParams } from 'assets/history'
import { OnbPages } from 'assets/pages'

type Props = {
  renderKey: 'member-add' | 'action-add'
  teamId: string
  isTutorial?: boolean
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const TitleWithUpload: React.FC<Props & StyleProps> = ({ renderKey, teamId, isTutorial, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  type SubProps = {
    title: string
    uploadButtonTitle: string
    uploadButtonImgUrl: string
    uploadButtonLink: () => void
  }

  const renderData: { [k in typeof renderKey]: SubProps } = {
    'member-add': {
      title: 'メンバーの追加',
      uploadButtonTitle: 'CSVで一括登録',
      uploadButtonImgUrl: memberAdd,
      uploadButtonLink: () => {
        if (!isTutorial) history.push(replacePathParams(OnbPages.MemberManageAddMemberCSV, { teamId }))
      },
    },
    'action-add': {
      title: 'アクション追加',
      uploadButtonTitle: 'CSVで一括登録',
      uploadButtonImgUrl: actionAdd,
      uploadButtonLink: () => {
        if (!isTutorial) history.push(replacePathParams(OnbPages.ActionManageAddCSV, { teamId }))
      },
    },
  }

  return (
    <div>
      <div className={classes.titleWithUploadRoot}>
        {isSmDown && <div className={classes.title}>{renderData[renderKey].title}</div>}

        {isTutorial ? (
          <></>
        ) : (
          <div className={classes.toBack} onClick={() => history.goBack()}>
            <img src={ArrowLeft} alt="arrow-left" />
            <span>戻る</span>
          </div>
        )}
        {!isSmDown && <div className={classes.title}>{renderData[renderKey].title}</div>}
        <NoStyleButton className={classes.button} onClick={renderData[renderKey].uploadButtonLink}>
          <img src={renderData[renderKey].uploadButtonImgUrl} className={classes.img} />
          {renderData[renderKey].uploadButtonTitle}
        </NoStyleButton>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    titleWithUploadRoot: ({ ownStyles }: StyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...ownStyles,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      },
    }),
    title: {
      marginLeft: 80,
      fontSize: 16,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
        margin: '0 0 26px 0',
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 166,
      padding: '8px 16px',
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: '0 24px 0 auto',
      },
    },
    img: {
      display: 'inline-block',
      margin: '0 8px 0 0',
    },
    toBack: {
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      margin: -5,
      padding: 5,
      cursor: 'pointer',
      '& img': {
        position: 'relative',
        top: 1,
      },
      '& span': {
        marginLeft: 7,
      },
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: 0,
        left: 0,
      },
    },
  }),
  { name: 'TitleWithUpload' }
)
