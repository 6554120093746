import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

interface Props {
  iconName: 'heart-plus' | 'plus'
  onPress: () => void
  isClass?: boolean
}

/**
 * Simple rounded icon button. Just pass icon name and event function.
 */
export const ButtonIcon: React.FC<Props> = ({ iconName, onPress, isClass }) => {
  const styles = useStyles()
  return (
    <span className={isClass ? styles.buttonHover : styles.button} onClick={onPress}>
      <img src={require(`./assets/${iconName}.svg`).default} alt={`icon-button-${iconName}`} />
    </span>
  )
}

const useStyles = makeStyles({
  button: {
    width: 42,
    height: 42,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  buttonHover: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
})
