import React, { useContext, useState } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { PeriodList, categories } from 'pages/onboarding/utils/ranking'

import { useRanking } from '../../hooks/ranking'
import { OnbContext } from '../PagesRoot'

import { PeriodSelect } from './components/PeriodSelect'
import { RankingBox } from './components/RankingBox'


import * as constants from 'assets/constants'

export const RankingPageBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)
    const { loading, teamMembersOwnData } = useRanking(teamId, teamMembers)

    const [period, setPeriod] = useState<PeriodList>('lastWeek')

    return { teamId, loading, teamMembersOwnData, period, setPeriod }
  },
}

export const RankingPage: React.FC = () => {
  const classes = useStyles()
  const { teamId, loading, teamMembersOwnData, period, setPeriod } = RankingPageBloc.useAdapter()

  return (
    <div className={classes.container}>
      <div className={classes.head}>
        <h1 className={classes.title}>ランキング</h1>
        {!loading && <PeriodSelect period={period} setPeriod={setPeriod} />}
      </div>

      <div className={classes.body}>
        {!loading ? (
          <>
            <RankingBox category={categories.ownLikes} teamId={teamId} period={period} items={teamMembersOwnData} />
            <RankingBox category={categories.ownPosts} teamId={teamId} period={period} items={teamMembersOwnData} />
            <RankingBox category={categories.ownActions} teamId={teamId} period={period} items={teamMembersOwnData} />
          </>
        ) : (
          <p className={classes.progress}>
            ロード中です。このままお待ちください<span className={classes.point1}>.</span>
            <span className={classes.point2}>.</span>
            <span className={classes.point3}>.</span>
            <p className={classes.progressSecond}>(処理には数分かかる場合がございます。)</p>
          </p>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      margin: '18px 16px 48px',
      color: constants.TEXT_GRAY_DARK,
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: 24,
      },
    },
    title: {
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        rowGap: 16,
        marginTop: 24,
      },
    },
    progress: {
      margin: '32px 0 24px',
      textAlign: 'center',
      fontSize: 14,
      '& span': {
        fontSize: 16,
      },
    },
    progressSecond: {
      marginTop: -2,
      textAlign: 'center',
      fontSize: 14,
      '& span': {
        fontSize: 16,
      },
    },
    point1: {
      animation: '$textOn 3s cubic-bezier(0.33,0,0,1) 200ms infinite normal backwards running',
      '@global': {
        '@keyframes textOn': {
          '0%': {
            opacity: 0,
          },
          '100%': {
            opacity: 1,
          },
        },
      },
    },
    point2: {
      animation: '$textOn 3s cubic-bezier(1,0,0,1) 200ms infinite normal backwards running',
    },
    point3: {
      animation: '$textOn 3s cubic-bezier(1,-0.35,0.8,1) 200ms infinite normal backwards running',
    },
  }),
  { name: 'RankingPage' }
)
