import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../../../assets/constants'

import CocolaboOnbFooterIcon from '../assets/CocolaboOnbFooterIcon.svg'
import FacebookIcon from '../assets/FacebookIcon.svg'
import TwitterIcon from '../assets/TwitterIcon.svg'



type Props = WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes }) => {
  const history = useHistory()
  return (
    <div className={classes.Hfooter}>
      <div className="footerIcons">
        <div className="facebookIcon">
          <a href="https://www.facebook.com/cocolabo.club/" target="_blank" rel="noopener noreferrer">
            <img src={FacebookIcon} alt="facebook" />
          </a>
        </div>
        <div className="twitterIcon">
          <a href="https://twitter.com/cocolabo_club" target="_blank" rel="noopener noreferrer">
            <img src={TwitterIcon} alt="twitter" />
          </a>
        </div>
      </div>
      <div
        className="logoHouseIcon"
      >
        <img src={CocolaboOnbFooterIcon}
          alt="ページトップ"
          className={classes.icon}
          onClick={
            () => history.push(constants.PAGE_TOP)
          }
        />
        <span className={classes.logo} onClick={() => history.push(constants.PAGE_TOP)}>
          Cocolabo
        </span>
        <span className={classes.backToTop} onClick={() => history.push(constants.PAGE_TOP)}>
          トップへ戻る
        </span>
      </div>
      <div className="copyrightIcon">
        <span>&copy;NEWONE,Inc.</span>
      </div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    backToTop: {
      textDecoration: 'underline',
      textDecorationStyle: 'solid',
      textDecorationThickness: 2,
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    logo: {
      fontWeight: 'bold',
      margin: '0 5px 0 2px',
      cursor: 'pointer',
    },
    icon: {
      cursor: 'pointer',
    },
    Hfooter: {
      height: '155px',
      width: '300px',
      backgroundColor: constants.COLOR_WHITE,
      '& .footerIcons': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .facebookIcon': {
          cursor: 'pointer',
          margin: '36px 12px 12px 102px',
          '& .img': {
            width: '24px',
          },
        },
        '& .twitterIcon': {
          cursor: 'pointer',
          margin: '36px 102px 12px 12px',
          '& .img': {
            width: '24px',
          },
        },
      },
      '& .logoHouseIcon': {
        marginBottom: 11,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {},
      },
      '& .copyrightIcon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: constants.TEXT_GRAY_DARK,
        fontSize: '12px',
        '& span': {},
      },
    },
  })

export const FooterIcon = withStyles(useStyles)(Index)
