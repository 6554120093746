import React from 'react'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import { QuestionContainer, Button, WaitingForLeader, ButtonContainer } from '../../components'
import { Datepicker } from '../../components'

import { constants } from 'assets'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q5: React.FC<Props> = (props) => {
  const { isLeader } = React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const classes = useStyles()
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null)
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = async () => {
    if (!selectedDate) {
      return
    }

    await updateTeamBuilding({
      id: props.match.params.teamId,
      endAt: selectedDate.toISOString(),
      status: TeamBuildingStatus.Q1, // Changed page transition by UI UX modification
      timeQ1: new Date().toISOString(), // Changed according to the above
    })
  }

  return (
    <QuestionContainer noPadding fullWidth>
      {isLeader ? (
        <>
          <div className={classes.main}>
            <h1 className={classes.title}>チームの最終日</h1>
            <div className={classes.pickerWrapper}>
              <Datepicker onSelectDate={(date) => setSelectedDate(date)} />
            </div>
            <p className={classes.description}>
              例：期末の日、プロジェクトの場合はプロジェクト
              {isSmDown && <br />}
              最終日を仮でよいので設定しましょう
            </p>
          </div>

          <ButtonContainer
            fixed
            marginTopPcLayout={287}
            buttons={[
              <Button
                key={`button-1`}
                type={'submit'}
                disabled={!dayjs.isDayjs(selectedDate)}
                onClick={() => onSubmit()}
              >
                次へ
              </Button>,
            ]}
          />
        </>
      ) : (
        <div className={classes.waitContainer}>
          <p className={classes.memberWait}>リーダーがチームの目指す終了日を設定しています</p>
          <div>
            <WaitingForLeader />
          </div>
        </div>
      )}
    </QuestionContainer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    padding: '40px 16px 20px',
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      padding: 40,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 24px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  pickerWrapper: {
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
  },
  description: {
    margin: '12px 0 0',
    paddingLeft: '2em',
    textIndent: '-2em',
    color: constants.COLOR_TEAMBUILDING_NEUTRAL_400,
    fontSize: 14,
  },
  waitContainer: {
    minHeight: 'calc(100vh - 150px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: 'calc(100vh - 70px)',
      paddingTop: 80,
    },
  },
  memberWait: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    padding: '48px 16px 0',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
  },
}))
