import * as React from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { Box, Theme, Typography } from '@mui/material'
import { useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import Logo from 'components/icon/Logo'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from '../../assets/constants'
import PlusIcon from '../../pages/landing/components/topPageModules/assets/plusIcon.svg'

import { FootMessage } from './components/FootMessage'
import { connector, ContainerProps } from './Footer.container'

import * as pages from 'assets/pages'

type Props = ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>
const FOOTER_BREAKPOINT = constTop.breakPointsSP + 312

const Footer: React.FC<Props> = (props) => {
  const { classes } = props
  const { location } = props
  const theme = useTheme()
  const isBreakpointDown = useMediaQuery(theme.breakpoints.down(FOOTER_BREAKPOINT))

  // TODO: Pathの定数化
  const showMini = [
    '/karte/member/questionnaire',
    '/karte/member',
    '/karte/member/prepare',
    '/karte/member/results',
  ].some((path) => location.pathname.startsWith(path))

  if (showMini)
    return (
      <footer className={classes.footer}>
        <Box py={2} className={classes.copyright}>
          &copy;NEWONE,Inc.
        </Box>
      </footer>
    )

  if (
    location.pathname.match(new RegExp(pages.ONBOARDING_LANDING + '/.+')) ||
    location.pathname.match(new RegExp(pages.Pages.TeamsLanding + '/.+')) ||
    location.pathname.match(new RegExp('/ghost/.+')) ||
    location.pathname.match(new RegExp('/signup/confirm?.+')) ||
    location.pathname === pages.Pages.HatarakuQuestionnaire ||
    location.pathname === pages.Pages.ObakesagashiSearch
  ) {
    return <></>
  }

  return (
    <footer className={classes.footer}>
      <FootMessage />
      <Box pt={{ xs: 12.5, md: 17.5 }} pb={{ xs: 12.5, md: 13 }}>
        <div className={classes.container}>
          {!showMini && (
            <Grid container>
              <Grid size={{ xs: 12, md: 'grow' }}>
                <div className={classes.logoLink}>
                  <Link to={constants.PAGE_TOP}>
                    <Logo width={206} height={26} />
                  </Link>
                </div>
              </Grid>

              <Grid className={classes.sevenServiceRoot} size={{ xs: 12, md: 3 }}>
                {isBreakpointDown ? (
                  <>
                    <Accordion
                      className={classes.accordion}
                      square={true}
                      classes={{
                        root: classes.accordionRoot,
                        expanded: classes.expanded,
                        disabled: classes.disabled,
                      }}
                    >
                      <AccordionSummary
                        className={classes.summary}
                        classes={{
                          root: classes.summaryRoot,
                          content: classes.summaryContent,
                          expandIconWrapper: classes.MUISummaryExpandIcon,
                        }}
                        expandIcon={<img src={PlusIcon} alt="plus-icon" />}
                      >
                        <>
                          <div className={classes.serviceTitle}>機能一覧</div>
                        </>
                      </AccordionSummary>
                      <AccordionDetails
                        className={classes.details}
                        classes={{
                          root: classes.detailsRoot,
                        }}
                      >
                        <div className={classes.serviceList}>
                          <Link to={constants.PAGE_FEATURES_KICKOFF}>
                            <span className={classes.humMenuList}>ー　キックオフ</span>
                          </Link>
                        </div>
                        <div className={classes.serviceList}>
                          <Link to={constants.PAGE_FEATURES_ONBOARDING}>
                            <span className={classes.humMenuList}>ー　オンボーディング</span>
                          </Link>
                        </div>
                        <div className={classes.serviceList}>
                          <Link to={constants.PAGE_FEATURES_OBAKE}>
                            <span className={classes.humMenuList}>ー　オバケ</span>
                          </Link>
                        </div>
                        <div className={classes.serviceList}>
                          <Link to={constants.PAGE_FEATURES_KARTE}>
                            <span className={classes.humMenuList}>ー　カルテ</span>
                          </Link>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Typography className="__title">機能一覧</Typography>
                    <div className="__links">
                      <Grid container>
                        <Grid className={classes.sevenServiceRoot} size={{ xs: 12, md: 6 }}>
                          <div className="__link">
                            <Link to={constants.PAGE_FEATURES_KICKOFF}>ー　キックオフ</Link>
                          </div>
                          <div className="__link">
                            <Link to={constants.PAGE_FEATURES_ONBOARDING}>ー　オンボーディング</Link>
                          </div>
                          <div className="__link">
                            <Link to={constants.PAGE_FEATURES_OBAKE}>ー　オバケ</Link>
                          </div>
                          <div className="__link __end">
                            <Link to={constants.PAGE_FEATURES_KARTE}>ー　カルテ</Link>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Grid>

              <Grid className="__menusItem" size={{ xs: 12, md: 1 }}>
                <div className="__menus1">
                  <div className="__menusLink">
                    <Link to={constants.PAGE_PLAN}>料金</Link>
                  </div>
                  <div className="__menusLink">
                    <Link to={constants.PAGE_CASE}>事例</Link>
                  </div>
                  <div className="__menusLink">
                    <Link to={constants.PAGE_RESOURCES}>お役立ち資料</Link>
                  </div>
                  <div className="__menusLink">
                    <Link to={constants.PAGE_HELP}>ヘルプ</Link>
                  </div>
                  <div className="__menusLink">
                    <Link to={constants.PAGE_NOTIFICATION}>お知らせ</Link>
                  </div>
                  <div className="__menusLink">
                    <Link to={constants.PAGE_CONTACT}>お問い合わせ</Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Box mt={{ xs: 12.5, md: 9 }} className={classes.copyrightBox}>
            {isBreakpointDown ? (
              <>
                <div className={classes.__menus2}>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_ABOUT}>運営会社</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_TERMS}>利用規約</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_PRIVACY_POLICY}>プライバシーポリシー</Link>
                  </div>
                  <Box className={classes.__menusLink2}>
                    <Link to={constants.PAGE_LEGAL}>特定商取引法表示</Link>
                  </Box>
                </div>
                <a href="https://new-one.co.jp/" target="_blank" rel="noopener noreferrer">
                  <img
                    className={classes.logo}
                    src={process.env.PUBLIC_URL + '/img/topPage/newoneLogo.svg'}
                    alt="newone"
                  />
                </a>
                <div className={classes.copyright}>&copy;NEWONE,Inc.</div>
              </>
            ) : (
              <>
                <a href="https://new-one.co.jp/" target="_blank" rel="noopener noreferrer">
                  <img
                    className={classes.logo}
                    src={process.env.PUBLIC_URL + '/img/topPage/newoneLogo.svg'}
                    alt="newone"
                  />
                </a>
                <div className={classes.copyright}>&copy;NEWONE,Inc.</div>
                <div className={classes.__menus2}>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_ABOUT}>運営会社</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_TERMS}>利用規約</Link>
                  </div>
                  <div className={classes.__menusLink2}>
                    <Link to={constants.PAGE_PRIVACY_POLICY}>プライバシーポリシー</Link>
                  </div>
                  <Box className={classes.__menusLink2}>
                    <Link to={constants.PAGE_LEGAL}>特定商取引法表示</Link>
                  </Box>
                </div>
              </>
            )}
          </Box>
        </div>
      </Box>
    </footer>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    footer: {
      background: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    },
    container: {
      width: '100%',
      maxWidth: '1200px',
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down(1248)]: {
        width: 'calc(100% - 48px)',
        marginLeft: 24,
        marginRight: 24,
      },
      '& .footerIcons': {
        margin: '139px 0 0 0',
      },
      '& .sevenServiceRoot': {
        margin: '-20px 0 0 0',
      },
      '& .__title': {
        margin: '10px 0 30px 0',
        fontSize: 13,
        fontWeight: 'bold',
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
          marginTop: 48,
          textAlign: 'center',
          '&:hover': {
            color: constants.COLOR_ONBOARDING_MAIN,
            cursor: 'pointer',
          },
        },
      },
      '& .__links': {
        marginTop: 18,
        fontSize: 12,
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
          textAlign: 'center',
        },
      },
      '& .__link': {
        marginBottom: 20,
        '&:hover': {
          color: constants.COLOR_ONBOARDING_MAIN,
        },
      },
      '& .__menusLink': {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: 29,
        color: constants.TEXT_GRAY_DARK,
        whiteSpace: 'nowrap',
        '&:hover': {
          color: constants.COLOR_ONBOARDING_MAIN,
        },
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
          marginTop: 20,
        },
      },

      '& .__end': {
        marginBottom: 0,
      },
      '& .__menusItem': {
        [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
          margin: '0 24px',
        },
        '& .__menus1': {
          marginTop: 48,
          fontSize: 13,
          fontWeight: 'bold',
          [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
            marginTop: 5,
          },
        },
      },
    },
    logoLink: {
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        textAlign: 'center',
      },
    },
    copyrightBox: {
      display: 'flex',
      alignItems: 'end',
      padding: '44px 0 0 0',
      borderTop: '1px solid #E3E3E3',
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        display: 'block',
        padding: '0 0 0 0',
        margin: '41px 24px 0',
      },
    },
    logo: {
      height: 30,
      width: 153,
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        order: 1,
      },
    },

    copyright: {
      fontSize: '10px',
      color: constants.TEXT_GRAY_DARK,
      margin: '0 0 6px 24px',
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        margin: '24px 0 0',
      },
    },
    __menus2: {
      margin: '48px 0 4px auto',
      fontSize: 12,
      display: 'flex',
      alignItems: 'end',
      flexWrap: 'wrap',
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        marginBottom: 24,
      },
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
    __menusLink2: {
      fontSize: 13,
      marginLeft: 24,
      color: constants.TEXT_GRAY_DARK,
      '&:hover': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
      [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
        fontSize: 12,
        margin: '0 24px 24px 0',
        color: constants.TEXT_GRAY_DARK,
      },
    },
    [theme.breakpoints.down(FOOTER_BREAKPOINT)]: {
      logoLink: {
        margin: '0 0 40px 24px',
        textAlign: 'start',
      },
      serviceTitle: {
        fontSize: 13,
        fontWeight: 'bold',
      },
      accordionRoot: {
        margin: '0 24px 0 24px',
        boxShadow: 'none',
        padding: '0 0 0 0',

        '&.Mui-expanded': {
          margin: '0 24px 0 24px',
          minHeight: '46px',
        },
      },
      expanded: {
        margin: '0 24px 0 24px',
      },
      summaryRoot: {
        boxShadow: 'none',
        padding: '0 0 0 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&.Mui-expanded': {
          minHeight: '46px',
        },
      },
      plusIcon: {
        display: 'block',
        margin: ' 0 24px 0 auto',
      },
      summaryContent: {
        margin: '0 0 0 0',
        boxShadow: 'none',
        padding: '0 0 0 0',
      },
      MUISummaryExpandIcon: {
        '&.Mui-expanded': {
          transform: 'rotate(45deg) scale(1.15)',
        },
      },
      detailsRoot: {
        display: 'block',
        margin: '0 0 0 0',
        boxShadow: 'none',
        padding: '0 0 0 0',
      },
      serviceList: {
        margin: '0 0 20px 24px',
        fontSize: 12,
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Footer)))
