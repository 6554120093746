import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Theme, TextField } from '@mui/material'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import 'date-fns'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import { useForm } from 'react-hook-form'
import 'dayjs/locale/ja'

import * as constants from '../../../assets/constants'
import Button from '../../../components/Button'
import { IActionItem, IMission } from '../../../stores/setupaction/reducer'

import { connector, ContainerProps } from './ModalMission.container'

interface IOwnProps {
  open: boolean
  handleClose: any
  item: IActionItem
  values?: IMission
}

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  React.useEffect(() => {
    setValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setValues = () => {
    if (props.values && props.values.value) {
      if (props.values.value > 0) {
        setValueAction(props.values.value)
        setIsDoing(false)
        setSelectedDate(dayjs(new Date(props.values?.settedAt || '')))
      } else {
        setValueAction(Math.abs(props.values.value))
        setIsDoing(true)
        setSelectedDate(dayjs(new Date()))
      }
    } else {
      setValueAction(null)
      setIsDoing(true)
      setSelectedDate(dayjs(new Date()))
    }
  }

  const { classes } = props

  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(new Date()))

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date)
  }

  const [isDoing, setIsDoing] = React.useState<boolean>(true)

  const [valueAction, setValueAction] = React.useState<number | null>()

  const { register, handleSubmit } = useForm<IMission>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const [hasError, setHasError] = React.useState<boolean>(false)
  const onSubmit = (data: IMission) => {
    setHasError(false)
    if (!disabled()) {
      if (!isDoing) {
        if (valueAction) {
          data.value = -valueAction
        }
      } else {
        if (valueAction) {
          data.value = valueAction
        }
      }

      if (selectedDate) {
        data.settedAt = dayjs(selectedDate).format('YYYY-MM-DD')
      }

      if (props.setup.currentSetupStartedAt) {
        data.setupStartedAt = props.setup.currentSetupStartedAt
      }

      if (props.item) {
        props.submit(props.item, data)
        props.handleClose()
      }
    } else {
      setHasError(true)
    }
  }

  const disabled = () => {
    // if (!defaultIsDoing) {
    //   return false
    // }
    return !valueAction //? (defaultIsDoing !== isDoing ? false : true) : true
  }

  const handleClose = () => {
    if (props.values && props.values.value && props.values.value > 0) {
      setValueAction(props.values.value)
      setIsDoing(false)
      setSelectedDate(dayjs(new Date(props.values?.settedAt || '')))
    } else {
      setValueAction(props.values ? (props.values.value ? Math.abs(props.values.value) : null) : null)
      setIsDoing(true)
      setSelectedDate(dayjs(new Date()))
    }
    props.handleClose()
  }

  return (
    <Modal
      open={props.open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.handleClose(event, reason)
        }
      }}
      aria-labelledby="action-modal-title"
      aria-describedby="action-modal-description"
      className={classes.modal}
    >
      <Paper className="__modalPaper">
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.item && <div className="__modalPaper__title">{props.item.mission}</div>}
          <div className="__modalPaper__title">
            {isDoing
              ? 'アクションを達成したら以下の項目を入力の上[達成済みにする] を押してください。'
              : 'アクションを進行中に戻す場合は下部の[ 進行中に戻す ] を押してください。'}
          </div>
          {/*<div className="__modalPaper__buttons">*/}
          {/*  <Button*/}
          {/*    color={isDoing ? constants.COLOR_RED : constants.COLOR_WHITE}*/}
          {/*    textColor={*/}
          {/*      isDoing ? constants.COLOR_WHITE : constants.COLOR_BLACK*/}
          {/*    }*/}
          {/*    fullWidth={true}*/}
          {/*    body={<div>進行中</div>}*/}
          {/*    onClick={handleIsDoing}*/}
          {/*  />*/}
          {/*  <Button*/}
          {/*    color={!isDoing ? constants.COLOR_RED : constants.COLOR_WHITE}*/}
          {/*    textColor={*/}
          {/*      !isDoing ? constants.COLOR_WHITE : constants.COLOR_BLACK*/}
          {/*    }*/}
          {/*    fullWidth={true}*/}
          {/*    body={<div>クリア</div>}*/}
          {/*    onClick={handleIsClear}*/}
          {/*  />*/}
          {/*</div>*/}

          <div className="__modalPaper__title">難易度は？</div>
          <div className="__modalPaper__actions">
            <div className="__modalPaper__actions__action">
              <img
                className={`__modalPaper__actions__action__icon ${valueAction === 1 ? 'activeAction' : ''}`}
                src={
                  process.env.PUBLIC_URL +
                  (valueAction === 1 ? '/assets/svg/setupList/face-1-a.svg' : '/assets/svg/setupList/face-1.svg')
                }
                alt={'face-1'}
                onClick={() => {
                  setValueAction(1)
                }}
              />
              <div className="__modalPaper__actions__action__memo">難しい</div>
            </div>
            <div className={classes.actionIcon}>
              <img
                className={`__modalPaper__actions__action__icon ${valueAction === 2 ? 'activeAction' : ''}`}
                src={
                  process.env.PUBLIC_URL +
                  (valueAction === 2 ? '/assets/svg/setupList/face-2-a.svg' : '/assets/svg/setupList/face-2.svg')
                }
                alt={'face-2'}
                onClick={() => {
                  setValueAction(2)
                }}
              />
            </div>
            <div className={classes.actionIcon}>
              <img
                className={`__modalPaper__actions__action__icon ${valueAction === 3 ? 'activeAction' : ''}`}
                src={
                  process.env.PUBLIC_URL +
                  (valueAction === 3 ? '/assets/svg/setupList/face-3-a.svg' : '/assets/svg/setupList/face-3.svg')
                }
                alt={'face-3'}
                onClick={() => {
                  setValueAction(3)
                }}
              />
              <div className="__modalPaper__actions__action__memo">普通</div>
            </div>
            <div className={classes.actionIcon}>
              <img
                className={`__modalPaper__actions__action__icon ${valueAction === 4 ? 'activeAction' : ''}`}
                src={
                  process.env.PUBLIC_URL +
                  (valueAction === 4 ? '/assets/svg/setupList/face-4-a.svg' : '/assets/svg/setupList/face-4.svg')
                }
                alt={'face-4'}
                onClick={() => {
                  setValueAction(4)
                }}
              />
            </div>
            <div className={classes.actionIcon}>
              <img
                className={`__modalPaper__actions__action__icon ${valueAction === 5 ? 'activeAction' : ''}`}
                src={
                  process.env.PUBLIC_URL +
                  (valueAction === 5 ? '/assets/svg/setupList/face-5-a.svg' : '/assets/svg/setupList/face-5.svg')
                }
                alt={'face-5'}
                onClick={() => {
                  setValueAction(5)
                }}
              />
              <div className="__modalPaper__actions__action__memo">易しい</div>
            </div>
          </div>

          <div className="__modalPaper__title">所感（気づいたこと）</div>
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue={props.values?.comment}
            variant="outlined"
            classes={{ root: '__modalPaper__textField' }}
            name="comment"
            inputRef={register({})}
          />

          <div></div>
          <div className={classes.datepcikerContainer}>
            <div className="__title">日付</div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
              <DatePicker
                format="YYYY/MM/DD"
                value={selectedDate}
                onChange={handleDateChange}
                className={classes.datepicker}
                desktopModeMediaQuery="@media (min-width:0px)"
                slotProps={{
                  textField: {
                    variant: 'standard',
                    margin: 'normal',
                    sx: {
                      height: '42px',
                      padding: '4px 4px 4xp 16px',
                      '& .MuiIconButton-root': {
                        marginRight: '2px',
                        padding: '12px',
                      },
                    },
                  },
                  popper: {
                    className: classes.popover,
                    placement: 'bottom-end',
                  },
                }}
              />
            </LocalizationProvider>
          </div>
          {hasError && (
            <small
              style={{
                color: 'red',
                width: '100%',
                textAlign: 'center',
                display: 'block',
              }}
            >
              難易度を選択してから更新してください。
            </small>
          )}

          <div className="__modalPaper__buttons">
            <Button
              bgColor={constants.COLOR_WHITE}
              textColor={constants.COLOR_BLACK}
              fullWidth={true}
              body={<div>キャンセル</div>}
              onClick={handleClose}
            />

            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.COLOR_WHITE}
              fullWidth={true}
              submit={true}
              body={isDoing ? '達成済みにする' : '進行中に戻す'}
            />
          </div>
        </form>
      </Paper>
    </Modal>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& .__modalPaper': {
        background: constants.COLOR_GRAY,
        padding: theme.spacing(2),
        // height: '80vh',
        width: '100%',
        margin: '0 16px',
        maxHeight: '90vh',
        overflow: 'scroll',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },
        '&:focus': {
          outline: 'none',
        },

        '&__title': {
          color: constants.COLOR_MAIN,
          fontSize: '18px',
          fontWeight: 'bold',
          padding: '16px 0',
          textAlign: 'center',
        },
        '&__actions': {
          display: 'flex',
          justifyContent: 'space-between',
          '&__action': {
            //
            '&__icon': {
              width: '50px',
              height: '50px',
              background: 'lightgray',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '8px',
              boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

              '&.activeAction': {
                backgroundColor: constants.COLOR_MAIN_LIGHT,
              },
            },

            '&__memo': {
              marginTop: '4px',
              textAlign: 'center',
              fontSize: '12px',
            },
          },
        },

        '&__textField': {
          width: '100%',
          backgroundColor: constants.COLOR_WHITE,

          '& .MuiInputBase-root': {
            padding: '18px 14px',
            borderRadius: '0',
          },
        },

        '&__buttons': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 0',
          '& :first-child': {
            marginRight: '4px',
          },
          '& :last-child': {
            marginLeft: '4px',
          },
        },
      },
    },
    datepcikerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& .__title': {
        marginRight: '8px',
      },
    },
    datepicker: {
      '& .MuiInputBase-root': {
        width: '200px',
        backgroundColor: 'white',
        padding: '4px 4px 4px 16px;',
        border: `1px solid rgba(0, 0, 0, 0.23)`,
      },

      '& .MuiInput-underline': {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
      },
      '& .MuiInput-focused': {
        '&::before': {
          border: 'none',
        },
      },
    },
    popover: {
      '& .MuiPickersDay-today': {
        color: constants.COLOR_MAIN,
        border: 'none',
        boxShadow: 'none',
      },
      '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: constants.COLOR_MAIN,
        color: constants.TEXT_GRAY_DARK,
      },
      '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: constants.COLOR_MAIN,
        color: constants.TEXT_GRAY_DARK,
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
