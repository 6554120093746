import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import { Close } from '../close/Index'

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    lineHeight: '70px',
    height: '70px',
    backgroundColor: constants.COLOR_WHITE,
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.16)',
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    maxWidth: '1080px',
    margin: '0 auto',

    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
    },
  },

  close: {
    marginRight: 'auto',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      padding: '0 16px',
    },
  },

  heading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    margin: 0,
    padding: 0,
    fontSize: '22px',
    fontWeight: 200,
  },
}))

export type IProps = {
  heading: string
  onClick: () => void
}

// -----------------------------
// Component
// -----------------------------
export const Header = (props: IProps) => {
  const classes = useStyles()

  return (
    <header className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.close} onClick={props.onClick}>
          <Close src="/assets/svg/hint/close_icn.svg" alt="close" />
        </Box>
        <Box>
          <h2 className={classes.heading}>{props.heading}</h2>
        </Box>
      </Box>
    </header>
  )
}
