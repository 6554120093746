import React, { useEffect, KeyboardEventHandler, useMemo } from 'react'

import { TextField, CircularProgress, Button } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form'


import { NoStyleButton } from 'components/NoStyleButton'
import { Comment } from 'pages/onboarding/components/comment'
import { FormCharCount, IFormCharCountInput } from 'pages/onboarding/components/formCharCount'
import { useMentions } from 'pages/onboarding/hooks/mentions'
import { useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { dateStringToLPassedTime } from 'utils/date'
import { OnboardingPostComment, OnboardingPostSecondComment, OnboardingTeamMember } from 'utils/generated'

import DeleteSvg from '../../assets/delete.svg'
import EditSvg from '../../assets/edit.svg'
import LikeOffSvg from '../../assets/like_comment_off.svg'
import LikeOnSvg from '../../assets/like_comment_on.svg'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

export type CommentSetProps = {
  teamId: string
  teamMembers: OnboardingTeamMember[]
  item: OnboardingPostComment | OnboardingPostSecondComment
  userId?: string
  allEditClose?: boolean
  setAllEditClose?: React.Dispatch<React.SetStateAction<boolean>>
  isSecondComment?: boolean
}

type PickInput = Pick<IFormCharCountInput, 'editComment'>
type PickInputReply = Pick<IFormCharCountInput, 'commentReply'>
type SubStyleProps = {
  mentionBackWidth: number | undefined
  mentionBackHeight: number | undefined
}

export const CommentSet: React.FC<CommentSetProps> = ({
  teamId,
  teamMembers,
  item,
  userId,
  allEditClose,
  setAllEditClose,
  isSecondComment,
}) => {
  const mentionBackWidth = document.getElementById('add-comment-input')?.clientWidth
  const mentionBackHeight = document.getElementById('add-comment-input')?.scrollHeight

  const classes = useStyles({ mentionBackWidth: mentionBackWidth, mentionBackHeight: mentionBackHeight })
  const history = useOnbHistory()

  const maxLength = 10000
  const maxLengthReply = 10000
  const inputName: keyof PickInput = 'editComment'
  const inputNameReply: keyof PickInputReply = 'commentReply'

  const { register, handleSubmit, errors, reset, watch, setValue } = useForm<PickInput>({ mode: 'onChange' })
  const {
    register: registerReply,
    handleSubmit: handleSubmitReply,
    errors: errorsReply,
    watch: watchReply,
    setValue: setValueReply,
  } = useForm<PickInputReply>({ mode: 'onChange' })
  const watchInput = watch(inputName, '')
  const watchInputReply = watchReply(inputNameReply, '')
  const element = document.getElementById('add-comment-input') as HTMLInputElement
  const {
    checkCorrectMentions,
    clearMentions,
    SuggestBox,
    openSuggestionBox,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
    convertMentionsStylesForInput,
  } = useMentions<keyof PickInputReply>('commentReply', watchInputReply, teamMembers, setValueReply, element)
  const onKeyDown = (e: KeyboardEvent) => {
    openSuggestionByTypeText(e)
  }
  useEffect(() => {
    closeSuggestionByTypeText()
  }, [watchInputReply, closeSuggestionByTypeText])

  const [isEdit, setIsEdit] = React.useState(false)
  const [processing, setProcessing] = React.useState(false)
  const [openReply, setOpenReply] = React.useState(false)

  const {
    deleteComment,
    updateComment,
    commentLikeToggleHandler,
    createSecondComment,
    secondCommentLikeToggleHandler,
    updateSecondComment,
    deleteSecondComment,
    loading,
  } = useOnboardingPostActions()

  const handleDelete = React.useCallback(() => {
    const confirm = window.confirm('本当に削除しますか？')

    if (setAllEditClose) setAllEditClose((prev) => !prev)
    if (confirm) {
      if ('postId' in item) deleteComment(item.id, item.postId)
      if ('postCommentId' in item) deleteSecondComment(item.id, item.postCommentId)
    }
  }, [deleteComment, deleteSecondComment, item, setAllEditClose])

  const handlePush = React.useCallback(
    () =>
      history.push(!isSecondComment ? OnbPages.TimelineCommentLikes : OnbPages.TimelineSecondCommentLikes, {
        teamId,
        postId: item.id,
      }),
    [history, isSecondComment, item.id, teamId]
  )

  const handleReply = React.useCallback(() => {
    setOpenReply((prev) => !prev)
  }, [])

  const handleLike = React.useCallback(() => {
    if ('postId' in item) commentLikeToggleHandler(item.id, teamId)
    if ('postCommentId' in item) secondCommentLikeToggleHandler(item.id, teamId)
  }, [commentLikeToggleHandler, secondCommentLikeToggleHandler, item, teamId])

  const onSubmit = async (data: PickInput) => {
    setProcessing(true)
    setIsEdit(false)
    if (data.editComment) {
      if ('postId' in item) await updateComment(item.id, item.postId, data.editComment)
      if ('postCommentId' in item) await updateSecondComment(item.id, item.postCommentId, data.editComment)
    }
  }

  const onSubmitReply = React.useCallback(
    async (data: PickInputReply) => {
      if (data.commentReply) {
        await createSecondComment(item.id, data.commentReply, teamId, checkCorrectMentions())
        handleReply()
        clearMentions()
      }
    },
    [handleReply, createSecondComment, checkCorrectMentions, clearMentions, item.id, teamId]
  )

  React.useEffect(() => {
    if (!loading) {
      setProcessing(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  React.useEffect(() => {
    setIsEdit(false)
    setValue(inputName, item.text)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEditClose])

  React.useEffect(() => {
    if (isEdit) {
      setValue(inputName, item.text)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit])

  const Like = useMemo(() => {
    return (
      <div className={classes.like}>
        {item.likeCount ? (
          <div>
            <NoStyleButton className={classes.likeCountButton} onClick={handlePush}>
              {item.likeCount} いいね
            </NoStyleButton>
          </div>
        ) : (
          <></>
        )}
        <div onClick={handleLike}>
          <img src={item.isLiked ? LikeOnSvg : LikeOffSvg} alt={item.isLiked ? 'liked' : 'like'} />
        </div>
      </div>
    )
  }, [item, handlePush, handleLike, classes])

  return (
    (<div className={classes.userContent}>
      <div className={classes.userNameContainer}>
        <div className={classes.userName}>{item.teamMember?.nickname}</div>
        <div className={classes.userTime}>{item.createdAt ? dateStringToLPassedTime(item.createdAt, 'time') : ''}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        {isEdit && !loading && !processing ? (
          <>
            <TextField
              variant="standard"
              autoFocus
              id="edit-comment"
              className={classes.textArea}
              placeholder={'コメントを追加する'}
              multiline
              maxRows={8}
              name={inputName}
              inputRef={register({ required: true, maxLength })} />
            <FormCharCount length={watchInput.length} maxLength={maxLength} />
          </>
        ) : (
          <>
            {loading || processing ? (
              <div className={classes.loadingContainer}>
                <CircularProgress size={24} />
              </div>
            ) : (
              <Comment text={item.text} teamMembers={teamMembers} />
            )}
          </>
        )}
        <div className={classes.commentItems}>
          {!isSecondComment ? (
            <div className={classes.reply} onClick={handleReply} role="button" tabIndex={0}>
              {openReply ? '閉じる' : '返信する'}
            </div>
          ) : (
            <div className={classes.reply}></div>
          )}
          <div className={classes.buttonContainer}>
            {item.userId === userId ? (
              <>
                {!isEdit && !loading && !processing ? (
                  <>
                    {Like}
                    <div onClick={() => setIsEdit(true)}>
                      <img src={EditSvg} alt="edit" />
                    </div>
                    <div onClick={handleDelete}>
                      <img src={DeleteSvg} alt="delete" />
                    </div>
                  </>
                ) : (
                  <>
                    {isEdit && !loading && !processing ? (
                      <>
                        <Button
                          className={classes.editCancel}
                          onClick={() => {
                            setIsEdit(false)
                            reset({ [inputName]: '' })
                          }}
                          variant="text"
                        >
                          キャンセル
                        </Button>
                        <Button
                          className={classes.editSave}
                          variant="text"
                          type="submit"
                          disabled={watchInput && !errors.editComment ? false : true}
                        >
                          保存
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <>{Like}</>
            )}
          </div>
        </div>
      </form>
      {openReply && (
        <div>
          <form onSubmit={handleSubmitReply(onSubmitReply)} className={classes.formReply}>
            <div
              className={classes.textareaReplyBack}
              dangerouslySetInnerHTML={{ __html: convertMentionsStylesForInput() }}
            />
            <textarea
              id="add-comment-input"
              name={inputNameReply}
              className={classes.textareaReply}
              placeholder={'返信する'}
              onKeyDown={onKeyDown as unknown as KeyboardEventHandler<HTMLTextAreaElement>}
              autoFocus
              required
              // rows={Math.max(watchInputReply.split('\n').length, isXsDown ? 4 : 1)}
              maxLength={maxLengthReply}
              ref={registerReply}
            />
            <SuggestBox ownRootStyles={{ right: 75, width: 170 }} ownItemStyles={{ right: 75, width: 170 }} />
            <div>
              <div className={classes.inputAdornment}>
                <div>
                  <NoStyleButton
                    className={`${classes.replyButton} ${classes.mention}`}
                    type="button"
                    onClick={openSuggestionBox}
                  >
                    @
                  </NoStyleButton>
                </div>
                <div>
                  <NoStyleButton
                    className={`${classes.replyButton} ${classes.send}${
                      !watchInputReply || errorsReply.commentReply ? ' ' + classes.disabled : ''
                    }`}
                    type="submit"
                    disabled={!watchInputReply || !!errorsReply.commentReply}
                  >
                    送信
                  </NoStyleButton>
                </div>
              </div>
            </div>
            <FormCharCount length={watchInputReply.length} maxLength={maxLengthReply} />
          </form>
        </div>
      )}
    </div>)
  );
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    like: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    likeCountButton: {
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },

    userContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    userNameContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      marginTop: 5,
    },
    userName: {
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
      marginRight: 8,
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
    },
    userTime: {
      fontSize: 10,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      marginTop: 6,
    },

    commentItems: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    reply: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 8,
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
      cursor: 'pointer',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: 16,
      marginTop: 10,
      '& img': {
        verticalAlign: 'top',
        cursor: 'pointer',
      },
    },
    editCancel: {
      marginRight: 8,
      color: constants.COLOR_ONBOARDING_GRAY,
      fontSize: 14,
      fontWeight: 'bold',
      height: 24,
      '&.MuiButton-root': {
        padding: 0,
        minWidth: 0,
      },
    },
    editSave: {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 14,
      fontWeight: 'bold',
      height: 24,
      '&.MuiButton-root': {
        padding: 4,
        minWidth: 0,
      },
    },

    textArea: {
      marginTop: 8,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 8,
      width: '100%',
      '& .MuiInputBase-root': {
        padding: 0,
      },
      '& .MuiInputBase-input': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
        padding: 8,
      },
      '& .MuiInput-underline:before': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:after': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },

    formReply: {
      marginTop: 8,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 8,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    textareaReplyBack: ({ mentionBackWidth }: SubStyleProps) => ({
      // for use mention highlight
      position: 'absolute',
      top: 0,
      left: 0,
      width: mentionBackWidth || '100%',
      color: 'transparent',
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      overflow: 'hidden',
      // for same as textarea
      margin: '10px 0px 10px 0px',
      padding: '0px 50px 10px 16px',
      fontSize: 12,
      letterSpacing: 0.1,
      lineHeight: 1.6,
    }),
    textareaReply: ({ mentionBackHeight }: SubStyleProps) => ({
      resize: 'none',
      border: 'none',
      flex: 1,
      width: '100%',
      height: mentionBackHeight,
      padding: '10px 50px 10px 16px',
      // for mentions
      background: 'transparent',
      position: 'relative',
      zIndex: 2,
      // for same as textareaBack
      fontSize: 12,
      letterSpacing: 0.1,
      lineHeight: 1.6,
      fontFamily: constants.ONBOARDING_FONT_FAMILY,
      '&:focus-visible': {
        border: 'none',
        outline: 'none',
      },
    }),

    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 8,
      height: 60,
      '& .MuiCircularProgress-root': {
        color: constants.COLOR_ONBOARDING_MAIN,
      },
    },

    textAreaReply: {
      marginTop: 8,
      fontSize: 12,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 8,
      width: '100%',
      '& .MuiInputBase-root': {
        padding: '12px 0 12px 16px',
      },
      '& .MuiInputBase-input': {
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
      },
      '& .MuiInput-underline:before': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:after': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
    input: {
      '&::placeholder': {
        color: constants.COLOR_ONBOARDING_GRAY_DARK,
        opacity: 1,
      },
    },
    inputAdornment: {
      display: 'flex',
      gap: '8px',
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    replyButton: {
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      minWidth: 56,
      minHeight: 32,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      borderRadius: 4,
    },
    mention: {
      fontSize: 20,
    },
    send: {
      fontSize: 12,
    },
    disabled: {
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
    },
  }),
  { name: 'CommentSet' }
)
