import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { TeamKarte } from 'utils/generated'

import { getKarteResult, getKarteValueType } from '../../../../utils'
import { TeamKarteWithTime, VALUE_HEIGHT } from '../DataList'

import { DataRowDetail } from './DataRowDetail'
import { ValueLabel } from './ValueLabel'

import { COLOR_MAIN_NEW } from 'assets/constants'

type Props = {
  teamKarte: TeamKarteWithTime | null | undefined
  page: number
  size: 'xs' | 'sm' | 'md'
  resultCount: number
  setSelectedTeamKarte: (value: TeamKarte) => void
  isExpandRow?: boolean
  displayTimeState?: {
    displayTime: number
    setDisplayTime: React.Dispatch<React.SetStateAction<number>>
  }
  handleCloseList?: () => void
}

export const DataRowValues: React.FC<Props> = ({
  teamKarte,
  page,
  size,
  resultCount,
  setSelectedTeamKarte,
  isExpandRow,
  displayTimeState,
  handleCloseList,
}) => {
  const classes = useStyles()

  const results = teamKarte ? getKarteResult(teamKarte.data) : null
  const type = getKarteValueType(results?.points[0].title)

  const dataRowDetail = (
    <DataRowDetail
      teamKarte={teamKarte}
      resultCount={resultCount}
      type={type}
      setSelectedTeamKarte={setSelectedTeamKarte}
      size={size}
      isExpandRow={isExpandRow}
      displayTimeState={displayTimeState}
      handleCloseList={handleCloseList}
    />
  )

  const NoneDiagnosed: React.FC<{ isFirstContent?: boolean }> = ({ isFirstContent }) => (
    <div
      className={classNames(classes.box, classes.value, classes.noneDiagnosed, isFirstContent && classes.firstContent)}
    >
      {isFirstContent ? '未実施' : '-'}
    </div>
  )
  const noneDiagnosedList = (count: number, hasFirstContent?: boolean) =>
    [...Array(count).keys()].map((_, index) => (
      <NoneDiagnosed key={`none-diagnosed-${index}`} isFirstContent={index === 0 && hasFirstContent} />
    ))

  const setLabels = () => {
    switch (size) {
      case 'md':
        return page === 1 ? (
          <>
            {results ? (
              <>
                {/* trend を渡す場合、title はスタイルの適用のみに利用 */}
                <ValueLabel title={results.points[0].title} trend={results.about.short} />
                <ValueLabel title={results.points[0].title} />
                <ValueLabel title={results.points[1].title} />
                <ValueLabel title={results.points[2].title} />
              </>
            ) : (
              noneDiagnosedList(4, true)
            )}
          </>
        ) : page === 2 ? (
          <>
            {results ? (
              <>
                <ValueLabel title={results.points[3].title} />
                <ValueLabel title={results.points[4].title} />
                <ValueLabel title={results.points[5].title} />
                <ValueLabel title={results.points[6].title} />
              </>
            ) : (
              noneDiagnosedList(4)
            )}
          </>
        ) : (
          <>
            {results ? (
              <>
                <ValueLabel title={results.points[7].title} />
                <ValueLabel title={results.points[8].title} />
              </>
            ) : (
              noneDiagnosedList(2)
            )}
            {/* 他のページと幅を合わせるための空要素 */}
            <ValueLabel />
            <ValueLabel />
          </>
        )

      case 'sm':
        switch (page) {
          case 1:
            return (
              <>
                {results ? (
                  <>
                    {/* trend を渡す場合、title はスタイルの適用のみに利用 */}
                    <ValueLabel title={results.points[0].title} trend={results.about.short} />
                    <ValueLabel title={results.points[0].title} />
                    <ValueLabel title={results.points[1].title} />
                  </>
                ) : (
                  noneDiagnosedList(3, true)
                )}
              </>
            )

          case 2:
            return (
              <>
                {results ? (
                  <>
                    <ValueLabel title={results.points[2].title} />
                    <ValueLabel title={results.points[3].title} />
                    <ValueLabel title={results.points[4].title} />
                  </>
                ) : (
                  noneDiagnosedList(3)
                )}
              </>
            )

          case 3:
            return (
              <>
                {results ? (
                  <>
                    <ValueLabel title={results.points[5].title} />
                    <ValueLabel title={results.points[6].title} />
                    <ValueLabel title={results.points[7].title} />
                  </>
                ) : (
                  noneDiagnosedList(3)
                )}
              </>
            )

          case 4:
            return (
              <>
                {results ? (
                  <>
                    <ValueLabel title={results.points[8].title} />
                  </>
                ) : (
                  noneDiagnosedList(1)
                )}
                {/* 他のページと幅を合わせるための空要素 */}
                <ValueLabel />
                <ValueLabel />
              </>
            )

          default:
            return <></>
        }

      case 'xs':
        switch (page) {
          case 1:
            return dataRowDetail

          case 2:
            // trend を渡す場合、title はスタイルの適用のみに利用
            return results ? (
              <ValueLabel title={results.points[0].title} trend={results.about.short} />
            ) : (
              <NoneDiagnosed isFirstContent />
            )
          case 3:
            return results ? <ValueLabel title={results.points[0].title} /> : <NoneDiagnosed />
          case 4:
            return results ? <ValueLabel title={results.points[1].title} /> : <NoneDiagnosed />
          case 5:
            return results ? <ValueLabel title={results.points[2].title} /> : <NoneDiagnosed />
          case 6:
            return results ? <ValueLabel title={results.points[3].title} /> : <NoneDiagnosed />
          case 7:
            return results ? <ValueLabel title={results.points[4].title} /> : <NoneDiagnosed />
          case 8:
            return results ? <ValueLabel title={results.points[5].title} /> : <NoneDiagnosed />
          case 9:
            return results ? <ValueLabel title={results.points[6].title} /> : <NoneDiagnosed />
          case 10:
            return results ? <ValueLabel title={results.points[7].title} /> : <NoneDiagnosed />
          case 11:
            return results ? <ValueLabel title={results.points[8].title} /> : <NoneDiagnosed />

          default:
            return <></>
        }
    }
  }

  return (
    <>
      {size !== 'xs' && dataRowDetail}
      {setLabels()}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    box: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: VALUE_HEIGHT,
    },
    value: {
      fontSize: 10,
      padding: 7,
      marginBottom: 2,
      flex: 1,
    },
    noneDiagnosed: {
      justifyContent: 'center',
      color: COLOR_MAIN_NEW,
    },
    firstContent: {
      justifyContent: 'flex-start',
    },
  }),
  { name: 'DataRowValues' }
)
