import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = {
  markType: 'required' | 'optional'
  isVisible?: boolean //default is true
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

type SubProps = {
  // you don't have to set itself manually on parent Components
  title: string
  bgcolor: string
}
type SubStyleProps = {
  // you don't have to set itself manually on parent Components
  bgcolor: string
}

export const FormLabelMark: React.FC<Props & StyleProps> = ({ markType, isVisible = true, ownStyles }) => {
  const marks: { [k in typeof markType]: SubProps } = {
    required: {
      title: '必須',
      bgcolor: constants.COLOR_ALERT,
    },
    optional: {
      title: '任意',
      bgcolor: constants.COLOR_ONBOARDING_GRAY,
    },
  }

  const classes = useStyles({ bgcolor: marks[markType].bgcolor, ownStyles: ownStyles })

  return isVisible ? <div className={classes.RequiredMarkRoot}>{marks[markType].title}</div> : <></>
}

const useStyles = makeStyles(
  {
    RequiredMarkRoot: ({ bgcolor, ownStyles }: StyleProps & SubStyleProps) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 4,
      fontSize: 10,
      whiteSpace: 'nowrap',
      color: constants.COLOR_WHITE,
      width: 36,
      height: 20,
      fontWeight: 'bold',
      padding: '5px 8px',
      backgroundColor: bgcolor,
      ...ownStyles,
    }),
  },
  { name: 'FormLabelMark' }
)
