import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding, useManageTeamBuildingMember } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import {
  InputCard,
  InputCardMemo,
  QuestionContainer,
  Button,
  InputTextarea,
  FormError,
  Waiting,
  ButtonContainer,
  QuestionPcLayout,
  Timer,
} from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

interface Input {
  answer: string
}

export const Q1: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { control, errors, handleSubmit, watch } = useForm<Input>()

  const { isLeader, teamBuilding } = React.useContext(HooksContext)
  const { currentTeamBuildingMember, refreshTeamBuildingMember, teamBuildingMembers } =
    React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const { updateTeamBuildingMember } = useManageTeamBuildingMember()

  const onSubmit = async (data: Input) => {
    const updated = await updateTeamBuildingMember({
      teamId: props.match.params.teamId,
      resultQ1: data.answer,
    })

    if (updated) {
      refreshTeamBuildingMember()
    }
  }

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q1Select,
      timeQ1Select: new Date().toISOString(),
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QuestionContainer
          active={1}
          progressDotsProps={!currentTeamBuildingMember?.resultQ1 ? { maxLength: 3, currentProgress: 1 } : {}}
          questionHeadBar
          colorTitleText={!currentTeamBuildingMember?.resultQ1}
        >
          {!currentTeamBuildingMember?.resultQ1 ? (
            <>
              <div className={classes.cardContainer}>
                <InputCard noRadius>
                  <QuestionPcLayout questionNumber={1} />
                  <div className={classes.inputContainer}>
                    <Controller
                      name={'answer'}
                      control={control}
                      rules={{ required: '入力してください' }}
                      render={({ onChange, name, value }) => (
                        <>
                          <InputTextarea name={name} value={value} onChange={onChange} />
                          <FormError error={errors.answer} />
                        </>
                      )}
                    />
                  </div>

                  <InputCardMemo>
                    <span>（例）</span>
                    <ul style={{ margin: 0, paddingLeft: 24 }}>
                      <li>学生時代のアルバイトで、音楽イベントの運営をし、皆で一致団結した時</li>
                      <li>3年目の時に所属していた部署で、会社で初の取り組みに対して、皆で支援しあって乗り越えた</li>
                    </ul>
                  </InputCardMemo>
                </InputCard>
              </div>

              {teamBuilding?.timeQ1 && <Timer from={teamBuilding.timeQ1} time={180} container />}

              <ButtonContainer
                fixed
                buttons={[
                  <Button key="q1-1" type={'submit'} disabled={!(watch('answer')?.length > 0)}>
                    次へ
                  </Button>,
                ]}
              />
            </>
          ) : (
            <Waiting
              who={isLeader ? 'leader' : 'member'}
              progress={{
                child: teamBuildingMembers.filter((t) => !!t.resultQ1).length,
                parent: teamBuildingMembers.length,
              }}
              onNext={onNext}
              status={teamBuilding?.status}
            />
          )}
        </QuestionContainer>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  cardContainer: {
    width: '100%',
    marginBottom: 150,
  },
  inputContainer: {
    marginBottom: 16,
  },
}))
