import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'

import CheckIcon from '../assets/check.svg'


type Props = {
  title: string
  subtitle: string
  nextAt: string | null | undefined
}

const FORMAT = 'YYYY年M月D日（ddd）'
const separateNextAt = (nextAt: string) => nextAt.split(' ')

export const Complete: React.FC<Props> = ({ title, subtitle, nextAt }) => {
  const classes = useStyles()
  const [date, start, join, end] = nextAt ? separateNextAt(nextAt) : ['', '', '', '']
  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} />
      <img height={80} className="check" alt="check" src={CheckIcon} />

      <ContentSection>
        <Content>
          <div className={classes.next}>
            <div>【次回日程】</div>
            <div>{nextAt ? dayjs(date).format(FORMAT) : '設定なし'}</div>
            <div>
              {start} {join} {end}
            </div>
          </div>
        </Content>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '40px 0',
    ' & .check': {
      display: 'block',
      margin: '80px auto',
    },
  },
  next: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '4px',
  },
}))
