import * as React from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify'
import { useUtils } from 'services/api/utils'

import { Breadcrumb } from 'components/Breadcrumb'
import { NoStyleButton } from 'components/NoStyleButton'

import Button from '../../components/Button'

import { Modal } from './components/Modal'
import { connector, ContainerProps } from './sendEmail.container'

import * as constants from 'assets/constants'

type Props = ContainerProps & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { getEncryptString } = useUtils()
  const [isDecrypting, setIsDecrypting] = React.useState<boolean>(true)
  const [decryptedEmail, setDecryptedEmail] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [isComplete, setIsComplete] = React.useState(false)
  const [countdown, setCountdown] = React.useState(0)
  const [disabled, setDisabled] = React.useState(false)
  const history = useHistory()
  const { state } = useLocation<{ isEmailResendTriggered: boolean } | undefined>()

  const redirectToTopPage = () => {
    history.replace(constants.PAGE_TOP)
    window.scrollTo(0, 0)
  }

  // URL パラメータから暗号化された email を取得
  const params = new URLSearchParams(props.location.search)
  const emailFromParams = params.get('key')

  /*
  以下の場合は、トップページにリダイレクト
    email を URLパラメータから取得できなかった
    ログイン済み (id が truthy)
  */
  if (!emailFromParams || props.user?.id) {
    redirectToTopPage()
  }

  const handleOpenModal = (isSuccess: boolean) => {
    setIsOpen(true)
    setIsComplete(isSuccess)
  }
  const handleCloseModal = () => setIsOpen(false)

  // 再送ボタンクリックで再送メールが送信
  const handleResendEmail = async (decryptedEmail: string) => {
    if (!decryptedEmail) return
    handleButtonClick()

    // 以下再送メールの実行には redux は使用せず、AUTH の resendSignUp を使用
    try {
      await Auth.resendSignUp(decryptedEmail)
      // 送信完了のモーダルを表示
      handleOpenModal(true)
    } catch (e) {
      // エラーのモーダルを表示
      handleOpenModal(false)
    }
  }

  React.useEffect(() => {
    if (!emailFromParams) return

    getEncryptString({ encrypt: false, text: emailFromParams }).then((decrypted) => {
      setIsDecrypting(false)

      if (!decrypted) {
        redirectToTopPage()
        return
      }

      setDecryptedEmail(decrypted)

      if (state?.isEmailResendTriggered) {
        // ページリロード時に handleResend が実行されないように history の state をリセット
        history.replace({ ...props.history.location, state: undefined })
        props.resendSignUp(decrypted)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 再送ボタンクリック後、10秒間はボタンを無効化する
  const handleButtonClick = () => {
    setCountdown(10)
    setDisabled(true)
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1)
    }, 1000)

    setTimeout(() => {
      clearInterval(intervalId)
      setDisabled(false)
    }, 10000)
  }

  return (
    <div className={classes.main}>
      <div className={classes.breadcrumb}>
        <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="新規会員登録" />
      </div>

      <div className={classes.container}>
        <div className="__flexBox">
          <h1 className="__header">確認メールを送信しました</h1>
          <img
            className="__successImg"
            src={`${process.env.PUBLIC_URL}/assets/svg/successMainNew.svg`}
            alt="success mark"
          />
          {!isDecrypting && decryptedEmail && (
            <div className={classes.fadeIn}>
              <div className="__titleDescription">
                <strong className="__alertText">まだ登録は完了していません。</strong>
                <br />
                <strong>{decryptedEmail}</strong>にメールをお送りいたしました。
                <br />
                メール内のリンクをクリックして登録を完了させてください。
              </div>
              <div className={classes.resendButtonWrapper}>
                <NoStyleButton
                  type="button"
                  onClick={() => handleResendEmail(decryptedEmail)}
                  disabled={!decryptedEmail || disabled}
                  className={disabled ? classes.disabledButton : classes.button}
                >
                  確認メールを再送する
                </NoStyleButton>
              </div>
            </div>
          )}
          <div className={classes.countBox}>
            {countdown > 0 && (
              <div className="__titleDescription">
                <strong className="__number">{countdown}</strong>秒後に確認メールをもう一度送れるようになります
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className={classes.modalBox}>
          {isComplete ? (
            <>
              <img src={`${process.env.PUBLIC_URL}/assets/svg/successSub.svg`} alt="success mark" />
              <p className="__title">
                確認コードを<strong>{decryptedEmail}</strong>
                に再送しました。メール内のリンクをクリックして登録を完了させてください。
              </p>
            </>
          ) : (
            <>
              <img src={`${process.env.PUBLIC_URL}/assets/svg/exclamationRed.svg`} alt="error mark" />
              <p className="__title red">確認コードの再送に失敗しました。時間をおいて再度お試しください。</p>
            </>
          )}
          <Button
            bgColor={constants.COLOR_ORANGE_BTN}
            textColor={constants.COLOR_WHITE}
            body={<p>閉じる</p>}
            noShadow
            style={{ width: '190px', height: '54px' }}
            fontSize={14}
            hoverBgColor={constants.TEXT_WHITE}
            hoverTextColor={constants.COLOR_ORANGE_BTN}
            hoverBorderColor={constants.COLOR_ORANGE_BTN}
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      minHeight: `calc(100vh - ${constants.HEADER_HEIGHT_SP}px)`,
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      [theme.breakpoints.up('md')]: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT}px)`,
      },
    },

    breadcrumb: {
      margin: '0 auto 32px',
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
        marginBottom: 40,
      },
    },

    container: {
      margin: '0 auto',
      padding: '0px 24px 100px',
      [theme.breakpoints.up('md')]: {
        maxWidth: 1000,
      },

      '& .__flexBox': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
        [theme.breakpoints.down('md')]: {
          gap: 16,
        },
      },

      '& h1': {
        margin: 0,
        textAlign: 'center',
        fontSize: 32,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
          fontSize: 20,
        },
      },

      '& .__successImg': {
        width: 80,
        height: 80,
      },

      '& .__titleDescription': {
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
          fontSize: 13,
        },

        '& .__alertText': {
          color: constants.COLOR_DANGER,
          fontSize: 20,
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
        },

        '& strong': {
          color: constants.COLOR_MAIN_NEW,
        },

        '& br': {
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },
      },
    },

    resendButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 40,
    },

    button: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 600,
      textDecoration: 'underline',

      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.7,
        },
      },
    },

    disabledButton: {
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      fontSize: 14,
      fontWeight: 600,
      textDecoration: 'none',
      cursor: 'not-allowed',
    },

    fadeIn: {
      animation: `$fadeIn 800ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },

    countBox: {
      marginTop: 40,

      '& .__titleDescription': {
        padding: 24,
        backgroundColor: constants.COLOR_WHITE2,
        borderRadius: 8,
      },

      '& .__number': {
        fontSize: 24,
        paddingRight: 3,
      },
    },

    modalBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
      [theme.breakpoints.down('md')]: {
        gap: 16,
      },

      '& .__title': {
        margin: 0,
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
          fontSize: 13,
        },

        '& strong': {
          color: constants.COLOR_MAIN_NEW,
          wordBreak: 'break-all',
        },
      },

      '& .__title.red': {
        color: constants.COLOR_DANGER,
      },
    },
  }),
  { name: 'SignupSendEmailPage' }
)

export default connector(Index)
