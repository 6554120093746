import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../assets/constants'
import * as pages from '../../assets/pages'
import Button from '../../components/Button'
import MessagePanel from '../../components/MessagePanel'

import { connector, ContainerProps } from './complete.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    props.onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { classes } = props

  const handlePageSelfCheck = () => {
    props.history.push(pages.SELFCHECK)
    window.scrollTo(0, 0)
  }
  const handlePageTop = () => {
    props.history.push(constants.PAGE_TOP)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__signup_complete_container">
          <MessagePanel
            success={true}
            body={
              <div className={classes.messageBody}>
                <p>Cocolaboへの登録ありがとうございます。</p>
                <p>
                  Cocolaboでは日々忙しく戦っている
                  <br />
                  管理職の武器になるように、
                  <br />
                  人や組織のエンゲージメント向上の観点から
                  <br />
                  手助けとなる7つの道具が揃っています。
                </p>
                <p>「とはいえ、まずは何からやればよいのか？」</p>
                <p>
                  そんな最初の一歩目は、
                  <br />
                  Cocolabo第1のツールとしてこちらをご利用ください。
                </p>
                <p>
                  Cocolaboはじめの第一歩
                  <br />
                  「セルフチェック」です。
                </p>
                <p className="__left">
                  これを使えば、今あなたが気づいていない問題が明らかになったり、悩みを解決するきっかけが見つかったり、そのためにCocolaboのどのツールから使えばいいいのかがわかります。
                </p>
              </div>
            }
            footer={
              <div className={classes.footer}>
                <Button
                  bgColor={constants.COLOR_SECOND}
                  textColor={constants.COLOR_WHITE}
                  fullWidth={true}
                  onClick={handlePageSelfCheck}
                  body={<div>セルフチェックへ</div>}
                />

                <p className="__link">
                  <span className="__txt" onClick={handlePageTop}>
                    TOPページへ戻る
                  </span>
                </p>
              </div>
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '100vh',
      backgroundColor: constants.COLOR_MAIN,
      background: `linear-gradient(-${
        Math.atan2(window.innerHeight - constants.HEADER_HEIGHT, window.innerWidth) * (180 / Math.PI)
      }deg, ${constants.COLOR_MAIN_DARK} 0%, ${constants.COLOR_MAIN_DARK} 50%, transparent 50%)`,

      '& .__signup_complete_container': {
        margin: '0 auto',
        padding: '36px 16px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },
      },
    },

    messageBody: {
      fontSize: '12px',
    },

    footer: {
      '& .__link': {
        marginTop: 16,
        textAlign: 'center',
      },
      '& .__txt': {
        color: constants.COLOR_MAIN,
        borderBottom: `1px solid ${constants.COLOR_MAIN}`,
        cursor: 'pointer',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
