import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { mergeClasses } from 'pages/onboarding/utils/styles'

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={mergeClasses([classes.container, className])}>
      <svg xmlns="http://www.w3.org/2000/svg" width="11.999" height="12.001" viewBox="0 0 11.999 12.001">
        <path
          d="M5.836-.124,3.5-2.461a.562.562,0,0,0-.4-.164H2.719a4.851,4.851,0,0,0,1.031-3A4.874,4.874,0,0,0-1.125-10.5,4.874,4.874,0,0,0-6-5.625,4.874,4.874,0,0,0-1.125-.75a4.851,4.851,0,0,0,3-1.031V-1.4a.562.562,0,0,0,.164.4L4.376,1.336a.56.56,0,0,0,.795,0L5.834.673A.565.565,0,0,0,5.836-.124Zm-6.961-2.5a3,3,0,0,1-3-3,3,3,0,0,1,3-3,3,3,0,0,1,3,3A3,3,0,0,1-1.125-2.625Z"
          transform="translate(6 10.5)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
