import * as React from 'react'
import { RouteComponentProps, Link, useLocation, useHistory } from 'react-router-dom'

import { TextField } from '@mui/material'
import { Dialog } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useForm } from 'react-hook-form'
import { useUtils } from 'services/api/utils'

import { Breadcrumb } from 'components/Breadcrumb'
import { AccountType } from 'utils/generated'

import * as constants from '../../assets/constants'
import { USERNAME_EXISTS_EXCEPTION } from '../../assets/errorCode'
import Button from '../../components/Button'
import MessagePanel from '../../components/MessagePanel'
import { ISignup } from '../../services/amplify/signup'

import { connector, ContainerProps } from './index.container'

import { PatternEmailLowerCase, PatternEmailLowerCaseMessage, PatternPass } from 'assets/patterns'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const SIGN_UP_AS_PERSONAL = 'personal'

export const storageKeySignIn = 'sign-in-r'

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const [decryptedParamEmail, setDecryptedParamEmail] = React.useState<string>()

  const query = new URLSearchParams(props.location.search)
  // signout のコメントアウトにともないコメント化
  // const userId = query.get('user')
  const paramEmail = query.get('email')

  const history = useHistory()
  const { state } = useLocation<{ transition: string } | undefined>()

  const { register, handleSubmit, errors, clearErrors, getValues, setValue } = useForm<ISignup>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  React.useEffect(() => {
    if (props.user.id) {
      props.history.push(constants.PAGE_TOP)
    }
  }, [props.user.id, props.history])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [errors])

  const onSubmit = (data: ISignup) => {
    if (decryptedParamEmail) {
      data.email = decryptedParamEmail
    }
    if (state?.transition) {
      localStorage.setItem(storageKeySignIn, state.transition)
    }
    props.signup(data, {
      accountType: query.get('su_flg') === SIGN_UP_AS_PERSONAL ? AccountType.Personal : AccountType.Company,
    })
  }

  const handleClose = () => {
    // 登録済み・メールアドレス未認証の場合は、モーダルを閉じる際にサインインページに遷移させる
    if (props.user?.error?.code === USERNAME_EXISTS_EXCEPTION) {
      history.replace(constants.PAGE_SIGNIN)
    }

    clearErrors()
    props.refreshErrors()
    setIsOpenErrorModal(false)
  }

  const [checked, setChecked] = React.useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }

  // ログインユーザーがアクセスしたときはトップページにリダイレクトするので、signout の必要性が不明なためコメントアウト。ただし何かしら影響があればもとに戻す。
  // React.useEffect(() => {
  //   if (userId) {
  //     if (props.user.id) {
  //       props.signout()
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const { getEncryptString } = useUtils()
  const [isOpenErrorModal, setIsOpenErrorModal] = React.useState(false)
  React.useEffect(() => {
    // ログインユーザーがアクセスしたときはトップページにリダイレクトするので、signout の必要性が不明なためコメントアウト。ただし何かしら影響があればもとに戻す。
    // if (props.user.id) {
    //   props.signout()
    // }

    if (props.user?.error || errors?.username || errors?.email || errors?.password || errors?.passwordRe) {
      setIsOpenErrorModal(true)
      return
    }

    if (
      props.user?.id || // id が truthy の場合は signup・confirm 処理が完了しているので、この useEffect では何もしない
      !props.user?.email || // email が falsy の場合は UserActions.signup が失敗しているので何もしない
      !props.user?.hasSignupProcessed // UserActions.signup による state 更新が完了していないので何もしない
    )
      return

    getEncryptString({ encrypt: true, text: props.user.email }).then((encrypted) => {
      if (encrypted) {
        history.push({
          pathname: constants.PAGE_SIGNUP_SEND_EMAIL,
          search: `?key=${encodeURIComponent(encrypted)}`,
          state: { isEmailResendTriggered: false },
        })
        window.scrollTo(0, 0)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, errors])

  const decryptedEmail = React.useCallback(async () => {
    if (paramEmail) {
      const decrypted = await getEncryptString({ encrypt: false, text: paramEmail })

      if (decrypted) {
        setValue('email', decrypted)
        setDecryptedParamEmail(decrypted) // disabled の input は onSubmit で値を取れないので、useState で値を保持する。
      }
    }
  }, [paramEmail, getEncryptString, setValue])

  React.useEffect(() => {
    decryptedEmail()
  }, [decryptedEmail])

  if (!props.user.fetched || props.user.id) {
    return null
  }

  return (
    (<React.Fragment>
      <div className={classes.main}>
        <div className={classes.breadcrumb}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="新規会員登録" />
        </div>
        <div className="__signup_index_container">
          <div
            style={{
              display:
                !props.user?.error && !errors.username && !errors.email && !errors.password && !errors.passwordRe
                  ? 'block'
                  : 'none',
            }}
          >
            <div className="__signup_index_container__header">新規会員登録</div>
            <div className="__signup_index_container__titleDescription">以下の情報を入力して新規登録してください。</div>

            <div className="__signup_index_container__body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="__formGroup">
                  <div className="__labelWrapper">
                    <label>ユーザー名</label>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </div>
                  <TextField
                    variant="standard"
                    name="username"
                    className="__textField"
                    inputRef={register({
                      required: 'ユーザー名を入力してください。',
                    })} />
                </div>
                <div className="__formGroup">
                  <div className="__labelWrapper">
                    <label>メールアドレス（ログインID）</label>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </div>
                  <TextField
                    variant="standard"
                    name="email"
                    type="email"
                    inputMode="email"
                    className="__textField"
                    inputRef={register({
                      required: 'メールアドレスを入力してください。',
                      pattern: {
                        value: PatternEmailLowerCase,
                        message: PatternEmailLowerCaseMessage,
                      },
                    })}
                    disabled={!!paramEmail} />
                </div>
                <div className="__formGroup">
                  <div className="__labelWrapper">
                    <label>パスワード</label>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </div>
                  <TextField
                    variant="standard"
                    name="password"
                    className="__textField"
                    type="password"
                    inputRef={register({
                      required: 'パスワードを入力してください。',
                      pattern: {
                        value: PatternPass,

                        message: `パスワードを半角英数大文字・小文字,記号を一つ以上含む8〜24文字で入力してください。使用可能な記号は\n!"#$%&'()*+,-./:;<=>?@[]^_\`{|}~ です`,
                      },
                    })} />
                  <div className="__annotation">
                    ※12文字以上、英字（大文字）、英字（小文字）、数字、記号の4種類を組み合わせたもので設定を推奨
                  </div>
                </div>
                <div className="__formGroup">
                  <div className="__labelWrapper">
                    <label>パスワード再入力</label>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </div>
                  <TextField
                    variant="standard"
                    name="passwordRe"
                    className="__textField"
                    type="password"
                    inputRef={register({
                      required: '確認用パスワードを入力してください。',
                      validate: {
                        same: (value) =>
                          value === getValues('password') ||
                          'パスワードが一致しません。\nパスワードをご確認の上、もう一度入力し直して ください。',
                      },
                    })} />
                </div>

                <div className="__checkWrapper">
                  <div className="__check">
                    <label>
                      <input onChange={handleChange} type="checkbox" name="checkPersonalInfo" checked={checked} />
                      <span className="__check__description">
                        <Link to={constants.PAGE_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                          個人情報の取り扱い
                        </Link>
                        および
                        <Link to={constants.PAGE_TERMS} target="_blank" rel="noopener noreferrer">
                          利用規約
                        </Link>
                        に同意する
                      </span>
                    </label>
                  </div>

                  <div className="__buttons">
                    <Button
                      bgColor={checked ? constants.COLOR_ORANGE_BTN : constants.COLOR_TEAMBUILDING_NEUTRAL_200}
                      textColor={checked ? constants.COLOR_WHITE : constants.COLOR_TEAMBUILDING_NEUTRAL_500}
                      fullWidth={true}
                      submit={true}
                      body={<div>新規会員登録</div>}
                      disabled={!checked}
                      style={{ height: '100%' }}
                      noShadow
                      hoverBgColor={constants.TEXT_WHITE}
                      hoverTextColor={constants.COLOR_ORANGE_BTN}
                      hoverBorderColor={constants.COLOR_ORANGE_BTN}
                    />
                  </div>
                </div>

                <hr className="__hr" />
              </form>
              <div className={classes.wrapperLogin}>
                既に登録済みの方は
                <Link to={constants.PAGE_SIGNIN} rel="noopener noreferrer">
                  こちら
                </Link>
              </div>
            </div>
          </div>

          <Dialog open={isOpenErrorModal} onClose={handleClose} className={classes.errorDialog}>
            <MessagePanel
              failure={true}
              handleClose={handleClose}
              body={
                errors.username ? (
                  errors.username.message
                ) : errors.email ? (
                  errors.email.message
                ) : errors.password ? (
                  errors.password.message
                ) : errors.passwordRe ? (
                  errors.passwordRe.message
                ) : props.user?.error && props.user.error.code === USERNAME_EXISTS_EXCEPTION ? (
                  <>
                    <p>
                      このメールアドレスは既に登録されています。
                      <Link to={constants.PAGE_SIGNIN} className={classes.link}>
                        ログイン
                      </Link>
                      してご利用ください。
                    </p>
                    <p>お心当たりのない場合は、再度よくご確認の上、メールアドレスをご入力ください。</p>
                  </>
                ) : (
                  <p>{props.user?.error?.message}</p>
                )
              }
            />
          </Dialog>
        </div>
      </div>
    </React.Fragment>)
  );
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      '& .MuiInputBase-input': {
        padding: 17,
      },
      background: `linear-gradient(-${
        Math.atan2(window.innerHeight - constants.HEADER_HEIGHT, window.innerWidth) * (180 / Math.PI)
      }deg, ${constants.COLOR_WHITE} 0%, ${constants.COLOR_WHITE} 50%, transparent 50%)`,

      '& .__signup_index_container': {
        margin: '0 auto',
        padding: '0px 24px 133px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 1000,
        },

        '&__header': {
          marginTop: 80,
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bold',
          [theme.breakpoints.down('md')]: {
            marginTop: 32,
            fontSize: 20,
          },
        },
        '&__titleDescription': {
          marginTop: 28,
          textAlign: 'center',
          fontSize: 14,
          [theme.breakpoints.down('md')]: {
            marginTop: 22,
            fontSize: 13,
          },
        },
        '&__body': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: '8px',
          marginTop: 80,
          padding: '12px 24px 80px',
          boxShadow: '1px 3px 9px 0px rgba(21, 27, 38, 0.50)',
          [theme.breakpoints.up('md')]: {
            padding: '48px 200px 160px',
          },

          '& .__hr': {
            height: 1,
            backgroundColor: '#E3E3E3',
            border: 'none',
            margin: '24px 0',
          },

          '& form': {
            '& .__formGroup': {
              margin: '32px 0 0',
              '& .__labelWrapper': {
                marginBottom: 12,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                '& label': {
                  display: 'block',
                  marginRight: 8,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
                '& .__labelSVG': {
                  width: 26,
                  height: 15,
                  '& img': {
                    width: '100%',
                  },
                },
              },
              '& .__annotation': {
                marginTop: 12,
                paddingLeft: '1em',
                textIndent: '-1em',
              },
            },

            '& .__textField': {
              width: '100%',
              '& input': {
                border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
                borderRadius: 8,
                '&:disabled': {
                  backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
                  color: constants.COLOR_GRAY_DARK,
                },
              },

              '& .MuiInput-underline:before': {
                border: constants.COLOR_MAIN, // Semi-transparent underline
              },
              '& .MuiInput-underline': {
                '&:after': {
                  border: constants.COLOR_MAIN, // Solid underline on focus
                },
              },
            },

            '& .__descriptionBox': {
              width: '100%',
              height: 325,
              marginTop: 30,
              marginBottom: 20,
              padding: '5px 15px',
              boxSizing: 'border-box',
              overflow: 'auto',
              border: 'solid 1px ' + constants.COLOR_GRAY_LIGHT2,
              [theme.breakpoints.up('sm')]: {
                height: 225,
              },
              '& .__description': {
                fontSize: '12px',
                textAlign: 'justify',
                '& .__indent': {
                  paddingLeft: 32,
                },
                '& a': {
                  color: constants.COLOR_MAIN,
                  textDecoration: 'underline',
                  overflowWrap: 'break-word',
                },
              },
            },
            '& .__checkWrapper': {
              display: 'flex',
              marginTop: 48,
              gap: '40px 8px',
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                justifyContent: 'space-between',
              },
              alignItems: 'center',
            },

            '& .__check': {
              '& label': {
                display: 'flex',
                alignItems: 'flex-start',
                '& input': {
                  flexShrink: 0,
                  position: 'relative',
                  top: -3,
                  display: 'inline-block',
                  margin: '0 4px 0 0',
                  height: 20,
                  width: 20,
                  background: '#fff',
                  border: `1px solid ${constants.COLOR_ONBOARDING_GRAY}`,
                  borderRadius: 4,
                  '-webkit-appearance': 'none',
                  appearance: 'none',
                  '&:checked': {
                    background: constants.COLOR_MAIN_NEW,
                    border: 'none',
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/svg/checkForCheckbox.svg)`,
                    backgroundSize: '14px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  },
                },
              },
              '&__description': {
                marginLeft: '3px',
                fontSize: '14px',
                verticalAlign: 'text-bottom',
                '& a': {
                  color: constants.COLOR_ONBOARDING_MAIN,
                  textDecoration: 'underline',
                  padding: '0 2px',
                  fontWeight: 'bold',
                },
              },
            },
            '& .__btnWrapper': {
              marginTop: 40,
            },

            '& .__questionnaire': {
              marginBottom: '18px',
              '&__label': {
                fontSize: '12px',
                lineHeight: '18px',
                color: constants.COLOR_MAIN,
                marginBottom: '5px',
              },
              '&__list': {
                fontSize: '12px',
              },
              '&__text': {
                fontSize: 8,
                margin: 0,
              },
            },

            '& .__buttons': {
              height: 50,
              width: 200,

              [theme.breakpoints.down('md')]: {
                width: 250,
              },
            },
          },

          '&__link': {
            fontSize: 14,
            textAlign: 'center',
            color: constants.TEXT_GRAY_DARK,
          },
        },
      },
    },
    errorDialog: {
      '& .MuiDialog-paper': {
        margin: '32px 24px',
        borderRadius: 8,
        maxWidth: 327,
      },
    },
    link: {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    wrapperLogin: {
      display: 'flex',
      justifyContent: 'center',
      '& a': {
        color: constants.COLOR_ONBOARDING_MAIN,
        textDecoration: 'underline',
        fontWeight: 'bold',
      },
    },
    breadcrumb: {
      margin: '0 auto',
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
