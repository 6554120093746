import * as React from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import makeStyles from '@mui/styles/makeStyles'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import Button from 'components/Button'
import { ErrorMessage } from 'pages/hint/_shared/components/ereorMessage/Index'
import { Header } from 'pages/hint/_shared/components/modal/header/Index'
import { Container } from 'pages/hint/_shared/layouts/container/Index'
import { Wrapper } from 'pages/hint/_shared/layouts/wrapper/Index'
import { useDialogCancel } from 'pages/hint/faq/_shared/components/modal/dialog/dialogCancel/useDialogCancel'
import { useDialogPublish } from 'pages/hint/faq/_shared/components/modal/dialog/dialogPublish/useDialogPublish'
import { useTitle } from 'pages/hint/faq/_shared/components/modal/title/useTitle'
import { useModalValue } from 'pages/hint/tips/_shared/components/modal/modal/useModalValue'

import '../../../assets/editor.scss'
import { Categories } from '../categories/Index'
import { useCategory } from '../categories/useCategory'
import { DialogCancel } from '../dialog/dialogCancel/Index'
import { DialogPublish } from '../dialog/dialogPublish/Index'
import { Title } from '../title/Index'

import { useModalHandler } from './useModalHandler'

import * as constants from 'assets/constants'
import * as registers from 'assets/registers'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    willChange: 'content',
    maxWidth: '100%',
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: constants.COLOR_WHITE,
  },

  inner: {
    width: '100%',
    height: '100vh',
    overflow: 'scroll',
  },
}))

// -----------------------------
// Components
// -----------------------------
export const Modal = () => {
  const classes = useStyles()
  const title = useTitle()
  const category = useCategory()
  const modalValue = useModalValue()
  const modalHandler = useModalHandler()
  const dialogCancel = useDialogCancel()
  const dialogPublish = useDialogPublish()

  const modalSelector = useSelector((state: RootStateType) => state.hint.ui)
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)

  const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createEmpty())

  // --------------------------------------------------
  // Errorがある場合
  // --------------------------------------------------
  React.useEffect(() => {
    if (commonSelector.error) {
      alert(commonSelector.error.error)
    }
  }, [commonSelector])

  // --------------------------------------------------
  // Editorの入力時の処理
  // --------------------------------------------------
  const onEditorStateChange = (updatedEditorState: EditorState) => {
    const html = draftToHtml(convertToRaw(updatedEditorState.getCurrentContent()))
    setEditorState(updatedEditorState)
    modalValue.validateContent(html)
    modalValue.setContentValue(html)
    modalValue.formatContentPreviewDatas(html)
  }

  // --------------------------------------------------
  // Modal内の全アクション共通ハンドラー
  // --------------------------------------------------
  const onCommonHandler = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    modalValue.setTitleValue(title.getTitle())
    modalValue.setCategoryValue(category.getCurrentCategory())
    modalValue.validateTitle(title.getTitle())

    // どれも入力されていない場合は何もしない
    if (!title.getTitle() && !category.getCurrentCategory() && modalValue.contentValue === '') {
      return
    }

    // EditorのParamsをセットする
    if (title.getTitle() !== '') {
      modalHandler.setParams({
        id: modalSelector.modal.status === 'UPDATE' ? hintSelector.hint.id : modalSelector.modal.id,
        title: modalValue.titleValue,
        content: modalValue.contentValue ? modalValue.contentValue : '',
        contentPreview: modalValue.contentPreviewValue ? modalValue.contentPreviewValue : '',
        category: modalValue.categoryValue ? modalValue.categoryValue : null,
        isPublished: 1,
      })
    }
  }

  // --------------------------------------------------
  // EditorのSubmit時の処理
  // --------------------------------------------------
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    onCommonHandler(event)
    if (title.getTitle() === '') return
    if (modalValue.titleError || modalValue.contentError) return
    dialogPublish.onOpenDialog()
  }

  // --------------------------------------------------
  // Editorのバツボタンとキャンセルボタンを押した時の処理
  // --------------------------------------------------
  const onExitHandler = (): void => {
    dialogCancel.onOpenDialog()
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.inner}>
        <Header onClick={onExitHandler} heading={'お悩み相談'} />

        <DialogCancel presenter={dialogCancel} />
        <DialogPublish presenter={dialogPublish} params={modalHandler.getParams()} />

        <form>
          <Wrapper color={constants.COLOR_WHITE}>
            <Container maxWidth={780}>
              <Box mt="74px">
                <Title
                  name="title"
                  presenter={title}
                  value={modalValue.titleValue}
                  onChange={(e) => {
                    modalValue.setTitleValue(e.target.value)
                    modalValue.validateTitle(e.target.value)
                    title.onTitleEnter(e)
                  }}
                />
              </Box>
              {modalValue.titleError && (
                <Box display="flex" mt="10px" mx={{ xs: '16px', lg: '0px' }}>
                  <ErrorMessage message={registers.MODAL_TIPS.TITLE.required.message} />
                </Box>
              )}

              <Box mt={{ xs: '48px', lg: '32px' }}>
                <Editor
                  editorClassName="conclusion-editor-class"
                  onEditorStateChange={onEditorStateChange}
                  editorState={editorState}
                  toolbarHidden
                  placeholder="本文入力"
                />
              </Box>
              {modalValue.contentError && (
                <Box display="flex" mt="10px" mx={{ xs: '16px', lg: '0px' }}>
                  <ErrorMessage message={registers.MODAL_TIPS.BODY.maxLength.message} />
                </Box>
              )}

              <Box mt="140px" maxWidth="347px" px={{ xs: '16px', md: '0px' }}>
                <Categories
                  name="category"
                  presenter={category}
                  type={'post'}
                  value={modalValue.categoryValue}
                  onChange={(e) => {
                    modalValue.setCategoryValue(e.target.value)
                    category.setCurrentCategory(e)
                  }}
                />
              </Box>

              <Box mt="140px" pb="120px">
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6 }}>
                    <Button
                      bgColor={constants.COLOR_GRAY}
                      textColor={constants.TEXT_BLACK}
                      fullWidth={true}
                      onClick={onExitHandler}
                      body={<div>キャンセル</div>}
                    />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <Button
                      submit={true}
                      bgColor={constants.COLOR_SECOND}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      onClick={onSubmitHandler}
                      body={<div>相談する</div>}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Wrapper>
        </form>
      </Box>
    </Box>
  )
}
