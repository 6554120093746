import * as React from 'react'

import { Box, SwipeableDrawer } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { Categories } from 'pages/hint/_shared/components/categories/Index'
import { IuseCategory } from 'pages/hint/_shared/components/categories/useCategory'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: { zIndex: 1300 },
  panel: {
    width: '280px',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  open: boolean
  onOpen: () => void
  onClose: () => void
  presenter: IuseCategory
}

// -----------------------------
// Component
// -----------------------------
export const SearchPanel = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box>
      <SwipeableDrawer
        anchor={'right'}
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        className={classes.drawerPaper}
      >
        <Box className={classes.panel}>
          <Box py="32px" px="16px">
            <Categories presenter={props.presenter} />
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  )
}
