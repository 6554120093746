import React, { useContext } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import { BackResponsiveCard, ButtonForGhost, GrayBackGround, UsefulCard } from '../components/atoms'
import { GhostReported } from '../components/organisms'
import { GhostContext } from '../contexts/ghost'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { ResearchContext } from '../contexts/research'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

import { constants } from 'assets'

export const Step3Presentation: React.FC = () => {
  const classes = useStyles()

  const { currentHistory } = useContext(HistoryContext)
  const { ghosts } = React.useContext(GhostContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList } = useContext(ghostTeamMemberContext)
  const { selectNextPresenter } = useContext(CommandContext)
  const { researches2 } = useContext(ResearchContext)

  const isMdUp = useCustomMediaQuery('up', 'md')
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimerMemberNoBtn' })

  const isLeader = teamMember?.role === GhostMemberRole.Leader

  const ghost = React.useMemo(() => ghosts.find((g) => g.id === currentHistory?.ghostId), [currentHistory, ghosts])

  const r2 = React.useMemo(() => {
    return researches2.sort((a, b) => (a.like < b.like ? 1 : -1))
  }, [researches2])

  const presenter = React.useMemo(() => {
    if (!team?.timerS3SelectUserIds?.length || !teamMemberList?.length) return null
    // timerS3SelectUserIds の先頭の要素が発表者の id
    const presenterId = team.timerS3SelectUserIds[0]

    return teamMemberList.find((m) => m.userId === presenterId)
  }, [team?.timerS3SelectUserIds, teamMemberList])

  React.useEffect(() => {
    if (team && team.progress === GhostProgress.S3DonePresentationOptions) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [team])

  const onNextPresenter = React.useCallback(async () => {
    if (!teamMember || !team?.timerS3SelectNumber || !team?.timerS3SelectUserIds) return

    await selectNextPresenter({
      team,
      selectNumber: team.timerS3SelectNumber,
      selectUserIds: team.timerS3SelectUserIds,
      teamMemberList,
      currentMember: teamMember,
      nextProgress: GhostProgress.S3DoneRandomAnswers,
    })
  }, [team, teamMember, teamMemberList, selectNextPresenter])

  if (!ghost) return null

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs, color: constants.TEXT_GRAY_DARK }}>
        <WidthControlContainer>
          <h1 className={classes.title}>気づいたことを対話しましょう</h1>
          <p className={classes.description}>
            オバケの対処方法の中で一番共感が多かったのは下記でした。
            <br />
            気づいたこと感じたことを自由に対話しましょう。
          </p>
          <p className={classes.description}>
            {team?.timerS3SelectUserIds?.length}/{team?.timerS3SelectNumber}人目 :
            <span className={classes.presenterName}>{presenter?.name}さん</span>
          </p>

          <div className={classNames([classes.cardsWrapper, { [classes.memberWrapper]: !isLeader }])}>
            <UsefulCard backgroundColor={'white'} ownStyles={{ marginTop: 24 }}>
              <p className={classes.contentName}>今後の対処方法</p>
              <GhostReported
                researches={r2}
                thumbUpIconBehavior={'onlyView'}
                ownStyles={{ padding: isMdUp ? '0 40px 40px' : '16px 16px 24px' }}
                balloonPadding={16}
                isLike
              />
            </UsefulCard>
          </div>

          {isLeader ? (
            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="ghost-rule"
                  buttonBodyColor="green"
                  bodyText="次へ"
                  onClick={onNextPresenter}
                  isResizeBasic
                />,
              ]}
            />
          ) : null}
        </WidthControlContainer>
      </BackResponsiveCard>

      {team && (
        <Timer
          from={team.timerS3SelectStarted ?? new Date().toISOString()}
          time={team.timerS3SelectMinute ? team.timerS3SelectMinute * 60 : 0}
          hasGhostStyle
          ghostWrapBottom={!isLeader ? 54 : undefined}
        />
      )}
    </GrayBackGround>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    description: {
      margin: '24px 0 0',
      fontSize: 14,
      '& span': {
        fontWeight: 'bold',
      },
    },
    presenterName: {
      fontSize: 20,
      paddingLeft: 4,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
    cardsWrapper: {
      margin: '24px 0 86px',
    },
    memberWrapper: {
      marginBottom: 0,
    },
    contentName: {
      margin: 0,
      padding: '40px 40px 24px',
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
  { name: 'Step3Presentation' }
)
