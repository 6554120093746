import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: 1.8,
    textDecoration: 'none',
    color: '#5F5F5F',
  },

  ellipsis: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,

    [theme.breakpoints.down('lg')]: {
      '-webkit-line-clamp': 1,
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
}

// -----------------------------
// Component
// -----------------------------
export const AuthorName = (props: IProps) => {
  const classes = useStyles()

  return <span className={`${classes.root} ${classes.ellipsis}`}>by {props.text}</span>
}
