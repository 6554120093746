import * as React from 'react'

import { Theme, MenuItem, Menu } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Auth } from 'aws-amplify'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { useTeams } from 'pages/onboarding/hooks/team'

import { HooksContext } from '../contexts'

import { HeaderSpContents } from './HeaderSpContents'

import { Pages, useHistory } from 'assets'
import { constants } from 'assets'
import { replacePathParams } from 'assets/history'
import { TO_ONBOARDING_TIMELINE } from 'assets/pages'

interface Props {
  isMdup: boolean
  open: boolean
  anchorEl: null | HTMLElement
  handleClose: (event: React.MouseEvent<HTMLElement>) => void
}
export const HeaderMenu: React.FC<Props> = React.memo(({ isMdup, open, anchorEl, handleClose }) => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'md')

  const toUsagePage = (e: React.MouseEvent<HTMLElement>) => {
    handleClose(e)
    route.push(Pages.TeamsUsageMovie, { teamId: currentTeamMember?.teamId })
  }

  const [onbTeamId, setOnbTeamId] = React.useState<null | string>(null)
  const { teams: onbTeams } = useTeams()

  React.useEffect(() => {
    if (onbTeams.length > 0) {
      setOnbTeamId(onbTeams[0].id)
    }
  }, [onbTeams])

  const handleUseOnb = () => {
    if (onbTeamId) {
      route.push(TO_ONBOARDING_TIMELINE(onbTeamId))
      window.scrollTo(0, 0)
    } else {
      alert('オンボーディングの権限がありません。')
    }
  }

  const handleTeamsOpen = (e: React.MouseEvent<HTMLElement>) => {
    handleClose(e)
    route.push(replacePathParams(Pages.TeamsSelect, { teamId: currentTeam?.id }))
    window.scrollTo(0, 0)
  }

  const { currentTeamMember, currentTeam } = React.useContext(HooksContext)
  const { route } = useHistory()
  const handleProfile = (e: React.MouseEvent<HTMLElement>) => {
    handleClose(e)
    if (currentTeamMember) {
      route.push(Pages.TeamsMembersEdit, { teamId: currentTeamMember.teamId, teamMemberId: currentTeamMember.id })
    }
  }

  const handleSignOut = (e: React.MouseEvent<HTMLElement>) => {
    handleClose(e)
    Auth.signOut()
    window.location.href = '/'
  }

  return (
    <>
      {anchorEl ? (
        <img src={process.env.PUBLIC_URL + '/assets/svg/arrow-up.svg'} alt="arrow" />
      ) : (
        <img src={process.env.PUBLIC_URL + '/assets/svg/arrow-down.svg'} alt="arrow" />
      )}

      <div>
        <Menu
          id="demo-customized-menu"
          autoFocus={false}
          className={classes.menu}
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          disableScrollLock
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: -18,
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleProfile} disableRipple>
            <img
              className={classes.menuIcon}
              src={process.env.PUBLIC_URL + '/assets/svg/account.svg'}
              alt="accont icon"
            />
            アカウント編集
          </MenuItem>
          {isSmDown && (
            <MenuItem onClick={handleTeamsOpen} disableRipple>
              <img
                className={classes.menuIcon}
                src={process.env.PUBLIC_URL + '/assets/svg/cocolabo-min.svg'}
                alt="cocolabo icon"
              />
              <span className={classes.teamName}>{currentTeam?.name}</span>
            </MenuItem>
          )}
          <MenuItem onClick={handleUseOnb} disableRipple>
            <img
              className={classes.menuIcon}
              src={process.env.PUBLIC_URL + '/assets/svg/reverseAllowBlack.svg'}
              alt="double arrow icon"
            />
            オンボーディングに切り替える
          </MenuItem>

          {!isMdup && (
            <div onClick={toUsagePage}>
              <MenuItem disableRipple>
                <img
                  className={classes.menuIcon}
                  src={process.env.PUBLIC_URL + '/assets/svg/movie.svg'}
                  alt="movie icon"
                />
                お役立ち動画
              </MenuItem>
            </div>
          )}
          {!isMdup && <HeaderSpContents />}

          <div className={classes.divider} />
          <MenuItem onClick={handleSignOut} disableRipple>
            <img
              className={classes.menuIcon}
              src={process.env.PUBLIC_URL + '/assets/svg/logout.svg'}
              alt="logout icon"
            />
            ログアウト
          </MenuItem>
        </Menu>
      </div>
    </>
  )
})

const useStyles = makeStyles(
  (theme: Theme) => ({
    divider: {
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    menu: {
      '& .MuiMenuItem-root': {
        height: 44,
        padding: '0 16px',
        fontSize: 12,
        color: constants.TEXT_GRAY_DARK,
      },
      '& .MuiPaper-root': {
        borderRadius: 8,
        boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.16)',
        width: 240,
      },
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    menuIcon: {
      marginRight: 8,
      position: 'relative',
      top: -1,
    },
    teamName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'HeaderMenu' }
)
