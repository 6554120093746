import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Modal, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';



import { MessagePanel } from 'components/MessagePanelNew'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { OnboardingPost } from 'utils/generated'

import { ActionModalState } from '..'
import { ButtonNew as Button } from '../../../../../components/ButtonNew'
import { IActionItem } from '../../../../../stores/setupaction/reducer'
import { OnbContext } from '../../PagesRoot'

import * as constants from 'assets/constants'

type Props = {
  item: IActionItem
  open: boolean
  setModal: (state: ActionModalState) => void
  onCreateFeedback: (value: number) => Promise<void>
  onCreatePost: () => Promise<OnboardingPost>
  tutorialNext?: () => void
}

export const FeedBack: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { teamId } = useContext(OnbContext)
  const { userId: targetUserId } = useParams<{ userId: string }>()
  const { teamMember: targetMemberInfo } = useTeamMember(teamId, targetUserId)

  const [valueAction, setValueAction] = React.useState<number | null>()
  const [hasErrorLevel] = React.useState<boolean>(false)

  const handleOnCreateFeedback = async () => {
    if (valueAction !== null && valueAction !== undefined) {
      await props.onCreateFeedback(valueAction)
      await props.onCreatePost()
      props.tutorialNext ? props.tutorialNext() : props.setModal('complete-feedback')
    }
  }
  const reset = () => {
    props.tutorialNext ? props.tutorialNext() : props.setModal('complete-feedback')
  }
  const isReadTutorialAt = targetMemberInfo?.readTutorialAt

  const handleClose = () => {
    if (props.tutorialNext) return
    props.setModal('idle')
  }

  return (
    <Modal open={props.open} className={classes.modal} disableAutoFocus={true}>
      <Paper className="__modalPaper">
        <MessagePanel
          failure={false}
          handleClose={handleClose}
          body={
            <>
              <div className="__modalPaper__title">このアクションの評価をして下さい。</div>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}
                data-testid="feedback-evals"
              >
                {[
                  { label: 'A', value: 1, text: '良く出来た' },
                  { label: 'B', value: 2, text: '' },
                  { label: 'C', value: 3, text: '普通' },
                  { label: 'D', value: 4, text: '' },
                  { label: 'E', value: 5, text: '難しかった' },
                ].map((item, index) => (
                  <div key={`feedback-${index}`} style={{ marginRight: index !== 4 ? 3 : 0, width: '100%' }}>
                    <div
                      style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        padding: '5px 0',
                        fontFamily: 'Hiragino Sans',
                        fontWeight: 500,
                        border: `1px solid ${
                          valueAction === item.value
                            ? constants.COLOR_ONBOARDING_MAIN
                            : constants.COLOR_ONBOARDING_GRAY_DARK
                        }`,
                        color: `${
                          valueAction === item.value ? constants.TEXT_WHITE : constants.COLOR_ONBOARDING_GRAY_DARK
                        }`,
                        backgroundColor: `${
                          valueAction === item.value ? constants.COLOR_ONBOARDING_MAIN : constants.TEXT_WHITE
                        }`,
                        textAlign: 'center',
                        marginBottom: 18,
                        borderRadius: 4,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setValueAction(item.value)
                      }}
                    >
                      {item.label}
                    </div>
                    {item.text ? (
                      <div
                        style={{
                          textAlign: 'center',
                          fontSize: 11,
                          fontWeight: 'bold',
                          color: constants.COLOR_ONBOARDING_GRAY_DARK,
                        }}
                      >
                        {item.text}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>

              {hasErrorLevel && (
                <small
                  style={{
                    color: 'red',
                    width: '100%',
                    textAlign: 'center',
                    display: 'block',
                  }}
                >
                  難易度を選択してから更新してください。
                </small>
              )}
            </>
          }
          footer={
            <div className="__modalPaper__buttons">
              <Button
                body={'達成済みにする'}
                onClick={isReadTutorialAt ? handleOnCreateFeedback : reset}
                noShadow={true}
              />
              <div
                onClick={handleClose}
                style={{
                  textAlign: 'center',
                  marginTop: 26,
                  cursor: 'pointer',
                  fontSize: 12,
                  fontWeight: 'bold',
                  color: constants.COLOR_ONBOARDING_GRAY_DARK,
                }}
              >
                キャンセル
              </div>
            </div>
          }
        />
      </Paper>
    </Modal>
  )
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,

    '& .__modalPaper': {
      width: '100%',
      maxWidth: 350,
      margin: '0 7px',
      maxHeight: '90vh',
      overflow: 'scroll',
      borderRadius: 8,
      '&:focus': {
        outline: 'none',
      },

      '&__title': {
        color: constants.COLOR_ONBOARDING_MAIN,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 29,
      },

      '&__buttons': {
        '& :first-child': {
          marginRight: '4px',
        },
        '& :last-child': {
          marginLeft: '4px',
        },
      },
    },
  },
}))
