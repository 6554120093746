import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useFormContext } from 'react-hook-form'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { Tooltip } from '../pages/list/components/modules'

import { FormDate } from './FormDate'
import { FormTime } from './FormTime'
import { HorizontalLine } from './HorizontalLine'
import { ToggleSwitch } from './ToggleSwitch'

import { constants } from 'assets'

type Props = {
  defaultValues?: {
    sendEmailDateAt: string
    sendEmailTimeAt: string
  }
  styles?: {
    reserveWrapper?: React.CSSProperties
    headContent?: React.CSSProperties
    reserveInputWrapper?: React.CSSProperties
    inputCalendar?: React.CSSProperties
    inputTime?: React.CSSProperties
  }
  isReserved: boolean
  lineParentPadding?: number
}

export const ReservationInvitationMail: React.FC<Props> = ({
  defaultValues,
  styles,
  isReserved,
  lineParentPadding,
}) => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'sm')
  const isSmDown = useCustomMediaQuery('down', 'md')
  const [isReserveVisible, setIsReserveVisible] = React.useState<boolean>(false)
  const handleReserveOpen = () => {
    setIsReserveVisible(true)
  }
  const handleReserveClose = () => setIsReserveVisible(false)
  const {
    control,
    formState: { errors },
  } = useFormContext()

  React.useEffect(() => {
    setIsReserveVisible(isReserved)
  }, [isReserved])

  return (
    <>
      {isXsDown && <HorizontalLine parentPadding={lineParentPadding} />}
      <div className={classes.reserveWrapper} style={styles?.reserveWrapper}>
        <div className={classes.headContent} style={styles?.headContent}>
          <p className={classes.switchLabel}>予約招待メール</p>
          <Tooltip
            balloonId="tooltip-teams-switch"
            title="予約招待メール"
            body={`メンバーの追加をすると、入力したメールアドレス宛に招待メールが届きます。\n招待メールを送信する日付と時間を指定することができます。`}
            width={isSmDown ? 300 : 350}
            positions={{ top: 30, right: isSmDown ? -142 : -167 }} // top 30 = 20（? icon の高さ） + 10（ballon の矢印の高さ）
          />
          <span className={classes.labelMark}>任意</span>
          <div className={classes.switchWrapper}>
            <ToggleSwitch
              onChange={isReserveVisible ? handleReserveClose : handleReserveOpen}
              checked={isReserveVisible}
              widthlarge={60}
            />
          </div>
        </div>
        {isReserveVisible ? (
          <div className={classes.reserveInputWrapper} style={styles?.reserveInputWrapper}>
            <div className={classes.dateInput}>
              <Controller
                control={control}
                name="sendEmailDateAt"
                defaultValue={defaultValues?.sendEmailDateAt}
                rules={{
                  required: '日付を選択してください',
                  validate: (value) => {
                    if (!isReserveVisible) return true
                    const isInValid = value?.toString() !== 'Invalid Date'
                    const isNotEmpty = value?.toString() !== '' && value !== null
                    return isInValid && isNotEmpty
                  },
                }}
                render={({ name, value, onChange }) => (
                  <FormDate name={name} value={value} onChange={onChange} ownStyles={{ ...styles?.inputCalendar }} />
                )}
              />
              {errors.sendEmailDateAt && (
                <small className={classes.errorMessage}>{errors.sendEmailDateAt.message}</small>
              )}
            </div>

            <div className={classes.timeInput}>
              <Controller
                control={control}
                name="sendEmailTimeAt"
                defaultValue={defaultValues?.sendEmailTimeAt}
                rules={{
                  required: '時間を選択してください',
                }}
                render={({ name, value, onChange }) => (
                  <FormTime name={name} value={value} onChange={onChange} ownStyles={{ ...styles?.inputTime }} />
                )}
              />
              {errors.sendEmailTimeAt && (
                <small className={classes.errorMessage}>{errors.sendEmailTimeAt.message}</small>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {isXsDown && <HorizontalLine parentPadding={lineParentPadding} style={{ marginTop: 24, marginBottom: 24 }} />}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    reserveWrapper: {
      marginBottom: 24,
      [theme.breakpoints.down('lg')]: {
        marginTop: 24,
      },
    },
    headContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      maxWidth: 406,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    switchLabel: {
      margin: 0,
      fontSize: 14,
      fontWeight: 600,
      color: constants.COLOR_TEAMBUILDING_TEXT,
    },
    labelMark: {
      fontSize: 9,
      fontWeight: 600,
      borderRadius: 4,
      padding: '2px 4px',
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      color: constants.COLOR_WHITE,
    },
    switchWrapper: {
      marginLeft: 'auto',
    },
    reserveInputWrapper: {
      display: 'flex',
      gap: 16,
      marginTop: 16,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    dateInput: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minWidth: 150,
      maxWidth: 250,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    timeInput: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 140,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    errorMessage: {
      display: 'block',
      marginTop: 4,
      color: constants.COLOR_TEAMBUILDING_RED,
      fontSize: 10,
    },
  }),
  { name: 'ReservationInvitationMail' }
)
