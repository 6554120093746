import * as React from 'react'

import { Box } from '@mui/material'

import AlertDialog from 'components/AlertDialog'
import Button from 'components/Button'
import { ICreateHintParams } from 'pages/hint/_shared/stores/apis/createHint'

import { IuseDialogPublishProps } from './useDialogPublish'

import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseDialogPublishProps
  params: ICreateHintParams
}

// -----------------------------
// Component
// -----------------------------
export const DialogPublish = (props: IProps) => {
  return (
    <AlertDialog
      open={props.presenter.getOpen()}
      success={true}
      onClose={props.presenter.onClose}
      body={<p>記事を公開しますか？</p>}
      footer={
        <Box display="flex">
          <Box mr="8px" width="100%">
            <Button
              bgColor={constants.COLOR_BLACK}
              textColor={constants.TEXT_WHITE}
              fullWidth={true}
              onClick={props.presenter.onDoNotPublish}
              body={<div>いいえ</div>}
            />
          </Box>
          <Box ml="8px" width="100%">
            <Button
              bgColor={constants.COLOR_SECOND}
              textColor={constants.TEXT_BLACK}
              fullWidth={true}
              onClick={() => props.presenter.onDoPublish(props.params)}
              body={<div>はい</div>}
            />
          </Box>
        </Box>
      }
    />
  )
}
