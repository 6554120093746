import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../../../assets/constants'

interface IOwnProps {
  gender: string
  goodLabel: string
  badLabel: string
  answerGood: string[]
  answerBad: string[]
  compare?: boolean
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  return (
    <div className={`${classes.main} ${props.compare ? 'compare' : ''}`}>
      <div className="memberAnswerContainer">
        <div className="memberAnswerContainer__answerGood">
          <div className="memberAnswerContainer__title">{props.goodLabel}</div>

          {props.answerGood.map((answer, index) => (
            <div key={`answerGood-${index}`} className="memberAnswerContainer__body">
              <span className="good">{index + 1}</span>
              {answer}
            </div>
          ))}
        </div>
        <div className="memberAnswerContainer__img">
          <img src={process.env.PUBLIC_URL + `/assets/svg/karte/${props.gender}.svg`} alt="member" />
        </div>

        <div className="memberAnswerContainer__answerBad">
          <div className="memberAnswerContainer__title">{props.badLabel}</div>
          {props.answerBad.map((answer, index) => (
            <div key={`answerBad-${index}`} className="memberAnswerContainer__body">
              <span className="bad">{index + 1}</span>
              {answer}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      position: 'relative',
      padding: 16,
      borderRadius: 5,
      border: `2px solid ${constants.COLOR_BLUE_LIGHT}`,
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 16,

      '&.compare': {
        backgroundColor: '#DDFDFF',
      },

      '& .memberAnswerContainer': {
        display: 'flex',
        justifyContent: 'space-between',

        '&__img': {
          width: '33%',
          padding: '0 8px',
          alignItems: 'center',
          display: 'flex',
          '& img': {
            width: '100%',
            maxHeight: 250,
          },
        },

        '&__title': {
          borderLeft: `3px solid ${constants.COLOR_BLUE_LIGHT}`,
          paddingLeft: 8,
          marginBottom: 16,
          fontSize: 14,
        },
        '&__body': {
          display: 'flex',
          alignItems: 'center',
          marginBottom: 16,
          width: '100%',

          '& span': {
            width: 18,
            minWidth: 18,
            height: 18,
            minHeight: 18,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            lineHeight: 0,
            marginRight: 8,
            //

            '&.good': {
              background: '#FF5858',
            },
            '&.bad': {
              background: '#2CB6FF',
            },
          },
        },
        '&__answerGood': {
          width: '33%',
          paddingTop: 16,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          //
        },
        '&__answerBad': {
          width: '33%',
          paddingTop: 16,

          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'baseline',
          //
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
