import React, { useContext, useState } from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import Button from 'components/Button'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { ButtonContainer, DropZoneCsv, TemplateDownloadBox, CSVUploadedModal } from 'pages/onboarding/components'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'

import { useCsvUpload } from '../../hooks/csvUpload'
import { OnbContext } from '../PagesRoot'

import * as constants from 'assets/constants'

export const ActionsManageCsvPageBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const [csvData, setCsvData] = useState<string[][]>([])
    const [uploadFile, setUploadFile] = useState<File>()
    const [open, setOpen] = useState(false)
    const isXsDown = useCustomMediaQuery('down', 'sm')
    const { onUploadActions, processing, uploadedNum, uploadErrors } = useCsvUpload(
      teamId,
      teamMember,
      csvData,
      setUploadFile,
      setCsvData,
      setOpen
    )
    const disabled = !csvData.length

    return {
      teamId,
      disabled,
      setCsvData,
      onUploadActions,
      processing,
      uploadedNum,
      uploadErrors,
      uploadFile,
      setUploadFile,
      open,
      setOpen,
      isXsDown,
    }
  },
}

export const ActionsManageCsvPage = () => {
  const classes = useStyles()
  const {
    teamId,
    disabled,
    setCsvData,
    onUploadActions,
    processing,
    uploadedNum,
    uploadErrors,
    uploadFile,
    setUploadFile,
    open,
    setOpen,
    isXsDown,
  } = ActionsManageCsvPageBloc.useAdapter()

  return (
    <>
      <TitleWithBack title={'アクション追加'} omitBack={false} spaceBetween />
      <div className={classes.root}>
        <div className={classes.text}>CSVファイルをアップロードしてください。</div>
        <DropZoneCsv uploadFile={uploadFile} setUploadFile={setUploadFile} setCsvData={setCsvData} />
        <div className={classes.templateContainer}>
          <TemplateDownloadBox template="action" />
        </div>
      </div>
      <div className={classNames([classes.buttonBox, classes.separator, classes.bottomBtnWrapper])}>
        <ButtonContainer
          button={
            <Button
              disabled={disabled || processing}
              bgColor={disabled || processing ? constants.COLOR_ONBOARDING_WHITE : constants.COLOR_WHITE}
              textColor={
                disabled || processing ? constants.COLOR_ONBOARDING_TEXT_GRAY : constants.COLOR_ONBOARDING_MAIN
              }
              borderColor={disabled || processing ? constants.COLOR_GRAY : constants.COLOR_ONBOARDING_MAIN}
              fontSize={16}
              fullWidth={true}
              body={processing ? '処理中...' : '追加する'}
              noShadow={true}
              height={50}
              style={{ borderRadius: 8, width: isXsDown ? '100%' : 318 }}
              onClick={onUploadActions}
            />
          }
        />
      </div>

      <CSVUploadedModal
        open={open}
        setOpen={setOpen}
        importPage="action-add"
        teamId={teamId}
        uploadedCounts={uploadedNum}
        errRows={uploadErrors}
      />
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: 15,
      paddingTop: 20,
    },
    text: {
      fontWeight: 'bold',
      fontSize: '14',
      paddingBottom: 10,
      color: constants.TEXT_GRAY_DARK,
    },
    templateContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    templateText: {
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
      marginLeft: 8,
      fontSize: 12,
    },
    buttonBox: {
      backgroundColor: '#F9F9F9',
      marginBottom: 38,
    },
    separator: {
      borderTop: '1px solid #F5F5F5',
      marginTop: 15,
    },
    bottomBtnWrapper: {
      position: 'static',
    },
  }),
  { name: 'ActionsManageCsvPage' }
)
