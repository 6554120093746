import * as React from 'react'

import { Theme, CircularProgress } from '@mui/material';
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  icon?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: 'submit' | 'reset' | 'button'
  style?: React.CSSProperties
  loading?: boolean
  loadingColor?: string
  disabled?: boolean
  title?: string
  endIcon?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
  titleStyle?: React.CSSProperties
  children?: string | React.ReactNode
  forwardRef?: React.Ref<HTMLButtonElement>
  form?: string
  color?:
    | 'primary'
    | 'darkPrimary'
    | 'secondary'
    | 'black'
    | 'danger'
    | 'cancel'
    | 'team_main'
    | 'team_white'
    | 'team_disabled'
    | 'team_grayCard'
}

export const DefaultButton: React.FC<Props> = (props) => {
  const classes = useStyles({ loading: props.loading, disabled: props.disabled })

  const style = () => {
    switch (props.color) {
      case 'primary':
        return {}

      case 'darkPrimary':
        return {
          color: constants.TEXT_WHITE,
          backgroundColor: constants.COLOR_ONBOARDING_MAIN,
        }
      case 'secondary':
        return {
          color: constants.TEXT_WHITE,
          backgroundColor: constants.COLOR_SECOND,
        }

      case 'black':
        return {
          color: constants.TEXT_WHITE,
          backgroundColor: constants.COLOR_BLACK,
        }

      case 'danger':
        return {
          color: constants.TEXT_WHITE,
          backgroundColor: constants.COLOR_DANGER,
        }

      case 'cancel':
        return {
          color: constants.COLOR_GRAY_DARK,
          backgroundColor: '#fff',
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
          boxShadow: 'none',
        }

      case 'team_main':
        return {
          color: constants.TEXT_WHITE,
          backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
          border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
          boxShadow: 'none',
        }

      case 'team_white':
        return {
          color: constants.COLOR_TEAMBUILDING_PRIMARY,
          backgroundColor: constants.TEXT_WHITE,
          border: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
          boxShadow: 'none',
        }
      case 'team_disabled':
        return {
          color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
          backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
          boxShadow: 'none',
        }
      case 'team_grayCard':
        return {
          color: constants.COLOR_TEAMBUILDING_PRIMARY,
          backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
          border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
          borderRadius: 0,
          boxShadow: 'none',
        }
      default:
        return {}
    }
  }

  return (
    <Button
      variant="contained"
      color={'primary'}
      className={classes.button}
      onClick={props.loading || props.disabled ? undefined : props.onClick}
      type={props.type}
      style={{
        ...style(),
        ...props.style,
      }}
      disabled={props.loading || props.disabled}
      ref={props.forwardRef}
      endIcon={props.endIcon ?? undefined}
      form={props.form}
    >
      {!props.loading ? (
        <>
          {props.icon ? props.icon : <></>}
          <div className={classes.title} style={props.titleStyle}>
            {props.title || props.children}
          </div>
        </>
      ) : (
        <CircularProgress
          style={{
            color: props.loadingColor ?? constants.TEXT_WHITE,
          }}
          size={16}
        />
      )}
    </Button>
  )
}

interface StyleProps {
  loading?: boolean
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  button: (props: StyleProps) => ({
    minWidth: 200,
    padding: '8px 20px',
    fontWeight: 'bold',
    backgroundColor: constants.COLOR_MAIN,
    color: constants.COLOR_WHITE,
    height: 40,
    borderRadius: 42 / 2,
    fontSize: 14,

    opacity: props.disabled || props.loading ? 0.3 : 1,

    '&:hover': {
      opacity: props.loading ? 0.3 : 0.7,
    },
  }),

  title: {
    whiteSpace: 'pre-wrap',
    lineHeight: 1.4,
  },
}))
