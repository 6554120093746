import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { PlayButton } from 'pages/obakesagashi/components/PlayButton'

import { constants } from 'assets'

export const SimilarTypeSample: React.FC = () => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'md')

  const sampleGhosts = {
    number: '7',
    name: '個人プレイヤーオバケ',
    desc: 'このオバケがいることで、支援が得られにくく、また一体感がも感じづらくなるため、部署に対する貢献意欲が下がる可能性があります。',
    labelItems: [
      { text: '似てる業種', label: 'industry' },
      { text: '似てる立場', label: 'position' },
      { text: '似てる人', label: 'person' },
    ],
  }
  return (
    <section className={classes.section}>
      <WidthControlWrapper>
        <img
          className={classes.sideGhost}
          src={`${process.env.PUBLIC_URL}/img/obakesagashi/side-ghost.svg`}
          alt="右にいる白いオバケ"
        />
        <div className={classes.titleArea}>
          <div className={classes.more}>さらに</div>
          <h2 className={classNames(classes.title)}>
            あなたに似た環境や状況で{isSmDown && <br />}よく見つかる、
            <br />
            オバケも知ることができます。
          </h2>
        </div>
        <p className={classes.titleSub}>
          オバケはチームの関係性や環境によって、出やすいオバケと出にくいオバケがあったりします。また、世代や性別、立場によっても、見えるオバケが異なる場合があります。あなたによく似た環境や状況にある人によく見えるオバケを知っておくことで、これからの職場づくりに活かせることがあるかもしれません。
        </p>

        {sampleGhosts.labelItems.map((item) => (
          <div key={item.text} className={classes.topGhostBox}>
            <div className={classes.topGhostImgBox}>
              <div className={classNames(classes.number1, item.label)}>
                あなたに
                <br />
                {item.text}
                <br />
                <span className="no1">No.1</span>
                <img className="crown" src={`${process.env.PUBLIC_URL}/img/obakesagashi/crown.svg`} alt="王冠" />
              </div>

              <img
                className={classes.ghostImg}
                src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${sampleGhosts.number}.svg`}
                alt={sampleGhosts.name}
              />
            </div>

            <div>
              <div>
                <span className={classes.fileNo}>FILE NO.{sampleGhosts.number}</span>
                <p className={classes.ghostName}>{sampleGhosts.name}</p>
                <p className={classes.ghostCon}>{sampleGhosts.desc}</p>
              </div>
            </div>
          </div>
        ))}
      </WidthControlWrapper>
      <PlayButton backColor="secondary" className="buttomButton" />
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    section: {
      position: 'relative',
      padding: '0 0 122px',
      color: '#000',
      [theme.breakpoints.down('md')]: {
        padding: '0 0 200px',
      },
    },
    titleArea: {
      position: 'relative',
      display: 'flex',
      alignItems: 'flex-end',
      margin: '100px 0 0',
      [theme.breakpoints.down('md')]: {
        margin: '80px 0 0',
        gap: 24,
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    more: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      left: -14,
      width: 131,
      height: 131,
      background: '#FCCF00',
      borderRadius: '50%',
      fontSize: 40,
      fontWeight: 'bold',
      fontFamily: '"MOBO SemiBold"',
      letterSpacing: -4,
      [theme.breakpoints.down('md')]: {
        left: 0,
        fontSize: 32,
        letterSpacing: -3.2,
      },
    },
    sideGhost: {
      position: 'absolute',
      right: 0,
      transform: 'scale(-1, 1)',
      width: 151,
      [theme.breakpoints.down('md')]: {
        top: 80,
        width: 78,
      },
    },
    title: {
      margin: '0',
      textAlign: 'center',
      fontFamily: '"MOBO SemiBold"',
      fontSize: 40,
      fontWeight: 600,
      lineHeight: '48px',
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        lineHeight: '32px',
        textAlign: 'center',
      },
      '&.topShort': {
        margin: '107px 0 0',
        [theme.breakpoints.down('md')]: {
          margin: '39px 0 0',
        },
      },
    },
    titleSub: {
      margin: '60px 0 68px',
      fontSize: 16,
      lineHeight: '32px',
      [theme.breakpoints.down('md')]: {
        margin: '40px 0 40px',
      },
    },
    topGhostBox: {
      display: 'flex',
      columnGap: 24,
      marginTop: 24,
      padding: '32px 24px 24px',
      background: '#fff',
      borderRadius: 16,
      boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',

      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 24,
        alignItems: 'center',
        marginTop: 16,
        padding: '24px 16px',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
      },
    },
    topGhostImgBox: {
      display: 'flex',
      columnGap: 24,
      [theme.breakpoints.down('md')]: {
        columnGap: 16,
      },
    },
    number1: {
      position: 'relative',
      flexShrink: 0,
      width: 147,
      height: 147,
      paddingTop: 32,
      borderRadius: '50%',
      color: '#fff',
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      lineHeight: '20px',
      letterSpacing: 1.6,
      '&.industry': {
        background: '#E07682',
      },
      '&.position': {
        background: '#7BB7C4',
      },
      '&.person': {
        background: '#98AC3E',
      },
      '& .no1': {
        display: 'block',
        marginTop: 16,
        textAlign: 'center',
        fontFamily: "'din-2014', sans-serif",
        fontSize: 40,
        fontWeight: 400,
        letterSpacing: 0.8,
      },
      '& .crown': {
        position: 'absolute',
        top: -10,
        left: 0,
        width: 43,
      },
    },
    ghostImg: {
      width: 147,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    fileNo: {
      color: constants.COLOR_GRAY_DARK,
      fontFamily: "'din-2014', sans-serif",
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '16px',
    },
    ghostName: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '22px',
    },
    ghostCon: {
      margin: '24px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        margin: '16px 0 0',
        lineHeight: '22px',
      },
    },
  }),
  { name: 'SimilarTypeSample' }
)
