import * as React from 'react'

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { requierdMarkStyles, selectArrowIconStyles } from './documents.data'

import * as constants from 'assets/constants'

type Props = {
  formId: string
}

export const BREAKPOINT = 1080 - 200
export const HubSpotForm: React.FC<Props> = ({ formId }) => {
  const classes = useStyles()
  const PORTAL_ID = process.env.REACT_APP_HUBSPOT_POST_ID
    ? JSON.parse(process.env.REACT_APP_HUBSPOT_POST_ID).toString()
    : '00000000'
  useHubspotForm({
    portalId: PORTAL_ID,
    formId: formId,
    target: '#my-hubspot-form',
  })

  return (
    <div>
      {process.env.REACT_APP_HUBSPOT_POST_ID ? (
        <div id="my-hubspot-form" className={classes.hubSpotFormRoot}></div>
      ) : (
        <div style={{ textAlign: 'center', width: '100%', margin: '54px 0 0', fontSize: 16 }}>
          ページを再読み込みしてください
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  hubSpotFormRoot: {
    marginTop: 16,
    '& .hs-form-required': {
      display: 'none',
    },
    '& .hs-form ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    '& fieldset': {
      width: '100%',
    },
    '& .form-columns-2': {
      width: '100% ',

      [theme.breakpoints.up(400)]: {
        width: '100% ',
        display: 'flex',
      },
      [theme.breakpoints.up(BREAKPOINT)]: {
        display: 'flex',
      },
    },
    '& .form-columns-1': {
      width: '100% ',
    },

    '& .field': {
      maxWidth: BREAKPOINT,
      margin: '32px -10px 0 0',
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: '100%',
      },
    },
    '& label': {
      fontSize: 14,
      color: constants.TEXT_GRAY_DARK,
      position: 'relative',
    },
    '& label[id^="label-"]': {
      fontWeight: 'bold',
    },
    '& label[id^="label-"]::after': {
      ...requierdMarkStyles,
      position: 'absolute',
      top: 1,
      right: -32,
    },
    '& .hs-error-msg': {
      display: 'none',
    },
    '& .input': {
      margin: '16px 0 0',
    },
    '& .hs-form-903ea780-b10e-4d1b-84ef-4eb0b25d0e00_8fe904d6-dc8f-48a2-9e72-ca8b5e1937df': {
      margin: '16px 0 0',
    },
    '& .hs-input': {
      width: '100% !important',
      padding: 16,
      border: `1px solid ${constants.BORDER_LIGHTGRAY}`,
      borderRadius: 8,
      fontSize: 14,
      '&:focus-visible': {
        outlineColor: constants.COLOR_MAIN_NEW,
      },
    },
    '& .hs_jobtitle__c>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },

    '& .hs_lastname.field': {
      width: 'calc(50% - 12px)',
      margin: '32px 12px 0 0',
      [theme.breakpoints.down(400)]: {
        width: 'calc(100% + 10px) !important',
      },
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: 'calc(50% - 12px)',
        margin: '32px 12px 0 0',
      },
    },
    '& .hs_firstname.field': {
      width: 'calc(49% + 1px) ',
      [theme.breakpoints.down(400)]: {
        width: 'calc(100% + 10px) !important',
      },
      [theme.breakpoints.up(BREAKPOINT)]: {
        width: 'calc(50% - 10px)',
        margin: '32px 0 0 12px',
      },
    },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },

    '& .hs-form-radio-display': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&>span': {
        fontWeight: 'normal',
      },
    },
    '& select': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: ' none',
    },
    '& .hs_numemployees>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },
    '& .hs-input[id^="cocolabo_examination_situation"]': {
      width: 'auto',
      margin: '0 8px 0 0',
    },
    '& .hs-form-booleancheckbox-display': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&>span': {
        fontWeight: 'normal',
      },
      '& a': {
        color: constants.COLOR_MAIN_NEW,
      },
      '& .input': {
        width: 'auto !important',
      },
    },
    '& .hs-input[id^="agree_personal_information"]': {
      width: 'auto !important',
      margin: '0 8px 0 0',
    },
    '& .hs_background>.input': {
      position: 'relative',
      '&::after': {
        ...selectArrowIconStyles,
      },
    },
    '& .grecaptcha-badge': {
      margin: 0,
      display: 'none',
    },
    '& li': {
      position: 'relative',
    },
    '& #cocolabo_inquiry_items0-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items1-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items2-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items3-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items4-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items5-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },
    '& #cocolabo_inquiry_items6-903ea780-b10e-4d1b-84ef-4eb0b25d0e00': {
      width: 'auto !important',
    },

    /// エラーメッセージ共通
    '& .hs-error-msgs label': {
      color: constants.COLOR_ALERT,
      marginRight: 'auto',
    },

    /// 会社/組織名のエラーメッセージ
    '& .hs_company .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// 姓のエラーメッセージ
    '& .hs_lastname .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
      marginBottom: 20,
    },

    /// 名のエラーメッセージ
    '& .hs_firstname .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// メールアドレスのエラーメッセージ
    '& .hs_email .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 電話番号のエラーメッセージ
    '& .hs_phone .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 従業員数のエラーメッセージ
    '& .hs_numemployees .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    // お問い合わせ項目のエラーメッセージ
    '& .hs_cocolabo_inquiry_items .hs-error-msg': {
      position: 'absolute',
      top: 10,
      left: 0,
    },

    // お問い合わせ項目のエラーメッセージ
    '& .hs_message .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
      left: 0,
    },

    /// 個人情報の取り扱いのエラーメッセージ
    '& .hs_agree_personal_information .hs-error-msgs label': {
      position: 'absolute',
      top: 5,
    },

    //「全ての必須項目に入力してください」を非表示にする
    '& .hs_error_rollup label': {
      display: 'none',
    },

    // 個人情報リンクを太字にする
    '& .hs_agree_personal_information a': {
      fontWeight: 'bold !important',
    },

    '& .hs-main-font-element': {
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 'calc(50% - 120px)',
    },

    '& .hs_submit': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down(BREAKPOINT)]: {
        marginTop: 48,
      },
    },

    '& .actions': {
      width: '100%',
      maxWidth: 252,
    },
    '& .hs-button': {
      display: 'block',
      width: '100%',
      height: 50,
      margin: '0 auto',
      border: `2px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      borderRadius: 25,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      boxShadow: '0 3px 6px 0 #0000001E',
      '&:hover': {
        cursor: 'pointer',
        animation: '$hoverAnime 300ms cubic-bezier(0,0.5,0.5,1) 0s 1 normal forwards running',
        '@global': {
          '@keyframes hoverAnime': {
            '0%': {},
            '100%': {
              color: constants.COLOR_ONBOARDING_MAIN,
              backgroundColor: constants.COLOR_WHITE,
            },
          },
        },
      },
    },
  },
}))
