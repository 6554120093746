import React, { useMemo } from 'react'
import { useParams, useLocation, useHistory as useHis } from 'react-router-dom'

import { useTheme, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { SlideArrow } from 'pages/onboarding/components'
import { IconButtonWithLabel, Card, LoadingCircular, IntersectLoading } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useTeams } from 'pages/teams/hooks/teams'
import { firstTab } from 'pages/teams/pages/dashboard/components/tabs/Tabs'
import { scrollElements } from 'pages/teams/utils/scrollElements'
import { Team, TeamStatusAdmin, TeamStatusUsage, TeamTool } from 'utils/generated'

import {
  Filter,
  FilterProps,
  FilterType,
  StatusDialog,
  StatusErrorDialog,
  StatusManagement,
  TeamList,
  ToolUsageList,
} from './components'
import { useSortTeams } from './components/hooks/sortTeams'
import { GraphView } from './components/modules/GraphView'

import arrowLeftSvg from './assets/arrow-left.svg'
import { useHistory, Pages, constants } from 'assets'

export const fromParamName = 'from'

export type IndexedTeam = Team & {
  num: number
}

export const TeamsListPage: React.FC = () => {
  const classes = useStyles()
  const { route } = useHistory()
  const history = useHis()
  const { currentUser, isAdmin, isLeader } = React.useContext(HooksContext)
  const { viewTarget, teamId } = useParams<{ viewTarget?: FilterType; teamId?: string }>() // FilterProps
  const theme = useTheme()
  const isMdScreenUp = useMediaQuery(theme.breakpoints.up('md'))
  const { search } = useLocation()
  const { watch, register, setValue } = useForm()

  const searchParams = new URLSearchParams(search)
  const hiddenParam = searchParams.get('hidden')
  const fromParam = searchParams.get(fromParamName)

  const isHidden = Boolean(hiddenParam === 'true' || hiddenParam === 'false' ? JSON.parse(hiddenParam) : hiddenParam) // JSON.parse() converts string to boolean

  const [filter, setFilter] = React.useState<FilterProps>({
    isNonUse: false,
    type: viewTarget ?? 'GENERAL',
    selectedTeamId: teamId,
    isHidden,
  })

  const [statusChangeList, setStatusChangeList] = React.useState(false)
  const [isListUpload, setIsListUpload] = React.useState(false)
  const [errors, setErrors] = React.useState<string[] | null>(null)
  const [isShowDialog, setIsShowDialog] = React.useState(false)
  const [isShowError, setIsShowError] = React.useState(false)
  const { teams, nextToken, loading, more, isMoreLoading, refresh } = useTeams({ isSortByCreatedAt: true })
  const { sortKey, setSortKey, sortTeams } = useSortTeams()

  const errorTeamNamesById = (teams: Team[], errorsTeamIds: string[]) => {
    return teams.reduce((acc: string[], team) => {
      if (errorsTeamIds.includes(team.id)) {
        acc.push(team.name)
      }
      return acc
    }, [])
  }
  const teamNames = errors ? errorTeamNamesById(teams, errors) : []

  React.useEffect(() => {
    setFilter({
      isNonUse: false,
      type: viewTarget ?? 'GENERAL',
      selectedTeamId: teamId,
      isHidden: isHidden,
    })
  }, [teamId, viewTarget, isHidden])

  React.useEffect(() => {
    document.getElementById('scroll-container')?.scroll({ top: 0, behavior: 'smooth' })
  }, [filter])

  const selectedTeam = React.useMemo(() => teams.find((t) => t.id === filter.selectedTeamId), [filter, teams])

  const filteredTeams: IndexedTeam[] = useMemo(() => {
    if (!teams) return []

    const displayTeams = teams.filter((t) =>
      filter.isNonUse ? t.statusUsage === TeamStatusUsage.NonUse : t.statusUsage === TeamStatusUsage.InUse
    )

    const addIndexTeams = displayTeams.map((team, index) => ({
      ...team,
      num: index + 1,
    }))

    return addIndexTeams?.filter((t) => {
      if (!filter.isNonUse) {
        return t.statusUsage === TeamStatusUsage.InUse
      } else {
        return true
      }
    })
  }, [teams, filter])

  const sortedTeams = sortTeams(filteredTeams)
  const watchedTeamIds: string[] | string | boolean = watch('teamIds', [])

  React.useEffect(() => {
    if (isListUpload) {
      refresh()
      setIsListUpload(false)
      setIsShowDialog(true)
      if (errors) {
        setIsShowError(true)
      }
    }
  }, [refresh, isListUpload, errors])

  const props = {
    filter,
    setFilter,
    sortKey,
    setSortKey,
    nextToken,
    more,
    isMoreLoading,
    statusChangeList,
    register,
    setValue,
    watchedTeamIds,
    setIsListUpload,
    setErrors,
  }

  const handleGraphClose = () => {
    if (fromParam === 'tb') {
      history.push({
        pathname: Pages.TeamsDashboard.replace(':teamId', teamId ?? ''),
        state: { [firstTab]: TeamTool.BuildingCheck },
      })
      return
    }
    setFilter({ ...filter, selectedTeamId: undefined, isHidden: true })
    searchParams.set('hidden', 'true')
    history.replace({ search: searchParams.toString() })
  }

  const handleStatusChangeClick = () => {
    setStatusChangeList(true)
  }
  const handleBackToList = () => {
    setStatusChangeList(false)
  }

  // react-hook-form の仕様により、チェックボックスの数によって型が変わる。
  //  1. チェックボックスが1つの場合
  //     checked: string
  //     unchecked: boolean (false)　※初期値のみ空配列
  //  2. チェックボックスが2つ以上の場合
  //     checked: string[]
  //     unchecked: string[] (空配列)
  const selectedCount = () => {
    if (Array.isArray(watchedTeamIds)) {
      return watchedTeamIds.length
    }
    if (typeof watchedTeamIds === 'string') {
      return 1
    }
    if (typeof watchedTeamIds === 'boolean') {
      return watchedTeamIds ? 1 : 0
    }
    return 0
  }

  const hasWatchedTeamIds =
    (typeof watchedTeamIds === 'string' || Array.isArray(watchedTeamIds)) && watchedTeamIds.length > 0

  return !selectedTeam || filter.isHidden ? (
    <>
      {isShowDialog && <StatusDialog setIsShowDialog={setIsShowDialog} />}
      {isShowError && (
        <StatusErrorDialog isShowError={isShowError} setIsShowError={setIsShowError} teamNames={teamNames} />
      )}
      {!statusChangeList && (isAdmin || isLeader) && <ToolUsageList />}
      {statusChangeList && (
        <NoStyleButton className={classes.button} type="button" onClick={handleBackToList}>
          <img src={arrowLeftSvg} alt="arrow icon" />
          リストに戻る
        </NoStyleButton>
      )}
      <Card loading={false} ownStyles={{ marginBottom: '16px', padding: isMdScreenUp ? '24px' : 0 }}>
        <div className={`${classes.cardHeader} ${statusChangeList ? classes.cardHeaderStatusPage : ''}`}>
          <div className={classes.cardHeaderTitle}>{statusChangeList ? 'ステータス一括管理' : 'チームリスト'}</div>
          {statusChangeList && (
            <StatusManagement
              hasWatchedTeamIds={hasWatchedTeamIds}
              selectCount={selectedCount()}
              watchedTeamIds={watchedTeamIds}
              setStatusChangeList={setStatusChangeList}
              setIsListUpload={setIsListUpload}
              setErrors={setErrors}
            />
          )}
          <div className={classes.actionButtonBox}>
            {!statusChangeList && currentUser?.statusAdmin === TeamStatusAdmin.Active && (
              <div className={classes.buttonsWrapper}>
                <IconButtonWithLabel
                  label="ステータス一括変更"
                  icon="rotateIcon"
                  onClick={handleStatusChangeClick}
                  styles={
                    isMdScreenUp
                      ? { minWidth: 172, fontSize: 12, padding: '8px 16px' }
                      : { padding: '8px 10px', minWidth: 0, fontSize: 10, flex: 1, justifyContent: 'flex-start' }
                  }
                />
                <IconButtonWithLabel
                  label="チーム追加"
                  icon="humanIcon2"
                  onClick={() => route.push(Pages.TeamsNew, { teamId })}
                  styles={
                    isMdScreenUp
                      ? { minWidth: 124, fontSize: 12, padding: '8px 16px' }
                      : { padding: '8px 10px', minWidth: 0, fontSize: 10, flex: 1, justifyContent: 'flex-start' }
                  }
                />
                <IconButtonWithLabel
                  label="チームCSV一括追加"
                  icon="FilePdf"
                  onClick={() => route.push(Pages.TeamsNewByCsv, { teamId })}
                  styles={
                    isMdScreenUp
                      ? { minWidth: 175, fontSize: 12, padding: '8px 16px' }
                      : { padding: '8px 10px', minWidth: 0, fontSize: 10, flex: 1, justifyContent: 'flex-start' }
                  }
                />
              </div>
            )}
            {!loading && (
              <div className={classes.filterSlideWrapper}>
                <Filter filter={filter} setFilter={setFilter} statusChangeList={statusChangeList} />
                <SlideArrow
                  visible={isMdScreenUp}
                  windowId={filter.type === 'GENERAL' ? scrollElements.generalList : scrollElements.graphList}
                  isLoading={loading}
                  ownStyles={{ position: 'static', bottom: 0 }}
                />
              </div>
            )}
          </div>
        </div>

        {loading ? <LoadingCircular loading={loading} size={32} /> : <TeamList teams={sortedTeams ?? []} {...props} />}
      </Card>
      <IntersectLoading IntersectItem={{ isLoading: loading, isMoreLoading, nextToken, more }} />
    </>
  ) : (
    <GraphView team={selectedTeam} onClose={handleGraphClose} />
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '16px',
      marginBottom: '24px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        marginBottom: 0,
        padding: '16px 16px 0',
      },
    },
    cardHeaderStatusPage: {
      [theme.breakpoints.down('lg')]: {
        flexWrap: 'wrap',
      },
    },
    cardHeaderTitle: {
      display: 'flex',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#333',
      whiteSpace: 'nowrap',
    },
    cardHeaderMenu: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    actionButtonBox: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'right',
        gap: '16px',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    buttonsWrapper: {
      display: 'flex',
      gap: 8,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: 16,
      },
    },
    filterSlideWrapper: {
      display: 'flex',
      gap: '16px',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
        width: `calc(100% + 32px)`,
        marginLeft: -16,
        padding: 16,
        borderTop: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 3,
      '& img': {
        marginRight: 4,
      },
    },
  }),
  { name: 'TeamsListPage' }
)
