import React, { useContext, useState, useRef, useEffect } from 'react'

import { useMediaQuery, useTheme } from '@mui/material';
import { ClassNameMap } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles';

import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useIntersection } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPost, OnboardingPostType } from 'utils/generated'

import { WhiteCard, HandleDetailOpenText } from '../../../components/atoms'
import { OnbContext } from '../../PagesRoot'

import ListItem from './timelineList'

import { OnbPages } from 'assets/pages'

type Props = {
  posts: OnboardingPost[]
  userId: string
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const PersonalTimelineBox: React.FC<Props & StyleProps> = ({ posts, ownStyles, userId }) => {
  const classes = useStyles({ ownStyles: ownStyles })
  //  handleDetail
  const [visiblePersonalTimelineDetail, setVisiblePersonalTimelineDetail] = useState<boolean>(true)

  return (
    <>
      <WhiteCard ownStyles={{ ...ownStyles }}>
        <div className={classes.root}>
          <div className={classes.title}>タイムライン</div>
          <HandleDetailOpenText open={visiblePersonalTimelineDetail} setOpen={setVisiblePersonalTimelineDetail} />
        </div>
        {visiblePersonalTimelineDetail ? <DetailBox posts={posts} userId={userId} classes={classes} /> : <></>}
      </WhiteCard>
    </>
  )
}

type PersonalTimelineProps = {
  posts: OnboardingPost[]
  classes: ClassNameMap
  userId: string
}

const DetailBox: React.FC<PersonalTimelineProps> = ({ posts, classes, userId }) => {
  //handle reload for pagination
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [postCount, setPostCount] = useState<number>(10)
  const el = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>
  const { teamId } = useContext(OnbContext)
  const { intersecting } = useIntersection(el)
  useEffect(() => {
    if (intersecting) {
      setPostCount((prev) => (isBreakpointsUp ? prev + 10 : prev + 5))
    }
  }, [isBreakpointsUp, intersecting])

  const { teamMembers } = useTeamMembers(teamId)
  const history = useOnbHistory()

  const sortedPost = posts.sort(function (a, b: OnboardingPost) {
    /* eslint-disable */
    if (a.createdAt! < b.createdAt!) return 1
    if (a.createdAt! > b.createdAt!) return -1
    /* eslint-enable */
    return 0
  })

  return (
    <div className={classes.list}>
      {sortedPost.map((item, index) => {
        if (index >= postCount) return
        if (!item.teamMember) return
        if (!!item.comment && !!item.actionId) return
        if (item.type === OnboardingPostType.Ranking) return
        return (
          <React.Fragment key={item.id}>
            <ListItem
              key={item.id}
              post={item}
              postUser={item.teamMember}
              teamMembers={teamMembers}
              onComment={() => {
                history.push(OnbPages.TimelinePost, { teamId, postId: item.id })
              }}
              onAvatar={() => {
                history.push(OnbPages.TeamMember, { teamId, userId })
              }}
            />
          </React.Fragment>
        )
      })}

      <div style={{ paddingTop: 50 }} ref={el} />
    </div>
  )
}

const useStyles = makeStyles(
  {
    dailyCheckBoxRoot: ({ ownStyles }: StyleProps) => ({
      ...ownStyles,
    }),
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    detailBoxRoot: {},
    title: {
      fontWeight: 'bold',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  { name: 'DetailBox' }
)
