import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'
import { ButtonToDiagnose } from 'pages/teams/pages/_tools/karte/components/ButtonToDiagnose'
import { ButtonToPlans } from 'pages/teams/pages/_tools/karte/components/ButtonToPlans'

export const DiagnoseButtons: React.FC = () => {
  const classes = useStyles()
  const { isLeader } = React.useContext(TeamsHooksContext)

  return (
    <div className={classes.root}>
      {isLeader && <ButtonToPlans />}
      <ButtonToDiagnose />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      columnGap: 16,
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'center',
      },
    },
  }),
  { name: 'DiagnoseButtons' }
)
