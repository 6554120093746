import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCookies } from 'react-cookie'

import SpeechBubble from 'pages/karte/components/SpeechBubble'
import { teamCookieKeys } from 'pages/karte/member/static'

import { Button } from '../../components/Button'
import { Phase, TeamToolsKarteDiagnoseContext } from '../../contexts/TeamToolsKarteDiagnoseContext'

import { constants } from 'assets'

export const Prepare: React.FC = () => {
  const classes = useStyles()

  const [, , removeCookie] = useCookies([teamCookieKeys.phase, teamCookieKeys.data])
  const { setPhase } = React.useContext(TeamToolsKarteDiagnoseContext)

  const handleStart = () => {
    removeCookie(teamCookieKeys.phase)
    removeCookie(teamCookieKeys.data)
    setPhase(Phase.Q1)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__header">
            <div style={{ width: '100%', textAlign: 'center' }}>チームカルテ診察室</div>
          </div>
          <div className="__container__content">
            <div className="__container__content__description">
              <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
              <div>
                <SpeechBubble>こちらはチームカルテ診察室です。</SpeechBubble>
                <SpeechBubble>下記注意事項をよく確認した上で​、 診断をスタートしてください。</SpeechBubble>
              </div>
            </div>

            <div className="__container__content__cautions">
              <div className="__container__content__cautions__title">注意事項</div>
              <p>所要時間は約3分です。</p>
              <p>あまり1問毎に時間をかけず回答していきましょう。</p>
              <div className="__container__content__cautions__hr" />
              <p>回答はチームで共有されます。</p>
              <p>率直に今の気持ちでお答えください。​</p>
            </div>

            <div className="__container__content__buttons">
              <Button
                buttonType="secondary"
                style={{
                  height: 48,
                  borderRadius: 48,
                  marginBottom: 16,
                }}
                onClick={handleStart}
              >
                診断スタート
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
    // backgroundColor: '#F5F5F5',

    '& .__container': {
      margin: '0 auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_MEDIUM,
      },

      '&__header': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px 8px',
        alignItems: 'center',
        marginTop: 8,

        '&__button': {
          fontWeight: 'bold',
          '& i': {
            marginRight: 4,
          },
        },
      },

      '&__content': {
        backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT,
        borderTopRightRadius: '30px',
        borderTopLeftRadius: '30px',
        padding: '16px 16px 32px',
        boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

        '&__description': {
          display: 'flex',
          padding: '16px 16px 8px',

          '& img': {
            width: 50,
            height: 50,
            marginRight: 16,
          },
        },

        '&__cautions': {
          padding: 16,
          background: '#fff',
          borderRadius: 5,
          marginBottom: 24,

          '& p': {
            marginTop: 8,
            marginBottom: 8,
          },

          '&__title': {
            color: constants.COLOR_MAIN,
            fontSize: 20,
            fontWeight: 'bold',
          },

          '&__hr': {
            borderBottom: `1px dotted ${constants.COLOR_MAIN}`,
          },
        },

        '&__buttons': {
          textAlign: 'center',
          '& button': {
            height: 50,
            borderRadius: 50,
            fontSize: 16,
          },
        },
      },
    },
  },
}))
