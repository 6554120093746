import * as React from 'react'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  fontSize: number
  bold?: boolean
  color?: string
  lineHeight?: number
  textAlign?: 'left' | 'center' | 'right'
  onClick?: () => void
  ownStyles?: React.CSSProperties
  children: React.ReactNode
  testId?: string
}

export const Font: React.FC<Props> = ({
  fontSize,
  bold = false,
  color = constGhost.COLOR_BLACK3,
  lineHeight = 1.6,
  textAlign = 'left',
  onClick,
  ownStyles,
  children,
  testId,
}) => {
  const classes = useStyles({
    fontSize: fontSize,
    bold: bold,
    color: color,
    lineHeight: lineHeight,
    textAlign: textAlign,
    hover: onClick ? 'pointer' : 'initial',
  })

  return (
    <div onClick={onClick} className={classes.fontRoot} style={{ ...ownStyles }} data-testid={testId}>
      {children}
    </div>
  )
}

type Styles = {
  fontSize: number
  bold?: boolean
  color?: string
  lineHeight?: number
  textAlign?: 'left' | 'center' | 'right'
  hover: 'pointer' | 'initial'
}

const useStyles = makeStyles(
  () => ({
    fontRoot: (props: Styles) => ({
      fontSize: props.fontSize,
      fontWeight: props.bold ? 'bold' : 300,
      color: props.color,
      lineHeight: props.lineHeight,
      textAlign: props.textAlign || 'right',
      cursor: props.hover,
    }),
  }),
  { name: 'Font' }
)

type P = { children: React.ReactNode; ownStyles?: React.CSSProperties }
export const PageTitle: React.FC<P> = ({ children, ownStyles }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Font
      fontSize={16}
      bold
      textAlign={isSmDown ? 'center' : 'left'}
      color={constGhost.COLOR_MAIN_NEW}
      ownStyles={{ letterSpacing: 0.8, ...ownStyles }}
    >
      {children}
    </Font>
  )
}

export const LeadText: React.FC<P> = ({ children, ownStyles }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Font
      fontSize={14}
      color={constGhost.COLOR_BLACK3}
      ownStyles={{
        letterSpacing: 0.1,
        whiteSpace: 'pre-line',
        marginTop: 24,
        marginBottom: isSmDown ? 6 : 0,
        ...ownStyles,
      }}
    >
      {children}
    </Font>
  )
}
