import dayjs from 'dayjs'
import iconv from 'iconv-lite'

import { TeamAnketBuildingDataForExport } from 'utils/generated'

export const exportCSVTeamAnketBuilding = async (inputDataList: TeamAnketBuildingDataForExport[]) => {
  // CSV への出力データを作成
  const outputCsv = []
  outputCsv.push(
    [
      'チーム名',
      '開催回数',
      '開催日時',
      '総合評価',
      '回答率 (%)',
      '氏名',
      '回答日',
      'q1',
      'q2',
      'q3',
      'q4',
      'q5',
      'q6',
      '平均',
      'フリーコメント',
    ].join(',')
  )
  for (const data of inputDataList) {
    // TBチェックが一度も開催されていない場合は、未開催として出力
    if (!data?.anketDataList || data.anketDataList.length === 0) {
      outputCsv.push([escapeCsvValue(data.teamName), '未開催'].join(','))
      continue
    }

    for (const [index, anketData] of data.anketDataList.entries()) {
      // アンケート回答がない場合は、回答なしとして出力
      if (!anketData?.anketEntries || anketData.anketEntries.length === 0) {
        outputCsv.push(
          [
            `${index === 0 ? escapeCsvValue(data.teamName) : ''}`,
            `${data.anketDataList.length - index}回目`,
            '回答なし',
          ].join(',')
        )
        continue
      }

      outputCsv.push(
        [
          `${index === 0 ? escapeCsvValue(data.teamName) : ''}`,
          `${data.anketDataList.length - index}回目`,
          anketData.anketSummary.date,
          anketData.anketSummary?.average ? anketData.anketSummary.average.toFixed(1) : '',
          anketData.anketSummary?.answerRate ? (anketData.anketSummary.answerRate * 100).toFixed(1) : '',
          '', // 氏名
          '', // 回答日
        ].join(',')
      )

      for (const anket of anketData.anketEntries) {
        const answerDate = anket.createdAt ? dayjs(anket.createdAt).format('YYYY/MM/DD') : ''
        const average = anket.average ? anket.average.toFixed(1) : ''
        outputCsv.push(
          [
            '', // チーム名
            '', // 開催回数
            '', // 開催日時
            '', // 総合評価
            '', // 回答率
            escapeCsvValue(anket.userFullName),
            answerDate,
            anket.q1,
            anket.q2,
            anket.q3,
            anket.q4,
            anket.q5,
            anket.q6,
            average,
            escapeCsvValue(anket.freeComment?.[0]?.message ?? ''),
          ].join(',')
        )
      }
    }
  }

  // Shift-JIS 形式で CSV としてエンコード
  const csvBuffer = iconv.encode(outputCsv.join('\n'), 'Shift_JIS')
  const filename = `tbcheck-${dayjs().format('YYYYMMDD_HHmmss')}.csv`

  // CSVファイルをダウンロード
  downloadCSVFile(csvBuffer, filename)
}

// CSV ファイルをダウンロードする関数
const downloadCSVFile = (buffer: Buffer, filename: string) => {
  const blob = new Blob([buffer], { type: 'text/csv; charset=shift_jis' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', filename)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 改行コードやダブルクオーテーション、カンマが含まれていても
// 1セルとして認識させるためにエスケープする関数
const escapeCsvValue = (value: string): string => {
  return `"${value.replace(/"/g, '""')}"`;
}
