import React from 'react'

import MuiProgress from '@mui/material/CircularProgress'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type CircularProgressNoWrapperProps = {
  size?: number
} & StyleProps

type StyleProps = {
  color?: string
  margin?: string | number
}

export const CircularProgressNoWrapper: React.FC<CircularProgressNoWrapperProps> = ({ size, color, margin }) => {
  const classes = useStyles({ color, margin })

  return <MuiProgress className={classes.progress} size={size ?? 20} />
}

const useStyles = makeStyles<Theme, StyleProps>(
  () => ({
    progress: ({ margin, color }) => ({
      margin: margin || '0 auto',
      color: color || constants.COLOR_MAIN_NEW,
    }),
  }),
  { name: 'CircularProgressNoWrapper' }
)
