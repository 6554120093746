import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { mutationUpdateTeamBuildingActionDate } from 'pages/teams/hooks/graphql'
import { TeamBuildingActionDateStatus } from 'utils/generated'

import CautionIcon from '../../assets/caution.svg'
import { ContentButton } from '../generic/ContentButton'

type Props = {
  id: string
  onClose: () => void
}

export const ActionCompleteModalBody: React.FC<Props> = ({ id, onClose }) => {
  const classes = useStyles()
  const cancel = () => {
    onClose()
  }
  const submit = async () => {
    await mutationUpdateTeamBuildingActionDate({
      input: {
        id,
        status: TeamBuildingActionDateStatus.Completed,
      },
    })
    onClose()
  }

  return (
    <div className={classes.root}>
      <img alt="" src={CautionIcon} />
      <ContentButton label="完了する" onClick={submit} inline disabled={!id} />
      <ContentButton type="border" label="キャンセル" onClick={cancel} inline />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
  },
}))
