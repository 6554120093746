import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export const NoStyleButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...props }, ref) => {
  const classes = useStyles()

  return (
    <button className={classNames([classes.resetButton, className])} ref={ref} {...props}>
      {props.children}
    </button>
  )
})

const useStyles = makeStyles(
  (theme: Theme) => ({
    resetButton: {
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      appearance: 'none',
    },
  }),
  { name: 'NoStyleButton' }
)
