import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import CheckIcon from '../../assets/check.svg'
import NoCheckIcon from '../../assets/no_check.svg'

type Props = {
  options?: {
    label: string
    value: string
  }[]
  selectedString?: string | null
  setSelected(v: string): void
}
export const IcebreakSelect: React.FC<Props> = ({ selectedString, setSelected, options = [] }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {options.map(({ label, value }) => (
        <div
          key={label}
          className={classNames([classes.select])}
          onClick={() => {
            setSelected(value)
          }}
        >
          {selectedString === label ? (
            <img className={classes.icon} alt="check" src={CheckIcon} />
          ) : (
            <img className={classes.icon} alt="no_check" src={NoCheckIcon} />
          )}
          {label}
        </div>
      ))}
    </div>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    color: '#00838C',
    fontWeight: 'bold',
    width: '100%',
    height: 56,
    borderRadius: 8,
    border: '1px solid #00838C',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    marginRight: 24,
  },
}))
