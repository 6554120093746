import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { ArrowLinkButton } from 'pages/features/components/ArrowLinkButton'
import { Feature, TabNames } from 'pages/features/features.data'

import * as constants from 'assets/constants'

type Props = {
  feature: Feature
  checked: TabNames
}

export const FeatureBox: React.FC<Props> = ({ feature }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.featureImg}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/landing/features/${feature.id}-top.jpg`}
          alt={`${feature.label}メイン画像`}
        />
      </div>
      <div className={classes.featureBox}>
        <div className={classes.featureTitleBox}>
          <div className={classes.featureTitle}>{feature.title}</div>
          <div>
            <span className={classes.featureSubTitle}>{feature.label}</span>
            <span className={classes.verticalLine}>|</span>
            <span className={classes.featureSubTitle}>{feature.mainText.title}</span>
          </div>
        </div>
        <p className={classes.featureDescription}>{feature.mainText.body}</p>
        <ArrowLinkButton
          path={feature.link}
          text={
            <div>
              <p className={classes.linkText1}>{feature.mainText.link}</p>
              <p className={classes.linkText2}>{`${feature.label}を詳しく見る`}</p>
            </div>
          }
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        gap: '48px',
      },
    },
    featureImg: {
      width: '100%',
      '& img': {
        verticalAlign: 'top',
        width: '100%',
      },
    },
    featureBox: {
      width: '100%',
    },
    featureTitleBox: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      rowGap: 8,
      margin: '12px 0 0',
    },
    featureTitle: {
      marginRight: 7,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 34,
      fontFamily: constants.FONT_FAMILY_TOP_TITLE,
      lineHeight: 1,
    },
    featureSubTitle: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },
    verticalLine: {
      margin: '0 14px 0',
      fontWeight: 'normal',
      color: constants.COLOR_GRAY_DARK,
    },
    featureDescription: {
      margin: '24px 0 18px',
      lineHeight: 1.8,
    },
    linkText1: {
      margin: 0,
      fontSize: 14,
      lineHeight: 1,
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
    },
    linkText2: {
      margin: '10px 0 0',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1,
    },
  }),
  { name: 'TabContent' }
)
