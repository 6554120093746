import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'

import { constants, Pages } from 'assets'

type Props = {
  backColor: 'primary' | 'secondary'
} & React.ComponentProps<'button'>

export const PlayButton: React.FC<Props> = ({ className, backColor }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleStart = () => {
    history.push(Pages.ObakesagashiSearch)
    window.scrollTo(0, 0)
  }

  return (
    <div className={classes.topButtonBox}>
      <NoStyleButton className={classNames(className, backColor)} type="button" onClick={handleStart}>
        オバケを探してみる
      </NoStyleButton>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    topButtonBox: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '& button': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 400,
        height: 100,
        fontSize: 24,
        fontWeight: 'bold',
        borderRadius: 100,
        '&.primary': {
          background: constants.COLOR_YELLOW4,
          color: '#000',
        },
        '&.secondary': {
          background: constants.COLOR_NAVY,
          color: constants.COLOR_YELLOW4,
        },
        '&.buttomButton': {
          marginTop: 100,
        },
        [theme.breakpoints.down('md')]: {
          width: 343,
          height: 100,
          '&.buttomButton': {
            marginTop: 80,
          },
        },
      },
    },
  }),
  { name: 'PlayButton' }
)
