import React from 'react'

import { Theme } from '@mui/material';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { IActionItem } from 'stores/setupaction/reducer'

import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import { OnboardingAction, OnboardingActionCategory } from 'utils/generated'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


interface IOwnProps {
  item: IActionItem
  teamId: string
  userId: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const timing = (period?: number) => {
  if (period === 0) return '参加当日に追加'
  else if (period) return `参加から${period}日後に追加`
  else return ''
}

/**
 * generate card contents for each card. ( for action-manage / action-manage-detail )
 */
export const cardContents = (item?: IActionItem | OnboardingAction) => ({
  category: onboardingActionCategoryLabel(item?.category as OnboardingActionCategory),
  timing: timing(item?.period),

  mission: item?.mission || '', // this is title of each card in list
  missionDesc: item?.why || '', // inserted below title

  actionDesc: item?.what || '', // described as アクション
  actionTarget: item?.how || '', // described as 達成基準
})

const Card: React.FC<Props> = ({ classes, item, teamId, userId }) => {
  const history = useOnbHistory()

  const onCardClick = () => {
    history.push(OnbPages.ActionManageDetail, {teamId, userId, actionId: item.id})
  }

  const contents = cardContents(item)

  return (
    <div className={classes.root} onClick={onCardClick}>
      <div className={classes.row}>
        <span className={classes.category}>{contents.category}</span>
        <span className={classes.timing}>{contents.timing}</span>
      </div>

      <div className={classes.row}>
        <span className={classes.boldText}>{contents.mission}</span>
      </div>

      <div className={classes.row}>
        <span className={classes.goToDetail}>
          アクション詳細 &nbsp;
          <img src={require('./assets/arrow-right.svg').default} alt="arrow-right" />
        </span>
      </div>
    </div>
  )
}

export const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      padding: '4px 16px 80px 16px',
    },

    root: {
      minHeight: '100px',
      marginBottom: theme.spacing(1),
      padding: 16,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      borderRadius: '5px',
      cursor: 'pointer',
    },

    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingBottom: '16px',

      '&:last-child': {
        paddingBottom: 0,
      },
    },

    // head
    category: {
      display: 'block',
      padding: '3px 8px',

      color: constants.COLOR_ONBOARDING_MAIN,
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: '1em',

      border: '1px solid',
      borderColor: constants.COLOR_ONBOARDING_MAIN,
      borderRadius: 4,

      boxSizing: 'border-box',
    },

    timing: {
      fontSize: 12,
      display: 'block',
      color: constants.TEXT_GRAY,
    },

    // body
    boldText: {
      lineHeight: '24px',
      fontWeight: 'bold',
    },

    descText: {
      marginTop: '-10px',
      fontSize: '1rem',
    },

    // footer
    goToDetail: {
      display: 'block',
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
      width: '100%',
      textAlign: 'right',
    },
  })

export const ActionCard = withStyles(useStyles)(Card)
