import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { Button } from './Button'
import { SendEmailDialog } from './SendEmailDialog'

type Props = {
  isLeader: boolean | undefined
  handleClickCreateSummary: () => Promise<void>
  handleCreateSummaryBySendEmail: () => Promise<boolean>
}

export const AnketClose: React.FC<Props> = ({ isLeader, handleClickCreateSummary, handleCreateSummaryBySendEmail }) => {
  const classes = useStyles()
  const [isOpenDialog, setIsOpenDialog] = React.useState(false)

  const handleConfirmQuickStart = () => {
    if (window.confirm('メンバーに案内メールを送らずに、すぐにTBチェックを開始しますか？')) {
      handleClickCreateSummary()
    }
  }

  return (
    <div className={classes.container}>
      <div>
        {isLeader
          ? 'TBチェックを開始する場合は、下記のどちらかのボタンを押してください。'
          : 'リーダーがTBチェックを開始すると、アンケートの回答ボタンが表示されます。\nリーダーが開始してもボタンが表示されない場合は、右上の更新ボタンを押してください。'}
      </div>

      {isLeader ? (
        <>
          <div className={classes.buttonWrapper}>
            <Button onClick={handleConfirmQuickStart}>いますぐ開始</Button>
            <Button onClick={() => setIsOpenDialog(true)}>メールで依頼</Button>
          </div>
          <SendEmailDialog
            isOpen={isOpenDialog}
            handleCloseDialog={() => setIsOpenDialog(false)}
            handleCreateSummaryBySendEmail={handleCreateSummaryBySendEmail}
          />
        </>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    container: {
      fontSize: 12,
    },
    buttonWrapper: {
      display: 'flex',
      gap: 16,
      marginTop: 16,
    },
  }),
  { name: 'AnketClose' }
)
