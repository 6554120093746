import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { WorkplaceLab } from 'components/WorkplaceLab'

import { Play } from './Play'

import { constants } from 'assets'

const contentMotive = {
  titleImgFont: { img: 'motive-title.svg', alt: '価値観' },
  body: '27種類の傾向から、あなたの\nはたらく価値観がわかります。',
  imgItems: [
    { src: 'motive-1.png', alt: 'チームミッション追求傾向' },
    { src: 'motive-2.png', alt: '上司とタッグ傾向' },
    { src: 'motive-3.png', alt: '成長できる仕事求む傾向' },
    { src: 'motive-4.png', alt: '公私充実するぞ傾向' },
    { src: 'motive-5.png', alt: '日々の手応え大切傾向' },
    { src: 'motive-6.png', alt: '目指せ甲子園傾向' },
  ],
}

const contentCommitment = {
  titleImgFont: { img: 'commitment-title.svg', alt: 'こだわり' },
  body1: 'あなたが働くうえで重視している\n「こだわり」が見えます。',
  body2: '例)お客様の喜ぶ声など反応が得られる仕事がしたい',
  img: 'commitment-people.png',
}

export const WhatWorkDiagnosis: React.FC = () => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <Play />

      <h2 className={classes.title}>
        <span>はたらく価値観診断</span>とは？
      </h2>

      <div className={classes.body}>
        <div className={classes.bodyImg}>
          <img src={`${process.env.PUBLIC_URL}/img/hataraku/what-work-diagnosis.png`} alt="" />
        </div>

        <div className={classes.bodyText}>
          <h3 className={classes.bodyTitle}>
            あなたが働くうえで、
            <br />
            <span>大切にしたい価値観</span>は何でしょう？
          </h3>
          <p className={classes.bodyDesc}>
            この診断では、仕事に関する質問に答えていくことで、あなたが大切にしたい、仕事に対する価値観の傾向やこだわりを見つけることができます。
          </p>
        </div>
      </div>

      <div className={classes.contents}>
        <div className={classes.content}>
          <h3 className={classes.contentTitle}>
            <img
              src={`${process.env.PUBLIC_URL}/img/hataraku/${contentMotive.titleImgFont.img}`}
              alt={contentMotive.titleImgFont.alt}
            />
          </h3>

          <p className={classes.contentDescTop}>{contentMotive.body}</p>
          <div className={classes.motiveImages}>
            {contentMotive.imgItems.map((img) => (
              <img key={img.alt} src={`${process.env.PUBLIC_URL}/img/hataraku/${img.src}`} alt={img.alt} />
            ))}
          </div>
        </div>

        <div className={classes.downArrow}>
          <img src={`${process.env.PUBLIC_URL}/img/hataraku/arrow-3down.svg`} alt="" />
        </div>

        <div className={classes.content}>
          <h3 className={classes.contentTitle}>
            <img
              src={`${process.env.PUBLIC_URL}/img/hataraku/${contentCommitment.titleImgFont.img}`}
              alt={contentCommitment.titleImgFont.alt}
            />
          </h3>

          <p className={classNames(classes.contentDescTop, 'commitment')}>{contentCommitment.body1}</p>
          <p className={classes.contentDescSub}>{contentCommitment.body2}</p>
          <div className={classes.commitmentImage}>
            <img
              src={`${process.env.PUBLIC_URL}/img/hataraku/${contentCommitment.img}`}
              alt="丸とバツの札を挙げる男女"
            />
          </div>
        </div>
      </div>

      <WorkplaceLab />
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 0 159px',
      background: '#fff',
      // 背景に格子模様を作る。
      backgroundImage: `
        repeating-linear-gradient(
          to bottom,
          ${constants.COLOR_BLUE_DARK2}26 ,
          ${constants.COLOR_BLUE_DARK2}26 1px,
          transparent 1px,
          transparent 24px
        ),
        repeating-linear-gradient(
          to right,
          ${constants.COLOR_BLUE_DARK2}26 ,
          ${constants.COLOR_BLUE_DARK2}26 1px,
          ${constants.COLOR_GRAY_LIGHT3} 1px,
          ${constants.COLOR_GRAY_LIGHT3} 24px
        )
      `,
    },
    title: {
      margin: '86px 0 0',
      padding: '0 6px 0 11px',
      background: '#00AEBB',
      color: '#fff',
      fontSize: 32,
      fontWeight: 'bold',
      letterSpacing: '3.2px',
      lineHeight: 1.3,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 28,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    body: {
      display: 'flex',
      columnGap: 44,
      marginTop: 70,
      padding: '0 18% 16px 12%',
      background: '#fff',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 16px 16px',
      },
    },
    bodyImg: {
      flexShrink: 0,
      marginTop: -40,
      width: '50%',
      maxWidth: 507,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 480,
      },
      '& img': {
        width: '100%',
      },
    },
    bodyText: {
      marginTop: 12,
    },
    bodyTitle: {
      margin: '24px 0 0',
      fontSize: 24,
      fontWeight: 'bold',
      lineHeight: 1.7,
      letterSpacing: 2.4,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
        textAlign: 'center',
      },
      '& span': {
        color: constants.COLOR_BLUE_DARK2,
      },
    },
    bodyDesc: {
      margin: '24px 0 0',
      width: '100%',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.7,
      letterSpacing: 1.6,
    },

    contents: {
      marginTop: 147,
      padding: '0 80px',
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    },
    content: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 815,
      padding: '0 80px',
      background: '#FFF',
      borderRadius: 33,
      boxShadow: '8px 8px 14px 0 rgba(0, 0, 0, 0.25)',
      zIndex: 1,
      [theme.breakpoints.down('md')]: {
        padding: '0 60px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    },
    contentTitle: {
      position: 'absolute',
      top: -26,
      left: -52,
      margin: 0,
      width: 209,
      [theme.breakpoints.down('md')]: {
        top: -40,
        left: 10,
        width: 140,
      },
      '& img': {
        width: '100%',
      },
    },
    contentDescTop: {
      margin: '45px 0 0',
      color: '#2EB1BA',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '30px',
      letterSpacing: 2,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('md')]: {
        margin: '110px 0 0',
      },
      '&.commitment': {
        marginTop: 57,
        [theme.breakpoints.down('md')]: {
          margin: '110px 0 0',
        },
      },
    },
    motiveImages: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 7,
      margin: '34px 0 67px',
      '& img': {
        width: 165,
        [theme.breakpoints.down('sm')]: {
          width: 140,
        },
      },
    },
    downArrow: {
      display: 'grid',
      placeItems: 'center',
      margin: '36px 0 30px',
      '& img': {
        width: 85,
      },
    },
    contentDescSub: {
      position: 'relative',
      margin: '24px 0 0',
      padding: '0 24px',
      color: '#FFF',
      textAlign: 'center',
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: 1.6,
      borderRadius: 80,
      background: '#2EB1BA',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 16px',
        lineHeight: '22px',
      },
      // 疑似要素で吹き出し部分を作成。
      '&::before': {
        position: 'absolute',
        content: '""',
        top: 'calc(100% - 1px)',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: 18,
        height: 13,
        background: '#2EB1BA',
        clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
      },
    },
    commitmentImage: {
      margin: '10px 0 42px',
      width: 372,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 300,
      },
      '& img': {
        width: '100%',
        verticalAlign: 'top',
      },
    },
  }),
  { name: 'WhatWorkDiagnosis' }
)
