import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import SearchSvg from 'pages/teams/pages/_tools/karte/assets/search.svg'

import { TableFilter } from './index'

import { constants } from 'assets'

type Props = {
  memberName: string
  setFilter: React.Dispatch<React.SetStateAction<TableFilter>>
  ownStyles?: React.CSSProperties
}

export const FilterMemberNameInput: React.FC<Props> = ({ memberName, setFilter, ownStyles }) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ ...ownStyles }}>
      <img src={SearchSvg} alt="search icon" />
      <input
        type="text"
        name="memberName"
        placeholder="メンバーを検索"
        maxLength={100}
        value={memberName}
        onChange={(e) => setFilter((prev) => ({ ...prev, targetMemberName: e.target.value }))}
      />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',

      '& img': {
        position: 'absolute',
        left: 8,
        top: 9,
        cursor: 'pointer',

        width: 20,
        height: 20,
      },

      '& input[type="text"]': {
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        width: 200,
        height: 40,
        padding: '8px 8px 8px 36px',
        backgroundColor: '#fff', // ブラウザごとのデフォルトの背景色を上書き
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
        border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
        borderRadius: 8,

        [theme.breakpoints.down('md')]: {
          width: '100%',
          fontSize: 16, // iOS の仕様で 16pxより小さいとフォーカス時にズームされてしまうため
        },

        '&:focus': {
          outline: `1px solid ${constants.COLOR_TEAMBUILDING_PRIMARY}`,
        },
      },
    },
  }),
  { name: 'FilterMemberNameInput' }
)
