import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import { IuseSocials } from '../useSocials'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 0,
    padding: 0,
  },

  icons: {
    marginTop: 8,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseSocials
}

// -----------------------------
// Component
// -----------------------------
export const SocialsShare = (props: IProps) => {
  const classes = useStyles()
  const location = useLocation()

  return (
    <div className={classes.root}>
      <div className={classes.icons}>
        <TwitterShareButton url={`${window.location.protocol}//${window.location.host}${location.pathname}`}>
          <img src={'/assets/svg/karte/twitter_icn.svg'} alt={'twitter'} />
        </TwitterShareButton>
      </div>
      <div className={classes.icons}>
        <FacebookShareButton url={`${window.location.protocol}//${window.location.host}${location.pathname}`}>
          <img src={'/assets/svg/karte/facebook_icn.svg'} alt={'facebook'} />
        </FacebookShareButton>
      </div>
    </div>
  )
}
