import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { Content } from '../../review/components/generic/Content'
import { ContentHead } from '../../review/components/generic/ContentHead'
import { ContentSection } from '../../review/components/generic/ContentSection'
import { Landing } from '../../review/components/generic/Landing'
import { CategorizedIceBreak, useCategorizedIceBreak } from '../../review/components/hooks/useIcebreak'
import { IcebreakSelect } from '../../review/components/icebreak/IcebreakSelect'
import { IceBreakContext } from '../contexts/iceBreakContext'

import { constants } from 'assets'

type CategoryProps = {
  data: CategorizedIceBreak
  iceBreakTheme?: string | null
  setIceBreakTheme(theme: string): void
}

export const SelectTheme: React.FC = () => {
  const classes = useStyles()
  const { categorizedIceBreak } = useCategorizedIceBreak()
  const { isLeader, iceBreakTheme, setIceBreakTheme } = React.useContext(IceBreakContext)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!isLeader) return <Landing title="アイスブレイク" infoMsg="テーマ設定が完了するまでお待ちください" />

  return (
    <div className={classes.root}>
      <ContentHead
        title={'アイスブレイク'}
        subtitle={`アイスブレイクの内容を選択しましょう。`}
        steps={3}
        currentStep={1}
      />
      <ContentSection>
        {categorizedIceBreak.map((category) => (
          <Category
            key={category.id}
            data={category}
            iceBreakTheme={iceBreakTheme}
            setIceBreakTheme={setIceBreakTheme}
          />
        ))}
      </ContentSection>
    </div>
  )
}

const Category: React.FC<CategoryProps> = ({ data, iceBreakTheme, setIceBreakTheme }) => {
  const classes = useStyles()
  const options = data.icaBreak.map((icaBreak) => ({
    label: icaBreak.name ?? '',
    value: icaBreak.name ?? '',
  }))

  return (
    <Content>
      <div className={classes.name}>{data.name}</div>
      <div className={classes.description}>{data.description}</div>
      <IcebreakSelect options={options} selectedString={iceBreakTheme} setSelected={setIceBreakTheme} />
    </Content>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
    },
    name: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    description: {
      fontSize: 12,
      margin: '8px 0 26px',
    },
  }),
  { name: 'SelectTheme' }
)
