import React from 'react'
import { Link } from 'react-router-dom'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { MenuItem, Select } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { DefaultInputWithLabel, DefaultButton, Card, CardFooter, InputLabel } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useManageTeam } from 'pages/teams/hooks/teams'
import { TeamMemberRole, TeamStatusUsage } from 'utils/generated'
import { getTeamStatusUsageLabel } from 'utils/labels'

import { TeamsPlans } from './components/Plans'
import { TeamsTools } from './components/Tools'

import { constants } from 'assets'




interface IInput {
  [key: string]: string
  name: string
  statusUsage: TeamStatusUsage
}

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsAboutPage: React.FC<Props> = (props) => {
  const { teamId } = props.match.params

  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const { currentTeam, currentTeamMember, refreshTeam, isAdmin } = React.useContext(HooksContext)

  const { updateTeam, loading: processing } = useManageTeam()

  const { control, errors, handleSubmit, setValue } = useForm<IInput>()
  React.useEffect(() => {
    if (currentTeam) {
      setValue('name', currentTeam.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam])

  const onSubmit = async (input: IInput) => {
    const updated = await updateTeam({
      id: teamId,
      name: input.name,
      statusUsage: input.statusUsage,
    })
    if (updated) {
      refreshTeam()
    }
  }

  const editable =
    currentTeamMember?.role.includes(TeamMemberRole.Admin) || currentTeamMember?.role.includes(TeamMemberRole.Leader)

  const cardStyles: React.CSSProperties = { padding: isSmDown ? '30px 16px 40px' : '30px 24px 40px' }

  return (
    <>
      <div className={classes.frontContainer}>
        {isSmDown && (
          <div className={classes.toTeamListContainer}>
            <Link
              to={`/teams/${currentTeam !== undefined ? currentTeam.id : ':teamId'}/list?hidden=${true}`}
              className={classes.toTeamListLink}
            >
              チームリスト
            </Link>
          </div>
        )}
        <section className={classes.frontSection}>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Card headerTitle={'チーム情報'} loading={!currentTeam} ownStyles={cardStyles} headerMb={isSmDown ? 8 : 24}>
              <DefaultInputWithLabel
                label={'チーム名'}
                name={'name'}
                control={control}
                rules={{ required: 'チーム名は必須です' }}
                error={errors.name}
                defaultValue={currentTeam?.name}
                readOnly={!editable}
                htmlFor={'teamName'}
              />

              <InputLabel>活動状況</InputLabel>
              <Controller
                name={'statusUsage'}
                control={control}
                defaultValue={currentTeam?.statusUsage}
                render={({ onChange, value }) => (
                  <Select
                    className={`${classes.select} ${!editable ? classes.selectReadOnly : ''}`}
                    variant={'outlined'}
                    value={value}
                    onChange={onChange}
                    readOnly={!editable}
                    IconComponent={ExpandMoreIcon}
                  >
                    {[TeamStatusUsage.InUse, TeamStatusUsage.NonUse].map((value, index) => (
                      <MenuItem key={`team-status-usage-selector-${index}`} value={value} className={classes.menuItem}>
                        {getTeamStatusUsageLabel(value)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {editable ? (
                <CardFooter>
                  <DefaultButton title={'更新'} color={'team_main'} type="submit" loading={processing} />
                </CardFooter>
              ) : (
                <></>
              )}
            </Card>
          </form>
        </section>
        {isAdmin ? (
          <section className={classes.frontSection}>
            <TeamsPlans teamId={teamId} cardStyles={cardStyles} />
          </section>
        ) : (
          <></>
        )}
      </div>

      <section>
        <TeamsTools teamId={teamId} cardStyles={cardStyles} />
      </section>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  frontContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  toTeamListContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 18,
  },
  toTeamListLink: {
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: 12,
    fontWeight: 600,
    textDecoration: 'underline',
  },

  frontSection: {
    backgroundColor: constants.COLOR_WHITE,
    width: '49%',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  form: {},

  select: {
    width: '100%',
    marginBottom: 40,
    borderRadius: 8,
    height: 49,
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
    border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

    '& .MuiSelect-root': {
      padding: 16,
    },

    '& .MuiSvgIcon-root': {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      right: 12,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  selectReadOnly: {
    backgroundColor: '#f5f5f5',
  },

  menuItem: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
  },
}))
