import * as React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { useTeams } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMemberRole, OnboardingTeamMember } from 'utils/generated'

import MemberAddSvg from '../assets/member-add.svg'
import { OnbPages } from 'assets/pages'


type Props = {
  teamId?: string
  teamLabel: string
  members?: { value: string; label: string }[]
  selectedMemberId?: string
  handleOnClickMember: (value: string) => void
  handleOnClickAdd: () => void
  teamMember: OnboardingTeamMember | undefined
  ttRef?: React.MutableRefObject<HTMLElement | undefined>
  displayValue?: string
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const Toolbar: React.FC<Props & StyleProps> = ({
  teamId,
  teamLabel,
  members,
  selectedMemberId,
  teamMember,
  handleOnClickMember,
  handleOnClickAdd,
  displayValue,
  ttRef,
  ownStyles,
}) => {
  const { teams } = useTeams()
  const theme = useTheme()
  const classes = useStyles({ ownStyles: ownStyles })
  const history = useOnbHistory()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <div className={classes.rootContainer}>
      <div className={classes.dropDownForm}>
        <Dropdown
          items={teams.map((team) => ({ value: team.id, label: `${team.title}(${team.memberCount || 0})` }))}
          handleOnClick={(teamId) => history.push(OnbPages.Team, { teamId })}
          value={teamId}
          keyPrefix={'team-three-person'}
          borderRadius={8}
          teamLabel={teamLabel}
        />
      </div>
      {displayValue && isSmUp ? (
        <div className={classes.container}>
          <div className={classes.dropDownIcon}>
            <Dropdown
              items={[{ value: '', label: ' 全員' }, ...(members || [])]}
              value={selectedMemberId}
              handleOnClick={handleOnClickMember}
              keyPrefix={'team-two-person'}
              displayValue={displayValue}
              borderRadius={8}
              ownStyles={{ minWidth: 120 }}
            />
          </div>
        </div>
      ) : (
        <div className={classes.dropDownIcon}>
          <Dropdown
            items={[{ value: '', label: ' 全員' }, ...(members || [])]}
            value={selectedMemberId}
            handleOnClick={handleOnClickMember}
            keyPrefix={'team-two-person'}
            iconList="change"
          />
        </div>
      )}

      {teamMember?.role === OnboardingTeamMemberRole.Admin ? (
        <div className={classes.dropDownIcon}>
          <div className={classes.iconWrapper} ref={ttRef as React.MutableRefObject<HTMLDivElement>}>
            <img src={MemberAddSvg} alt={`member-add-icon`} onClick={handleOnClickAdd} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rootContainer: ({ ownStyles }: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    ...ownStyles,
  }),
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  dropDownForm: {
    flex: 1,
  },
  dropDownIcon: {
    marginLeft: 16,
  },
  iconWrapper: {
    width: 42,
    height: 42,
    borderRadius: '50%',
    cursor: 'pointer',
  },
}))
