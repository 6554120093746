import * as React from 'react'

import { Box } from '@mui/material'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
const WrapperInTips = (props: { children: React.ReactNode }) => {
  return <Box pt={{ xs: '40px', lg: '74px' }}>{props.children}</Box>
}

const WrapperInFaq = (props: { children: React.ReactNode }) => {
  return <Box pt={'160px'}>{props.children}</Box>
}

const WrapperSocial = (props: { children: React.ReactNode }) => {
  return (
    <Box width="100%" mt="32px" mb={{ xs: '64px', lg: '64px' }} display="flex" justifyContent="flex-end">
      {props.children}
    </Box>
  )
}

const WrapperProfile = (props: { children: React.ReactNode }) => {
  return (
    <Box mt="64px" mb={{ xs: '35px', lg: '64px' }}>
      {props.children}
    </Box>
  )
}

const WrapperForm = (props: { children: React.ReactNode }) => {
  return <Box mb={{ xs: '100px', lg: '94px' }}>{props.children}</Box>
}

const WrapperButton = (props: { children: React.ReactNode }) => {
  return (
    <Box mt={{ xs: '28px', lg: '51px' }} maxWidth={'324px'} mx={'auto'}>
      {props.children}
    </Box>
  )
}

const WrapperComment = (props: { children: React.ReactNode }) => {
  return <Box mb={{ xs: '70px', lg: '140px' }}>{props.children}</Box>
}

export { WrapperInTips, WrapperInFaq, WrapperSocial, WrapperProfile, WrapperForm, WrapperButton, WrapperComment }
