import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

export interface IFormCharCountInput {
  comment: string
  nickname: string
  position: string
  description: string
  labelText: string
  memberEmail: string
  editPost: string
  mission: string
  why: string
  what: string
  how: string
  editComment: string
  commentReply: string
}

type Props = {
  length: number
  maxLength: number
  invisibleHeight?: number
}

export const FormCharCount: React.FC<Props> = ({ length, maxLength, invisibleHeight }) => {
  const classes = useStyles()

  return (
    <>
      {length >= maxLength - 20 ? (
        <div className={classes.alert} style={{ color: length > maxLength ? constants.COLOR_ONBOARDING_ALERT : undefined }}>
          {`残り文字数　${maxLength - length}`}
        </div>
      ) : (
        <>{invisibleHeight ? <div style={{ paddingTop: invisibleHeight }}></div> : <></>}</>
      )}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    textAlign: 'right',
    fontSize: 12,
    marginTop: 9,
    lineHeight: 1,
  },
}))
