import * as React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import { TextBox } from './topPageModules/TextBox'
import { TopSectionTitle } from './topPageModules/TopSectionTitle'

type Props = {}

export const TopDeveloper: React.FC<Props> = () => {
  const classes = useStyles({})
  const theme = useTheme()
  const is700Up = useMediaQuery(theme.breakpoints.up(700))
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={classes.root} id={constTop.anchorLinkId[4]}>
      <TopSectionTitle title="プロダクト開発者" />
      <div className={classes.wrap}>
        <div className={classes.logo}>
          <a href="https://new-one.co.jp/" target="_blank" rel="noopener noreferrer">
            <img
              className={classes.newoneLogo}
              src={process.env.PUBLIC_URL + '/img/topPage/newoneLogo.svg'}
              alt="newone"
            />
          </a>
        </div>
        <div className={classes.textBox}>
          {isSmDown ? (
            <>
              <div className={classes.topic}>{is700Up ? constTop.developerPC.topic : constTop.developerSP.topic}</div>
              <div className={classes.text}>{is700Up ? constTop.developerPC.text : constTop.developerSP.text}</div>
            </>
          ) : (
            <TextBox
              topic={is700Up ? constTop.developerPC.topic : constTop.developerSP.topic}
              text={is700Up ? constTop.developerPC.text : constTop.developerSP.text}
              ownStyles={{ ...constTop.smallTopicTextStyles, lineHeight: 1.9, fontWeight: 400 }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '&::before': {
      ...constTop.fixAnchorLinkPosition,
    },
  },
  logo: {
    textAlign: 'center',
    margin: '60px auto 0',
    '& img': {
      width: 320,
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        width: 240,
      },
    },
    [theme.breakpoints.down('md')]: {
      margin: '44px auto 0',
      paddingTop: '40px',
    },
  },
  textBox: {
    width: '100%',
    margin: '64px 0 0 0',
    padding: '0 24px 0',
    lineHeight: 1.8,
    [theme.breakpoints.down('md')]: {
      margin: '24px 0 0 0',
    },
  },
  [theme.breakpoints.down('md')]: {
    wrap: {
      backgroundColor: constTop.COLOR_WHITE,
    },
  },
  [theme.breakpoints.up(constTop.breakPointsSP + 300)]: {
    wrap: {
      width: '100%',
      margin: '64px 0 0 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: constTop.COLOR_WHITE,
    },
    logo: {
      margin: '0 0 0 39px',
      padding: 0,
    },
    textBox: {
      width: 610,
      margin: '60px 38px 60px 40px',
      padding: '0',
    },
  },
  topic: {
    fontSize: 16,
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
  text: {
    fontSize: 14,
    margin: '24px 0 0 0',
    lineHeight: 1.75,
    paddingBottom: '40px',
  },
}))
