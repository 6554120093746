import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '70px',
    marginBottom: '90px',
    height: '113px',
  },

  textarea: {
    width: '100%',
    fontSize: '16px',
    resize: 'none',
    padding: '16px',
    boxSizing: 'border-box',
    border: 'solid 1px#ADADAD',
    borderRadius: '4px',

    [theme.breakpoints.down('lg')]: {
      height: '170px',
    },

    '&::placeholder': {
      color: '#E6E6E6',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string
  value: string
}

// -----------------------------
// Component
// -----------------------------
export const CommentField = React.forwardRef<HTMLTextAreaElement | null, IProps>(({ ...props }, ref) => {
  const classes = useStyles()

  return (
    <Box>
      <textarea
        {...props}
        value={props.value}
        ref={ref}
        name={props.name}
        placeholder="コメントを入力"
        className={classes.textarea}
        rows={4}
      />
    </Box>
  )
})
