import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { Draft } from 'pages/hint/_shared/components/draft/Index'

import { IuseArticleHeading } from './useArticleHeading'


import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'inline',
  },

  heading: {
    color: constants.COLOR_BLACK,
    fontWeight: 600,
    fontSize: '24px',
    textDecoration: 'underline',
    margin: 0,
    wordBreak: 'break-all',

    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: string
  ellipsis?: boolean
  articleId?: number
  presenter?: IuseArticleHeading
}

// -----------------------------
// Component
// -----------------------------
export const ArticleHeading = (props: IProps) => {
  const classes = useStyles()
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const userSelector = useSelector((state: RootStateType) => state.user)

  return (
    <div className={classes.root}>
      <h2 className={`${classes.heading}`}>
        {props.text}
        {userSelector.id === hintSelector.hint.username && (
          <Box ml="16px" display="inline">
            <Draft
              articleId={props.articleId}
              onClick={() => props.presenter?.onOpenDraftModal(hintSelector.hint.username)}
            />
          </Box>
        )}
      </h2>
    </div>
  )
}
