import { useState, useEffect, useCallback, useMemo } from 'react'

import { GraphQLResult } from '@aws-amplify/api'
import { useForm, useWatch } from 'react-hook-form'
import { queryGetFeature, queryGetFeaturesByPrefix, subscriptionOnUpdateFeatureWithVersion } from 'services/api/graphql'

import {
  createIceBreak,
  deleteIceBreakByLeader,
  getIceBreakHistories,
  handleApiCall,
  updateIceBreakByLeader,
  updateIceBreakMember,
} from 'utils/apiService'
import { randomPickupElWithExclusion } from 'utils/array'
import { DynamoDB_ERRORS } from 'utils/error'
import { Feature, FeatureCategory, FeatureStatus } from 'utils/generated'
import { extractFeatureSkId, getFeatureSkFromSkId, makeFeatureSk, makeFeatureSkPrefix } from 'utils/string'
import type { IceBreakContents, IceBreakPreparePresentation, UseCreateIceBreak, UseManageIceBreak } from 'utils/types'

import { constants, Pages } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'

export const useStartIceBreak = ({ teamId, currentTeamMember }: UseCreateIceBreak) => {
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasActiveIceBreak, setHasActiveIceBreak] = useState<boolean>()
  const [errors, setErrors] = useState<Error[]>()
  const [histories, setHistories] = useState<Feature[]>()

  const handleStart = useCallback(async () => {
    if (isLoading || !teamId || !currentTeamMember) return

    // ポップアップブロック対策: ユーザーアクション内で新しいタブを先に開く。
    const newTab = window.open('', '_blank')

    const startProcess = async () => {
      const res = await queryGetFeaturesByPrefix({
        pk: teamId,
        skPrefix: makeFeatureSkPrefix(FeatureCategory.IceBreak),
        limit: 1,
      })

      // --------------------
      // 進行中のアイスブレイクが存在する場合:

      if (res?.items?.[0]?.status && ![FeatureStatus.Completed, FeatureStatus.Cancel].includes(res.items[0].status)) {
        const iceBreakId = extractFeatureSkId(res.items[0].sk, FeatureCategory.IceBreak)
        const contents = JSON.parse(res.items[0].contents) as IceBreakContents

        // 進行中のアイスブレイクの activeUserIds に自分の userId が含まれていない場合、追加。
        if (!contents.activeUserIds.includes(currentTeamMember.userId)) {
          await updateIceBreakMember({
            pk: teamId,
            sk: res.items[0].sk,
            currentVersion: res.items[0].version as number,
            contents,
            ownUserId: currentTeamMember.userId,
            action: 'entry',
          })
        }

        // 既存のアイスブレイクの iceBreakId を返す（iceBreakId は sk から prefix を除いた ulid の部分）。
        return iceBreakId
      }

      // --------------------
      // 進行中のアイスブレイクが存在しない場合:

      // 新規アイスブレイク作成。
      // 複数ユーザーの同時接続によるレースコンディションを回避するため、あらかじめ決められた ULID を sk に使用。
      //
      const ulid = res?.items?.[0]
        ? (JSON.parse(res.items[0].contents) as IceBreakContents).nextPlayId
        : constants.ICE_BREAK_FIRST_SK
      const sk = makeFeatureSk(FeatureCategory.IceBreak, ulid)

      try {
        // バックエンドにおいて、condition check の attribute_not_exists によって2回目以降の create を禁止。
        const createdItem = await createIceBreak({
          pk: teamId,
          sk,
          currentTeamMember,
        })

        return extractFeatureSkId(createdItem?.sk || '', FeatureCategory.IceBreak)
      } catch (e: any) {
        // condition check でエラーとなった場合、メンバーとして追加する。
        if (e.errors[0].errorType.includes(DynamoDB_ERRORS.conditionalCheck)) {
          const response = await queryGetFeature({ pk: teamId, sk })
          if (response) {
            await updateIceBreakMember({
              pk: teamId,
              sk: response.sk,
              currentVersion: response.version as number,
              contents: JSON.parse(response.contents) as IceBreakContents,
              ownUserId: currentTeamMember.userId,
              action: 'entry',
            })

            return extractFeatureSkId(response.sk, FeatureCategory.IceBreak)
          }
        }
        throw e
      }
    }

    const iceBreakId = await handleApiCall(startProcess, setIsLoading, setErrors)

    if (iceBreakId && newTab) {
      // 非同期処理後にタブの URL を設定。
      newTab.location.href = replacePathParams(Pages.TeamsIceBreak, { teamId, iceBreakId })
    } else {
      // エラー時にタブを閉じる。
      if (newTab) newTab.close()
    }
  }, [isLoading, teamId, currentTeamMember])

  const refresh = useCallback(async () => {
    if (!teamId) return
    setErrors(undefined)

    try {
      const res = await getIceBreakHistories({ teamId })
      setHistories(res.histories || [])
      setHasActiveIceBreak(res.hasActiveIceBreak)
    } catch (e: any) {
      setErrors(e.errors)
      setHistories(undefined)
    } finally {
      setIsRefreshed(true)
    }
  }, [teamId])

  useEffect(() => {
    refresh()
  }, [teamId, refresh])

  return { handleStart, isLoading, errors, histories, isRefreshed, hasActiveIceBreak }
}

export const useManageIceBreak = ({ teamId, iceBreakId, ownUserId }: UseManageIceBreak) => {
  const [iceBreak, setIceBreak] = useState<Feature>()
  const [isRefreshed, setIsRefreshed] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCanceling, setIsCanceling] = useState<boolean>(false)
  const [errors, setErrors] = useState<Error[]>()
  // NOTE: iceBreakTheme, setIceBreakTheme は、あくまで SelectTheme ページでの選択時に使用。
  //   テーマを参照したい場合は、iceBreakContents から取得する。
  const [iceBreakTheme, setIceBreakTheme] = useState<string>()
  const [isTimerEnd, setIsTimerEnd] = useState<boolean>(false)
  const { route } = useHistory()

  const { control } = useForm<IceBreakPreparePresentation>()
  const { memberCount: watchMemberCount, minute: watchMinute } = useWatch<IceBreakPreparePresentation>({ control })

  const pk = useMemo(() => teamId, [teamId])
  const sk = useMemo(() => getFeatureSkFromSkId(iceBreakId, FeatureCategory.IceBreak), [iceBreakId])

  const iceBreakContents: IceBreakContents = useMemo(
    () => (iceBreak?.contents ? JSON.parse(iceBreak.contents) : undefined),
    [iceBreak]
  )

  const isLeader = useMemo(() => ownUserId === iceBreakContents?.leaderUserId, [ownUserId, iceBreakContents])

  // NOTE: memberCount, minute は、あくまで PreparePresentation ページでの選択時に使用。
  //   値を参照したい場合は、iceBreakContents から取得する。
  const memberCount = useMemo(() => {
    const validated = watchMemberCount && watchMemberCount >= 1
    return validated ? watchMemberCount : 1
  }, [watchMemberCount])
  const minute = useMemo(() => {
    const validated = watchMinute && watchMinute >= 1
    return validated ? watchMinute : 1
  }, [watchMinute])

  const handleCancel = useCallback(
    async ({ isLeaderAction }: { isLeaderAction: boolean }) => {
      if (isLoading || !pk || !sk || (isLeaderAction && !isLeader)) return

      const confirmMessage = isLeaderAction ? 'アイスブレイクを中止しますか？' : '参加をキャンセルしますか？'
      if (!window.confirm(confirmMessage)) return

      const cancelProcess = async () => {
        if (isLeaderAction) {
          setIsCanceling(true)

          await updateIceBreakByLeader({
            pk,
            sk,
            status: FeatureStatus.Cancel,
            currentVersion: iceBreak?.version as number,
            contents: iceBreakContents as IceBreakContents,
            isLeader: true,
          })
          await deleteIceBreakByLeader({ pk, sk, isLeader: true })
          route.push(replacePathParams(Pages.TeamsDashboard, { teamId }))
        } else {
          if (!ownUserId) {
            alert('ユーザー情報を取得できませんでした。時間を置いて再度お試しください。')
            return
          }

          await updateIceBreakMember({
            pk,
            sk,
            currentVersion: iceBreak?.version as number,
            contents: iceBreakContents as IceBreakContents,
            ownUserId,
            action: 'cancel',
          })
          route.push(replacePathParams(Pages.TeamsDashboard, { teamId }))
          setIsCanceling(false)
        }
      }

      await handleApiCall(
        cancelProcess,
        setIsLoading,
        setErrors,
        '操作に失敗しました。時間を置いて再度お試しください。'
      )
    },
    [isLoading, pk, sk, route, teamId, iceBreakContents, iceBreak, isLeader, ownUserId]
  )

  const handleUpdateByLeader = useCallback(
    async ({ status, contents }: { status?: FeatureStatus; contents?: IceBreakContents }) => {
      const updateProcess = async () => {
        if (isLoading || !pk || !sk || !isLeader || !iceBreak?.status || !iceBreak.version || !iceBreakContents) return

        const res = await updateIceBreakByLeader({
          pk,
          sk,
          status: status ?? iceBreak.status,
          currentVersion: iceBreak.version,
          contents: contents ?? iceBreakContents,
          isLeader,
        })
        if (res) {
          setIceBreak(res)
        }
      }

      await handleApiCall(
        updateProcess,
        setIsLoading,
        setErrors,
        'データ更新に失敗しました。時間を置いて再度お試しください。'
      )
    },
    [isLoading, pk, sk, iceBreakContents, iceBreak, isLeader]
  )

  const selectUserIds = useCallback(
    (existingIds: string[] = []) => {
      const activeUserIds = iceBreakContents?.activeUserIds ?? []
      const selectedUserIds = iceBreakContents?.presentation?.selectedUserIds ?? []

      const pickupEl = randomPickupElWithExclusion<string>([...activeUserIds], [...selectedUserIds])

      return pickupEl ? [...existingIds, pickupEl] : []
    },
    [iceBreakContents]
  )

  const refresh = useCallback(async () => {
    if (!pk || !sk) return
    setErrors(undefined)

    try {
      const res = await queryGetFeature({ pk, sk })
      setIceBreak(res)
    } catch (e: any) {
      setErrors(e.errors)
      setIceBreak(undefined)
    } finally {
      setIsRefreshed(true)
    }
  }, [pk, sk])

  useEffect(() => {
    refresh()
  }, [teamId, refresh])

  useEffect(() => {
    // onUpdateFeatureWithVersion サブスクリプション
    // NOTE: 排他制御のため subscriptionOnUpdateFeature はアイスブレイクでは未使用。
    const subscribeUpdateWithVersion = subscriptionOnUpdateFeatureWithVersion(pk, sk).subscribe({
      next: ({ value }: { value: GraphQLResult<{ onUpdateFeatureWithVersion: Feature }> }) => {
        if (value.data?.onUpdateFeatureWithVersion) {
          setIceBreak(value.data.onUpdateFeatureWithVersion)
        }
      },
      error: (e) => {
        alert(
          `リアルタイムのデータを取得できませんでした。ページを再読み込みしてください。\nエラー内容: ${e.error.errors[0].message}`
        )
      },
    })

    // コンポーネントのアンマウント時にサブスクリプションを停止。
    return () => {
      subscribeUpdateWithVersion.unsubscribe()
    }
  }, [pk, sk])

  return {
    iceBreak,
    iceBreakContents,
    isRefreshed,
    isLoading,
    isLeader,
    errors,
    handleCancelLeader: () => handleCancel({ isLeaderAction: true }),
    handleCancelMember: () => handleCancel({ isLeaderAction: false }),
    handleUpdateByLeader,
    iceBreakTheme,
    setIceBreakTheme,
    control,
    memberCount,
    minute,
    selectUserIds,
    isTimerEnd,
    setIsTimerEnd,
    isCanceling,
  }
}
