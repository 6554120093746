import * as React from 'react'

import { Modal, Paper } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useOnbHistory } from 'pages/onboarding/navigation/route'

import { ActionModalState } from '..'
import { ButtonNew as Button } from '../../../../../components/ButtonNew'

import IconClose from '../assets/close.svg'
import IconAlert from '../assets/Icons-48px-Alert.svg'
import * as constants from 'assets/constants'
import {OnbPages} from 'assets/pages'



type Props = {
  open: boolean
  teamId?: string
  userId?: string
  setModal: (state: ActionModalState) => void
  onDeleteFeedback?: () => void
}

export const DeleteFeedBack: React.FC<Props> = (props) => {
  const classes = useStyles()
  const history = useOnbHistory()

  const handleDeleteFeedback = () => {
    props.setModal('idle')
    if (props.onDeleteFeedback) {
      props.onDeleteFeedback()
    }
    history.push(OnbPages.MemberManageActions, {teamId: props.teamId, userId: props.userId})
  }

  return (
    <Modal open={props.open} className={classes.modal} disableAutoFocus={true}>
      <Paper className="__modalPaper">
        <img
          className={classes.img}
          src={IconClose}
          alt={'icon'}
          onClick={() => props.setModal('idle')}
        />
        <div className="__modalPaper__title">
          <img src={IconAlert} alt="icon" />
        </div>
        <div className={classes.title}>未達成に戻しますか？</div>
        <div className="__modalPaper__buttons">
          <Button
            body={'キャンセル'}
            onClick={() => props.setModal('idle')}
            noShadow={true}
            borderColor={constants.COLOR_ONBOARDING_GRAY_DARK}
            textColor={constants.COLOR_ONBOARDING_GRAY_DARK}
          />
          <Button body={'はい'} onClick={handleDeleteFeedback} noShadow={true} />
        </div>
      </Paper>
    </Modal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,

    '& .__modalPaper': {
      display: 'inline-block',
      position: 'relative',
      background: constants.COLOR_GRAY,
      padding: '24px 16px',
      width: '100%',
      maxWidth: 350,
      // height: '80vh',
      margin: '0 16px',
      maxHeight: '90vh',
      overflow: 'scroll',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_SMALL,
      },
      '&:focus': {
        outline: 'none',
      },

      '&__title': {
        color: constants.COLOR_MAIN,
        fontSize: '18px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 16,
      },
      '&__actions': {
        display: 'flex',
        justifyContent: 'space-between',
        '&__action': {
          //
          '&__icon': {
            width: '50px',
            height: '50px',
            background: 'lightgray',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '8px',
            boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

            '&.activeAction': {
              backgroundColor: constants.COLOR_MAIN_LIGHT,
            },
          },

          '&__memo': {
            marginTop: '4px',
            textAlign: 'center',
            fontSize: '12px',
          },
        },
      },

      '&__textField': {
        width: '100%',
        backgroundColor: constants.COLOR_WHITE,

        '& .MuiInputBase-root': {
          borderRadius: '0',
        },
      },

      '&__buttons': {
        display: 'flex',
        '& :first-child': {
          marginRight: '8px',
        },
        '& :last-child': {
          marginLeft: '8px',
        },
      },
    },
  },
  title: {
    marginBottom: 27,
    fontSize: 14,
    fontWeight: 'bold',
    color: constants.COLOR_ONBOARDING_MAIN,
    textAlign: 'center',
  },
  img: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
}))
