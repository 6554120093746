import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Button from '../../button'

import * as constants from 'assets/constants'

interface Props {
  textCode: 'utf-8' | 'sift-jis'
  template: 'action' | 'team-member'
}

export const TemplateDownloader = ({ textCode, template }: Props) => {
  const classes = useStyles()
  const isUtf8 = textCode === 'utf-8'
  const isCodeURL = isUtf8 ? '' : 'sjis-'

  const downloadCSV = () => {
    location.href = `/templates/${isCodeURL}onboarding-add-${template}.csv`
  }

  return (
    <Button
      theme="primary-new"
      icon="csv"
      title={`テンプレートダウンロード\n${isUtf8 ? 'MacOS用（UTF-8）' : 'Windows用（Shift-JIS）'}`}
      onPress={downloadCSV}
      className={classes.button}
    />
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      '&:hover': {
        backgroundColor: constants.COLOR_WHITE,
        opacity: 0.7,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
  { name: 'TemplateDownloader' }
)
