import React, { useContext } from 'react'

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { HistoryContext } from 'pages/ghost/contexts/history'

import { Flexbox, UsefulCard } from '../atoms'
import { GhostDetailLabel } from '../modules'

import { GhostReported } from './GhostReported'
import { GhostToolbar } from './GhostToolbar'

type Props = {
  basicInformation: React.ReactNode
  backgroundAndFeatures: React.ReactNode
  action: React.ReactNode
  reported: boolean
  ownStyles?: React.CSSProperties
  selectButton?: React.ReactNode
  found: number
}

type TabManager = 'basicInformation' | 'backgroundAndFeatures' | 'action' | 'reported'

export const GhostDetailBoard: React.FC<Props> = (props) => {
  const classes = useStyles()
  const [tabManager, setTab] = React.useState<TabManager>('basicInformation')

  const onClickTabButton = (tab: TabManager) => {
    if (!props.found) return // don't show other than basic info until team found the ghost.
    setTab(tab)
  }

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <GhostDetailLabel
            onClick={() => onClickTabButton('basicInformation')}
            clickable={tabManager !== 'basicInformation'}
          >
            基本情報
          </GhostDetailLabel>
          <GhostDetailLabel
            onClick={() => onClickTabButton('backgroundAndFeatures')}
            clickable={tabManager !== 'backgroundAndFeatures'}
          >
            出没経緯・特徴
          </GhostDetailLabel>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <GhostDetailLabel onClick={() => onClickTabButton('action')} clickable={tabManager !== 'action'}>
            対処法
          </GhostDetailLabel>

          <GhostDetailLabel onClick={() => onClickTabButton('reported')} clickable={tabManager !== 'reported'}>
            対話結果
          </GhostDetailLabel>
        </div>
      </div>
      <Flexbox>
        <UsefulCard backgroundColor="white" borderRadius="none" ownStyles={{ boxShadow: 'none', width: '100%' }}>
          <div className={classes.container}>
            <div style={tabManager !== 'basicInformation' ? { display: 'none' } : {}}>{props.basicInformation}</div>
            <div style={tabManager !== 'backgroundAndFeatures' ? { display: 'none' } : {}}>
              {props.backgroundAndFeatures}
            </div>
            <div style={tabManager !== 'action' ? { display: 'none' } : {}}>{props.action}</div>
            <div style={tabManager !== 'reported' ? { display: 'none' } : {}}>
              <ResearchBoard />
            </div>
          </div>
          {props.selectButton ? props.selectButton : <></>}
        </UsefulCard>
      </Flexbox>
    </>
  )
}

type ResearchBoardProps = {
  noFlex?: boolean
  noBalloonTitle?: boolean
  ballonMarginTop?: number
}

export const ResearchBoard = ({ noFlex, noBalloonTitle, ballonMarginTop }: ResearchBoardProps) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStylesResearch()
  const { historyList } = useContext(HistoryContext)
  const { detailId } = useContext(GhostContext)

  const [selectedResearch, setSelectedResearch] = React.useState<{ hstIdx: number; rptIdx: number }>({
    hstIdx: 0,
    rptIdx: 0,
  })

  const filteredByIdResearchList = React.useMemo(() => {
    const filteredList = historyList
      .filter((ghostConnection) => ghostConnection.history?.ghostId === detailId)
      .map((item) => item.research)

    // 作成日時順に並び替え
    for (let i = 0; i < filteredList.length; i++) {
      filteredList[i]?.first?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      filteredList[i]?.second?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      filteredList[i]?.feeling?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      filteredList[i]?.nextAction?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    }
    return filteredList
  }, [detailId, historyList])

  const dropdownOptions = React.useMemo(() => {
    const items: string[][] = [[]]
    if (filteredByIdResearchList.length === 0) {
      items.push([
        '1回目 発生した原因',
        '1回目 今後の対処法',
        '1回目 気づいたこと / 感じたこと',
        '1回目 明日から出来そうなアクション',
      ]) //dummy data for compile
      return items
    } else {
      for (let i = 0; i < filteredByIdResearchList.length; i++) {
        items[i] = []
        filteredByIdResearchList[i]?.first?.length && items[i].push(`${i + 1}回目 発生した原因`)
        filteredByIdResearchList[i]?.second?.length && items[i].push(`${i + 1}回目 今後の対処法`)
        filteredByIdResearchList[i]?.feeling?.length && items[i].push(`${i + 1}回目 気づいたこと / 感じたこと`)
        filteredByIdResearchList[i]?.nextAction?.length && items[i].push(`${i + 1}回目 明日から出来そうなアクション`)
      }
    }
    return items
  }, [filteredByIdResearchList])

  const selectedResearchList = React.useMemo(() => {
    return filteredByIdResearchList.map((item) => {
      if (!item) return null
      if (selectedResearch.rptIdx === 0) return item['first']
      else if (selectedResearch.rptIdx === 1) return item['second']
      // 強制進行によってファシリ単独のプレイだった場合、nextAction 選択時の rptIdx が 3 ではなく 2 になってしまうため
      else if (selectedResearch.rptIdx === 2 && item['feeling']?.length) return item['feeling']
      else if ((selectedResearch.rptIdx === 2 || selectedResearch.rptIdx === 3) && item['nextAction']?.length)
        return item['nextAction']
    })
  }, [filteredByIdResearchList, selectedResearch])

  const handleListSelect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    const { hstidx, rptidx } = event.currentTarget.dataset
    hstidx && rptidx && setSelectedResearch({ hstIdx: Number(hstidx), rptIdx: Number(rptidx) })
  }

  return (
    <div className={noFlex ? classes.researchContainerNoFlex : classes.researchContainer}>
      <GhostToolbar
        dataArray={dropdownOptions}
        listOnClick={handleListSelect}
        selectedResearch={selectedResearch}
        ownStyles={isMdUp ? { width: 300, margin: '0 24px 0 0' } : { margin: '0 0 16px' }}
        ownBoxStyles={{ color: constGhost.COLOR_BLACK3 }}
      >
        {dropdownOptions[selectedResearch.hstIdx][selectedResearch.rptIdx]}
      </GhostToolbar>
      <GhostReported
        researches={selectedResearchList[selectedResearch.hstIdx] || []}
        thumbUpIconBehavior={'onlyView'}
        modal={true}
        noBalloonTitle={noBalloonTitle}
        menuItem={dropdownOptions[selectedResearch.hstIdx][selectedResearch.rptIdx]}
        ownStyles={{ marginTop: ballonMarginTop }}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constGhost.COLOR_LIGHTGRAY3,
    boxSizing: 'border-box',
    padding: 8,
    marginBottom: 16,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 24,
    },
  },
  container: {
    marginBottom: 40,
    padding: '0 16px',
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  menuPaper: {
    width: 'calc(100% - 96px)',
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
}))

const useStylesResearch = makeStyles(
  (theme: Theme) => ({
    researchContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    researchContainerNoFlex: {
      alignItems: 'flex-start',
    },
  }),
  { name: 'ResearchBoard' }
)
