import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { TeamBuildingAction } from 'utils/generated'

interface Props {
  action?: TeamBuildingAction
  optional?: React.ReactNode
}

export const ActionDescriptions: React.FC<Props> = ({ action, optional }) => {
  const classes = useStyles()

  const isMaster = !action?.editable

  return (
    <dl className={classes.root}>
      <dt>実施する理由</dt>
      {isMaster ? (
        <dd
          dangerouslySetInnerHTML={{
            __html: action?.reason ?? '',
          }}
        />
      ) : (
        <dd>{action?.reason}</dd>
      )}
      <dt>実施する内容</dt>
      {isMaster ? (
        <dd
          dangerouslySetInnerHTML={{
            __html: action?.content ?? '',
          }}
        />
      ) : (
        <dd>{action?.content}</dd>
      )}
      <dt>推奨タイミング</dt>
      {isMaster ? (
        <dd
          dangerouslySetInnerHTML={{
            __html: action?.timing ?? '',
          }}
        />
      ) : (
        <dd>{action?.timing}</dd>
      )}

      {optional && optional}
    </dl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    fontSize: 14,

    '& > dt': {
      fontWeight: 'bold',
    },

    '& > dd': {
      marginTop: 10,
      marginLeft: 0,
    },

    '& > dd + dt': {
      marginTop: 24,
    },
  },
}))
