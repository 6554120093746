import * as React from 'react'
import { Link } from 'react-router-dom'

import Fade from '@mui/material/Fade'
import ListItemText from '@mui/material/ListItemText'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'

import { Title } from 'pages/teams/components/Title'
import { useTeams } from 'pages/teams/hooks'
import { Team, TeamStatusUsage } from 'utils/generated'

import { constants } from 'assets'

type Props = {
  teamId: string
}

export const TeamListMenu: React.FC<Props> = ({ teamId }) => {
  const theme = useTheme()
  const classes = useStyles()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  const { teams, nextToken, more } = useTeams()
  const inUseTeams = React.useMemo(() => teams.filter((t) => t.statusUsage === TeamStatusUsage.InUse), [teams])

  React.useEffect(() => {
    if (nextToken) more()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken])

  return (
    <div className={isMdUp ? classes.titleWrapperPC : ''}>
      <div onClick={handleOpenMenu}>
        <Title teamId={teamId} isOpen={Boolean(anchorEl)} isMdUp={isMdUp} />
      </div>
      <Menu
        currentTeamId={teamId}
        teams={inUseTeams}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        isMdUp={isMdUp}
      />
    </div>
  )
}

type MenuProps = {
  currentTeamId: string
  teams: Team[]
  anchorEl: HTMLElement | null
  handleCloseMenu: () => void
  isMdUp: boolean
}

const Menu: React.FC<MenuProps> = ({ isMdUp, currentTeamId, teams, anchorEl, handleCloseMenu }) => {
  const classes = useStyles()

  return (
    <MuiMenu
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
      elevation={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionComponent={Fade}
      disableAutoFocusItem
      className={isMdUp ? classes.pcMenu : classes.spMenu}
    >
      {teams.map((team, index) => (
        <MenuItem key={`${team.id}-${index}`} onClick={handleCloseMenu} component={Link} to={`/teams/${team.id}`}>
          <ListItemText
            disableTypography
            primary={team.name}
            className={`${classes.listItemText} ${team.id === currentTeamId ? classes.mainTextColor : ''}`}
          />
        </MenuItem>
      ))}
      {!isMdUp && (
        <MenuItem
          onClick={handleCloseMenu}
          component={Link}
          to={`/teams/${currentTeamId}/list?hidden=true`}
          className={classes.menuItemForTeamListLink}
        >
          <ListItemText disableTypography primary="チームリスト" className={classes.listItemText} />
        </MenuItem>
      )}
    </MuiMenu>
  )
}

const useStyles = makeStyles(
  {
    titleWrapperPC: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_TEAMBUILDING_TEXT,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'visible',
    },

    pcMenu: {
      marginTop: 4,
      '& .MuiMenu-paper': {
        width: 240,
        maxHeight: 'calc(100vh - 200px)',
        borderRadius: 8,
      },
    },
    spMenu: {
      marginTop: 2,
      '& .MuiMenu-paper': {
        width: '100%',
        maxHeight: 'calc(100vh - 200px)',
        borderRadius: 8,
      },
    },
    listItemText: {
      fontSize: 12,
      color: constants.TEXT_GRAY_DARK,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    mainTextColor: {
      color: constants.COLOR_MAIN_NEW,
    },
    menuItemForTeamListLink: {
      borderTop: 'solid 1px #E0E0E0',
    },
  },
  { name: 'TeamListMenu' }
)
