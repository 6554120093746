import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FieldError } from 'react-hook-form'

interface Props {
  error?: FieldError
}

export const FormError: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <>{props.error ? <small className={classes.text}>{props.error.message}</small> : <></>}</>
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    text: {
      marginTop: 8,
      display: 'block',
      color: 'red',
      fontSize: 14,
    },
  }),
  { name: 'FormError' }
)
