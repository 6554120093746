import React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { useForm, useFieldArray } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { ModalBuildingContext } from 'pages/teams/contexts/ModalBuildingContext'
import {
  mutationCreateTeamBuildingAction,
  mutationUpdateTeamBuildingAction,
  mutationCreateTeamBuildingActionDates,
  queryGetTeamBuildingAction,
  queryGetTeamBuildingActionDates,
  mutationRefreshTeamBuilding,
} from 'pages/teams/hooks/graphql'
import {
  TeamBuildingActionDate,
  TeamBuildingAction,
  TeamBuildingActionDateStatus,
  IndexGetTeamBuildingActionDates,
  TeamBuildingMember,
  Team,
  TeamBuildingStatus,
} from 'utils/generated'

import {
  Select,
  InputCard,
  ScheduleForm,
  ButtonContainer,
  FormLabel,
  FormError,
  InputText,
  InputTextarea,
  Button,
  BackToIndex,
  ActionDescriptions,
  SideBar,
  Header,
  Timer,
} from '../../../components'

import { constants } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = RouteComponentProps<{
  teamId: string
  actionId?: string
}>

export const TeamsToolsBuildingDashboardActionPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { route } = useHistory()
  const { search } = useLocation()

  const [teamBuildingAction, setTeamBuildingAction] = React.useState<TeamBuildingAction | null>(null)
  const [teamBuildingActionDates, setTeamBuildingActionDates] = React.useState<TeamBuildingActionDate[]>([])

  const getData = async (teamBuildingActionId: string) => {
    const fetchedTeamBuildingAction = await queryGetTeamBuildingAction({ id: teamBuildingActionId })

    if (fetchedTeamBuildingAction) {
      setTeamBuildingAction(fetchedTeamBuildingAction)
    }

    const fetchedTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
      id: teamBuildingActionId,
      index: IndexGetTeamBuildingActionDates.TeamBuildingActionId,
    })

    if (fetchedTeamBuildingActionDates) {
      setTeamBuildingActionDates(fetchedTeamBuildingActionDates?.items)
    }
  }

  React.useEffect(() => {
    if (props.match.params.actionId) {
      getData(props.match.params.actionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { currentTeam, loadedTeamBuilding, loadingTeam, teamBuilding } = React.useContext(HooksContext)
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBack = () => {
    const param = new URLSearchParams(search)
    const from = param.get('from')

    if (from) {
      route.push(from)
    } else {
      route.push(replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId: currentTeam?.id }))
    }
  }

  if (!loadedTeamBuilding || loadingTeam) {
    return <LoadingCircular loading={true} />
  }

  return (
    <SideBar>
      <div className={classNames([classes.container, teamBuilding?.status === 'COMPLETED' && classes.containerHeader])}>
        {teamBuilding?.status === 'COMPLETED' && <Header />}
        <main className={classes.main}>
          <div className={classes.headingWrap}>
            <BackToIndex onClick={() => handleBack()} />
            <h2 className={classes.heading}>{props.match.params.actionId ? 'アクション編集' : '新規アクション追加'}</h2>
            <div style={{ width: 40 }}></div>
          </div>

          {/* Edit */}
          {props.match.params.actionId && teamBuildingAction && teamBuildingActionDates.length > 0 && (
            <TeamsToolsBuildingDashboardActionFrom
              isEdit={true}
              currentTeam={currentTeam as Team}
              teamBuildingAction={teamBuildingAction}
              teamBuildingActionDates={teamBuildingActionDates}
              teamBuildingMembers={teamBuildingMembers}
              buildingStatus={teamBuilding?.status}
            />
          )}
          {/* Create */}
          {!props.match.params.actionId && !teamBuildingAction && teamBuildingActionDates.length === 0 && (
            <TeamsToolsBuildingDashboardActionFrom
              isEdit={false}
              currentTeam={currentTeam as Team}
              teamBuildingMembers={teamBuildingMembers}
              buildingStatus={teamBuilding?.status}
            />
          )}
        </main>
      </div>
    </SideBar>
  )
}

interface Input {
  name: string
  reason: string
  content: string
  timing: string
  assignedTeamMemberId: string
  dates: any[]
}

type FormProps = {
  isEdit: boolean
  currentTeam: Team
  teamBuildingMembers: TeamBuildingMember[]
  teamBuildingAction?: TeamBuildingAction
  teamBuildingActionDates?: TeamBuildingActionDate[]
  buildingStatus?: TeamBuildingStatus
}

const TeamsToolsBuildingDashboardActionFrom: React.FC<FormProps> = ({
  isEdit,
  teamBuildingMembers,
  teamBuildingAction,
  teamBuildingActionDates = [],
  currentTeam,
  buildingStatus,
}) => {
  const classes = useStyles()
  const { route } = useHistory()
  const { search } = useLocation()

  const param = new URLSearchParams(search)
  const from = param.get('from')

  const { onModal, onClose } = React.useContext(ModalBuildingContext)
  const { teamBuilding } = React.useContext(HooksContext)

  const openModal = () => {
    onModal(
      <>
        <h2 className={classes.modalHeading}>直前で誰かが更新しています。こちらの変更を優先して保存しますか？</h2>
        <div className={classes.modalButtonWrap}>
          <Button full onClick={() => submitModal()}>
            はい
          </Button>
          <Button full buttonType="secondary" onClick={() => cancelModal()}>
            いいえ
          </Button>
        </div>
      </>
    )
  }

  const isMasterAction = React.useMemo(() => {
    return isEdit && teamBuildingAction && !teamBuildingAction.editable
  }, [isEdit, teamBuildingAction])

  React.useEffect(() => {
    if (teamBuildingActionDates.length === 0) {
      append({ date: null })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectMemberOptions = React.useMemo(() => {
    if (!teamBuildingMembers) {
      return []
    } else {
      return teamBuildingMembers.map((member) => ({
        value: member.id,
        label: member.teamMember?.username || '',
      }))
    }
  }, [teamBuildingMembers])

  const defaultValues = React.useMemo(() => {
    if (teamBuildingAction) {
      return {
        name: teamBuildingAction.name,
        reason: teamBuildingAction.reason,
        content: teamBuildingAction.content,
        timing: teamBuildingAction.timing,
        assignedTeamMemberId: teamBuildingAction.assignedTeamMemberId,
        dates: teamBuildingActionDates.map((item) => ({ date: item.date })),
      }
    } else {
      return {}
    }
  }, [teamBuildingAction, teamBuildingActionDates])

  const { register, control, handleSubmit, errors, getValues, trigger } = useForm<Input>({
    mode: 'onChange',
    defaultValues,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dates',
  })

  const onSubmit = async (data: Input) => {
    if (!currentTeam) {
      return
    }

    if (isEdit) {
      if (!teamBuildingAction) {
        return
      }

      const fetchedTeamBuildingAction = await queryGetTeamBuildingAction({ id: teamBuildingAction.id })
      const isUpdatedByOtherUser = teamBuildingAction.updatedAt !== fetchedTeamBuildingAction?.updatedAt

      if (isUpdatedByOtherUser) {
        openModal()
      } else {
        update(data)
      }
    } else {
      const input = {
        editable: isMasterAction ? 0 : 1,
        teamId: currentTeam.id,
        name: data.name,
        reason: data.reason,
        content: data.content,
        timing: data.timing,
        assignedTeamMemberId: data.assignedTeamMemberId,
        assignedTeamMemberName:
          teamBuildingMembers.find((member) => member.id === data.assignedTeamMemberId)?.teamMember?.username || '',
      }

      const createdAction = await mutationCreateTeamBuildingAction({ input })

      if (createdAction) {
        const datesInput = {
          teamBuildingActionId: createdAction.id,
          dates: data.dates.map((item) => ({
            teamBuildingActionId: createdAction.id,
            date: item.date,
            status: TeamBuildingActionDateStatus.Created,
          })),
        }

        await mutationCreateTeamBuildingActionDates({ input: datesInput })

        const path = replacePathParams(Pages.TeamsToolBuildingDashboardActionCompleted, {
          teamId: currentTeam.id,
          actionId: createdAction.id,
        })

        route.push(`${path}${from ? `?from=${from}` : ''}`)
      }
    }

    await mutationRefreshTeamBuilding({ input: { id: currentTeam.id } })
  }

  const update = async (data: Input) => {
    if (!teamBuildingAction) {
      return
    }

    const input = {
      id: teamBuildingAction.id,
      name: data.name,
      reason: data.reason,
      content: data.content,
      timing: data.timing,
      assignedTeamMemberId: data.assignedTeamMemberId,
      assignedTeamMemberName:
        teamBuildingMembers.find((member) => member.id === data.assignedTeamMemberId)?.teamMember?.username || '',
    }

    const updatedAction = await mutationUpdateTeamBuildingAction({ input })

    if (updatedAction) {
      const currentTeamBuildingActionDates = await queryGetTeamBuildingActionDates({
        id: teamBuildingAction.id,
        index: IndexGetTeamBuildingActionDates.TeamBuildingActionId,
      })

      if (!currentTeamBuildingActionDates?.items) {
        return
      }

      const deleteRequests = currentTeamBuildingActionDates.items
        .filter((item) => {
          const currentIds = data.dates.map(({ date }) => `${updatedAction.id}-${date}`)
          return !currentIds.includes(item.id)
        })
        .map((item) => ({
          teamBuildingActionDateId: item.id,
        }))
      const createRequests = data.dates
        .filter(({ date }) => {
          const oldIds = currentTeamBuildingActionDates.items.map(({ id }) => id)
          return !oldIds.includes(`${updatedAction.id}-${date}`)
        })
        .map((item) => ({
          teamBuildingActionId: updatedAction.id,
          date: item.date,
          status: TeamBuildingActionDateStatus.Created,
        }))
      const datesInput = {
        teamBuildingActionId: updatedAction.id,
        dates: [...deleteRequests, ...createRequests],
      }

      await mutationCreateTeamBuildingActionDates({ input: datesInput })

      const path = replacePathParams(Pages.TeamsToolBuildingDashboardActionCompleted, {
        teamId: currentTeam.id,
        actionId: updatedAction.id,
      })
      route.push(`${path}${from ? `?from=${from}` : ''}`)
    }
  }

  const submitModal = () => {
    const data = getValues()

    update(data)
    onClose()
  }

  const cancelModal = () => {
    onClose()

    if (!teamBuildingAction) {
      return
    }

    const path = replacePathParams(Pages.TeamsToolBuildingDashboardActionPreview, {
      teamId: currentTeam?.id,
      actionId: teamBuildingAction.id,
    })

    if (from) {
      route.push(`${path}?from=${from}`)
    } else {
      route.push(path)
    }
  }

  const handleBack = () => {
    const param = new URLSearchParams(search)
    const from = param.get('from')

    if (from) {
      route.push(from)
    } else {
      route.push(replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId: currentTeam?.id }))
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isMasterAction ? (
          <>
            <div className={classes.actionTitleWrap}>
              <h1>{teamBuildingAction?.name}</h1>
            </div>
            <InputCard>
              <ActionDescriptions action={teamBuildingAction} />
            </InputCard>
          </>
        ) : (
          <>
            <div className={classes.formItem}>
              <FormLabel>アクション名</FormLabel>
              <InputText
                ref={register({ required: 'アクション名を入力してください' })}
                name="name"
                placeholder="アクション名を入力してください"
                style={{ width: '100%', borderRadius: 4 }}
              />
              <FormError error={errors.name} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>実施する理由</FormLabel>
              <InputTextarea
                ref={register({ required: '実施する理由を入力してください' })}
                name="reason"
                placeholder="アクションを実施する理由を入力してください"
              />
              <FormError error={errors.reason} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>実施する内容</FormLabel>
              <InputTextarea
                ref={register({ required: '実施する内容を入力してください' })}
                name="content"
                placeholder="アクションを実施する内容を入力してください"
              />
              <FormError error={errors.content} />
            </div>
            <div className={classes.formItem}>
              <FormLabel>推奨タイミング</FormLabel>
              <InputTextarea
                ref={register({ required: '推奨タイミングを入力してください' })}
                name="timing"
                placeholder="推奨タイミングを入力してください"
              />
              <FormError error={errors.timing} />
            </div>
          </>
        )}

        <div className={classes.formItem}>
          <FormLabel>終了日までの実施予定回数</FormLabel>
          {!!fields.length && (
            <div style={{ marginTop: 16 }}>
              <InputCard>
                <ScheduleForm
                  fields={fields}
                  getValues={getValues}
                  errors={errors}
                  trigger={trigger}
                  control={control}
                  remove={remove}
                  append={append}
                />
              </InputCard>
            </div>
          )}
        </div>
        <div className={`${classes.formItem} ${classes.mb}`}>
          <FormLabel>推進責任者</FormLabel>
          <Select
            name={'assignedTeamMemberId'}
            options={selectMemberOptions}
            ref={register({ required: '推進責任者を選択してください' })}
          />
          <FormError error={errors.assignedTeamMemberId} />
        </div>
        {teamBuilding?.status !== 'COMPLETED' && teamBuilding?.timeQ5Actions && (
          <Timer from={teamBuilding.timeQ5Actions} time={180} container marginBottom={50} />
        )}
        <div style={{ margin: '40px -16px 0' }}>
          <ButtonContainer
            marginTopPcLayout={0}
            buildingStatus={buildingStatus}
            nowWrap={true}
            buttons={[
              <Button key={`button-1`} type="submit">
                {isEdit ? '保存' : '追加'}
              </Button>,
              <Button key={`button-2`} buttonType="secondary" onClick={() => handleBack()}>
                キャンセル
              </Button>,
            ]}
          />
        </div>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modalButtonWrap: {
    marginTop: '24px',

    '& > button + button': {
      marginTop: '16px',
    },
  },
  modalHeading: {
    margin: 0,
    fontSize: '14px',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    textAlign: 'center',
  },

  actionTitleWrap: {
    margin: '24px 0',

    '& > h1': {
      textAlign: 'center',
      fontSize: 16,
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },

  formItem: {
    marginTop: 24,
  },
  mb: {
    marginBottom: 100,
  },
  headingWrap: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '16px',
    fontWeight: 'bold',
  },

  root: {},
  container: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '0 16px 16px',
    backgroundColor: '#F9F9F9',
    color: '#333',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '0 70px 16px',
    },
  },
  containerHeader: {
    paddingTop: 48,
  },
  main: {
    margin: 'auto',
    width: '100%',
    paddingBottom: '100px',
    maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
    [theme.breakpoints.up('md')]: {
      maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
    },
  },
}))
