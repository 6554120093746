import * as React from 'react'

import { Modal, Theme } from '@mui/material';
import { Checkbox } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import Button from 'components/Button'

import close from './assets/close.svg'
import talkers from './assets/talkers.svg'
import * as constants from 'assets/constants'

interface IOwnProps {
  open: boolean
  handleCloseNotice: () => void
  handleCheck: () => void
  isChecked: boolean
}

type Props = IOwnProps

export const CheckDailyModal: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <Modal
      open={props.open}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
      }}
    >
      <div className={classes.main}>
        <div className={classes.card}>
          <div className={classes.closeIcon}>
            <img src={close} alt="close" onClick={() => props.handleCloseNotice()} />
          </div>
          <div className={classes.icon}>
            <img src={talkers} alt="talkers" />
          </div>
          <div className={classes.title}>毎日デイリーチェックを行いましょう</div>
          <div className={classes.textWrapper}>
            デイリーチェックでは自分の今の気分をチームに共有し、今の自分の状態を把握してもらいましょう。必要があれば、誰かに相談してみよう！
          </div>
          <div className={classes.checkboxWrapper}>
            <Checkbox
              checked={props.isChecked}
              size="small"
              disableRipple
              className={classes.checkColor}
              onClick={() => props.handleCheck()}
            />
            <span className={classes.checkboxText}>次回から表示しない</span>
          </div>
          <Button
            body={'OK'}
            fullWidth
            onClick={props.handleCloseNotice}
            style={{
              borderRadius: 8,
              borderColor: constants.COLOR_ONBOARDING_MAIN,
              backgroundColor: '#FFFFFF',
              boxShadow: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              textAlign: 'center',
              color: constants.COLOR_ONBOARDING_MAIN,
              margin: '32px 0 16px',
              height: 48,
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  checkColor: {
    '& .MuiSvgIcon-root': {
      color: constants.COLOR_MAIN_NEW,
    },
    '&.MuiIconButton-colorSecondary:hover': {
      backgroundColor: constants.COLOR_WHITE,
    },
  },
  checkboxWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxText: {
    fontSize: 12,
    color: constants.COLOR_GRAY_DARK,
  },
  textWrapper: {
    padding: '20px 0',
    fontSize: 12,
  },

  card: {
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 10,
    padding: '16px 16px 0 16px',
    width: '350px',
    boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'end',
    cursor: 'pointer',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },

  img: {
    display: 'flex',
    justifyContent: 'center',
  },

  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '16px 0 0',
    textAlign: 'center',
    color: constants.COLOR_ONBOARDING_MAIN,
  },

  sentence: {
    fontSize: 14,
    fontWeight: 300,
    padding: '16px 0 0',
    whiteSpace: 'pre-wrap',
  },
}))
