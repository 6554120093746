import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    color: '#3B3B3B',
    borderLeft: `dotted 1px ${constants.COLOR_MAIN}`,
    paddingLeft: '24px',
    fontSize: '12px',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: '12px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  count: number
}

// -----------------------------
// Component
// -----------------------------
export const Answer = (props: IProps) => {
  const classes = useStyles()
  return <span className={classes.root}>回答{props.count}件</span>
}
