import { GraphQLResult } from '@aws-amplify/api'

interface ApiError {
  errors?: {
    message: string
    errorType?: string
  }[]
  [key: string]: unknown
}

export const handleError = (
  e: any,
  setErrors: React.Dispatch<React.SetStateAction<Error[] | undefined>>,
  alertMessage?: string
) => {
  console.error(e)
  setErrors(e.errors)
  if (alertMessage) {
    alert(`${alertMessage}\nエラー内容: ${e.errors?.[0]?.message || '不明なエラー'}`)
  }
}

// e is ApiError: 型ガード (Type Guard) によるチェックを行う関数
export const isApiError = (e: unknown): e is ApiError =>
  typeof e === 'object' && e !== null && 'errors' in e && Array.isArray((e as ApiError).errors)

export const ERROR_MESSAGES = {
  unexpected: '予期しないエラー形式が検出されました。サポートチームに連絡するか、もう一度お試しください。',
  dataUpdate:
    'データ更新の処理が混雑しています。時間を置いて再度お試しください。\n解決しない場合は、ページを再読み込みしてください。',
}

export const DynamoDB_ERRORS = {
  conditionalCheck: 'ConditionalCheckFailedException',
}

// GraphQLResult型のエラープロパティをチェックするヘルパー関数
export const isErrorWithGraphQLErrors = (error: any): error is { errors?: GraphQLResult['errors'] } => {
  return error && Array.isArray(error.errors)
}
