import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constGhost from 'pages/ghost/assets/constGhost'
import {
  BackResponsiveCard,
  GrayBackGround,
  ButtonForGhost,
  Font,
  Imgcard,
  LeadText,
  Flexbox,
} from 'pages/ghost/components/atoms'
import { ButtonContainer, ModalConfirmForCommon, WidthControlContainer } from 'pages/ghost/components/modules'
import { WaitingContainer } from 'pages/ghost/components/modules/WaitingContainer'
import { ProgressBoard, LoadingIndicator } from 'pages/ghost/components/organisms'
import { GhostMemberRole, GhostProgress, GhostTeam } from 'utils/generated'

import checkedIcon from '../../assets/svg/checkedIcon.svg'
import { GhostContext } from '../../contexts/ghost'
import { ghostTeamContext } from '../../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'
import { HistoryContext } from '../../contexts/history'
import { PageManagerContext } from '../../contexts/pageManger'
import { ResearchContext } from '../../contexts/research'
import { CommandContext } from '../../service/commands'
import { ForceProceed, useBreakCtl } from '../Break'

type Props = {}
type TeamKeys = keyof GhostTeam

export const Complete: React.FC<Props> = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { team, updateGhostTeam, loading: loadingTeam } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList, updateMemberPage, renewTeamMemberList } = useContext(ghostTeamMemberContext)
  const { currentHistory } = useContext(HistoryContext)
  const { loading } = useContext(ResearchContext)
  const { runFromCompletePage, terminateGame, forceProceed } = useContext(CommandContext)
  const { resetSelectedGhosts } = useContext(GhostContext)
  const { completePageManage, jumpTo, reportCategory } = useContext(PageManagerContext)
  const {
    toggleModal: proceedToggleModal,
    open: proceedOpen,
    handleProceed,
    stuckMembers,
  } = useBreakCtl(
    team,
    teamMember ? teamMember : undefined,
    teamMemberList,
    reportCategory,
    updateGhostTeam,
    updateMemberPage,
    renewTeamMemberList
  )
  const isSmUp = useCustomMediaQuery('up', 'sm')

  const nextProgressTimer = (): { progress: GhostProgress | null; timer: TeamKeys | null } => {
    switch (team?.progress) {
      case GhostProgress.S1DoneRandomAnswers:
        return { progress: GhostProgress.S2Started, timer: 'timerS2Started' }
      case GhostProgress.S2DoneRandomAnswers:
        return { progress: GhostProgress.S3Started, timer: 'timerS3Started' }
      case GhostProgress.S3DoneRandomAnswers:
        return { progress: GhostProgress.S4Started, timer: 'timerS4Started' }
      default:
        return { progress: null, timer: null }
    }
  }

  const isAdminOrLeader = [GhostMemberRole.Leader, GhostMemberRole.Admin].includes(
    teamMember?.role || GhostMemberRole.Member
  )
  //delete  admin === true  later

  const step = ['first', 'second', 'step4', 'third'].findIndex((item) => item === completePageManage(teamMember)) + 1

  const gotoNextPage = async () => {
    if (!(teamMember && team)) return

    const { progress, timer } = nextProgressTimer()
    if (!progress || !timer) return
    await updateGhostTeam({
      input: {
        id: teamId,
        progress,
        [timer]: new Date().toISOString(),
      },
    })

    await runFromCompletePage(team, teamMember, 'idle')
  }

  const toHome = () => {
    resetSelectedGhosts()
    team && terminateGame()
    jumpTo(`/teams/${team?.masterTeamId}`)
  }

  const [open, setOpen] = useState(false)

  const toggleModal = () => setOpen((prev) => !prev)

  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    // when members are reached  at page 20(Last Page),deleteGhostTeam for not remaining ghostTeam without click toHome button
    if (completePageManage(teamMember) !== 'third' || !team) return
    const isMembersFinished = teamMemberList.every((item) => completePageManage(item) === 'third')
    setIsMounted(true)
    if (!isMembersFinished || isMounted) return
    terminateGame()
  }, [completePageManage, teamMember, teamMemberList, terminateGame, team, isMounted])

  const mainButton = (
    <ButtonForGhost
      key={'button-1'}
      buttonBodyColor={'green'}
      bodyText={step === 4 ? 'HOMEへ' : '次へ'}
      onClick={step === 4 ? toggleModal : gotoNextPage}
      isResizeBasic
    />
  )

  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard>
          <WidthControlContainer>
            <Font
              fontSize={16}
              bold
              color={constGhost.COLOR_MAIN_NEW}
              ownStyles={{
                textAlign: 'center',
                letterSpacing: 0.8,
              }}
            >
              {step === 1 ? 'オバケ選択完了' : step === 2 ? 'メンバーが見かけたオバケ確認完了' : 'オバケ対話終了'}
            </Font>
            <Imgcard
              src={checkedIcon}
              alt={'checkedIcon'}
              ownStyles={{ margin: step !== 4 ? '24px 0 0' : '24px 0 24px' }}
            />
            {step === 1 || step === 2 || step === 3 ? (
              <ProgressBoard step={step + 1} />
            ) : (
              <>
                {!loading && currentHistory && currentHistory.ghostId ? (
                  <LeadText ownStyles={{ textAlign: isSmUp ? 'center' : 'left' }}>
                    おつかれさまでした。オバケ対話が終了しました。
                  </LeadText>
                ) : (
                  <LoadingIndicator snapshot={{ vars: currentHistory }} />
                )}
              </>
            )}
            {!loadingTeam && (teamMember?.role === GhostMemberRole.Leader || step === 4) ? (
              <>
                {/* ButtonContainer のスペースを確保 */}
                <Flexbox ownStyles={{ height: 156 }}></Flexbox>
                <ButtonContainer fixed buttons={[mainButton]} />
              </>
            ) : (
              <>
                <WaitingContainer />
              </>
            )}
            <ForceProceed
              onClick={proceedToggleModal}
              open={proceedOpen}
              handleProceed={() => handleProceed(forceProceed)}
              stuckMembersCount={stuckMembers.length}
            />
          </WidthControlContainer>
        </BackResponsiveCard>
      </GrayBackGround>
      <ModalConfirmForCommon
        open={open}
        handleModalChange={toggleModal}
        firstLine={isAdminOrLeader ? '結果を保存してゲームを終了します。' : 'ゲームを終了します。'}
        secondLine="よろしいですか？"
        btnFirstLine="はい"
        btnSecondLine="キャンセル"
        toHome={toHome}
      />
    </>
  )
}
