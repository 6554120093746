import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import CheckIcon from 'pages/teams/pages/_tools/review/assets/check.svg'

import { ContentHead } from '../../review/components/generic/ContentHead'

export const Completed: React.FC = () => {
  const classes = useStyles()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <ContentHead title="アイスブレイクは以上です" subtitle="お疲れ様でした！" />
      <img height={80} className="check" alt="check" src={CheckIcon} />
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
      height: '100%',
      padding: '40px 0',
      ' & .check': {
        display: 'block',
        margin: '80px auto',
      },
    },
  }),
  { name: 'Completed' }
)
