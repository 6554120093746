import React, { useState, useCallback, useRef } from 'react'

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Fade from '@mui/material/Fade'
import Popper from '@mui/material/Popper'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton } from 'components/NoStyleButton'
import { periodList, PeriodList } from 'pages/onboarding/utils/ranking'

import arrowDown from '../assets/arrow-down.svg'
import arrowUp from '../assets/arrow-up.svg'
import check from '../assets/check.svg'
import * as constants from 'assets/constants'

export const PeriodSelectBloc = {
  useAdapter: () => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const labels = {
      [periodList.lastWeek]: '直近1週間',
      [periodList.yesterday]: '昨日',
      [periodList.lastMonth]: '1ヶ月',
      [periodList.entire]: '全期間',
    }
    const lists = [periodList.lastWeek, periodList.yesterday, periodList.lastMonth, periodList.entire] as PeriodList[]

    const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [])

    const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return
      }
      setOpen(false)
    }, [])

    return { open, setOpen, anchorRef, labels, lists, handleToggle, handleClose }
  },
}

type Props = {
  period: PeriodList
  setPeriod: React.Dispatch<React.SetStateAction<PeriodList>>
}

export const PeriodSelect = ({ period, setPeriod }: Props) => {
  const classes = useStyles()
  const { open, setOpen, anchorRef, labels, lists, handleToggle, handleClose } = PeriodSelectBloc.useAdapter()

  const handleClickList = useCallback(
    (list: PeriodList) => {
      setPeriod(list)
      setOpen(false)
    },
    [setPeriod, setOpen]
  )

  return (
    <div className={classes.container}>
      <NoStyleButton className={classes.button} onClick={handleToggle} ref={anchorRef}>
        <div className={classes.selection}>
          <span>{labels[period]}</span>
          <div className={classes.arrowIcon}>
            <img src={open ? arrowUp : arrowDown} alt="" />
          </div>
        </div>
      </NoStyleButton>

      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps}>
              <div className={classes.selectionsWrapper} style={{ width: anchorRef.current?.clientWidth }}>
                <ul>
                  {lists.map((list) => (
                    <li key={list} role="menuitem" tabIndex={0} onClick={() => handleClickList(list)}>
                      {period === list && <img src={check} alt="" />}
                      {labels[list]}
                    </li>
                  ))}
                </ul>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      width: 200,
      height: 40,
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    button: {
      width: '100%',
      height: '100%',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
    },
    selection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 14px',
    },
    arrowIcon: {
      width: 12,
      height: 6,
      '& img': {
        width: '100%',
        verticalAlign: 'top',
      },
    },
    selectionsWrapper: {
      marginTop: 2,
      backgroundColor: constants.COLOR_WHITE,
      borderRadius: 8,
      boxShadow: '0px 3px 6px #00000029',
      '& ul': {
        margin: 0,
        padding: '8px 0',
        listStyle: 'none',
      },
      '& li': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 40,
        paddingLeft: 36,
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fafafa',
        },
      },
      '& img': {
        position: 'absolute',
        top: 13,
        left: 14,
        width: 13,
      },
    },
  }),
  { name: 'PeriodSelect' }
)
