import * as React from 'react'

import { Theme } from '@mui/material';
import { Button } from '@mui/material'
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../../../assets/constants'

interface IOwnProps {
  bgColor?: string
  borderColor?: string
  textColor?: string
  fullWidth?: boolean
  height?: number
  onClick?: any
  submit?: boolean
  body: any
  disabled?: boolean
  square?: boolean
  noShadow?: boolean
  style?: React.CSSProperties
  loading?: boolean
  fontSize?: number
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={!props.loading ? props.onClick : undefined}
      type={props.submit ? 'submit' : 'button'}
      style={{
        backgroundColor: props.disabled ? constants.COLOR_GRAY_LIGHT : props.bgColor ?? constants.COLOR_BLACK,
        border: props.borderColor ? `1px solid ${props.borderColor}` : '1px solid transparent',
        color: props.textColor || constants.COLOR_WHITE,
        width: props.fullWidth ? '100%' : '',
        height: props.height ? props.height + 'px' : '',
        borderRadius: props.square ? '8px' : '9999px',
        boxShadow: props.noShadow ? 'none' : undefined,
        fontSize: props.fontSize ? props.fontSize + 'px' : undefined,
        ...props.style,
        opacity: !props.loading ? 1 : 0.3,
      }}
      disabled={props.disabled}
    >
      {props.body}
    </Button>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    button: {
      minWidth: '100px',
      padding: '8px',
      fontWeight: 'bold',
    },
  })

export default withStyles(useStyles)(Index)
