import dayjs from 'dayjs'

import { OnboardingTeamMember, OnboardingTeamMemberRole, Maybe } from 'utils/generated'

export const periodList = {
  lastWeek: 'lastWeek',
  yesterday: 'yesterday',
  lastMonth: 'lastMonth',
  entire: 'entire',
} as const
export type PeriodList = keyof typeof periodList

export const periodDate = {
  [periodList.lastWeek]: dayjs(new Date()).add(-1, 'week'),
  [periodList.yesterday]: dayjs(new Date()).add(-1, 'day').format('YYYY-MM-DD'),
  [periodList.lastMonth]: dayjs(new Date()).add(-1, 'month'),
}

export const categories = { ownLikes: 'ownLikes', ownPosts: 'ownPosts',ownActions: 'ownActions'  } as const
export type Categories = keyof typeof categories

export type SrcRanking = (OnboardingTeamMember & {
  [categories.ownActions]: []
  [categories.ownPosts]: []
  [categories.ownLikes]: []
})[]

export type OrganizedRanking = RankingItem[]
export type RankingItem = {
  id: string
  userId: string
  src: Maybe<string> | undefined
  name: string
  role: OnboardingTeamMemberRole | ''
  count: number
  barWidth: number
  rank: number
}

export const formatDate = (date: string) => {
  return date.split('T')[0].replace(/-/g, '.');
}
