import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'

import 'dayjs/locale/ja'

type Props = {
  name: string
  value?: string | number | Date
  onChange: () => void
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}
type StyleSubProps = {
  readOnly?: boolean
}

export const FormDate: React.FC<Props & StyleProps & StyleSubProps> = ({
  name,
  value,
  onChange,
  readOnly = false,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles, readOnly })
  const defaultDate = value ? dayjs(new Date(value)) : null

  const CustomOpenPickerIcon = () => <img src={CalendarSvg} alt="calendar" className={classes.icon} />

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DatePicker
        name={name}
        defaultValue={defaultDate ? dayjs(defaultDate) : undefined}
        onChange={onChange}
        readOnly={readOnly}
        format="YYYY/MM/DD"
        desktopModeMediaQuery="@media (min-width:0px)"
        slots={{
          openPickerIcon: CustomOpenPickerIcon,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            margin: 'normal',
            className: classes.main,
            placeholder: '', // プレースホルダーを空に設定
          },
          popper: {
            className: classes.popover,
            placement: 'bottom',
          },
          desktopPaper: {
            sx: {
              borderRadius: '8px',
            },
          },
        }}
        className={classes.main}
      />
    </LocalizationProvider>
  )
}

const useStyles = makeStyles({
  main: ({ ownStyles, readOnly }: StyleProps & StyleSubProps) => ({
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: readOnly ? constants.COLOR_ONBOARDING_WHITE : '',
    ...ownStyles,

    '& .MuiFormLabel-root': {
      color: constants.COLOR_MAIN,
    },

    '& .MuiInputBase-root': {
      paddingRight: 0,
      borderRadius: 8,
      border: '1px solid ' + constants.COLOR_ONBOARDING_GRAY_LIGHT,
      height: 42,
    },
    '& .MuiInputBase-input': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      padding: 0,
      marginLeft: 16,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: `0 !important`,
    },
    '& .MuiButtonBase-root': {
      color: `${constants.COLOR_MAIN} !important`,
      padding: 8,
    },
    '& .MuiFormHelperText-root': {
      display: 'none',
    },
    '& .MuiInputAdornment-root': {
      marginRight: 12,
    },
  }),
  popover: {
    '& .MuiPickersDay-today': {
      color: constants.COLOR_ONBOARDING_MAIN,
      border: 'none',
      boxShadow: 'none',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      color: constants.TEXT_WHITE,
    },
    '& .MuiPickersYear-yearButton.Mui-selected': {
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: '#fff',
    },
  },
  icon: {
    padding: 0,
  },
})
