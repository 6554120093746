// This compoenent is based on index.tsx but modified some redundant parts.
// Consider migrating to this later on.

import * as React from 'react'

import { TextField, Theme, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import MagnifierSvg from './assets/magnifier.svg'
import * as constants from 'assets/constants'

interface Props {
  placeholder?: string
  borderColor?: string
  borderRadius?: number
  iconMarginLeft?: number
  iconMarginRight?: number

  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

// this compoenent holds its input value,
// and set the search query when users click on serach button(text).
export const SearchBar: React.FC<Props> = ({
  placeholder,
  borderColor,
  borderRadius,
  iconMarginLeft,
  iconMarginRight,
  setSearchQuery, // finally set the query and items are gonna be filtered
}) => {
  const [inputVal, setInputVal] = React.useState('')

  // when users clear out input, remove filter.
  React.useEffect(() => {
    inputVal === '' && setSearchQuery('')
  }, [inputVal, setSearchQuery])

  const classes = useStyles()

  return (
    (<div
      className={classes.root}
      style={{
        border: borderColor ? `1px solid ${borderColor}` : 'none',
        borderRadius: borderRadius ?? 0,
      }}
    >
      <TextField
        variant="standard"
        type="search"
        className={classes.input}
        placeholder={placeholder ?? ''}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: 0 }}>
              <img
                src={MagnifierSvg}
                alt={'magnifier'}
                style={{
                  cursor: 'pointer',
                  marginLeft: iconMarginLeft ?? 0,
                  marginRight: iconMarginRight ?? 0,
                }}
              />
            </InputAdornment>
          ),

          endAdornment: (
            <InputAdornment position="end">
              {inputVal ? (
                <span
                  className={classes.button}
                  onClick={() => {
                    setSearchQuery(inputVal)
                  }}
                >
                  検索
                </span>
              ) : (
                <span />
              )}
            </InputAdornment>
          ),
        }} />
    </div>)
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 42,
    backgroundColor: constants.COLOR_WHITE,
    display: 'flex',
    alignItems: 'center',
    '& input[type="search"]::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
  },
  input: {
    width: '100%',
    fontSize: 14,
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  button: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    marginRight: 6,
    padding: 10,
  },
}))
