import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'
import { RouteComponentProps } from 'react-router'
import { Waypoint } from 'react-waypoint'

import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useTeamBuildingActionDates } from 'pages/teams/hooks/teamBuildingActionDates'
import { TeamBuildingActionDate, TeamBuildingActionDateStatus } from 'utils/generated'

import { Calendar, Header, UserIcon } from '../../components'

import { EditEndAt } from './components'

import { constants } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = RouteComponentProps<{ teamId: string; tab?: DashboardTab }>

enum DashboardTab {
  List = 'list',
  Calendar = 'calendar',
}

export const TeamsToolsBuildingDashboardPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { pathname, search } = useLocation()
  const { route } = useHistory()
  const query = new URLSearchParams(search)
  const defaultTab = () => {
    switch (query.get('tab')) {
      case DashboardTab.List:
        return DashboardTab.List
      case DashboardTab.Calendar:
        return DashboardTab.Calendar
      default:
        return DashboardTab.List
    }
  }

  const { currentTeam, teamBuilding, loadedTeamBuilding: loaded, isAdmin, isLeader } = React.useContext(HooksContext)
  const isAdminOrLeader = (isAdmin || isLeader) ?? false
  // teamBuildingActionDates for List
  const {
    teamBuildingActionDates: listTeamBuildingActionDates,
    getTeamBuildingActionDates: getListTeamBuildingActionDates,
    nextToken: listNextToken,
    loading: listLoading,
  } = useTeamBuildingActionDates(currentTeam?.id)

  const onEnter = React.useCallback(() => {
    if (!listLoading && listNextToken) {
      getListTeamBuildingActionDates({ nextToken: listNextToken })
    }
  }, [listLoading, listNextToken, getListTeamBuildingActionDates])

  React.useEffect(() => {
    if (currentTeam) {
      getListTeamBuildingActionDates({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam])

  // teamBuildingActionDates for Calendar
  const {
    teamBuildingActionDates: calendarTeamBuildingActionDates,
    getTeamBuildingActionDates: getCalendarTeamBuildingActionDates,
    loading: calendarLoading,
  } = useTeamBuildingActionDates(currentTeam?.id)

  React.useEffect(() => {
    if (currentTeam) {
      const startDate = dayjs().startOf('month').format('YYYY-MM-DD')
      const endDate = dayjs().endOf('month').format('YYYY-MM-DD')
      getCalendarTeamBuildingActionDates({ startDate, endDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam])

  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs>(dayjs())

  const selectedDateTeamBuildingActionDates = React.useMemo(() => {
    return calendarTeamBuildingActionDates.filter((item) => item.date === selectedDate.format('YYYY-MM-DD'))
  }, [selectedDate, calendarTeamBuildingActionDates])

  const onSelectDate = (date: dayjs.Dayjs) => {
    setSelectedDate(date)
  }

  const onChangeYearMonth = (date: dayjs.Dayjs) => {
    const startDate = date.startOf('month').format('YYYY-MM-DD')
    const endDate = date.endOf('month').format('YYYY-MM-DD')
    getCalendarTeamBuildingActionDates({ startDate, endDate }, true)
  }

  const [tab, setTab] = React.useState<DashboardTab>(defaultTab())
  const onClickTab = (value: DashboardTab) => {
    if (currentTeam?.id) {
      route.push(
        `${replacePathParams(Pages.TeamsToolBuildingDashboard, {
          teamId: currentTeam?.id,
        })}?tab=${value}`
      )
      setTab(value)
    }
  }

  if (!loaded) {
    return <LoadingCircular loading={true} />
  }

  const renderActionLabel = (actionDate: TeamBuildingActionDate) => {
    const now = dayjs()
    const actionDateDay = dayjs(actionDate.date)
    const label = actionDateDay.format('MM月DD日')

    if (actionDate.status === TeamBuildingActionDateStatus.Completed) {
      // 実施済み
      return (
        <div className={`${classes.actionListItemLabel} primary`}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_check.svg'} alt="" />
          <span>{label}</span>
        </div>
      )
    } else if (now.isAfter(actionDateDay)) {
      // 期限が過ぎているもの
      return (
        <div className={`${classes.actionListItemLabel} red`}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_alert_white.svg'} alt="" />
          <span>{label}</span>
        </div>
      )
    } else if (now.isAfter(actionDateDay.subtract(1, 'day'))) {
      // 期限が迫っているもの
      return (
        <div className={`${classes.actionListItemLabel} yellow`}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_alert_black.svg'} alt="" />
          <span>{label}</span>
        </div>
      )
    } else {
      // デフォルト
      return (
        <div className={`${classes.actionListItemLabel} gray`}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_calendar_label.svg'} alt="" />
          <span>{label}</span>
        </div>
      )
    }
  }

  const renderActionList = (listItems: TeamBuildingActionDate[], loading: boolean) => {
    return (
      <div>
        <ul className={classes.actionList}>
          {listItems.map((item) => (
            <li
              key={item.id}
              className={classes.actionListItem}
              onClick={() => {
                route.push(
                  `${replacePathParams(Pages.TeamsToolBuildingDashboardActionPreview, {
                    teamId: currentTeam?.id,
                    actionId: item.teamBuildingActionId,
                  })}?from=${pathname}${search}`
                )
              }}
            >
              <div>
                <div className={classes.actionListItemHeader}>
                  {renderActionLabel(item)}
                  <div className={classes.actionListItemName}>
                    <UserIcon teamMemberId={item.assignedTeamMemberId} size={24} />
                    <span>{item.assignedTeamMemberName}</span>
                  </div>
                </div>
                <h3 className={classes.actionListItemHeading}>{item.name}</h3>
              </div>
              <ChevronRightRoundedIcon style={{ color: constants.COLOR_ONBOARDING_MAIN }} />
            </li>
          ))}
          <Waypoint onEnter={() => onEnter()} />
        </ul>

        {loading && (
          <div className={classes.loading}>
            <img
              className={classes.loadingIcon}
              src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_loading.svg'}
              alt=""
            />
          </div>
        )}
      </div>
    )
  }

  const renderContent = () => {
    if (tab === 'list') {
      return renderActionList(listTeamBuildingActionDates, listLoading)
    } else {
      return (
        <div className={classes.calendarWrap}>
          <Calendar
            selectedDate={selectedDate}
            onSelectDate={onSelectDate}
            onChangeYearMonth={onChangeYearMonth}
            teamBuildingActionDates={calendarTeamBuildingActionDates}
          />
          {renderActionList(selectedDateTeamBuildingActionDates, calendarLoading)}
        </div>
      )
    }
  }

  return (
    <>
      <div className={classes.container}>
        <Header />
        {teamBuilding && (
          <>
            <main className={classes.main}>
              <div className={classes.teamInfo}>
                <h2 className={classes.teamName}>{currentTeam?.name}</h2>
                <div className={classes.teamInfoList}>
                  <div className={classes.teamInfoListItem}>
                    <header>終了予定日</header>
                    <EditEndAt teamBuilding={teamBuilding} />
                  </div>
                  <div className={classes.teamInfoListItem}>
                    <header>結成</header>
                    <h3>{dayjs().diff(dayjs(teamBuilding.timeCompleted), 'days')}日目</h3>
                  </div>
                  <div className={classes.teamInfoListItem}>
                    <div className={classes.label}>あと{dayjs(teamBuilding.endAt).diff(dayjs(), 'days')}日</div>
                  </div>
                </div>
              </div>
              <div className={classes.tabWrap}>
                <div className={classes.tab}>
                  <div
                    onClick={() => onClickTab(DashboardTab.List)}
                    className={`${classes.tabItem} ${tab === 'list' ? classes.tabItemActive : ''}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/svg/teamBuilding/icon_list${tab === 'list' ? '_active' : ''}.svg`
                      }
                      alt=""
                    />
                    <span>リスト</span>
                  </div>
                  <div
                    onClick={() => onClickTab(DashboardTab.Calendar)}
                    className={`${classes.tabItem} ${tab === 'calendar' ? classes.tabItemActive : ''}`}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        `/assets/svg/teamBuilding/icon_calendar${tab === 'calendar' ? '_active' : ''}.svg`
                      }
                      alt=""
                    />
                    <span>カレンダー</span>
                  </div>
                </div>
                <Link
                  to={`${replacePathParams(Pages.TeamsToolBuildingDashboardActionCreate, {
                    teamId: currentTeam?.id,
                  })}?from=${pathname}${search}`}
                  className={classes.addAction}
                >
                  <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_add.svg'} alt="" />
                </Link>
              </div>
              <div>{renderContent()}</div>
            </main>
            {isAdminOrLeader && (
              <div className={classes.resetText}>
                <p className={classes.textSmall}>
                  キックオフのデータをリセットして再度やり直す場合には、
                  <Link
                    to={`${replacePathParams(Pages.TeamsToolBuildingDashboardActionReset, {
                      teamId: currentTeam?.id,
                    })}`}
                    className={classes.link}
                  >
                    こちら
                  </Link>
                  からリクエストを送信してください。
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modalHeading: {
    margin: 0,
    fontSize: '14px',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    textAlign: 'center',
  },

  calendarWrap: {
    marginTop: '24px',
  },

  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingIcon: {
    animation: '$loading-spin infinite 1s linear',
  },
  '@keyframes loading-spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },

  actionList: {
    listStyle: 'none',
    padding: '0',
  },

  actionListItem: {
    position: 'relative',
    padding: '16px 32px 16px 16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& + &': {
      marginTop: '16px',
    },
  },

  actionListItemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },

  actionListItemLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '92px',
    height: '24px',
    fontSize: '12px',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    borderRadius: '50px',
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    color: '#fff',

    '& > span': {
      marginLeft: '4px',
    },

    '&.red': {
      backgroundColor: constants.COLOR_TEAMBUILDING_RED,
    },

    '&.yellow': {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      backgroundColor: constants.COLOR_TEAMBUILDING_YERROW,
    },

    '&.gray': {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_300,
    },
  },
  actionListItemName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '16px',

    '& > span': {
      marginLeft: '4px',
    },
  },
  actionListItemHeading: {
    margin: '8px 0 0',
    fontSize: '14px',
  },
  actionListItemArrow: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '16px',
    margin: 'auto',
  },

  root: {},
  container: {
    minHeight: '100vh',
    padding: '48px 16px 16px',
    backgroundColor: '#F9F9F9',
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    width: '100%',
    maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
    },
  },
  teamName: {
    margin: 0,
    textAlign: 'center',
    fontSize: '14px',
  },
  teamInfo: {
    padding: '16px 0',
    marginTop: '24px',
    backgroundColor: '#fff',
    '@media (max-width: 600px)': {
      margin: '24px -16px 0',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '48px',
    },
  },
  teamInfoList: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    marginTop: '16px',
  },
  teamInfoListItem: {
    padding: '0 16px',

    '&:nth-child(1), &:nth-child(2)': {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        columnGap: '8px',
        alignItems: 'center',
      },
    },

    '& > header': {
      fontSize: '12px',
      fontWeight: 'bold',
    },

    '& > h3': {
      margin: 0,
      fontSize: '14px',
      fontWeight: 'normal',
    },

    '& + &': {
      borderLeft: 'solid 1px #E9E9E9',
    },

    '&:last-child': {
      flex: 1,
    },
  },
  teamInfoEndDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '33px',
    backgroundColor: '#F5F5F5',
    borderRadius: '50px',
    fontWeight: 'bold',
  },
  tabWrap: {
    display: 'flex',
    width: '100%',
    marginTop: '24px',
  },
  tab: {
    flex: '1',
    display: 'flex',
    height: '42px',
    padding: '4px',
    backgroundColor: '#E9E9E9',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  tabItem: {
    width: '50%',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#707070',

    '& > img': {
      width: '24px',
      height: '24px',
    },
  },
  tabItemActive: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
  },
  addAction: {
    display: 'flex',
    width: '42px',
    height: '42px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginLeft: '16px',
    backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    cursor: 'pointer',
  },
  resetText: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 0',
  },
  textSmall: {
    color: constants.COLOR_TEAMBUILDING_NEUTRAL_400,
    fontSize: '10px',
    lineHeight: '1.7',
    textAlign: 'center',
    margin: 'auto 0 0',
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}))
