import React, { useEffect, useRef, useContext } from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { HooksContext } from 'pages/teams/contexts'

import { constants } from 'assets'

export type Props = {
  item: ITEM
}

interface ITEM {
  id: number
  movieOwnerId: string
  title: string
  role: string
  src: string
}

export const Movie: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const { currentTeam } = useContext(HooksContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    videoRef.current?.play()
  }, [])

  return item.movieOwnerId === currentTeam?.id || item.movieOwnerId === 'COMMON' ? (
    <div className={classes.iframeWrapper}>
      <div className={classes.movieWrapper}>
        <iframe className={classes.iframe} src={item.src} allow="autoplay; fullscreen" />
        <div>{item.title}</div>
      </div>
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    iframeWrapper: {
      paddingBottom: 25,
      padding: '0 15px 25px 15px',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        display: 'flex',
      },
    },

    movieWrapper: {
      width: 225,
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
        width: 340,
      },
    },

    iframe: {
      width: '100%',
      height: '100%',
      borderRadius: 8,
      border: `1px solid ${constants.COLOR_GRAY}`,
      [theme.breakpoints.down('md')]: {
        width: 340,
        height: 195,
      },
    },
  }),
  { name: 'Movie' }
)
