import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'
import { ResearchContext } from 'pages/ghost/contexts/research'
import { useGetUnansweredUserIds } from 'pages/ghost/hooks/getUnansweredUserIds'
import { GhostMemberRole, GhostProgress } from 'utils/generated'

import { BackResponsiveCard, ButtonForGhost, GrayBackGround } from '../components/atoms'
import { ButtonContainer, WhiteGhostImg, WidthControlContainer } from '../components/modules'
import { WaitingContainer } from '../components/modules/WaitingContainer'
import { SelectTimer } from '../components/organisms/SelectTimer'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { CommandContext } from '../service/commands'
import { pickupRandomMember } from '../utils/pickupMember'
import { progressSelectTimerItem } from '../utils/timer'

export interface Input {
  answerCount: string
  minute: string
}

export const PresentationOptions: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { team, updateGhostTeam, loading: loadingTeam } = useContext(ghostTeamContext)
  const { teamMember, teamMemberList, loading: loadingMember } = useContext(ghostTeamMemberContext)
  const { researchesFeeling, researchesNextAction, loading: loadingResearches } = useContext(ResearchContext)
  const { runGoToNextPage } = useContext(CommandContext)
  const { control, errors, handleSubmit } = useForm<Input>()
  const isSmDown = useCustomMediaQuery('down', 'md')
  const { unansweredUserIds } = useGetUnansweredUserIds({
    team,
    researchesFeeling,
    researchesNextAction,
    teamMemberList,
  })

  const timerItem = progressSelectTimerItem(team)
  const nextProgress = (): GhostProgress | undefined => {
    switch (team?.progress) {
      case GhostProgress.S1SelectingPresentationOptions:
        return GhostProgress.S1DonePresentationOptions
      case GhostProgress.S2SelectingPresentationOptions:
        return GhostProgress.S2DonePresentationOptions
      case GhostProgress.S3SelectingPresentationOptionsReason:
        return GhostProgress.S3DonePresentationOptionsReason
      case GhostProgress.S3SelectingPresentationOptions:
        return GhostProgress.S3DonePresentationOptions
      case GhostProgress.S4SelectingPresentationOptions:
        return GhostProgress.S4DonePresentationOptions
      default:
        return undefined
    }
  }

  const onSubmit = async (data: Input) => {
    const answerCount = Number(data.answerCount)
    const minute = Number(data.minute)
    const picked = pickupRandomMember(teamMemberList, unansweredUserIds)

    if (teamMember && timerItem && picked && answerCount >= 1) {
      await updateGhostTeam({
        input: {
          id: teamId,
          progress: nextProgress(),
          [timerItem.number]: answerCount,
          [timerItem.minute]: minute,
          [timerItem.userIds]: [picked.userId],
          [timerItem.started]: new Date().toISOString(),
        },
      })
      await runGoToNextPage(teamMember)
    }
  }

  return (
    <GrayBackGround>
      <BackResponsiveCard>
        {!loadingTeam && !loadingMember && !loadingResearches ? (
          <WidthControlContainer ownStyles={{ height: isSmDown ? `calc(100vh - 206px)` : `calc(100vh - 220px)` }}>
            {teamMember?.role === GhostMemberRole.Leader ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <SelectTimer
                  maxNumber={teamMemberList.length - unansweredUserIds.length}
                  control={control}
                  errors={errors}
                />
                <ButtonContainer
                  fixed
                  buttons={[
                    <ButtonForGhost
                      key={'ghost-rule'}
                      type="submit"
                      buttonBodyColor={'green'}
                      bodyText="次へ"
                      isResizeBasic
                    />,
                  ]}
                />
              </form>
            ) : (
              <>
                <WaitingContainer ownStyles={{ bottom: 150 }} />
                <WhiteGhostImg
                  ownStyles={{ position: 'absolute', bottom: 0, left: 'calc(50% - 60px)', width: 120, marginTop: 150 }}
                />
              </>
            )}
          </WidthControlContainer>
        ) : null}
      </BackResponsiveCard>
    </GrayBackGround>
  )
}
