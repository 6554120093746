import * as React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import format from 'date-fns/format'

import * as constants from 'assets/constants'

type Props = {
  count: number
  isDashPage?: boolean
  recentDay?: string
}

export const CountBatch: React.FC<Props> = ({ count, isDashPage, recentDay }) => {
  const classes = useStyles({})
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('lg'))
  const timeConverter = (time: string | undefined) => {
    return time && format(new Date(time), 'yyyy/MM/dd')
  }
  return count ? (
    <div
      style={{
        position: 'absolute',
        top: isDashPage ? undefined : -25,
        bottom: isDashPage ? 5 : undefined,
        width: !isDashPage ? '100%' : isMdDown ? 140 : '100%',
      }}
    >
      <div className={isDashPage ? classes.findGhostDash : classes.findGhost}>
        過去に見つけた数：<span className={classes.findGhostNum}> {count}</span>
        {recentDay && <div>{timeConverter(recentDay)}</div>}
      </div>
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  findGhost: {
    width: '100%',
    background: constants.COLOR_ONBOARDING_WARNING,
    textAlign: 'center',
    fontSize: 10,
    padding: '5px 0',
  },
  findGhostDash: {
    width: '100%',
    background: constants.COLOR_ONBOARDING_WARNING,
    fontSize: 10,
    padding: '5px',
  },
  findGhostNum: {
    fontWeight: 'bold',
  },
}))
