import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'
import { SelfCheckComment } from '../../../utils/generated'
import { IFetchResult } from '../interface'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [fetchResult, setFetchResult] = React.useState<IFetchResult[]>()
  const [historyIndex, setHistoryIndex] = React.useState<number[]>([])
  const [selfCheckComments, setSelfCheckComments] = React.useState<SelfCheckComment[]>([])

  const handleStartQuestion = () => {
    props.history.push(pages.SELFCHECK_QUESTIONS)
    window.scrollTo(0, 0)
  }
  const handleStartLanding = (historyNum: number) => {
    props.fetch(historyNum)
    props.history.push(pages.SELFCHECK_RESULTS)
    window.scrollTo(0, 0)
  }
  const sliceText = (text: String | undefined) => {
    const SLICENUM = 14
    let slicedText = text
    if (text !== undefined) {
      if (text.length >= SLICENUM) {
        slicedText = `${text.slice(0, SLICENUM)}...`
      }
      return slicedText
    }
  }

  React.useEffect(() => {
    if (props.user.fetched) {
      props.fetch(0)
      props.fetchSelfCheckComments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.fetched])
  React.useEffect(() => {
    const sortCommentsList = (obj: SelfCheckComment[]) => {
      const commentsList = obj.sort((a, b) => {
        if (a.id !== undefined && b.id !== undefined) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        }
        return 0
      })
      return commentsList
    }

    if (props.selfCheck.comments) {
      let commentsList = props.selfCheck.comments.items as SelfCheckComment[]

      if (commentsList !== undefined && commentsList !== null) {
        commentsList = sortCommentsList(commentsList)

        setSelfCheckComments(commentsList)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selfCheck.comments])
  React.useEffect(() => {
    interface IOwnResult {
      [index: string]: number | string
    }
    const rangeResults = (array: IOwnResult[]) => {
      const history = []
      for (let i = 0; i < 10; i++) {
        if (array.length === i) break
        const newObj = { ...array[i] }

        let keys = Object.keys(newObj)

        keys = keys.filter((word) => word !== 'createAt')
        const values = keys.map((keys: string) => array[i][keys] as number)

        const maxNum = values.indexOf(Math.max(...values))

        history.push(maxNum)
      }
      return history
    }
    // 10個以内の履歴抜き出す
    if (props.selfCheck.fetched) {
      if (props.selfCheck.result?.data !== undefined && props.selfCheck.result?.data !== null) {
        const parseResult = JSON.parse(props.selfCheck.result.data)

        setFetchResult(parseResult)

        if (parseResult.length === 0) return

        const history = rangeResults(parseResult)

        setHistoryIndex(history)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selfCheck.result])

  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && true && (
          <>
            <div className="__container">
              <h1 className="__title">セルフチェック</h1>

              <div className="__content">
                <div className="__baloon">
                  <p className="__txt">
                    28問の質問に回答してください。
                    <br />
                    回答結果からあなたの課題が見えます。
                  </p>
                </div>

                <div className="__caution">
                  <h2 className="__title">注意事項</h2>
                  <p>
                    所要時間は約2分です。
                    <br />
                    あまり1問毎に時間をかけず回答していきましょう。回答は誰にも共有されません。率直に今の気持ちでお答えください。
                  </p>

                  <div className="__line"></div>

                  <p>2回目の方は、前回の回答を気にしないでください。今の率直な回答にこそ価値があります。</p>
                </div>

                <div className="__buttons">
                  {props.user?.id && (
                    <Button
                      bgColor={constants.COLOR_GREEN_DARK}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      body={<div>セルフチェックをはじめる</div>}
                      onClick={handleStartQuestion}
                    />
                  )}
                </div>
              </div>

              <div className="__diagnose">
                <h3 className="__title">過去の診断結果一覧</h3>

                {fetchResult?.length !== undefined ? (
                  <>
                    {historyIndex &&
                      historyIndex.map((num: any, index: number) => (
                        <ul className="__list" key={'history' + index}>
                          <li className="__item" onClick={() => handleStartLanding(index)}>
                            {fetchResult[index].createAt}
                            <span className="__link">
                              {selfCheckComments[historyIndex[index]]
                                ? sliceText(selfCheckComments[historyIndex[index]].messageGood)
                                : ''}
                            </span>
                          </li>
                        </ul>
                      ))}
                  </>
                ) : (
                  <p>過去の診断結果はありません。</p>
                )}

                <p>＊最大10件まで自動保存されます。それ以前のものは消えていきます。</p>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        boxSizing: 'border-box',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },
        '& .__title': {
          textAlign: 'center',
        },
        '& .__content': {
          backgroundColor: constants.COLOR_GREEN_LIGHT,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '40px 16px 40px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '& .__baloon': {
            backgroundColor: constants.COLOR_WHITE,
            width: '315px',
            borderRadius: '20px',
            padding: '20px 30px',
            marginLeft: '16px',
            marginBottom: '30px',
            position: 'relative',
            '& .__txt': {
              margin: 0,
            },
            '&::before': {
              content: '""',
              width: 0,
              height: 0,
              display: 'block',
              borderRight: '4px solid transparent',
              borderBottom: `15px solid ${constants.COLOR_WHITE}`,
              borderLeft: '4px solid transparent',
              position: 'absolute',
              left: '-9px',
              top: '61px',
              transform: 'rotate(-100deg)',
            },
          },

          '& .__caution': {
            backgroundColor: constants.COLOR_WHITE,
            borderRadius: '10px',
            padding: '20px 16px 30px',
            marginBottom: '30px',
            '& .__title': {
              fontSize: '20px',
              color: constants.COLOR_MAIN,
              margin: 0,
              textAlign: 'left',
            },
            '& .__line': {
              border: '1px dashed ' + constants.COLOR_MAIN,
            },
          },

          '& .__buttons': {
            display: 'flex',
            marginTop: '8px',
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
          },
        },

        '& .__diagnose': {
          backgroundColor: constants.COLOR_WHITE,
          padding: '40px 16px 60px',
          '& .__title': {
            margin: '0 0 0 16px',
            textAlign: 'left',
          },
          '& .__list': {
            borderTop: `1px solid ${constants.BORDER_GRAY}`,
            borderLeft: `1px solid ${constants.BORDER_GRAY}`,
            borderRight: `1px solid ${constants.BORDER_GRAY}`,
            padding: 0,
            listStyle: 'none',
            '& .__item': {
              borderBottom: `1px solid ${constants.BORDER_GRAY}`,
              padding: '20px 16px',
              '& .__link': {
                color: constants.COLOR_MAIN,
                borderBottom: '1px solid ' + constants.COLOR_MAIN,
                display: 'inline-block',
                marginLeft: '16px',
                cursor: 'pointer',
              },
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
