import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Logo from 'components/icon/Logo'
import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { TOOL_DATA } from 'pages/landing/components/topPageModules/data/tool.data'

import { constants } from 'assets'

export const CocolaboIntroduction: React.FC = () => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <WidthControlWrapper>
        <div>
          <p className={classes.subTitle}>「推せる職場」をつくるための対話支援ツール</p>
          <h2 className={classes.title}>
            <Logo width={321} height={41} />
          </h2>
        </div>

        <p className={classes.info}>
          はたらく価値観診断は、管理職と従業員の対話支援ツール「Cocolabo」のサービス「カルテ」を、
          ログイン不要・無料で行える診断サービスです。
          「Cocolabo」では「カルテ」「キックオフ」「オバケ」の3つの対話ツールを用意してあり、組織や
          チームのシーンに応じて使い分けることができ、エンゲージメント向上に必要な仕事の進め方や関係
          性に特化した「対話」を気軽に実現することができます。
        </p>

        <div className={classes.contents}>
          {TOOL_DATA.map((tool, i) => (
            <div key={tool.alphabetName} className={classes.content}>
              <div>
                <div className={classes.toolName}>
                  <h3 className={classes.toolNamePrimary}>{tool.alphabetName}</h3>
                  <span className={classes.toolNameSecondary}>{tool.japaneseName}</span>
                </div>

                <p className={classes.toolDesc}>{tool.description}</p>

                <div className={classes.iconContainer}>
                  {tool.iconCaptions.map((caption, j) => {
                    return (
                      <div key={`icon-${tool.alphabetName}-${j}`} className={classes.iconWrapper}>
                        <img
                          src={`${process.env.PUBLIC_URL}/img/topPage/tools/icon_${tool.iconPath}_0${j + 1}.png`}
                          className={classes.iconImage}
                          alt={`${tool.japaneseName}のイメージ画像`}
                        />
                        <span key={caption} className={classes.iconCaption}>
                          {caption}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className={classes.toolImageWrapper}>
                <img src={`${process.env.PUBLIC_URL}/img/topPage/tools/top_tool_0${i + 1}.png`} alt="toolImage" />
              </div>
            </div>
          ))}
        </div>

        <div className={classes.link}>
          <a href={constants.PAGE_TOP} target="_blank" rel="noopener noreferrer">
            詳しくはこちら
          </a>
        </div>
      </WidthControlWrapper>
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '120px 0',
      background: '#DFF3F6',
    },
    subTitle: {
      margin: 0,
      paddingRight: 8, // "「" の位置調整
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    title: {
      margin: '12px 0 0',
      textAlign: 'center',
    },
    info: {
      margin: '36px 0 0',
      fontSize: 16,
      lineHeight: 1.7,
      letterSpacing: 1.6,
    },
    contents: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      marginTop: 56,
    },
    content: {
      display: 'flex',
      columnGap: 40,
      padding: '32px 40px 40px',
      background: '#fff',
      borderRadius: 8,
      boxShadow: '6px 6px 0 0 #BBD9DB',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 16,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '32px 16px 40px',
      },
    },
    toolName: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    toolNamePrimary: {
      margin: 0,
      color: constants.COLOR_BLUE_DARK2,
      fontSize: 36,
      fontWeight: 'bold',
    },
    toolNameSecondary: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    toolDesc: {
      flexGrow: 1,
      margin: '16px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      lineHeight: 1.7,
      whiteSpace: 'pre-wrap',
    },
    iconContainer: {
      display: 'flex',
      columnGap: 24,
      marginTop: 32,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    iconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 16,
    },
    iconImage: {
      width: 80,
      height: 80,
    },
    iconCaption: {
      fontSize: 12,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 80,
      },
    },
    toolImageWrapper: {
      flexShrink: 0,
      marginTop: 12,
      width: 400,
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      },
      '& img': {
        width: '100%',
        [theme.breakpoints.down('md')]: {
          maxWidth: 400,
        },
      },
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 40,
      width: '100%',
      '& a': {
        display: 'grid',
        placeItems: 'center',
        width: 340,
        height: 60,
        background: constants.COLOR_BLUE_DARK2,
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 30,
        '@media (hover: hover)': {
          '&:hover': {
            opacity: '80%',
          },
        },
      },
    },
  }),
  { name: 'CocolaboIntroduction' }
)
