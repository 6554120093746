import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useTeamBuildingMemberById, useTeamMember, useManageTeamBuilding } from 'pages/teams/hooks'
import { TeamBuilding, TeamBuildingStatus } from 'utils/generated'

import { QuestionContainer, Button, UsefulContainer, Timer, ButtonContainer, UserIcon } from '../../components'
import { pickupRandomMember } from '../../utils/pickUpTeamBuildingMember'

import { constants } from 'assets'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q1Presen: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { teamBuilding, isLeader } = React.useContext(HooksContext)
  const { teamBuildingMembers } = React.useContext(BuildingHooksContext)
  const { teamBuildingMember } = useTeamBuildingMemberById(
    props.match.params.teamId,
    teamBuilding ? (teamBuilding.q1PresenUserIds ? teamBuilding.q1PresenUserIds[0] : '') : ''
  )
  const { teamMember } = useTeamMember(teamBuildingMember?.id)
  const { updateTeamBuilding } = useManageTeamBuilding()

  const onNext = async () => {
    if (teamBuilding && teamBuilding.q1PresenNumber && teamBuilding.q1PresenUserIds) {
      // 発表人数が設定人数になっていれば次のフェーズへ
      if (teamBuilding.q1PresenUserIds?.length >= teamBuilding.q1PresenNumber) {
        await updateTeamBuilding({
          id: props.match.params.teamId,
          status: TeamBuildingStatus.Q1End,
          timeQ1End: new Date().toISOString(),
        })
      } else {
        const q1PresenUserIds: string[] = []
        const picked = pickupRandomMember(teamBuildingMembers, teamBuilding.q1PresenUserIds)
        if (picked) {
          q1PresenUserIds.push(picked.userId)
          for (const id of teamBuilding.q1PresenUserIds) {
            q1PresenUserIds.push(id)
          }

          await updateTeamBuilding({
            id: props.match.params.teamId,
            q1PresenUserIds,
            timeQ1Presen: new Date().toISOString(),
          })
        }
      }
    }
  }

  return (
    <>
      <QuestionContainer active={1} progressDotsProps={{ maxLength: 3, currentProgress: 3 }} questionHeadBar>
        <div className={classes.cardContainer}>
          <UsefulContainer>
            <div className={classes.titleText}>
              ランダムに発表者を指名します！ <br />
              先程書いたチームのエピソードをお話しください。
            </div>
            <div className={classes.last}>
              {teamBuilding?.q1PresenUserIds?.length}/{teamBuilding?.q1PresenNumber}人目
            </div>
            <div className={classes.answerContainer}>
              <div className={classes.humanContainer}>
                <div className={classes.humanIcon}>
                  <UserIcon userId={teamMember?.userId} isCircleIcon={false} size={32} />
                </div>
                <div className={classes.human}>{teamMember?.fullName}さん</div>
              </div>

              <div className={classes.answer}>{teamBuildingMember?.resultQ1 || '回答無し'}</div>
            </div>
          </UsefulContainer>
        </div>

        {teamBuilding?.timeQ1Presen && (
          <Timer
            from={teamBuilding.timeQ1Presen}
            time={teamBuilding.q1PresenMinute ? teamBuilding.q1PresenMinute * 60 : undefined}
            container
          />
        )}

        {isLeader && (
          <ButtonContainer
            fixed
            marginTopPcLayout={40}
            buttons={[
              <Button key="q1-presen-1" onClick={onNext}>
                次へ
              </Button>,
            ]}
          />
        )}
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  titleText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 29,
  },
  last: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    marginBottom: 20,
  },
  cardContainer: {
    width: '100%',
    marginBottom: 150,
  },
  answerContainer: {
    margin: '0',
    padding: '16px 16px 27px',
    border: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      padding: '24px 24px 27px',
    },
  },
  humanContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
  },
  humanIcon: {
    marginRight: 16,
  },
  human: {
    color: constants.TEXT_GRAY_DARK,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 16,
    fontWeight: 'bold',
  },
  answer: {
    color: constants.TEXT_GRAY_DARK,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    paddingLeft: 2,
    letterSpacing: '0.01em',
    lineHeight: '24px',
    whiteSpace: 'pre-wrap',
  },
}))
