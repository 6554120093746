import React, { useCallback, useState, useEffect } from 'react'

import { CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'

import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useResetTeamBuilding } from 'pages/teams/hooks/teamBuilding'
import { useTeamBuildingActionDates } from 'pages/teams/hooks/teamBuildingActionDates'

import { Button, Header } from '../../../components'
import { EditEndAt } from '../components/EditEndAt'
import { ErrorModal } from '../components/ErrorModal'
import { Modal } from '../components/resetModal'

import { constants } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

const BOTTOM_BUTTON_WIDTH = 135

export const KickoffResetPage: React.FC = () => {
  const classes = useStyles()
  const { route } = useHistory()
  const { currentTeam, teamBuilding, loadedTeamBuilding: loaded, isAdmin, isLeader } = React.useContext(HooksContext)
  const isAdminOrLeader = (isAdmin || isLeader) ?? false
  const {
    teamBuildingActionDates: listTeamBuildingActionDates,
    nextToken: nextTokenForActionDates,
    loading: isFetchingActionDates,
  } = useTeamBuildingActionDates(currentTeam?.id)
  const { resetTeamBuilding, resetResult, errors, isLoading: isReseting } = useResetTeamBuilding()

  const [modalOpen, setModalOpen] = useState(false)

  const handleReset = async (teamId?: string) => {
    if (!teamId) return
    const result = await resetTeamBuilding(teamId)
    if (result?.isTransactionCompleted || result?.resetInOrderProgress?.isAllCompleted) setModalOpen(true)
  }

  const handleBackToKickoffDashboard = () => {
    route.push(replacePathParams(Pages.TeamsToolBuildingDashboard, { teamId: currentTeam?.id }))
  }

  const handleBackDashBoard = useCallback(() => {
    route.push(replacePathParams(Pages.TeamsDashboard, { teamId: currentTeam?.id }))
  }, [currentTeam?.id, route])

  // Modal Open後の10秒自動遷移処理
  useEffect(() => {
    if (!modalOpen) {
      return
    }
    const timeoutId = setTimeout(() => {
      handleBackDashBoard()
    }, 10000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [modalOpen, handleBackDashBoard])

  if (!isAdminOrLeader) {
    return null
  }

  if (!loaded) {
    return <LoadingCircular loading={true} />
  }

  return (
    <div className={classes.container}>
      <Header />

      <div>
        {/* 最初にバック側のエラー有無を確認、次にフロント側のエラー有無を確認する */}
        {resetResult?.error ? (
          <ErrorModal
            open={true}
            msg={{
              main: 'キックオフのリセットに失敗しました。時間をおいて再度お試しください。',
              sub: `code: ${resetResult.error?.code}\ntime:${dayjs(resetResult.error?.time).format(
                'YYYY.MM.DD HH:mm:ss'
              )}\nstatusCode:${resetResult.error?.statusCode}`,
            }}
            firstBtnBody="戻る"
            firstBtnHandleClick={handleBackToKickoffDashboard}
          />
        ) : (
          errors && (
            <ErrorModal
              open={true}
              msg={{
                main: 'キックオフのリセットに失敗しました。時間をおいて再度お試しください。',
                sub: `message:${errors[0]?.message}`,
              }}
              firstBtnBody="戻る"
              firstBtnHandleClick={handleBackToKickoffDashboard}
            />
          )
        )}
      </div>
      {teamBuilding && (
        <main className={classes.main}>
          <div className={classes.textArea}>
            <p className={classes.text}>
              キックオフをやり直すため、こちらのチームのデータをリセットしてよろしいですか？
            </p>
            <p className={classes.textSmall}>※一度リセットすると登録されたデータは元に戻すことができません。</p>
          </div>
          <div className={classes.teamInfo}>
            <h2 className={classes.teamName}>{currentTeam?.name}</h2>
            <div className={classes.teamInfoList}>
              <div className={classes.teamInfoListItem}>
                <div className={classes.teamInfoTitle}>終了予定日</div>
                <EditEndAt teamBuilding={teamBuilding} />
              </div>
              <div className={classes.teamInfoListItem}>
                <div className={classes.teamInfoTitle}>結成</div>
                <div className={classes.teamInfoDetails}>
                  {dayjs().diff(dayjs(teamBuilding.timeCompleted), 'days')}日目
                </div>
              </div>
              <div className={classes.teamInfoListItem}>
                <div className={classes.label}>あと{dayjs(teamBuilding.endAt).diff(dayjs(), 'days')}日</div>
              </div>
            </div>
          </div>
          <div className={classes.teamResetInfo}>
            <div className={classes.teamResetInfoList}>
              {/* アクションの件数が100件を超える状況はまれであるため、100件を超える詳細な件数を表示する必要性は低い。 */}
              {/* そのため、100件以上の場合は、'+' と表示する。 */}
              <div className={classes.teamResetInfoTitle}>登録したアクション</div>
              <>
                {isFetchingActionDates ? (
                  <div className={classes.teamResetInfoListItem}>
                    <CircularProgress size={15} />
                  </div>
                ) : (
                  <div className={classes.teamResetInfoListItem}>
                    {listTeamBuildingActionDates.length}
                    {nextTokenForActionDates && '+'}
                  </div>
                )}
              </>
            </div>
          </div>
          <div className={classes.teamResetInfo}>
            <div className={classes.teamResetInfoList}>
              <div className={classes.teamResetInfoTitle}>結成</div>
              <div className={classes.teamResetInfoListItem}>
                <div>{dayjs().diff(dayjs(teamBuilding.timeCompleted), 'days')}日目</div>
                <div>
                  ( {dayjs(teamBuilding.timeCompleted).format('YYYY.MM.DD')} 〜 {dayjs().format('YYYY.MM.DD')} )
                </div>
              </div>
            </div>
          </div>
          {isReseting && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={20} />
              <span className={classes.loader}>リセット中</span>
            </div>
          )}
          <div className={classes.fixed}>
            <div className={classes.resetButtonWrap}>
              <Button
                key="cancelButton"
                buttonType="secondary"
                onClick={handleBackToKickoffDashboard}
                customWidth={BOTTOM_BUTTON_WIDTH}
              >
                キャンセル
              </Button>
              <Button
                key="resetButton"
                type="submit"
                onClick={() => handleReset(currentTeam?.id)}
                customWidth={BOTTOM_BUTTON_WIDTH}
                disabled={isReseting}
              >
                リセットする
              </Button>
            </div>
          </div>
        </main>
      )}
      <Modal
        open={modalOpen}
        msg={{
          main: '正常にリセットが完了しました。',
          sub: '10秒後に自動的にダッシュボードへ戻ります',
        }}
        firstBtnBody="ダッシュボードへ戻る"
        firstBtnHandleClick={handleBackDashBoard}
      />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      minHeight: '100vh',
      padding: '48px 16px 16px',
      backgroundColor: '#F9F9F9',
      color: '#333',
    },
    main: {
      width: '100%',
      maxWidth: constants.KICKOFF_MAX_WIDTH.sp,
      margin: 'auto',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
      },
    },
    teamName: {
      margin: 0,
      textAlign: 'center',
      fontSize: '14px',
    },
    teamInfo: {
      padding: '16px 0',
      margin: '24px -16px 0',
      backgroundColor: '#fff',
      [theme.breakpoints.up('sm')]: {
        margin: '24px 0 0 ',
      },
    },
    teamInfoList: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'center',
      marginTop: '16px',
    },
    teamInfoListItem: {
      padding: '0 16px',

      '&:nth-child(1), &:nth-child(2)': {
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          columnGap: '8px',
          alignItems: 'center',
        },
      },
      '& + &': {
        borderLeft: 'solid 1px #E9E9E9',
      },
      '&:last-child': {
        flex: 1,
      },
    },
    teamInfoTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    teamInfoDetails: {
      fontSize: '14px',
    },
    teamInfoEndDate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      cursor: 'pointer',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '33px',
      backgroundColor: '#F5F5F5',
      borderRadius: '50px',
      fontWeight: 'bold',
    },
    textArea: {
      marginTop: '48px',
      [theme.breakpoints.up('md')]: {
        marginTop: '24px',
      },
    },

    text: {
      fontSize: '14px',
      lineHeight: '1.7',
      margin: '0',
    },
    textSmall: {
      margin: '0',
      padding: '10px 0 0',
      fontSize: '12px',
      lineHeight: '1.6',
      color: constants.COLOR_TEAMBUILDING_RED,
    },
    teamResetInfo: {
      marginTop: '16px',
      borderRadius: '8px',
      backgroundColor: '#fff',
    },
    teamResetInfoList: {
      fontSize: '12px',
      display: 'flex',
      columnGap: '24px',
      alignItems: 'center',
      padding: '18px 16px',
    },
    teamResetInfoTitle: {
      minWidth: '110px',
      flex: '1',
    },
    teamResetInfoListItem: {
      flex: '3',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        columnGap: '8px',
      },
    },

    loadingContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      marginTop: 24,

      [theme.breakpoints.up('sm')]: {
        marginTop: 32,
      },
    },
    loader: {
      position: 'relative',
      paddingRight: 4,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 'calc(100% - 4px)',
        content: '""',
        animation: '$dotsRepeat 1.5s infinite',
        '@global': {
          '@keyframes dotsRepeat': {
            '0%': {
              content: '""',
            },
            '33%': {
              content: '"."',
            },
            '66%': {
              content: '".."',
            },
            '100%': {
              content: '"..."',
            },
          },
        },
      },
    },

    fixed: {
      position: 'fixed',
      width: '100vw',
      bottom: 0,
      left: 0,
      backgroundColor: '#ffffff',
    },
    resetButtonWrap: {
      display: 'flex',
      justifyContent: 'center',
      columnGap: '24px',
      padding: '16px 0',
    },
  }),
  { name: 'KickoffResetPage' }
)
