import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  balloonId: string
  message: string
}

export const Tooltip: React.FC<Props> = ({ balloonId, message }) => {
  const classes = useStyles()

  return (
    <div id={balloonId} className={classes.balloon}>
      <p className={classes.message}>{message}</p>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    balloon: {
      display: 'none',
      position: 'absolute',
      top: 45,
      right: -10,
      padding: 16,
      backgroundColor: constants.COLOR_TOOLTIP_CONTENT,
      borderRadius: 8,
      transform: 'translateZ(0)', // filter: drop-shadow の safari 対策
      filter: 'drop-shadow(0 3px 6px #00000029)', // 矢印にも影をつけるため boxShadow は使用しない
      zIndex: 100,

      // balloon の矢印部分
      '&::before': {
        position: 'absolute',
        content: '""',
        top: -12,
        left: 'calc(50% - 8px)', // 中央に表示
        borderStyle: 'solid',
        borderWidth: '0 8px 12px 8px',
        borderColor: `transparent transparent ${constants.COLOR_TOOLTIP_CONTENT} transparent`,
      },
    },
    message: {
      margin: 0,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      lineHeight: 1,
    },
  }),
  { name: 'Tooltip' }
)
