import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useIsTeam } from 'pages/plan/hooks/useIsTeam'

import { constants } from 'assets'
import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = {
  url?: string
}

export const AdminSwitchTab: React.FC<Props> = ({ url }) => {
  const { teams } = useIsTeam()
  const teamIds = teams?.items[0].id
  const { route } = useHistory()

  const switchTabCorp = () => {
    if (teams && teams.items.length > 0) {
      route.push(Pages.TeamsCorp, { teamId: teamIds })
    }
  }
  const switchTabPayment = () => {
    if (teams && teams.items.length > 0) {
      route.push(Pages.TeamsPayment, { teamId: teamIds })
    }
  }
  const switchTabBtnAdmin = () => {
    if (teams && teams.items.length > 0) {
      route.push(Pages.TeamsAdmin, { teamId: teamIds })
    }
  }
  const classes = useStyles()
  const switchPage = () => {
    switch (url) {
      case 'corp':
        return (
          <div className={classes.tabContainer}>
            <div onClick={switchTabCorp} className={classes.tabSelected}>
              企業情報
            </div>
            <div onClick={switchTabPayment} className={classes.tabNoSelected}>
              決済情報
            </div>
            <div onClick={switchTabBtnAdmin} className={classes.tabNoSelected}>
              管理者情報
            </div>
          </div>
        )
      case 'payment':
        return (
          <div className={classes.tabContainer}>
            <div onClick={switchTabCorp} className={classes.tabNoSelected}>
              企業情報
            </div>
            <div onClick={switchTabPayment} className={classes.tabSelected}>
              決済情報
            </div>
            <div onClick={switchTabBtnAdmin} className={classes.tabNoSelected}>
              管理者情報
            </div>
          </div>
        )
      case 'admin':
        return (
          <div className={classes.tabContainer}>
            <div onClick={switchTabCorp} className={classes.tabNoSelected}>
              企業情報
            </div>
            <div onClick={switchTabPayment} className={classes.tabNoSelected}>
              決済情報
            </div>
            <div onClick={switchTabBtnAdmin} className={classes.tabSelected}>
              管理者情報
            </div>
          </div>
        )
    }
  }

  return <>{switchPage()}</>
}

const useStyles = makeStyles((theme: Theme) => ({
  tabContainer: {
    borderRadius: 50,
    width: '100%',
    backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    color: constants.COLOR_GRAY_DARK,
    alignItems: 'center',
    marginBottom: 24,
  },
  tabSelected: {
    color: `${constants.COLOR_MAIN_NEW} !important`,
    backgroundColor: constants.COLOR_WHITE,
    padding: '7px 0',
    margin: 4,
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: 50,
    width: '50%',
    justifyContent: 'center',
    display: 'flex',
  },
  tabNoSelected: {
    color: `${constants.COLOR_GRAY_DARK} !important`,
    padding: '7px 0',
    margin: 4,
    fontSize: 12,
    fontWeight: 'bold',
    borderRadius: 50,
    width: '50%',
    justifyContent: 'center',
    display: 'flex',
  },
}))
