import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { range, get } from 'lodash-es'
import { Controller, UseFormMethods, UseFieldArrayMethods } from 'react-hook-form'

import { FormLabel, FormError, Datepicker } from './'

import { constants } from 'assets'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

type Props = {
  control: UseFormMethods['control']
  getValues: UseFormMethods['getValues']
  errors: UseFormMethods['errors']
  trigger: UseFormMethods['trigger']
  fields: UseFieldArrayMethods['fields']
  remove: UseFieldArrayMethods['remove']
  append: UseFieldArrayMethods['append']
}

export const ScheduleForm: React.FC<Props> = ({ fields, getValues, errors, trigger, control, remove, append }) => {
  const classes = useStyles()

  const validate = React.useCallback(
    (value: any, index: number) => {
      let error: string | undefined = undefined

      if (index > 0) {
        range(fields.length).forEach((i) => {
          const otherDate = getValues(`dates[${i}].date`)

          if (i < index && dayjs(value).isSameOrBefore(dayjs(otherDate))) {
            error = `${i + 1}回目の日程よりも後の日付を選択してください`
          }

          if (i > index && dayjs(value).isSameOrAfter(dayjs(otherDate))) {
            error = `${i + 1}回目の日程よりも前の日付を選択してください`
          }
        })
      }

      return error
    },
    [fields, getValues]
  )

  const reTriggerValidates = React.useCallback(
    (index: number) => {
      range(fields.length).forEach((i) => {
        if (i !== index) {
          trigger(`dates[${i}].date`)
        }
      })
    },
    [fields, trigger]
  )

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className={classes.formItem}>
            <FormLabel>{index + 1}回目</FormLabel>
            <Controller
              name={`dates[${index}].date`}
              control={control}
              defaultValue={field.date}
              render={({ onChange, name }) => (
                <>
                  <div className={classes.scheduleForm}>
                    <div style={{ flex: '1' }}>
                      <Datepicker
                        name={name}
                        defaultDate={field.date ? dayjs(field.date) : null}
                        onSelectDate={(date) => {
                          onChange(date.format('YYYY-MM-DD'))
                          reTriggerValidates(index)
                        }}
                      />
                    </div>
                    {index !== 0 && (
                      <div className={classes.removeButton} onClick={() => remove(index)}>
                        削除
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: '4px' }}>
                    <FormError error={get(errors, `dates[${index}].date`, undefined)} />
                  </div>
                </>
              )}
              rules={{ required: '日付を選択してください', validate: (value: any) => validate(value, index) }}
            />
          </div>
        )
      })}

      {fields.length < 20 && (
        <div className={classes.addAction} onClick={() => append({ date: null })}>
          <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_add_sm.svg'} alt="" />
          <span>実施予定日を追加する</span>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formItem: {
    '& + &': {
      marginTop: 24,
    },
  },
  scheduleForm: {
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    marginLeft: 16,
    color: constants.COLOR_ONBOARDING_MAIN,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  addAction: {
    display: 'inline-block',
    marginTop: 24,
    cursor: 'pointer',

    '& > img': {
      verticalAlign: 'middle',
    },

    '& > span': {
      marginLeft: 8,
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '24px',
      verticalAlign: 'middle',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  },
}))
