import * as React from 'react'

import { Modal, Paper } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useOnbHistory } from 'pages/onboarding/navigation/route'

import { ActionModalState } from '..'
import { ButtonNew as Button } from '../../../../../components/ButtonNew'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


type Props = {
  open: boolean
  setModal: (state: ActionModalState) => void
  teamId: string
  userId: string
}

export const CompleteFeedBack: React.FC<Props> = (props) => {
  const classes = useStyles()
  const history = useOnbHistory()

  const handleStartActionList = () => {
    props.setModal('idle')
    history.push(OnbPages.MemberManageActions, {teamId: props.teamId, userId: props.userId})
  }

  return (
    <Modal open={props.open} className={classes.modal} disableAutoFocus={true}>
      <Paper className="__modalPaper">
        <div className="__modalPaper__title">アクション達成</div>
        <div style={{ marginBottom: 35 }}>このアクションは達成リストに移動しました。</div>
        <div className="__modalPaper__buttons">
          <Button body={'OK'} onClick={handleStartActionList} noShadow={true} />
        </div>
      </Paper>
    </Modal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,

    '& .__modalPaper': {
      display: 'inline-block',
      margin: '0 16px',
      padding: '24px 16px 16px 16px',
      maxHeight: '90vh',
      overflow: 'scroll',
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.BREAKPOINT_SMALL,
      },
      '&:focus': {
        outline: 'none',
      },

      '&__title': {
        color: constants.COLOR_ONBOARDING_MAIN,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 21,
      },
      '&__buttons': {
        '& :first-child': {
          marginRight: '4px',
        },
        '& :last-child': {
          marginLeft: '4px',
        },
      },
    },
  },
}))
