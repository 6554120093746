import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { UsefulCard } from '../atoms'

type Props = {
  onClick: () => void
  clickable: boolean
  ownStyles?: React.CSSProperties
  ownBlueStyles?: React.CSSProperties
  ownWhiteStyles?: React.CSSProperties
  children: React.ReactNode
}

export const ClickableCard: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <UsefulCard
      backgroundColor={!props.clickable ? 'white' : 'lightGray3'}
      borderRadius="none"
      ownStyles={
        props.clickable
          ? {
              textAlign: 'center',
              cursor: 'pointer',
              ...props.ownWhiteStyles,
              ...props.ownStyles,
            }
          : {
              textAlign: 'center',
              ...props.ownBlueStyles,
              ...props.ownStyles,
            }
      }
      onClick={props.clickable ? props.onClick : undefined}
      noShadow
    >
      <div className={classes.fontSizewrapper}>{props.children}</div>
    </UsefulCard>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  fontSizewrapper: {
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}))
