import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constants.COLOR_WHITE,
    padding: '30px',

    [theme.breakpoints.down('lg')]: {
      padding: '15px',
      borderRadius: '30px 30px 0px 0px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const ProfileBox = (props: IProps) => {
  const classes = useStyles()

  return <Box className={classes.root}>{props.children}</Box>
}
