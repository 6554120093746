import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { HashLink } from 'react-router-hash-link'

type props = {
  children: React.ReactNode
  isReverse?: boolean
} & React.ComponentProps<'div'>

export const GhostBalloon: React.FC<props> = ({ children, isReverse, className }) => {
  const classes = useStyles()

  const urlParams = useParams<{ id: string }>()
  const id = urlParams.id

  return (
    <div className={classNames(classes.container, className)}>
      {!isReverse && (
        <>
          <img
            className={classNames(classes.sideGhost, 'left')}
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/side-ghost.svg`}
            alt="吹き出しの隣にいる白いオバケ"
          />
          <HashLink to={`/obakesagashi/result/${id}/#ghostFinding`} smooth={true} className={classes.link}>
            <div className={classNames(classes.balloon, 'isHover')}>
              {children}
              <div className={classNames(classes.balloonTip, 'isHover')}></div>
            </div>
          </HashLink>
        </>
      )}

      {isReverse && (
        <>
          <img
            className={classNames(classes.sideGhost, 'right')}
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/side-ghost.svg`}
            alt="吹き出しの隣にいる白いオバケ"
          />
          <div className={classes.balloon}>
            {children}
            <img
              className={classNames(classes.balloonTip, { isReverse })}
              src={`${process.env.PUBLIC_URL}/img/obakesagashi/ghost-balloon-tip.svg`}
              alt="吹き出しの隣にいる白いオバケ"
            />
          </div>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'relative',
      display: 'grid',
      placeItems: 'center',
      padding: '0 160px',
      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
      },
    },
    sideGhost: {
      position: 'absolute',
      top: 0,
      width: 151,
      [theme.breakpoints.down('md')]: {
        top: 'calc(100% + 4px)',
        width: 78,
      },
      '&.left': {
        left: 0,
      },
      '&.right': {
        right: 0,
        transform: 'scale(-1, 1)',
      },
    },
    balloon: {
      position: 'relative',
      display: 'grid',
      placeItems: 'center',
      width: '100%',
      maxWidth: 1000,
      background: '#fff',
      color: '#000',
      borderRadius: 200,
      '&.isHover': {
        '@media (hover: hover)': {
          '&:hover': {
            color: '#fff',
            backgroundColor: '#000',
            '& $balloonTip': {
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/ghost-balloon-tip-hover.svg)`,
            },
          },
        },
      },
    },
    balloonTip: {
      position: 'absolute',
      top: 'calc(100% - 2px)',
      left: 120,
      width: 85,
      [theme.breakpoints.down('md')]: {
        width: 72,
      },
      '&.isReverse': {
        left: 'calc(100% - 205px)',
        transform: 'scale(-1, 1)',
      },
      '&.isHover': {
        height: 40,
        [theme.breakpoints.down('md')]: {
          height: 34,
        },
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/ghost-balloon-tip.svg)`,
        backgroundSize: 'contain',
        '@media (hover: hover)': {
          '&:hover': {
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/ghost-balloon-tip-hover.svg)`,
          },
        },
      },
    },
    link: {
      width: '100%',
      maxWidth: 1000,
    },
  }),
  { name: 'GhostBalloon' }
)
