import * as React from 'react'

import { useTheme, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import { DIALOGUE_STEP_DATA, TOOL_DATA } from 'pages/landing/components/topPageModules/data/tool.data'
import { TopLinkButton } from 'pages/landing/components/topPageModules/TopLinkButton'

import { constants } from 'assets'

export const TopTools: React.FC = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={classes.root}>
      <div className={classes.sectionHeader}>3つの対話ツール</div>
      <div className={classes.sentenceWrapper}>
        <p className={classes.text}>
          それぞれの職場シーンに応じて使い分けることが可能です
          <br />
          エンゲージメント向上に必要な仕事の進め方や関係性に特化した「対話」を気軽に実現することができます​
        </p>
      </div>
      <div className={classes.introductionContainer}>
        {TOOL_DATA.map((tool, index) => {
          return (
            <div key={tool.alphabetName}>
              {isMdUp ? null : (
                <>
                  <div className={classes.toolNameContainer}>
                    <div className={classes.toolNamePrimary}>{tool.alphabetName}</div>
                    <div className={classes.toolNameSecondary}>{tool.japaneseName}</div>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/topPage/tools/top_tool_0${index + 1}.png`}
                    className={classes.toolImage}
                    alt="toolImage"
                  />
                </>
              )}
              <div className={classes.toolInfoContainer}>
                <div className={classes.toolIntroductionLeftBox}>
                  {isMdUp && (
                    <div className={classes.toolNameContainer}>
                      <div className={classes.toolNamePrimary}>{tool.alphabetName}</div>
                      <div className={classes.toolNameSecondary}>{tool.japaneseName}</div>
                    </div>
                  )}
                  <div className={classes.toolDescriptoinWrapper}>
                    <p className={classes.text}>{tool.description}</p>
                  </div>
                  <div className={classes.iconContainer}>
                    {tool.iconCaptions.map((caption, index) => {
                      return (
                        <div key={`icon-${tool}-${index}`} className={classes.iconWrapper}>
                          <img
                            src={`${process.env.PUBLIC_URL}/img/topPage/tools/icon_${tool.iconPath}_0${index + 1}.png`}
                            className={classes.iconImage}
                            alt="toolIconImage"
                          />
                          <span key={caption} className={classes.iconCaption}>
                            {caption}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <div className={classes.detailButtonContainer}>
                    <TopLinkButton path={`/${tool.path}`} label="詳細へ" ownStyles={{ width: 176, height: 46 }} />
                  </div>
                </div>
                {isMdUp && (
                  <div className={classes.toolImageWrapper}>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/topPage/tools/top_tool_0${index + 1}.png`}
                      className={classes.toolImage}
                      alt="toolImage"
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.dialogueHeaderContainer}>
        <div className={classes.dialogueHeader}>対話ステップ例(オバケの場合)</div>
        <p className={classes.text}>
          12体の共通のオバケから「違い」を作り出し、
          {isSmUp && <br />}
          意見をフラットに出しあうことで対話を成立させます。その後、本音が多数出てきます​
        </p>
      </div>
      <div className={classes.dialogueContainer}>
        {DIALOGUE_STEP_DATA.map((dialogueStep, index) => {
          return (
            <div key={dialogueStep.description} className={classes.dialogueStepContainer}>
              <div className={classes.dialogueStepWrapper}>
                <div className={classes.dialogueStepImageContainer}>
                  <div className={classes.dialogueStepHeader}>STEP 0{index + 1}</div>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/topPage/tools/${dialogueStep.imagePath}.png`}
                    className={classes.dialogueStepImage}
                    alt="dialogueStepImage"
                  />
                </div>
                <div className={classes.dialogueStepTextContainer}>
                  <p className={classes.text}>{dialogueStep.description}</p>
                </div>
              </div>
              {index < 2 && (
                <div className={classes.arrowWrapper}>
                  <img src={process.env.PUBLIC_URL + '/img/topPage/arrow.svg'} />
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    // common class
    text: {
      margin: 0,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      whiteSpace: 'pre-wrap',
    },

    root: {
      paddingTop: 80,
      paddingBottom: 74,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 80,
      },
    },
    sectionHeader: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 37,
      textAlign: 'center',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 32,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        marginBottom: 28,
        fontSize: 24,
      },
    },
    sentenceWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 82,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
        marginBottom: 49,
        textAlign: 'left',
      },
    },
    introductionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 80,
      marginBottom: 83,
    },
    toolIntroductionLeftBox: {
      [theme.breakpoints.up('sm')]: {
        flex: 4,
      },
    },
    toolNameContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginBottom: 21,
    },
    toolNamePrimary: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 50,
      fontWeight: 'bold',
    },
    toolNameSecondary: {
      fontSize: 16,
      fontWeight: 600,
    },
    toolInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
    },
    toolDescriptoinWrapper: {
      marginBottom: 24,
    },
    iconContainer: {
      display: 'flex',
      gap: 32,
      marginBottom: 34,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    iconWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 9,
    },
    iconImage: {
      width: 80,
      height: 80,
    },
    iconCaption: {
      fontSize: 12,
    },
    detailButtonContainer: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
    },
    toolImageWrapper: {
      [theme.breakpoints.up('sm')]: {
        flex: 5,
      },
    },
    toolImage: {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    dialogueHeaderContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 27,
      marginBottom: 47,
    },
    dialogueHeader: {
      fontSize: 20,
      fontWeight: 600,
      color: constants.COLOR_MAIN_NEW,
    },
    dialogueContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 19,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 39,
      },
    },
    dialogueStepContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 19,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 0,
      },
    },
    dialogueStepWrapper: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 298,
      },
      [theme.breakpoints.down('md')]: {
        padding: '0 16px',
      },
    },
    dialogueStepImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        maxWidth: 298,
      },
    },
    dialogueStepHeader: {
      fontSize: 24,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
    },
    dialogueStepImage: {
      width: '100%',
      maxWidth: 298,
      height: 200,
      marginBottom: 24,
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        maxWidth: '100%',
      },
    },
    dialogueStepTextContainer: {
      display: 'flex',
      alignItems: 'left',
      textAlign: 'left',
    },
    arrowWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        transform: 'rotate(90deg)',
        marginTop: 24,
      },
      '& img': {
        width: 15,
        height: 30,
      },
    },
  }),
  { name: 'TopTools' }
)
