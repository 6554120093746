import React from 'react'

import Grid from '@mui/material/Grid2'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'

import { Breadcrumb } from 'components/Breadcrumb'
import { TopRenewalWrapper } from 'components/TopRenewalWrapper'
import { useTeams } from 'pages/onboarding/hooks/team'

import { Feature } from '../features.data'

import { OnbStyledButton } from './StyledButton'

import * as constants from 'assets/constants'

export const FeatureContainer = (props: { feature: Feature }) => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'))
  const { teams } = useTeams()
  const [defaultTeamId, setDefaultTeamId] = React.useState<null | string>(null)
  const { id, title, label, mainText, recommended, subTexts } = props.feature
  const classes = useStyles({ id, isXsDown })
  const isOnb = id === constants.FEATURE.onb.id

  React.useEffect(() => {
    if (teams.length > 0) {
      setDefaultTeamId(teams[0].id)
    }
  }, [teams])

  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.topItemWrapper}>
          <div className={classes.breadcrumb}>
            <Breadcrumb
              items={[
                { link: constants.PAGE_TOP, name: 'トップ' },
                { link: constants.PAGE_FEATURES, name: '機能一覧' },
              ]}
              endItem={label}
            />
          </div>
          <p className={classes.titleEn}>{title}</p>
          <h1 className={classes.title}>{label}</h1>
          {isOnb && defaultTeamId && (
            <div className={classes.titleButton}>
              <OnbStyledButton />
            </div>
          )}
        </div>
      </div>

      <TopRenewalWrapper>
        <div className={classes.subTitle}>
          <h2 id={id}>
            <img src={`${process.env.PUBLIC_URL}/assets/landing/features/horizontal_stick.svg`} alt="" />
            {`${label}とは？`}
          </h2>
        </div>
        <Grid container spacing={isMdUp ? 5 : 4}>
          <Grid size={{ xs: 12, md: 6 }}>
            {isOnb ? (
              <>
                <div className={classes.contentVideo}>
                  <iframe
                    title="onboarding-top-video"
                    src="https://player.vimeo.com/video/648475446?h=60074935ec"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                  ></iframe>
                </div>
                <p className={classes.contentVideoDesc}>
                  配属後の新入社員職場実践支援ツール：オンボーディング（2分34秒）
                </p>
              </>
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/assets/landing/features/${id}.jpg`}
                alt={`${id}-main`}
                className={classes.contentImg}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <h3 className={classes.contentTitle}>{mainText.titleDetail ?? mainText.title}</h3>
            <p className={classes.contentText}>{isMdUp ? mainText.bodyPc ?? mainText.body : mainText.body}</p>
          </Grid>
        </Grid>

        <div className={classes.subTitle}>
          <h2 id={id}>
            <img src={`${process.env.PUBLIC_URL}/assets/landing/features/horizontal_stick.svg`} alt="" />
            どんな人におすすめ？
          </h2>
        </div>
        {Array.isArray(recommended) ? (
          <ul className={classes.recommendedUl}>
            {recommended.map((list) => (
              <li key={`key-${list}`}>{list}</li>
            ))}
          </ul>
        ) : (
          <p className={classes.recommendedText}>{recommended}</p>
        )}

        <div className={classes.subTitle}>
          <h2 id={id}>
            <img src={`${process.env.PUBLIC_URL}/assets/landing/features/horizontal_stick.svg`} alt="" />
            機能紹介
          </h2>
        </div>
        {subTexts && (
          <div className={classes.subTextsContainer}>
            <Grid container spacing={isMdUp ? 6 : 5}>
              {subTexts.map((subText, index) => (
                <Grid size={{ xs: 12, md: 4 }} key={`feature-sub-text-key-${index}`} className={classes.subTextBox}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/landing/features/${id}_sub${index + 1}.png`}
                    alt={`${id}-main`}
                  />
                  <h4>{subText.title}</h4>
                  <div className={classes.spPadding}>
                    <p>{subText.body}</p>
                  </div>
                </Grid>
              ))}
            </Grid>
            {isOnb && defaultTeamId && (
              <div className={classes.bottomButtonWrapper}>
                <div className={classes.bottomButton}>
                  <OnbStyledButton />
                </div>
              </div>
            )}
          </div>
        )}
      </TopRenewalWrapper>
    </>
  )
}

type StyleProps = {
  id: string
  isXsDown: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    topContainer: ({ id, isXsDown }) => ({
      width: '100%',
      height: isXsDown ? 'calc(100vw * 0.73)' : 'calc(100vw * 0.18)',
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/landing/features/${id}-detail-top${
        isXsDown ? '-sp' : ''
      }.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }),
    topItemWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 24px',
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 'calc(7% + 24px)',
        paddingRight: 'calc(7% + 24px)',
      },
    },
    breadcrumb: {
      position: 'absolute',
      top: 0,
      left: 24,
      [theme.breakpoints.up('md')]: {
        left: 'calc(7% + 24px)',
      },
    },
    titleEn: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontFamily: constants.FONT_FAMILY_TOP_TITLE,
      lineHeight: 1,
    },
    title: {
      margin: '25px 0 10px',
      fontSize: 20,
      fontWeight: 'bold',
      lineHeight: 1,
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    titleButton: {
      marginTop: 30,
      width: 250,
      height: 50,
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        height: 35,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
        height: 50,
      },
    },

    subTitle: {
      padding: '86px 0 43px',
      '& h2': {
        margin: 0,
        color: constants.COLOR_MAIN_NEW,
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 1,
      },
      '& img': {
        position: 'relative',
        paddingRight: 14,
        top: -7,
      },
    },
    contentVideo: {
      position: 'relative',
      width: '100%',
      paddingTop: '56.25%',
      '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
    },
    contentVideoDesc: {
      margin: '14px 0 10px',
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        margin: '14px 0 0',
      },
    },
    contentImg: {
      width: '100%',
      verticalAlign: 'top',
    },
    contentTitle: {
      margin: 0,
      fontSize: 18,
      fontWeight: 'bold',
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
    },
    contentText: {
      margin: '24px 0 0',
      fontSize: 14,
      lineHeight: 1.8,
      textAlign: 'justify',
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.up('md')]: {
        margin: '16px 0 0',
      },
    },

    buttonWrapper: {
      marginTop: 25,
      display: 'flex',
      flexWrap: 'wrap',
      '&.buttons': {
        [theme.breakpoints.down('md')]: {
          marginTop: 42,
          justifyContent: 'space-between',
        },
      },
      '&.button': {
        [theme.breakpoints.down('md')]: {
          marginTop: 42,
          justifyContent: 'center',
        },
      },
    },
    buttonSpace: {
      marginLeft: '5%',
      flex: 1,
    },

    recommendedUl: {
      margin: 0,
      paddingLeft: 18,
      '& li': {
        fontSize: 14,
        lineHeight: 1.8,
      },
    },
    recommendedText: {
      margin: 0,
      fontSize: 14,
    },

    subTextsContainer: {
      paddingBottom: 80,
      [theme.breakpoints.up('md')]: {
        paddingBottom: 140,
      },
    },
    subTextBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& img': {
        width: '110%',
      },
      '& h4': {
        margin: 0,
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      },
      '& p': {
        margin: '24px 0 0',
        lineHeight: 1.8,
        textAlign: 'justify',
      },
    },
    bottomButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    bottomButton: {
      marginTop: 80,
      width: 250,
      height: 50,
    },
  }),
  { name: 'FeatureContainer' }
)
