import React, { FC } from 'react'

import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { anchorLinks, downloadFiles, downloadsType } from 'pages/documents/assets/documents.data'
import { FileBox } from 'pages/documents/components/FileBox'

import { useScrollXCheck } from './hooks/scroll'
import { UserVoiceArrowIcons } from './topPageModules/UserVoiceArrowIcons'

import { constants } from 'assets'

const doc1 = downloadFiles.find((files) => files.category === anchorLinks[1].id)
const doc2 = downloadFiles.find((files) => files.category === anchorLinks[0].id)
const docs: downloadsType[] = []

if (doc1) {
  docs.push(doc1)
}
if (doc2) {
  docs.push(doc2)
}

const scrollElId = 'scrollElTopUsefulDocs'

export const TopUsefulDocs: FC = () => {
  const classes = useStyles({})
  const isXsDown = useCustomMediaQuery('down', 'sm')
  const { scrollElRef, isActiveBtn, handleClickScroll } = useScrollXCheck(scrollElId, 330) // width 300 + gap 30

  return (
    <div id="TopUsefulDocs" className={classes.container}>
      <div className={classes.titleWrapper}>
        <h2>お役立ち資料</h2>
        {isXsDown && (
          <UserVoiceArrowIcons
            clickLeftButton={handleClickScroll('left')}
            clickRightButton={handleClickScroll('right')}
            isAbleToClickLeft={isActiveBtn.left}
            isAbleToClickRight={isActiveBtn.right}
          />
        )}
      </div>

      {docs.length ? (
        <div id={scrollElId} ref={scrollElRef} className={classes.docsWrapper}>
          <div className={classes.docs}>
            {docs.map((doc) => (
              <div key={doc.title} className={classes.doc}>
                <FileBox
                  key={`resources-list-${doc.title}`}
                  imgURL={doc.imgURL}
                  title={doc.title}
                  detail={doc.detail}
                  link={doc.link}
                  isTopPage
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      padding: '80px 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      whiteSpace: 'pre-wrap',

      '& h2': {
        margin: 0,
        fontSize: 32,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
          textAlign: 'left',
        },
      },
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
      },
    },
    docsWrapper: {
      marginTop: 40,
      [theme.breakpoints.down('md')]: {
        marginTop: 30,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        marginLeft: -24,
        paddingLeft: 24,
        overflowX: 'auto',
      },
    },
    docs: {
      display: 'flex',
      justifyContent: 'center',
      gap: 30,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: 654, // doc の width * 枚数 + gap + paddingRight
        paddingRight: 24,
      },
    },
    doc: {
      width: 380,
      [theme.breakpoints.down('md')]: {
        width: 300,
      },
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 6, // for boxShadow space
      },
    },
  }),
  { name: 'TopUsefulDocs' }
)
