import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { ButtonRenewal } from 'components/ButtonRenewal'
import { links } from 'components/casePageData/static'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import { useScrollXCheck } from './hooks/scroll'
import { Casebox } from './topPageModules/Casebox'
import { UserVoiceArrowIcons } from './topPageModules/UserVoiceArrowIcons'

import * as constants from 'assets/constants'

const scrollElId = 'scrollElTopUserVoice'

export const TopUserVoice: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const isSmDown = useCustomMediaQuery('down', 'md')

  const { scrollElRef, isActiveBtn, handleClickScroll } = useScrollXCheck(scrollElId, isSmDown ? 330 : 410) // card width + gap 30

  return (
    <div className={classes.root} id={constTop.anchorLinkId[3]}>
      <div className={classes.titleContainer}>
        <h2>
          導入実績<span>300社</span>以上
        </h2>
        <p className={classes.info}>
          Cocolabo（ココラボ）や関連サービスについてのご質問・導入など、お気軽にお問い合わせください。{'\n'}
          お客様の状況に合わせた最適なプランをご提案いたします。
        </p>
      </div>

      <div className={classes.icons}>
        <UserVoiceArrowIcons
          clickLeftButton={handleClickScroll('left')}
          clickRightButton={handleClickScroll('right')}
          isAbleToClickLeft={isActiveBtn.left}
          isAbleToClickRight={isActiveBtn.right}
        />
      </div>

      <div className={classes.carousel} id={scrollElId} ref={scrollElRef}>
        <div className={classes.boxes}>
          {links.map((link, i) => (
            <div key={`caselist-company-${i}`} className={classes.caseBox}>
              <Casebox
                imgURL={`${process.env.PUBLIC_URL}/assets/landing/case/${link.image}`}
                title={link.title}
                name={link.user}
                link={link.link}
                isOpenNewTab={true}
                isTopPage
              />
            </div>
          ))}
        </div>
      </div>

      <div>
        <ButtonRenewal
          onClick={() => history.push(constants.PAGE_CASE)}
          width={250}
          widthSP={176}
          height={50}
          heightSP={46}
          style={{ display: 'block', margin: '40px auto 0', width: '100%', maxWidth: 250, height: 50 }}
        >
          導入一覧を見る
        </ButtonRenewal>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: '90px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      whiteSpace: 'pre-wrap',
      [theme.breakpoints.down('md')]: {
        padding: '80px 0 0',
      },

      '& h2': {
        margin: 0,
        fontSize: 32,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
        },

        '& span': {
          color: constants.COLOR_MAIN_NEW,
        },
      },
    },

    titleContainer: {
      padding: '0 24px',
    },
    info: {
      margin: '26px 0 0',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
    },

    icons: {
      marginTop: 26,
      padding: '0 100px',
      [theme.breakpoints.down('md')]: {
        padding: '0 24px',
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },

    carousel: {
      display: 'flex',
      marginTop: 24,
      width: '100vw',
      paddingLeft: 92, // Casebox component padding 8 + this = 100
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: {
        marginTop: 31,
        paddingLeft: 16, // Casebox component padding 8 + this = 24
        overflowX: 'auto',
      },
    },
    boxes: {
      flex: 1,
      display: 'flex',
      gap: 14, // Casebox component padding 8 * 2 + this = 30
      paddingRight: 31,
      [theme.breakpoints.down('md')]: {
        paddingRight: 16, // Casebox component padding 8 + this = 24 ※主にスマホやタブレットの画面幅なので、scrollbar の幅は未考慮
      },
    },
    caseBox: {
      paddingBottom: 6, // for boxShadow space
      cursor: 'pointer',
    },
  }),
  { name: 'TopUserVoice' }
)
