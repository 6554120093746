import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Select, MenuItem } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Control, Controller, FieldError, RegisterOptions } from 'react-hook-form'

import { FormError } from '../../../components/FormError'

import CheckIcon from '../assets/checkedIcon.svg'
import { constants } from 'assets'

type StyleProps = {
  confirm: boolean
}
type Props = {
  label?: string
  name: string
  control?: Control
  readOnly?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  error?: FieldError
  defaultValue?: string | null
  value?: string
  rootStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties

  endAdornment?: React.ReactNode
  onChange?: (...event: any[]) => void
} & StyleProps

export const SignUpSelectBoxWithLabel: React.FC<Props> = (props) => {
  const classes = useStyles({ confirm: props.confirm })
  const { label, name, control, rules, error, defaultValue, readOnly, confirm, rootStyle } = props

  const options = [
    '10000名以上',
    '5000～9999名',
    '2500～4999名',
    '1000～2499名',
    '200～999名',
    '30～199名',
    '29名以下',
    '選択してください',
  ]

  return (
    <div className={classes.root} style={rootStyle}>
      {label ? (
        <div className={rules?.required ? classes.labelBox : ''}>
          <InputLabel>{label}</InputLabel>
          {rules?.required && !confirm ? <div className={classes.requiredMark}>必須</div> : <></>}
        </div>
      ) : (
        <></>
      )}
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ onChange, name, value }) => (
            <div className={classes.selectBoxRoot}>
              <Select
                variant="standard"
                value={value}
                name={name}
                error={error ? true : false}
                onChange={onChange}
                IconComponent={ExpandMoreIcon}
                readOnly={readOnly}
                MenuProps={{
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  classes: { paper: classes.muiPaperRoot },
                }}
              >
                {options.map((item) => (
                  <MenuItem key={`menuItem-${item}`} value={item} classes={{ root: classes.muiListItemRoot }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {error ? (
                <div className={classes.errorContainer}>
                  <FormError error={error} />
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: 20,
    },
    labelBox: {
      display: 'flex',
      alignItems: 'center',
    },
    requiredMark: {
      margin: '0 0 0 16px',
      padding: 3,
      fontWeight: 'bold',
      fontSize: 9,
      color: constants.COLOR_WHITE,
      backgroundColor: constants.COLOR_ALERT,
      borderRadius: 4,
      lineHeight: 1,
    },

    labelContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    },
    selectBoxRoot: (props: StyleProps) => ({
      //for init
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
      //
      // === for style ===
      //classes.selectBoxRoot
      width: '100%',
      height: 48,
      borderRadius: 8,
      border: props.confirm ? 'none' : '2px solid #E2E6E2',
      color: constants.TEXT_GRAY_DARK,
      margin: props.confirm ? 0 : '16px 0 0',
      '& .MuiInputBase-root': {
        width: '100%',
        height: '100%',
      },
      '& .MuiSelect-root': {
        width: '100%',
        height: 'calc(100% - 12px)',
        padding: '12px 8px 0',
        fontSize: 14,
        cursor: props.confirm ? 'default' : 'pointer',
        '&:focus': {
          background: constants.COLOR_WHITE,
        },
      },
      '& .MuiSelect-icon': {
        right: 8,
        fill: props.confirm ? constants.COLOR_WHITE : constants.COLOR_MAIN_NEW,
      },
    }),

    //for popOverPosition
    muiPaperRoot: {
      maxHeight: 400,
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    //for menuItems
    muiListItemRoot: {
      padding: '14px 0 14px 37px',
      fontSize: 14,
      fontWeight: 'normal',
      '&.Mui-selected': {
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:before': {
          content: '" "',
          backgroundImage: `url(${CheckIcon})`,
          position: 'relative',
          left: 'calc(100% - 35px)',
          display: 'block',
          height: '1rem',
          width: '1rem',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
    },

    errorContainer: {
      marginTop: 4,
    },
  }),
  { name: 'SignupSelectBoxWithLabel' }
)

interface InputLabelProps {
  children: React.ReactNode
}

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const classes = useInputLabelStyles()

  return (
    <div>
      <label className={classes.label}>{props.children}</label>
    </div>
  )
}

const useInputLabelStyles = makeStyles(
  (theme: Theme) => ({
    label: {
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.TEXT_GRAY_DARK,
    },
  }),
  { name: 'InputLabel' }
)
