import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Breadcrumb } from 'components/Breadcrumb'

import * as constants from 'assets/constants'


type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const policies = [
  {
    title: '法令・規範の遵守',
    body: (
      <p>
        当社は個人情報の取り扱いに関する法令、国が定める指針その他の規範を、常に最新状態に維持すると共にこれを遵守し、個人情報保護マネジメントシステム
        JIS Q 15001 個人情報マネジメントシステムの要求事項に基づき業務を遂行致します。
      </p>
    ),
  },
  {
    title: '適切な管理の徹底',
    body: (
      <p>
        当社は個人情報保護管理責任者を任命し、個人情報の不正利用・紛失・破壊・改ざん・及び漏洩に対し適切な予防ならびに是正に関する措置を講じます。また、全ての就労者に対し&quot;個人情報取り扱い&quot;についての教育を行い、適切な管理に取り組みます。
      </p>
    ),
  },
  {
    title: '取得目的と範囲',
    body: <p>当社は個人情報をお預かりする場合、その取得目的を明らかにして、必要な範囲で適切に取得致します。</p>,
  },
  {
    title: '利用目的範囲での利用',
    body: (
      <p>
        当社はご本人様の同意を得た取得目的の範囲、あるいは法令・規範に基づく要請の範囲を越えた利用、提供、取り扱いの委託は行わないこと、及びその為の措置を講じます。
      </p>
    ),
  },
  {
    title: '利用等の制限と管理',
    body: (
      <p>
        当社は保有する個人情報を守秘し、同意を得た利用目的の範囲、あるいは法令・規範に基づく要請の範囲を越えた利用、提供、取り扱いの委託は行いません。
      </p>
    ),
  },
  {
    title: '個人情報保護に関するマネジメントシステムの確立と継続的改善',
    body: (
      <p>
        当社は適切な取り扱いを行うよう規程類及び体制を整備して、個人情報保護マネジメントシステムを構築・実施し、これらを継続的に改善して参ります。
      </p>
    ),
  },
  {
    title: '個人情報の相談および苦情窓口',
    body: (
      <p>
        当社は『個人情報相談窓口』を設置しお客様からの個人情報に関するお問い合わせや苦情に対して、適切かつ誠実、迅速に対応致します。保有している個人情報について、ご本人様からの開示、訂正、利用停止の要請を受け付け、適切に対処致します。
      </p>
    ),
  },
]

const rules = [
  {
    title: '利用目的について',
    body: (
      <p>
        当社は個人情報を下記の利用目的の達成ならびに必要な範囲で利用致します。
        <br />
        （1）Cocolaboサービス全般の情報提供のため
        <br />
        （2）システムに不具合などがあった際の連絡のため
        <br />
        ご提供は任意ですが、当社が求める個人情報をご提供いただけない場合、目的を遂行できない場合がございますので、ご了承下さい。
      </p>
    ),
  },
  {
    title: '個人情報の取り扱いについて',
    body: (
      <p>
        当社はご提供頂きました個人情報を上記利用目的の範囲内で正確・最新の内容に保つように努め、不正なアクセス、改竄、漏洩等を防ぐべく、必要かつ適切な安全管理措置を講じます。ご提供頂きました個人情報は特定の場所にて保管し、原則として返却致しません。お預かり致しました個人情報は特定の場所にて保管致します。
        <br />
        <br />
        また当社は、本人が容易に認識できない方法によって個人情報を取得することはございません。但し、当社が運営するウェブサイト（cocolabo.club）では、セキュリティ確保・ユーザビリティ向上のため、アクセス・利用状況（個人情報を含みません）をCookieにより取得しています。Cookieの設定については、各ウェブブラウザのヘルプメニューをご確認下さい。
      </p>
    ),
  },

  {
    title: '個人情報の第三者への提供',
    body: (
      <p>
        当社は、以下の場合を除き、個人情報をご本人様の同意なしで第三者へ提供致しません。
        <br />
        （1）法令の規定により開示が要求された場合
        <br />
        （2）人の生命、健康、財産等の重大な利益を保護するために必要な場合
        <br />
        （3）公共の利益を保護するために必要な場合
      </p>
    ),
  },
  {
    title: '委託等の管理',
    body: (
      <p>
        個人情報の取り扱いを外部に委託する場合は、当社が規定する個人情報管理基準を満たす企業を選定して委託を行い、適切な取り扱いが行われるよう監督します。
      </p>
    ),
  },
  {
    title: '個人情報の開示等について',
    body: (
      <p>
        当社に対してご提供頂いた個人情報に関して、ご本人は、利用目的の通知・開示・内容の訂正・追加又は削除・利用の停止・消去及び第三者への提供の停止(以下「開示等」と言います)を求める事ができます。
        <br />
        個人情報の取り扱いに関する苦情及び開示等の申し出は、個人情報相談窓口まで、電話かメールにてご連絡下さい。当社の手続き規定に従い、対応致します。
      </p>
    ),
  },
  {
    title: '開示等の求めに関する手続き',
    body: (
      <p>
        開示等の求めは、まず個人情報相談窓口へ電話又はメールでご連絡下さい。以下の流れに沿って対応をさせていただきます。
        <br />
        （1）開示等の求めの受付
        <br />
        手続き用紙「個人情報開示等請求書」を郵送やメール等でお渡し致します。ご記入の上、当社にご提出下さい。
        <br />
        （2）本人確認
        <br />
        開示等請求者の本人確認をさせていただきます。運転免許証、パスポート等の写真つき身分証明書の写しを、（1）と併せて当社へご提出下さい。代理人の方が手続きする場合は、代理人の上記証明書と、代理権があることを証明する書面もご提出下さい。
        <br />
        （3）内容確認、求めへの回答
        <br />
        ご提出いただいた手続き用紙と身分証明書を当社にて確認し、対応を検討致します。開示等求めへの回答は、請求者の連絡先へ、書面にて郵送致します。尚、郵送にかかる費用は開示手数料として請求者より徴収致します。
        <br />
        ※利用目的の通知・開示請求に関する手数料
        <br />
        〔参考〕25gまでの定形郵便物の場合の料金（2021年7月1日　消費税10％現在）
        <br />
        郵便料金84円＋書留料金435円＋本人限定受取郵便料金105円＝ 計624円
      </p>
    ),
  },
  {
    title: '認定個人情報保護団体の名称及び苦情の解決の申出先',
    body: (
      <p>
        （1）認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会
        <br />
        （2）お問い合わせ先：個人情報保護苦情相談室
        <br />
        <span className="__indent"></span>住所：東京都港区六本木一丁9番9号六本木ファーストビル内
        <br />
        <span className="__indent"></span>TEL：03-5860-7565、0120-700-779
        <br />
        <span className="__indent"></span>
        <a
          href="https://www.jipdec.or.jp/protection_org/complaint_processing.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.jipdec.or.jp/protection_org/complaint_processing.html
        </a>
      </p>
    ),
  },
]

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.BreadcrumbWrapper}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="プライバシーポリシー" />
        </div>
        <div className="__container">
          <h3>プライバシーポリシー</h3>

          <div className="__content">
            <h6 className="__subTitle">個人情報保護方針</h6>
            <p>
              株式会社NEWONE（以下「当社」と言います）は、コンサルティング・企業研修・組織開発・新卒採用支援・起業支援・シェアードサービス・メールマガジン発行、その他これに付随する業務を行うにあたり、お客様からお預かりする個人情報を安全に保管・管理し、適切に使用する事を社会的責務と考え、以下の方針に基づき個人情報の保護・管理・運用・利用を徹底致します。
            </p>
            {policies.map((data, index) => (
              <React.Fragment key={'policy-' + index}>
                <div className="__title">
                  <div className="__num">{index + 1}. </div>
                  {data.title}
                </div>
                {data.body}
              </React.Fragment>
            ))}
            <p>
              個人情報相談窓口：株式会社NEWONE　個人情報保護管理者
              <br />
              （管理部門／取締役　tel：03-6261-3514　mail：<a href="mailto:info@new-one.co.jp">info@new-one.co.jp</a>）
            </p>
            <p>
              制定日　2017年11月1日
              <br />
              最終改正年月日　2019年2月1日
              <br />
              株式会社NEWONE
              <br />
              代表取締役社長　上林 周平
            </p>

            <div className="__horizontalLine" />

            <h6 className="__subTitle">個人情報の取り扱い</h6>
            <p>株式会社NEWONE（以下「当社」と言います）は、ご提供頂く個人情報を以下のとおり取り扱います。</p>
            {rules.map((data, index) => (
              <React.Fragment key={'policy-' + index}>
                <div className="__title">
                  <div className="__num">{index + 1}. </div>
                  {data.title}
                </div>
                {data.body}
              </React.Fragment>
            ))}
            <p>
              個人情報相談窓口：株式会社NEWONE　個人情報保護管理者
              <br />
              （管理部門/取締役　　mail：<a href="mailto:info@new-one.co.jp">info@new-one.co.jp</a>）
            </p>
            <p>
              2020年9月8日
              <br />
              株式会社NEWONE
              <br />
              代表取締役社長　上林 周平
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
      backgroundColor: constants.COLOR_GRAY,

      '& h3': {
        textAlign: 'center',
        fontSize: '24px',
        margin: 0,
        padding: '70px 0 24px',
        [theme.breakpoints.down('md')]: {
          padding: '48px 0 24px',
        },
      },

      '& .__container': {
        margin: '0 auto',
        paddingBottom: '32px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          margin: '0 16px',
          padding: '32px 16px',
          '& .__subTitle': {
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            margin: '0 0 28px',
          },
          '& p': {
            marginBottom: '24px',
            '& .__indent': {
              paddingLeft: 36,
            },
            '& a': {
              color: constants.COLOR_MAIN,
              textDecoration: 'underline',
              overflowWrap: 'break-word',
            },
          },
          '& .__title': {
            display: 'flex',
            alignItems: 'center',

            '& .__num': {
              fontWeight: 'bold',
              marginRight: '8px',
              color: constants.COLOR_MAIN,
            },
          },
          '& .__horizontalLine': {
            border: `1px solid ${constants.COLOR_GRAY_LIGHT2}`,
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            marginTop: 32,
            marginBottom: 32,
          },
        },
      },
    },
    BreadcrumbWrapper: {
      margin: '0 auto',
      padding: '0 16px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
  })

export default withStyles(useStyles)(Index)
