import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Breadcrumb } from 'components/Breadcrumb'

import * as constants from '../../assets/constants'


type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const terms = {
  new: [
    {
      title: '第1条(定義)',
      body: (
        <>
          <p>
            本規約において、次の各号に掲げる用語は、文脈上明らかに別の意味に解釈すべき場合を除き、次の各号にそれぞれ定める意味を有するものとします。
          </p>
          <p>
            　(1)「本サービス」とは、当社が「Cocolabo」の名称(理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。)にてインターネット上で提供する、「エンゲージメント高いチーム作りを支援するチームビルディングツール」に係るサービスのことをいいます。
            <br />
            　(2)「本サイト」とは、本サービスに関連して当社が運営するインターネット上の
            <a className="__link" href="https://cocolabo.club/" target="__blank">
              ウェブサイト
            </a>
            のことをいいます。
            <br />
            　(3)「チームビルディングプラン」とは、本サービスのうち、「チームのメンバーのエンゲージメントを高め、チームへの参画意識を高めるための対話ツール」を提供するプランのことをいいます。
            <br />
            　(4)「オンボーディングプラン」とは、本サービスのうち、「チームでの新入社員のエンゲージメントを高め、チームへの貢献意識を高めるための対話ツール」を提供するプランのことをいいます。
            <br />
            　(5)「利用契約」とは、本規約を契約条件とし、当社から本サービスの提供を受けるための契約のことをいいます。
            <br />
            　(6)「会員」とは、利用契約を当社と締結している個人または法人のことをいいます。なお、会員は、会員が営む事業のために本サービスを利用しているものとみなします。
            <br />
            　(7)「チーム」とは、本サービスの利用料金の算定基礎となる単位のことをいいます。
            <br />
            　(8)「メンバー」とは、利用契約に基づき会員が本サービスの利用を認めた第三者であり、チームに登録した者のことをいいます。
            <br />
            　(9)「会員等」とは、「会員」および「メンバー」の総称を意味します。
            <br />
            　(10)「投稿データ」とは、会員等が本サービスを利用して投稿その他送信するコンテンツ(文章、画像、動画、その他データを含みますがこれらに限りません。)のことをいいます。
            <br />
            　(11)「旧無料プラン」とは、本サービスのうち、無料で利用できるツールのことをいいます。現在、その申込は受け付けておりません。なお、会員等が「旧無料プラン」の利用を継続する場合も、本規約の適用を受けるものとします。
            <br />
          </p>
        </>
      ),
    },
    {
      title: '第2条(適用範囲)',
      body: (
        <p>
          1.
          本規約は、当社と会員等との間の本サービスの利用に関わる一切の関係に適用されるものとします。会員等は、本サービスを利用いただく際は、本規約すべてをお読みいただいたうえで、本規約すべてにご同意いただく必要があります。本規約に同意いただけない場合、本サービスを利用することはできません。
          <br />
          2. 当社が本サイト上で掲載する本サービスに関する
          <a className="__link" href="https://cocolabo.club/help" target="__blank">
            ルール
          </a>
          は、本規約の一部を構成するものとします。
          <br />
          3.
          本規約の内容と、前項のルールその他本規約外における本サービスの説明等(以下「その他のルール等」といいます。)の内容が異なる場合は、その他のルール等を優先して適用することが特に明示された場合を除き、本規約の規定が優先して適用されるものとします。
          <br />
          4.
          会員は、自己が利用を認めてチームに登録したメンバーが本サービスの利用を開始する際に、メンバーに対し、本規約の内容を説明し遵守させるものとします。
        </p>
      ),
    },
    {
      title: '第3条(利用契約の成立)',
      body: (
        <p>
          本サービスの利用を希望する者は、本サイト上で当社所定の方法により本サービスの利用申込みを行うものとし、当社から本サイト上の会員専用画面において本サービス利用開始日を表示することをもって、当社が申込を受諾したものとし、利用契約が成立するものとします。
        </p>
      ),
    },
    {
      title: '第4条(本サービスの提供)',
      body: (
        <p>
          1.
          当社は、会員等に対し、本利用規約に従い、本サービスを提供します。本サービスには、チームビルディングプランとオンボーディングプランがあり、各プランのサービスの具体的な内容は、本サイト上で掲載する
          <a className="__link" href="https://cocolabo.club/features" target="__blank">
            「機能一覧」ページ
          </a>
          の内容によります。なお「旧無料プラン」の内容は、
          <a className="__link" href="https://cocolabo.club/karte" target="__blank">
            こちらのページ
          </a>
          のとおりになります。
          <br />
          2.
          会員等は、本サービスを利用するために必要な通信機器、ソフトウェア、その他これに付随して必要となる全ての機器の準備および回線利用契約の締結、インターネット接続サービスへの加入、その他必要な準備を、自己の費用と責任において行うものとします。
        </p>
      ),
    },
    {
      title: '第5条(契約期間)',
      body: (
        <p>
          チームビルディングおよびオンボーディングの契約期間は、当社が別途見積りを示した上で第３条に定める申込の際に別途合意するとおりとします。
        </p>
      ),
    },
    {
      title: '第6条(利用料金)',
      body: (
        <p>
          1.
          本サービスの利用料金および支払方法は、当社が別途見積りを示した上で第３条に定める申込の際に別途合意するとおりとします。なお、「旧無料プラン」の内容は、
          <a className="__link" href="https://cocolabo.club/karte" target="__blank">
            こちらのページ
          </a>
          のとおりとなります。
          <br />
          2.
          月の途中から本サービスをご利用いただく場合や契約期間中の利用日数の有無にかかわらず、前条に定める契約期間分の利用料金は、全額お支払いいただくものとし、既にお支払いいただいた利用料金がある場合であっても、当社から会員への返金はしないものとします。
          <br />
          3. 会員が利用料金の支払を遅滞した場合、会員は、年14.6%の割合による遅延損害金を当社に支払うものとします。
        </p>
      ),
    },
    {
      title: '第7条(ログイン名・パスワードの管理等)',
      body: (
        <p>
          1. 本サービスを利用するにあたっては、会員等が任意に設定するログイン名およびパスワードの登録が必要となります。
          <br />
          2.
          会員等は、ログイン名およびパスワードを自己の責任において管理するものとし、ログイン名およびパスワードを第三者に利用させたり、貸与、譲渡、売買等をすることはできないものとします。
          <br />
          3.
          ログイン名またはパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は会員等が負うものとします。
        </p>
      ),
    },
    {
      title: '第8条(登録事項の変更)',
      body: (
        <p>
          会員等は、本サービスの利用申込み時に登録した会員等に関する情報(以下「登録事項」といいます。)に変更があった場合、当社所定の方法により当該変更事項を遅滞なく当社に通知するものとします。
        </p>
      ),
    },
    {
      title: '第9条(禁止事項)',
      body: (
        <>
          <p>本サービスの利用に際して、次の各号の行為を行うことを禁止します。</p>
          <p>
            　(1) 法令に違反する行為または犯罪行為に関連する行為
            <br />
            　(2) 当社、本サービスの他の会員等、その他第三者の権利、利益、名誉等を損ねること
            <br />
            　(3) 他の会員等に関する個人情報等を収集し、または蓄積すること
            <br />
            　(4) 本サービスの運営を妨害すること
            <br />
            　(5) 本サービスのネットワークまたはシステム等に過度な負荷をかけること
            <br />
            　(6) 他人になりすまして本サービスを利用すること
            <br />
            　(7) 当社のネットワークまたはシステム等への不正アクセス
            <br />
            　(8) 当社に虚偽の情報(個人情報を含む。)を提供すること
            <br />
            　(9) 次の表現を含み、または含むと当社が判断する内容を本サービス上に投稿し、または送信すること
            <br />
            　　①過度に暴力的な表現
            <br />
            　　②露骨な性的表現
            <br />
            　　③人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現
            <br />
            　　④自殺、自傷行為、薬物乱用を誘引または助長する表現
            <br />
            　　⑤その他反社会的な内容を含み他人に不快感を与える表現
            <br />
            　(10) 次の事項を目的とし、または目的とすると当社が判断する行為
            <br />
            　　①営業、宣伝、広告、勧誘、その他営利を目的とする行為(ただし、当社が認めた行為を除きます。)
            <br />
            　　②性行為やわいせつな行為を目的とする行為
            <br />
            　　③面識のない異性との出会いや交際を目的とする行為
            <br />
            　　④他の会員等に対する嫌がらせや誹謗中傷を目的とする行為
            <br />
            　　⑤当社、本サービスの他の会員等、その他第三者に不利益、損害または不快感を与えることを目的とする行為
            <br />
            　(11) 宗教活動または宗教団体への勧誘行為
            <br />
            　(12)
            会員、会員の関係会社又は会員もしくは会員の関係会社の従業員もしくは役員が、本サービスと類似又は競業するサービスを運営又は提供する行為
            <br />
            　(13) その他当社が不適切と判断する行為
          </p>
        </>
      ),
    },
    {
      title: '第10条(本サービスの変更)',
      body: (
        <p>
          当社は、当社の都合により、本サービス各プランの諸条件、利用料金、本サービスの部分的な改廃等、本サービスの内容を変更することがあります。
        </p>
      ),
    },
    {
      title: '第11条(本サービスの停止等)',
      body: (
        <>
          <p>
            当社は、次の各号のいずれかの事由により、会員等に対し、事前に、または緊急の場合は事後に通知し、本サービスの全部もしくは一部の提供を一時停止または終了する等の必要な措置を取ることができるものとします。
          </p>
          <p>
            　(1) 本サービスを維持するための保守点検などの作業を定期的または緊急に行う場合
            <br />
            　(2) 本サービスを運用するシステムに故障などが発生した場合
            <br />
            　(3) 停電、火災、地震、疫病その他不可抗力により本サービスの提供が困難となった場合
            <br />
            　(4) その他運用又は技術上当社が相当な理由があると判断した場合
            <br />
          </p>
        </>
      ),
    },
    {
      title: '第12条(権利帰属)',
      body: (
        <p>
          1.
          本サイトおよび本サービスに関する一切の特許権、実用新案権、意匠権、商標権、著作権、不正競争法上の権利、その他一切の財産的もしくは人格的権利(以下「知的財産権等」といいます。)は、全て当社または当社にライセンスを許諾している者に帰属します。会員等は、利用契約に基づいて、本サービスを利用することができますが、本サービスに関する知的財産権等を取得するものではありません。
          <br />
          2.
          会員等は、投稿データについて、自らが投稿その他送信することについての適法な権利を有していることおよび投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。会員等の投稿データに係る著作権は、投稿後も会員等が引き続き保有します。ただし、当社は、本サービスの改良、品質の向上および不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で無償利用できるものとし、会員等は、この利用に関して、著作者人格権を行使しないものとします。
        </p>
      ),
    },
    {
      title: '第13条(利用制限および解除)',
      body: (
        <>
          <p>
            1.
            当社は、会員等が、次の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該会員等について本サービスの利用を一時的に停止し、または利用契約の全部もしくは一部を解除することができます。
            <br />
            　(1) 本規約のいずれかの条項に違反した場合
            <br />
            　(2) 登録事項に虚偽の事実があることが判明した場合
            <br />
            　(3)
            支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
            <br />
            　(4) 当社からの問い合わせその他回答を求める連絡に対して2週間以内に返答がない場合
            <br />
            　(5) 第9条各号に該当する行為を行った場合
            <br />
            　(6) その他当社が本サービスの利用の継続を適当でないと判断した場合
            <br />
            2.
            前項各号のいずれかの事由に該当した場合、会員等は、当然に当社に対して負っている一切の債務について期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
          </p>
        </>
      ),
    },
    {
      title: '第14条(退会)',
      body: (
        <p>
          1. 会員は、当社所定の手続の完了により、本サービスから退会し、本サービスの利用を終了することができます。
          <br />
          2.
          前項により退会する場合、契約期間の途中の退会や利用日数の有無にかかわらず、第5条に定める契約期間分の利用料金は、全額お支払いいただくものとし、既にお支払いいただいた利用料金がある場合であっても、当社から会員への返金はしないものとします。
        </p>
      ),
    },
    {
      title: '第15条(保証の否認及び免責)',
      body: (
        <p>
          1.
          当社は、本サービスが会員等の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、会員等による本サービスの利用が会員等に適用のある法令等に適合すること、継続的に利用できること、および不具合が生じないことについて、明示または黙示を問わず何ら保証するものではありません。
          <br />
          2.
          会員は、自己の責任をもって本サービスを利用するものとし、本サービスの利用に起因または関連して生じる損害(メンバーまたは第三者との間の紛争、第10条に定める本サービスの変更、第11条に定める本サービスの停止等、第13条に定める利用制限および解除、その他金銭的損失を含む一切の不利益を含むものとします。)については、速やかに自己の責任と費用をもって解決するものとし、当社は一切責任を負いません。ただし、当社の故意または重大な過失により当該損害が発生した場合は、この限りではありません。
          <br />
          3.
          前項ただし書に定める場合において、当社は、会員に対し、直接かつ現実に生じた通常の損害につき賠償する責任を負うものとします。
        </p>
      ),
    },
    {
      title: '第16条(損害賠償)',
      body: (
        <p>
          会員が本規約に違反し、または不正行為により当社に対し損害を与えた場合、会員は、当社に対し、当該損害を賠償しなければならないものとします。
        </p>
      ),
    },
    {
      title: '第17条(秘密保持)',
      body: (
        <p>
          会員等は、本サービスに関連して当社が会員等に対して秘密に取り扱うことを指定して開示した情報について、当社の事前の書面による承諾がある場合を除き、開示目的以外に利用せず、また、第三者に開示しないものとします。
        </p>
      ),
    },
    {
      title: '第18条(個人情報等の取扱い)',
      body: (
        <p>
          1. 当社は、別途当社
          <a className="__link" href="https://cocolabo.club/privacypolicy" target="__blank">
            プライバシーポリシー
          </a>
          に定めるところにより、会員等の個人情報を適切に取り扱うものとし、会員等は、当該プライバシーポリシーに従って当社が会員等の個人情報を取り扱うことに同意するものとします。
          <br />
          2.
          当社は、本サービスの提供、本サービスの改善等のために、会員等の本サービスの利用に関する一切の情報を会員等が識別できないよう統計情報に加工した上で分析することができるものとします。本項に基づいて加工された統計情報、分析された結果に関する情報については、新たなサービスの開発、当社の宣伝又は広告その他の一切の目的のために当社が利用できるものとします。
        </p>
      ),
    },
    {
      title: '第19条(連絡・通知)',
      body: (
        <p>
          1.
          会員等から当社への連絡または通知は、本サイト上の「お問い合わせ」ページまたは当社が指定するメールアドレス宛のメール送信により行うものとします。
          <br />
          2.
          当社から会員等への連絡または通知は、書面の送付、メールの送信、本サイトへの掲載その他当社が適当と判断する方法により行うものとします。
        </p>
      ),
    },
    {
      title: '第20条(利用契約上の地位の譲渡等)',
      body: (
        <p>
          1.
          会員等は、当社の書面による承諾なく、利用契約上の地位その他本規約に基づく一切の権利を譲渡、貸与、担保差し入れその他の形態を問わず処分することはできないものとします。
          <br />
          2.
          当社は、本サービスにかかる事業を第三者に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利および義務ならびに会員等の登録情報その他情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、当該事業譲渡につき予め同意したものとします。
        </p>
      ),
    },
    {
      title: '第21条(本規約の変更)',
      body: (
        <p>
          当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の内容および施行時期を、本サイト上での告知その他当社が適当と判断する方法により周知し、または会員等に通知します。ただし、法令上、会員の同意が必要となる内容の変更の場合は、当社所定の方法により会員の同意を取得するものとします。
        </p>
      ),
    },
    {
      title: '第22条(分離可能性)',
      body: (
        <p>
          本規約のいずれかの条項またはその一部が、法令または裁判所により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>
      ),
    },
    {
      title: '第23条(準拠法及び管轄裁判所)',
      body: (
        <p>
          1. 本規約に関する準拠法は、日本法とします。
          <br />
          2. 本規約に関する紛争は東京地方裁判所を第一審の専属管轄裁判所とします。
        </p>
      ),
    },
    {
      title: '第24条(協議)',
      body: (
        <p>本規約に関連して会員等と当社の間で紛争が生じた場合、誠実に協議し、できる限り円満に解決するものとします。</p>
      ),
    },
  ],
}

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const history = useHistory()

  const handleClick = () => {
    history.push(constants.PAGE_TERMS_PREVIOUS)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className={classes.BreadcrumbWrapper}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="利用規約" />
        </div>
        <div className="__container">
          <h3>Cocolabo利用規約</h3>
          <div className={classes.link} onClick={handleClick}>
            <span>以前の利用規約ページはこちら</span>
          </div>
          <div className="__content">
            <p>
              株式会社NEWONE(以下「当社」といいます。)は、当社が提供する本サービス(第1条(1)号に定めるサービス意味します。)の利用に関し、次のとおり本利用規約(以下「本規約」といいます。)を定めます。
            </p>
            {terms.new.map((data, index) => (
              <React.Fragment key={'policy-' + index}>
                <div className="__title">
                  <div className={classes.contentTitle}>{data.title}</div>
                </div>
                {data.body}
              </React.Fragment>
            ))}

            <div>
              <div>
                2021年8月1日制定
                <br />
                2022年12月7日改定
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
      backgroundColor: constants.COLOR_GRAY,

      '& h3': {
        textAlign: 'center',
        fontSize: '24px',
        margin: 0,
        padding: '70px 0 24px',
        [theme.breakpoints.down('md')]: {
          padding: '48px 0 24px',
        },
      },

      '& .__container': {
        margin: '0 auto',
        paddingBottom: '32px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          margin: '0 16px',
          padding: '32px 16px',

          '& p': {
            marginBottom: '24px',
          },
          '& .__title': {
            display: 'flex',
            alignItems: 'center',

            '& .__num': {
              fontWeight: 'bold',
              marginRight: '8px',
              color: constants.COLOR_MAIN,
            },
          },
        },
      },

      '& .__link': {
        color: 'blue',
        cursor: 'pointer',
        borderBottom: '1px solid blue',
      },
    },
    BreadcrumbWrapper: {
      margin: '0 auto',
      padding: '0 16px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
    link: {
      textAlign: 'right',
      margin: '0 16px 5px 0',
      fontWeight: 'bold',
      cursor: 'pointer',
      '& span': {
        borderBottom: '1px solid black',
      },
    },
    contentTitle: {
      fontWeight: 'bold',
    },
  })

export default withStyles(useStyles)(Index)
