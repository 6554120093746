import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'

import { constants } from 'assets'

type Props = {
  from?: string // ISODateString
  time?: number // seconds
  container?: boolean
  marginBottom?: number
  hasGhostStyle?: boolean
  ghostWrapBottom?: number
}

export const Timer: React.FC<Props> = ({
  from = new Date().toISOString(),
  time = 300,
  container,
  marginBottom,
  hasGhostStyle,
  ghostWrapBottom,
}) => {
  const [intervalTimer, setIntervalTimer] = React.useState<NodeJS.Timeout | undefined>(undefined)
  const [count, setCount] = React.useState<number | undefined>(undefined)
  const minutes = React.useMemo(() => {
    if (count !== undefined) {
      return Math.floor(count / 60)
    } else {
      return undefined
    }
  }, [count])
  const seconds = React.useMemo(() => {
    if (count !== undefined) {
      return count % 60
    } else {
      return undefined
    }
  }, [count])
  const label = React.useMemo(() => {
    if (minutes !== undefined && seconds !== undefined) {
      return `${zeroPadding(minutes, 2)}:${zeroPadding(seconds, 2)}`
    } else {
      return undefined
    }
  }, [minutes, seconds])
  const isEnd = React.useMemo(() => count === 0, [count])

  const classes = useStyles({ isEnd, container, marginBottom, ghostWrapBottom })

  React.useEffect(() => {
    if (intervalTimer) {
      clearInterval(intervalTimer)
    }

    const interval = setInterval(() => {
      setCount(() => {
        const diff = -dayjs(from).diff(dayjs(new Date().toISOString()), 'second')
        if (time > diff) {
          return time - diff
        } else {
          clearInterval(interval)
          return 0
        }
      })
    }, 1000)

    setIntervalTimer(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from])

  return (
    <>
      {label && (
        <div className={`${classes.timerWrap} ${hasGhostStyle ? classes.ghostWrap : ''}`}>
          <span className={classes.label}>残り時間</span>
          <span className={`${classes.time} ${hasGhostStyle ? classes.ghostTime : ''}`}>{label}</span>
        </div>
      )}
    </>
  )
}

interface StyleProps {
  isEnd: boolean
  container?: boolean
  marginBottom?: number
  ghostWrapBottom?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  timerWrap: (props: StyleProps) => ({
    position: props.container ? 'fixed' : undefined,
    right: props.container ? 0 : undefined,
    bottom: props.container ? 140 : undefined,
    padding: props.container ? '5px 15px' : undefined,
    textAlign: props.container ? 'center' : undefined,
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    [theme.breakpoints.down('sm')]: {
      bottom: props.container ? 100 : undefined,
      marginBottom: props.marginBottom || undefined,
    },
    [theme.breakpoints.up('md')]: {
      bottom: props.container ? 100 : undefined,
    },
    backgroundColor: props.container ? constants.COLOR_TEAMBUILDING_WHITE : undefined,
  }),
  ghostWrap: (props: StyleProps & { ghostWrapBottom?: number }) => ({
    position: 'fixed',
    right: 0,
    bottom: props.ghostWrapBottom ?? 110,
    padding: '5px 24px 5px 17px',
    backgroundColor: '#fff',
    border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    borderRight: 'none',
    fontFamily: `Arial, ${constants.ONBOARDING_FONT_FAMILY} !important`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      bottom: props.ghostWrapBottom ?? 80,
      paddingTop: 12,
    },
  }),

  label: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '12px',
    fontWeight: 'bold',
  },

  time: (props: StyleProps) => ({
    display: 'inline-block',
    marginLeft: '16px',
    color: props.isEnd ? constants.COLOR_TEAMBUILDING_RED : constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: '24px',
    fontWeight: 'bold',
  }),
  ghostTime: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0 !important',
    },
  },
}))

const zeroPadding = (num: number, len: number) => {
  return (Array(len).join('0') + num).slice(-len)
}
