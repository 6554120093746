import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { ReservationInvitationMail } from './ReservationInvitationMail'

type Props = {
  children?: React.ReactNode
  rightNode?: React.ReactNode
  defaultValues?: {
    sendEmailDateAt: string
    sendEmailTimeAt: string
  }
  isShowForm: boolean
  isReserved: boolean
  isHideRightNode?: boolean
  lineParentPadding?: number
}

export const ReservationFramework: React.FC<Props> = ({
  children,
  rightNode,
  defaultValues,
  isShowForm,
  isReserved,
  isHideRightNode,
  lineParentPadding,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.left}>{children}</div>
      {!isHideRightNode && (
        <div className={classes.right}>
          {isShowForm && (
            <ReservationInvitationMail
              defaultValues={defaultValues}
              isReserved={isReserved}
              lineParentPadding={lineParentPadding}
            />
          )}
          {rightNode ?? null}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      columnGap: 40,
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    left: {
      flex: 1,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    right: {
      width: 406,
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
  }),
  { name: 'ReservationFramework' }
)
