import * as React from 'react'

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Flexbox, Font, ButtonForGhost, UsefulCard } from '../../components/atoms'
import { GhostMageImg, GhostFeaturesAndThemes, GhostFileNumberAndTitle } from '../../components/modules'
import { GhostBackgroundAndBenefitsAndRisks, GhostActionAndEffect, ResearchBoard } from '../../components/organisms'
import { LoadingIndicator } from '../../components/organisms/LoadingIndicator'
import { GhostContext } from '../../contexts/ghost'

type Props = {
  select?: boolean
  readOnly?: boolean
}

export const GhostDetailsNoTab: React.FC<Props> = ({ select, readOnly }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { ghosts, detailId, setModal, selectGhost, selectedGhostIds } = React.useContext(GhostContext)

  const alreadySelected = selectedGhostIds.includes(detailId)
  const ghost = ghosts.find((g) => g.id === detailId)

  const selectGhostAndCloseModal = () => {
    selectGhost(detailId, false)
    setModal('')
  }

  if (!ghost) return <LoadingIndicator snapshot={{}} />

  return (
    <>
      <div key={detailId} className={classes.container} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
        <div className={classes.topContainer}>
          {!isMdUp && (
            <GhostFileNumberAndTitle filenumber={ghost.fileNo} name={ghost.name} ownStyles={{ textAlign: 'left' }} />
          )}
          <Flexbox>
            <UsefulCard
              backgroundColor="white"
              borderRadius="none"
              ownStyles={{
                width: 148,
                border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
              }}
            >
              <GhostMageImg ownStyles={{ width: '100%' }} ghostId={ghost.id} />
            </UsefulCard>
          </Flexbox>
          <div className={classes.topBox}>
            {isMdUp && <GhostFileNumberAndTitle filenumber={ghost.fileNo} name={ghost.name} />}
            <Font
              fontSize={14}
              ownStyles={{
                marginTop: isMdUp ? 22 : 12,
                textAlign: isMdUp ? 'left' : 'center',
              }}
            >
              これまでの発見数
              <span style={{ padding: '0 8px', fontSize: 24, fontWeight: 'bold', color: constGhost.COLOR_MAIN_NEW }}>
                {ghost.found || 0}
              </span>
              回
            </Font>
            {(ghost.found || 0) > 0 && (
              <div>
                {select ? (
                  <div className={classes.topButtonBox}>
                    <Flexbox ownStyles={{ justifyContent: isMdUp ? 'flex-start' : 'center' }}>
                      <SelectButton
                        alreadySelected={alreadySelected}
                        onClick={selectGhostAndCloseModal}
                        selectedGhostIds={selectedGhostIds}
                      />
                    </Flexbox>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
        <InfoTitle mt={isMdUp ? 27 : 36} isMdUp={isMdUp}>
          基本情報
        </InfoTitle>
        <div className={classes.infoBox}>
          <GhostFeaturesAndThemes scenes={ghost.scenes} sympton={ghost.sympton} />
        </div>
        {(ghost.found || 0) > 0 && (
          <>
            <InfoTitle mt={43} isMdUp={isMdUp}>
              出没経緯・特徴
            </InfoTitle>
            <div className={classes.infoBox}>
              <GhostBackgroundAndBenefitsAndRisks pro={ghost.pro} con={ghost.con} why={ghost.why} />
            </div>
            <InfoTitle mt={43} isMdUp={isMdUp}>
              対処法
            </InfoTitle>
            <div className={classes.infoBox}>
              <GhostActionAndEffect
                solution={ghost.solution}
                actions={ghost.actions}
                anti={ghost.anti}
                caveats={ghost.caveats}
                gapMargin={24}
              />
            </div>
            <InfoTitle mt={43} isMdUp={isMdUp}>
              対話結果
            </InfoTitle>
            <div className={classes.infoBox}>
              <ResearchBoard noFlex noBalloonTitle ballonMarginTop={16} />
            </div>
          </>
        )}
        <Flexbox flexDirection="column" ownStyles={{ marginTop: 40 }}>
          {select ? (
            <>
              <SelectButton
                alreadySelected={alreadySelected}
                onClick={selectGhostAndCloseModal}
                selectedGhostIds={selectedGhostIds}
              />
              <Font
                fontSize={12}
                color={constGhost.COLOR_GRAY2}
                bold
                onClick={() => setModal('')}
                ownStyles={{ marginTop: isMdUp ? 34 : 42 }}
              >
                キャンセル
              </Font>
            </>
          ) : (
            <ButtonForGhost
              buttonBodyColor="white"
              bodyText="閉じる"
              isResizeBasic
              onClick={() => {
                if (readOnly) {
                  setModal('')
                } else {
                  setModal('list')
                }
              }}
            />
          )}
        </Flexbox>
      </div>
    </>
  )
}

const SelectButton = ({
  alreadySelected,
  onClick,
  selectedGhostIds,
}: {
  alreadySelected: boolean
  onClick: () => void
  selectedGhostIds: string[]
}) => {
  const buttonBody = () => {
    if (alreadySelected) return '選択済み(ボタンを押して解除)'
    if (selectedGhostIds.length > 2) return '3体選択済み'
    return 'このオバケを選択する'
  }

  return (
    <ButtonForGhost
      buttonBodyColor={buttonBody() === '3体選択済み' ? 'gray' : 'green'}
      bodyText={<div>{buttonBody()}</div>}
      onClick={onClick}
      isResizeBasic
      disabled={!alreadySelected && selectedGhostIds.length > 2}
    />
  )
}

const InfoTitle = ({ mt, isMdUp, children }: { mt: number; isMdUp: boolean; children: React.ReactNode }) => (
  <Font
    fontSize={16}
    color={constGhost.COLOR_MAIN_NEW}
    bold
    textAlign={isMdUp ? 'left' : 'center'}
    ownStyles={{ marginTop: mt }}
  >
    {children}
  </Font>
)

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      position: 'relative',
      backgroundColor: constGhost.COLOR_WHITE,
      maxWidth: 600,
      margin: '0 auto',
      padding: '42px 16px 42px',
      borderRadius: 8,
      [theme.breakpoints.up('md')]: {
        padding: '40px 40px 42px',
      },
    },
    topContainer: {
      display: 'flex',
      gap: '40px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: '0px',
      },
    },
    topBox: {
      flex: 1,
    },
    topButtonBox: {
      marginTop: 6,
      [theme.breakpoints.down('md')]: {
        marginTop: 22,
      },
    },
    infoBox: {
      marginTop: 16,
    },
  }),
  { name: 'GhostDetailsNoTab' }
)
