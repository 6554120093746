import * as React from 'react'
import { Link } from 'react-router-dom'

import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { Answer } from 'pages/hint/_shared/components/answer/Index'
import { Details } from 'pages/hint/_shared/components/details/Index'
import { ArticlesHeading } from 'pages/hint/_shared/components/heading/articlesHeading/Index'
import { Lead } from 'pages/hint/_shared/components/lead/Index'
import { ListArticleWrapper, ContentWrapper } from 'pages/hint/_shared/layouts/articles/Index'
import { IConsultationListResult } from 'pages/hint/_shared/stores/apis/getConsultationList'
import { IConsultationBase } from 'pages/hint/_shared/stores/models/consultation'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },

  list: {
    display: 'block',
    textDecoration: 'none',
    borderBottom: 'solid 1px #E8E8E8',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  datas: IConsultationListResult
}

// -----------------------------
// Component
// -----------------------------
export const Articles = (props: IProps) => {
  const classes = useStyles()

  return (
    <ul className={classes.root}>
      {props.datas.items.map((data: IConsultationBase) => {
        return (
          <Link to={`/hint/faq/${data.id}`} className={classes.list} key={data.id}>
            <ListArticleWrapper>
              <ArticlesHeading text={data.title} />
              <ContentWrapper>
                <Lead text={data.contentPreview} color={'#5C5C5C'} ellipsis />
              </ContentWrapper>
              <Box mt={{ xs: '16px', lg: '24px' }}>
                <Details>
                  <Box width={{ xs: '60px', md: '80px' }} marginLeft={'auto'}>
                    <Answer count={data.commentCount} />
                  </Box>
                </Details>
              </Box>
            </ListArticleWrapper>
          </Link>
        )
      })}
    </ul>
  )
}
