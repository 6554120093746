import * as React from 'react'

import { Modal as MuiModal, Paper, Typography, Fade } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { ButtonNew as Button } from 'components/ButtonNew'

import ClearSvg from '../assets/clear.svg'
import TeamMemberAddErrorSvg from '../assets/teamMemberAdd_error.svg'
import TeamMemberAddSuccessSvg from '../assets/teamMemberAdd_success.svg'
import WarningSvg from '../assets/warning.svg'
import * as constants from 'assets/constants'

//for invitedModal when member added by ADMIN
type InvitedKey = 'done' | 'changeReserved' | 'alreadyInvited' | 'unknownError'

type Props = {
  open: boolean
  handleClose: () => void
  msg: { main: string; sub?: string }
  firstBtnBody: string
  firstBtnHandleClick: () => void
  secondBtnBody?: string
  secondBtnHandleClick?: () => void
  bottom?: { body: string; onClick: () => void }
  invFlg?: InvitedKey
  openInvFlg?: boolean
}

export const Modal = ({
  open,
  handleClose,
  msg,
  firstBtnBody,
  firstBtnHandleClick,
  secondBtnBody,
  secondBtnHandleClick,
  bottom,
  invFlg,
  openInvFlg,
}: Props) => {
  const classes = useStyles()

  return (
    <MuiModal
      open={open}
      disableAutoFocus={true}
      className={classes.modal}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.7)',
        },
      }}
    >
      <Fade in={open}>
        <Paper className={classes.modalPaper}>
          <div className={classes.clearIcon} onClick={handleClose}>
            {secondBtnBody ? (
              <>
                <img src={ClearSvg} alt="clear" />
              </>
            ) : (
              <></>
            )}
          </div>

          <div style={{ textAlign: 'center', height: 48 }}>
            <img
              src={
                secondBtnBody
                  ? WarningSvg
                  : invFlg === 'done' || invFlg === 'changeReserved'
                  ? TeamMemberAddSuccessSvg
                  : TeamMemberAddErrorSvg
              }
              alt={
                secondBtnBody
                  ? 'warning'
                  : invFlg === 'done' || invFlg === 'changeReserved'
                  ? 'TeamMemberAddSuccess'
                  : 'TeamMemberAddError'
              }
            />
          </div>
          <div className={classes.msgWrapper}>
            <p className={openInvFlg && !invFlg ? classes.modalErrorMsg : classes.modalMsg}>{msg.main}</p>
            {msg.sub ? <p className={classes.modalMsgSub}>{msg.sub}</p> : <></>}
          </div>

          <Grid
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            style={{ textAlign: 'center' }}
            spacing={2}
          >
            {secondBtnBody ? (
              <>
                <Grid size={{ xs: 6 }}>
                  <Button
                    body={firstBtnBody}
                    onClick={firstBtnHandleClick}
                    textColor={constants.COLOR_ONBOARDING_GRAY_DARK}
                    borderColor={constants.COLOR_ONBOARDING_GRAY_DARK}
                    noShadow
                  />
                </Grid>
              </>
            ) : (
              <>
                <Button
                  body={firstBtnBody}
                  onClick={firstBtnHandleClick}
                  textColor={constants.COLOR_ONBOARDING_MAIN}
                  borderColor={constants.COLOR_ONBOARDING_MAIN}
                  noShadow
                  margin={'0 8px 20px 8px'}
                />
              </>
            )}
            {secondBtnBody ? (
              <>
                <Grid size={{ xs: 6 }}>
                  <Button body={secondBtnBody} onClick={secondBtnHandleClick} noShadow />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          {bottom ? (
            <div className={classes.rmMember}>
              <Typography onClick={bottom.onClick} className={classes.rmMemberMsg}>
                {bottom.body}
              </Typography>
            </div>
          ) : (
            <></>
          )}
        </Paper>
      </Fade>
    </MuiModal>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalPaper: {
      position: 'relative',
      padding: '24px 16px 26px',
      width: '95%',
      maxWidth: 350,
      borderRadius: 8,
      '&:focus': {
        outline: 'none',
      },
    },
    clearIcon: {
      position: 'absolute',
      width: 16,
      height: 16,
      top: 16,
      right: 16,
      cursor: 'pointer',
      '& img': {
        width: '100%',
      },
    },
    msgWrapper: {
      '& p:last-of-type': {
        marginBottom: 27,
      },
    },
    modalMsg: {
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 16,
    },
    modalErrorMsg: {
      color: constants.COLOR_ONBOARDING_ERROR,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 16,
    },
    modalMsgSub: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      textAlign: `center`,
      marginTop: 21,
      whiteSpace: `pre-wrap`,
    },
    rmMember: {
      display: 'flex',
      justifyContent: 'center',
    },
    rmMemberMsg: {
      display: 'inline-block',
      marginTop: 26,
      color: constants.COLOR_ONBOARDING_ALERT,
      fontSize: 12,
      fontWeight: 'bold',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'Modal' }
)
