import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { LoadingCircular } from 'pages/teams/components'
import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'
import { Histories } from 'pages/teams/pages/_tools/iceBreak/components/Histories'
import { useStartIceBreak } from 'pages/teams/pages/_tools/iceBreak/hooks/iceBreak'
import { DescriptionTools } from 'pages/teams/pages/list/components'

import { constants } from 'assets'

export const IceBreak: React.FC = () => {
  const classes = useStyles()
  const { teamId, currentTeamMember } = React.useContext(TeamsHooksContext)
  const { handleStart, errors, isRefreshed, histories, hasActiveIceBreak } = useStartIceBreak({
    teamId,
    currentTeamMember,
  })

  if (!teamId || !currentTeamMember || !isRefreshed) return <LoadingCircular loading />

  return (
    <>
      <DescriptionTools
        titleIcon={!histories || histories.length === 0 ? 'NEW' : undefined}
        title="チーム登録が完了したらアイスブレイクを始めましょう。"
        recommendedTime="（推奨時間：3分～）"
        description={
          'アイスブレイクは短い時間でお互いのことを知るための相互理解ツールです。\n定期的に行うことで相手の「見えない」部分が見えると言われています。\n会議の最初の5分～10分でよいので、毎月・毎週の会議の冒頭に入れてみましょう。'
        }
        buttonLabel={hasActiveIceBreak ? 'アイスブレイクに参加する' : 'アイスブレイクを始める'}
        handleOpenTool={handleStart}
        isMainColorBtn={false}
      />

      {hasActiveIceBreak && <p className={classes.exist}>進行中のアイスブレイクがあります</p>}
      {errors && errors[0].message && (
        <p className={classes.error}>
          エラーが発生しました。時間をあけて再度お試しください。
          <br />
          エラー内容: {errors[0].message}
        </p>
      )}

      {histories && histories.length > 0 ? (
        <div className={classes.historiesWrapper}>
          <Histories className={classes.historyTitle} histories={histories} />
        </div>
      ) : null}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    exist: {
      margin: '8px 0 0',
      backgroundColor: '#fff',
      color: constants.TEXT_GRAY,
      fontSize: 11,
      [theme.breakpoints.down('md')]: {
        margin: '-16px 0 0',
        paddingBottom: 24,
        textAlign: 'center',
      },
    },
    error: {
      margin: '16px 0',
      color: constants.COLOR_DANGER,
      fontSize: 12,
      fontWeight: 'bold',
    },
    historiesWrapper: {
      padding: '16px 16px 32px',
      backgroundColor: '#fff',
      [theme.breakpoints.up('md')]: {
        marginTop: 40,
        padding: 0,
      },
    },
    historyTitle: {
      fontSize: 12,
    },
  }),
  { name: 'Karte' }
)
