import * as React from 'react'

import { Theme, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import downArrow from 'pages/teams/assets/svg/downArrow.svg'
import downArrowWhite from 'pages/teams/assets/svg/downArrowWhite.svg'
import upArrow from 'pages/teams/assets/svg/upArrow.svg'
import upArrowWhite from 'pages/teams/assets/svg/upArrowWhite.svg'
import { strLimit } from 'utils/string'

import { HooksContext } from '../contexts'

import { constants } from 'assets'

interface Props {
  teamId?: string
  ownStyles?: React.CSSProperties
  isOpen?: boolean
  isMdUp: boolean
}

export const Title: React.FC<Props> = (props) => {
  const { ownStyles } = props

  const { currentTeam, currentTeamAdmin, isAdmin, isLeader } = React.useContext(HooksContext)
  const isAdminOrLeader = (isAdmin || isLeader) ?? false
  const classes = useStyles({ isAdminOrLeader })

  if (!currentTeam) return null

  if (!props.isMdUp) {
    return (
      <div className={classes.spRoot}>
        <div className={classes.avatarWithText}>
          <Avatar
            className={classes.companyIcon}
            alt="company-icon"
            src={currentTeamAdmin?.companyIconUrl || undefined}
          >
            {currentTeamAdmin?.companyName?.charAt(0) || ''}
          </Avatar>
          <span className={classes.teamName}>{currentTeam?.name}</span>
        </div>
        {props.isOpen ? <img src={upArrow} alt="up-arrow" /> : <img src={downArrow} alt="down-arrow" />}
      </div>
    )
  }

  return (
    <div className={classes.title} style={{ ...ownStyles }}>
      <div className={classes.titleAllWrapper}>
        <div className={classes.titleNameWrapper}>
          <Avatar className={classes.companyIcon} alt="i" src={currentTeamAdmin?.companyIconUrl || undefined}>
            {currentTeamAdmin?.companyName?.charAt(0) || ''}
          </Avatar>
          <div className={classes.companyTitle}>
            <div className={classes.companyText}>{strLimit(20, currentTeam.name)}</div>
          </div>
        </div>
        <div className={classes.iconWrapper}>
          {props.isOpen ? (
            <img src={isAdminOrLeader ? upArrowWhite : upArrow} alt="up-arrow" />
          ) : (
            <img src={isAdminOrLeader ? downArrowWhite : downArrow} alt="down-arrow" />
          )}
        </div>
      </div>
    </div>
  )
}

interface StyleProps {
  isAdminOrLeader?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  title: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    height: 65,
    width: 260,
    paddingLeft: 20,
    color: props.isAdminOrLeader ? constants.COLOR_TEAMBUILDING_WHITE : constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
    cursor: 'pointer',
  }),
  companyIcon: {
    width: 24,
    minWidth: 24,
    height: 24,
    minHeight: 24,
    borderRadius: '50%',
    marginRight: 8,
    [theme.breakpoints.up('md')]: {
      width: 40,
      minWidth: 40,
      height: 40,
      minHeight: 40,
      marginRight: 16,
    },
  },

  companyTitle: {
    fontSize: 16,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  companyText: {
    fontSize: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: 14,
      fontWeight: 'bold',
      overflowWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      paddingRight: 40,
    },
  },
  titleNameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titleAllWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    position: 'absolute',
    left: 207,
    top: 10,
  },

  // for SP
  spRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    height: 48,
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 100,
    cursor: 'pointer',
  },
  avatarWithText: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  teamName: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))
