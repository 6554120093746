import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  result: {
    width: '100%',
    color: '#9A9C9D',
    fontSize: '28px',
    textAlign: 'center',
    fontWeight: 600,
    margin: 0,
    padding: '100px 85px',
    boxSizing: 'border-box',
    lineHeight: 1.6875,

    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
      padding: '50px 30px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  text: any
}

// -----------------------------
// Component
// -----------------------------
export const Empty = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box>
      <p className={classes.result}>{props.text}</p>
    </Box>
  )
}
