import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import 'date-fns'
import { IActionItem } from 'stores/setupaction/reducer'

import { OnboardingPostLabel, OnboardingActionCategory } from 'utils/generated'

import * as constants from 'assets/constants'

export interface Props {
  item: IActionItem
  label?: OnboardingPostLabel | null
  detail?: boolean
}

export const CardContent: React.FC<Props> = (props) => {
  const classes = useStyles()

  const categoryLabel = (category: string | undefined) => {
    switch (category) {
      case OnboardingActionCategory.Category1:
        return 'チーム理解行動'
      case OnboardingActionCategory.Category2:
        return '関係構築'
      case OnboardingActionCategory.Category3:
        return '社会人自律'
      case OnboardingActionCategory.Category4:
        return '自己成長'
      case OnboardingActionCategory.Category5:
        return '意味づけ'
      case 'TUTORIAL':
        return 'タイムライン理解'
      default:
        return ''
    }
  }

  return (
    <div
      className={classes.root}
      style={{
        marginTop: props.detail ? 24 : 27,
        padding: props.detail ? '8px 8px 27px' : '8px 8px 13px',
      }}
    >
      <div className={classes.contentContainer}>
        <div style={{ display: 'inline-block' }}>
          <div className={classes.category}>{categoryLabel(props.item.category)}</div>
        </div>
        {/* <div className={classes.ratingContent}>
          <span className={classes.ratingText}>重要度</span>
          <div className={classes.rating}>
            <span
              style={{
                backgroundColor:
                  props.item.importance && props.item.importance > 0
                    ? constants.COLOR_ONBOARDING_MAIN
                    : constants.COLOR_ONBOARDING_GRAY_LIGHT,
              }}
            ></span>
            <span
              style={{
                backgroundColor:
                  props.item.importance && props.item.importance > 1
                    ? constants.COLOR_ONBOARDING_MAIN
                    : constants.COLOR_ONBOARDING_GRAY_LIGHT,
              }}
            ></span>
            <span
              style={{
                backgroundColor:
                  props.item.importance && props.item.importance > 2
                    ? constants.COLOR_ONBOARDING_MAIN
                    : constants.COLOR_ONBOARDING_GRAY_LIGHT,
              }}
            ></span>
            <span
              style={{
                backgroundColor:
                  props.item.importance && props.item.importance > 3
                    ? constants.COLOR_ONBOARDING_MAIN
                    : constants.COLOR_ONBOARDING_GRAY_LIGHT,
              }}
            ></span>
            <span
              style={{
                backgroundColor:
                  props.item.importance && props.item.importance > 4
                    ? constants.COLOR_ONBOARDING_MAIN
                    : constants.COLOR_ONBOARDING_GRAY_LIGHT,
              }}
            ></span>
          </div>
        </div> */}
      </div>
      <div
        className={classes.mission}
        style={{
          marginTop: props.detail ? 16 : 8,
          fontSize: props.detail ? 18 : 14,
        }}
      >
        {props.item.mission}
      </div>
      {props.detail ? (
        <>
          <div className={classes.missionItem} style={{ marginTop: 22 }}>
            アクション
          </div>
          <div className={classes.missionItemDesc}>{props.item.what}</div>
          <div className={classes.missionItem} style={{ marginTop: 19 }}>
            達成基準
          </div>
          <div className={classes.missionItemDesc}>{props.item.how}</div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    borderRadius: 8,
    width: '100%',
  },

  contentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  category: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 4,
    fontSize: 10,
    marginRight: 8,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    width: '86px',
    height: '20px',
    fontWeight: 'bold',
  },

  ratingContent: {
    display: 'flex',
    marginTop: 2,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 8,
    fontWeight: 'bold',
  },
  ratingText: {
    paddingRight: 8,
  },
  rating: {
    '& span': {
      display: 'inline-block',
      marginRight: 2,
      width: 8,
      height: 8,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },

  mission: {
    color: constants.TEXT_GRAY_DARK,
    fontWeight: 'bold',
  },
  missionItem: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontWeight: 'bold',
  },
  missionItemDesc: {
    marginTop: 13,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
  },
}))
