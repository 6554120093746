import React, { useState } from 'react'

import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { mergeClasses } from 'pages/onboarding/utils/styles'

import * as constants from 'assets/constants'


export type OwnProps = {
  onSend: (message: string) => void
}

type Props = OwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, onSend }) => {
  const [text, setText] = useState<string>('')
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <textarea
          className={classes.input}
          placeholder={'コメントする…'}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div
          className={mergeClasses([classes.btn, text.length > 0 ? '' : classes.btnInactive])}
          onClick={() => {
            if (text.length > 0) {
              onSend(text)
              setText('')
            }
          }}
        >
          送信
        </div>
      </div>
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    container: {
      // position: 'fixed',
      // left: 0,
      // right: 0,
      // bottom: 0,
      minHeight: 53,
      backgroundColor: constants.COLOR_WHITE,
      paddingLeft: 16,
      paddingTop: 10,
      paddingRight: 16,
      paddingBottom: 10,
    },
    innerContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
    input: {
      flex: 1,
      fontSize: 13,
      backgroundColor: constants.COLOR_GRAY,
      border: 'none',
      paddingTop: 16,
      paddingLeft: 16,
      paddingRight: 16,
      minHeight: 45,
    },
    btn: {
      backgroundColor: constants.COLOR_MAIN,
      color: constants.COLOR_WHITE,
      fontSize: 12,
      fontWeight: 500,
      padding: 14,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    btnInactive: {
      backgroundColor: constants.COLOR_GRAY_DARK,
    },
  })

export default withStyles(useStyles)(Index)
