import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'inline',
  },

  heading: {
    fontWeight: 600,
    fontSize: '18px',
    textDecoration: 'underline',
    margin: 0,
    wordBreak: 'break-all',

    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
    },
  },

  ellipsis: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,

    [theme.breakpoints.down('lg')]: {
      '-webkit-line-clamp': 1,
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  articleId?: number
  text: string
  ellipsis?: boolean
  isPublished?: number
}

// -----------------------------
// Component
// -----------------------------
export const ArticlesHeading = (props: IProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h2
        className={`${classes.heading} ${classes.ellipsis}`}
        style={{
          color: props.isPublished === 0 ? '#8D8D8D' : constants.COLOR_BLACK,
        }}
      >
        {props.text}
      </h2>
    </div>
  )
}
