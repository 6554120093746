import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

interface IOwnProps {
  index: number
  title: string
  point: number
  body: string
  color: string
  link: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const bodyRows = ['']
  for (const c of props.body) {
    if (bodyRows[bodyRows.length - 1].length >= 21) {
      bodyRows.push('')
    }
    bodyRows[bodyRows.length - 1] += c
  }

  let color = {
    gradient: { start: '#4DC6CF', end: '#4DC6CF' },
    title: '#4DC6CF',
    colorName: 'other',
  }
  if (props.color === 'orange') {
    color = {
      gradient: { start: '#FFAA55', end: '#FDCC00' },
      title: '#FFAA55',
      colorName: props.color,
    }
  } else if (props.color === 'pink') {
    color = {
      gradient: { start: '#FFBA95', end: '#FD868C' },
      title: '#FD868C',
      colorName: props.color,
    }
  } else {
    color = {
      gradient: { start: '#2CB6FF', end: '#6CEEFF' },
      title: '#2CB6FF',
      colorName: props.color,
    }
  }

  return (
    <div className={classes.main}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100%"
        viewBox="0 0 348 113"
      >
        <defs>
          <filter id="Path_619" x="0" y="0" width="348" height="113" filterUnits="userSpaceOnUse">
            <feOffset dy="1" in="SourceAlpha" />
            <feGaussianBlur stdDeviation="1.5" result="blur" />
            <feFlood floodOpacity="0.2" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <linearGradient id="linear-gradient-orange" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#FFAA55" />
            <stop offset="1" stopColor="#FDCC00" />
          </linearGradient>
          <linearGradient id="linear-gradient-pink" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#FFBA95" />
            <stop offset="1" stopColor="#FD868C" />
          </linearGradient>
          <linearGradient id="linear-gradient-blue" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#2CB6FF" />
            <stop offset="1" stopColor="#6CEEFF" />
          </linearGradient>
          <linearGradient id="linear-gradient-other" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#4DC6CF" />
            <stop offset="1" stopColor="#4DC6CF" />
          </linearGradient>
        </defs>
        <g id="Group_1351" data-name="Group 1351" transform="translate(-4516.5 -464.5)">
          <g transform="matrix(1, 0, 0, 1, 4516.5, 464.5)" filter="url(#Path_619)">
            <path id="Path_619-2" data-name="Path 619" d="M0,0H339V104H0Z" transform="translate(4.5 3.5)" fill="#fff" />
          </g>
          <a href={props.link} target="_blank" rel="noopener noreferrer">
            <text
              transform="translate(4538 522)"
              fill="#2CBBC6"
              fontSize="12"
              fontFamily="HiraKakuProN-W3, Hiragino Kaku Gothic ProN"
              textDecoration="underline"
            >
              {bodyRows.map((row, index) => (
                <tspan key={index} x="0" y={11 + 11 * (index * 2)}>
                  {row}
                </tspan>
              ))}
            </text>
          </a>
          <line
            x2="244"
            transform="translate(4538 512)"
            fill="none"
            stroke="#d5d5d5"
            strokeWidth="1"
            strokeDasharray="1"
          />
          <g transform="translate(4538 436)">
            <text
              transform="translate(34 52)"
              fill={color.title}
              fontSize="14"
              fontFamily="HiraginoSans-W6, Hiragino Sans"
            >
              <tspan x="0" y="12">
                {props.title}
              </tspan>
            </text>
            <text
              id="_1位"
              data-name="1位"
              transform="translate(0 64)"
              fill={color.title}
              fontSize="14"
              fontFamily="HiraginoSans-W6, Hiragino Sans"
            >
              <tspan x="0" y="0">
                {props.index + 1}位
              </tspan>
            </text>
          </g>
          <g transform="translate(4785 266)">
            <g transform="translate(0 2)">
              <path
                d="M17,0H66V34H17A17,17,0,0,1,17,0Z"
                transform="translate(13 224)"
                fill={`url(#linear-gradient-${color.colorName})`}
              />
              <g transform="translate(10 234)">
                <text
                  transform="translate(36 12)"
                  fill="#fff"
                  fontSize="14"
                  fontFamily="HiraKakuProN-W6, Hiragino Kaku Gothic ProN"
                >
                  <tspan x="-19.299" y="0">
                    {props.point} 点
                  </tspan>
                </text>
              </g>
            </g>
            <path d="M4,0H0V4H0Z" transform="translate(75 260)" fill={color.gradient.start} />
          </g>
        </g>
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {},
  })

export default withStyles(useStyles)(Index)
