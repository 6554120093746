import * as React from 'react'
import { useParams } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import { IceBreakContext, useIceBreakContext } from './contexts/iceBreakContext'
import { Container } from './layout/Container'

import { constants } from 'assets'

export const TeamsIceBreakPage: React.FC = () => {
  const classes = useStyles()
  const { teamId, iceBreakId } = useParams<{ teamId: string; iceBreakId: string }>()
  const iceBreakValues = useIceBreakContext({ teamId, iceBreakId })

  return (
    <IceBreakContext.Provider value={iceBreakValues}>
      <div className={classes.root}>
        <Container />
      </div>
    </IceBreakContext.Provider>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '32px 16px',
      width: '100%',
      minHeight: '100vh',
      backgroundColor: constants.COLOR_GRAY,
      color: constants.TEXT_GRAY_DARK,
      '@media (max-width: 632px)': {
        justifyContent: 'space-between',
      },
    },
  }),
  { name: 'TeamsIceBreakPage' }
)
