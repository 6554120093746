import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '300px',
    backgroundColor: '#F5F5F5',

    [theme.breakpoints.down('lg')]: {
      height: '320px',
    },
  },

  heroWrapper: {
    display: 'block',
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '320px',
    width: '100%',
    margin: 0,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  src: string | null
}

// -----------------------------
// Component
// -----------------------------
export const Hero = (props: IProps) => {
  const classes = useStyles()

  return (
    <section className={classes.root}>
      <figure
        className={classes.heroWrapper}
        style={{
          backgroundImage: props.src ? `url(${props.src})` : '',
        }}
      />
    </section>
  )
}
