import React, { useContext, useState, useEffect, KeyboardEventHandler } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { useForm } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { Comment } from 'pages/onboarding/components/comment'
import { FormCharCount } from 'pages/onboarding/components/formCharCount'
import { MessageInput } from 'pages/onboarding/components/message-input-new'
import { Reaction } from 'pages/onboarding/components/reaction'
import { useMentions } from 'pages/onboarding/hooks/mentions'
import { useOnboardingPostActions, useOnboardingPostComment } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnbContext } from 'pages/onboarding/pages/PagesRoot'
import { useTutorial } from 'pages/onboarding/pages/tutorial/logics/hooks'
import { TutorialProps } from 'pages/onboarding/pages/tutorial/Tutorial'
import { OnboardingPostType, OnboardingPost, OnboardingTeamMember } from 'utils/generated'

import { CommentListItem } from '../CommentListItem'

import { AvatarBox } from './components/AvatarBox'
import { CardContent } from './components/card-content-new'
import { DailyCheckBox } from './components/DailyCheckBox'
import { PostTypePost } from './components/PostTypePost'
import { UserNameBox } from './components/UserNameBox'
import { WeeklySummary } from './components/WeeklySummary'

import BalloonSvg from './assets/balloon.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

// TODO
// - delegate logics to parent, it's a bit expensive
// - change name. ListItem is hard to guess what

export type OwnProps = {
  post: OnboardingPost
  postUser: OnboardingTeamMember
  teamMembers: OnboardingTeamMember[]
  onComment: () => void
  onCommentFocus: () => void
  onAvatar?: () => void
  tutorial?: TutorialProps
}

type Props = OwnProps
type SubStyleProps = {
  mentionBackWidth: number | undefined
  mentionBackHeight: number | undefined
}

export const TimelinePostBloc = {
  useAdapter: (postId: string) => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { postComments } = useOnboardingPostComment(postId)
    const { createComment } = useOnboardingPostActions()
    const { createSecondComment } = useOnboardingPostActions()

    // sort newer one
    postComments.sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1))

    return {
      teamId,
      teamMember,
      postComments,
      createComment,
      createSecondComment,
    }
  },
}

export const ListItem: React.FC<Props> = ({
  post,
  postUser,
  teamMembers,
  onComment,
  onCommentFocus,
  onAvatar,
  tutorial,
}) => {
  // deps
  const { teamId, teamMember, postComments, createComment, createSecondComment } = TimelinePostBloc.useAdapter(post.id)

  const id = `add-comment-input-${post.id}`
  const mentionBackWidth = document.getElementById(id)?.clientWidth
  const mentionBackHeight = document.getElementById(id)?.scrollHeight

  const classes = useStyles({ mentionBackWidth: mentionBackWidth, mentionBackHeight: mentionBackHeight })

  // handle commentReplyBox
  const [commentReplyOpen, setCommentReplyOpen] = useState<boolean>(false)
  const onReplyTextClick = () => {
    setCommentReplyOpen((prev) => !prev)
  }

  //commentReplyFormControl
  type IInput = {
    text: string
  }
  const inputName: keyof IInput = 'text'
  const { register, handleSubmit, errors, watch, setValue } = useForm<IInput>({ mode: 'onChange' })
  const watchInput = watch(inputName, '')
  const maxLength = 10000
  const element = document.getElementById(id) as HTMLInputElement
  const {
    checkCorrectMentions,
    clearMentions,
    SuggestBox,
    openSuggestionBox,
    openSuggestionByTypeText,
    closeSuggestionByTypeText,
    convertMentionsStylesForInput,
  } = useMentions<keyof IInput>(inputName, watchInput, teamMembers, setValue, element)
  const onKeyDown = (e: KeyboardEvent) => {
    openSuggestionByTypeText(e)
  }
  useEffect(() => {
    closeSuggestionByTypeText()
  }, [watchInput, closeSuggestionByTypeText])

  const onSubmit = React.useCallback(
    async (data: IInput) => {
      if (data.text) {
        await createSecondComment(postComments[0].id, data.text, teamId, checkCorrectMentions())
        onReplyTextClick()
        clearMentions()
      }
    },
    [createSecondComment, checkCorrectMentions, , clearMentions, postComments, teamId]
  )

  const statistic = { commentCount: post.commentCount || 0, likeCount: post.likeCount || 0 }

  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const history = useOnbHistory()

  //handle appear contents
  const isAction: boolean = post.type === OnboardingPostType.Action && Boolean(post.action)
  const isDaily: boolean = post.type === OnboardingPostType.Daily && Boolean(post.daily)
  const isPost: boolean = post.type === OnboardingPostType.Post
  const isActionDone: boolean = post.type === OnboardingPostType.ActionDone
  const isSummary: boolean = post.type === OnboardingPostType.Ranking
  const isAppearPostOwner = isAction || isDaily || isPost || isActionDone

  const { ttRef, clearRefStyle } = useTutorial(tutorial)
  const ttonlike = tutorial?.tooltip?.onWhat === 'like'
  const ttonSample = tutorial?.tooltip?.onWhat === 'sample'

  return (
    (<div
      className={classNames([classes.container, ttonSample ? classes.preventClick : ''])}
      ref={ttonSample ? (ttRef as React.MutableRefObject<HTMLDivElement>) : null}
    >
      <div className={classes.mainContent}>
        {isAppearPostOwner ? <AvatarBox post={post} postUser={postUser} onAvatar={onAvatar} /> : <></>}
        <div className={isSummary ? classes.summaryContainer : classes.contentContainer}>
          <div className={classes.linkContainer} onClick={onComment}>
            {isAppearPostOwner ? <UserNameBox post={post} postUser={postUser} /> : <></>}
            {isAction || isDaily ? (
              post.comment ? (
                <Comment text={post.comment} isPost teamMembers={teamMembers} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {isAction && post.action ? ( // post.action is duplicated but it is used
              (<CardContent
                item={{
                  id: post.action.id,
                  importance: post.action.importance,
                  mission: post.action.mission,
                  what: post.action.what,
                  why: post.action.why,
                  how: post.action.how,
                  category: tutorial ? 'TUTORIAL' : post.action.category,
                  period: new Date().getTime(),
                  missionValues: undefined,
                  anketValues: undefined,
                  point: undefined,
                  weekDate: undefined,
                  valuesId: undefined,
                }}
                label={post.label}
              />)
            ) : (
              <></>
            )}
            {isDaily && post.daily ? <DailyCheckBox post={post} isSmUp={isSmUp} /> : <></>}
            {isPost || isActionDone ? <PostTypePost post={post} teamMembers={teamMembers} /> : <></>}
            {isSummary ? <WeeklySummary post={post} teamId={teamId} /> : <></>}
          </div>
          {statistic ? (
            <div className={classes.statisticContainer}>
              <div className={classes.statisticIcons}>
                {statistic.commentCount !== undefined ? (
                  <>
                    {post.type !== 'RANKING' && (
                      <div onClick={onCommentFocus} className={classes.statisticItem}>
                        <img src={BalloonSvg} alt="balloon" />
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Reaction
                  postId={post.id}
                  teamId={teamId}
                  isLiked={post.isLiked}
                  ttRef={ttRef}
                  ttOnLike={ttonlike}
                  next={ttonlike ? tutorial?.next : undefined}
                  clearRefStyle={clearRefStyle}
                />
              </div>
              {post.likeCount ? (
                <div className={classes.statisticLikes}>
                  <NoStyleButton
                    className={classes.countButton}
                    onClick={() => history.push(OnbPages.TimelinePostLikes, { teamId, postId: post.id })}
                  >
                    {post.likeCount} いいね
                  </NoStyleButton>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={classes.containerBorder}></div>
      {/* Comment */}
      {post.type !== 'RANKING' && (
        <div
          className={classes.actionsContainer}
          ref={ttonlike ? null : (ttRef as React.MutableRefObject<HTMLDivElement>)}
        >
          {postComments.length > 0 ? (
            <div>
              {statistic?.commentCount ? (
                <div className={classes.commentCount}>コメント {statistic?.commentCount}件</div>
              ) : (
                <></>
              )}
              <div className={classes.latestComment}>
                <CommentListItem teamId={teamId} item={postComments[0]} teamMembers={teamMembers} />
              </div>
              {/* commentReply */}
              <div onClick={onReplyTextClick} className={classes.commentReplyText}>
                {commentReplyOpen ? <>閉じる</> : <>このコメントに返信する</>}
              </div>
              {commentReplyOpen ? (
                <form onSubmit={handleSubmit(onSubmit)} className={classes.formReply}>
                  <div
                    className={classes.textareaReplyBack}
                    dangerouslySetInnerHTML={{ __html: convertMentionsStylesForInput() }}
                  />
                  <textarea
                    id={id}
                    name={inputName}
                    className={classes.textareaReply}
                    placeholder={'返信する'}
                    onKeyDown={onKeyDown as unknown as KeyboardEventHandler<HTMLTextAreaElement>}
                    autoFocus
                    required
                    maxLength={maxLength}
                    ref={register}
                  />
                  <SuggestBox ownRootStyles={{ right: 75, width: 170 }} ownItemStyles={{ right: 75, width: 170 }} />
                  <div>
                    <div className={classes.inputAdornment}>
                      <div>
                        <NoStyleButton
                          className={`${classes.replyButton} ${classes.mention}`}
                          type="button"
                          onClick={openSuggestionBox}
                        >
                          @
                        </NoStyleButton>
                      </div>
                      <div>
                        <NoStyleButton
                          className={`${classes.replyButton} ${classes.send}${
                            !watchInput || errors.text ? ' ' + classes.disabled : ''
                          }`}
                          type="submit"
                          disabled={!watchInput || !!errors.text}
                        >
                          送信
                        </NoStyleButton>
                      </div>
                    </div>
                  </div>
                  <FormCharCount length={watchInput.length} maxLength={maxLength} />
                </form>
              ) : (
                <></>
              )}
              {statistic?.commentCount && statistic?.commentCount >= 1 ? (
                <div className={classes.commentContainerLink} onClick={onComment}>
                  コメントを全て見る
                </div>
              ) : (
                <div style={{ paddingTop: 24 }}></div>
              )}
            </div>
          ) : (
            <></>
          )}
          <div>
            <MessageInput
              teamId={teamId}
              onSend={(text, mentions) => {
                if (tutorial) {
                  clearRefStyle()
                  tutorial.next && !ttonlike && tutorial.next()
                } else createComment(post.id, text, teamId, mentions)
              }}
              imageUrl={teamMember?.imageUrl ?? ''}
              teamMembers={teamMembers}
              postId={post.id}
            />
          </div>
        </div>
      )}
    </div>)
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: constants.COLOR_WHITE,
    marginTop: 16,
    paddingTop: 24,
    boxShadow: '0px 0px 0px #0000004',
  },
  preventClick: {
    pointerEvents: 'none',
  },
  mainContent: {
    padding: '0 16px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  summaryContainer: {
    flex: 1,
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  usernameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },

  usernameButtonContainer: {
    height: 40,
  },

  userAvatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: constants.COLOR_GRAY,
    objectFit: 'cover',
  },
  postLabel: {
    '& img': {
      width: 60,
      verticalAlign: 'top',
    },
  },
  userContent: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    width: '100%',
  },
  userName: {
    marginRight: 8,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1,
    color: constants.TEXT_GRAY_DARK,
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',
  },
  userTime: {
    marginTop: 7,
    fontSize: 10,
    lineHeight: 1,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
  },
  userRole: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 10,
    marginTop: 11,
    paddingLeft: 8,
    paddingRight: 8,
    color: constants.COLOR_ONBOARDING_MAIN,
    border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    height: '20px',
    fontWeight: 'bold',
  },
  contentContainer: {
    marginLeft: 16,
    flex: 1,
  },
  linkContainer: {
    paddingTop: 3,
    cursor: 'pointer',
  },
  dailyContainer: {
    paddingTop: 24,
  },
  dailyText: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 12,
  },
  contentImage: {
    width: '100%',
    maxWidth: 274,
    verticalAlign: 'top',
  },
  statisticContainer: {
    marginTop: 24,
  },
  statisticIcons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
    alignItems: 'center',
    gap: '16px 24px',
    width: '100%',
  },
  statisticItem: {
    cursor: 'pointer',
    height: 24,
  },
  statisticLikes: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
    height: 24,
  },
  commentReplyText: {
    marginTop: 9,
    marginBottom: 8,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },
  formReply: {
    marginTop: 8,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  textareaReplyBack: ({ mentionBackWidth }: SubStyleProps) => ({
    // for use mention highlight
    position: 'absolute',
    top: 0,
    left: 0,
    width: mentionBackWidth || '100%',
    color: 'transparent',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    overflow: 'hidden',
    // for same as textarea
    margin: '10px 0px 10px 0px',
    padding: '0px 50px 10px 16px',
    fontSize: 12,
    letterSpacing: 0.1,
    lineHeight: 1.6,
  }),
  textareaReply: ({ mentionBackHeight }: SubStyleProps) => ({
    resize: 'none',
    border: 'none',
    flex: 1,
    width: '100%',
    height: mentionBackHeight,
    padding: '10px 50px 10px 16px',
    // for mentions
    background: 'transparent',
    position: 'relative',
    zIndex: 2,
    // for same as textareaBack
    fontSize: 12,
    letterSpacing: 0.1,
    lineHeight: 1.6,
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    '&:focus-visible': {
      border: 'none',
      outline: 'none',
    },
  }),
  inputAdornment: {
    display: 'flex',
    gap: '8px',
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  replyButton: {
    backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
    minWidth: 56,
    minHeight: 32,
    color: constants.COLOR_MAIN_NEW,
    fontWeight: 'bold',
    borderRadius: 4,
  },
  mention: {
    fontSize: 20,
  },
  send: {
    fontSize: 12,
  },
  disabled: {
    color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
  },

  countButton: {
    color: constants.COLOR_GRAY_DARK,
    fontSize: 12,
  },
  containerBorder: {
    marginTop: 16,
    borderTopColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
  actionsContainer: {
    padding: '17px 16px 16px',
    width: '100%',
    backgroundColor: constants.COLOR_WHITE,
  },
  commentCount: {
    marginTop: 17,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
  },
  latestComment: {
    marginTop: 18,
  },

  // comments
  commentContainerLink: {
    display: 'block',
    marginTop: 9,
    marginBottom: 26,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 12,
    cursor: 'pointer',
    '&:hover': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },
}))
