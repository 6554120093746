import React from 'react'

import { TextField, Typography } from '@mui/material'
import type { Mark } from '@mui/material/Slider/useSlider.types.d.ts'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Control, Controller, DeepMap, FieldError, useWatch } from 'react-hook-form'

import { FormError } from 'pages/teams/components'
import { TeamBuildingReviewTeamAnswerItem } from 'utils/generated'

import Slider from './Slider'

const VALUES: Mark[] = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
]
export type QuestionInfo = {
  label: string
}

type Props = QuestionInfo & {
  prevValue: TeamBuildingReviewTeamAnswerItem | undefined
  name: string
  control: Control<any>
  error?: DeepMap<{ [key: string]: number } | undefined, FieldError>
}

export const Step2QuestionSlider: React.FC<Props> = ({ label, prevValue, name, control, error }) => {
  const classes = useStyles()
  const current = useWatch<number>({ name, control })
  return (
    <div className={classes.question}>
      <div className={classes.questionLabel}>{label}</div>

      <div className={classes.sliderWrap}>
        <div className={classes.slider}>
          <div className={classes.sliderLabel}>低い</div>
          <div style={{ width: '100%' }}>
            <Controller
              name={name}
              control={control}
              defaultValue={5}
              value={current ?? 5}
              rules={{ required: '入力してください' }}
              render={({ onChange, name }) => (
                <>
                  <Slider
                    name={name}
                    options={VALUES}
                    value={current ?? 5}
                    onChange={onChange}
                    aria-labelledby="input-slider"
                  />
                </>
              )}
            />
          </div>
          <div className={classes.sliderLabel}>高い</div>
        </div>
        <div>
          <div className={classes.sliderLabel}>前回: {prevValue ? `${prevValue.value}点` : 'なし'}</div>

          <Controller
            name={name}
            control={control}
            defaultValue={5}
            value={current ?? 5}
            render={({ onChange }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  className={classes.text}
                  type="number"
                  onChange={onChange}
                  value={current ?? 5}
                  variant="outlined"
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 10,
                    style: { width: 81, padding: '13px 3px', textAlign: 'right' },
                  }}
                  aria-labelledby="input-slider"
                />
                <span className={`${classes.inputLabel} __point`}>点</span>
              </div>
            )}
          />
        </div>
      </div>

      <Typography variant="body1" color="error">
        {error && <FormError error={error[name]} />}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  text: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  sliderWrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
      gap: '16px',
      alignItems: 'flex-end',
    },
  },
  slider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginTop: 18,
  },
  sliderLabel: {
    color: '#707070',
    fontSize: 12,
    flexShrink: 0,
    height: 18,
  },
  questionLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  questionNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    borderRadius: '50%',
    width: 24,
    height: 24,
    backgroundColor: '#00838C',
    color: '#fff',
  },
  question: {
    margin: '0 auto',
    width: '100%',
    borderRadius: 8,
    '& .__comment': {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      fontSize: 12,
    },
  },

  input: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
  },
  inputLabel: {
    fontSize: 12,
    '&.__point': {
      marginLeft: 12,
    },
    '&.__minus': {
      textAlign: 'center',
      color: '#c62c2c',
    },
    '&.__plus': {
      textAlign: 'center',
      color: '#00838C',
    },
  },
}))
