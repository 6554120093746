import * as React from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import Radio from '@mui/material/Radio'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

import { GhostTeamMember, GhostMemberRole } from '../../../../utils/generated'

type Props = {
  currentMember: GhostTeamMember & { currentId?: string; isWaiting?: boolean }
  page: 'break' | 'else'
  clickable?: boolean
  selectedValue?: string //if checkable is FALSE ,this prop is no use
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void //if checkable is FALSE ,this prop is no use
}

export const MemberItem: React.FC<Props> = ({
  currentMember,
  page,
  clickable = false,
  selectedValue,
  handleChange,
}) => {
  const classes = useStyles({})
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const isBreakPage: boolean = page === 'break'
  const isElseClickablePage: boolean = !isBreakPage && clickable
  const role = currentMember.role === GhostMemberRole.Leader ? 'ファシリテーター' : 'メンバー'

  return (
    <>
      <div className={classes.boxWrapper}>
        {isBreakPage && isBreakpointsUp ? (
          <div className={classNames([classes.containerBreak, currentMember?.isWaiting ? classes.isWaiting : ''])}>
            <div className={classes.svgContainerBreak}>
              <img
                src={process.env.PUBLIC_URL + '/assets/ghost/personIcon.svg'}
                alt={'personIcon'}
                className={classes.svgBreak}
              />
              <div>
                <div className={classes.nameBreak}>
                  {currentMember.name}
                  <span className={classes.unAnswered}>{currentMember?.isWaiting ? '回答済' : '未回答'}</span>
                </div>
                <div className={classes.detailBreak}>{role}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className={classNames([classes.container, currentMember?.isWaiting ? classes.isWaiting : ''])}>
            <div className={classes.svgContainer}>
              <img
                src={process.env.PUBLIC_URL + '/assets/ghost/personIcon.svg'}
                alt={'personIcon'}
                className={classes.svg}
              />
              <div>
                <div className={classes.name}>
                  {currentMember.name}
                  <span className={classes.unAnswered}>
                    {isBreakPage ? (currentMember?.isWaiting ? '回答済' : '未回答') : ''}
                  </span>
                </div>
                <div className={classes.detail}>
                  {currentMember.role === GhostMemberRole.Admin ? '最初に立ち上げた方' : role}
                </div>
              </div>
            </div>
            {isElseClickablePage ? (
              <Radio
                checked={selectedValue === currentMember.id}
                onChange={handleChange}
                value={currentMember.id}
                name="radio-button-demo"
                icon={<img src={process.env.PUBLIC_URL + '/assets/ghost/emptyCircle.svg'} alt={'emptyCircle'} />}
                checkedIcon={<img src={process.env.PUBLIC_URL + '/assets/ghost/checkedCircle.svg'} alt={'circle'} />}
                classes={{ root: classes.muiRadioRoot }}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    boxWrapper: {
      backgroundColor: constGhost.COLOR_WHITE,
      border: `solid 1px ${constGhost.COLOR_LIGHTGRAY3}`,
      borderRadius: 8,
      overflow: 'hidden',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'calc(50% - 8px)', //8px is a half of gap
      },
    },
    //CSS for selectLeaderPage
    container: {
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    svg: {
      marginRight: 8,
    },
    svgContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    name: {
      color: constGhost.COLOR_BLACK3,
      fontSize: 16,
      fontWeight: 'bold',
    },
    detail: {
      color: constGhost.COLOR_GRAY2,
      fontSize: 10,
    },
    //CSS for breakPage
    containerBreak: {
      padding: '16px',
    },
    isWaiting: {
      backgroundColor: constGhost.COLOR_LIGHTGRAY2,
    },
    svgContainerBreak: {
      display: 'flex',
      width: '100%',
    },
    svgBreak: {
      marginRight: 8,
    },
    nameBreak: {
      color: constGhost.COLOR_BLACK3,
      fontSize: 16,
      fontWeight: 'bold',
    },
    unAnswered: {
      color: constGhost.COLOR_GRAYTEXT,
      fontSize: 10,
      fontWeight: 600,
      paddingLeft: 8,
    },
    detailBreak: {
      fontSize: 10,
      margin: '2px 0 0',
      color: constGhost.COLOR_GRAY2,
    },
    muiRadioRoot: {
      '&.MuiRadio-colorSecondary.Mui-checked:hover': {
        backgroundColor: constGhost.COLOR_WHITE,
      },
      '&.MuiRadio-colorSecondary.Mui-checked': {
        color: constGhost.COLOR_WHITE,
      },
      '&.MuiIconButton-colorSecondary:hover': {
        backgroundColor: constGhost.COLOR_WHITE,
      },
      '&.MuiIconButton-root:hover': {
        backgroundColor: constGhost.COLOR_WHITE,
      },
    },
  }),
  { name: 'MemberItem' }
)
