import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { TextField, Dialog } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useForm } from 'react-hook-form'
import { useUtils } from 'services/api/utils'

import { Breadcrumb } from 'components/Breadcrumb'

import * as constants from '../../assets/constants'
import { CODE_MISMATCH_EXCEPTION } from '../../assets/errorCode'
import Button from '../../components/Button'
import MessagePanel from '../../components/MessagePanel'

import { connector, ContainerProps } from './reset_password.container'

import { PatternPass } from 'assets/patterns'

interface ICResetPassword {
  email: string
  code: string
  password: string
  passwordRe: string
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const { getEncryptString } = useUtils()

  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')
    const code = params.get('code')

    if (email) {
      getEncryptString({ encrypt: false, text: email }).then((decrypted) => {
        if (decrypted) {
          setValue('email', decrypted)
        }
      })
    } else {
      props.history.push('/')
      window.scrollTo(0, 0)
    }
    if (code) {
      setValue('code', code)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { classes } = props

  const { register, handleSubmit, errors, clearErrors, getValues, setValue } = useForm<ICResetPassword>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [errors])

  const onSubmit = (data: ICResetPassword) => {
    props.resetPassword({
      email: data.email.toLowerCase(),
      code: data.code,
      password: data.password,
    })
  }

  const handleClose = () => {
    clearErrors()
    props.refreshErrors()
  }

  React.useEffect(() => {
    if (props.user?.reset) {
      props.history.push(constants.PAGE_RESET_PASSWORD_COMPLETE)
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.reset])

  return (
    (<React.Fragment>
      <div className={classes.main}>
        <div className={classes.breadcrumb}>
          <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="パスワード再設定" />
        </div>
        <div className="__signin_resetPassword_container">
          <div
            style={{
              display: !props.user?.error && !errors.code && !errors.password && !errors.passwordRe ? 'block' : 'none',
            }}
          >
            <div className="__title">パスワード再設定</div>
            <div className="__desc">新しいパスワードを設定して下さい。</div>
            <div className="__info">
              <span>
                ※8文字〜24文字で、英字（大文字）、英字（小文字）、数字、記号(!、?など)の4種類を組み合わせたもので設定して下さい。
              </span>
            </div>

            <div className="__signin_resetPassword_container__body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="email" ref={register({})} />
                <div className="__formGroup">
                  <label className="__label">
                    <span className="__labelText">認証コード</span>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </label>
                  <TextField
                    variant="standard"
                    name="code"
                    className="__textField"
                    inputRef={register({
                      required: '認証コードを入力してください。',
                    })} />
                </div>
                <div className="__formGroup">
                  <label className="__label">
                    <span className="__labelText">新しいパスワード</span>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </label>
                  <TextField
                    variant="standard"
                    name="password"
                    className="__textField"
                    type="password"
                    inputRef={register({
                      required: '新しいパスワードを入力してください。',
                      pattern: {
                        value: PatternPass,
                        message: `パスワードを8文字〜24文字で、英字（大文字）、英字（小文字）、数字、記号の4種類を組み合わせたもので設定して下さい。
                        \n使用可能な記号は !"#$%&'()*+,-./:;<=>?@[]^_\`{|}~ です`,
                      },
                    })} />
                </div>
                <div className="__formGroup">
                  <label className="__label">
                    <span className="__labelText">確認のため再度ご入力下さい。※コピペ不可</span>
                    <div className="__labelSVG">
                      <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                    </div>
                  </label>
                  <TextField
                    variant="standard"
                    name="passwordRe"
                    className="__textField"
                    type="password"
                    inputRef={register({
                      required: '確認用パスワードを入力してください。',
                      validate: {
                        same: (value) =>
                          value === getValues('password') ||
                          `パスワードが一致しません。\nパスワードをご確認の上、もう一度入力し直して ください。`,
                      },
                    })} />
                </div>

                <div className="__buttonsWrapper">
                  <div className="__buttons">
                    <Button
                      bgColor={constants.COLOR_ORANGE_BTN}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      height={50}
                      submit={true}
                      body={<div>送信</div>}
                      noShadow
                      hoverBgColor={constants.TEXT_WHITE}
                      hoverTextColor={constants.COLOR_ORANGE_BTN}
                      hoverBorderColor={constants.COLOR_ORANGE_BTN}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>

          <Dialog
            open={!props.user?.error && !errors.code && !errors.password && !errors.passwordRe ? false : true}
            onClose={handleClose}
            className={classes.errorDialog}
          >
            <div
              style={{
                display:
                  !props.user?.error && !errors.code && !errors.password && !errors.passwordRe ? 'none' : 'block',
              }}
            >
              <MessagePanel
                failure={true}
                handleClose={handleClose}
                body={
                  errors.code ? (
                    errors.code.message
                  ) : errors.password ? (
                    errors.password.message
                  ) : errors.passwordRe ? (
                    errors.passwordRe.message
                  ) : props.user?.error && props.user?.error.code === CODE_MISMATCH_EXCEPTION ? (
                    <>
                      <p>コードが認証できませんでした。</p>
                      <p>確認コードを確認の上再度認証してください。</p>
                    </>
                  ) : (
                    <>
                      <p>{props.user?.error?.code}</p>
                      <p>{props.user?.error?.message}</p>
                    </>
                  )
                }
              />
            </div>
          </Dialog>
        </div>
      </div>
    </React.Fragment>)
  );
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      '& .MuiInputBase-input': {
        padding: 17,
      },

      '& .__signin_resetPassword_container': {
        margin: '0 auto',
        padding: '0px 24px 100px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 1000,
        },

        '& .__title': {
          marginTop: 80,
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bold',
          [theme.breakpoints.down('md')]: {
            marginTop: 32,
            fontSize: 20,
          },
        },
        '& .__desc': {
          marginTop: 28,
          textAlign: 'center',
          fontSize: 14,
          [theme.breakpoints.down('md')]: {
            marginTop: 22,
            fontSize: 13,
          },
        },
        '& .__info': {
          display: 'flex',
          justifyContent: 'center',
          padding: '8px 0 0 1em',
          textIndent: '-0.7em',
          '& span': {
            maxWidth: 480,
            fontSize: 12,
          },
        },

        '&__body': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: 8,
          marginTop: 40,
          padding: '12px 24px 40px',
          boxShadow: '1px 3px 9px 4px rgba(21, 27, 38, 0.15)',
          [theme.breakpoints.up('md')]: {
            padding: '48px 200px 80px',
          },

          '& form': {
            '& .__formGroup': {
              margin: '32px 0 0',
              '& label': {
                display: 'block',
                marginBottom: 12,
                '& .__labelText': {
                  marginRight: 8,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
                '& .__labelSVG': {
                  display: 'inline-block',
                  position: 'relative',
                  top: 2,
                  width: 26,
                  height: 15,
                  '& img': {
                    width: '100%',
                    height: '100%',
                  },
                },
              },
            },

            '& .__textField': {
              width: '100%',
              border: `2px solid ${constants.BORDER_LIGHTGRAY}`,
              borderRadius: 8,

              '& .MuiInput-underline:before': {
                border: 'none', // Semi-transparent underline
              },
              '& .MuiInput-underline': {
                '&:after': {
                  border: 'none', // Solid underline on focus
                },
              },
            },

            '& .__buttonsWrapper': {
              display: 'flex',
              justifyContent: 'center',
              '& .__buttons': {
                margin: '40px auto 0',
                width: 200,
                [theme.breakpoints.down('md')]: {
                  width: 250,
                },
              },
            },
          },
        },
      },
    },
    breadcrumb: {
      margin: '0 auto',
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
    errorDialog: {
      '& .MuiDialog-paper': {
        margin: '32px 24px',
        borderRadius: 8,
        maxWidth: 327,
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
