import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { IuseTitleProps } from './useTitle'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    fontFamily: theme.typography.fontFamily,
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  className?: string
  name: string
  value: string
  presenter: IuseTitleProps
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

// -----------------------------
// Props
// -----------------------------
export const Title = React.forwardRef<HTMLTextAreaElement | null, IProps>(({ ...props }, ref) => {
  const classes = useStyles()

  React.useEffect(() => {
    if (props.value) {
      props.presenter.onUpdateSetTitle(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <textarea
      {...props}
      ref={ref}
      name={props.name}
      value={props.presenter.getTitle()}
      placeholder={props.presenter.getPlaceholder()}
      className={`${props.className} ${classes.root}`}
      onKeyDown={props.presenter.onPreventEnter}
      onChange={props.onChange}
      maxLength={props.presenter.getMaxTitleLen(50)}
    />
  )
})
