import React from 'react'

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import { TeamBuildingStatus } from 'utils/generated'

type Props = {
  buttons: React.ReactNode[]
  fixed?: boolean
  marginTopPcLayout?: number
  buildingStatus?: TeamBuildingStatus
  nowWrap?: boolean
}

export const ButtonContainer: React.FC<Props> = (props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const isCompleted = props.buildingStatus && props.buildingStatus === 'COMPLETED' ? true : false
  const classes = useStyles({ fixed: props.fixed, isSmDown, marginTop: props.marginTopPcLayout ?? 80, isCompleted })

  return (
    <div className={classes.root} data-testid="buttonContainer">
      {(() => {
        switch (props.buttons.length) {
          case 1:
            return <div className={classes.base}>{props.buttons[0]}</div>

          case 2:
            return (
              <div className={`${classes.base} ${classes.double}`}>
                <div className={classes.doubleLeft}>{props.buttons[1]}</div>
                <div className={classes.doubleRight}>{props.buttons[0]}</div>
              </div>
            )
          default:
            return <div className={classes.base}>{props.buttons}</div>
        }
      })()}
    </div>
  )
}

type StyleProps = {
  fixed?: boolean
  isSmDown?: boolean
  marginTop?: number
  isCompleted?: boolean
  nowWrap?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ fixed, marginTop, isSmDown, isCompleted }: StyleProps) => ({
    width: isSmDown || isCompleted ? '100%' : 'calc(100vw - 340px)',
    left: fixed && isSmDown ? 0 : undefined,
    right: 0,
    bottom: 0,
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    padding: fixed && isSmDown ? '30px 16px 30px' : '16px',
    backgroundColor: '#ffffff',

    [theme.breakpoints.down('sm')]: {
      padding: fixed ? '16px 16px 16px' : '0 16px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop,
    },
  }),

  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  double: {
    [theme.breakpoints.up('sm')]: {
      gap: '32px 24px',
      flexWrap: ({ nowWrap }) => (nowWrap ? 'nowrap' : 'wrap-reverse'),
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },

  doubleLeft: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  doubleRight: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      width: '100%',
    },
  },
}))
