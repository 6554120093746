import React, { useEffect, useContext, useRef } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Button, CircularProgress } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { replacePathParams } from 'pages/onboarding/navigation/route'

import CocolaboAvatarSvg from '../../assets/cocolaboAvatar.svg'
import NoImage from '../../assets/noImage.svg'
import { NotificationBatch, NotificationMark } from '../../components/notification'
import { useIntersection, Tab } from '../../hooks/notifycation'
import { useTeam } from '../../hooks/team'
import { setNotificationMsg, setNotificationUrl } from '../../utils/notification'
import { notificationCtx, OnbContext } from '../PagesRoot'

import { Notification } from './components'
import { NotificationModal } from './components/NotificationModal'

import GearIcon from './assets/gear.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

const Index: React.FC<RouteComponentProps<{ teamId: string }>> = (props) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const MAXLIMIT = 100
  const classes = useStyles()
  const teamId = props.match.params.teamId

  const {
    notifications,
    isNotification,
    newsList,
    isNews,
    unCheckedNotify,
    loading,
    tab,
    setTab,
    getNotification,
    updateNotification,
    moreNotificationList,
    moreNewsList,
  } = useContext(notificationCtx)
  const { teamMember } = useContext(OnbContext)
  const { team } = useTeam(teamId)

  const makeTabStyles = (tabName: Tab) =>
    tab === tabName
      ? { backgroundColor: constants.COLOR_WHITE, color: constants.COLOR_ONBOARDING_MAIN }
      : { color: constants.COLOR_ONBOARDING_GRAY_DARK }

  const getBatch = (id: string) => {
    return getNotification(id)
  }

  const updateBatch = (id: string) => {
    updateNotification(id)
  }

  //// observer ////
  const el = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>
  const { intersecting } = useIntersection(el)

  useEffect(() => {
    if (intersecting && !loading) {
      if (isNotification && tab === 'activity') {
        moreNotificationList()
      }
    }
  }, [intersecting, loading, isNotification, tab, moreNotificationList])
  useEffect(() => {
    if (intersecting && !loading) {
      if (isNews && tab === 'news') {
        moreNewsList()
      }
    }
  }, [intersecting, loading, isNews, tab, moreNewsList])

  return (
    <>
      <TitleWithBack title="お知らせ" omitBack />
      <div className={classes.tabHeader}>
        <div className={classes.tabBox}>
          <Button
            variant="text"
            className={classes.tab}
            style={makeTabStyles('activity')}
            onClick={() => setTab('activity')}
          >
            アクティビティ
            <Batch tabName="activity" tab={tab} unCheckedNotify={unCheckedNotify} />
          </Button>
          <Button variant="text" className={classes.tab} style={makeTabStyles('news')} onClick={() => setTab('news')}>
            ニュース
            <Batch tabName="news" tab={tab} unCheckedNotify={0} />
          </Button>
        </div>
        <Button variant="text" className={classes.notificationSetting} onClick={() => setOpen(true)}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: 'bold' }}>
            <img height={16} width={16} src={GearIcon} />
            通知設定
          </div>
        </Button>
      </div>
      <div className={classes.container}>
        {tab === 'activity' && (
          <>
            {notifications &&
              notifications.map((item, index) => {
                return (
                  <div
                    key={`onb-notification-key-${index}`}
                    style={{ marginTop: index === 0 ? 0 : 16, cursor: 'pointer' }}
                  >
                    {item && (
                      <Notification
                        id={item.id}
                        time={item.createdAt}
                        description={setNotificationMsg(
                          item.type,
                          team?.title,
                          item.teamMember.nickname ? item.teamMember.nickname : 'NoName'
                        )}
                        avatarSrc={item.teamMember.imageUrl ?? NoImage}
                        title={item.teamMember.nickname ?? 'NoName'}
                        tab={tab}
                        url={`${setNotificationUrl(
                          item.type,
                          item.teamId,
                          teamMember.userId,
                          item.actionId
                        )}?p=notification`}
                        getBatch={getBatch}
                        updateBatch={updateBatch}
                      />
                    )}
                  </div>
                )
              })}
          </>
        )}

        {tab === 'news' && (
          <>
            {newsList &&
              newsList.map((item, index) => (
                <div
                  key={`onb-notification-key-${index}`}
                  style={{ marginTop: index === 0 ? 0 : 16, cursor: 'pointer' }}
                >
                  {item && (
                    <Notification
                      id={item.id}
                      time={item.createdAt}
                      description={item.description}
                      avatarSrc={CocolaboAvatarSvg}
                      title={item.title}
                      tab={tab}
                      url={replacePathParams(OnbPages.NotificationDetail, { teamId, notificationId: item.id })}
                      getBatch={() => undefined} // add get func if create newsNotification Table
                      updateBatch={() => undefined} // add get func if create newsNotification Table
                    />
                  )}
                </div>
              ))}
          </>
        )}
        <NotificationModal teamId={teamId} open={open} setOpen={setOpen} />

        {!loading ? (
          <>
            <>
              {isNotification && tab === 'activity' && notifications.length < MAXLIMIT && (
                <div style={{ marginTop: 50 }} ref={el} />
              )}
            </>
            <>{isNews && tab === 'news' && newsList.length < MAXLIMIT && <div style={{ marginTop: 50 }} ref={el} />}</>
          </>
        ) : (
          <div className={classes.loading}>
            <CircularProgress size={34} />
          </div>
        )}
      </div>
    </>
  )
}

type BatchProps = {
  tabName: Tab
  tab: Tab
  unCheckedNotify: number
}
const Batch: React.FC<BatchProps> = ({ tabName, tab, unCheckedNotify }) => {
  const classes = useStyles()
  return (
    <div className={tab === tabName ? classes.tabBatch : classes.tabMark}>
      {tab === tabName ? <NotificationBatch count={unCheckedNotify} /> : <NotificationMark count={unCheckedNotify} />}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tabHeader: {
    display: 'flex',
  },
  tabBox: {
    margin: '0 16px',
    padding: 4,
    backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
    borderRadius: 4,
    display: 'flex',
    flex: 1,
    '@media (max-width: 632px)': {
      margin: '0 16px 0 8px',
    },
  },
  tab: {
    borderRadius: 4,
    flex: 1,
    fontSize: 14,
    position: 'relative',
    '@media (max-width: 375px)': {
      fontSize: 12,
    },
  },

  notificationSetting: {
    color: '#00838C',
    borderRadius: 4,
    flex: 0,
    fontSize: 10,
    margin: '0 16px',
    backgroundColor: constants.COLOR_WHITE,
    '@media (max-width: 632px)': {
      margin: '0 16px 0 0',
    },
  },

  container: {
    margin: '24px 16px 0',
    paddingBottom: 20,
  },

  tabBatch: {
    position: 'absolute',
    top: -3,
    right: 0,
  },
  tabMark: {
    position: 'relative',
    top: -5,
    right: -7,
  },

  loading: {
    marginTop: 49,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },
}))

export default Index
