import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import DisplaySvg from '../../../../assets/display.svg'
import GraphSvg from '../../../../assets/graph.svg'
import PeopleSvg from '../../../../assets/people.svg'
import { getKarteValueType, KarteValueType } from '../../../../utils'
import { getTypeClass, VALUE_HEIGHT } from '../DataList'

const getImageIcon = (type: KarteValueType | null) => {
  switch (type) {
    case KarteValueType.Result: // 成果重視傾向
      return GraphSvg
    case KarteValueType.Achievement: // 実績重視主義
      return DisplaySvg
    case KarteValueType.TeamBuilding: // チーム作り重視傾向
      return PeopleSvg
  }
}

type Props = {
  title?: string
  trend?: string
}

export const ValueLabel: React.FC<Props> = ({ title, trend }) => {
  const classes = useStyles()
  const type = getKarteValueType(title)

  return (
    <div className={classNames(classes.box, classes.value, getTypeClass(type))}>
      {title && (
        <>
          <img className={classes.valueLabelIcon} src={getImageIcon(type)} />
          {trend ?? title}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    box: {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      height: VALUE_HEIGHT,
    },
    value: {
      fontSize: 10,
      padding: 7,
      marginBottom: 2,
      flex: 1,
    },
    valueLabelIcon: {
      marginRight: 8,
      width: 16,
      height: 16,
      maxWidth: 16,
      maxHeight: 16,
      minWidth: 16,
      minHeight: 16,
      overflow: 'hidden',
    },
  }),
  { name: 'ValueLabel' }
)
