import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { LogoWhiteText } from 'components/icon/LogoWhiteText'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

export const headerHeight = { pc: 96, sp: 60 }

export const Header: React.FC = () => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'md')

  return (
    <header className={classes.header}>
      <div>
        <LogoWhiteText width={isSmDown ? 100 : 142} height={isSmDown ? 16 : 18} />
      </div>
      <div className={classes.headerTitle}>
        <img src={`${process.env.PUBLIC_URL}/img/obakesagashi/header-title.svg`} alt="職場のオバケ探し" />
      </div>
    </header>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: headerHeight.pc,
      padding: '0 47px',
      background: constants.COLOR_NAVY,
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.HATARAKU_FONT_FAMILY,
      [theme.breakpoints.down('md')]: {
        height: headerHeight.sp,
        padding: '0 16px',
      },
    },
    headerTitle: {
      width: 180,
      [theme.breakpoints.up('md')]: {
        width: 266,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '& img': {
        width: '100%',
      },
    },
  }),
  { name: 'Header' }
)
