import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = {
  placeholder: string
  onPress?: () => void
  disabled?: boolean
  submit?: boolean
  SPwidth?: boolean
}
type StyleProps = {
  SPwidth?: boolean
  ownStyles?: { [k: string]: string | number | undefined }
}
/**
 * Simple large submit button for forms.
 * Especially made for /onboarding/pages/action-manage-form
 */
export const SubmitButton: React.FC<Props & StyleProps> = ({
  placeholder,
  onPress,
  disabled,
  submit,
  SPwidth,
  ownStyles,
}) => {
  const classes = useStyles({ SPwidth, ownStyles })

  return (
    <button className={classes.button} onClick={onPress} disabled={disabled} type={submit ? 'submit' : undefined}>
      {placeholder}
    </button>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  button: ({ ownStyles }) => ({
    border: '1px solid',
    borderColor: constants.COLOR_ONBOARDING_MAIN,
    borderRadius: 10,

    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#fff',

    padding: '12px 16px',
    minWidth: 300,
    margin: 16,
    cursor: 'pointer',
    ...ownStyles,
    [theme.breakpoints.down('md')]: {
      minWidth: ({ SPwidth }) => (SPwidth ? '90%' : 'none'),
    },

    '&:hover': {
      opacity: 0.8,
    },

    '&[disabled]': {
      cursor: 'default',
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      color: constants.COLOR_ONBOARDING_GRAY,
      border: 'none',
      '&:hover': {
        opacity: 1,
      },
    },
  }),
}))
