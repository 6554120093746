import * as React from 'react'
import { useHistory } from 'react-router-dom'

import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useForm } from 'react-hook-form'

import { CircularProgressNoWrapper } from 'components/CircularProgressNoWrapper'
import { NoStyleButton } from 'components/NoStyleButton'
import { CreateNoSignInGhostQuestions } from 'utils/generated'

import { useObakesagashi } from '../hooks/obakesagashi'
import {
  IObakesagashiQuestionnaireAnswerSet,
  IObakesagashiQuestionnaireAnswerValueSet,
  IObakesagashiQuestionnaireFormField,
} from '../utils/type'

import { constants, Pages } from 'assets'
import { PERSONAL_QUESTIONS } from 'assets/constants'
import { replacePathParams } from 'assets/history'

type Props = {
  selectedGhostIds: number[]
}

export const PersonalQuestions: React.FC<Props> = ({ selectedGhostIds }) => {
  const classes = useStyles()
  const history = useHistory()
  const [answers, setAnswers] = React.useState({} as IObakesagashiQuestionnaireAnswerSet)
  const { createNoSignInGhost, errors: createErrors, isCreateLoading } = useObakesagashi()

  const { register, handleSubmit, setValue, errors } = useForm<IObakesagashiQuestionnaireAnswerValueSet>({
    mode: 'onChange',
  })

  // useForm と useState の併用により、isValid の計算を useMemo で行う。
  const isValid = React.useMemo(() => {
    return PERSONAL_QUESTIONS.fields.every((field) => answers && answers[field.group]?.[field.key] !== undefined)
  }, [answers])

  const onSubmit = async (data: IObakesagashiQuestionnaireAnswerValueSet) => {
    const questions = {
      age: data['年代'],
      position: data['役職'],
      employeeSize: data['従業員規模'],
      gender: data['性別'],
      industry: data['業種'],
      occupation: data['職種'],
    } as CreateNoSignInGhostQuestions

    const res = await createNoSignInGhost({ questions, selectedGhostIdList: selectedGhostIds })
    if (res) {
      history.push(replacePathParams(Pages.ObakesagashiResult, { id: res.id }))
      window.scrollTo(0, 0)
    }
  }

  React.useEffect(() => {
    PERSONAL_QUESTIONS.fields.forEach((field) => {
      register(field.key, { required: `${field.label}は必須です` })
      if (answers[field.group] && answers[field.group][field.key]) {
        setValue(field.key, answers[field.group][field.key])
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderQuestionnaireField = (field: IObakesagashiQuestionnaireFormField, index: number) => {
    const getFormAnswer = (group: string, fieldKey: string) => {
      return answers[group] && answers[group][fieldKey] ? answers[group][fieldKey] : null
    }
    const setFormAnswer = (group: string, fieldKey: string, value: string) => {
      const localAnswers = { ...answers }
      if (!answers[group]) {
        localAnswers[group] = {} as IObakesagashiQuestionnaireAnswerValueSet
      }
      localAnswers[group][fieldKey] = value
      setAnswers(localAnswers)
      setValue(field.key, value)
    }
    const errorMessage =
      errors.hasOwnProperty(field.key) && errors[field.key] ? errors[field.key]?.message || 'Unknown error' : null

    if (field.type === 'radio') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">{field.label}</div>
          <div>
            <RadioGroup
              row={true}
              className={classes.radioGroup}
              aria-label={field.key}
              value={getFormAnswer(field.group, field.key)}
              name={field.key}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
            >
              {field.options.map((option, optionIndex) => (
                <FormControlLabel
                  key={optionIndex}
                  className={classes.radioControl}
                  value={option.value}
                  control={
                    <Radio
                      disableRipple
                      classes={{
                        root: classes.radioRoot,
                        checked: classes.radioChecked,
                      }}
                    />
                  }
                  label={option.label}
                />
              ))}
            </RadioGroup>
            {errorMessage ? <small className={classes.formErrorMsg}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else if (field.type === 'select') {
      return (
        <div key={index}>
          <div className="__container__content__body__title">{field.label}</div>
          <div className="__container__content__body__select">
            <Select
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={getFormAnswer(field.group, field.key) || ''}
              onChange={(e) => setFormAnswer(field.group, field.key, e.target.value as string)}
              autoWidth={true}
              displayEmpty={true}
              disableUnderline={true}
              classes={{
                select: '__container__content__body__select__root',
                icon: '__container__content__body__select__icon',
              }}
              className={`__container__content__body__select__select ${
                getFormAnswer(field.group, field.key) !== null ? 'hasValue' : ''
              }`}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              <MenuItem className={classes.menuItem} value="" disabled>
                {field.label}を選択
              </MenuItem>
              {field.options.map((option, optionIndex) => (
                <MenuItem key={optionIndex} className={classes.menuItem} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errorMessage ? <small className={classes.formErrorMsg}>{errorMessage}</small> : null}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const renderContent = () => {
    return (
      <div className="__container">
        <div className="__container__contentWrapper">
          <div className="__container__content">
            <div className="title-wrapper">
              <h1 className="title">
                最後にあなたについて、
                <br />
                かんたんに教えてください
              </h1>
              <img
                className="title-image"
                src={`${process.env.PUBLIC_URL}/img/obakesagashi/tie-mini-ghost.svg`}
                alt="ネクタイをしたオバケ"
              />
            </div>
            <p className="title-sub">
              あなたと似たタイプの人たちがどんなオバケを見つけているのか、知ることができます。
            </p>

            <div className="__container__content__body">
              {PERSONAL_QUESTIONS.fields.map(renderQuestionnaireField)}

              {createErrors?.length ? (
                <div className={classes.createErrorsBox}>
                  <ul className={classes.errorMessages}>
                    {createErrors.map((error, index) => (
                      <li key={index}>{error.message}</li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <div className="__container__button">
                <NoStyleButton type="submit" className="nextButton" disabled={isCreateLoading || !isValid}>
                  {isCreateLoading ? (
                    <CircularProgressNoWrapper color={constants.COLOR_NAVY} />
                  ) : (
                    '職場にいたオバケを確認'
                  )}
                </NoStyleButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.main}>
      <form onSubmit={handleSubmit(onSubmit)}>{renderContent()}</form>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      paddingBottom: 120,
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      background: constants.COLOR_NAVY,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 182,
      },

      '& .__container': {
        color: '#fff',

        '&__contentWrapper': {
          padding: '0 24px',
          [theme.breakpoints.down('md')]: {
            padding: '0 16px',
          },
        },

        '&__content': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 auto',
          paddingTop: 40,
          width: '100%',
          [theme.breakpoints.down('md')]: {
            paddingTop: 54,
          },
          '& .title-wrapper': {
            position: 'relative',
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              columnGap: 6,
            },
          },
          '& .title': {
            margin: 0,
            fontSize: 40,
            fontWeight: 600,
            [theme.breakpoints.down(1120)]: {
              fontSize: 32,
            },
            [theme.breakpoints.down('md')]: {
              fontSize: 24,
            },
            [theme.breakpoints.up('sm')]: {
              '& br': {
                display: 'none',
              },
            },
          },
          '& .title-image': {
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              top: 0,
              right: -87,
              width: 77,
            },
            [theme.breakpoints.down('md')]: {
              flexShrink: 0,
              alignSelf: 'flex-end',
              width: 42,
              paddingBottom: 6,
            },
          },
          '& .title-sub': {
            margin: '4px 0 0',
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
              margin: '12px 0 0',
            },
          },

          '&__body': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 40,
            padding: '56px 0 0',
            width: '100%',
            maxWidth: 1000,
            borderRadius: 16,
            [theme.breakpoints.up('md')]: {
              rowGap: 36,
              marginTop: 40,
              padding: '76px 176px 80px',
              border: '2px solid #FFF',
            },

            '&__title': {
              marginBottom: 18,
              width: 272,
              fontSize: 24,
              fontWeight: 'bold',
              [theme.breakpoints.down('md')]: {
                marginBottom: 14,
                fontSize: 16,
              },
            },
            '&__select': {
              width: '100%',
              '&__select': {
                width: '100%',
                height: 60,
                background: '#FFF',
                border: '1px solid #CCC',
                borderRadius: 8,
                '&.hasValue': {
                  '& .__container__content__body__select__root': {
                    color: '#000 !important',
                  },
                },
              },

              '&__root': {
                display: 'flex',
                alignItems: 'center',
                padding: '0 24px',
                height: 64,
                fontSize: 16,
                color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
                [theme.breakpoints.down('md')]: {
                  padding: '0 16px',
                },
              },

              '&__icon': {
                color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
                padding: '8px 16px',
                width: 60,
                height: 64,
                top: 0,
                [theme.breakpoints.down('md')]: {
                  padding: 8,
                  width: 40,
                },
              },
            },
          },
        },

        '&__button': {
          display: 'flex',
          justifyContent: 'center',
          marginTop: 8,
          padding: '0 24px',
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            padding: 16,
            width: '100%',
            background: constants.COLOR_NAVY_DARK,
            zIndex: 2,
          },

          '& .nextButton': {
            width: 400,
            height: 92,
            background: constants.COLOR_YELLOW4,
            color: '#000',
            fontSize: 24,
            fontWeight: 900,
            borderRadius: 46,
            [theme.breakpoints.down('md')]: {
              width: 300,
              fontSize: 18,
              height: 60,
            },
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              fontSize: 16,
              height: 50,
            },
            '&:disabled': {
              background: constants.COLOR_GRAY4,
              color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
              cursor: 'not-allowed',
            },
          },
        },
      },
    },

    radioGroup: {
      '&.MuiFormGroup-root': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 23%)',
        columnGap: '2.6%',
        rowGap: 16,
        [theme.breakpoints.down('md')]: {
          gridTemplateColumns: 'repeat(auto-fit, 49%)',
          columnGap: '2%',
        },
        [theme.breakpoints.down('sm')]: {
          gridTemplateColumns: 'repeat(auto-fit, 48%)',
          columnGap: '4%',
        },
      },
    },
    radioControl: {
      position: 'relative',
      margin: 0,
      '& .MuiFormControlLabel-label': {
        display: 'grid',
        placeItems: 'center',
        padding: '0 8px',
        width: '100%',
        height: 64,
        color: '#000',
        fontFamily: constants.HATARAKU_FONT_FAMILY,
        fontSize: 16,
        fontWeight: 500,
        zIndex: 2,
      },
    },
    radioRoot: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: '#fff',
      border: '1px solid #CCC',
      borderRadius: 8,
      zIndex: 1,
      '& span:first-of-type': {
        width: '100%',
        height: '100%',
        opacity: 0,
      },
    },
    radioChecked: {
      background: constants.COLOR_YELLOW4,
    },

    formErrorMsg: {
      display: 'block',
      color: 'red',
      paddingTop: 24,
    },

    createErrorsBox: {
      margin: '16px 24px 0',
    },
    errorMessages: {
      marginTop: '16px 0 0',
      padding: 16,
      background: constants.COLOR_RED_LIGHT,
      color: constants.COLOR_RED,
      borderRadius: 8,
      listStyleType: 'none',
      '& li': {
        overflowWrap: 'break-word',
      },
    },
    menuItem: {
      fontFamily: constants.HATARAKU_FONT_FAMILY,
    },
  }),
  { name: 'PersonalQuestions' }
)
