import React, { useContext } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { Maybe } from 'utils/generated'

import * as constants from '../../../../../assets/constants'
import { OnbPages } from '../../../../../assets/pages'
import { OnbContext } from '../../PagesRoot'



import { Tooltip } from './Tooltip'

import optional from '../assets/optional.svg'

type Props = {
  isOptionalIcon?: boolean
  toolTip?: () => void
  isTooltipShow?: boolean
  isOpen?: boolean
  titleFirst?: string
  messageFirst?: string
  titleSecond?: string
  messageSecond?: string
  target?: string
  name?: string
  value?: Maybe<string> | undefined
  isSideBySide?: boolean
  isNotMyOwnPage?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const TargetOutput: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { teamId } = useContext(OnbContext)
  const history = useOnbHistory()

  return (
    <div className={props.isSideBySide ? classes.targetRoot : undefined}>
      <div className={classes.targetTitleWrapper}>
        <span className={classes.title}>目標</span>
        {props.isTooltipShow && (
          <div className={classes.optional}>
            <Tooltip
              toolTip={props.toolTip}
              isOpen={props.isOpen}
              titleFirst={props.titleFirst}
              messageFirst={props.messageFirst}
              titleSecond={props.titleSecond}
              messageSecond={props.messageSecond}
            />
          </div>
        )}
        {props.isOptionalIcon && <img src={optional} alt={optional} className={classes.optional} />}
      </div>
      <div className={props.isSideBySide ? classes.settingWrapper : undefined}>
        {props.value ? (
          <div
            className={props.isNotMyOwnPage ? undefined : classes.clickable}
            onClick={props.isNotMyOwnPage ? undefined : () => history.push(OnbPages.MypageEdit, { teamId })}
          >
            {props.value}
          </div>
        ) : (
          <div
            className={classes.setting}
            onClick={props.isNotMyOwnPage ? undefined : () => history.push(OnbPages.MypageEdit, { teamId })}
          >
            {props.isNotMyOwnPage ? undefined : '設定する'}
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  targetRoot: {
    display: 'flex',
  },
  targetTitleWrapper: {
    display: 'flex',
    justifyContent: 'baseline',
    marginBottom: 10,
    minWidth: 30,
  },
  title: {
    fontWeight: 'bold',
  },
  setting: {
    fontWeight: 'bold',
    cursor: 'pointer',
    color: constants.COLOR_MAIN_NEW,
  },
  settingWrapper: {
    marginLeft: 88,
    width: '70%',
    overflowWrap: 'break-word',
    [theme.breakpoints.down(500)]: {
      width: '60%',
    },
    [theme.breakpoints.down(400)]: {
      width: '50%',
    },
  },
  optional: {
    marginLeft: 8,
    marginRight: 16,
    marginTop: 2,
  },
  box: {
    border: '1px solid #E9E9E9',
    padding: 16,
    margin: '9px 0',
    borderRadius: 4,
  },
  input: {
    fontSize: 14,
    padding: 16,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 5,
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiInput-underline::before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}))
