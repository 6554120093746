import * as React from 'react'
import { RouteComponentProps, useLocation, useHistory as userReactRouterHistory } from 'react-router-dom'

import { TextField, CircularProgress } from '@mui/material'
import { Dialog, useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useUtils } from 'services/api/utils'
import { RootStateType } from 'store'
import { IUser } from 'stores/user/model'

import { Breadcrumb } from 'components/Breadcrumb'
import { LoginButton } from 'components/LoginButton'
import { queryGetOnboardingOwnTeamMemberList } from 'pages/onboarding/graphql'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { useConvertTeamMember } from 'pages/teams/hooks/convertTeamMember'
import { queryGetTeams } from 'pages/teams/hooks/graphql'
import { OnboardingTeam, OnboardingTeamMemberStatus, OnboardingUsageStatus } from 'utils/generated'

import * as constants from '../../assets/constants'
import * as errorCode from '../../assets/errorCode'
import MessagePanel from '../../components/MessagePanel'
import { TopButton } from '../../components/TopButton'
import { ISignin } from '../../services/amplify/signin'
import { useAnket } from '../signup/hooks/anket'

import { connector, ContainerProps } from './index.container'

import { replacePathParams, useHistory } from 'assets/history'
import { Pages, KARTE_LANDING, PAGE_SETUP_LANDING } from 'assets/pages'
import { TO_ONBOARDING_TIMELINE } from 'assets/pages'
import { PatternEmail, PatternEmailMessage } from 'assets/patterns'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ r: string; team: string }>

export type SignInLocationState = {
  confirmedEmail: string | null
}

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props
  const location = useLocation()
  const locationState = location.state as SignInLocationState | undefined
  const confirmedEmail = locationState?.confirmedEmail
  const query = new URLSearchParams(props.location.search)
  const teamId = query.get('team')
  const { route } = useHistory()
  const history = userReactRouterHistory()
  const [inTransition, setInTransition] = React.useState(false)
  const [selectedTeam, setSelectedTeam] = React.useState({ team: '', onboarding: '' })
  const [decryptedParamEmail, setDecryptedParamEmail] = React.useState<string>()
  const paramEmail = query.get('email')

  React.useEffect(() => {
    props.onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { convertFakeUserOnboardingTeamMember } = useTeamManage()
  const { convertTeamMember } = useConvertTeamMember()
  const { createSingupAnket } = useAnket()
  React.useEffect(() => {
    routing(props.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  const routing = async (user: IUser) => {
    if (user.id) {
      if (user.fetched && user.email) {
        createSingupAnket(user.email)
      }
      convertFakeUserOnboardingTeamMember()
      await convertTeamMember()

      const r = query.get('r')
      if (r === KARTE_LANDING || r === PAGE_SETUP_LANDING) {
        route.push(r)
        return
      }

      if (teamId) {
        route.push(TO_ONBOARDING_TIMELINE(teamId))
        return
      }

      if (commnetSelector.type === 'HINT') {
        route.push(`/hint/tips/${commnetSelector.hintId}`)
        return
      }

      if (answerSelector.type === 'CONSULTATION') {
        route.push(`/hint/faq/${answerSelector.consultationId}`)
        return
      }

      try {
        // check team
        const getTeamsResponse = await queryGetTeams({ limit: 1 })

        // check onboarding
        const response = await queryGetOnboardingOwnTeamMemberList({})
        const localTeams: OnboardingTeam[] = []
        response.getOnboardingOwnTeamMemberList?.items.forEach((member) => {
          if (
            member.team &&
            member.status !== OnboardingTeamMemberStatus.Stopped &&
            member.team.usageStatus === OnboardingUsageStatus.Active
          ) {
            localTeams.push(member.team)
          }
        })
        const activeOnbTeams = localTeams.filter((t) => t.usageStatus === OnboardingUsageStatus.Active)

        if (getTeamsResponse && getTeamsResponse.items.length > 0 && activeOnbTeams.length > 0) {
          setSelectedTeam({ team: getTeamsResponse.items[0].id, onboarding: activeOnbTeams[0].id })
          setOpen(true)
          return
        }
        if (getTeamsResponse && getTeamsResponse.items.length > 0) {
          route.push(replacePathParams(Pages.TeamsDashboard, { teamId: getTeamsResponse.items[0].id }))
          return
        }
        if (activeOnbTeams.length > 0) {
          route.push(TO_ONBOARDING_TIMELINE(activeOnbTeams[0].id))
          return
        }
      } catch (e) {
        console.log('e', e)
        setInTransition(false)
      }

      //now it is no use but it may use in the future
      // const currentUser = await queryGetUser({ id: user.id })

      // if (currentUser && currentUser.accountType === AccountType.Company) {
      //   route.push(replacePathParams(Pages.TeamsLanding))
      //   return
      // }

      route.push(r || constants.PAGE_TOP)
    }
  }

  const selectPlan = (plan: 'team' | 'onboarding') => () => {
    if (plan === 'team') {
      route.push(replacePathParams(Pages.TeamsDashboard, { teamId: selectedTeam.team }))
    } else {
      route.push(TO_ONBOARDING_TIMELINE(selectedTeam.onboarding))
    }
  }

  const { register, handleSubmit, errors, getValues, clearErrors, setValue } = useForm<ISignin>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const commnetSelector = useSelector((state: RootStateType) => state.hint.comment)
  const answerSelector = useSelector((state: RootStateType) => state.hint.answer)

  const handlePage = (e: React.MouseEvent<HTMLElement>) => {
    route.push(e.currentTarget.getAttribute('data-page') || '')
    window.scrollTo(0, 0)
  }

  const handlePageRestore = () => {
    props.setEmailToParam(getValues('email'))
    route.push(constants.PAGE_RESTORE)
    window.scrollTo(0, 0)
  }
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [errors])

  const onSubmit = (data: ISignin) => {
    setInTransition(true)

    if (decryptedParamEmail) {
      data.email = decryptedParamEmail
    }
    const lowerCaseEmail = data.email.toLowerCase()

    setCurrentEmail(lowerCaseEmail)
    props.signin({
      email: lowerCaseEmail,
      password: data.password,
    })
  }

  const CloseMessagePanel = () => {
    setInTransition(false)
    clearErrors()
    props.refreshErrors()
  }

  const [currentEmail, setCurrentEmail] = React.useState<string>('')

  const { getEncryptString } = useUtils()
  const handlePageConfirm = () => {
    getEncryptString({ encrypt: true, text: currentEmail }).then((encrypted) => {
      if (encrypted) {
        history.push({
          pathname: constants.PAGE_SIGNUP_SEND_EMAIL,
          search: `?key=${encodeURIComponent(encrypted)}`,
          state: { isEmailResendTriggered: true },
        })
        window.scrollTo(0, 0)
      }
    })
  }

  const decryptedEmail = React.useCallback(async () => {
    const email = confirmedEmail ?? paramEmail
    if (email) {
      const decrypted = await getEncryptString({ encrypt: false, text: email })

      if (decrypted) {
        setValue('email', decrypted)
        setDecryptedParamEmail(decrypted) // disabled の input は onSubmit で値を取れないので、useState で値を保持する。
      }
    }
  }, [confirmedEmail, paramEmail, getEncryptString, setValue])

  React.useEffect(() => {
    decryptedEmail()
  }, [decryptedEmail])

  return (
    (<React.Fragment>
      <div className={classes.main}>
        {!inTransition && (
          <div className={classes.breadcrumb}>
            <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="ログイン" />
          </div>
        )}

        {props.user?.fetched && !props.user?.id && (
          <div className="__signup_index_container">
            <div
              style={{
                display: !errors.email && !errors.password && !props.user?.error ? 'block' : 'none',
              }}
            >
              {!inTransition ? (
                <>
                  <div className="__signup_index_container__header">ログイン</div>
                  <div className={classes.headertext}>ログインIDとパスワードを入力してログインしてください。</div>

                  <div className="__signup_index_container__body">
                    {confirmedEmail ? (
                      <div className="authBox">
                        <p className="completeAuth">メール認証が完了しました</p>
                        <img
                          className="successImg"
                          src={`${process.env.PUBLIC_URL}/assets/svg/successMainNew.svg`}
                          alt="success mark"
                        />
                      </div>
                    ) : null}

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="__formGroup">
                        <label className="__labelWrapper">
                          <div className="__text">メールアドレス（ログインID）</div>
                          <div className="__labelSVG">
                            <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                          </div>
                        </label>
                        <TextField
                          variant="standard"
                          name="email"
                          type="email"
                          inputMode="email"
                          className="__textField"
                          disabled={!!confirmedEmail || !!paramEmail}
                          inputRef={register({
                            required: 'メールアドレスを入力してください。',
                            pattern: {
                              value: PatternEmail,
                              message: PatternEmailMessage,
                            },
                          })} />
                      </div>
                      <div className="__formGroup">
                        <label className="__labelWrapper">
                          <div className="__text">パスワード</div>
                          <div className="__labelSVG">
                            <img src={`${process.env.PUBLIC_URL}/assets/landing/features/need.svg`} alt={`need`} />
                          </div>
                        </label>
                        <TextField
                          variant="standard"
                          name="password"
                          className="__textField"
                          type="password"
                          inputRef={register({
                            required: 'パスワードを入力してください。',
                            pattern: {
                              value: /^[a-z\d!-~]{8,24}$/i,
                              message: `パスワードを半角英数8〜24文字で入力してください。\n使用可能な記号は !"#$%&'()*+,-./:;<=>?@[]^_\`{|}~ です`,
                            },
                          })} />
                      </div>
                      <div className="__wrapperText">
                        <div onClick={handlePageRestore} className="__click">
                          <span className="__arrow">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/landing/features/arrowRight.svg`}
                              alt={`need`}
                            />
                          </span>
                          <span className="__remember">パスワードを忘れた方はこちら</span>
                        </div>
                      </div>

                      <div className="__buttons">
                        <LoginButton
                          label="ログイン"
                          ownStyles={isMdUp ? { height: 50, width: 200 } : { height: 50, width: 250 }}
                          submit={true}
                        />
                      </div>
                    </form>
                    <div>
                      <hr className="__hr" />
                      <div className="__inquireWrapper">
                        <span>登録されていない方はこちら</span>
                        <div>
                          <TopButton
                            label="お問い合わせ"
                            type="contact"
                            teams={undefined} // type="contact" は teams の有無が関係ないため undefined を設定。
                            isMoveAnimation={false}
                            ownStyles={isMdUp ? { height: 50, width: 200 } : { height: 50, width: 250 }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={classes.inTransition}>
                  <CircularProgress size={34} />
                </div>
              )}
            </div>

            <Dialog
              open={!errors.email && !errors.password && !props.user?.error?.code ? false : true}
              onClose={CloseMessagePanel}
              className={classes.errorDialog}
            >
              <div
                style={{
                  display: !errors.email && !errors.password && !props.user?.error?.code ? 'none' : 'block',
                }}
              >
                <MessagePanel
                  failure={true}
                  handleClose={CloseMessagePanel}
                  body={
                    errors.email ? (
                      errors.email.message
                    ) : errors.password ? (
                      errors.password.message
                    ) : props.user && props.user?.error?.code === errorCode.USER_NOT_FOUND_EXCEPTION ? (
                      <>
                        <p>このログインIDは登録されていません。</p>
                        <p>ログインIDをご確認の上、もう一度入力し直してください。</p>
                      </>
                    ) : props.user?.error?.code === errorCode.NOT_AUTHORIZED_EXCEPTION ? (
                      <>
                        <p>パスワードが違います。</p>
                        <p>パスワードをご確認の上、もう一度入力し直してください。</p>
                        <p>
                          なお、
                          <span className={classes.link} onClick={handlePage} data-page={constants.PAGE_RESTORE}>
                            パスワードを忘れた方
                          </span>
                          はこちらです。
                        </p>
                      </>
                    ) : props.user?.error?.code === errorCode.USER_NOT_CONFIMED_EXCEPTION ? (
                      <>
                        <p>このメールアドレスはまだ認証されていません。</p>
                        <p>
                          <span className={classes.link} onClick={handlePageConfirm}>
                            こちら
                          </span>
                          から認証処理を完了させてください。
                        </p>
                      </>
                    ) : (
                      <>
                        <p>{props.user?.error?.code}</p>
                        <p>{props.user?.error?.message}</p>
                      </>
                    )
                  }
                />
              </div>
            </Dialog>
          </div>
        )}

        <Dialog open={open} className={classes.planDialog}>
          <>
            <div className={classes.modalTitle}>どちらのプランにログインしますか？</div>
            <div className={classes.planButtonWrapper1}>
              <LoginButton
                label="チームビルディング"
                ownStyles={{ height: 50, width: 200 }}
                onClick={selectPlan('team')}
              />
            </div>
            <div className={classes.planButtonWrapper2}>
              <LoginButton
                label="オンボーディング"
                ownStyles={{ height: 50, width: 200 }}
                onClick={selectPlan('onboarding')}
              />
            </div>
          </>
        </Dialog>
      </div>
    </React.Fragment>)
  );
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      '& .MuiInputBase-input': {
        padding: 17,
      },

      '& .__signup_index_container': {
        margin: '0 auto',
        padding: '0px 24px 100px',
        [theme.breakpoints.up('md')]: {
          maxWidth: 1000,
        },

        '& .__subText': {
          textAlign: 'center',
          color: '#333333',
        },

        '& .__wrapperText': {
          display: 'flex',
          justifyContent: 'right',

          '& .__click': {
            marginTop: 16,
            cursor: 'pointer',
          },
          '& .__arrow': {
            marginRight: 8,
          },
          '& .__remember': {
            fontSize: 12,
            fontWeight: 'bold',
          },
        },

        '& .__buttons': {
          marginTop: 50,
          display: 'flex',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            marginTop: 42,
          },
        },

        '& .__inquireWrapper': {
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          gap: '8px 16px',
          [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          },
        },

        '& .__hr': {
          height: 1,
          backgroundColor: '#E3E3E3',
          border: 'none',
          margin: '24px 0',
        },
        '&__header': {
          marginTop: 80,
          textAlign: 'center',
          fontSize: 32,
          fontWeight: 'bold',
          [theme.breakpoints.down('md')]: {
            marginTop: 32,
            fontSize: 20,
          },
        },
        '&__body': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: 8,
          marginTop: 80,
          padding: '12px 24px 40px',
          boxShadow: '1px 3px 9px 0px rgba(21, 27, 38, 0.50)',
          [theme.breakpoints.up('md')]: {
            padding: '48px 200px 80px',
          },

          '& .authBox': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

            '& .completeAuth': {
              margin: '32px 0 16px',
              color: constants.COLOR_MAIN_NEW,
              fontSize: 14,
              fontWeight: 'bold',
            },

            '& .successImg': {
              width: 80,
              height: 80,
            },
          },

          '&__description': {
            fontSize: '12px',
            padding: '16px 0',
          },

          '& form': {
            '& .__formGroup': {
              margin: '32px 0 0',
              '& .__labelWrapper': {
                marginBottom: 12,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                '& .__text': {
                  marginRight: 8,
                  fontSize: 14,
                  fontWeight: 'bold',
                },
                '& .__labelSVG': {
                  width: 26,
                  height: 15,
                  '& img': {
                    width: '100%',
                  },
                },
              },
            },

            '& .__textField': {
              width: '100%',
              '& input': {
                border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
                borderRadius: 8,
                '&:disabled': {
                  backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_200,
                  color: constants.COLOR_GRAY_DARK,
                },
              },

              '& .MuiInput-underline:before': {
                border: constants.COLOR_MAIN, // Semi-transparent underline
              },
              '& .MuiInput-underline': {
                '&:after': {
                  border: constants.COLOR_MAIN, // Solid underline on focus
                },
              },
            },
          },

          '&__link': {
            marginTop: '8px',
            fontWeight: 'bold',
            textAlign: 'right',
            color: constants.COLOR_ONBOARDING_GRAY_DARK,
          },
        },
      },
    },

    planDialog: {
      '& .MuiDialog-paper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '32px 24px',
        padding: '60px 45px 40px',
        borderRadius: 8,
        maxWidth: 350,
      },
    },
    modalTitle: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      fontWeight: 'bold',
    },
    planButtonWrapper1: {
      marginTop: 42,
    },
    planButtonWrapper2: {
      marginTop: 32,
    },

    inTransition: {
      height: 'calc(100vh - 78px - 88px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .MuiCircularProgress-root': {
        color: constants.COLOR_MAIN_NEW,
      },
    },
    errorDialog: {
      '& .MuiDialog-paper': {
        margin: '32px 24px',
        borderRadius: 8,
        maxWidth: 327,
      },
    },
    link: {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
      cursor: 'pointer',
    },

    headertext: {
      marginTop: 28,
      textAlign: 'center',
      fontSize: 14,
      [theme.breakpoints.down('md')]: {
        marginTop: 22,
        fontSize: 13,
      },
    },

    breadcrumb: {
      margin: '0 auto',
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
        maxWidth: 1080,
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
