import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search'
import { CircularProgress, IconButton, Modal, OutlinedInput } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import * as constants from '../../../assets/constants'
import AlertDialog from '../../../components/AlertDialog'
import Button from '../../../components/Button'
import SpeechBubble from '../components/SpeechBubble'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ leaderId: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes, isKarteNotFound } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onQuestionnaire = () => {
    props.history.push(`/karte/member/${props.match.params.leaderId}/questionnaire`)
    window.scrollTo(0, 0)
  }

  // TODO: Add membership validation

  const refKarteNo = React.useRef<HTMLInputElement>(null)
  const handleSearchKarte = () => {
    if (refKarteNo && refKarteNo.current) {
      const id = refKarteNo.current.value
      if (id.length > 0) {
        props.onKarteSearch(props.match.params.leaderId, id, () => {
          props.history.push(`/karte/member/${props.match.params.leaderId}/questionnaire/${id}/results`)
          window.scrollTo(0, 0)
        })
      }
    }
  }

  const renderLoading = () => {
    if (!props.isLoading) return null
    return (
      <Modal
        open={props.isLoading}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress className={classes.loadingCircularProgress} />
      </Modal>
    )
  }

  const renderError = () => {
    if (!props.error) return null

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const onClose = () => {}

    let message = 'Unknown error'
    if (props.error.message) {
      message = props.error.message
    } else if (props.error.errors) {
      message = props.error.errors[0].message
    }

    return (
      <AlertDialog
        open={true}
        failure={true}
        onClose={onClose}
        body={
          <>
            <p>{message}</p>
          </>
        }
      />
    )
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <div className="__container__description">
            <img className="" src={process.env.PUBLIC_URL + '/assets/svg/karte_icon.svg'} alt={'icon'} />
            <div>
              <SpeechBubble>カルテへようこそ。</SpeechBubble>
              <SpeechBubble>リーダーからの依頼の場合は、​ 診断後のカルテNO.を必ずリーダーに伝えましょう。</SpeechBubble>
            </div>
          </div>
          <div className="__container__content">
            <div className="__container__content__top">
              <span>あなたの仕事観がわかる</span>
              <span>質問に答えましょう。</span>
            </div>

            <div className="__container__content__mainButtons">
              <Button
                bgColor={constants.COLOR_BLUE}
                textColor={constants.COLOR_WHITE}
                fullWidth={true}
                body={<div>メンバー診察室へ行く</div>}
                onClick={onQuestionnaire}
              />
            </div>

            <span className="__container__content__caution">
              このメンバー診断は、「Cocolabo」のカルテ機能の一部です。
            </span>

            <div className="__container__content__history">
              <div className="__container__content__history__title">過去のメンバー診断の結果をみる</div>
              <div className="__container__content__history__description">MからはじまるカルテNoを入力してください</div>
              <OutlinedInput
                placeholder="カルテNo."
                className="__container__content__history__input"
                inputRef={refKarteNo}
                onChange={() => {
                  if (isKarteNotFound) {
                    props.onResetError()
                  }
                }}
                endAdornment={
                  <IconButton onClick={handleSearchKarte} size="large">
                    <SearchIcon className="__container__content__history__input__icon" />
                  </IconButton>
                }
                style={{ padding: 0 }}
              />
              <span className="__container__content__history__caution">
                カルテNo.を入れて検索すると、過去の診断結果を見ることができます。
              </span>
              {isKarteNotFound && <small style={{ color: 'red' }}>入力されたNoのカルテは見つかりませんでした</small>}
            </div>
          </div>
        </div>
        {renderLoading()}
        {renderError()}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: `calc(100vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
      backgroundColor: '#bfeaee',
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '&__description': {
          display: 'flex',
          padding: '0 16px',

          '& img': {
            width: 50,
            height: 50,
            marginRight: 16,
          },
        },

        '&__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          marginTop: 16,
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__top': {
            margin: '16px 0',
            '& span': {
              display: 'block',
              fontSize: '20px',
              fontWeight: 'bold',
              color: constants.COLOR_MAIN_DARK,
              marginBottom: 8,
            },
          },
          '&__mainButtons': {
            backgroundColor: '#F5F5F5',
            padding: 16,
            margin: '8px 0',

            '& button': {
              height: 50,
              borderRadius: 50,
              fontSize: 16,
            },
          },
          '&__caution': {
            color: '#292626',
            fontSize: 10,
            textAlign: 'center',
            display: 'block',
            marginBottom: 16,
          },
          '&__history': {
            backgroundColor: constants.COLOR_BLUE_LIGHT,
            padding: 16,
            borderRadius: 5,

            '&__title': {
              color: '#fff',
              fontSize: 20,
              fontWeight: 'bold',
            },

            '&__description': {
              color: '#fff',
              fontSize: 16,
              fontWeight: 'normal',
            },

            '&__input': {
              width: '100%',
              background: '#fff',
              height: 40,
              borderRadius: 0,
              marginTop: 16,
              marginBottom: 8,

              '&__icon': {
                color: constants.COLOR_BLUE_LIGHT,
              },
            },

            '&__caution': {
              fontSize: 10,
              display: 'block',
              color: '#404040',
            },
          },
        },
      },
    },

    loadingCircularProgress: {
      '&:focus': {
        outline: 'none',
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
