import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { TeamBuildingActionDate, TeamBuildingActionDateStatus } from 'utils/generated'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentModal } from './generic/ContentModal'
import { ContentSection } from './generic/ContentSection'
import { ActionCompleteModalBody } from './step3/ActionCompleteModalBody'
import { ActionContent } from './step3/ActionContent'
import { ActionEdit } from './step3/ActionEdit'

import AddIcon from '../assets/add.svg'




type Props = {
  title: string
  subtitle: string
  callUpdate: () => Promise<void>
}
type SortedActionDates = {
  created: TeamBuildingActionDate[]
  completed: TeamBuildingActionDate[]
}
export const Step3: React.FC<Props> = ({ title, subtitle, callUpdate }) => {
  const { teamId } = useParams<{ teamId: string }>()
  const { teamBuildingActionDates, defaultActionMasters } = React.useContext(TeamBuildingReviewHooksContext)

  const classes = useStyles()
  const [open, setOpen] = React.useState<boolean>(false)
  const [openActionEdit, setOpenActionEdit] = React.useState<boolean>(false)
  const [selectedActionId, setSelectedActionId] = React.useState<string | undefined>()
  const [createdActions, setCreatedActions] = React.useState<TeamBuildingActionDate[]>([])
  const [completedActions, setCompletedActions] = React.useState<TeamBuildingActionDate[]>([])

  React.useEffect(() => {
    if (teamBuildingActionDates) {
      const { created, completed } = teamBuildingActionDates.reduce(
        (prev, actionDate) => {
          if (actionDate.status === TeamBuildingActionDateStatus.Created) {
            prev.created.push(actionDate)
          }
          if (actionDate.status === TeamBuildingActionDateStatus.Completed) {
            prev.completed.push(actionDate)
          }
          return prev
        },
        {
          created: [],
          completed: [],
        } as SortedActionDates
      )
      setCreatedActions(created)
      setCompletedActions(completed)
    }
  }, [teamBuildingActionDates])

  const onOpen = (actionId: string | undefined) => {
    setSelectedActionId(actionId)
    setOpen(true)
  }
  const onClose = () => {
    onEditEnd()
  }

  const onEditAction = (actionId: string | undefined) => {
    setSelectedActionId(actionId)
    setOpenActionEdit(true)
  }
  const onEditClose = () => {
    setSelectedActionId(undefined)
    setOpenActionEdit(false)
  }

  const onEditEnd = async () => {
    callUpdate()
    onEditClose()
  }

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={2} currentStep={1} />
      <ContentSection title="未完了">
        {createdActions.map((action) => (
          <Content key={action.teamBuildingActionId}>
            <ActionContent
              type="caution"
              date={dayjs(action.date).format('MM月DD日')}
              name={action.teamBuildingAction?.assignedTeamMemberName}
              label={action.teamBuildingAction?.name}
              onComplete={onOpen}
              actionId={action.id}
              teamBuildingActionId={action.teamBuildingActionId}
              onEditAction={onEditAction}
            />
          </Content>
        ))}
        <div className={classes.addAction} onClick={() => onEditAction(undefined)}>
          <img src={AddIcon} alt="" />
          任意でアクションを追加する
        </div>
      </ContentSection>
      <ContentSection title="完了済み" separate>
        {completedActions.map((action) => (
          <Content key={action.teamBuildingActionId}>
            <ActionContent
              type="done"
              date={dayjs(action.date).format('MM月DD日')}
              name={action.teamBuildingAction?.assignedTeamMemberName}
              label={action.teamBuildingAction?.name}
              onComplete={onOpen}
              actionId={action.id}
              teamBuildingActionId={action.teamBuildingActionId}
              onEditAction={onEditAction}
              completed
            />
          </Content>
        ))}
      </ContentSection>
      {selectedActionId && (
        <ContentModal open={open} onClose={onClose}>
          <ActionCompleteModalBody id={selectedActionId} onClose={onClose} />
        </ContentModal>
      )}
      <ContentModal open={openActionEdit} onClose={onEditClose} fullContent>
        <ActionEdit
          teamId={teamId}
          actionId={selectedActionId}
          defaultActionMasters={defaultActionMasters}
          onEditClose={onEditClose}
          onEditEnd={onEditEnd}
        />
      </ContentModal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  addAction: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 0',
    color: '#00838C',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}))
