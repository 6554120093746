import React from 'react'

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  onClick?: () => void
  full?: boolean
  children?: React.ReactNode
}

export const BackToIndex: React.FC<Props> = ({ onClick, full, children }) => {
  const classes = useStyles({ full })

  return (
    <div className={classes.root} onClick={onClick}>
      <ChevronLeftRoundedIcon fontSize="small" />
      <span>{children || '戻る'}</span>
    </div>
  )
}

type StyleProps = {
  full?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ full }) => ({
    display: 'flex',
    alignItems: 'center',
    color: '#707070',
    cursor: 'pointer',
    width: full ? '100%' : 'auto',
    '& > span': {
      fontSize: 12,
    },
  }),
}))
