import * as React from 'react'
import { useContext } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Theme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { Ghost } from 'utils/generated'

import info from '../../assets/svg/info.svg'
import { Font, CountBatch, Flexbox, ButtonForGhost, Imgcard } from '../atoms'
import { GhostItemBox } from '../modules'

type Props = {
  ownStyles?: React.CSSProperties
  ghosts: Ghost[] // 12 all ghosts or found ghosts only
  onTapCard?: (id: string) => void
  selectedIds?: string[]
}

export const GhostTemplateBoard: React.FC<Props> = ({ ghosts, onTapCard, selectedIds }) => {
  const classes = useStyles({})
  const theme = useTheme()

  const MQ = React.useCallback((query: string): { matches: boolean } => {
    return { matches: window.matchMedia(query).matches }
  }, [])
  const mediaQuery = useMediaQuery(theme.breakpoints.up('md'), { ssrMatchMedia: MQ })
  const mediaQueryMaxMin = useMediaQuery(theme.breakpoints.up(470), { ssrMatchMedia: MQ })

  const { setModal, setDetail, resetSelectedGhosts, modal } = useContext(GhostContext)
  const toGhostDetail = (gid: string) => {
    setDetail(gid)
    setModal('detail-no-tab-no-select')
  }
  return (
    <div className={classes.root}>
      <div className={classes.ghostContainer}>
        <CloseIcon className={classes.closeIcon} onClick={() => setModal('')} />
        {ghosts.map((ghost, index) => (
          <Grid className={classes.contentBox} key={`numberIs${index}`}>
            <GhostItemBox
              onTap={onTapCard ? () => onTapCard(ghost.id) : () => toGhostDetail(ghost.id)}
              ownStyles={{ marginBottom: 24 }}
              childrenForTitle={
                <img
                  src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghost.id}.svg`}
                  alt={ghost.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    verticalAlign: 'top',
                    background: 'white',
                    border: '1px solid #E9E9E9',
                    padding: 10,
                  }}
                />
              }
              childrenForContent={
                <>
                  <div className={classes.ghostInfo}>
                    <div>
                      <Font
                        fontSize={mediaQueryMaxMin ? 10 : 8}
                        ownStyles={{ color: constGhost.COLOR_GRAY2, paddingTop: 8, cursor: 'pointer' }}
                      >
                        {`FILE NO.${ghost.fileNo}`}
                      </Font>
                      <Font
                        fontSize={mediaQueryMaxMin ? 10 : 10}
                        ownStyles={{ color: constGhost.COLOR_BLACK3, cursor: 'pointer' }}
                      >
                        {ghost.name}
                      </Font>
                    </div>
                    <Flexbox
                      onClick={onTapCard ? () => onTapCard(ghost.id) : () => toGhostDetail(ghost.id)}
                      ownStyles={{
                        justifyContent: 'flex-end',
                        paddingTop: 8,
                        cursor: 'pointer',
                      }}
                    >
                      <div className={classes.iconBox}>
                        <Imgcard
                          src={info}
                          alt={'ghost info'}
                          ownStyles={{ width: '100%', verticalAlign: 'top' }}
                          ownWrapStyles={{ width: 16, height: 16 }}
                        />
                        <Font
                          fontSize={10}
                          ownStyles={{
                            color: constGhost.COLOR_MAIN_NEW,
                            fontWeight: 'bold',
                            margin: '4px 0 0',
                            cursor: 'pointer',
                          }}
                        >
                          詳細
                        </Font>
                      </div>
                    </Flexbox>
                  </div>
                  <CountBatch count={ghost.found || 0} />
                </>
              }
              withBatch
              selected={selectedIds ? selectedIds.includes(ghost.id) : false}
              selectedNum={selectedIds?.length ? selectedIds.indexOf(ghost.id) + 1 : undefined}
            />
          </Grid>
        ))}
      </div>
      {modal === 'select' && (
        <Flexbox ownStyles={{ marginTop: 12, width: '100%', flexFlow: mediaQuery ? 'initial' : 'column-reverse' }}>
          <ButtonForGhost
            buttonBodyColor={'transparent'}
            bodyText={<div>選びなおす</div>}
            ownStyles={{
              border: `1px solid ${constGhost.COLOR_MAIN_NEW}`,
              margin: mediaQuery ? '0 16px 0 0' : 0,
              width: mediaQuery ? 250 : '100%',
            }}
            onClick={() => resetSelectedGhosts()}
          />
          <ButtonForGhost
            buttonBodyColor={'green'}
            bodyText={<div>決定する</div>}
            ownStyles={{ margin: mediaQuery ? 'initial' : '0 0 16px 0', width: mediaQuery ? 250 : '100%' }}
            onClick={() => setModal('')}
          />
        </Flexbox>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: 600,
    maxWidth: 549,
    width: '100%',
  },
  ghostContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginRight: -16,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
  },
  contentBox: {
    height: '100%',
    marginRight: 16,
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  batch: {
    position: 'absolute',
    top: -13,
    right: 0,
  },
  ghostInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))
