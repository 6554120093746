import React from 'react'
import { Link, useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { IntersectLoading, LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useTeams } from 'pages/teams/hooks'
import { TeamStatusUsage } from 'utils/generated'

import { constants } from 'assets'

type Props = {}

export const TeamsSelectPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { currentTeam } = React.useContext(HooksContext)
  const { teamId } = useParams<{ teamId?: string }>()
  const { teams, nextToken, more, loading, isMoreLoading } = useTeams()
  const inUseTeams = React.useMemo(() => teams.filter((t) => t.statusUsage === TeamStatusUsage.InUse), [teams])
  const isMdUp = useCustomMediaQuery('up', 'md')

  if (isMdUp) return null

  return (
    <div className={classes.root}>
      <p className={`${classes.desc} ${classes.descTop}`}>現在選択中のチーム</p>
      <div className={classes.currentTeam}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/cocolabo-min.svg'} alt="cocolabo icon" />
        <span>{currentTeam?.name}</span>
      </div>

      <p className={classes.desc}>チーム切替</p>
      <LoadingCircular loading={loading} size={32} wrapperStyles={{ padding: 0 }} />

      {inUseTeams.map((team) => (
        <Link key={team.id} className={classes.linkBox} to={`/teams/${team.id}`}>
          <span>{team.name}</span>
          <img src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'} alt="arrow right icon" />
        </Link>
      ))}

      {!loading && !isMoreLoading && (
        <Link className={`${classes.linkBox} ${classes.teamListLink}`} to={`/teams/${teamId}/list?hidden=true`}>
          <span>チームリスト</span>
          <img src={process.env.PUBLIC_URL + '/assets/svg/arrow-right.svg'} alt="arrow right icon" />
        </Link>
      )}

      <IntersectLoading IntersectItem={{ isLoading: loading, isMoreLoading, nextToken, more }} />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    desc: {
      margin: '16px 0',
      fontWeight: 'bold',
    },
    descTop: {
      marginTop: 0,
    },
    currentTeam: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      width: '100%',
      height: 56,
      padding: '0 16px',
      background: '#fff',
      '& img': {
        width: 24,
        height: 24,
        position: 'relative',
        top: -1,
      },
      '& span': {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    linkBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginBottom: 1,
      padding: '0 18px 0 16px',
      height: 52,
      background: '#fff',
      '& span': {
        flex: 1,
        minWidth: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& img': {
        width: 6,
      },
    },
    teamListLink: {
      marginTop: 16,
    },
  }),
  { name: 'TeamsSelectPage' }
)
