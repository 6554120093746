import * as React from 'react'

import { Theme, CircularProgress, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = {
  step: 1 | 2 | 2.5 | 3 | 4 | 5
  style?: React.CSSProperties
}

export const TeamCircularProgress: React.FC<Props> = ({ step }) => {
  const classes = useStyles({})

  //小数点の場合は、整数部分のみを表示する
  const displayStep = Number.isInteger(step) ? step : Math.floor(step)

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={100} thickness={5} classes={{ root: classes.circleBaseColor }} />
      <CircularProgress
        variant={'determinate'}
        value={step * 20}
        thickness={5}
        classes={{ root: classes.circleCurrentColor }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <div>
          <span className={classes.currentNumberText}>{displayStep}</span>
          <span className={classes.numberText}>/5</span>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  circleBaseColor: {
    color: constants.COLOR_ONBOARDING_GRAY_LIGHT,
  },
  circleCurrentColor: {
    color: constants.COLOR_ONBOARDING_MAIN,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  currentNumberText: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 14,
    fontWeight: 'bold',
  },
  numberText: {
    color: '#666666',
    fontSize: 12,
    fontWeight: 'bold',
  },
}))
