import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

type Props = {
  len: number
  activeIndex: number
  keyPrefix: string
}

export const ProgressDots: React.FC<Props> = ({ len, activeIndex, keyPrefix }) => {
  const classes = useStyles()

  if (len <= 0 || len < activeIndex + 1) {
    return <></>
  }

  return (
    <div className={classes.root}>
      {[...Array(len).keys()].map((i) => (
        <span
          key={`${keyPrefix}-${i}`}
          className={classNames([classes.dot, i === activeIndex ? 'active' : 'inactive'])}
        />
      ))}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: 16,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
    },
    dot: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      '&.active': {
        backgroundColor: constGhost.COLOR_WHITE,
      },
      '&.inactive': {
        backgroundColor: constGhost.COLOR_GRAYTEXT,
      },
    },
  }),
  { name: 'ProgressDots' }
)
