import * as React from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import Button from 'components/Button'
import { DialogSuspension } from 'pages/hint/_shared/components/dialogs/dialogSuspension/Index'
import { useDialogSuspension } from 'pages/hint/_shared/components/dialogs/dialogSuspension/useDialogSuspension'
import { ErrorMessage } from 'pages/hint/_shared/components/ereorMessage/Index'
import { Header } from 'pages/hint/_shared/components/modal/header/Index'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useDialogDelete } from 'pages/hint/tips/_shared/components/modal/dialog/dialogDelete/useDialogDelete'
import { useDialogPublish } from 'pages/hint/tips/_shared/components/modal/dialog/dialogPublish/useDialogPublish'
import { useHero } from 'pages/hint/tips/_shared/components/modal/hero/useHero'
import { useTitle } from 'pages/hint/tips/_shared/components/modal/title/useTitle'

import '../../../assets/base.scss'
import '../../../assets/editor.scss'
import { localization } from '../../../assets/icon'
import { toolbarConfig } from '../../../assets/toolbarConfig'
import { Categories } from '../categories/Index'
import { useCategory } from '../categories/useCategory'
import { Container } from '../container/Index'
import { DialogDelete } from '../dialog/dialogDelete/Index'
import { DialogPublish } from '../dialog/dialogPublish/Index'
import { Hero } from '../hero/Index'
import { Title } from '../title/Index'
import { Wrapper } from '../wrapper/Index'

import { useModalHandler } from './useModalHandler'
import { useModalToolabr } from './useModalToolbar'
import { useModalValue } from './useModalValue'
import { useToolbar } from './useToolbar'

import * as constants from 'assets/constants'
import * as registers from 'assets/registers'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    height: '100vh',
    backgroundColor: constants.COLOR_WHITE,
  },

  inner: {
    width: '100%',
    height: '100vh',
    overflow: 'scroll',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IEditor = {
  title: string
  body: string
  category: string
}

// -----------------------------
// Component
// -----------------------------
export const Modal = () => {
  const classes = useStyles()

  const modalValue = useModalValue()
  const modalToolbar = useModalToolabr()
  const modalHandler = useModalHandler()
  const hero = useHero()
  const title = useTitle()
  const category = useCategory()
  const dialogDelete = useDialogDelete()
  const dialogPublish = useDialogPublish()
  const dialogSuspension = useDialogSuspension()

  const modalSelector = useSelector((state: RootStateType) => state.hint.ui)
  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)

  const [editorState, setEditorState] = React.useState<EditorState>(EditorState.createEmpty())
  const context = React.useContext(hintContext)

  // --------------------------------------------------
  // Errorがある場合
  // --------------------------------------------------
  React.useEffect(() => {
    if (commonSelector.error) {
      alert(commonSelector.error.error)
      window.location.reload()
    }
  }, [commonSelector])

  // --------------------------------------------------
  // Editorの編集時に現在の記事情報の値をセットする
  // --------------------------------------------------
  React.useEffect(() => {
    if (modalValue.isUpdate()) {
      const draft = htmlToDraft(hintSelector.hint.content)
      const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
      const updatedEditorState = EditorState.createWithContent(contentState)
      setEditorState(updatedEditorState)
      modalValue.setTitleValue(hintSelector.hint.title)
      modalValue.setContentValue(hintSelector.hint.content)
      modalValue.setCategoryValue(hintSelector.hint.category)
      modalValue.formatContentPreviewDatas(hintSelector.hint.content)
    } else {
      modalValue.setTitleValue('')
      modalValue.setContentValue('')
      modalValue.setCategoryValue('')
      modalValue.formatContentPreviewDatas('')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hintSelector, modalSelector, modalSelector.modal.status])

  // --------------------------------------------------
  // Editorの入力時の処理
  // --------------------------------------------------
  const onEditorStateChange = (updatedEditorState: EditorState) => {
    const html = draftToHtml(convertToRaw(updatedEditorState.getCurrentContent()))
    setEditorState(updatedEditorState)
    modalValue.setContentValue(html)
    modalValue.validateContent(html)
    modalValue.formatContentPreviewDatas(html)
  }

  // --------------------------------------------------
  // Modal内の全アクション共通ハンドラー
  // --------------------------------------------------
  const onCommonHandler = (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    modalValue.setTitleValue(title.getTitle())
    modalValue.validateTitle(title.getTitle())
    modalValue.setCategoryValue(category.getCurrentCategory())

    // どれも入力されていない場合は何もしない
    if (!title.getTitle() && !category.getCurrentCategory() && !hero.getFile() && modalValue.contentValue === '') {
      return
    }

    // EditorのParamsをセットする
    if (title.getTitle() !== '') {
      modalHandler.setParams({
        id: modalSelector.modal.status === 'UPDATE' ? hintSelector.hint.id : modalSelector.modal.id,
        title: modalValue.titleValue,
        imageUrl: hero.resizeImage ? hero.resizeImage : '',
        content: modalValue.contentValue ? modalValue.contentValue : '',
        contentPreview: modalValue.contentPreviewValue ? modalValue.contentPreviewValue : '',
        category: modalValue.categoryValue ? modalValue.categoryValue : null,
        isPublished: 0,
      })
    }
  }

  // --------------------------------------------------
  // Submit時の処理
  // --------------------------------------------------
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    onCommonHandler(event)
    if (title.getTitle() === '') return
    if (modalValue.titleError || modalValue.contentError) return
    dialogPublish.onOpenDialog()
  }

  // --------------------------------------------------
  // Delete時の処理
  // --------------------------------------------------
  const onDoDlete = (event: React.FormEvent<HTMLFormElement>) => {
    onCommonHandler(event)
    if (title.getTitle() === '') return
    dialogDelete.onOpenDialog()
  }

  // --------------------------------------------------
  // Draft時の処理
  // --------------------------------------------------
  const onDoDraftPublish = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    onCommonHandler(event)
    if (title.getTitle() === '') return
    if (modalValue.titleError || modalValue.contentError) return
    const _params = {
      id: modalSelector.modal.status === 'UPDATE' ? hintSelector.hint.id : modalSelector.modal.id,
      title: modalValue.titleValue,
      imageUrl: hero.resizeImage ? hero.resizeImage : '',
      content: modalValue.contentValue ? modalValue.contentValue : '',
      contentPreview: modalValue.contentPreviewValue ? modalValue.contentPreviewValue : '',
      category: modalValue.categoryValue ? modalValue.categoryValue : null,
      isPublished: 0,
    }
    dialogSuspension.onDoSuspensionHandler(_params)
  }

  // --------------------------------------------------
  // Suspense時の処理
  // --------------------------------------------------
  const onDoSuspensePublish = (): void => {
    onCommonHandler()
    if (!title.getTitle() && !category.getCurrentCategory() && !hero.getFile() && modalValue.contentValue === '') {
      context.onCloseModal()
      return
    }
    if (modalValue.titleError) {
      context.onCloseModal()
      return
    }
    if (title.getTitle() === '') return

    if (modalValue.titleError || modalValue.contentError) return
    dialogSuspension.onOpenDialog()
  }

  // --------------------------------------------------
  // ToolbarのFixedハンドリング
  // --------------------------------------------------
  const { scrollableBoxRef, toolbarWrapperClass, toolbarClass } = useToolbar()

  return (
    <Box className={classes.root}>
      <Box {...{ className: classes.inner, ref: scrollableBoxRef }}>
        <Header
          onClick={onDoSuspensePublish}
          heading={modalSelector.modal.status === 'UPDATE' ? '記事編集' : '記事投稿'}
        />

        <DialogDelete presenter={dialogDelete} />
        <DialogPublish presenter={dialogPublish} params={modalHandler.getParams()} />
        <DialogSuspension presenter={dialogSuspension} params={modalHandler.getParams()} />

        <form>
          <Hero presenter={hero} uuid={modalSelector.modal.id} />
          <Wrapper color={constants.COLOR_WHITE}>
            <Container maxWidth={780}>
              <Editor
                wrapperClassName={`wrapper-class ${toolbarWrapperClass}`}
                toolbarClassName={`toolbar-class ${toolbarClass}`}
                editorClassName="editor-class"
                localization={localization}
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
                toolbar={{
                  ...toolbarConfig,
                  image: {
                    options: ['url', 'fileUpload'],
                    className: 'image-class',
                    popupClassName: 'image-modal-class',
                    uploadEnabled: true,
                    previewImage: true,
                    alignmentEnabled: false,
                    alt: { present: true, mandatory: false },
                    inputAccept: 'image/jpeg, image/jpg, image/png',
                    uploadCallback: modalToolbar.uploadCallback,
                  },
                }}
                placeholder="本文入力"
              />

              {modalValue.titleError && (
                <Box display="flex" mt="10px" mx={{ xs: '16px', lg: '0px' }}>
                  <ErrorMessage message={registers.MODAL_TIPS.TITLE.required.message} />
                </Box>
              )}

              {modalValue.contentError && (
                <Box display="flex" mt="10px" mx={{ xs: '16px', lg: '0px' }}>
                  <ErrorMessage message={registers.MODAL_TIPS.BODY.maxLength.message} />
                </Box>
              )}

              <Title
                name="title"
                className="title-class"
                presenter={title}
                value={modalValue.isUpdate() ? modalValue.titleValue : ''}
                onChange={(e) => {
                  modalValue.setTitleValue(e.target.value)
                  modalValue.validateTitle(e.target.value)
                  title.onTitleEnter(e)
                }}
              />

              <Box mt="140px" maxWidth="347px" px={{ xs: '16px', md: '0px' }}>
                <Categories
                  name="category"
                  presenter={category}
                  type={'post'}
                  value={modalValue.isUpdate() ? modalValue.categoryValue : null}
                  onChange={(e) => {
                    modalValue.setCategoryValue(e.target.value)
                    category.setCurrentCategory(e)
                  }}
                />
              </Box>

              <Box mt="140px" pb="120px" px={{ xs: '16px', md: '0px' }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 6, md: 4 }}>
                    <Button
                      bgColor={constants.COLOR_GRAY}
                      textColor={constants.TEXT_BLACK}
                      fullWidth={true}
                      onClick={onDoDlete}
                      body={<div>削除</div>}
                    />
                  </Grid>
                  <Grid size={{ xs: 6, md: 4 }}>
                    <Button
                      submit={true}
                      bgColor={constants.COLOR_GRAY}
                      textColor={constants.TEXT_BLACK}
                      fullWidth={true}
                      onClick={onDoDraftPublish}
                      body={<div>一時保存</div>}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <Button
                      submit={true}
                      bgColor={constants.COLOR_SECOND}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      onClick={onSubmitHandler}
                      body={<div>公開</div>}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Wrapper>
        </form>
      </Box>
    </Box>
  )
}
