import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton } from 'components/NoStyleButton'
import { Reaction } from 'pages/onboarding/components/reaction'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPost } from 'utils/generated'

import BalloonSvg from './assets/balloon.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


export type Props = {
  post: OnboardingPost
  teamId: string
}

export const Likes: React.FC<Props> = ({ post, teamId }) => {
  const classes = useStyles()
  const history = useOnbHistory()

  return (
    <div className={classes.statisticContainer}>
      <div className={classes.statisticIcons}>
        {post.type !== 'RANKING' && (
          <a href="#onbCommentInput">
            <div>
              <img className={classes.balloon} src={BalloonSvg} alt="balloon" />
            </div>
          </a>
        )}
        <Reaction postId={post.id} teamId={teamId} isLiked={post.isLiked} />
      </div>
      {post.likeCount ? (
        <div className={classes.statisticLikes}>
          <NoStyleButton
            className={classes.countButton}
            onClick={() => history.push(OnbPages.TimelinePostLikes, { teamId, postId: post.id })}
          >
            {post.likeCount} いいね
          </NoStyleButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    statisticContainer: {
      marginTop: 24,
    },
    statisticIcons: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))',
      alignItems: 'center',
      gap: '16px 24px',
      width: '100%',
    },
    balloon: {
      width: 24,
      verticalAlign: 'top',
    },
    statisticItem: {
      width: 24,
      cursor: 'pointer',
      height: 24,
    },
    statisticLikes: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 8,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      height: 24,
    },
    countButton: {
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },
  }),
  { name: 'Likes' }
)
