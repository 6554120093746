import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { postBalloonLabel } from 'pages/onboarding/utils/postLabel'
import { OnboardingPost, OnboardingTeamMember } from 'utils/generated'

import NoAvatarSvg from '../assets/noAvatar.svg'
import * as constants from 'assets/constants'



type Props = {
  post: OnboardingPost
  postUser: OnboardingTeamMember
  onAvatar?: () => void
}
type StyleProps = {}

export const AvatarBox: React.FC<Props & StyleProps> = ({ post, postUser, onAvatar }) => {
  const classes = useStyles()
  const pushPersonalPage = (e: React.MouseEvent) => {
    e.stopPropagation()
    onAvatar ? onAvatar() : undefined
  }

  return (
    <>
      <div className={classes.userContainer}>
        <div className={classes.usernameContainer} onClick={(e) => pushPersonalPage(e)}>
          {postUser.imageUrl ? (
            <img className={classes.userAvatar} src={postUser.imageUrl} alt={'avatar'} />
          ) : (
            <img className={classes.userAvatar} src={NoAvatarSvg} alt={'no-avatar'} />
          )}
        </div>
        {post.label ? (
          <div className={classes.postLabel}>
            <img src={postBalloonLabel(post.label)} alt="post-label" />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  {
    userContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    usernameContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
    },

    usernameButtonContainer: {
      height: 40,
    },

    userAvatar: {
      width: 60,
      height: 60,
      borderRadius: 30,
      backgroundColor: constants.COLOR_GRAY,
      objectFit: 'cover',
    },
    postLabel: {
      '& img': {
        width: 60,
        verticalAlign: 'top',
      },
    },
  },
  { name: 'AvatarBox' }
)
