import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CircularProgress, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { addHours } from 'date-fns'

import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useOnboardingPosts, useIntersection } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingPost, OnboardingTeamMemberRole } from 'utils/generated'

import { notificationCtx } from '../PagesRoot'
import { OnbContext } from '../PagesRoot'
import { TutorialProps } from '../tutorial/Tutorial'

import { NewPostModal } from './components'
import { ListItem } from './components/list-item/index'
import Toolbar from './components/toolbar'

import AlertSvg from './assets/alert.svg'
import ArrowRight from './assets/arrow-right.svg'
import ListSvg from './assets/list.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

interface TutorialOverride {
  posts: OnboardingPost[]
  loading: boolean
}

export const TimelineBloc = {
  useAdapter: (override?: TutorialOverride) => {
    const { teamMember, teamId } = useContext(OnbContext)
    const { posts, loading, createPost, nextToken, morePostList } = useOnboardingPosts(teamId)
    const { teamMembers } = useTeamMembers(teamId)

    const { unCheckedLeader, updateNotification } = useContext(notificationCtx)

    return {
      teamMember,
      teamId,
      posts,
      loading,
      createPost,
      nextToken,
      morePostList,
      teamMembers,
      unCheckedLeader,
      updateNotification,
      ...override,
    }
  },
}

const Index: React.FC<{ tutorial?: TutorialProps; override?: TutorialOverride }> = (props) => {
  const classes = useStyles()
  const routerHistory = useHistory()
  const history = useOnbHistory()

  // deps
  const {
    teamMember,
    teamId,
    posts,
    loading,
    createPost,
    nextToken,
    morePostList,
    teamMembers,
    unCheckedLeader,
    updateNotification,
  } = TimelineBloc.useAdapter(props.override)

  // UI control & event
  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>()
  const [newPostOpen, setNewPostOpen] = useState(false)

  const handleOnClickNotify = async (notifyId: string, teamId: string, actionId: string) => {
    await updateNotification(notifyId)
    history.push(OnbPages.TimelinePost, { teamId, actionId })
  }

  const [isNoticeShow, setIsNoticeShow] = useState(true)
  const closeDCNotice = () => {
    history.push(OnbPages.Actions, { teamId })
  }
  const noticeDailyCheck = () => {
    const now = new Date()
    const dailyCheckNotice = localStorage.getItem('notice-todays-daily-action')
    if (dailyCheckNotice === null || now > addHours(new Date(dailyCheckNotice), 24)) {
      localStorage.setItem('notice-todays-daily-action', now.toISOString())
      setIsNoticeShow(true)
    } else {
      setIsNoticeShow(false)
    }
  }
  useEffect(() => {
    noticeDailyCheck()
  }, [])

  const [isNoticeAdmin, setIsNoticeAdmin] = useState(true)
  const noticeFromAdmin = () => {
    const now = new Date()
    const dailyCheckNotice = localStorage.getItem('notice-from-admin-action')
    if (dailyCheckNotice === null || now > addHours(new Date(dailyCheckNotice), 24)) {
      localStorage.setItem('notice-from-admin-action', now.toISOString())
      setIsNoticeAdmin(true)
    } else {
      setIsNoticeAdmin(false)
    }
  }
  useEffect(() => {
    noticeFromAdmin()
  }, [])

  React.useEffect(() => {
    routerHistory.listen((_, action) => {
      if (action === 'POP') {
        const scrollBack = () => {
          setTimeout(() => {
            const scrollPosition = JSON.parse(
              window.localStorage.getItem('timeline-scroll-position') || '{ "x": 0, "y": 0 }'
            ) as {
              x: number
              y: number
            }
            window.scrollTo(scrollPosition.x, scrollPosition.y)
            if (window.scrollY < scrollPosition.y) {
              scrollBack()
            }
          }, 800)
        }
        scrollBack()
      } else if (action === 'PUSH') {
        const scrollPosition = { x: window.scrollX, y: window.scrollY }
        window.localStorage.setItem('timeline-scroll-position', JSON.stringify(scrollPosition))
      }
    })
  }, [routerHistory])

  // pagination
  const el = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>
  const { intersecting } = useIntersection(el)

  useEffect(() => {
    if (intersecting && !loading && nextToken && morePostList) {
      morePostList()
    }
  }, [intersecting, loading, nextToken, morePostList])

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        タイムライン
      </Typography>
      <Toolbar
        teamId={teamId}
        members={teamMembers
          .filter((i) => {
            return i.nickname
          })
          .map((i) => {
            return { value: i.userId, label: i.nickname || '' }
          })}
        selectedMemberId={selectedMemberId}
        onChangeMember={(value) => {
          setSelectedMemberId(value.length === 0 ? undefined : value)
        }}
        onClick={() => setNewPostOpen((prev) => !prev)}
      />
      {teamMember.role === OnboardingTeamMemberRole.Member && isNoticeShow ? (
        <div className={classes.cautionBox} onClick={closeDCNotice}>
          <div className={classes.cautionMsg}>
            <img src={ListSvg} alt="list" style={{ marginRight: 16 }} />
            今日もアクションリストをチェックしよう！
          </div>
          <img src={ArrowRight} alt="arrow-right" />
        </div>
      ) : (
        <></>
      )}

      {unCheckedLeader.length && isNoticeAdmin ? (
        <div
          className={classes.cautionBox}
          onClick={() =>
            unCheckedLeader.length > 1
              ? history.push(OnbPages.Notification, { teamId })
              : handleOnClickNotify(unCheckedLeader[0].id, teamId, unCheckedLeader[0].actionId)
          }
          style={{ marginTop: 8 }}
        >
          <div className={classes.cautionMsg}>
            <img src={AlertSvg} alt="alert" style={{ marginRight: 16 }} />
            運営事務局の投稿をチェックしよう！
          </div>
          <img src={ArrowRight} alt="arrow-right" />
        </div>
      ) : (
        <></>
      )}

      <NewPostModal
        isNewPostModalOpen={newPostOpen}
        popupClose={() => setNewPostOpen(false)}
        createPost={createPost}
        teamMember={teamMember}
        teamMemberList={teamMembers}
      />

      <div className={classes.list}>
        {!loading ? (
          <>
            {posts
              .filter((post) => (selectedMemberId ? post.userId === selectedMemberId : true))
              .map((item) =>
                item.teamMember ? (
                  <ListItem
                    key={item.id}
                    post={item}
                    postUser={item.teamMember}
                    teamMembers={teamMembers}
                    onComment={() => {
                      history.push(OnbPages.TimelinePost, { teamId, postId: item.id })
                    }}
                    onCommentFocus={() => {
                      history.push(OnbPages.TimelinePost, { teamId, postId: item.id }, undefined, { isFocus: true })
                    }}
                    onAvatar={() => {
                      teamMember.role === OnboardingTeamMemberRole.Member
                        ? history.push(OnbPages.MemberManageActions, { teamId, userId: item.userId })
                        : history.push(OnbPages.TeamMember, { teamId, userId: item.userId })
                    }}
                    tutorial={props.tutorial}
                  />
                ) : null
              )}
            {!loading && nextToken ? (
              <div style={{ paddingTop: 50 }} ref={el} />
            ) : (
              <>
                {loading && nextToken ? (
                  <div className={classes.postsLoading}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles({
  title: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 21,
    marginBottom: 26,
  },

  cautionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '24px 16px 0',
    paddingLeft: 16,
    paddingRight: 16,
    background: 'transparent linear-gradient(90deg, #00838C 0%, #03B2BE 100%) 0% 0% no-repeat padding-box',
    height: 48,
    borderRadius: 8,
    cursor: 'pointer',
  },
  cautionMsg: {
    display: 'flex',
    alignItems: 'center',
    color: constants.TEXT_WHITE,
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 16,
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 20,
  },

  loadingContainer: {
    minHeight: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },

  postsLoading: {
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-svg': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },
})

export default Index
