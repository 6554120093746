import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import ClockIcon from '../../assets/clock.svg'

type Props = {
  title: string
  description: string
  min: number
  max: number
  isCurrent: boolean
}

export const StepDescription: React.FC<Props> = ({ title, description, min, max, isCurrent }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root} ${isCurrent ? classes.current : ''}`}>
      <div>
        <div className={classes.title}>{title}</div>
        <div className={isCurrent ? classes.currentDescription : classes.description}>{description}</div>
      </div>
      <div className={classes.time}>
        <img className={classes.timeIcon} alt="time" src={ClockIcon} />
        <span>
          {min}-{max}分
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 180,
    height: 112,
    padding: 14,
    backgroundColor: '#F9F9F9',
    borderRadius: 8,
    '@media (max-width: 375px)': {
      padding: 8,
    },
    '@media (max-width: 632px)': {
      textAlign: 'left',
      width: '100%',
      height: 78,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
    '@media (max-width: 632px)': {
      fontSize: 16,
      marginBottom: 4,
    },
    '@media (max-width: 375px)': {
      fontSize: 12,
    },
  },
  time: {
    margin: '0 auto',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    fontSize: 10,
    fontWeight: 'bold',
    width: 63,
    borderRadius: 10,
    padding: '2px 4px',
    '@media (max-width: 632px)': {
      width: 'auto',
      position: 'absolute',
      top: 14,
      right: 14,
    },
    '@media (max-width: 375px)': {
      padding: '0 4px',
      gap: '2px',
      fontSize: 8,
      top: 8,
      right: 8,
    },
  },
  timeIcon: {
    height: 12,
    '@media (max-width: 375px)': {
      height: 10,
    },
  },
  description: {
    fontSize: 11,
    color: '#707070',
    '@media (max-width: 632px)': {
      fontSize: 14,
    },
    '@media (max-width: 375px)': {
      fontSize: 12,
    },
  },
  currentDescription: {
    fontSize: 11,
    color: '#FFFFFF',
    '@media (max-width: 632px)': {
      fontSize: 14,
    },
    '@media (max-width: 375px)': {
      fontSize: 12,
    },
  },
  current: {
    backgroundColor: '#00838C',
    color: '#FFFFFF',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      left: '50%',
      top: -16,
      border: '8px solid transparent',
      borderBottom: '16px solid #00838C',
      marginRight: '-8px',
      marginLeft: '-8px',
      '@media (max-width: 632px)': {
        left: 0,
        top: 20,
        borderBottom: '8px solid transparent',
        borderRight: '16px solid #00838C',
        borderLeft: 0,
        marginTop: '-8px',
      },
    },
  },
}))
