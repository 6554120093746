import * as React from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import { UsefulCard } from '../atoms'

type Props = {
  childrenForTitle: React.ReactNode
  childrenForContent: React.ReactNode
  onTap?: () => void
  ownStyles?: React.CSSProperties
  withBatch?: boolean
  selected?: boolean
  selectedNum?: number
  isOnDashboard?: boolean
}

export const GhostItemBox: React.FC<Props> = (props) => {
  const classes = useStyles()
  const theme = useTheme()

  const MQ = React.useCallback((query: string): { matches: boolean } => {
    return { matches: window.matchMedia(query).matches }
  }, [])
  const mediaQuery = useMediaQuery(theme.breakpoints.up(470), { ssrMatchMedia: MQ })

  return (
    <div className={classes.root} style={props.ownStyles} onClick={props.onTap}>
      <UsefulCard
        backgroundColor="gray"
        borderRadius={0}
        ownStyles={{
          width: mediaQuery || props.isOnDashboard ? 148 : 130,
          height: mediaQuery || props.isOnDashboard ? 148 : 130,
          position: 'relative',
        }}
      >
        {props.childrenForTitle}
        {props.selected && (
          <div className={classes.selectedLayer}>
            <div className={classes.selectedBatch}>{props.selectedNum}</div>
          </div>
        )}
      </UsefulCard>
      <UsefulCard
        backgroundColor="white"
        borderRadius={0}
        ownStyles={{
          position: props.withBatch ? 'relative' : undefined,
        }}
      >
        {props.childrenForContent}
      </UsefulCard>
    </div>
  )
}
const useStyles = makeStyles({
  root: {
    position: 'relative',
    cursor: 'pointer',
  },
  selectedLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 50%)',
    border: `4px solid ${constGhost.COLOR_MAIN_NEW}`,
  },
  selectedBatch: {
    display: 'inline-block',
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 20,
    boxSizing: 'border-box',
    padding: '4px 9px',
    backgroundColor: constGhost.COLOR_MAIN_NEW,
    fontSize: 14,
    fontWeight: 'bold',
    color: constGhost.COLOR_WHITE,
  },
})
