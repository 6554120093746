import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    margin: '0 auto',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
  maxWidth: number
}

// -----------------------------
// Component
// -----------------------------
export const Container = (props: IProps) => {
  const classes = useStyles()

  return (
    <section className={classes.root} style={{ maxWidth: props.maxWidth ? `${props.maxWidth}px` : '1080px' }}>
      {props.children}
    </section>
  )
}
