import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Theme } from '@mui/material'
import Slider from '@mui/material/Slider'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { useForm } from 'react-hook-form'

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../components/Button'
import { IOwnProps, IOwnResult, IOwnSubmit, IOwnSubmitData, IQuestionnaireSliderProps } from '../interface'

import Stepper from './components/Stepper'
import { connector, ContainerProps } from './index.container'

import questions from '../assets/questions'

const marks = [
  {
    value: 1,
    label: 'そう思う',
  },
  {
    value: 2,
    label: 'ややそう思う',
  },
  {
    value: 3,
    label: 'どちらともいえない',
  },
  {
    value: 4,
    label: 'ややそう思わない',
  },
  {
    value: 5,
    label: 'そう思わない',
  },
]
const initialQuestions = {
  direction: 0,
  bottomUp: 0,
  individually: 0,
  influence: 0,
  back: 0,
  relationInner: 0,
  relationOuter: 0,
}

const currentlQuestions = {
  direction: 0,
  bottomUp: 0,
  individually: 0,
  influence: 0,
  back: 0,
  relationInner: 0,
  relationOuter: 0,
}

type Props = IOwnProps & ContainerProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  //// Questionnaire
  const { register, handleSubmit, setValue, reset, errors } = useForm<IOwnSubmit>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(0)
  const [questionCurrent, setQuestionCurrent] = React.useState<IOwnResult>(currentlQuestions)
  const [questionTotal, setQuestionTotal] = React.useState<IOwnResult>(initialQuestions)
  const [history, setHistory] = React.useState<IOwnSubmitData>({})

  const handleStartNext = () => {
    setCurrentPageNum((prev) => ++prev)
    window.scrollTo(0, 0)
  }
  const handleStartPrev = () => {
    if (currentPageNum === 0) {
      props.history.push(pages.SELFCHECK_LANDING)
      window.scrollTo(0, 0)
      return
    }
    setCurrentPageNum((prev) => --prev)
    window.scrollTo(0, 0)
  }
  const handleStartResult = () => {
    props.history.push(pages.SELFCHECK_RESULTS)
    window.scrollTo(0, 0)
  }

  const onNextSubmit = (data: IOwnResult) => {
    setQuestionTotal((prev) => ({
      direction: Number(data.direction) + prev.direction,
      bottomUp: Number(data.bottomUp) + prev.bottomUp,
      individually: Number(data.individually) + prev.individually,
      influence: Number(data.influence) + prev.influence,
      back: Number(data.back) + prev.back,
      relationInner: Number(data.relationInner) + prev.relationInner,
      relationOuter: Number(data.relationOuter) + prev.relationOuter,
    }))

    handleStartNext()
  }

  const onPrevSubmit = () => {
    const data = questionCurrent

    setQuestionTotal((prev) => ({
      direction: prev.direction !== 0 ? prev.direction - Number(data.direction) : 0,
      bottomUp: prev.bottomUp !== 0 ? prev.bottomUp - Number(data.bottomUp) : 0,
      individually: prev.individually !== 0 ? prev.individually - Number(data.individually) : 0,
      influence: prev.influence !== 0 ? prev.influence - Number(data.influence) : 0,
      back: prev.back !== 0 ? prev.back - Number(data.back) : 0,
      relationInner: prev.relationInner !== 0 ? prev.relationInner - Number(data.relationInner) : 0,
      relationOuter: prev.relationOuter !== 0 ? prev.relationOuter - Number(data.relationOuter) : 0,
    }))

    handleStartPrev()
  }

  const onResultSubmit = (data: IOwnResult) => {
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()

    const submitData = {
      direction: Number(data.direction) + questionTotal.direction,
      bottomUp: Number(data.bottomUp) + questionTotal.bottomUp,
      individually: Number(data.individually) + questionTotal.individually,
      influence: Number(data.influence) + questionTotal.influence,
      back: Number(data.back) + questionTotal.back,
      relationInner: Number(data.relationInner) + questionTotal.relationInner,
      relationOuter: Number(data.relationOuter) + questionTotal.relationOuter,
      createAt: `${year}/${month}/${day}`,
    }

    const concatResult = (submitData: IOwnResult, history: IOwnResult[] = []) => {
      let jsonObj

      if (history.length !== 0) {
        if (history.length >= 12) {
          history.pop()

          const newObj = [...history]
          newObj.unshift(submitData)
          jsonObj = JSON.stringify(newObj)

          return jsonObj
        }

        const newObj = [...history]
        newObj.unshift(submitData)
        jsonObj = JSON.stringify(newObj)

        return jsonObj
      }

      history.push(submitData)
      jsonObj = JSON.stringify(history)

      return jsonObj
    }

    if (props.selfCheck.result?.data !== undefined && props.selfCheck.result?.data !== null) {
      const Result = JSON.parse(props.selfCheck.result.data)
      const concatJson = concatResult(submitData, Result)

      props.onSubmit(concatJson)
      handleStartResult()
      return
    }

    const concatJson = concatResult(submitData)
    props.onSubmit(concatJson)
    handleStartResult()
  }

  const onSubmit = (submitData: any) => {
    let data
    // buttonValue
    setQuestionCurrent((prev) => ({
      ...prev,
      ...submitData,
    }))

    if (currentPageNum === 3) {
      data = submitData
      onResultSubmit(data)
      return
    }

    data = submitData
    onNextSubmit(data)
  }

  React.useEffect(() => {
    props.fetch(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    reset()

    const questionForm = questions[currentPageNum]
    if (questionForm) {
      questionForm.forEach((data: any, index) => {
        register(data.name, { required: `「${data.title}」は必須です` })
        if (history[currentPageNum] && history[currentPageNum][index]) {
          setValue(data.name, history[currentPageNum][index])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNum])

  return (
    <div className={classes.main}>
      {props.user?.fetched && true && (
        <>
          <div className="__container">
            <h1 className="__title">セルフチェック</h1>

            <div className="__stepper">
              <Stepper activeStepIndex={currentPageNum} stepCount={4} />
            </div>

            <div className="__diagnose">
              <div className="__inner">
                {questions[currentPageNum] && (
                  <form id="form" name="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="__slider">
                      {questions[currentPageNum].map((item, index) => (
                        <React.Fragment key={item.id}>
                          <div className="__slider__values__title">
                            {item.id}. {item.title}
                          </div>
                          <QuestionnaireSlider
                            name={item.name}
                            setValue={setValue}
                            register={register}
                            marks={marks}
                            id={item.id}
                            history={history}
                            setVal={setHistory}
                            index={index}
                            currentPage={currentPageNum}
                            errors={errors}
                          />
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="__buttons">
                      <>
                        <Button
                          bgColor={constants.COLOR_BLACK}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>戻る</div>}
                          dataId="prev"
                          onClick={onPrevSubmit}
                        />
                        {currentPageNum < 3 ? (
                          <Button
                            bgColor={constants.COLOR_GREEN_DARK}
                            textColor={constants.COLOR_WHITE}
                            fullWidth={true}
                            body={<div>次へ</div>}
                            submit={true}
                            dataId="next"
                          />
                        ) : (
                          <Button
                            bgColor={constants.COLOR_GREEN_DARK}
                            textColor={constants.COLOR_WHITE}
                            fullWidth={true}
                            body={<div>診断結果へ</div>}
                            submit={true}
                            dataId="submit"
                          />
                        )}
                      </>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const QuestionnaireSlider: React.FC<IQuestionnaireSliderProps> = (props) => {
  const currentString = props.currentPage
  const indexString = props.index
  const history = props.history
  const errorMessage =
    props.errors.hasOwnProperty(props.name) && props.errors[props.name] ? props.errors[props.name].message : null

  const val = getvalue(currentString, indexString, history)

  const handleChange = (event: any, newValue: number | number[]) => {
    setHistory(props.history, props.currentPage, getQuestionValue(newValue as number))
    if (props.setValue) {
      props.setValue(props.name, getQuestionValue(newValue as number))
    }
  }
  function getvalue(currentNum: number, index: number, history: any) {
    const currentString = currentNum.toString()
    const indexString = index.toString()

    if (history[currentString] && history[currentString][indexString]) {
      return getQuestionValue(history[currentString][indexString])
    }

    return 0
  }
  function setHistory(history: any, currentPage: number, newValue: number | number[]) {
    const historyObj = { ...history }

    if (!historyObj[currentPage]) {
      historyObj[currentPage] = {}
    }

    historyObj[props.currentPage][props.index] = newValue
    props.setVal(historyObj)
  }

  function getQuestionValue(value: number) {
    if (props.id < 15) {
      switch (value) {
        case 1:
          return 1
        case 2:
          return 2
        case 3:
          return 3
        case 4:
          return 4
        case 5:
          return 5
        default:
          return 3
      }
    }
    switch (value) {
      case 1:
        return 5
      case 2:
        return 4
      case 3:
        return 3
      case 4:
        return 2
      case 5:
        return 1
      default:
        return 3
    }
  }

  function getSliderValue(value?: number) {
    switch (value) {
      case 1:
        return 1
      case 2:
        return 2
      case 3:
        return 3
      case 4:
        return 4
      case 5:
        return 5
      default:
        return 0
    }
  }

  return (
    <div className="__slider__values__value">
      <div className="__slider__values__value__label">
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
      </div>
      <div className="__slider__values__value__slider">
        <Slider
          value={getSliderValue(val)}
          valueLabelDisplay="off"
          step={1}
          marks={props.marks}
          min={1}
          max={5}
          classes={{
            thumb: val ? '__thumb' : '__thumb__hidden',
            track: '__track',
            rail: '__rail',
            mark: '__mark',
            markLabel: '__markLabel',
          }}
          onChange={handleChange}
          sx={{
            '& .MuiSlider-mark': {
              transition: 'none',
            },
            '& .MuiSlider-markActive': {
              transition: 'none',
            },
            '& .MuiSlider-thumb': {
              transition: 'none',
            },
          }}
        />
      </div>
      {errorMessage ? <small style={{ color: 'red', paddingTop: 16 }}>{errorMessage}</small> : null}
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY,
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        boxSizing: 'border-box',
        padding: '0 16px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
          padding: 0,
        },

        '& .__title': {
          textAlign: 'center',
          marginBottom: '26px',
        },

        '& .__stepper': {
          marginBottom: '14px',
        },

        '& .__diagnose': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '40px 16px 40px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
          '& .__inner': {
            marginBottom: '15px',
          },
        },

        '& .__slider': {
          '&__values': {
            '&__title': {
              paddingBottom: '2px',
              fontWeight: 'bold',
              borderBottom: `1px solid ${constants.COLOR_MAIN}`,
            },
            '&__value': {
              '&__label': {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '16px 14px 0',
                '& span': {
                  display: 'block',
                  fontSize: '8px',
                },
              },
              '&__slider': {
                padding: '0 16px 16px',
              },

              '& .__thumb': {
                height: 28,
                width: 28,
                backgroundColor: constants.COLOR_SECOND,
                '&:focus, &:hover, &$active': {
                  boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
                  '@media (hover: none)': {},
                },
              },
              '& .__thumb__hidden': {
                display: 'none',
              },

              '& .__track': {
                opacity: 0,
              },
              '& .__rail': {
                height: 2,
                opacity: 1,
                backgroundColor: constants.COLOR_BLACK,
              },
              '& .__mark': {
                backgroundColor: constants.COLOR_BLACK,
                height: 16,
                width: 2,
              },
              '& .__markLabel': {
                fontSize: '8px',
              },
            },
          },
          '& .__error': {
            display: 'none',
            color: 'red',
            margin: 0,
          },
          '& .__show': {
            display: 'block',
          },
        },
        '& .__buttons': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 0',
          marginBottom: '100px',
          '& :first-child': {
            marginRight: '4px',
          },
          '& :last-child': {
            marginLeft: '4px',
          },
        },
      },
    },
  })

export default withRouter(withStyles(useStyles)(connector(Index)))
