import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'


interface IOwnProps {
  ownStyles?: React.CSSProperties
}
type Props = constTop.topicAndText & IOwnProps
export const TextBox: React.FC<Props> = (props) => {
  const classes = useStyles({})

  // topic2 is optional props

  return (
    <>
      <div className={classes.topic}>{props.topic}</div>
      <div className={classes.topic2}>{props.topic2}</div>
      <div className={classes.text} style={props.ownStyles}>
        {props.text}
      </div>
    </>
  )
}

const useStyles = makeStyles({
  topic: {
    ...constTop.topicStyles,
    whiteSpace: 'pre-wrap',
  },
  topic2: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: '8px 0 0 0',
    whiteSpace: 'pre-wrap',
  },
  text: {
    fontSize: 24,
    margin: '24px 0 0 0',
    lineHeight: 1.75,
  },
})
