import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: constants.COLOR_GRAY_LIGHT,
    borderRadius: '5px 5px 0 0',
    lineHeight: '50px',
    height: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    marginRight: '20px',
    fontSize: '14px',
    fontWeight: 600,

    border: 'none',

    [theme.breakpoints.down('lg')]: {
      marginRight: '10px',
      lineHeight: '45px',
      fontSize: '15px',
      height: '45px',
    },

    '&:focus': {
      outline: 'none',
    },
  },

  active: {
    color: constants.COLOR_WHITE,
    backgroundColor: constants.COLOR_MAIN,
  },

  icon: {
    display: 'flex',
    '& > svg': {
      marginRight: '4px',
      width: '16px',
      height: '16px',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  icon: 'Tips' | 'お悩み掲示板'
  isActive?: boolean
  label: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

// -----------------------------
// Component
// -----------------------------
export const Tab = (props: IProps) => {
  const classes = useStyles()
  return (
    <button
      className={classes.root}
      type="button"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => props.onClick(e)}
      style={{
        backgroundColor: props.isActive ? constants.COLOR_MAIN : '#EBEBEB',
        color: props.isActive ? constants.COLOR_WHITE : '#AAAAAA',
      }}
    >
      {props.icon === 'Tips' && (
        <span className={classes.icon}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="lightbulb"
            className="icon svg-inline--fa fa-lightbulb fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M96.06 454.35c.01 6.29 1.87 12.45 5.36 17.69l17.09 25.69a31.99 31.99 0 0 0 26.64 14.28h61.71a31.99 31.99 0 0 0 26.64-14.28l17.09-25.69a31.989 31.989 0 0 0 5.36-17.69l.04-38.35H96.01l.05 38.35zM0 176c0 44.37 16.45 84.85 43.56 115.78 16.52 18.85 42.36 58.23 52.21 91.45.04.26.07.52.11.78h160.24c.04-.26.07-.51.11-.78 9.85-33.22 35.69-72.6 52.21-91.45C335.55 260.85 352 220.37 352 176 352 78.61 272.91-.3 175.45 0 73.44.31 0 82.97 0 176zm176-80c-44.11 0-80 35.89-80 80 0 8.84-7.16 16-16 16s-16-7.16-16-16c0-61.76 50.24-112 112-112 8.84 0 16 7.16 16 16s-7.16 16-16 16z"
            ></path>
          </svg>
        </span>
      )}
      {props.icon === 'お悩み掲示板' && (
        <span className={classes.icon}>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="comments"
            className="icon svg-inline--fa fa-comments fa-w-18"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"
            ></path>
          </svg>
        </span>
      )}
      {props.label}
    </button>
  )
}
