import * as React from 'react'

import { Box } from '@mui/material'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  src: string
  alt: string
}

// -----------------------------
// Component
// -----------------------------
export const Close = (props: IProps) => {
  return (
    <Box>
      <img src={props.src} alt={props.alt} style={{ verticalAlign: 'middle' }} />
    </Box>
  )
}
