import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={`${classes.container}${className ? ` ${className}` : ''}`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="13.562" height="13.562" viewBox="0 0 13.562 13.562">
        <path
          d="M6.781-12.031A6.78,6.78,0,0,0,0-5.25,6.78,6.78,0,0,0,6.781,1.531,6.78,6.78,0,0,0,13.562-5.25,6.78,6.78,0,0,0,6.781-12.031ZM8.969-7.437a.874.874,0,0,1,.875.875.874.874,0,0,1-.875.875.874.874,0,0,1-.875-.875A.874.874,0,0,1,8.969-7.437Zm-4.375,0a.874.874,0,0,1,.875.875.874.874,0,0,1-.875.875.874.874,0,0,1-.875-.875A.874.874,0,0,1,4.594-7.437ZM9.92-2.784A4.074,4.074,0,0,1,6.781-1.312,4.074,4.074,0,0,1,3.642-2.784a.438.438,0,0,1,.673-.561A3.2,3.2,0,0,0,6.781-2.19,3.2,3.2,0,0,0,9.248-3.344.438.438,0,0,1,9.92-2.784Z"
          transform="translate(0 12.031)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
