import React, { MouseEvent, FC } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useMountTransition } from './hooks/useMountTransition'

import { constants } from 'assets'

const transitionMs = 300

type Props = {
  children: React.ReactNode
  isModalOpen: boolean
  handleModalClose: () => void
  modalStyle?: React.CSSProperties
  styleProps?: StyleProps
}

type StyleProps = {
  width?: number | string
  maxWidth?: number | string
  height?: number | string
  maxHeight?: number | string
}

export const GenericModal: FC<Props> = ({ children, isModalOpen, handleModalClose, modalStyle, styleProps }) => {
  const classes = useStyles(styleProps ?? {})

  // フェードアニメーションを管理するためのカスタムフック
  const { hasTransitionedIn } = useMountTransition(isModalOpen, transitionMs)

  const handleContentClick = (e: MouseEvent) => {
    e.stopPropagation()
  }

  return (
    <>
      {(isModalOpen || hasTransitionedIn) && (
        <div
          className={`${classes.overlay} ${isModalOpen && hasTransitionedIn ? classes.fadeIn : ''}`}
          onClick={handleModalClose}
        >
          <div className={classes.modal} style={{ ...modalStyle }} onClick={handleContentClick}>
            {children}
          </div>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    overlay: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: '32px 16px',
      backgroundColor: `${constants.COLOR_ONBOARDING_GRAY2}B3`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 20000,
      animation: `$fadeOut ${transitionMs}ms forwards`,
      '@global': {
        '@keyframes fadeOut': {
          from: {
            opacity: '1',
          },
          to: {
            opacity: '0',
          },
        },
      },
    },
    fadeIn: {
      animation: `$fadeIn ${transitionMs}ms forwards`,
      '@global': {
        '@keyframes fadeIn': {
          from: {
            opacity: '0',
          },
          to: {
            opacity: '1',
          },
        },
      },
    },
    modal: ({ width, maxWidth, height, maxHeight }) => ({
      width: width ?? undefined,
      maxWidth: maxWidth ?? '100%',
      height: height ?? undefined,
      maxHeight: maxHeight ?? '100%',
      backgroundColor: '#fff',
      borderRadius: 8,
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    }),
  }),
  { name: 'GenericModal' }
)
