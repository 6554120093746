import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../assets/constants'
import * as pages from '../../../assets/pages'
import Button from '../../../components/Button'

// import ModalStartSetup from './components/ModalStartSetup.component'
import { connector, ContainerProps } from './index.container'


type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const handleStartKarte = () => {
    // setOpen(true)
    props.history.push(pages.KARTE_LEADER_HOME)
    window.scrollTo(0, 0)
  }

  const handlePageSignup = () => {
    props.history.push(constants.PAGE_SIGNUP, { transition: pages.KARTE_LANDING })
    window.scrollTo(0, 0)
  }

  const handlePageSignin = () => {
    props.history.push(constants.PAGE_SIGNIN + '?r=' + pages.KARTE_LANDING)
    window.scrollTo(0, 0)
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    (<React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && true && (
          // !props.user?.karteStartedAt && // TODO
          (<>
            <div className="__container">
              <div className="__content">
                <div className="__top">
                  <div className="__subTitle">
                    <span>1on1をアップグレードする</span>
                  </div>
                  <h3>カルテ</h3>

                  <div className="__landing_front_wrapper">
                    <img
                      className="__landing_front"
                      src={process.env.PUBLIC_URL + '/assets/svg/karte_landing.svg'}
                      alt={'karte_landing'}
                    />
                    <Link className="__landing_front_badge" to="/hint/tips/457e66ed-453f-42e2-9193-6a4f770abb8f">
                      <img src="/assets/svg/karte/badge.svg" alt="使い方はこちら" width="90" height="90" />
                    </Link>
                  </div>

                  <div className="__buttons">
                    {props.user?.id ? (
                      <Button
                        bgColor={constants.COLOR_BLUE}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>利用開始</div>}
                        onClick={handleStartKarte}
                      />
                    ) : (
                      <>
                        <Button
                          bgColor={constants.COLOR_BLACK}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>無料登録</div>}
                          onClick={handlePageSignup}
                        />
                        <Button
                          bgColor={constants.COLOR_BLUE}
                          textColor={constants.COLOR_WHITE}
                          fullWidth={true}
                          body={<div>ログイン</div>}
                          onClick={handlePageSignin}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="__description">
                  <div className="__title">カルテとは？</div>
                  <div className="__body">
                    上司と部下の相互認識を合わせて関係性を強めていくサポートツールです。
                    <br />
                    主な利用シーンは面談前です。これを使うことにより、上司と部下が自分自身の考え方を客観的に見ることができた上で、相手側のことも理解して面談に臨むことができるようになります。カルテを使用してから面談に臨むだけで面談の品質を大きく向上できます。
                  </div>
                  <div className="__body">動画で見る1on1アップグレードツール：カルテ（2分13秒）</div>
                  <div className="__video">
                    <iframe
                      title="karte-top-video"
                      src="https://player.vimeo.com/video/663268191?h=2533660adf"
                      width="640"
                      height="360"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                    ></iframe>
                  </div>
                  <div className="__title">どんな人におすすめ？</div>
                  <div className="__body">
                    メンバーのことが理解できない、もしくは理解しているつもりなのに何故か思うように力を発揮させられないと感じているチームリーダーの方に最適です。
                  </div>
                </div>
                <div className="__functions">
                  <div className="__description">
                    <div className="__title">機能紹介</div>
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/karte/karte1.png'} alt={'karte1'} />
                    </div>
                    <div className="__body">
                      各メンバーにURLを渡して自己診断をしてもらいます。自己診断の結果を見るだけでメンバーは自身の仕事観を客観視できます。
                    </div>
                  </div>

                  <div className="__line" />

                  <div className="__description">
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/karte/karte2.png'} alt={'karte2'} />
                    </div>
                    <div className="__body">
                      診断結果では、自身が仕事において大事にしていること、重視していないことなどが浮き彫りになります。これができているだけでも面談に臨む姿勢が変わります。
                    </div>
                  </div>

                  <div className="__line" />

                  <div className="__description">
                    <div className="__image">
                      <img src={process.env.PUBLIC_URL + '/assets/png/karte/karte3.png'} alt={'karte3'} />
                    </div>
                    <div className="__body">
                      リーダーはメンバーとの相互理解度合をベースとした分析結果が得られます。
                      なお、リーダーの診断はメンバーの自己診断結果に付いているカルテNoを共有してもらってから行います。
                    </div>
                  </div>
                </div>

                <div className="__buttons">
                  {props.user?.id ? (
                    <Button
                      bgColor={constants.COLOR_BLUE}
                      textColor={constants.COLOR_WHITE}
                      fullWidth={true}
                      body={<div>利用開始</div>}
                      onClick={handleStartKarte}
                    />
                  ) : (
                    <>
                      <Button
                        bgColor={constants.COLOR_BLACK}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>無料登録</div>}
                        onClick={handlePageSignup}
                      />
                      <Button
                        bgColor={constants.COLOR_BLUE}
                        textColor={constants.COLOR_WHITE}
                        fullWidth={true}
                        body={<div>ログイン</div>}
                        onClick={handlePageSignin}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <ModalStartSetup open={open} handleClose={handleClose} /> */}
          </>)
        )}
      </div>
    </React.Fragment>)
  );
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: '#bfeaee',
      paddingTop: '32px',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderTopRightRadius: '30px',
          borderTopLeftRadius: '30px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '& .__buttons': {
            display: 'flex',
            marginTop: '8px',
            '& :first-child': {
              marginRight: '4px',
            },
            '& :last-child': {
              marginLeft: '4px',
            },
          },
          '& .__top': {
            '& .__subTitle': {
              textAlign: 'center',
              '& span': {
                // borderBottom: '1px solid',
                background: 'linear-gradient(transparent 75%, ' + constants.COLOR_SECOND + ' 0%)',
                fontWeight: 'bold',
              },
            },
            '& h3': {
              textAlign: 'center',
              fontSize: '24px',
              marginTop: '4px',
            },
          },
          '& .__landing_front_wrapper': {
            position: 'relative',
            paddingTop: '30px',
          },
          '& .__landing_front': {
            width: '100%',
          },
          '& .__landing_front_badge': {
            position: 'absolute',
            right: 0,
            top: 0,
            '& img': {
              display: 'block',
            },
          },
          '& .__title': {
            fontSize: '20px',
            color: constants.COLOR_MAIN_DARK,
            margin: '16px 0',
            width: '100%',
            fontWeight: 'bold',
          },

          '& .__description': {
            padding: '16px',
            '& .__image': {
              '& img': {
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '70%',
              },
            },

            '& .__body': {
              lineHeight: '24px',
              padding: '16px 0',
            },
            '& .__video': {
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              marginBottom: 42,
              '& iframe': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            },
          },

          '& .__line': {
            margin: '16px 0',
            borderBottom: '1px solid #e3e3e3',
          },
          '& .__functions': {
            backgroundColor: constants.COLOR_GRAY,

            '& .__description': {
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexWrap: 'wrap',
              },
              '& .__image': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },

              '& .__body': {
                [theme.breakpoints.up('md')]: {
                  width: '50%',
                },
              },
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
