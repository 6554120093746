import * as React from 'react'

import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import withStyles from '@mui/styles/withStyles'

import * as constants from '../../../../assets/constants'

interface IOwnProps {
  value: number
  max: number
  setWeekSlider: React.Dispatch<React.SetStateAction<number>>
}

interface ISliderProps {
  children: React.ReactElement
  open: boolean
  value: number
}

type Props = IOwnProps

const Index: React.FC<Props> = (props: Props) => {
  function ValueLabelComponent(props: ISliderProps) {
    const { children, value } = props

    return (
      <>
        <RadarTooltip open={true} enterTouchDelay={0} placement="top" title={<div>{value + 'w'}</div>}>
          {children}
        </RadarTooltip>
      </>
    )
  }

  const [sliderValue, setSliderValue] = React.useState(props.value)
  const handleChange = (event: object, value: number | number[]) => {
    const val = value as number
    if (props.value >= val) {
      setSliderValue(val)
      props.setWeekSlider(val)
    }
  }

  React.useEffect(() => {
    setSliderValue(props.value)
  }, [props.value])

  return (
    <PrettoSlider
      aria-label="pretto slider"
      value={sliderValue}
      max={props.max}
      min={0}
      onChange={handleChange}
      valueLabelDisplay="on"
      slots={{ valueLabel: ValueLabelComponent }}
    />
  )
}

const PrettoSlider = withStyles({
  root: {
    color: constants.COLOR_MAIN,
    height: 6,
    marginTop: 45,

    '&.Mui-disabled': {
      color: constants.COLOR_MAIN,
    },
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&.Mui-disabled': {
      backgroundColor: 'gray',
      border: '1px solid #fff',
      height: 10,
      width: 10,
      marginTop: -2,
      marginLeft: -6,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 8px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    color: constants.TEXT_GRAY,
    height: 6,
    borderRadius: 4,
  },
})(Slider)

const RadarTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    padding: '4px 10px',
    borderRadius: 0,
    boxShadow: '-1px -1px 4px -1px rgba(21, 27, 38, 0.15)',
    margin: 0,
    zIndex: 10,
    transition: '0s',

    '&::before': {
      content: '""',
      position: 'absolute',
      left: '7px',
      zIndex: -1,
      backgroundColor: constants.COLOR_WHITE,
      borderWidth: '100px 100px 0 100px',
      height: '20px',
      width: '20px',
      // boxShadow: '-2px 2px 2px 0 rgba( 178, 178, 178, .4 )',
      transform: 'rotate( -45deg )',
      mozTransform: 'rotate( 45deg )',
      msTransform: 'rotate( 45deg )',
    },
  },
})(Tooltip)

export default Index
