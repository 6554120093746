import React from 'react'

import MuiProgress from '@mui/material/CircularProgress'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type CircularProgressProps = {
  size?: number
} & StyleProps

type StyleProps = {
  color?: string
  margin?: string
}

export const CircularProgress: React.FC<CircularProgressProps> = ({ size, color, margin }) => {
  const classes = useStyles({ color, margin })

  return (
    <div className={classes.wrapper}>
      <MuiProgress className={classes.progress} size={size ?? 20} />
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    wrapper: {
      display: 'grid',
      placeItems: 'center',
      width: '100%',
    },
    progress: ({ margin, color }) => ({
      margin: margin || '24px auto',
      color: color || constants.COLOR_MAIN_NEW,
    }),
  }),
  { name: 'CircularProgress' }
)
