import * as React from 'react'

import { getGhostTeamByMaster } from 'pages/ghost/api/team/handlers'
import { useGhostTeam } from 'pages/ghost/contexts/ghostTeam'
import { HooksContext } from 'pages/teams/contexts'
import { TeamTool } from 'utils/generated'

import { replacePathParams } from 'assets/history'

type UseContentHeader = { teamId: string | undefined }

export const useContentHeader = ({ teamId }: UseContentHeader) => {
  const { currentTeam, isAdmin, isLeader } = React.useContext(HooksContext)
  const { createGhostTeam } = useGhostTeam(teamId)

  const canGhostTeamCreate = (isAdmin || isLeader) ?? false

  const toWaiting = async () => {
    const ghostTeam = await getGhostTeamByMaster({ masterTeamId: teamId }) // it is usual to use hooks(fetchGhostTeamByMaster and team in useGhostTeam), but only this page use it because setStateAction is async process not to update before using value.
    if (ghostTeam) {
      window.open(replacePathParams(`/ghost/${ghostTeam.id}/waiting`, { teamId }), '_team-ghost')
    } else {
      if (canGhostTeamCreate) {
        const newGhostTeam = await createGhostTeam({ input: { masterTeamId: teamId } }) // in this API if ghostTeam already exist, return it.
        window.open(replacePathParams(`/ghost/${newGhostTeam.id}/waiting`, { teamId }), '_team-ghost')
      } else {
        alert('おばけゲーム立ち上げを行うため、一番最初はリーダーの方がこちらをクリックしてください')
      }
    }
  }

  const toolsStates = {
    isIceBreak: currentTeam?.teamTools && currentTeam.teamTools.length > 0,
    isTB: currentTeam?.teamTools.includes(TeamTool.BuildingCheck),
    isGhost: currentTeam?.teamTools.includes(TeamTool.Ghost),
    isKarte: currentTeam?.teamTools.includes(TeamTool.Karte),
    isBuilding: currentTeam?.teamTools.includes(TeamTool.Building),
    isBuildingReview: currentTeam?.teamTools.includes(TeamTool.Building),
  }

  return { canGhostTeamCreate, toWaiting, toolsStates }
}
