import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from 'store'
import { v4 as uuidv4 } from 'uuid'

import Button from 'components/Button'
import { CommentField } from 'pages/hint/_shared/components/commentField/Index'
import { useCommentField } from 'pages/hint/_shared/components/commentField/useCommentField'
import { ErrorMessage } from 'pages/hint/_shared/components/ereorMessage/Index'
import { WrapperButton } from 'pages/hint/_shared/layouts/article/Index'
import actions from 'pages/hint/_shared/stores/actions'

import { IProps } from '../../hooks/useFaqArticle'

import * as constants from 'assets/constants'
import * as registers from 'assets/registers'

// -----------------------------
// Props
// -----------------------------
type Iprops = {
  presenter: IProps
  handler: () => void
}

// -----------------------------
// Component
// -----------------------------
const CommentForm = (props: Iprops) => {
  // -----------------------------
  // Hooks
  // -----------------------------
  const commentField = useCommentField()
  const { register, handleSubmit, setValue, errors } = useForm()

  const history = useHistory()
  const dispatch = useDispatch()

  const answerSelector = useSelector((state: RootStateType) => state.hint.answer)
  const userSelector = useSelector((state: RootStateType) => state.user)
  const hintSelector = useSelector((state: RootStateType) => state.hint)

  const onSubmitHandler = () => {
    const _params = {
      id: uuidv4(),
      consultationId: hintSelector.consultation.id,
      content: commentField.getCommnetValue(),
      type: 'CONSULTATION',
    }
    props.presenter.onSetParams(_params)

    // ログインしていない場合はreduxに情報を詰めてログイン画面へ
    if (!userSelector?.id) {
      dispatch(actions.getAnswer(_params))
      history.push(constants.PAGE_SIGNIN)
    } else {
      if (commentField.getCommnetValue() === '') return
      props.handler()
    }
  }

  // -----------------------------
  // LifeCycle
  // -----------------------------
  React.useEffect(() => {
    commentField.setCommentValue(answerSelector.content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSelector])

  React.useEffect(() => {
    window.scrollTo(0, 0)
    register('comment', registers.ARTICLE_COMMENT)
  }, [register])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <CommentField
        name={'comment'}
        value={commentField.getCommnetValue()}
        onChange={(e) => {
          setValue('comment', e.target.value)
          commentField.setCommentValue(e.target.value)
        }}
      />
      {errors.comment && (
        <Box display="flex" mt="10px">
          <ErrorMessage message={registers.ARTICLE_COMMENT.maxLength.message} />
        </Box>
      )}
      <WrapperButton>
        <Button
          submit={true}
          fullWidth={true}
          bgColor={constants.COLOR_GREEN_DARK}
          textColor={constants.TEXT_WHITE}
          body={<>{!userSelector?.id ? <div>ログインして回答する</div> : <div>回答する</div>}</>}
        />
      </WrapperButton>
    </form>
  )
}

export default CommentForm
