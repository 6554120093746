import * as React from 'react'

import { Theme } from '@mui/material';
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    fontWeight: 'bold',
    boxShadow: 'none',

    minWidth: '120px',
    width: '120px',
    height: '34px',
    fontSize: '12px',
    marginRight: 16,
  },

  icon: {
    display: 'flex',
    alignItems: 'center',

    width: '20px',
    height: '20px',
    marginRight: '4px',

    '& svg': {
      width: '100%',
      height: '100%',
      verticalAlign: 'middle',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  color?: string
  textColor?: string
  fullWidth?: boolean
  onClick?: any
  submit?: boolean
  body: any
  disabled?: boolean
  action: 'Tips' | 'お悩み掲示板'
}

// -----------------------------
// Component
// -----------------------------
export const ModalButton = (props: IProps) => {
  const classes = useStyles()

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={props.onClick}
      type={props.submit ? 'submit' : 'button'}
      style={{
        backgroundColor: props.color || constants.COLOR_BLACK,
        color: props.textColor || constants.COLOR_WHITE,
        width: props.fullWidth ? '100%' : '',
        opacity: props.disabled ? 0.6 : 1,
      }}
      disabled={props.disabled}
    >
      {props.action === 'Tips' && (
        <span className={classes.icon}>
          <img src="/assets/svg/hint/tips_icn.svg" alt="Tips" />
        </span>
      )}
      {props.action === 'お悩み掲示板' && (
        <span className={classes.icon}>
          <img src="/assets/svg/hint/faq_icn.svg" alt="お悩み相談" />
        </span>
      )}
      {props.body}
    </Button>
  )
}
