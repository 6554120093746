import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../assets/constants'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__container">
          <h3>運営会社</h3>
          <div className="__content">
            <div className="__title">運営会社名</div>
            <p>{process.env.REACT_APP_COMPANY_NAME}</p>
            <div className="__title">代表</div>
            <p>{process.env.REACT_APP_COMPANY_OWNER}</p>
            <div className="__title">所在地</div>
            <p>{process.env.REACT_APP_COMPANY_ADDRESS}</p>
            <div className="__title">お問い合わせ</div>
            <p>{process.env.REACT_APP_COMPANY_CONTACT}</p>
            <div className="__title">設立日</div>
            <p>{process.env.REACT_APP_COMPANY_PUBLISHED}</p>
            <div className="__title">事業概要</div>
            <p>{process.env.REACT_APP_COMPANY_DO}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY,
      '& h3': {
        textAlign: 'center',
        fontSize: '24px',
      },
      '& .__container': {
        margin: '0 auto',
        paddingBottom: '32px',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          margin: '0 16px',
          padding: '32px 16px',

          '& .__title': {
            borderBottom: '1px solid',
            color: constants.COLOR_MAIN,
            paddingBottom: '4px',
          },
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
