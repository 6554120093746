import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { IKarteMemberResults } from 'pages/hataraku/pages/results/interface'

import { constants } from 'assets'

type Props = {
  characteristics: IKarteMemberResults['characteristics']
}

export const Commitment: React.FC<Props> = ({ characteristics }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.heading}>はたらくこだわり</div>
      <div className={classes.cardContainer}>
        <div className={classNames([classes.card, classes.doing])}>
          <div className={classes.cardHeader}>
            <img
              src={`${process.env.PUBLIC_URL}/img/hataraku/approval-sign-man.png`}
              alt="男性が丸印のサインを示している"
            />
            重視していること
          </div>
          <div className={classes.cardBody}>
            <ul>
              {characteristics.good.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={classNames([classes.card, classes.notDoing])}>
          <div className={classes.cardHeader}>
            <img
              src={`${process.env.PUBLIC_URL}/img/hataraku/disapproval-sign-woman.png`}
              alt="女性がバツ印のサインを示している"
            />
            重視していないこと
          </div>
          <div className={classes.cardBody}>
            <ul>
              {characteristics.bad.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: 40,
      paddingBottom: 40,
      borderBottom: '2px solid #fff',
    },

    heading: {
      color: '#fff',
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 40,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        marginBottom: 24,
      },
    },

    cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },

    card: {
      display: 'flex',
      gap: 80,
      padding: '40px 40px 40px 80px',
      backgroundColor: '#fff',
      borderRadius: 8,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        gap: 0,
        padding: 24,
      },
    },

    doing: {
      border: `5px solid ${constants.COLOR_YELLOW2}`,
      color: constants.COLOR_YELLOW2,
      '& li': {
        backgroundColor: constants.COLOR_YELLOW_LIGHT2,
      },
    },

    notDoing: {
      border: `5px solid ${constants.COLOR_BLUE_DARK2}`,
      color: constants.COLOR_BLUE_DARK2,
      '& li': {
        backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT3,
      },
    },

    cardHeader: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
      justifyContent: 'center',
      alignItems: 'center',
      gap: 8,
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        marginBottom: 16,
      },
      '& img': {
        width: 80,
        height: 80,
        [theme.breakpoints.down('md')]: {
          width: 60,
          height: 60,
        },
      },
    },

    cardBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      '& ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
      '& li': {
        marginBottom: 8,
        padding: '13px 16px',
        borderRadius: 80,
        color: constants.TEXT_GRAY_DARK,
        fontSize: 14,
      },
      '& li:last-child': {
        marginBottom: 0,
      },
    },
  }),
  { name: 'Commitment' }
)
