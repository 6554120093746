import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { DefaultButton } from '.'

interface Props {
  onClick?: () => void
  label: string
  icon:
    | 'icon_team_add'
    | 'icon_team_add_csv'
    | 'icon_member_add'
    | 'icon_member_add_csv'
    | 'FilePdf'
    | 'humanIcon'
    | 'humanIcon2'
    | 'rotateIcon'
  buttonColor?: 'team_white' | 'team_grayCard'
  styles?: React.CSSProperties
  titleStyles?: React.CSSProperties
}

export const IconButtonWithLabel: React.FC<Props> = ({
  onClick,
  label,
  buttonColor = 'team_white',
  icon,
  styles,
  titleStyles,
}) => {
  const classes = useStyles()

  return (
    <DefaultButton
      icon={<img src={`/assets/svg/teams/${icon}.svg`} alt="icon" className={classes.menuIcon} />}
      title={label}
      color={buttonColor} //      color={'team_white'}
      style={styles}
      titleStyle={titleStyles}
      onClick={onClick}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  menuIcon: {
    marginRight: 8,
  },
}))
