import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

type Props = {
  clickLeftButton: () => void
  clickRightButton: () => void
  isAbleToClickLeft?: boolean
  isAbleToClickRight?: boolean
}

export const UserVoiceArrowIcons: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <img
        className={classNames([
          props.isAbleToClickLeft ? classes.arrowLeftButtonAbleToClickImg : classes.arrowLeftButtonDisableToClickImg,
        ])}
        src={
          props.isAbleToClickLeft
            ? process.env.PUBLIC_URL + '/img/topPage/userVoice/arrowLeftButtonAbleToClick.png'
            : process.env.PUBLIC_URL + '/img/topPage/userVoice/arrowLeftButtonDisableToClick.png'
        }
        alt="slideToLeft"
        onClick={props.isAbleToClickLeft ? props.clickLeftButton : undefined}
      />
      <img
        className={classNames([
          props.isAbleToClickRight ? classes.arrowRightButtonAbleToClickImg : classes.arrowRightButtonDisableToClickImg,
        ])}
        src={
          props.isAbleToClickRight
            ? process.env.PUBLIC_URL + '/img/topPage/userVoice/arrowRightButtonAbleToClick.png'
            : process.env.PUBLIC_URL + '/img/topPage/userVoice/arrowRightButtonDisableToClick.png'
        }
        alt="slideToRight"
        onClick={props.isAbleToClickRight ? props.clickRightButton : undefined}
      />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    margin: '0 0 -10px 0',
  },
  arrowLeftButtonAbleToClickImg: {
    width: 36,
    cursor: 'pointer',
  },
  arrowLeftButtonDisableToClickImg: {
    width: 36,
    cursor: 'pointer',
  },
  arrowRightButtonAbleToClickImg: {
    margin: '0 0 0 10px',
    width: 36,
    cursor: 'pointer',
  },
  arrowRightButtonDisableToClickImg: {
    margin: '0 0 0 10px',
    width: 36,
    cursor: 'pointer',
  },
})
