import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import format from 'date-fns/format'

import { ModalShareAction } from 'pages/onboarding/components'
import { Loader } from 'pages/onboarding/components/loader'
import { mutationCreateOnboardingPostPost, mutationDeleteOnboardingPostPost } from 'pages/onboarding/graphql'
import {
  useOnboardingAction,
  useOnboardingActionComment,
  useOnboardingActionsActions,
  useOnboardingActionsFeedback,
  useOnboardingActionLikes,
} from 'pages/onboarding/hooks/action'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'

import {
  OnboardingAction,
  OnboardingPostLabel,
  OnboardingTeamMemberRole,
  OnboardingPostType,
} from '../../../../utils/generated'
import { onboardingActionCategoryLabel } from '../../utils/onboardingActionCategoryLabel'
import { onboardingDeadlinePeriod } from '../../utils/onboardingDeadlinePeriod'
import { onboardingFeedbackLabel } from '../../utils/onboardingFeedbackLabel'
import { onboardingMemberPeriodFromNow } from '../../utils/onboardingMemberPeriodFromNow'
import { OnbContext } from '../PagesRoot'
import { useTutorial } from '../tutorial/logics/hooks'
import { TutorialProps } from '../tutorial/Tutorial'

import { FeedBack, CompleteFeedBack, DeleteFeedBack } from './components'

import IconsLeft from './assets/Icons-ArrowLeft.svg'
import IconReturn from './assets/Icons-Return.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

// TODO
// - Delegate tasks to child components, modals

/**
 * toggle between 4 modals. defaults to idle
 */
export type ActionModalState = 'idle' | 'feedback' | 'complete-feedback' | 'delete' | 'post'

interface TutorialOverride {
  action: OnboardingAction
}

export const ActionListDetailBloc = {
  useAdapter: (override?: TutorialOverride) => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { actionId, userId: targetUserId } = useParams<{ actionId: string; userId: string }>()
    const userId = targetUserId || teamMember.userId
    const { action } = useOnboardingAction(actionId, userId)
    const { teamMember: selectedTeamMember } = useTeamMember(teamId, userId)
    const { teamMembers } = useTeamMembers(teamId)
    const { actionComments } = useOnboardingActionComment(actionId, userId)
    const { actionLikes } = useOnboardingActionLikes(`${actionId}-${userId}`)
    const { actionsFeedback } = useOnboardingActionsFeedback(teamId, userId)
    const feedback = actionsFeedback.find((item) => item.id === `${action?.id}-${userId}`)
    const { likeToggleHandler, createComment, createPostHandler, createFeedbackHandler, deleteFeedbackHandler } =
      useOnboardingActionsActions()

    return {
      teamId,
      action,
      teamMember,
      teamMembers,
      actionId,
      userId,
      selectedTeamMember,
      actionComments,
      actionLikes,
      actionsFeedback,
      feedback,
      likeToggleHandler,
      createComment,
      createPostHandler,
      createFeedbackHandler,
      deleteFeedbackHandler,
      ...override,
    }
  },
}

const Index: React.FC<{ tutorial?: TutorialProps; override?: TutorialOverride } & WithStyles<typeof useStyles>> = ({
  classes,
  tutorial,
  override,
}) => {
  // deps
  const {
    teamId,
    action,
    teamMember,
    userId,
    selectedTeamMember,
    actionsFeedback,
    feedback,
    createPostHandler,
    createFeedbackHandler,
    deleteFeedbackHandler,
  } = ActionListDetailBloc.useAdapter(override)

  const history = useOnbHistory()

  const ttonwhat = tutorial?.tooltip?.onWhat // tutorial

  // modal
  const [modal, setModal] = useState<ActionModalState>('idle')

  const onSubmitShare = async (textFieldValue: string, selectedLabel: OnboardingPostLabel) => {
    if (action) {
      await createPostHandler(teamId, action?.id, textFieldValue, selectedLabel)
    }
    setModal('idle')
    if (teamId) {
      history.push(OnbPages.Timeline, { teamId })
    }
  }
  const closeModal = () => {
    setModal('idle')
  }

  if (!action) return <Loader />
  return (
    <>
      <Box className={classes.toolbar}>
        <Box onClick={history.goBack} className="__back">
          <span style={{ marginRight: 8 }}>
            <img src={IconsLeft} alt="" />
          </span>
          戻る
        </Box>
        <Box style={{ fontSize: 16, fontWeight: 'bold' }}>アクション詳細</Box>
      </Box>

      <Box className={classes.template}>
        <Box paddingLeft={2} paddingRight={2} className={classes.box}>
          <Box
            style={{
              padding: 16,
              backgroundColor: 'white',
            }}
          >
            <Box style={{ position: 'relative', textAlign: 'right', marginBottom: 31 }}>
              <div className={classes.role}>{onboardingActionCategoryLabel(action.category)}</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  color: constants.COLOR_ONBOARDING_GRAY_DARK,
                  fontSize: 12,
                }}
              >
                {feedback ? (
                  <>
                    自己評価：
                    <span style={{ color: constants.COLOR_ONBOARDING_MAIN, fontSize: 14, fontWeight: 'bold' }}>
                      {onboardingFeedbackLabel(feedback.value)}
                    </span>
                  </>
                ) : (
                  <>
                    期限
                    {action.period !== null && selectedTeamMember?.startedAt
                      ? format(
                          onboardingDeadlinePeriod(
                            action.period,
                            selectedTeamMember.startedAt,
                            action?.deadline || undefined
                          ),
                          'MM月dd日'
                        )
                      : '____'}
                  </>
                )}
              </div>
            </Box>

            <Box style={{ marginBottom: 28 }}>
              <Box style={{ marginBottom: 19 }}>
                <div className={classes.descriptionTitleTop}>{action.mission}</div>
                <div className="__content">{action.why}</div>
              </Box>
              <Box style={{ marginBottom: 19 }}>
                <div className={classes.descriptionTitle}>アクション</div>
                <div className="__content">{action.what}</div>
              </Box>
              <Box>
                <div className={classes.descriptionTitle}>達成基準</div>
                <div className="__content">{action.how}</div>
              </Box>
            </Box>

            {(teamMember.userId === userId &&
              teamMember &&
              teamMember.role === OnboardingTeamMemberRole.Member &&
              action.period <= onboardingMemberPeriodFromNow(teamMember.startedAt)) ||
            tutorial ? (
              <>
                {actionsFeedback.findIndex((item) => item.id === `${action?.id}-${userId}`) === -1 ? (
                  <Box style={{ marginBottom: 32, display: 'flex', justifyContent: 'space-between' }}>
                    <ActionPostButton
                      className={classes.buttonLeft}
                      onClick={() => setModal('post')}
                      label="投稿する"
                      tutorial={tutorial}
                      ttonwhat="post"
                    />
                    <ActionPostButton
                      className={classes.buttonRight}
                      onClick={() => setModal('feedback')}
                      label="達成"
                      tutorial={tutorial}
                      ttonwhat="done"
                    />
                  </Box>
                ) : (
                  <Box className={classes.rollback}>
                    <div onClick={() => setModal('delete')} style={{ cursor: 'pointer' }}>
                      <span style={{ marginRight: 8 }}>
                        <img src={IconReturn} alt="icon" />
                      </span>
                      未達成に戻す
                    </div>
                  </Box>
                )}
              </>
            ) : (
              <></>
            )}
          </Box>

          {/* feedBackModal */}
          <FeedBack
            open={modal === 'feedback'}
            setModal={setModal}
            item={{
              id: action.id,
              importance: action.importance,
              mission: action.mission,
              what: action.what,
              why: action.why,
              how: action.how,
              category: action.category,
              period: new Date().getTime(),
              missionValues: undefined,
              anketValues: undefined,
              point: undefined,
              weekDate: undefined,
              valuesId: undefined,
            }}
            onCreateFeedback={async (value) =>
              await createFeedbackHandler(teamId, action.id, action.category, value, action.importance)
            }
            onCreatePost={async () =>
              await mutationCreateOnboardingPostPost({
                input: {
                  teamId,
                  comment: `アクション【${action.mission}】が達成されました！`,
                  actionId: action.id,
                  type: OnboardingPostType.ActionDone,
                  id: `${teamMember.id}-actionDone-${action.id}`,
                  fileNames: undefined,
                  mentions: undefined,
                },
              })
            }
            tutorialNext={tutorial && tutorial.next}
          />
          {/* completeModal */}
          <CompleteFeedBack open={modal === 'complete-feedback'} setModal={setModal} teamId={teamId} userId={userId} />
          {/* deleteModal */}
          {feedback?.id ? (
            <DeleteFeedBack
              open={modal === 'delete'}
              setModal={setModal}
              onDeleteFeedback={async () => {
                await deleteFeedbackHandler(feedback.id)
                await mutationDeleteOnboardingPostPost({ id: `${teamMember.id}-actionDone-${action.id}` })
              }}
              teamId={teamId}
              userId={userId}
            />
          ) : (
            <></>
          )}

          {/* ModalShareAction */}
          <ModalShareAction
            open={modal === 'post'}
            handleClose={() => {
              setModal('idle')
            }}
            onSubmit={onSubmitShare}
            closeModal={closeModal}
            tutorial={ttonwhat === 'modal' ? tutorial : undefined}
          />
        </Box>
      </Box>
    </>
  )
}

const ActionPostButton = ({
  className,
  onClick,
  label,
  tutorial,
  ttonwhat,
}: {
  className: string
  onClick: () => void
  label: string
  tutorial?: TutorialProps
  ttonwhat: string
}) => {
  const { ttRef } = useTutorial(tutorial)
  const tton = tutorial?.tooltip?.onWhat
  return (
    <div
      className={className}
      onClick={onClick}
      ref={tton === ttonwhat ? (ttRef as React.MutableRefObject<HTMLDivElement>) : null}
    >
      {label}
    </div>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    list: {
      padding: '16px 16px 0 16px',
      backgroundColor: 'white',
      borderBottom: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    },
    template: {
      display: 'flex',
      flexDirection: 'column',
    },
    box: {
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    },
    role: {
      position: 'absolute',
      bottom: -10,
      left: 0,
      boxSizing: 'border-box',
      padding: '5px 8px',
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      color: constants.COLOR_ONBOARDING_MAIN,
      borderRadius: 4,
      fontSize: 10,
      fontWeight: 'bold',
    },

    descriptionTitle: {
      letterSpacing: '0.008em',
      marginBottom: 13,
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      '& ._content': {
        letterSpacing: '0.008em',
      },
    },

    descriptionTitleTop: {
      letterSpacing: '0.008em',
      marginBottom: 13,
      fontSize: 16,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      textAlign: 'center',
    },

    buttonLeft: {
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      color: constants.COLOR_ONBOARDING_MAIN,
      boxSizing: 'border-box',
      padding: '13px 40px',
      fontWeight: 'bold',
      borderRadius: 4,
      fontSize: 16,
      backgroundColor: constants.COLOR_WHITE,
      flexBasis: '100%',
      textAlign: 'center',
      marginRight: 16,
      cursor: 'pointer',
    },

    buttonRight: {
      boxSizing: 'border-box',
      padding: '13px 40px',
      fontWeight: 'bold',
      borderRadius: 4,
      fontSize: 16,
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      color: constants.COLOR_WHITE,
      flexBasis: '100%',
      textAlign: 'center',
      cursor: 'pointer',
    },

    rollback: {
      marginBottom: 32,
      display: 'flex',
      justifyContent: 'end',
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 16,
      fontWeight: 'bold',
    },

    like: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      fontSize: 12,
      color: constants.COLOR_ONBOARDING_GRAY,
    },

    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      margin: '27px 0',
      padding: '0 16px',
      '& .__back': {
        position: 'absolute',
        top: 0,
        left: 16,
        cursor: 'pointer',
      },
    },
  })

export default withStyles(useStyles)(Index)
