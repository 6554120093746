import * as React from 'react'

import { TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dayjs } from 'dayjs'
import 'dayjs/locale/ja'

import * as constants from 'assets/constants'
import { CalendarSvg } from 'assets/svg'

type Props = {
  onChange: (date: Date | null) => void
}

export const CalendarForm: React.FC<Props> = ({ onChange }) => {
  const classes = useStyles()
  const [date, setDate] = React.useState<Dayjs | null>(null)

  React.useEffect(() => {
    if (onChange && date) {
      onChange(new Date(date.format()))
    }
  }, [onChange, date])

  const CustomOpenPickerIcon = () => <img src={CalendarSvg} alt="calendar" className={classes.icon} />

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
      <DatePicker
        value={date}
        onChange={(newDate) => setDate(newDate)}
        format="YYYY/MM/DD"
        desktopModeMediaQuery="@media (min-width:0px)"
        slots={{
          textField: TextField,
          openPickerIcon: CustomOpenPickerIcon,
        }}
        slotProps={{
          textField: {
            variant: 'outlined',
            margin: 'normal',
            className: classes.main,
            placeholder: '', // プレースホルダーを空に設定
            InputProps: {
              classes: {
                root: classes.inputRoot,
                adornedEnd: classes.adornedEnd,
              },
            },
          },
          popper: {
            className: classes.popover,
            placement: 'bottom-end',
          },
          desktopPaper: {
            sx: {
              borderRadius: '8px',
            },
          },
        }}
      />
    </LocalizationProvider>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& .MuiFormLabel-root': {
      color: constants.COLOR_MAIN,
    },
    '& .MuiInputBase-root': {
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 8,
      height: 42,
    },
    '& .MuiInputBase-input': {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
      padding: 0,
      marginLeft: 16,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `0 !important`,
    },
    '& .MuiInputAdornment-root': {
      marginRight: 12,
    },
  },
  inputRoot: {
    paddingRight: 0,
  },
  adornedEnd: {
    marginRight: 0,
  },
  icon: {
    padding: 0,
  },
  popover: {
    '& .MuiPickersDay-today': {
      color: constants.COLOR_ONBOARDING_MAIN,
      border: 'none',
      boxShadow: 'none',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
      color: constants.TEXT_WHITE,
    },
    '& .MuiPickersYear-yearButton.Mui-selected': {
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: '#fff',
    },
  },
}))
