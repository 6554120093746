import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { TemplateDownloader } from './components/TemplateDownloader'

type Props = {
  template: 'action' | 'team-member'
}

export const TemplateDownloadBox = ({ template }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.buttonContainer}>
      <TemplateDownloader textCode="sift-jis" template={template} />
      <TemplateDownloader textCode="utf-8" template={template} />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: 12,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        flex: 1,
      },
    },
  }),
  { name: 'TemplateDownloadBox' }
)
