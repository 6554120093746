import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { CircularProgress } from 'components/CircularProgress'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { useHistoryStore } from 'pages/ghost/contexts/history'
import { GhostResearch } from 'utils/generated'

import { HooksContext } from '../../contexts'
import { useTeamMembers } from '../../hooks'

import { ListRow } from './ListRow'
import { SpNextActionBox } from './SpNextActionBox'

import { constants } from 'assets'

export const GhostPastListTable: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { ghosts } = React.useContext(GhostContext)
  const { currentTeamMember } = React.useContext(HooksContext)
  const { teamMembers, loading: isMemberLoading } = useTeamMembers(teamId)
  const { completedGhostList, loading: isHistoryLoading } = useHistoryStore(
    teamId,
    'dummy-ghost-teamId',
    currentTeamMember?.id
  )
  const isMdUp = useCustomMediaQuery('up', 'md')

  const memberItem = React.useCallback(
    (userId: string | undefined) => teamMembers.find((member) => member.id === userId),
    [teamMembers]
  )

  const actionFilter = React.useCallback(
    (nextActionList: GhostResearch[] | undefined | null) =>
      nextActionList
        ?.filter((action) => action.report)
        .map((action) => ({
          ...action,
          fullName: memberItem(action.userId)?.fullName ?? '',
          imageUrl: memberItem(action.userId)?.user?.imageUrl ?? undefined,
        }))
        .sort((a, b) => a.fullName.localeCompare(b.fullName, 'ja')), // 名前順にソート
    [memberItem]
  )

  if (isMemberLoading || isHistoryLoading) return <CircularProgress />

  return (
    <div className={classes.container}>
      {isMdUp ? (
        <table className={classes.table}>
          <thead className={classes.thead}>
            <tr>
              <th scope="col" className={classes.sticky}>
                回（日付）
              </th>
              <th scope="col" className={classes.sticky}>
                探索したオバケ
              </th>
              <th scope="col" className={classes.sticky}>
                明日から出来そうなアクション
              </th>
            </tr>
          </thead>
          <tbody>
            {completedGhostList.map((ghostItem, i) => (
              <ListRow
                key={ghostItem.history?.id}
                ghostItem={ghostItem}
                times={completedGhostList.length - i}
                ghosts={ghosts}
                actionFilter={actionFilter}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classes.spList}>
          {completedGhostList.map((ghostItem, i) => (
            <SpNextActionBox
              key={ghostItem.history?.id}
              ghostItem={ghostItem}
              times={completedGhostList.length - i}
              ghosts={ghosts}
              actionFilter={actionFilter}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      marginTop: 25,
      [theme.breakpoints.down('md')]: {
        marginTop: 16,
      },
    },
    table: {
      width: '100%',
      height: '100%',
      fontSize: 12,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderCollapse: 'collapse',
      borderSpacing: 0,
      textAlign: 'left',
    },
    thead: {
      height: 48,
      '& th': {
        padding: '0 16px',
        fontWeight: 'bold',
      },
      '& th:last-of-type': {
        borderLeft: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
    },
    sticky: {
      position: 'sticky',
      top: 149,
      left: 0,
      zIndex: 10,
      backgroundColor: constants.COLOR_TEAMBUILDING_WHITE,
      // スクロールするとボーダーが消えるので、疑似要素でボーダーを再現
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
    },
    spList: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
  }),
  { name: 'GhostPastListTable' }
)
