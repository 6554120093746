import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

type Props = {
  children: React.ReactNode
}

export const MediaGrid: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.container}>{children}</div>
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '8px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(40%, 1fr))',
    gridGap: 8,
    '& > *': {
      width: '100%',
      maxWidth: '100%',
      height: 124,
      '&:first-child:last-child': {
        height: 258,
      },
      '&:last-child:nth-child(odd)': {
        gridColumn: '1 / 3',
      },
    },
  },
}))
