import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import arrowDownSvg from 'pages/teams/pages/_tools/karte/assets/arrowDown.svg'
import { TeamKarte } from 'utils/generated'

import { KarteValueType } from '../../../../utils'
import { TeamKarteWithTime, serialNumDescList, getTypeClass, VALUE_HEIGHT } from '../DataList'

import { TEXT_GRAY_DARK, COLOR_TEAMBUILDING_NEUTRAL_200, COLOR_MAIN_NEW } from 'assets/constants'

type Props = {
  teamKarte: TeamKarteWithTime | null | undefined
  resultCount: number
  type: KarteValueType | null
  setSelectedTeamKarte: (value: TeamKarte) => void
  size: 'xs' | 'sm' | 'md'
  isExpandRow?: boolean
  displayTimeState?: {
    displayTime: number
    setDisplayTime: React.Dispatch<React.SetStateAction<number>>
  }
  handleCloseList?: () => void
}

export const DataRowDetail: React.FC<Props> = ({
  teamKarte,
  resultCount,
  type,
  setSelectedTeamKarte,
  size,
  isExpandRow,
  displayTimeState,
  handleCloseList,
}) => {
  const classes = useStyles()

  const { displayTime, setDisplayTime } = displayTimeState ?? {}
  const timesList = serialNumDescList(resultCount)

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (setDisplayTime && handleCloseList) {
      setDisplayTime(Number(e.target.value))
      handleCloseList()
    }
  }

  return (
    <div
      className={classNames(
        classes.boxDates,
        classes.value,
        getTypeClass(type),
        size !== 'xs' && classes.rightBorderAfter
      )}
    >
      <div>
        {!isExpandRow ? (
          <select className={classes.select} value={displayTime} onChange={handleChange}>
            {/* 全体フィルターで選択した回数がメンバーの実施回数を超える場合、その回数もリストに表示する */}
            {/* リストから別の実施回数を選択した場合、全体フィルターで選択した回数はリストに表示されなくなる */}
            {displayTime && timesList.length < displayTime ? (
              <option key={displayTime} value={displayTime}>
                {`第${displayTime}回`}
              </option>
            ) : null}
            {timesList.map((time) => (
              <option key={time} value={time}>
                {`第${time}回`}
              </option>
            ))}
          </select>
        ) : (
          <div className={classes.diagnosisResult}>第{teamKarte?.time}回</div>
        )}
        {teamKarte && (
          <div
            className={classes.toResultLink}
            onClick={() => {
              setSelectedTeamKarte(teamKarte)
            }}
          >
            診断結果を見る
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    boxDates: {
      height: VALUE_HEIGHT,
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',
    },
    value: {
      fontSize: 10,
      padding: 7,
      marginBottom: 2,
      flex: 1,
    },
    // margin の上に border を表示するための擬似要素
    rightBorderAfter: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: -1,
        width: 1,
        height: 'calc(100% + 2px)',
        borderRight: '1px solid #eee',
        [theme.breakpoints.down('md')]: {
          right: -0.75,
        },
      },
    },
    select: {
      display: 'block',
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      width: 63,
      height: 24,
      padding: '4px 8px',
      backgroundColor: '#fff', // ブラウザごとの背景色を上書き
      color: TEXT_GRAY_DARK,
      fontSize: 10,
      border: `1px solid ${COLOR_TEAMBUILDING_NEUTRAL_200}`,
      borderRadius: 4,
      cursor: 'pointer',
      backgroundImage: `url(${arrowDownSvg})`,
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 10,
    },
    diagnosisResult: {
      fontSize: 10,
      color: TEXT_GRAY_DARK,
      fontWeight: 'normal',
    },
    toResultLink: {
      marginTop: 6,
      width: '100%',
      fontSize: 8,
      color: COLOR_MAIN_NEW,
      fontWeight: 'bold',
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'DataRowDetail' }
)
