import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Header } from './Header'

type Props = {
  children: React.ReactNode
}

export const Container: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.main}>{props.children}</div>
    </div>
  )
}

type StyleProps = {}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({}) => ({
      minHeight: '100vh',
      padding: '20px 8px 8px',
      backgroundColor: '#F9F9F9',
      color: '#333',

      [theme.breakpoints.up('md')]: {
        padding: '28px 16px 16px',
      },
    }),
    main: ({}) => ({
      maxWidth: 800,
      width: '100%',
      margin: '0 auto',
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'center',
      // flexWrap: 'wrap',
      // padding: header ? '72px 16px 120px' : noPadding ? 0 : visibleQuestionHeadBar ? '8px 0 120px' : '48px 0 120px',
      // paddingLeft: addPaddingSide ? 16 : undefined,
      // paddingRight: addPaddingSide ? 16 : undefined,
      // [theme.breakpoints.up('md')]: {
      //   padding: header ? '72px 70px 70px' : '80px 70px 70px',
      // },
    }),
  }),
  { name: 'Container' }
)
