import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from 'assets/constants'

type Props = {
  imgURL: string
  title: string
  name: string
  link: string
  isOpenNewTab: boolean
  isTopPage?: boolean
}

export const Casebox: React.FC<Props> = (props) => {
  const classes = useStyles({ isTopPage: props.isTopPage })
  const [imgWide, setImageWide] = React.useState<boolean>(false)

  return (
    <div className={classes.root} onMouseOver={() => setImageWide(true)} onMouseOut={() => setImageWide(false)}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <div className={classes.wrap}>
          <div className={classes.imgWrap}>
            <img className={classNames([classes.img, imgWide ? 'imgWide' : ''])} src={props.imgURL} alt={props.title} />
          </div>
          <div className={classes.title}>{props.title}</div>
          <div className={classes.name}>{props.name}</div>
        </div>
      </a>
    </div>
  )
}

type StyleProps = {
  isTopPage?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    root: ({ isTopPage }) => ({
      width: isTopPage ? 316 : 288,
      padding: '0 8px',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        width: 396,
        padding: '0 8px',
      },
    }),
    wrap: ({ isTopPage }) => ({
      width: isTopPage ? 300 : 272,
      height: '100%',
      margin: '0,16px',
      boxShadow: '0 3px 6px 0 #0000001E',
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      [theme.breakpoints.up('md')]: {
        width: 380,
        margin: '0,16px',
        boxShadow: '0 3px 6px 0 #0000001E',
        borderRadius: 8,
        backgroundColor: constants.COLOR_WHITE,
      },
    }),
    imgWrap: ({ isTopPage }) => ({
      display: 'flex',
      justifyContent: 'center',
      width: isTopPage ? 300 : 272,
      height: isTopPage ? 185 : 148,
      borderRadius: '8px 8px 0 0',
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        width: 380,
        height: isTopPage ? 232 : 207,
      },
    }),
    img: ({ isTopPage }) => ({
      display: 'flex',
      justifyContent: 'center',
      width: isTopPage ? undefined : 272,
      height: isTopPage ? '100%' : undefined,
      borderRadius: '8px 8px 0 0',
      '&.imgWide': {
        animation: `$scale forwards 300ms ${theme.transitions.easing.easeInOut}`,
        '@global': {
          '@keyframes scale': {
            '0%': {
              transform: 'scale(1)',
            },
            '100%': {
              transform: 'scale(1.2)',
            },
          },
        },
      },
      [theme.breakpoints.up('md')]: {
        width: isTopPage ? undefined : 380,
        borderRadius: '8px 8px 0 0',
      },
    }),
    title: ({ isTopPage }) => ({
      ...constTop.smallTopicTextStyles,
      margin: isTopPage ? '16px 16px 0' : '24px 24px 0',
      lineHeight: 1.75,
      fontWeight: 400,
    }),
    name: ({ isTopPage }) => ({
      padding: isTopPage ? 16 : 24,
      fontSize: 12,
      color: constTop.COLOR_TEXT_GRAY,
    }),
  }),
  { name: 'Casebox' }
)
