import * as React from 'react'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import { UsefulCard } from '../atoms'

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  handleVisible: () => void
  popupOpen: boolean
  ownStyles?: React.CSSProperties
  ownBoxStyles?: React.CSSProperties
  children: React.ReactNode
}

export const DropdownBox: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <>
      <UsefulCard
        backgroundColor="white"
        ownStyles={{ border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`, ...props.ownStyles }}
      >
        <div
          className={classes.dropdownBoxText}
          onClick={(event) => {
            props.onClick(event)
            props.handleVisible()
          }}
          style={props.ownBoxStyles}
        >
          {props.children}
          {props.popupOpen ? (
            <ExpandLessIcon classes={{ root: classes.svgIconRoot }} />
          ) : (
            <ExpandMoreIcon classes={{ root: classes.svgIconRoot }} />
          )}
        </div>
      </UsefulCard>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownBoxText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px 0 16px',
    height: 42,
    fontSize: 14,
    color: constGhost.COLOR_GRAYTEXT,
  },
  svgIconRoot: {
    color: constGhost.COLOR_MAIN_NEW,
  },
}))
