import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { dateStringToLPassedTime } from 'utils/date'
import { OnboardingPost, OnboardingTeamMember } from 'utils/generated'

import * as constants from 'assets/constants'


type Props = {
  post: OnboardingPost
  postUser: OnboardingTeamMember
}
type StyleProps = {}

export const UserNameBox: React.FC<Props & StyleProps> = ({ post, postUser }) => {
  const classes = useStyles()
  const { nickname, role } = postUser

  return (
    <>
      <div className={classes.userContent}>
        <div className={classes.userName}>{nickname}</div>
        {post.createdAt ? (
          <div className={classes.userTime}>{dateStringToLPassedTime(post.createdAt, 'time')}</div>
        ) : (
          <></>
        )}
      </div>
      {role ? (
        <div style={{ display: 'inline-block' }}>
          <div className={classes.userRole}>{teamMemberRoleLabel(role)}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

const useStyles = makeStyles(
  {
    userContent: {
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      width: '100%',
    },
    userName: {
      marginRight: 8,
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1,
      color: constants.TEXT_GRAY_DARK,
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
    },
    userTime: {
      marginTop: 7,
      fontSize: 10,
      lineHeight: 1,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
    },
    userRole: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      marginTop: 11,
      paddingLeft: 8,
      paddingRight: 8,
      color: constants.COLOR_ONBOARDING_MAIN,
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      height: '20px',
      fontWeight: 'bold',
    },
  },
  { name: 'UserNameBox' }
)
