import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  ranking: number
}

export const RankingIcon: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {(() => {
        switch (props.ranking) {
          case 1:
            return <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/ranking1.svg'} alt="" />
          case 2:
            return <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/ranking2.svg'} alt="" />
          case 3:
            return <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/ranking3.svg'} alt="" />
          default:
            return props.ranking
        }
      })()}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 40,
    width: 40,
    minHeight: 40,
    minWidth: 40,
    borderRadius: 40,
    marginRight: 16,
    backgroundColor: '#E9E9E9',
    fontWeight: 'bold',
    color: constants.TEXT_GRAY_DARK,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
