import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useObakesagashi } from 'pages/obakesagashi/hooks/obakesagashi'

import { constants } from 'assets'

export const GhostScroll: React.FC = () => {
  const classes = useStyles()
  const { ghosts } = useObakesagashi()

  return (
    <div className={classes.scrollWrapper}>
      <div className={classes.cardContainer}>
        {ghosts.map((ghost) => (
          <div className={classes.cardItem} key={`obake-card-list-${ghost.name}`}>
            <div className={classes.fileNumber}>
              <span className="pre">File No</span>
              <span className="num">{`0${ghost.fileNo}`.slice(-2)}</span>
            </div>
            <div className={classes.ghostImg}>
              <img src={process.env.PUBLIC_URL + `/assets/ghost/ghost${ghost.id}.svg`} alt={ghost.name} />
            </div>
            <div className={classes.ghostName}>{ghost.name}</div>
            <p className={classes.ghostDetail}>{ghost.con}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    scrollWrapper: {
      marginLeft: 200,
      [theme.breakpoints.down('md')]: {
        marginLeft: 16,
      },
    },
    cardContainer: {
      display: 'flex',
      overflowX: 'auto',
      width: 'calc(100vw - 220px)',
      padding: '50px 50px 40px 4px',
      gap: 24,
      scrollbarColor: `#000 ${constants.COLOR_GRAY4}`,
      scrollbarWidth: 'thin',
      [theme.breakpoints.down('md')]: {
        width: 'calc(100vw - 16px)',
        paddingRight: 16,
        gap: 16,
      },
      '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: constants.COLOR_GRAY4,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#000',
        borderRadius: 10,
      },
    },
    cardItem: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'stretch',
      padding: '25px 40px 60px',
      minWidth: 400,
      height: 315,
      background: constants.COLOR_TEAMBUILDING_WHITE,
      borderRadius: 16,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
      [theme.breakpoints.down('md')]: {
        padding: 16,
        minWidth: 330,
        height: 267,
      },
    },
    fileNumber: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexShrink: 0,
      width: 73,
      height: 98,
      color: '#000',
      fontFamily: '"MOBO SemiBold"',
      background: `no-repeat top / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/light-blue-fire.svg)`,
      position: 'absolute',
      top: -15,
      left: 22,
      [theme.breakpoints.down('md')]: {
        top: -16,
        left: 16,
      },
      '& .pre': {
        fontSize: 16,
        lineHeight: 1,
      },
      '& .num': {
        fontSize: 25,
        lineHeight: 1.2,
        paddingBottom: 4,
      },
    },
    ghostImg: {
      width: 120,
      height: 120,
      [theme.breakpoints.down('md')]: {
        width: 110,
        height: 110,
      },
    },
    ghostName: {
      fontFamily: '"MOBO SemiBold"',
      fontSize: 16,
      padding: '4px 0 16px',
    },
    ghostDetail: {
      fontSize: 14,
      margin: 0,
      lineHeight: 1.57,
    },
  }),
  { name: 'GhostScroll' }
)
