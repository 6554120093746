import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { HorizontalLine } from './HorizontalLine'

import checkSvg from '../assets/svg/check.svg'
import exclamationSvg from '../assets/svg/exclamation.svg'
import { constants } from 'assets'

type Props = {
  topText: string
  iconType: 'success' | 'warning'
  message?: string
  dateBox?: {
    heading?: string
    date: string
    time: string
    dayOfWeek: string
  }
  button: Button & {
    boxStyles?: React.CSSProperties
    button2?: Button
  }
}

type Button = {
  label: string
  onClick: () => void
  type?: 'button' | 'submit'
  color?: 'white' | 'main'
  disabled?: boolean
  buttonStyles?: React.CSSProperties
}

export const ResultWithIcon: React.FC<Props> = ({ topText, iconType, message, dateBox, button }) => {
  const classes = useStyles()
  const isSmDown = useCustomMediaQuery('down', 'md')

  const selectIcon = (iconType: string) => {
    switch (iconType) {
      case 'success':
        return checkSvg
      case 'warning':
        return exclamationSvg
      default:
        return ''
    }
  }

  return (
    <div className={classes.root}>
      <p className={`${classes.topText} ${iconType}`}>{topText}</p>
      <img className={classes.icon} src={selectIcon(iconType)} alt="icon" />

      {message && <p className={classes.message}>{message}</p>}

      {dateBox && (
        <div className={classes.dateBox}>
          <span className={classes.heading}>{dateBox.heading ?? '予約招待日時'}</span>
          <span>
            <span className={classes.date}>{dateBox.date}</span>
            <span className={classes.dayOfWeek}>{dateBox.dayOfWeek}</span>
            <span className={classes.time}>{dateBox.time}</span>
          </span>
        </div>
      )}

      {button && (
        <>
          {isSmDown && <HorizontalLine style={{ marginTop: 24 }} />}

          <div className={`${classes.box} ${button.button2 ? classes.buttonContainer : ''}`} style={button.boxStyles}>
            <NoStyleButton
              className={`${classes.button} ${button.color ?? 'white'} ${button.disabled ? 'disabled' : ''}`}
              style={button.buttonStyles}
              type={button.type ?? 'button'}
              disabled={button.disabled}
              onClick={button.onClick}
            >
              {button.label}
            </NoStyleButton>

            {button.button2 && (
              <NoStyleButton
                className={`${classes.button} ${button.button2.color ?? 'white'} ${
                  button.button2.disabled ? 'disabled' : ''
                }`}
                style={button.button2.buttonStyles}
                type={button.button2.type ?? 'button'}
                disabled={button.button2.disabled}
                onClick={button.button2.onClick}
              >
                {button.button2.label}
              </NoStyleButton>
            )}
          </div>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    topText: {
      margin: 0,
      fontSize: 14,
      fontWeight: 'bold',
      '&.success': {
        color: constants.COLOR_MAIN_NEW,
      },
      '&.warning': {
        color: constants.COLOR_RED4,
      },
    },
    icon: {
      marginTop: 16,
      width: 80,
      height: 80,
    },
    message: {
      margin: '24px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      lineHeight: 1.6,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    dateBox: {
      marginTop: 24,
      padding: 24,
      background: constants.COLOR_WHITE2,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '24px 16px',
      },
    },
    heading: {
      fontWeight: 'bold',
    },
    date: {
      paddingLeft: 22,
    },
    dayOfWeek: {
      paddingLeft: 4,
    },
    time: {
      paddingLeft: 12,
    },
    box: {
      marginTop: 24,
    },
    buttonContainer: {
      display: 'flex',
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    button: {
      width: 190,
      height: 54,
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: 27,
      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.7,
        },
      },
      '&.white': {
        color: constants.COLOR_MAIN_NEW,
        border: `2px solid ${constants.COLOR_MAIN_NEW}`,
      },
      '&.main': {
        color: constants.COLOR_WHITE,
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '&.disabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
  }),
  { name: 'ResultWithIcon' }
)
