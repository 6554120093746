import { createTheme } from '@mui/material/styles'

import { COLOR_MAIN, COLOR_WHITE2 } from './constants'

export const theme = createTheme({
  typography: {
    fontSize: 14,
  },
  // レイアウト崩れを考慮し、v4 の breakpoints に合わせる。
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: COLOR_WHITE2,
          color: '#1F1818',
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: COLOR_MAIN,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1250, // default 1400
    tooltip: 1275, // default 1500
    // header is 1290
  },
})
