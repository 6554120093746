import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import checked from '../../assets/svg/checked.svg'

import { Imgcard } from './Imgcard'

type Props = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  handleVisible: () => void
  id?: string
  hstIdx?: number
  rptIdx?: number
  ownStyles?: React.CSSProperties
  children: React.ReactNode
}

export const DropdownItem: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div
      className={classes.dropdownItemRoot}
      onClick={(event) => {
        props.onClick(event)
        props.handleVisible()
      }}
      id={props.id}
      data-hstidx={props.hstIdx}
      data-rptidx={props.rptIdx}
      style={props.ownStyles}
    >
      {props.children}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownItemRoot: {
    height: 50,
    fontSize: 14,
    color: constGhost.COLOR_BLACK3,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: constGhost.COLOR_LIGHTGRAY,
    },
  },
}))

//  ==== for members ====

type MemberDropdownItemProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  handleVisible: () => void
  selected: boolean
  id: string
  ownStyles?: React.CSSProperties
  children: React.ReactNode
}

export const MemberDropdownItem: React.FC<MemberDropdownItemProps> = (props) => {
  return (
    <DropdownItem
      onClick={(event) => {
        props.onClick(event)
      }}
      handleVisible={() => props.handleVisible()}
      id={props.id}
      ownStyles={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: props.selected ? constGhost.COLOR_MAIN_NEW : undefined,
      }}
    >
      <Imgcard
        src={checked}
        alt={'checked'}
        ownWrapStyles={props.selected ? { margin: '2px 8px 0' } : { margin: '2px 8px 0', visibility: 'hidden' }}
      />
      {props.children}
    </DropdownItem>
  )
}
