import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { ButtonRenewal } from 'components/ButtonRenewal'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from 'assets/constants'

type Props = {
  imgURL: string
  title: string
  detail: string
  link: string
  isTopPage?: boolean
}

export const FileBox: React.FC<Props> = ({ imgURL, title, detail, link, isTopPage }) => {
  const classes = useStyles({ isTopPage })
  const history = useHistory()
  const [imgWide, setImageWide] = React.useState<boolean>(false)
  const isSmUp = useCustomMediaQuery('up', 'sm')
  const isNewoneLink = link.indexOf('new-one') !== -1
  const onClick = () => {
    isNewoneLink ? window.open(link, '_blank') : history.push(constants.TO_DOCUMENTS_DOWNLOAD(link))
  }

  const button = (
    <ButtonRenewal
      fontSize={isTopPage && !isSmUp ? 10 : undefined}
      style={{
        width: isTopPage ? '100%' : undefined,
        height: isTopPage && !isSmUp ? 39 : undefined,
        maxWidth: isTopPage ? 'calc(100% - 60px)' : undefined,
        display: 'block',
        margin: isTopPage ? '0 30px' : '0 24px 0 auto',
        flex: '0 0 50px',
      }}
      onClick={onClick}
    >
      {isNewoneLink ? '詳しく見る' : 'ダウンロード'}
    </ButtonRenewal>
  )

  return (
    <div
      className={`${classes.caseCardRoot}${isTopPage ? ' ' + classes.fullHeight : ''}`}
      onMouseOver={() => setImageWide(true)}
      onMouseOut={() => setImageWide(false)}
    >
      <div className={classes.mainWrapper}>
        <div className={classes.imgWrap}>
          <img
            className={classNames([classes.img, imgWide && isSmUp ? 'imgWide' : ''])}
            src={`${process.env.PUBLIC_URL}/img/documentsPage/${imgURL}.png`}
            alt={imgURL}
          />
        </div>
        <div className={classes.textBox}>
          <div className={classes.title}>{title}</div>
          <p className={classes.detail}>{detail}</p>
        </div>
      </div>
      {isTopPage ? <div>{button}</div> : button}
    </div>
  )
}

type StyleProps = {
  isTopPage?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    caseCardRoot: ({ isTopPage }) => ({
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      padding: isTopPage ? '0 0 16px' : '0 0 24px',
      boxShadow: '0 3px 6px 0 #0000001E',
      borderRadius: 8,
      backgroundColor: constants.COLOR_WHITE,
      aspectRatio: '1 / 1',
    }),
    fullHeight: {
      width: '100%',
      height: 'auto',
    },
    mainWrapper: {
      flex: '1 1 auto',
    },
    imgWrap: {
      overflow: 'hidden',
      aspectRatio: '380 / 232',
      borderRadius: '8px 8px 0 0',
    },
    img: {
      width: '100%',
      borderRadius: '8px 8px 0 0',
      aspectRatio: '380 / 232',
      objectFit: 'cover',
      '&.imgWide': {
        animation: `$scale forwards 300ms ${theme.transitions.easing.easeInOut}`,
        '@global': {
          '@keyframes scale': {
            '0%': {
              transform: 'scale(1)',
            },
            '100%': {
              transform: 'scale(1.2)',
            },
          },
        },
      },
    },
    textBox: {
      flexGrow: 1,
      margin: '0 0 auto',
    },
    title: ({ isTopPage }) => ({
      fontSize: isTopPage ? 16 : 18,
      fontWeight: 'bold',
      margin: isTopPage ? '16px 16px 0' : '24px 24px 0',
      lineHeight: 1.75,
      height: isTopPage ? 50 : undefined,
    }),
    detail: ({ isTopPage }) => ({
      padding: isTopPage ? 16 : '16px 24px 32px',
      fontSize: 14,
      lineHeight: isTopPage ? 2 : 1.6,
      color: constants.TEXT_GRAY_DARK,
    }),
    [theme.breakpoints.up(constTop.breakPointsSP)]: {
      caseCardRoot: {
        width: '100%',
        height: 'auto',
        boxShadow: '0 3px 6px 0 #0000001E',
        borderRadius: 8,
        backgroundColor: constants.COLOR_WHITE,
      },
      imgWrap: {
        width: '100%',
        borderRadius: '8px 8px 0 0',
      },
    },
  }),
  { name: 'FileBox' }
)
