import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Header, headerHeight } from './Header'

import { constants, Pages } from 'assets'

type Props = {
  children: React.ReactNode
} & StyleProps

type StyleProps = {
  minHeightSub?: number
}

export const Layout: React.FC<Props> = ({ children, minHeightSub }) => {
  const location = useLocation()
  const isTop = !!location.pathname.match(`${Pages.Obakesagashi}/?$`)
  const classes = useStyles({ isTop, minHeightSub })

  return (
    <div className={classes.root}>
      {!isTop && <Header />}

      <div className={classes.container}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles<Theme, StyleProps & { isTop: boolean }>(
  (theme) => ({
    root: {
      background: '#F0F0F0',
      color: constants.TEXT_GRAY_DARK,
      fontFamily: constants.HATARAKU_FONT_FAMILY,
      '& button': {
        fontFamily: constants.HATARAKU_FONT_FAMILY,
      },
    },
    container: ({ isTop, minHeightSub }) => ({
      minHeight: isTop ? '100vh' : `calc(100vh - ${headerHeight.pc}px - ${minHeightSub ?? 0}px)`,
      [theme.breakpoints.down('md')]: {
        minHeight: isTop ? '100vh' : `calc(100vh - ${headerHeight.sp}px - ${minHeightSub ?? 0}px)`,
      },
    }),
  }),
  { name: 'Layout' }
)
