import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { IuseCategory } from './useCategory'

import { IOptions } from 'assets/options'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'solid 1px #8D8D8D',
    borderRadius: '4px',
    backgroundColor: '#F7F7F7',
    color: '#8D8D8D',
    height: '47px',
    width: '347px',
    fontSize: '16px',
    paddingLeft: '8px',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    backgroundImage: 'url(/assets/svg/hint/down_icn.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'right 10px center',

    '&::-ms-expand': {
      display: 'none',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  name?: string
  type: 'search' | 'post'
  value: string | null
  presenter: IuseCategory
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

// -----------------------------
// Component
// -----------------------------
export const Categories = (props: IProps) => {
  const classes = useStyles()

  React.useEffect(() => {
    if (props.value) {
      props.presenter.setUpdateCategory(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <select
      name={props.name}
      value={props.presenter.getCurrentCategory()}
      onChange={(e) => {
        if (props.onChange) {
          props.onChange(e)
        }
      }}
      className={classes.root}
    >
      {!props.value && <option>カテゴリーを選択</option>}

      {props.presenter.getCategories().map((getCategorie: IOptions) => {
        return (
          <option key={getCategorie.label} label={getCategorie.label} value={getCategorie.value}>
            {getCategorie.label}
          </option>
        )
      })}
    </select>
  )
}
