import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

export const SearchedNothing: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <p className={classes.title}>検索結果はありません</p>
      <p className={classes.supplement}>別なキーワードをお試しください。</p>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: 'center',
      backgroundColor: constants.COLOR_WHITE,
      padding: '40px 16px',
      color: constants.TEXT_GRAY_DARK,
    },
    title: {
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
    },
    supplement: {
      margin: '19px 0 0',
      fontSize: 12,
    },
  }),
  { name: 'SearchedNothing' }
)
