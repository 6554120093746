import * as React from 'react'

import { Theme, Modal } from '@mui/material';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../assets/constants'

interface IOwnProps {
  open: boolean
  failure?: boolean
  success?: boolean
  body?: any
  footer?: any

  onClose?: () => void
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props

  if (!props.open) {
    return null
  }

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 16,
        }}
        onClose={props.onClose}
      >
        <div className={classes.main}>
          <div className="__msg__container">
            <div className="__msg__container__content">
              {props.onClose && (
                <div className="__msg__container__content__close">
                  <img
                    className="__msg__container__content__close__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/close_message.svg'}
                    alt={'close'}
                    onClick={props.onClose}
                  />
                </div>
              )}

              <div className="__msg__container__content__header">
                {props.success && (
                  <img
                    className="__msg__container__content__header__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/success.svg'}
                    alt={'success'}
                  />
                )}
                {props.failure && (
                  <img
                    className="__msg__container__content__header__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/failure.svg'}
                    alt={'failure'}
                  />
                )}
              </div>

              {props.body ? <div className="__msg__container__content__body">{props.body}</div> : null}
              {props.footer ? <div className="__msg__container__content__footer">{props.footer}</div> : null}
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      width: '100%',
      '&:focus': {
        border: 'none',
        outline: 'none',
      },
      '& .__msg__container': {
        margin: '0 auto',

        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_SMALL,
        },

        '&__content': {
          backgroundColor: constants.COLOR_WHITE,
          borderRadius: '10px',
          padding: '16px 16px 32px',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',

          '&__close': {
            display: 'flex',

            '& img': {
              marginLeft: 'auto',
              marginRight: 0,
              width: 16,
              height: 16,
            },
          },
          '&__header': {
            display: 'flex',
            justifyContent: 'center',
            padding: '16px 0 0',
            '&__icon': {
              width: '36px',
              height: '36px',
            },
          },
          '&__body': {
            padding: '16px 0',
            lineHeight: '24px',
          },

          '&__footer': {
            padding: '16px 0',
          },
        },
      },
    },
  })

export default withStyles(useStyles)(Index)
