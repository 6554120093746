import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  src: string
  alt: string
  width?: string | number
  height?: string | number
  onClick?: () => void
  ownStyles?: React.CSSProperties
  ownWrapStyles?: React.CSSProperties
}

export const Imgcard: React.FC<Props> = (props) => {
  const classes = useStyles({})

  return (
    <div className={classes.imgcardRoot} style={{ ...props.ownWrapStyles }}>
      <img
        loading="lazy"
        src={props.src}
        alt={props.alt}
        onClick={props.onClick}
        style={{ width: props.width, height: props.height, ...props.ownStyles }}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  imgcardRoot: { textAlign: 'center' },
}))
