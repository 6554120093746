import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import leftArrowGray from '../assets/leftArrowGray.svg'
import leftArrowGreen from '../assets/leftArrowGreen.svg'
import rightArrowGray from '../assets/rightArrowGray.svg'
import rightArrowGreen from '../assets/rightArrowGreen.svg'

type Props = {
  visible: boolean
  windowId: string
  isLoading: boolean
}
type StyleProps = { ownStyles?: { [k: string]: string | number | undefined } }

export const SlideArrow: React.FC<Props & StyleProps> = ({ visible, windowId, isLoading, ownStyles }) => {
  const classes = useStyles({ ownStyles })

  //helpers--->
  const [isClickable, setIsClickable] = React.useState<{ left: boolean; right: boolean }>({ left: false, right: true }) // handle arrows are clickable or not
  const rightBorder = () => setIsClickable({ left: true, right: false })
  const leftBorder = () => setIsClickable({ left: false, right: true })
  const middle = () => setIsClickable({ left: true, right: true })
  const noUse = () => setIsClickable({ left: false, right: false })

  React.useEffect(() => {
    if (!isLoading) {
      const el = document.getElementById(windowId)
      const isNoScroll = el?.scrollWidth === el?.clientWidth
      const isLeftBorder = el?.scrollLeft === 0
      const isRightBorder =
        el?.scrollLeft && el?.scrollWidth && el?.scrollLeft + el?.clientWidth + 0.25 === el?.scrollWidth

      if (isNoScroll) {
        noUse()
      } else if (isLeftBorder) {
        leftBorder()
      } else if (isRightBorder) {
        rightBorder()
      } else {
        middle()
      }
    }
  }, [windowId, isLoading])

  const [scrollId, setScrollId] = React.useState<NodeJS.Timer | null>(null)

  const scrollMove = 20 //handle move contents horizontally per 0.05 seconds
  //<---helpers

  const onMouseDownLeft = () => {
    const el = document.getElementById(windowId)
    if (el) {
      let position: number
      setScrollId(
        setInterval(() => {
          el.scrollBy({ top: 0, left: -1 * scrollMove, behavior: 'smooth' })
          if (position === el.scrollLeft) {
            clearInterval(scrollId ?? ({} as NodeJS.Timer))
            leftBorder()
          } else {
            position = el.scrollLeft
            middle()
          }
        }, 50)
      )
    }
  }
  const onMouseUpLeft = () => {
    clearInterval(scrollId ?? ({} as NodeJS.Timer))
    setScrollId(null)
  }
  const onMouseLeaveLeft = () => {
    clearInterval(scrollId ?? ({} as NodeJS.Timer))
    setScrollId(null)
  }
  const onMouseDownRight = () => {
    const el = document.getElementById(windowId)
    if (el) {
      let position: number
      setScrollId(
        setInterval(() => {
          el.scrollBy({ top: 0, left: 1 * scrollMove, behavior: 'smooth' })
          if (position === el.scrollLeft) {
            clearInterval(scrollId ?? ({} as NodeJS.Timer))
            rightBorder()
          } else {
            position = el.scrollLeft
            middle()
          }
        }, 50)
      )
    }
  }
  const onMouseUpRight = () => {
    clearInterval(scrollId ?? ({} as NodeJS.Timer))
    setScrollId(null)
  }
  const onMouseLeaveRight = () => {
    clearInterval(scrollId ?? ({} as NodeJS.Timer))
    setScrollId(null)
  }

  if (!visible) return null
  return (
    <div className={classes.slideArrowRoot}>
      <img
        src={isClickable.left ? leftArrowGreen : leftArrowGray}
        alt={'leftArrowGreen'}
        onMouseDown={isClickable.left ? onMouseDownLeft : undefined}
        onMouseUp={onMouseUpLeft}
        onMouseLeave={onMouseLeaveLeft}
      />
      <img
        src={isClickable.right ? rightArrowGreen : rightArrowGray}
        alt={'rightArrowGreen'}
        onMouseDown={isClickable.right ? onMouseDownRight : undefined}
        onMouseUp={onMouseUpRight}
        onMouseLeave={onMouseLeaveRight}
      />
    </div>
  )
}

const useStyles = makeStyles(
  {
    slideArrowRoot: ({ ownStyles }: StyleProps) => ({
      position: 'absolute',
      bottom: 16,
      right: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 8,
      ...ownStyles,
    }),
  },
  { name: 'SlideArrow' }
)
