import * as React from 'react'

import HyphenIcon from '@mui/icons-material/Remove'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useForm } from 'react-hook-form'
import sanitize from 'sanitize-html'
import { IData } from 'services/api/helps'

import { Breadcrumb } from 'components/Breadcrumb'
import { MenuList } from 'components/MenuList'
import { TitleRenewal } from 'components/TitleRenewal'

import * as constants from '../../assets/constants'
import PlusIcon from '../landing/components/topPageModules/assets/plusIcon.svg'

import { connector, ContainerProps } from './index.container'

interface IInputSearch {
  content: string
  category: string
}

type Props = ContainerProps

type AccordionBoxProps = {
  data: IData
}

const AccordionBox: React.FC<AccordionBoxProps> = ({ data }) => {
  const classes = useStyles()

  const textToHtml = (content: string) => {
    const sanitizeHtml = (contentSanitize: string) => {
      return sanitize(contentSanitize, {
        allowedTags: ['a', 'br'],
        allowedClasses: {
          a: ['__link'],
        },
        allowedAttributes: {
          a: ['href'],
        },
      })
    }

    if (content) {
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content),
          }}
        />
      )
    } else {
      return <></>
    }
  }

  return (
    <Accordion
      className={classes.accordion}
      square={true}
      classes={{
        root: classes.MUIRoot,
        expanded: classes.expanded,
      }}
    >
      <AccordionSummary
        className={classes.summary}
        classes={{
          content: classes.content,
          expandIconWrapper: classes.MUISummaryExpandIcon,
        }}
        expandIcon={<img src={PlusIcon} alt="plus-icon" />}
      >
        <div className={classes.Q}>Q</div>
        <div className={classes.question}>{data.question}</div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.MUIRootDetails,
        }}
      >
        <div className={classes.A}>A</div>
        <div className={classes.answer}>{textToHtml(data.answer)}</div>
      </AccordionDetails>
    </Accordion>
  )
}

const Index: React.FC<Props> = (props: Props) => {
  React.useEffect(() => {
    props.onMount()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = useStyles()

  const { register, handleSubmit } = useForm<IInputSearch>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const labels = ['サービス全体', 'オンボーディングツール', '管理職主導型組織開発ツール']

  const categories = [
    { id: 'service', label: labels[0] },
    { id: 'onboarding', label: labels[1] },
    { id: 'team', label: labels[2] },
  ]

  const dataServices = props.help.data?.filter((item) => item.category === labels[0])
  const dataOnboardings = props.help.data?.filter((item) => item.category === labels[1])
  const dataTeams = props.help.data?.filter((item) => item.category === labels[2])

  const onSubmit = (data: IInputSearch) => {
    // setCategory('0')
    props.search(data.content, '')
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.topContainer}>
          <div className={classes.topImg}>
            <div className={classes.breadcrumbContainer}>
              <div className={classes.breadcrumbWrapper}>
                <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="ヘルプセンター" />
              </div>
            </div>
            <TitleRenewal category="Help center" title="ヘルプセンター" />

            <div className={classes.formContainer}>
              <form className="__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="__formGroup">
                  <IconButton type="submit" className="__iconButton" aria-label="search" size="large">
                    <img src={`${process.env.PUBLIC_URL}/assets/landing/help/search.svg`} alt={`search`} />
                  </IconButton>
                  <InputBase
                    className="__inputBase"
                    placeholder="何をお探しですか？"
                    name="content"
                    inputRef={register({})}
                    inputProps={{ 'aria-label': '検索' }}
                  />
                </div>
              </form>
            </div>

            <div className={classes.menuContainer}>
              <MenuList page={constants.PAGE_HELP} items={categories} buttonLabel={'カテゴリから選ぶ'} />
            </div>
          </div>
        </div>

        <div className={classes.categoryContainer}>
          {labels.map((label, index) => {
            const items = index === 0 ? dataServices : index === 1 ? dataOnboardings : dataTeams
            return items && items.length !== 0 ? (
              <div className={classes.categoryWrapper} key={`help-label-key-${index}`}>
                <div id={categories[index].id} className={classes.categoryTitle}>
                  <HyphenIcon className={classes.hyphen} />
                  {label}
                </div>
                {items.map((item, i) => (
                  <React.Fragment key={`help-item-key-${i}`}>
                    <AccordionBox data={item} />
                  </React.Fragment>
                ))}
              </div>
            ) : null
          })}
        </div>

        <div className={classes.contactContainer}>
          <p className={classes.contactTitle}>その他の問い合わせ</p>
          <p className={classes.contactTo}>Cocolabo 運営事務局</p>
          <p className={classes.contactMail}>
            サポート担当　mail :{' '}
            <a href={'mailto:' + process.env.REACT_APP_COMPANY_MAIL}>{process.env.REACT_APP_COMPANY_MAIL}</a>
          </p>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: constants.COLOR_WHITE,
    padding: '0 24px 140px',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    overflow: 'hidden',
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 100,
    },
  },
  container: {
    width: '100%',
    maxWidth: 1000,
    '& .__form': {
      width: '100%',
      maxWidth: 630,

      '& .__formGroup': {
        background: constants.COLOR_WHITE,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '8px',
        height: 60,
        borderRadius: 30,
        border: `1px solid ${constants.COLOR_GRAY_LIGHT}`,
        '& .__iconButton': {
          height: 60,
          width: 60,
          borderRadius: 30,
        },
        '& .__inputBase': {
          padding: '8px 16px 8px 0',
          width: '100%',
        },

        '& .__selectForm': {
          width: '100%',
        },
        '& .__select': {
          padding: '16px',

          '&:focus': {
            backgroundColor: 'white',
          },
        },
        '& .__select__icon': {
          width: '50px',
          height: '50px',
          position: 'absolute',
          top: 0,
          background: constants.TEXT_GRAY,
          color: constants.COLOR_WHITE,
        },
        '& .MuiInputBase-root': {
          fontSize: 14,
        },
      },
    },
  },

  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 -24px',
    },
  },
  topImg: {
    width: 2040,
    margin: '0 calc((100vw - 2040px) / 2)',
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/landing/help/mock.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/landing/help/mock-sp.jpg)`,
      backgroundSize: '100% 290px',
    },
  },

  breadcrumbContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  breadcrumbWrapper: {
    width: 'calc(100vw - 48px)',
    maxWidth: 1200,
  },

  formContainer: {
    margin: '25px auto 36px',
    padding: '0 48px',
    width: '100%',
    maxWidth: 630,
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },

  menuContainer: {
    margin: '48px auto 0',
    padding: '0 24px',
    width: '100%',
    maxWidth: 620,
    [theme.breakpoints.down('md')]: {
      maxWidth: 434,
    },
  },

  categoryContainer: {
    paddingTop: 35,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  categoryWrapper: {
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      marginTop: 96,
      '&:first-of-type': {
        marginTop: 60,
      },
    },
  },
  categoryTitle: {
    marginTop: -80,
    paddingTop: 80,
    marginBottom: 28,
    color: constants.COLOR_MAIN_NEW,
    fontSize: 20,
    fontWeight: 'bold',
  },
  hyphen: {
    fontSize: 26,
    position: 'relative',
    top: 5,
    marginRight: 10,
  },

  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 48,
    padding: '42px 0',
    backgroundColor: constants.COLOR_BLUE_EXTRA_LIGHT3,
  },
  contactTitle: {
    margin: 0,
    color: constants.COLOR_MAIN_NEW,
    fontSize: 18,
    fontWeight: 'bold',
  },
  contactTo: {
    margin: 0,
    marginTop: 20,
    fontWeight: 'bold',
  },
  contactMail: {
    margin: 0,
    marginTop: 10,
    fontSize: 12,
    '& a': {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
      textDecorationColor: constants.COLOR_MAIN_NEW,
    },
  },

  accordion: {
    color: constants.TEXT_GRAY_DARK,
    '& a': {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
      textDecorationColor: constants.COLOR_MAIN_NEW,
    },
  },
  MUIRoot: {
    margin: '0 0 24px',
    position: 'static',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px #00000014',
    '&::before': {
      opacity: 0,
    },
    '&.Mui-expanded': {
      boxShadow: '0px 0px 10px #00000014',
    },
    '&.MuiAccordion-root': {
      '&.Mui-expanded': {
        margin: '24px 0',
      },
    },
  },
  expanded: {
    backgroundColor: constants.COLOR_WHITE,
    height: 'auto',
    padding: 0,
  },
  summary: {
    height: 'auto',
    margin: 0,
    padding: '0 36px',
    [theme.breakpoints.down('md')]: {
      padding: '0 24px',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: '26px 0',
    [theme.breakpoints.down('md')]: {
      margin: '21px 0',
    },
    '&.MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: '26px 0',
        [theme.breakpoints.down('md')]: {
          margin: '21px 0',
        },
      },
    },
  },
  MUISummaryExpandIcon: {
    '&.Mui-expanded': {
      transform: 'rotate(45deg) scale(1.15)',
    },
  },
  Q: {
    fontSize: 24,
    margin: '-5px 0 0 0',
    alignSelf: 'start',
    fontFamily: constants.FONT_FAMILY_TOP_TITLE,
  },
  question: {
    fontSize: 14,
    fontWeight: 600,
    margin: '0 24px 0 24px',
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  MUIRootDetails: {
    margin: '0 36px 26px',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0 24px 24px',
    },
  },
  answer: {
    padding: '0 0 0 24px',
    fontSize: 14,
    '& p': {
      margin: '5px 0 0',
      lineHeight: 1.8,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  A: {
    fontSize: 24,
    padding: '-5px 0 0 0',
    alignSelf: 'start',
    fontFamily: constants.FONT_FAMILY_TOP_TITLE,
  },
}))

export default connector(Index)
