import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import { useMediaQuery } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { HelmetProvider } from 'react-helmet-async'

import { AppHelmet } from 'components/AppHelmet'
import Footer from 'components/footer/Footer.component'
import Header from 'components/header/Header.component'
import CaseIntroduction from 'pages/case-introduction'
import { Freetrial } from 'pages/freetrial'
import { Hataraku } from 'pages/hataraku/pages'
import { HatarakuQuestionnariePage } from 'pages/hataraku/pages/questionnaire'
import { HatarakuResultsPage } from 'pages/hataraku/pages/results'
import { hintContext, useHintContext } from 'pages/hint/_shared/context/useHintContext'
import { MaintainContextProvider } from 'pages/maintain/contexts/MaintainContext'
import { Result as ObakesagashiResult } from 'pages/obakesagashi/pages/Result'
import { Search as ObakesagashiSearch } from 'pages/obakesagashi/pages/Search'
import { Top as ObakesagashiTop } from 'pages/obakesagashi/pages/Top'
import OnboardingPagesRoot from 'pages/onboarding/pages/PagesRoot'
import { TeamsAnketNewPage } from 'pages/teams/anket/new'
import { DefaultContainer, TeamAuth } from 'pages/teams/components'
import { TeamBuildingContainer } from 'pages/teams/components'
import {
  TeamBuildingReviewHooksContext,
  useTeamBuildingReviewHooksContext,
} from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { TeamsHooksContextProvider } from 'pages/teams/contexts/HooksContextTeams'
import { TeamsToolsBuildingRootPage } from 'pages/teams/pages/_tools/buildings'
import { TeamsToolsBuildingDashboardPage } from 'pages/teams/pages/_tools/buildings/pages/dashboard'
import { TeamsToolsBuildingDashboardActionCompletedPage } from 'pages/teams/pages/_tools/buildings/pages/dashboard/action/completed'
import { TeamsToolsBuildingDashboardActionPage } from 'pages/teams/pages/_tools/buildings/pages/dashboard/action/edit'
import { TeamsToolsBuildingDashboardActionPreviewPage } from 'pages/teams/pages/_tools/buildings/pages/dashboard/action/preview'
import { KickoffResetPage } from 'pages/teams/pages/_tools/buildings/pages/dashboard/reset/kickoffResetPage'
import { TeamsToolsBuildingHomePage } from 'pages/teams/pages/_tools/buildings/pages/home'
import { TeamsIceBreakPage } from 'pages/teams/pages/_tools/iceBreak'
import { TeamsToolsKarteRoute } from 'pages/teams/pages/_tools/karte/pages'
import { TeamsReviewPage } from 'pages/teams/pages/_tools/review'
import { TeamsAboutPage } from 'pages/teams/pages/about'
import { TeamsAdminPage } from 'pages/teams/pages/admin'
import { TeamsAdminTransferPage } from 'pages/teams/pages/adminTransfer'
import { TeamsAdminTransferAcceptPage } from 'pages/teams/pages/adminTransferAccept'
import { TeamsCorpPage } from 'pages/teams/pages/corp'
import { TeamsDashboardPage } from 'pages/teams/pages/dashboard'
import { DocumentsDownload } from 'pages/teams/pages/documentsDownload'
import { TeamsLandingPage } from 'pages/teams/pages/landing'
import { TeamsListPage } from 'pages/teams/pages/list'
import { TeamsMembersPage } from 'pages/teams/pages/members'
import { TeamsMembersAddByCsvPage } from 'pages/teams/pages/membersAddByCsv'
import { TeamsMembersEditPage } from 'pages/teams/pages/membersEdit'
import { TeamsNewPage } from 'pages/teams/pages/new'
import { TeamsNewByCsvPage } from 'pages/teams/pages/newByCsv'
import { TeamsPaymentPage } from 'pages/teams/pages/payment'
import { TeamsPlanPage } from 'pages/teams/pages/plan'
import { TeamsStartPage } from 'pages/teams/pages/start'
import { TeamsEditPage } from 'pages/teams/pages/teamsEdit'
import { TeamsSelectPage } from 'pages/teams/pages/teamsSelect'
import { TeamsToolsPage } from 'pages/teams/pages/tools'
import { TeamsUsageMovie } from 'pages/teams/pages/usgageMovie'

import './App.css'
import AuthRouter from './AuthRouter'
import AboutPage from './pages/about'
import CasePage from './pages/case'
import CaseUserPage from './pages/case/user'
import CoachPage from './pages/coach/index'
import { ContactPage } from './pages/contact'
import { ContactFinished } from './pages/contactFinished'
import { ResourcesPage } from './pages/documents'
import { CompletePage } from './pages/documents/CompletePage'
import { DownloadPage } from './pages/documents/DownloadPage'
import FeaturesPage from './pages/features/index.component'
import { KartePage } from './pages/features/pages/karte'
import { KickoffPage } from './pages/features/pages/kickoff'
import { ObakePage } from './pages/features/pages/obake'
import { OnboardingPage } from './pages/features/pages/onboarding'
import GhostRoute from './pages/ghost/root'
import HelpPage from './pages/help'
import HintFaqArticlesPage from './pages/hint/faq/article/index'
import HintFaqHomePage from './pages/hint/faq/index/index'
import HintSearchPage from './pages/hint/search/index/index'
import HintTipsArticlesPage from './pages/hint/tips/article/index'
import HintTipsHomePage from './pages/hint/tips/index/index'
import HintProfilePage from './pages/hint/tips/profile/index/index'
import KarteLandingPage from './pages/karte/landing/index.component'
import KarteLeaderHomePage from './pages/karte/leader/index.component'
import KarteLeaderPreparePage from './pages/karte/leader/prepare/index.component'
import KarteLeaderQuestionnairePage from './pages/karte/leader/questionnaire/index.component'
import KarteLeaderResultsPage from './pages/karte/leader/results/index.component'
import KarteLeaderSearchPage from './pages/karte/leader/search/index.component'
import KarteHomeMemberPage from './pages/karte/member/index.component'
import KarteMemberPreparePage from './pages/karte/member/prepare/index.component'
import KarteMemberQuestionnairePage from './pages/karte/member/questionnaire/index.component'
import KarteMemberResultsPage from './pages/karte/member/results/index.component'
import LandingPage from './pages/landing/index.component'
import RegalPage from './pages/legal'
import NotificationPage from './pages/notification'
import PlanPage from './pages/plan/index.component'
import PrivacyPolicyPage from './pages/privacypolicy'
import SelfCheck from './pages/selfcheck/description/index.component'
import SelfCheckLanding from './pages/selfcheck/landing/index.component'
import SelfCheckQuestions from './pages/selfcheck/questions/index.component'
import SelfCheckResults from './pages/selfcheck/results/index.component'
import SetupEmailNotifyPage from './pages/setup/emailNotify/index.component'
import SetupHome from './pages/setup/home/index.component'
import SetupLandingPage from './pages/setup/landing/index.component'
import SetupListPage from './pages/setup/list/index.component'
import SigninPage from './pages/signin/index.component'
import ResetPasswordPage from './pages/signin/reset_password.component'
import ResetPasswordCompletePage from './pages/signin/reset_password_complete.component'
import RestorePage from './pages/signin/restore.component'
import SignupCompletePage from './pages/signup/complete.component'
import SignupConfirmPage from './pages/signup/confirm.component'
import SignupPage from './pages/signup/index.component'
import SignupSendEmailPage from './pages/signup/sendEmail.component'
import TeamPage from './pages/team/index'
import TearmsPage from './pages/terms'
import TearmsPreviousPage from './pages/terms-previous'
import WithdrawPage from './pages/withdraw/index.component'

import * as constants from './assets/constants'
import * as pages from './assets/pages'
import { theme } from 'assets/theme'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type Props = WithStyles<typeof useStyles>

const App: React.FC<Props> = (props) => {
  const { classes } = props
  const location = useLocation()

  const { isShowModal, onSetShowCreateModal, onSetShowUpdateModal, onCloseModal } = useHintContext()
  const teamBuildingReviewValues = useTeamBuildingReviewHooksContext()

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const onboardingHeader = location.pathname.match(new RegExp('/onboarding/.+'))
  const anketHeader = location.pathname.match(new RegExp('/anket/.+'))
  const teams = location.pathname.match(new RegExp('/teams/.+'))
  const isHataraku = location.pathname.match(new RegExp(`${pages.Pages.Hataraku}.*`))
  const isObakesagashi = location.pathname.match(new RegExp(`${pages.Pages.Obakesagashi}.*`))
  const paddingTeams = teams && !isMdUp
  const reviewHeader = location.pathname.match(new RegExp('/review'))
  const iceBreakHeader = location.pathname.match(new RegExp('/icebreak'))
  const paddingZero =
    anketHeader ||
    onboardingHeader ||
    reviewHeader ||
    iceBreakHeader ||
    location.pathname === '/' ||
    isHataraku ||
    isObakesagashi
  return (
    <MaintainContextProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <hintContext.Provider value={{ isShowModal, onSetShowCreateModal, onSetShowUpdateModal, onCloseModal }}>
            <HubspotProvider>
              <HelmetProvider>
                <AppHelmet />
                <CssBaseline />
                <Header />
                <main
                  className={!onboardingHeader ? classes.main : `${classes.main} ${classes.onboarding}`}
                  style={{
                    paddingTop: paddingTeams ? 0 : paddingZero ? 0 : teams ? 78 : constants.HEADER_HEIGHT,
                  }} //if  page is Onboarding or TopPage ,paddingTop is 0 because of HeaderHeight
                >
                  <Switch>
                    {/* Public */}
                    <Route exact={true} path={constants.PAGE_TOP} component={LandingPage} />
                    <Route exact={true} path={constants.PAGE_SIGNIN} component={SigninPage} />
                    <Route exact={true} path={constants.PAGE_SIGNUP} component={SignupPage} />
                    <Route path={constants.PAGE_SIGNUP_SEND_EMAIL} component={SignupSendEmailPage} />
                    <Route path={constants.PAGE_SIGNUP_CONFIRM} component={SignupConfirmPage} />
                    <Route exact={true} path={constants.PAGE_SIGNUP_COMPLETE} component={SignupCompletePage} />
                    <Route exact={true} path={constants.PAGE_RESTORE} component={RestorePage} />
                    <Route exact={true} path={constants.PAGE_RESET_PASSWORD} component={ResetPasswordPage} />
                    <Route
                      exact={true}
                      path={constants.PAGE_RESET_PASSWORD_COMPLETE}
                      component={ResetPasswordCompletePage}
                    />
                    <Route exact={true} path={constants.PAGE_HELP} component={HelpPage} />
                    <Route exact={true} path={constants.PAGE_NOTIFICATION} component={NotificationPage} />
                    <Route exact={true} path={constants.PAGE_TERMS} component={TearmsPage} />
                    <Route exact={true} path={constants.PAGE_TERMS_PREVIOUS} component={TearmsPreviousPage} />
                    <Route exact={true} path={constants.PAGE_DOCUMENTS} component={ResourcesPage} />

                    <Route exact={true} path={constants.PAGE_DOCUMENTS_DOWNLOAD} component={DownloadPage} />
                    <Route exact={true} path={constants.PAGE_DOCUMENTS_COMPLETE} component={CompletePage} />
                    <Route exact={true} path={constants.PAGE_PRIVACY_POLICY} component={PrivacyPolicyPage} />
                    <Route exact={true} path={constants.PAGE_ABOUT} component={AboutPage} />
                    <Route exact={true} path={constants.PAGE_PLAN} component={PlanPage} />
                    <Route exact={true} path={constants.PAGE_LEGAL} component={RegalPage} />
                    <Route exact={true} path={constants.PAGE_CASE} component={CasePage} />
                    <Route exact={true} path={constants.PAGE_CASE_USER} component={CaseUserPage} />
                    <Route exact={true} path={constants.PAGE_CASE_INTRODUCTION} component={CaseIntroduction} />
                    <Route exact={true} path={constants.PAGE_FEATURES} component={FeaturesPage} />
                    <Route exact={true} path={constants.PAGE_FEATURES_KICKOFF} component={KickoffPage} />
                    <Route exact={true} path={constants.PAGE_FEATURES_OBAKE} component={ObakePage} />
                    <Route exact={true} path={constants.PAGE_FEATURES_KARTE} component={KartePage} />
                    <Route exact={true} path={constants.PAGE_FEATURES_ONBOARDING} component={OnboardingPage} />
                    <Route exact={true} path={constants.PAGE_CONTACT} component={ContactPage} />
                    <Route exact={true} path={constants.PAGE_CONTACTFINISHED} component={ContactFinished} />
                    <Route exact={true} path={constants.PAGE_FREETRIAL} component={Freetrial} />
                    <Route exact={true} path={pages.PAGE_SETUP_LANDING} component={SetupLandingPage} />

                    <Route exact={true} path={pages.KARTE_LANDING} component={KarteLandingPage} />
                    <Route exact={true} path={pages.KARTE_MEMBER} component={KarteHomeMemberPage} />
                    <Route exact={true} path={pages.KARTE_MEMBER_PREPARE} component={KarteMemberPreparePage} />
                    <Route
                      exact={true}
                      path={pages.KARTE_MEMBER_QUESTIONNAIRE}
                      component={KarteMemberQuestionnairePage}
                    />
                    <Route exact={true} path={pages.KARTE_MEMBER_RESULTS} component={KarteMemberResultsPage} />

                    <Route exact={true} path={pages.HINT}>
                      <Redirect to={pages.HINT_TIPS_HOME} />
                    </Route>
                    <Route exact={true} path={pages.HINT_TIPS_HOME} component={HintTipsHomePage} />
                    <Route exact={true} path={pages.HINT_FAQ_HOME} component={HintFaqHomePage} />
                    <Route exact={true} path={pages.HINT_TIPS_PAGE} component={HintTipsHomePage} />
                    <Route exact={true} path={pages.HINT_FAQ_PAGE} component={HintFaqHomePage} />
                    <Route exact={true} path={pages.HINT_TIPS} component={HintTipsArticlesPage} />
                    <Route exact={true} path={pages.HINT_FAQ} component={HintFaqArticlesPage} />
                    <Route exact={true} path={pages.HINT_PROFILE} component={HintProfilePage} />
                    <Route exact={true} path={pages.HINT_PROFILE_PAGE} component={HintProfilePage} />
                    <Route exact={true} path={pages.HINT_TIPS_SEARCH} component={HintSearchPage} />
                    <Route exact={true} path={pages.HINT_FAQ_SEARCH} component={HintSearchPage} />
                    <Route exact={true} path={pages.HINT_SEARCH_PAGE} component={HintSearchPage} />

                    <Route exact={true} path={constants.PAGE_COACH} component={CoachPage} />
                    <Route exact={true} path={constants.PAGE_TEAM} component={TeamPage} />
                    <Route exact={true} path={constants.PAGE_TEAM_OLD} component={TeamPage}>
                      <Redirect to={constants.PAGE_TEAM} />
                    </Route>

                    <Route exact={true} path={pages.SELFCHECK} component={SelfCheck} />
                    <Route exact={true} path={pages.Pages.TeamsLanding} component={TeamsLandingPage} />

                    {/* Free Play */}
                    <Route exact={true} path={pages.Pages.Hataraku} component={Hataraku} />
                    <Route
                      exact={true}
                      path={pages.Pages.HatarakuQuestionnaire}
                      component={HatarakuQuestionnariePage}
                    />
                    <Route exact={true} path={pages.Pages.HatarakuResults} component={HatarakuResultsPage} />

                    <Route exact={true} path={pages.Pages.Obakesagashi} component={ObakesagashiTop} />
                    <Route exact={true} path={pages.Pages.ObakesagashiSearch} component={ObakesagashiSearch} />
                    <Route exact={true} path={pages.Pages.ObakesagashiResult} component={ObakesagashiResult} />

                    {/* Auth */}
                    <AuthRouter>
                      <Route exact={true} path={pages.PAGE_SETUP_HOME} component={SetupHome} />
                      <Route exact={true} path={pages.PAGE_SETUP_LIST} component={SetupListPage} />
                      <Route exact={true} path={pages.PAGE_SETUP_EMAILNOTIFY} component={SetupEmailNotifyPage} />
                      <Route exact={true} path={pages.KARTE_LEADER_HOME} component={KarteLeaderHomePage} />
                      <Route exact={true} path={pages.KARTE_LEADER_PREPARE} component={KarteLeaderPreparePage} />
                      <Route exact={true} path={pages.KARTE_LEADER_SEARCH} component={KarteLeaderSearchPage} />
                      <Route
                        exact={true}
                        path={pages.KARTE_LEADER_QUESTIONNAIRE}
                        component={KarteLeaderQuestionnairePage}
                      />
                      <Route exact={true} path={pages.KARTE_LEADER_RESULTS} component={KarteLeaderResultsPage} />

                      {/* ONBOARDING */}
                      <Route path={pages.ONBOARDING_ROUTE_CATCH} component={OnboardingPagesRoot} />

                      <Route exact={true} path={pages.SELFCHECK_LANDING} component={SelfCheckLanding} />
                      <Route exact={true} path={pages.SELFCHECK_QUESTIONS} component={SelfCheckQuestions} />
                      <Route exact={true} path={pages.SELFCHECK_RESULTS} component={SelfCheckResults} />
                      <Route exact={true} path={pages.WITHDRAW} component={WithdrawPage} />

                      <Route exact={true} path={pages.Pages.DocumentsDownload} component={DocumentsDownload} />

                      <TeamsHooksContextProvider>
                        <Switch>
                          <Route exact={true} path={pages.Pages.TeamsAnketNew} component={TeamsAnketNewPage} />
                          <Route exact={true} path={pages.Pages.TeamsStart} component={TeamsStartPage} />
                          <Route exact={true} path={pages.Pages.TeamsIceBreak} component={TeamsIceBreakPage} />
                          <Route exact={true} path={pages.Pages.TeamsReview}>
                            <TeamBuildingReviewHooksContext.Provider value={teamBuildingReviewValues}>
                              <Route exact={true} path={pages.Pages.TeamsReview} component={TeamsReviewPage} />
                            </TeamBuildingReviewHooksContext.Provider>
                          </Route>

                          <Route path={pages.Pages.TeamsTool}>
                            <Switch>
                              {/* Teams TeamBuilding App */}
                              <Route path={pages.Pages.TeamsToolBuilding}>
                                <TeamBuildingContainer>
                                  <Switch>
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuilding}
                                      component={TeamsToolsBuildingRootPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboard}
                                      component={TeamsToolsBuildingDashboardPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboardActionCreate}
                                      component={TeamsToolsBuildingDashboardActionPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboardActionEdit}
                                      component={TeamsToolsBuildingDashboardActionPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboardActionCompleted}
                                      component={TeamsToolsBuildingDashboardActionCompletedPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboardActionPreview}
                                      component={TeamsToolsBuildingDashboardActionPreviewPage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingSubs}
                                      component={TeamsToolsBuildingHomePage}
                                    />
                                    <Route
                                      exact={true}
                                      path={pages.Pages.TeamsToolBuildingDashboardActionReset}
                                      component={KickoffResetPage}
                                    />
                                  </Switch>
                                </TeamBuildingContainer>
                              </Route>

                              {/* Teams Dashboard */}
                              <Route path={pages.Pages.TeamsToolKarte} component={TeamsToolsKarteRoute} />
                            </Switch>
                          </Route>

                          {/* Teams Dashboard */}
                          <Route path={pages.Pages.TeamsDashboard}>
                            <TeamBuildingReviewHooksContext.Provider value={teamBuildingReviewValues}>
                              <DefaultContainer>
                                <Switch>
                                  <Route exact={true} path={pages.Pages.TeamsList} component={TeamsListPage} />
                                  <Route exact={true} path={pages.Pages.TeamsListDirect} component={TeamsListPage} />
                                  <Route exact={true} path={pages.Pages.TeamsNew} component={TeamsNewPage} />
                                  <Route exact={true} path={pages.Pages.TeamsNewByCsv} component={TeamsNewByCsvPage} />
                                  <Route exact={true} path={pages.Pages.TeamsEdit} component={TeamsEditPage} />
                                  <Route exact={true} path={pages.Pages.TeamsSelect} component={TeamsSelectPage} />

                                  <TeamAuth>
                                    <Switch>
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsDashboard}
                                        component={TeamsDashboardPage}
                                      />
                                      <Route exact={true} path={pages.Pages.TeamsAbout} component={TeamsAboutPage} />
                                      <Route exact={true} path={pages.Pages.TeamsCorp} component={TeamsCorpPage} />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsPayment}
                                        component={TeamsPaymentPage}
                                      />
                                      <Route exact={true} path={pages.Pages.TeamsPlan} component={TeamsPlanPage} />
                                      <Route exact={true} path={pages.Pages.TeamsTools} component={TeamsToolsPage} />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsUsageMovie}
                                        component={TeamsUsageMovie}
                                      />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsMembers}
                                        component={TeamsMembersPage}
                                      />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsMembersAdd}
                                        component={TeamsMembersEditPage}
                                      />
                                      <Route exact={true} path={pages.Pages.TeamsAdmin} component={TeamsAdminPage} />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsAdminTransfer}
                                        component={TeamsAdminTransferPage}
                                      />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsAdminTransferAccept}
                                        component={TeamsAdminTransferAcceptPage}
                                      />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsMembersAddByCsv}
                                        component={TeamsMembersAddByCsvPage}
                                      />
                                      <Route
                                        exact={true}
                                        path={pages.Pages.TeamsMembersEdit}
                                        component={TeamsMembersEditPage}
                                      />
                                    </Switch>
                                  </TeamAuth>
                                </Switch>
                              </DefaultContainer>
                            </TeamBuildingReviewHooksContext.Provider>
                          </Route>
                        </Switch>
                      </TeamsHooksContextProvider>

                      {/* ghost */}
                      <Route path={'/ghost/*'} component={GhostRoute} />
                    </AuthRouter>

                    {/* Not Found */}
                    <Route component={() => <Redirect to="/" />} />
                  </Switch>
                </main>

                <Footer />
              </HelmetProvider>
            </HubspotProvider>
          </hintContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </MaintainContextProvider>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      minHeight: '80vh',
    },
    onboarding: {
      fontFamily: constants.ONBOARDING_FONT_FAMILY,
      '& div': {
        fontFamily: constants.ONBOARDING_FONT_FAMILY,
      },
    },
  })

export default withStyles(useStyles)(App)
