import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import Auth from '@aws-amplify/auth'

import { PAGE_SIGNIN } from 'assets/constants'

type Props = RouteComponentProps & {
  children: React.ReactNode
}

const AuthRouter: React.FC<Props> = ({ history, children }) => {
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    const f = async () => {
      const h = window.location.pathname.split('/')
      Auth.currentSession()
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          h.includes('teams') ? history.push(PAGE_SIGNIN) : history.push('/')
        })
    }
    f()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{!loading && children}</>
}

export default withRouter(AuthRouter)
