import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  title: string
  rightNode?: React.ReactNode
  bottomNode?: React.ReactNode
  onClick?: () => void
  onClickTitle?: string
  fullWidth?: boolean
  children: React.ReactNode
}

export const BuildingCard: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{props.title}</div>
        {props.onClick ? (
          <div onClick={props.onClick} className={classes.action}>
            {props.onClickTitle || '編集'}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.body}>{props.children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px solid',
    borderColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    padding: '18px 16px',
    width: '49%',
    backgroundColor: constants.COLOR_WHITE,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },

  title: {
    fontSize: 12,
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontWeight: 'bold',
  },

  action: {
    color: constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: 12,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  body: {},
}))
