import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import format from 'date-fns/format'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { SearchBar } from 'pages/onboarding/components/search-bar'
import {
  useOnboardingActions,
  useOnboardingActionsFeedback,
  useOnboardingActionManageList,
} from 'pages/onboarding/hooks/action'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { Card, Filter } from 'pages/onboarding/pages/actions-list/components'
import {
  countOfActions,
  countOfOverDeadlineActions,
  calcPercent,
} from 'pages/onboarding/utils/onboardingActionOverView'
import {
  OnboardingAction,
  OnboardingActionFeedback,
  OnboardingActionCategory,
  OnboardingRadarValue,
  OnboardingTeamMember,
  OnboardingTeamMemberRole,
} from 'utils/generated'

import { ProfileBox, TargetBox, ActionOverViewBox } from '../../components'
import { onboardingActionAddDate } from '../../utils/onboardingActionAddDate'
import { onboardingDeadlinePeriod } from '../../utils/onboardingDeadlinePeriod'
import { onboardingMemberPeriodFromNow } from '../../utils/onboardingMemberPeriodFromNow'
import { OnbContext } from '../PagesRoot'
import { useTutorial } from '../tutorial/logics/hooks'
import { TutorialProps } from '../tutorial/Tutorial'

import lock from './assets/lock.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

interface TutorialOverride {
  activeActions: OnboardingAction[]
  actionsFeedback?: OnboardingActionFeedback[]
  loadingActions: false
  userId: string
}

export const ActionListBloc = {
  useAdapter: (override?: TutorialOverride) => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { userId: paramUserId } = useParams<{ userId?: string }>()
    const targetUserId = paramUserId || teamMember.userId
    const { actions, loading } = useOnboardingActions(teamId, targetUserId)
    const { actionsFeedback, loading: loadingActionsFeedback } = useOnboardingActionsFeedback(teamId, targetUserId)
    const { teamMember: selectedTeamMember } = useTeamMember(teamId, targetUserId)
    const isMyActionList = teamMember.userId === targetUserId
    const isXsDown = useCustomMediaQuery('down', 'sm')
    const { actions: totalActions, loading: loadingTotalActions } = useOnboardingActionManageList(teamId)

    //ActionData
    const actionProgressPercent = () => {
      const total: { [k in OnboardingActionCategory]: number } = countOfActions(totalActions)
      const done: { [k in OnboardingActionCategory]: number } = countOfActions(actionsFeedback)
      const overDeadline: { [k in OnboardingActionCategory]: number } = countOfOverDeadlineActions(
        actions,
        selectedTeamMember
      )
      const donePerTotal: { [k in OnboardingActionCategory]: number } = calcPercent(done, total)

      return { overDeadline, donePerTotal }
    }
    const { overDeadline, donePerTotal } = actionProgressPercent()

    const percentDoneActions = Math.round((actionsFeedback.length / totalActions.length) * 100 * 10) / 10

    const isStarted = selectedTeamMember?.startedAt && selectedTeamMember.startedAt <= new Date().toISOString()

    const memberPeriodFromNow = onboardingMemberPeriodFromNow(selectedTeamMember?.startedAt)

    const actionsInProgress =
      !loadingActionsFeedback && !loadingTotalActions
        ? totalActions.filter(
            (action) => actionsFeedback.findIndex((feedback) => feedback.id === `${action.id}-${targetUserId}`) === -1
          )
        : []
    const activeActions = actionsInProgress
      .filter((action) => action.period <= memberPeriodFromNow)
      .sort((a, b) => a.period + (a.deadline || 10) - (b.period + (b.deadline || 10)))
    const standbyActions = actionsInProgress
      .filter((action) => action.period > memberPeriodFromNow)
      .sort((a, b) => a.period - b.period)

    return {
      teamId,
      userId: targetUserId,
      activeActions,
      standbyActions,
      isStarted,
      loadingActions: loading,
      actionsFeedback,
      loadingActionsFeedback,
      selectedTeamMember,
      isMyActionList,
      isXsDown,
      overDeadline,
      donePerTotal,
      percentDoneActions,
      ...override,
    }
  },
  parseRadarValue: (radarValues: OnboardingRadarValue) => {
    const data: number[] = [
      Math.round((radarValues.category2 ? (radarValues.category2 > 0.5 ? radarValues.category2 : 0.5) : 0.5) * 10) / 10,
      Math.round((radarValues.category3 ? (radarValues.category3 > 0.5 ? radarValues.category3 : 0.5) : 0.5) * 10) / 10,
      Math.round((radarValues.category4 ? (radarValues.category4 > 0.5 ? radarValues.category4 : 0.5) : 0.5) * 10) / 10,
      Math.round((radarValues.category5 ? (radarValues.category5 > 0.5 ? radarValues.category5 : 0.5) : 0.5) * 10) / 10,
      Math.round((radarValues.category1 ? (radarValues.category1 > 0.5 ? radarValues.category1 : 0.5) : 0.5) * 10) / 10,
    ]
    return data
  },
  msg: {
    noActiveAction: '進行中のアクションはありません。',
    noStandbyAction: '追加待ちのアクションはありません。',
    noAction: '該当するアクションがありません。',
    noMatch: '検索結果0件',
  },
}

const Index: React.FC<{ tutorial?: TutorialProps; override?: TutorialOverride }> = ({ tutorial, override }) => {
  // outer deps
  const { ttRef } = useTutorial(tutorial)
  const {
    teamId,
    userId,
    activeActions,
    standbyActions,
    isStarted,
    loadingActions,
    actionsFeedback,
    loadingActionsFeedback,
    selectedTeamMember,
    isMyActionList,
    isXsDown,
    donePerTotal,
    overDeadline,
    percentDoneActions,
  } = ActionListBloc.useAdapter(override)
  const { noActiveAction, noStandbyAction, noAction, noMatch } = ActionListBloc.msg
  const classes = useStyles()
  const history = useOnbHistory()

  const isAchievedTutorial = tutorial?.tooltip?.ownControl === 'actionAchieved'
  const isSelectTutorial = tutorial?.tooltip?.ownControl === 'actionSelect'

  // Category Filter
  const [filterShown, setFilterShown] = useState<boolean>(false)
  const [category, setCategory] = useState<OnboardingActionCategory | undefined>()

  // Tab
  const [tabValue, setTabValue] = useState<'one' | 'two'>(isAchievedTutorial ? 'two' : 'one')

  // Search Input
  const [input, setInput] = useState<string>('')
  const [search, setSearch] = useState<string>('')

  const filteredActions = (actions: OnboardingAction[]) =>
    actions
      .filter((item) => (category ? item.category === category : true))
      .filter((item) => item.mission && item.mission.indexOf(search) !== -1)
  const filteredFeedbacks = (feedbacks: OnboardingActionFeedback[]) =>
    feedbacks
      .filter((item) => (category ? item.category === category : true))
      .filter((item) => item.action?.mission && item.action.mission.indexOf(search) !== -1)

  const handleStartInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }
  const handleStartEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearch(input)
    }
  }
  const handleStartSearch = () => {
    setSearch(input)
  }

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: 'one' | 'two') => {
    setTabValue(newValue)
  }

  const handleClickPcTab = (e: React.MouseEvent) => {
    setTabValue((e.target as HTMLButtonElement).value as 'one' | 'two')
  }

  const startInfo = selectedTeamMember?.startedAt ? (
    <p className={classes.startInfo}>
      {selectedTeamMember.startedAt <= new Date().toISOString() ? (
        `アクション開始日：
      ${format(Date.parse(selectedTeamMember.startedAt), 'yyyy年MM月dd日')}
      `
      ) : (
        <>
          <img src={lock} alt="lock icon" className={classes.lock} />
          アクションスタート後行うことができます
        </>
      )}
    </p>
  ) : (
    <></>
  )

  return (
    <>
      <h1 style={{ textAlign: 'center', fontSize: 16, margin: '27px 0' }}>アクションリスト</h1>

      <div className={classes.boxWrapper}>
        <ProfileBox teamMember={selectedTeamMember || ({} as OnboardingTeamMember)} />
      </div>

      {!isAchievedTutorial && (
        <div className={classes.boxWrapper}>
          <TargetBox
            teamMember={selectedTeamMember || ({} as OnboardingTeamMember)}
            ownStyles={{ marginTop: isXsDown ? 16 : 24 }}
          />
        </div>
      )}

      {!isAchievedTutorial && selectedTeamMember?.role === OnboardingTeamMemberRole.Member ? (
        <div className={classes.boxWrapper}>
          <ActionOverViewBox
            percents={donePerTotal}
            overDeadlineActions={overDeadline}
            percentDoneActions={percentDoneActions}
            ownStyles={{ margin: isXsDown ? '16px 0' : '24px 0' }}
          />
        </div>
      ) : (
        <></>
      )}

      <div className={classes.template}>
        <Box className={classes.box}>
          <div
            ref={isAchievedTutorial ? (ttRef as React.MutableRefObject<HTMLDivElement>) : undefined}
            className={isAchievedTutorial ? classes.preventClick : undefined}
          >
            <div className={classes.contentsWrapper}>
              {isXsDown && <div className={classes.spStartInfoWrapper}>{startInfo}</div>}

              <div className={classes.search}>
                <SearchBar
                  placeholder="アクションを検索"
                  borderColor={constants.COLOR_ONBOARDING_GRAY_LIGHT}
                  borderRadius={8}
                  iconMarginLeft={16}
                  iconMarginRight={16}
                  onClickImg={handleStartSearch}
                  onChangeInput={handleStartInput}
                  onKeyPressInput={handleStartEnter}
                  inputValue={input}
                  setInputValue={setInput}
                  searchValue={search}
                  setSearchValue={handleStartSearch}
                />
                <div className={classes.filter}>
                  <Filter
                    isOpen={filterShown}
                    category={category}
                    setCategory={setCategory}
                    setFilterShowen={setFilterShown}
                    filterShowen={filterShown}
                  />
                </div>
              </div>

              {isXsDown ? (
                <Tabs
                  className={classes.tabs}
                  value={tabValue}
                  onChange={handleChangeTab}
                  aria-label="wrapped label tabs example"
                  classes={{ indicator: classes.indicator }}
                >
                  <Tab
                    value="one"
                    label="進行中"
                    wrapped={true}
                    id={`wrapped-tab-one`}
                    className="__tab __tabLeft"
                    classes={{
                      selected: '__tabSelected',
                      textColorInherit: '__textColorInherit',
                    }}
                  />
                  <Tab
                    value="two"
                    label="達成済"
                    className="__tab __tabRight"
                    classes={{
                      selected: '__tabSelected',
                      textColorInherit: '__textColorInherit',
                    }}
                  />
                </Tabs>
              ) : (
                <div className={classes.pcTabsWrapper}>
                  <div className={classes.pcTabs}>
                    {['one', 'two'].map((tab) => (
                      <NoStyleButton
                        key={`actions-list-tab-${tab}`}
                        type="button"
                        value={tab}
                        onClick={handleClickPcTab}
                        className={`tab ${tab === 'one' ? 'left' : 'right'}${tabValue !== tab ? ' inactive' : ''}`}
                      >
                        {tab === 'one' ? '進行中' : '達成済'}
                      </NoStyleButton>
                    ))}
                  </div>
                  {!isXsDown && startInfo}
                </div>
              )}

              {tabValue === 'one' ? (
                <>
                  {!loadingActions ? (
                    <>
                      {activeActions.length || standbyActions.length ? (
                        <>
                          {isStarted || isSelectTutorial ? (
                            <div className={classes.cards}>
                              {activeActions.length ? (
                                <>
                                  {filteredActions(activeActions).length ? (
                                    filteredActions(activeActions).map((action) => (
                                      <Card
                                        key={action.id}
                                        item={{
                                          id: action.id,
                                          importance: action.importance,
                                          mission: action.mission,
                                          what: action.what,
                                          why: action.why,
                                          how: action.how,
                                          category: action.category,
                                          period: selectedTeamMember?.startedAt
                                            ? onboardingDeadlinePeriod(
                                                action.period,
                                                selectedTeamMember?.startedAt,
                                                action?.deadline || undefined
                                              )
                                            : new Date().getTime(),
                                          missionValues: undefined,
                                          anketValues: undefined,
                                          point: undefined,
                                          weekDate: undefined,
                                          valuesId: undefined,
                                        }}
                                        basePeriod={new Date().getTime()}
                                        onActionDetail={() => {
                                          history.push(
                                            isMyActionList ? OnbPages.ActionsDetail : OnbPages.MemberManageActionDetail,
                                            {
                                              teamId,
                                              userId,
                                              actionId: action.id,
                                            }
                                          )
                                        }}
                                        ttRef={
                                          action.mission === '自己紹介をしてみよう' &&
                                          tutorial?.scene !== 'actionAchieved'
                                            ? (ttRef as React.MutableRefObject<HTMLDivElement>)
                                            : undefined
                                        }
                                      />
                                    ))
                                  ) : (
                                    <div>{noMatch}</div>
                                  )}
                                </>
                              ) : (
                                <div>{noActiveAction}</div>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={classes.cards}>
                            {(isStarted || isSelectTutorial) && (
                              <p className={classes.cardDescription}>今後追加されるアクション</p>
                            )}
                            {standbyActions.length && selectedTeamMember?.startedAt ? (
                              <>
                                {filteredActions(standbyActions).length ? (
                                  filteredActions(standbyActions).map((action) => (
                                    <Card
                                      key={action.id}
                                      isBeforeStart={!isStarted}
                                      addDay={onboardingActionAddDate(selectedTeamMember.startedAt, action.period)}
                                      item={{
                                        id: action.id,
                                        importance: action.importance,
                                        mission: action.mission,
                                        what: action.what,
                                        why: action.why,
                                        how: action.how,
                                        category: action.category,
                                        period: selectedTeamMember.startedAt
                                          ? onboardingDeadlinePeriod(
                                              action.period,
                                              selectedTeamMember?.startedAt,
                                              action?.deadline || undefined
                                            )
                                          : new Date().getTime(),
                                        missionValues: undefined,
                                        anketValues: undefined,
                                        point: undefined,
                                        weekDate: undefined,
                                        valuesId: undefined,
                                      }}
                                      basePeriod={new Date().getTime()}
                                      onActionDetail={() => {
                                        history.push(
                                          isMyActionList ? OnbPages.ActionsDetail : OnbPages.MemberManageActionDetail,
                                          {
                                            teamId,
                                            userId,
                                            actionId: action.id,
                                          }
                                        )
                                      }}
                                    />
                                  ))
                                ) : (
                                  <div>{noMatch}</div>
                                )}
                              </>
                            ) : (
                              <div>{noStandbyAction}</div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className={classes.noAction}>{noAction}</div>
                      )}
                    </>
                  ) : (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  )}
                </>
              ) : (
                <>
                  {!loadingActionsFeedback ? (
                    <>
                      {actionsFeedback.length ? (
                        <div className={classes.cards}>
                          {filteredFeedbacks(actionsFeedback).length ? (
                            filteredFeedbacks(actionsFeedback).map(({ action, ...feedback }) => (
                              <Card
                                key={feedback.id}
                                item={{
                                  id: feedback.id,
                                  importance: action?.importance || 0,
                                  mission: action?.mission || '',
                                  what: action?.what || '',
                                  why: action?.why || '',
                                  how: action?.how || '',
                                  category: feedback.category,
                                  period: new Date().getTime(),
                                  missionValues: {
                                    setupItemId: feedback.actionId,
                                    category: feedback.category as string,
                                    point: 0,
                                    value: feedback.value,
                                    comment: '',
                                    settedAt: feedback.settedAt,
                                  },
                                  anketValues: undefined,
                                  point: undefined,
                                  weekDate: undefined,
                                  valuesId: undefined,
                                }}
                                basePeriod={new Date().getTime()}
                                onActionDetail={() => {
                                  history.push(
                                    isMyActionList ? OnbPages.ActionsDetail : OnbPages.MemberManageActionDetail,
                                    {
                                      teamId,
                                      userId,
                                      actionId: feedback.actionId,
                                    }
                                  )
                                }}
                              />
                            ))
                          ) : (
                            <div>{noMatch}</div>
                          )}
                        </div>
                      ) : (
                        <div className={classes.noAction}>{noAction}</div>
                      )}
                    </>
                  ) : (
                    <div className={classes.loadingContainer}>
                      <CircularProgress />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    boxWrapper: {
      padding: '0 16px',
    },
    template: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      // backgroundColor: constants.COLOR_WHITE,
    },
    box: {
      flex: 1,
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    radar: {
      backgroundColor: constants.COLOR_WHITE,
      marginBottom: 27,
      boxSizing: 'border-box',
      padding: '19px 16px',
    },
    svg: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    preventClick: {
      pointerEvents: 'none',
    },
    contentsWrapper: {
      backgroundColor: constants.COLOR_WHITE2, // for tutorial highlight
    },
    spStartInfoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 24,
    },
    tabs: {
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      boxSizing: 'border-box',
      padding: 4,
      minHeight: 42,
      marginBottom: 14,
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 24,
      },
      '& .__tab': {
        fontSize: 14,
        fontWeight: 'bold',
        width: 'calc(50% - 4px)',
        minWidth: 'calc(50% - 4px)',
        padding: '4px 12px',
        minHeight: 34,
        borderRadius: 4,
        color: constants.COLOR_ONBOARDING_GRAY_DARK,
      },
      '& .__tabLeft': {
        marginRight: '4px',
      },
      '& .__tabRight': {
        marginLeft: '4px',
      },
      '& .__tabSelected': {
        backgroundColor: constants.COLOR_WHITE,
        color: constants.COLOR_ONBOARDING_MAIN,
      },
      '& .__textColorInherit': {
        opacity: 1,
      },
    },
    indicator: {
      backgroundColor: 'transparent',
    },

    pcTabsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 8,
    },
    pcTabs: {
      display: 'flex',
      '& .tab': {
        width: 100,
        height: 35,
        backgroundColor: constants.COLOR_WHITE,
        color: constants.COLOR_ONBOARDING_MAIN,
        fontSize: 12,
        fontWeight: 'bold',
      },
      '& .left': {
        borderTopLeftRadius: 4,
      },
      '& .right': {
        borderTopRightRadius: 4,
      },
      '& .inactive': {
        backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
        color: constants.COLOR_GRAY_DARK,
      },
    },
    startInfo: {
      margin: 0,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    lock: {
      position: 'relative',
      top: 3,
      marginRight: 6,
    },

    cards: {
      marginBottom: 16,
      padding: 16,
      backgroundColor: constants.COLOR_WHITE,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        backgroundColor: 'transparent',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
    cardDescription: {
      margin: '0 0 10px',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 10,
      fontWeight: 'bold',
    },

    noAction: {
      padding: '16px 24px',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 14,
    },

    loadingContainer: {
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    filter: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
    },

    search: {
      width: '100%',
      marginBottom: 24,
      boxSizing: 'border-box',
      paddingRight: 166,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 58,
      },
      '& .__input': {
        backgroundColor: '#FFFF',
        border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
        borderRadius: 8,
        padding: '7px 16px',
        display: 'flex',
        alignItems: 'center',
      },
    },

    input: {
      width: '100%',
      fontSize: 14,
      '& .MuiInputBase-input': {
        fontSize: 14,
      },
      '& .MuiInput-underline:before': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:after': {
        borderBottom: 0,
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
    },
  }),
  { name: 'Index' }
)

export default Index
