import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  currentIndex?: number
  totalNumber?: number | null
  presenterName?: string | null
  ownStyles?: React.CSSProperties
}

export const PresenterInfo: React.FC<Props> = ({ currentIndex, totalNumber, presenterName, ownStyles }) => {
  const classes = useStyles()
  if (!currentIndex || !totalNumber || !presenterName) return null

  return (
    <div className={classes.root} style={{ ...ownStyles }}>
      <span>
        {currentIndex}/{totalNumber}人目：
      </span>
      <span className={classes.presenterName}>{presenterName}さん</span>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: 8,
    },
    presenterName: {
      fontWeight: 'bold',
      fontSize: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
    },
  }),
  { name: 'PresenterInfo' }
)
