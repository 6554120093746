import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  children: React.ReactNode
}

export const AlertContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.iconContainer}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/alert.svg'} alt="" />
      </div>
      {children}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    height: 48,
    textAlign: 'center',
    marginBottom: 16,
  },
}))
