import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { QuestionHeadBar, SideBar } from './'

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
  header?: boolean
  questionHeadBar: {
    visible?: boolean
    props?: { step?: 1 | 2 | 2.5 | 3 | 4 | 5; isColorText?: boolean; textBold?: boolean }
  }
  circleProgress?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  addPaddingSide?: boolean
  addMarginBottom?: boolean
}

export const Container: React.FC<Props> = ({ questionHeadBar = { visible: false }, ...props }) => {
  const classes = useStyles({
    header: props.header,
    visibleQuestionHeadBar: questionHeadBar.visible,
    noPadding: props.noPadding,
    fullWidth: props.fullWidth,
    addPaddingSide: props.addPaddingSide,
    addMarginBottom: props.addMarginBottom,
  })

  return (
    <SideBar>
      {questionHeadBar.visible ? (
        <QuestionHeadBar
          step={questionHeadBar.props?.step || 1}
          isColorText={questionHeadBar.props?.isColorText || false}
          textBold={questionHeadBar.props?.textBold || false}
        />
      ) : (
        <></>
      )}
      <div className={classes.main}>
        <div className={classes.widthControl}>{props.children}</div>
      </div>
    </SideBar>
  )
}

type StyleProps = {
  header?: boolean
  visibleQuestionHeadBar?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  addPaddingSide?: boolean
  addMarginBottom?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    main: ({ header, visibleQuestionHeadBar, noPadding, addPaddingSide, addMarginBottom }) => ({
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      padding: header ? '72px 16px 120px' : noPadding ? 0 : visibleQuestionHeadBar ? '8px 0 120px' : '48px 0 120px',
      paddingLeft: addPaddingSide ? 16 : undefined,
      paddingRight: addPaddingSide ? 16 : undefined,
      marginBottom: addMarginBottom ? 50 : undefined,
      [theme.breakpoints.up('md')]: {
        padding: header ? '72px 70px 70px' : '80px 70px 70px',
        marginBottom: addMarginBottom ? 150 : undefined,
      },
    }),
    widthControl: ({ fullWidth }) => ({
      width: '100%',
      maxWidth: fullWidth ? undefined : constants.KICKOFF_MAX_WIDTH.sp,
      [theme.breakpoints.up('md')]: {
        maxWidth: constants.KICKOFF_MAX_WIDTH.pc,
      },
    }),
  }),
  { name: 'Container' }
)
