import React from 'react'
import { useParams } from 'react-router-dom'

import { ClassNameMap, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import { useStyles, cardContents } from 'pages/onboarding/components/cards/ActionCard'
import { DropDownActions } from 'pages/onboarding/components/dropdown-new/alt'
import { Loader } from 'pages/onboarding/components/loader'
import { callScreenModal } from 'pages/onboarding/components/modal/ScreenModal'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingAction, useOnboardingActionDelete } from 'pages/onboarding/hooks/action'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingAction } from 'utils/generated'

import { OnbPages } from 'assets/pages'

export const ActionManageDetailBloc = {
  useAdapter: () => {
    const { teamId, actionId, userId } = useParams<{teamId: string, userId: string, actionId: string}>()
    const { action } = useOnboardingAction(actionId, userId)
    
    return {
      teamId, 
      actionId,
      userId,
      action,
    }
  }
}

const Index: React.FC<WithStyles<typeof useStyles>> = (props) => {

  const {
    teamId, 
    actionId,
    userId,
    action,
  } = ActionManageDetailBloc.useAdapter()

  return (
    <>
      <HeadRow teamId={teamId} actionId={actionId} userId={userId} />
      <DetailBody classes={props.classes} action={action} />
    </>
  )
}

const HeadRow = ({ teamId, actionId, userId }: { teamId: string; actionId: string; userId: string }) => {
  const history = useOnbHistory()

  const deleteAction = useOnboardingActionDelete()

  const onClickEdit = async (addOrEdit: string) => {
    if (addOrEdit === 'edit') {
      history.push(OnbPages.ActionManageEdit, {teamId, actionId, userId})
    } else {
      const res = await deleteAction(actionId)
      if (res) {
        goBack()
        callScreenModal({ head: 'アクションが削除されました' })
      }
    }
  }

  const goBack = () => history.push(OnbPages.ActionManage, {teamId})

  return (
    <TitleWithBack
      title="アクション詳細"
      rightItem={
        <DropDownActions
          items={[
            { label: '削除', onClickItem: () => onClickEdit('delete') },
            { label: '編集', onClickItem: () => onClickEdit('edit') },
          ]}
          keyPrefix={'action-manage-action'}
          anchorIcon={'dots'}
        />
      }
    />
  )
}

const DetailBody = ({ classes, action }: { classes: ClassNameMap<string>; action: OnboardingAction | undefined }) => {
  const contents = cardContents(action)
  if (!contents.mission) return <Loader />
  return (
    <div className={classes.container}>
      <div className={classes.root} style={{ cursor: 'default' }}>
        <div className={classes.row}>
          <span className={classes.category}>{contents.category}</span>
          <span className={classes.timing}>{contents.timing}</span>
        </div>

        {/* Mission */}
        <div className={classes.row}>
          <span className={classes.boldText}>{contents.mission}</span>
        </div>

        <div className={classes.row}>
          <span className={classes.descText}>{contents.missionDesc}</span>
        </div>

        {/* Action */}
        <div className={classes.row}>
          <span className={classes.boldText}>アクション</span>
        </div>

        <div className={classes.row}>
          <span className={classes.descText}>{contents.actionDesc}</span>
        </div>

        {/* Target */}
        <div className={classes.row}>
          <span className={classes.boldText}>達成基準</span>
        </div>

        <div className={classes.row}>
          <span className={classes.descText}>{contents.actionTarget}</span>
        </div>
      </div>
    </div>
  )
}

export default withStyles(useStyles)(Index)
