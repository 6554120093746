import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'

import { Content } from '../../review/components/generic/Content'
import { ContentHead } from '../../review/components/generic/ContentHead'
import { ContentSection } from '../../review/components/generic/ContentSection'
import { ReviewTimer } from '../../review/components/generic/ReviewTimer'
import { IceBreakContext } from '../contexts/iceBreakContext'

export const Presentation: React.FC = () => {
  const classes = useStyles()
  const { iceBreakContents, setIsTimerEnd, teamMembers } = React.useContext(IceBreakContext)
  const {
    presentation: { memberCount, minute, selectedUserIds, startedAt },
    theme,
  } = iceBreakContents

  const selectedUser = React.useMemo(() => {
    if (selectedUserIds.length === 0) return undefined

    const currentId = selectedUserIds[selectedUserIds.length - 1]
    return teamMembers.find((member) => member.userId === currentId)
  }, [selectedUserIds, teamMembers])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <ContentHead
        title={`「${theme}」`}
        subtitle={`ランダムに発表者を指名します！${minute}分でお話下さい。`}
        steps={3}
        currentStep={3}
      />
      <ContentSection title={`${selectedUserIds.length}/${memberCount}人目`}>
        <Content>
          {selectedUser && (
            <div className={classes.flex}>
              <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/human.svg'} alt="" />
              <span className={classes.userName}>{selectedUser.fullName}さん</span>
            </div>
          )}
        </Content>
        <ReviewTimer from={startedAt} time={minute * 60} container setIsPresenEnd={setIsTimerEnd} />
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
      '@media (max-width: 632px)': {
        justifyContent: 'flex-start',
      },
    },
    userName: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  }),
  { name: 'Presentation' }
)
