import React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Waypoint } from 'react-waypoint'
import useOnClickOutside from 'use-onclickoutside'

import { Calendar } from './Calendar'

import { constants } from 'assets'

dayjs.locale('ja')
dayjs.extend(isSameOrAfter)

type Props = {
  name?: string
  placeholder?: string
  defaultDate?: dayjs.Dayjs | null
  onSelectDate?(selectedDate: dayjs.Dayjs): void
}

const isTodayOrAfter = (date: dayjs.Dayjs) => {
  return date.isSameOrAfter(dayjs().startOf('day'))
}

export const Datepicker: React.FC<Props> = ({
  name = 'date',
  placeholder = '日付を選択してください',
  defaultDate = null,
  onSelectDate = () => undefined,
}) => {
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(defaultDate ? defaultDate : null)
  const [showCalendar, setShowCalendar] = React.useState<boolean>(false)

  const [position, setPosition] = React.useState<string>('below') // 'below' | 'inside' | 'above'
  const showCalendarOnUpper = React.useMemo(() => position === 'below', [position])

  const ref = React.useRef(null)
  useOnClickOutside(ref, () => setShowCalendar(false))

  const inputValue = React.useMemo(() => {
    return selectedDate ? selectedDate.format('YYYY年 MM月 DD日 dddd') : ''
  }, [selectedDate])

  const handleSelectDate = React.useCallback(
    (date: dayjs.Dayjs) => {
      setSelectedDate(date)
      onSelectDate(date)
      setShowCalendar(false)
    },
    [setSelectedDate, onSelectDate, setShowCalendar]
  )

  return (
    <div className={`${showCalendar ? classes.active : ''}`}>
      <div className={`${classes.inner}`}>
        <Waypoint
          onPositionChange={(event) => setPosition(event.currentPosition)}
          bottomOffset={380} // input height + calendar height
        >
          <input
            readOnly
            name={name}
            className={classes.input}
            value={inputValue}
            type="text"
            placeholder={placeholder}
            onClick={() => setShowCalendar(true)}
          />
        </Waypoint>
        <img
          className={classes.icon}
          src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_calendar_input.svg'}
          alt=""
        />
        {showCalendar && (
          <div
            ref={ref}
            className={`${classes.calendarWrapper} ${
              showCalendarOnUpper ? classes.calendarUpper : classes.calendarLower
            }`}
          >
            <Calendar
              layout={'datepicker'}
              selectedDate={selectedDate}
              onSelectDate={handleSelectDate}
              isAvailableDate={isTodayOrAfter}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  active: {
    position: 'relative',
    zIndex: 1000,
  },
  inner: {
    position: 'relative',
  },
  input: {
    width: '100%',
    padding: '16px',
    border: `solid 1px ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    borderRadius: '8px',
    cursor: 'pointer',
    appearance: 'none',
    background: 'transparent',
    outline: 'none',

    '&:focus': {
      border: `solid 1px ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
  },
  icon: {
    position: 'absolute',
    top: '12px',
    right: '8px',
    pointerEvents: 'none',
  },
  calendarWrapper: {
    position: 'absolute',
    right: '0',
    padding: '12px 24px 24px',
    maxWidth: '350px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  calendarUpper: {
    top: '0',
    transform: 'translateY(-100%)',
  },
  calendarLower: {
    bottom: '0',
    transform: 'translateY(100%)',
  },
})
