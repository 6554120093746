import React from 'react'

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ButtonForGhost, UsefulModal, Font, Flexbox } from '../atoms'

import {} from '../../contexts/ghostTeamMember'
import * as constants from 'assets/constants'

type Props = {
  open: boolean
  firstLine: string
  secondLine?: string
  info?: string
  btnFirstLine: string
  btnSecondLine: string
  handleModalChange: () => void
  toHome: () => void
}

export const ModalConfirmForCommon: React.FC<Props> = ({
  open,
  firstLine,
  secondLine,
  info,
  btnFirstLine,
  btnSecondLine,
  handleModalChange,
  toHome,
}) => {
  const classes = useStyles()
  return (
    <>
      <UsefulModal open={open} handleModalChange={handleModalChange}>
        <img className={classes.img} src={process.env.PUBLIC_URL + '/assets/svg/exclamation.svg'} alt="注意" />
        <Typography className={classes.title} id="modal-modal-title" variant="h6" component="h2">
          {secondLine ? (
            <>
              {firstLine}
              <br />
              {secondLine}
            </>
          ) : (
            firstLine
          )}
        </Typography>
        {info && (
          <Font fontSize={14} ownStyles={{ marginTop: 24, lineHeight: 1.6 }}>
            {info}
          </Font>
        )}

        <Flexbox ownStyles={{ marginTop: 24, gap: 16 }} flexDirection="column">
          <ButtonForGhost buttonBodyColor="green" bodyText={btnFirstLine} onClick={toHome} isResizeBasic />
          <ButtonForGhost buttonBodyColor="white" bodyText={btnSecondLine} onClick={handleModalChange} isResizeBasic />
        </Flexbox>
      </UsefulModal>
    </>
  )
}

const useStyles = makeStyles(
  () => ({
    img: {
      width: 80,
      height: 80,
      verticalAlign: 'top',
    },
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 350,
      background: constants.COLOR_WHITE,
      borderRadius: 5,
      outline: 'none',
      boxSizing: 'border-box',
      padding: 24,
      textAlign: 'center',
    },
    title: {
      marginTop: 24,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
  { name: 'ModalConfirmForCommon' }
)
