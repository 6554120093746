import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { NoStyleButton, ButtonProps } from 'components/NoStyleButton'

import { constants } from 'assets'

export const Button = (props: ButtonProps) => {
  const classes = useStyles()

  return (
    <NoStyleButton
      className={`${classes.base} ${props.disabled ? classes.disabled : classes.active}`}
      type="button"
      {...props}
    >
      {props.children}
    </NoStyleButton>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    base: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 16px',
      minWidth: 160,
      height: 40,
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 20,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        minWidth: 0,
        flex: 1,
      },
    },
    disabled: {
      color: constants.COLOR_GRAY2,
      border: `1px solid ${constants.COLOR_GRAY2}`,
      cursor: 'default',
    },
    active: {
      color: constants.COLOR_MAIN_NEW,
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      cursor: 'pointer',

      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.7,
        },
      },
    },
  }),
  { name: 'Button' }
)
