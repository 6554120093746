import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Select, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import * as constants from 'assets/constants'

type Props = {
  name: string
  value: string
  onChange: () => void
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const FormTime: React.FC<Props & StyleProps> = ({ name, value, onChange, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  const options = () => {
    const items = [] as string[]
    for (const hour of [...Array(24).keys()]) {
      items.push(`${hour}:00`)
    }
    return items
  }

  return (
    <div className={classes.selectBoxRoot}>
      <Select
        variant="standard"
        name={name}
        value={value}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          classes: { paper: classes.muiPaperRoot },
        }}
      >
        {options()?.map((item) => (
          <MenuItem key={`menuItem-${item}`} value={item} classes={{ root: classes.muiListItemRoot }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  selectBoxRoot: ({ ownStyles }: StyleProps) => ({
    //for init
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    //
    // === for style ===
    //classes.selectBoxRoot
    width: 94,
    height: 48,
    borderRadius: 8,
    border: `2px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_100}`,
    color: constants.TEXT_GRAY_DARK,
    ...ownStyles,
    '& .MuiInputBase-root': {
      width: '100%',
      height: '100%',
    },
    '& .MuiSelect-select': {
      width: '100%',
      height: 'calc(100% - 12px)',
      padding: '8px 8px 0 16px',
      fontSize: 14,
      '&:focus': {
        background: constants.COLOR_WHITE,
      },
    },
    '& .MuiSelect-icon': {
      right: 8,
      fill: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  }),

  //for popOverPosition
  muiPaperRoot: {
    maxHeight: 200,
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  //for menuItems
  muiListItemRoot: {
    padding: '14px 15px 14px 37px',
    fontSize: 14,
    fontWeight: 'normal',
    '&.Mui-selected': {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      background: constants.COLOR_WHITE,
      padding: '14px 0 14px 13px',
      '&:before': {
        content: '" "',
        display: 'inline-block',
        height: '1rem',
        width: '1rem',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        marginRight: 8,
      },
    },
  },
}))
