import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  noRadius?: boolean
}

export const InputCard: React.FC<Props> = (props) => {
  const classes = useStyles({ noRadius: props.noRadius })

  return (
    <div className={classes.root} style={props.style}>
      {props.children}
    </div>
  )
}

type StyleProps = {
  noRadius?: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ noRadius }) => ({
    width: '100%',
    background: constants.COLOR_WHITE,
    borderRadius: noRadius ? 0 : 8,
    padding: '24px 16px',
    [theme.breakpoints.up('md')]: {
      padding: 40,
    },
  }),
}))
