import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import ArrowLeft from './assets/arrow-left.svg'
import * as constants from 'assets/constants'

interface Props {
  title: string
  rightItem?: JSX.Element
  containerTop?: number
  containerBottom?: number
  omitBack?: boolean
  styleRightItem?: React.CSSProperties
  spaceBetween?: boolean
}
type StyleProps = {
  styleRightItem?: { [k: string]: string | number | undefined }
}

export const TitleWithBack: React.FC<Props & StyleProps> = ({
  title,
  rightItem,
  containerTop,
  containerBottom,
  omitBack,
  styleRightItem,
  spaceBetween,
}) => {
  const classes = useStyles({ styleRightItem })
  const history = useHistory()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    (<div
      className={classes.container}
      style={{
        marginTop: containerTop ?? 21,
        marginBottom: containerBottom ?? 26,
      }}
    >
      {spaceBetween && !isSmDown ? (
        <div className={classes.titleContainer}>
          <div className={classes.actionWrapper}>
            <div className={classes.titleTrue}>{title}</div>
          </div>
          <div className={classes.rightWrapper}>
            <div style={{ ...styleRightItem }}>{rightItem}</div>
            {!omitBack ? (
              <div className={classes.toBack} onClick={() => history.goBack()}>
                <img src={ArrowLeft} alt="arrow-left" />
                <span>戻る</span>
              </div>
            ) : (
              (<div />) // just to place left item
            )}
          </div>
        </div>
      ) : (
        <div>
          {!omitBack ? (
            <div className={classes.toBack} onClick={() => history.goBack()}>
              <img src={ArrowLeft} alt="arrow-left" />
              <span>戻る</span>
            </div>
          ) : (
            (<div />) // just to place left item
          )}
          <div className={classes.title}>{title}</div>
          <div className={classes.rightItemWrapper}>
            <div className={classes.styleRightItem}>{rightItem}</div>
          </div>
        </div>
      )}
    </div>)
  );
}

const useStyles = makeStyles(
  {
    styleRightItem: ({ styleRightItem }: StyleProps) => ({
      ...styleRightItem,
    }),
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 16,
      height: '17px',
      lineHeight: '17px',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    rightItemWrapper: {
      left: '88%',
      top: -10,
      position: 'absolute',
    },
    titleTrue: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeight: 'bold',
    },
    rightWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    actionWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    toBack: {
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      margin: -5,
      padding: 5,
      cursor: 'pointer',
      '& img': {
        position: 'relative',
        top: 1,
      },
      '& span': {
        marginLeft: 7,
      },
    },

    title: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
  { name: 'TitleWithBack' }
)
