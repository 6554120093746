import React from 'react'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import { RouteComponentProps } from 'react-router'

import { HooksContext, ModalBuildingContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding, useManageTeamBuildingMember } from 'pages/teams/hooks'
import { queryGetTeamBuildingMembers } from 'pages/teams/hooks/graphql'
import { TeamBuildingStatus } from 'utils/generated'

import {
  AlertContainer,
  Button,
  ButtonContainer,
  Logo,
  QuestionContainer,
  Flow,
  InputCard,
  StartModal,
  IModal,
} from '../../components'

import { constants } from 'assets'

type Props = {
  onClickStart: () => void
} & RouteComponentProps<{ teamId: string }>

const MIN_START_NUM = 2

export const Created: React.FC<Props> = (props) => {
  const { match, onClickStart } = props
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const [displayFlow, setDisplayFlow] = React.useState(false)
  const [startModal, setStartModal] = React.useState<IModal>({ isOpen: false })

  const { currentTeam, teamBuilding, isLeader, isMember } = React.useContext(HooksContext)
  const { currentTeamBuildingMember, refreshTeamBuildingMember } = React.useContext(BuildingHooksContext)
  const { onModal, onClose } = React.useContext(ModalBuildingContext)

  const { updateTeamBuilding } = useManageTeamBuilding()
  const { updateTeamBuildingMember } = useManageTeamBuildingMember()

  const handleStart = async () => {
    alert('開始します')
    await updateTeamBuilding({
      id: match.params.teamId,
      status: TeamBuildingStatus.Q5, // Changed page transition by UI UX modification
      timeQ5: new Date().toISOString(), // Changed according to the above
    })
  }

  const handleOnClick = async () => {
    if ((isLeader || isMember) && isSmDown && !displayFlow) {
      setDisplayFlow(true)
      return
    }
    if (isLeader) {
      const updated = await updateTeamBuildingMember({ teamId: match.params.teamId })
      if (updated) {
        refreshTeamBuildingMember()
      }

      const response = await queryGetTeamBuildingMembers({
        teamId: match.params.teamId,
        limit: 500,
      })

      if (response && response.items.length >= MIN_START_NUM) {
        setStartModal({ isOpen: true, response })
      } else {
        onModal(
          <AlertContainer>
            <div
              style={{
                fontFamily: 'Hiragino Kaku Gothic Pro',
                fontWeight: 'bold',
                fontSize: 14,
                letterSpacing: '0.01em',
                lineHeight: '24px',
                textAlign: 'center',
                color: constants.COLOR_ONBOARDING_MAIN,
                marginBottom: 30,
                whiteSpace: 'pre-wrap',
              }}
            >
              2人以上集まってから開始してください
            </div>
            <Button full buttonType={'light'} onClick={onClose}>
              閉じる
            </Button>
          </AlertContainer>
        )
      }
    } else if (isMember) {
      if (!teamBuilding) {
        onModal(
          <AlertContainer>
            <div
              style={{
                fontFamily: 'Hiragino Kaku Gothic Pro',
                fontWeight: 'bold',
                fontSize: 14,
                letterSpacing: '0.01em',
                lineHeight: '24px',
                textAlign: 'center',
                color: constants.COLOR_ONBOARDING_MAIN,
                marginBottom: 30,
                whiteSpace: 'pre-wrap',
              }}
            >
              リーダーの準備が済んでから再度お試しください
            </div>
            <Button full buttonType={'light'} onClick={onClose}>
              閉じる
            </Button>
          </AlertContainer>
        )
        return
      }
      if (teamBuilding?.status === TeamBuildingStatus.Created) {
        const updated = await updateTeamBuildingMember({ teamId: match.params.teamId })
        if (updated) {
          refreshTeamBuildingMember()
        }
        onClickStart()
      } else {
        onModal(
          <AlertContainer>
            <div
              style={{
                fontFamily: 'Hiragino Kaku Gothic Pro',
                fontWeight: 'bold',
                fontSize: 14,
                letterSpacing: '0.01em',
                lineHeight: '24px',
                textAlign: 'center',
                color: constants.COLOR_ONBOARDING_MAIN,
                marginBottom: 30,
                whiteSpace: 'pre-wrap',
              }}
            >
              既にキックオフが始まっています。{'\n'}終了までお待ち下さい
            </div>
            <Button full buttonType={'light'} onClick={onClose}>
              閉じる
            </Button>
          </AlertContainer>
        )
      }
      return
    }
  }

  const startLabel = () => {
    if (isSmDown && !displayFlow) {
      return '次へ'
    } else if (isLeader) {
      return 'はじめる'
    } else {
      return '参加する'
    }
  }

  React.useEffect(() => {
    setDisplayFlow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmDown])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [displayFlow])

  return (
    <QuestionContainer addPaddingSide>
      {displayFlow ? (
        <div className={classes.flowWrapper}>
          <div className={classes.flowImgBox}>
            <img src={process.env.PUBLIC_URL + '/assets/svg/teams/logo_kickoff.svg'} alt="cocolabo-kickoff" />
          </div>
          <InputCard>
            <h1 className={classes.flowTitle}>キックオフの流れ説明</h1>
            <Flow isSpFlow />
          </InputCard>
        </div>
      ) : (
        <>
          {isSmDown && <Logo container />}
          <div className={classes.panel}>
            <h1 className={classes.heading}>{currentTeam?.name}</h1>
            <p className={classes.role}>
              あなたは<span>{isLeader ? 'リーダー' : 'メンバー'}</span>です
            </p>
            <p className={classes.text}>
              {isLeader
                ? '他のメンバーはQ1～Q4の問いに一つずつ答えていきますが、あなたは自身の回答と全体の進行を行い、皆で意見交換して進めてください。'
                : '全体の進行はリーダーが進めますが、Q1～Q4の問いに一つずつ答えて、周りと意見交換しましょう。'}
            </p>
          </div>
          <div className={classNames([classes.panel, classes.endPanel])}>
            <p className={classes.text}>
              このツールは、チームの最終日をイメージし、チームの理想の状態やそこに到達するためのアクションをチームメンバー全員で対話することを目的としたツールです。
              <br />
              <br />
              キックオフミーティングなど期の初めやチーム発足時など初期段階での利用をお勧めします。
            </p>
            <p className={classes.textSmall}>キックオフは練習などでリセットして繰り返し行うことができます。</p>
          </div>
          {isLeader && (
            <div className={classes.panelBelow}>
              <p className={classes.text}>先に「はじめる」のボタンを押してもらうよう、メンバーに共有してください。</p>
            </div>
          )}
        </>
      )}
      <ButtonContainer
        fixed
        marginTopPcLayout={100}
        buttons={[
          <Button
            key="created-1"
            onClick={() => handleOnClick()}
            disabled={
              !(
                (isLeader || isMember) &&
                (!teamBuilding?.status || teamBuilding?.status === TeamBuildingStatus.Created)
              ) ||
              (!isLeader && !!currentTeamBuildingMember)
            }
          >
            {(() => {
              if (isLeader) {
                return startLabel()
              }
              if (isMember) {
                if (currentTeamBuildingMember) {
                  return '待機中...'
                } else {
                  return startLabel()
                }
              }
              return '参加できません'
            })()}
          </Button>,
        ]}
      />

      <StartModal state={{ startModal, setStartModal }} handleStart={handleStart} />
    </QuestionContainer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  panel: {
    marginTop: 55,
    padding: '24px 16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      padding: 40,
    },
  },
  endPanel: {
    marginTop: 16,
    [theme.breakpoints.up('md')]: {
      marginTop: 24,
    },
  },
  panelBelow: {
    marginTop: 16,
  },
  heading: {
    margin: 0,
    textAlign: 'center',
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '14px',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
    },
  },
  role: {
    margin: '10px 0 24px',
    textAlign: 'center',
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '14px',
    '& span': {
      fontWeight: 'bold',
    },
  },
  text: {
    margin: 0,
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '14px',
    lineHeight: '24px',
  },
  textSmall: {
    margin: '20px 0 0',
    color: constants.COLOR_TEAMBUILDING_NEUTRAL_400,
    fontSize: '12px',
    lineHeight: '20px',
  },

  flowWrapper: {
    marginTop: 21,
    position: 'relative',
  },
  flowImgBox: {
    width: 140,
    position: 'absolute',
    top: -50,
    left: 0,
    '& img': {
      width: '100%',
      verticalAlign: 'top',
    },
  },
  flowTitle: {
    textAlign: 'center',
    margin: '0 0 26px',
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: 14,
    fontWeight: 'bold',
  },
}))
