import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import CheckIcon from '../../assets/check.svg'

import { Steps } from './Steps'

type Props = {
  title: string
  subtitle: string
  steps?: number
  currentStep?: number
  complete?: boolean
}

export const ContentHead: React.FC<Props> = ({ title, subtitle, steps, currentStep, complete }) => {
  const classes = useStyles()

  return (
    <div className={classes.head}>
      {steps && currentStep && <Steps steps={steps} currentStep={currentStep} />}
      <div className={classes.flex}>
        {complete && <img className={classes.check} alt="check" src={CheckIcon} />}
        <div className={classes.titleSection}>
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  head: {
    marginBottom: 32,
  },
  flex: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    '@media (max-width: 632px)': {
      flexDirection: 'column',
    },
  },
  check: {
    height: 40,
    '@media (max-width: 632px)': {
      height: 80,
    },
  },
  titleSection: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    '@media (max-width: 632px)': {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  title: {
    color: '#00838C',
    fontSize: 22,
    fontWeight: 'bold',
    whiteSpace: 'break-spaces',
  },
  subtitle: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
}))
