import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Switch } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../assets/constants'
import updateAccount from '../../../services/api/updateAccount'

import { connector, ContainerProps } from './index.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  const [checked, setCehcked] = React.useState<number>()

  const onChangeEmailNotification = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist()

      setCehcked(Number(e.target.checked) as 0 | 1)
      updateAccount({
        emailNotifySetup: Number(e.target.checked) as 0 | 1,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateAccount]
  )

  const handleStartTop = () => {
    props.history.push(constants.PAGE_TOP)
    window.scroll(0, 0)
  }

  React.useEffect(() => {
    if (props.user.fetched) {
      setCehcked(props.user.emailNotifySetup as 0 | 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user.fetched])
  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.fetched && true && (
          <>
            <div className="__container">
              <div className="__title">
                <h1>メール通知設定</h1>
              </div>

              <div className="__content">
                <div className="__inner">
                  <p className="__txt __flex">Cocolabo運営事務局からのメール通知の設定を変更できます。</p>
                </div>
                <div className="__setup">
                  <div className="__inner">
                    <span className="__sub_title">Cocolabo運営事務局からのメール</span>
                    {checked !== null && (
                      <Switch
                        checked={Boolean(checked)}
                        onChange={onChangeEmailNotification}
                        name={'emailNotifySetup'}
                        color={'primary'}
                      />
                    )}
                    <div className="__txt">{checked ? <span>受け取る</span> : <span>受け取らない</span>}</div>
                  </div>
                </div>
                <div className="__inner">
                  <p className="__flex">
                    ＊各ツールのメール通知設定の変更については、各ツールの画面内にある通知設定から変更をお願いします。
                  </p>
                </div>
                <div className="__link">
                  <p className="__txt" onClick={handleStartTop}>
                    TOPページに戻る
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      backgroundColor: constants.COLOR_GRAY_LIGHT_DARK,
      padding: '32px 8px 5px',
      fontSize: 14,
      height: 'calc(100vh - 37px)',

      '& .__container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: constants.BREAKPOINT_MEDIUM,
        },
        '& .__title': {
          textAlign: 'center',
        },

        '& .__flex': {
          display: 'flex',
          justifyContent: 'center',
        },

        '& .__content': {
          backgroundColor: constants.COLOR_WHITE,
          padding: '40px 0',
          boxShadow: '0 1px 3px 0 rgba(21, 27, 38, 0.15)',
          marginBottom: 300,
          '& .__inner': {
            boxSizing: 'border-box',
            padding: '0 20px',
          },
          '& .__setup': {
            margin: '40px 0',
            borderTop: `2px solid ${constants.COLOR_GRAY_LIGHT}`,
            borderBottom: `2px solid ${constants.COLOR_GRAY_LIGHT}`,
            boxSizing: 'border-box',
            padding: '20px 0',
            '& .__inner': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 32,
              boxSizing: 'border-box',
              padding: '0 20px',
              '& .__sub_title': {
                marginRight: 16,
              },
              '& .__checked': {
                background: constants.COLOR_MAIN,
              },
              '& .__txt': {
                minWidth: 88,
              },
            },
          },
          '& .__link': {
            textAlign: 'center',
            '& .__txt': {
              display: 'inline-block',
              color: constants.COLOR_MAIN,
              borderBottom: `1px solid ${constants.COLOR_MAIN}`,
              cursor: 'pointer',
            },
          },
        },
      },
    },
  })

export default withStyles(useStyles)(connector(Index))
