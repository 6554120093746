import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import { Flexbox } from '../atoms'

type Props = {
  numerator: number
  denominator: number
}

export const CircularProgressCount: React.FC<Props> = ({ numerator, denominator }) => {
  const angle = (360 / denominator) * numerator
  const classes = useStyles({ angle })

  return (
    <div className={classes.circle}>
      <Flexbox
        ownStyles={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
          width: 29,
          height: 29,
          backgroundColor: constGhost.COLOR_WHITE,
          borderRadius: '50%',
          fontSize: 12,
          fontWeight: 'bold',
          zIndex: 4,
        }}
      >
        <div>
          <span className={classes.numerator}>{numerator}</span>
          <span>{`/${denominator}`}</span>
        </div>
      </Flexbox>
    </div>
  )
}

type StyleProps = {
  angle: number
}

const useStyles = makeStyles<Theme, StyleProps>(
  () => ({
    circle: ({ angle }) => ({
      position: 'relative',
      width: 40,
      height: 40,
      background: constGhost.COLOR_MAIN_NEW,
      borderRadius: '50%',
      textAlign: 'center',
      overflow: 'hidden',
      zIndex: 1,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: -20,
        width: 40,
        height: 40,
        display: 'block',
        background: constGhost.COLOR_LIGHTGRAY3,
        transformOrigin: 'right 20px',
        transform: angle > 180 ? `rotate(${angle - 180}deg)` : 'none',
        zIndex: 2,
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 20,
        width: 40,
        height: 40,
        display: 'block',
        background: angle > 180 ? constGhost.COLOR_MAIN_NEW : constGhost.COLOR_LIGHTGRAY3,
        transformOrigin: 'left 20px',
        transform: angle > 180 ? 'none' : `rotate(${angle}deg)`,
        zIndex: 3,
      },
    }),
    numerator: {
      color: constGhost.COLOR_MAIN_NEW,
      fontSize: 14,
    },
  }),
  { name: 'CircularProgressCount' }
)
