import React from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { WhiteGhostImg } from 'pages/ghost/components/modules'
import {
  LoaderPatternsMessage,
  LoaderPatternsMessageSP,
  LoaderPatterns,
  ScreenLoaderManager,
} from 'pages/ghost/hooks/loader'

import {
  SelectLeader,
  Start,
  SelectGhost,
  Report,
  ReportResult,
  Research,
  ResearchResult,
  Complete2Result,
  Like,
  LikeResult,
  Busy,
  Default,
} from './components/Inner'

export const SyncIndicator = ({ loadingPattern: lp }: { loadingPattern: ScreenLoaderManager['loadingPattern'] }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  if (lp === 'idle') return <></>
  return (
    <div className={classes.root}>
      <SyncIndicatorInner lp={lp} message={isSmDown ? LoaderPatternsMessageSP[lp] : LoaderPatternsMessage[lp]} />
      <div className={classes.ghost}>
        <WhiteGhostImg ownStyles={{ width: 165 }} />
      </div>
    </div>
  )
}

const SyncIndicatorInner = ({ lp, message }: { lp: LoaderPatterns; message: string }) => {
  switch (lp) {
    case 'selectLeader':
      return <SelectLeader message={message} />
    case 'start':
      return <Start message={message} />
    case 'selectGhost':
      return <SelectGhost message={message} />
    case 'report':
      return <Report message={message} />
    case 'report2complete':
      return <ReportResult message={message} />
    case 'complete2Result':
      return <Complete2Result message={message} />
    case 'research':
      return <Research message={message} />
    case 'researchResult':
      return <ResearchResult message={message} />
    case 'like':
      return <Like message={message} />
    case 'likeResult':
      return <LikeResult message={message} />
    case 'busy':
      return <Busy message={message} />
    default:
      return <Default message={message} />
  }
}

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 3000,
    width: '100vw',
    height: '100vh',
    opacity: 0.98,
    backgroundColor: constGhost.COLOR_LIGHTGRAY2,
    color: constGhost.COLOR_BLACK3,
    fontSize: 14,
    position: 'fixed',
    top: 0,
    left: 0,
    textAlign: 'center',
  },
  message: {
    whiteSpace: 'pre-line',
    lineHeight: 1.8,
  },
  ghost: {
    position: 'fixed',
    width: '100%',
    bottom: 0,

    animationDuration: '3s',
    'animation-name': '$appearFromBottom',
    'animation-iteration-count': 'infinite',
    'animation-direction': 'alternate',
    'animation-timing-function': 'ease-in-out',
    '@global': {
      '@keyframes appearFromBottom': {
        '0%': {
          bottom: '-100px',
        },
        '100%': {
          bottom: '0',
        },
      },
    },
  },
}))
