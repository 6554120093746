import * as React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ButtonRenewal } from 'components/ButtonRenewal'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import { FileBox } from './components/FileBox'

import { downloadFiles } from './assets/documents.data'
import * as constants from 'assets/constants'

type Props = {}
const breakpoint = 700

export const CompletePage: React.FC<Props> = () => {
  const classes = useStyles()
  const isMediaQueryUp = useMediaQuery(useTheme().breakpoints.up(breakpoint))

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.completeRoot}>
      <div className={classes.topContainer}>
        <h1>
          ダウンロード
          <br style={isMediaQueryUp ? { display: 'none' } : {}} />
          ありがとうございます！
        </h1>
        <p>
          ご登録いただいたメールアドレス宛に資料のダウンロードリンクをお送りしました。
          <br />
          届くまで数分かかる場合がございますので、しばらくお待ちください。
          <br />
          Cocolaboではそのほかにも多くの課題を解決するために資料をご用意しています。
          <br />
          あなたに合った資料を見つけてダウンロードしてみてください。
        </p>
      </div>
      <ButtonRenewal
        maxWidth={'none'}
        maxWidthSP={'none'}
        style={
          isMediaQueryUp
            ? { width: 200, height: 50, display: 'block', margin: '32px auto 0 ' }
            : { width: 200, height: 50, display: 'block', margin: '48px auto 0' }
        }
        onClick={() => (window.location.href = constants.PAGE_DOCUMENTS)}
      >
        お役立ち資料一覧
      </ButtonRenewal>
      <div className={classes.documentBox}>
        {downloadFiles
          .filter(
            (files) =>
              files.title === '新入社員の離職アラートを察知するオンボーディングとは' ||
              files.title === '新入社員の主体性を高める人材育成' ||
              files.title === 'チームの崩壊を防ぐたった1つの方法は？'
          )
          .map((files) => (
            <div key={`documents-${files.title}`} className={`${classes.itemWrapper}`}>
              <FileBox imgURL={files.imgURL} title={files.title} detail={files.detail} link={files.link} />
            </div>
          ))}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  completeRoot: {
    padding: '8px 7% 10px',
    maxWidth: '100%',
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    [theme.breakpoints.up(constTop.breakPointsPC + 242)]: {
      padding: `0 calc((100% - 1248px)/2) 120px`,
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      fontSize: 32,
      fontWeight: 'bold',
      margin: '100px 0 0',
      color: constants.COLOR_MAIN_NEW,
      [theme.breakpoints.down(breakpoint)]: {
        fontSize: 20,
        textAlign: 'center',
      },
    },
    '& p': {
      margin: '28px 0 0',
      textAlign: 'center',
      lineHeight: 1.8,
      fontSize: 14,
      [theme.breakpoints.down(breakpoint)]: {
        fontSize: 13,
        margin: '20px 0 0',
      },
    },
  },
  documentBox: {
    display: 'grid',
    gap: '24px 30px',
    width: '100%',
    margin: '64px 0 157px',
    gridTemplateColumns: ' repeat(auto-fill, minmax(300px, 1fr))',
    [theme.breakpoints.down(breakpoint)]: {
      margin: '80px 0 100px',
    },
  },
  img: {
    aspectRatio: '19 / 10',
    width: '48%',
    [theme.breakpoints.down(breakpoint)]: {
      width: '100%',
    },
  },
  itemWrapper: {},
  textWrapper: {},
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  detail: {
    fontSize: 13,
    margin: '24px 0 0 0',
  },
}))
