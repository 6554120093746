import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { OnboardingPostLike, OnboardingPostCommentLike, OnboardingPostSecondCommentLike } from 'utils/generated'

import NoAvatarSvg from './assets/noAvatar.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


type Props = {
  teamId: string
  postId: string
  likes: OnboardingPostLike[] | OnboardingPostCommentLike[] | OnboardingPostSecondCommentLike[]
}

export const LikeMembers = ({ teamId, postId, likes }: Props) => {
  const classes = useStyles()
  const history = useOnbHistory()

  return (
    <div className={classes.container}>
      {likes.length > 0 ? (
        <>
          {likes.map((postLike, index) => (
            <div
              key={`${postId}-likes-${index}`}
              className={classes.userContainer}
              onClick={() => {
                history.push(OnbPages.TeamMember, { teamId, userId: postLike.teamMember?.userId })
              }}
            >
              <div className={classes.userAvatar}>
                {postLike.teamMember?.imageUrl ? (
                  <img src={postLike.teamMember.imageUrl} alt={'avatar'} />
                ) : (
                  <img src={NoAvatarSvg} alt={'no-avatar'} />
                )}
              </div>
              <div className={classes.userProfiles}>
                <div className={classes.userName}>{postLike.teamMember?.nickname || '名無し'}</div>
                {postLike.teamMember?.position ? (
                  <div className={classes.userPosition}>{postLike.teamMember.position}</div>
                ) : (
                  <></>
                )}
                {postLike.teamMember?.role ? (
                  <div className={classes.userRoleWrapper}>
                    <div className={classes.userRole}>{teamMemberRoleLabel(postLike.teamMember.role)}</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '51px',
      backgroundColor: constants.COLOR_WHITE,
      margin: '24px 16px 0',
      padding: '24px 16px 40px',
    },
    userContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
    },
    userAvatar: {
      marginRight: 16,
      '& img': {
        width: 60,
        height: 60,
        borderRadius: 30,
        objectFit: 'cover',
        verticalAlign: 'top',
      },
    },
    userProfiles: {
      flex: 1,
      lineHeight: 1,
    },
    userName: {
      marginTop: 3,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 18,
      fontWeight: 'bold',
    },
    userPosition: {
      marginTop: 8,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      fontSize: 10,
    },
    userRoleWrapper: {
      display: 'inline-block',
      marginTop: 9,
    },
    userRole: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      paddingLeft: 8,
      paddingRight: 8,
      color: constants.COLOR_ONBOARDING_MAIN,
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      height: '20px',
      fontWeight: 'bold',
    },
  }),
  { name: 'LikeMembers' }
)
