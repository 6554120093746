import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  children: React.ReactNode
}

export const InputCardMemo: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.children}</div>
}

const useStyles = makeStyles({
  root: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    lineHeight: '24px',
    color: constants.COLOR_GRAY_DARK,
    whiteSpace: 'pre-wrap',
  },
})
