import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'

import checked from '../../assets/svg/checked.svg'
import { DropdownItem, Font, Imgcard } from '../atoms'

type Props = {
  dataArray: Array<string[]>
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
  handleVisible: () => void
  visible: boolean
  ownStyles?: React.CSSProperties
  selectedResearch: {
    hstIdx: number
    rptIdx: number
  }
}

export const GhostDropdownList: React.FC<Props> = (props) => {
  const classes = useStyles()

  if (!props.dataArray || props.dataArray.length === 0) return <></>

  return (
    <div
      className={classNames([classes.ghostDropdownListRoot, props.visible ? '' : 'invisible'])}
      style={props.ownStyles}
    >
      {props.dataArray.map((items, hstIdx) =>
        items.map((item, rptIdx) => (
          <DropdownItem
            key={`${item}-${hstIdx}-${rptIdx}`}
            onClick={(event) => props.onClick(event)}
            handleVisible={() => props.handleVisible()}
            hstIdx={hstIdx}
            rptIdx={rptIdx}
            ownStyles={{ display: 'flex', alignItems: 'center', paddingLeft: 37, position: 'relative' }}
          >
            {hstIdx === props.selectedResearch.hstIdx && rptIdx === props.selectedResearch.rptIdx ? (
              <>
                <Imgcard src={checked} alt="checked" ownWrapStyles={{ position: 'absolute', top: 16, left: 14 }} />
                <Font fontSize={14} color={constGhost.COLOR_MAIN_NEW}>
                  {item}
                </Font>
              </>
            ) : (
              item
            )}
          </DropdownItem>
        ))
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    ghostDropdownListRoot: {
      position: 'absolute',
      width: '100%',
      top: 44,
      backgroundColor: constGhost.COLOR_WHITE,
      border: `1px solid ${constGhost.COLOR_BORDERLIGHTGRAY}`,
      borderRadius: 8,
      zIndex: 1000,
      boxShadow: `0px 1px 3px 0px ${constGhost.COLOR_BLACK}1E`,
      overflowY: 'scroll',
      maxHeight: 200,

      [theme.breakpoints.up('md')]: {
        maxHeight: 300,
      },
      '&.invisible': {
        display: 'none',
      },
    },
  }),
  { name: 'GhostDropdownList' }
)
