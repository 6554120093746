import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { Controller } from 'react-hook-form'

import { SelectBox } from '../../buildings/components'
import { Content } from '../../review/components/generic/Content'
import { ContentHead } from '../../review/components/generic/ContentHead'
import { ContentSection } from '../../review/components/generic/ContentSection'
import { Landing } from '../../review/components/generic/Landing'
import { IceBreakContext } from '../contexts/iceBreakContext'

export const PreparePresentation: React.FC = () => {
  const classes = useStyles()
  const { isLeader, iceBreakContents, control, memberCount, minute } = React.useContext(IceBreakContext)

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!isLeader) return <Landing title="アイスブレイク" infoMsg="テーマ設定が完了するまでお待ちください" />

  return (
    <div className={classes.root}>
      <ContentHead
        title="アイスブレイク"
        subtitle="発表する「人数」と「発表時間」を選択してください。"
        steps={3}
        currentStep={2}
      />
      <ContentSection split>
        <Content>
          <div className={classes.inputWrap}>
            <div className={classes.inputTitle}>発表する人数</div>
            <div className={classes.input}>
              <Controller
                name="memberCount"
                control={control}
                defaultValue={1}
                value={memberCount}
                render={({ onChange, value }) => (
                  <>
                    <div className={classes.selectBox}>
                      <SelectBox
                        maxNumber={iceBreakContents.activeUserIds.length}
                        value={value}
                        onChange={onChange}
                        hasThinBorder
                      />
                      <span className={classes.unit}>人</span>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        </Content>
        <Content>
          <div className={classes.inputWrap}>
            <div className={classes.inputTitle}>１人当たりの発表時間</div>
            <div className={classes.input}>
              <Controller
                name="minute"
                control={control}
                defaultValue={1}
                value={minute}
                render={({ onChange, value }) => (
                  <>
                    <div className={classes.selectBox}>
                      <SelectBox maxNumber={60} value={value} onChange={onChange} hasThinBorder />
                      <span className={classes.unit}>分</span>
                    </div>
                  </>
                )}
              />
            </div>
          </div>
        </Content>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      width: '100%',
    },
    inputWrap: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    inputTitle: {
      fontWeight: 'bold',
    },
    input: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    selectBox: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      '& > div': {
        width: 128,
      },
    },
    unit: {
      fontSize: 14,
    },
  }),
  { name: 'PreparePresentation' }
)
