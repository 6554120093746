import * as React from 'react'

import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CloseIcon from '@mui/icons-material/Close'
import { Modal, Fade, IconButton } from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { Point } from 'utils/generated'

import { connector, ContainerProps } from './Point.container'
import SocialsSnackbar from './SocialsSnackbar'

interface IOwnProps {
  week: number
}

type Props = IOwnProps & ContainerProps

const Index: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [point, setPoint] = React.useState<Point | undefined>()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    if (props.setup.currentSetupStartedAt) {
      props.onMount(props.week, props.setup.currentSetupStartedAt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.week, props.setup.currentSetupStartedAt])

  React.useEffect(() => {
    if (
      point?.total &&
      point.description !== props.setup.point?.description &&
      props.setup.point?.total &&
      point.total < props.setup.point?.total
    ) {
      handleOpen()
    }
    setPoint(props.setup.point)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [point, props.setup.point])

  return (
    <div className="__body">
      <div className="__point">
        <span>{props.setup.point ? props.setup.point.total : 0}</span> P
      </div>
      <div className="__title">
        <span style={{ alignItems: 'center', display: 'flex' }}>
          称号
          <ArrowRightIcon />
        </span>
        <div>{props.setup.point ? props.setup.point.description : null}</div>
      </div>
      <div>
        <Modal
          aria-labelledby="new-description"
          aria-describedby="new-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <div className="__paper">
              <img
                className="__img"
                src={process.env.PUBLIC_URL + '/assets/svg/setup/new_title.svg'}
                alt={'new-description'}
              />
              <div className="__snackbar">
                <SocialsSnackbar />
              </div>
              <div className="__label">{props.setup.point?.description}</div>
              <IconButton aria-label="close" className="__close" onClick={handleClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 150,
      padding: 0,
      '& .__paper': {
        position: 'absolute',
        margin: 0,
        padding: 0,
        outline: 0,
        '& .__img': {
          margin: 0,
          padding: 0,
          width: '100%',
        },
        '& .__snackbar': {
          position: 'absolute',
          width: '100%',
          top: '50%',
          left: '65%',
          transform: 'translateX(-50%) translateY(-295%)',
        },
        '& .__label': {
          position: 'absolute',
          width: '80%',
          textAlign: 'center',
          fontSize: '24px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '22px',
          },
          fontWeight: 'bold',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-12%)',
        },
        '& .__close': {
          position: 'absolute',
          top: 10,
          right: 15,
        },
        '@media (max-width: 360px)': {
          '& .__snackbar': {
            transform: 'translateX(-50%) translateY(-270%)',
          },
          '& .__close': {
            top: 6,
            right: 9,
          },
        },
      },
    },
  })
)

export default connector(Index)
