import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  name?: string
  value?: string | string[] | number
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  style?: React.CSSProperties
}

export const InputTextarea = React.forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const classes = useStyles()
  const { name = '', value, onChange, style } = props

  return (
    <textarea
      rows={10}
      cols={60}
      className={classes.root}
      placeholder={props.placeholder || '回答を入力する'}
      name={name}
      value={value}
      onChange={onChange}
      ref={ref}
      style={style}
    />
  )
})

const useStyles = makeStyles(
  {
    root: {
      display: 'block',
      resize: 'none',
      width: '100%',
      height: 200,
      borderColor: '#E9E9E9',
      borderRadius: 4,
      padding: 16,
      fontSize: 14,

      '&:focus-visible': {
        outline: `${constants.COLOR_ONBOARDING_MAIN} solid 1px`,
      },
      '&::placeholder': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
        opacity: 1,
      },
    },
  },
  { name: 'InputTextarea' }
)
