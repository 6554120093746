import React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { MenuItem, Select, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Controller, useForm } from 'react-hook-form'

import { NoStyleButton } from 'components/NoStyleButton'
import { LoadingCircular } from 'pages/teams/components'
import { useManageTeam } from 'pages/teams/hooks'
import { TeamStatusUsage } from 'utils/generated'
import { getTeamStatusUsageLabel } from 'utils/labels'

import CheckIcon from '../assets/checkedIcon.svg'
import { constants } from 'assets'

type CheckedSubmitData = {
  hasWatchedTeamIds: boolean
  selectCount: number
  watchedTeamIds: string[] | string | boolean
  setStatusChangeList: React.Dispatch<React.SetStateAction<boolean>>
  setIsListUpload: React.Dispatch<React.SetStateAction<boolean>>
  setErrors: React.Dispatch<React.SetStateAction<string[] | null>>
}

type IInput = {
  [key: string]: string
  statusUsage: TeamStatusUsage
}

export const StatusManagement: React.FC<CheckedSubmitData> = ({
  hasWatchedTeamIds,
  selectCount,
  watchedTeamIds,
  setStatusChangeList,
  setIsListUpload,
  setErrors,
}) => {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid },
  } = useForm<CheckedSubmitData>({
    mode: 'onChange',
  })

  const { updateTeam } = useManageTeam()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const onSubmit = async (input: IInput) => {
    setIsLoading(true)
    const errors: string[] = []

    if (Array.isArray(watchedTeamIds) || typeof watchedTeamIds === 'string') {
      const teamIds = typeof watchedTeamIds === 'string' ? [watchedTeamIds] : watchedTeamIds
      for (const teamId of teamIds) {
        try {
          await updateTeam({
            id: teamId,
            statusUsage: input.statusUsage,
          })
        } catch (e) {
          errors.push(teamId)
        }
      }
    }

    if (errors.length > 0) {
      setErrors(errors)
    } else {
      setErrors(null)
    }

    setStatusChangeList(false)
    setIsListUpload(true)
    setIsLoading(false)
    reset()
  }

  return (
    <div className={classes.statusContainer}>
      <div className={`${classes.countText} ${hasWatchedTeamIds ? classes.countTextSelected : ''}`}>
        <span>選択したチーム</span>
        {hasWatchedTeamIds && <span>を{selectCount}件</span>}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formArea}>
        <Controller
          name={'statusUsage'}
          control={control}
          defaultValue={'noSelect'}
          render={({ onChange, value }) => (
            <div>
              <Select
                className={`${classes.select} ${!hasWatchedTeamIds ? classes.disabled : ''} ${
                  value === 'noSelect' ? classes.placeholder : ''
                }`}
                variant={'outlined'}
                value={value}
                onChange={onChange}
                IconComponent={ExpandMoreIcon}
                disabled={!hasWatchedTeamIds}
                MenuProps={{
                  anchorOrigin: { vertical: 'top', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  classes: { paper: classes.muiPaperRoot },
                }}
              >
                <MenuItem value="noSelect" disabled className={classes.label}>
                  ステータス選択
                </MenuItem>
                {[TeamStatusUsage.InUse, TeamStatusUsage.NonUse].map((value, index) => (
                  <MenuItem key={`team-status-usage-selector-${index}`} value={value} className={classes.menuItem}>
                    {getTeamStatusUsageLabel(value)}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        />
        {isLoading ? (
          <LoadingCircular loading={isLoading} size={32} wrapperStyles={{ padding: 0 }} />
        ) : (
          <NoStyleButton
            className={`${classes.updateButton} ${
              !isLoading && isDirty && isValid && hasWatchedTeamIds ? classes.enabled : ''
            } ${isLoading ? classes.disabled : ''}`}
            type="submit"
            disabled={isLoading || !isDirty || !isValid || !hasWatchedTeamIds}
          >
            更新
          </NoStyleButton>
        )}
      </form>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    formArea: {
      display: 'flex',
      gap: 8,
    },
    countText: {
      fontSize: 12,
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
    },
    countTextSelected: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
    },
    select: {
      width: 132,
      height: 40,
      borderRadius: 8,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 12,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      '& .MuiSelect-root': {
        padding: 8,
        '&:focus': {
          background: constants.COLOR_WHITE,
        },
      },
      '& .MuiSvgIcon-root': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        right: 8,
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-root.Mui-disabled': {
        colors: constants.COLOR_TEAMBUILDING_RED,
      },
    },

    disabled: {
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500 + ' !important',
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_300,
      '& .MuiSvgIcon-root': {
        color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      },
    },

    placeholder: {
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
    },

    selectReadOnly: {
      backgroundColor: '#f5f5f5',
    },

    menuItem: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 12,
      padding: '14px 0 14px 37px',
      fontWeight: 'normal',
      '&.Mui-selected': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_WHITE,
        padding: '14px 0 14px 13px',
        '&:hover': {
          background: constants.COLOR_WHITE,
        },
        '&:before': {
          content: '" "',
          backgroundImage: `url(${CheckIcon})`,
          display: 'inline-block',
          height: 16,
          width: 16,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          marginRight: 8,
        },
      },
    },
    label: {
      padding: 8,
      fontSize: 12,
    },

    //for popOverPosition
    muiPaperRoot: {
      maxHeight: 200,
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    updateButton: {
      width: 80,
      height: 40,
      borderRadius: 20,
      color: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_300,
    },
    enabled: {
      color: constants.COLOR_TEAMBUILDING_WHITE,
      backgroundColor: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  }),
  { name: 'StatusManagement' }
)
