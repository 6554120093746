import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  title: React.ReactNode
}

export const QuestionSubTitle: React.FC<Props> = (props) => {
  const classes = useStyles()

  return <div className={classes.root}>{props.title}</div>
}

const useStyles = makeStyles({
  root: {
    color: constants.TEXT_GRAY_DARK,
    fontFamily: 'Hiragino Kaku Gothic Pro',
    fontSize: 14,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold',
    paddingTop: 20,
  },
})
