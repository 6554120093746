import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { ContentHead } from './ContentHead'
import { ContentSection } from './ContentSection'

export const Landing: React.FC<{ title: string; hidePeople?: boolean; infoMsg?: string }> = ({
  title,
  hidePeople,
  infoMsg,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle="リーダーの進行をお待ちください" />
      {infoMsg && <p className={classes.infoMsg}>{infoMsg}</p>}
      <ContentSection>
        <div className={classes.wrap}>
          {!hidePeople && (
            <div
              style={{
                height: 138,
              }}
            >
              <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/waitingPeople.svg'} alt="" />
            </div>
          )}
        </div>
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  infoMsg: {
    margin: 0,
    paddingTop: 32,
    fontSize: 12,
    textAlign: 'center',
  },
  wrap: { padding: '64px 0 32px', width: '100%', textAlign: 'center' },
}))
