import React, { useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Loader } from 'pages/onboarding/components/loader'
import Timeline from 'pages/onboarding/pages/timeline'
import { OnboardingAction, OnboardingActionCategory } from 'utils/generated'

import ActionList from '../../actions-list'
import ActionDetail from '../../actions-list-detail'
import DailyCheck from '../../daily-check'
import { Complete } from '../components/Complete'
import { unmountTooltip } from '../logics/hooks'
import { RoleTutorialProps } from '../Tutorial'

import { MemberTimelinePostsDummy, actionsFeedbackDummy } from '../assets/dummy'
import { callTx } from '../assets/text'

// Simplified useOnboardingAction,
// it depends on teamId & userId so that component won't lose the first Action till the end of the Tutorial
const useActionsForTutorial = (teamId: string, userId: string) => {
  const [actions, setActions] = useState<OnboardingAction[]>()
  const [actionForTutorial, setAFT] = useState<OnboardingAction>()

  const actionsFeedbackForTutorial = actionsFeedbackDummy(actionForTutorial)

  useEffect(() => {
    async function getActions() {
      const payload = {
        id: 'default',
        category: OnboardingActionCategory.Category1,
        mission: '自己紹介をしてみよう',
        why: '自分から趣味などのプライベート情報を開示することで、自分が何者か知ってもらうことができ、かかわってもらいやすくなるため',
        what: '配属してすぐに、チーム全員にメールで人となりがわかる自己紹介を送る',
        how: '配属して3日以内に、メールでチーム全員へ自己紹介を送れたらOK',
        period: 0,
        importance: 3,
        point: 3,
        teamId: 'teamMember.teamId',
        isPublished: true,
        publishAt: new Date().toString(),
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        dammyHash: '',
      }
      setActions([payload])
    }
    getActions()
  }, [teamId, userId])

  useEffect(() => {
    if (actions && actions.length) {
      setAFT(actions.find((action) => action.mission === '自己紹介をしてみよう'))
    }
  }, [actions])

  return { actions, actionForTutorial, actionsFeedbackForTutorial }
}

const MemberTutorial: React.FC<RoleTutorialProps> = (props) => {
  const { stage, setStage } = props
  const text = callTx(props.teamMember.role)[stage]

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const { actions, actionForTutorial, actionsFeedbackForTutorial } = useActionsForTutorial(
    props.teamId,
    props.teamMember.userId
  )

  if (!actions || !actionForTutorial)
    return (
      <Loader
        timeout={10000}
        onTimeout={() => {
          alert('ロード中に問題が発生しました。')
          props.endTutorial()
        }}
      />
    )

  switch (stage) {
    case 4:
      return (
        <ActionList
          override={{
            activeActions: actions,
            loadingActions: false,
            userId: props.teamMember.userId,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: 'アクションリストへ',
              onClickButton: () => setStage(5),
              outerText: 'このチュートリアルは全部で約3分かかります',
            },
          }}
        />
      )
    case 5:
      return (
        <ActionList
          override={{
            activeActions: actions,
            loadingActions: false,
            userId: props.teamMember.userId,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 0, y: 0 },
              center: true,
              pointer: { position: 'bottom' },
              onClickRef: () => setStage(6),
              ownControl: 'actionSelect',
            },
          }}
        />
      )
    case 6:
      return (
        <ActionDetail
          override={{
            action: actionForTutorial,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 0, y: 0 },
              pointer: { position: 'bottom' },
              onClickRef: () => setStage(7),
              onWhat: 'post',
            },
          }}
        />
      )
    case 7:
      return (
        <ActionDetail
          override={{
            action: actionForTutorial,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -30, y: 0 },
              center: true,
              pointer: { position: 'bottom', offset: { x: -100, y: 0 } },
              onClickRef: () => undefined,
              onWhat: 'modal',
            },
            next: () => setStage(8),
            notRelative: true,
          }}
        />
      )
    case 8:
      return (
        <ActionDetail
          override={{
            action: actionForTutorial,
          }}
          tutorial={{
            modal: {
              ...text,
              buttonText: '次へ',
              onClickButton: () => setStage(9),
            },
          }}
        />
      )
    case 9:
      return (
        <Timeline
          key="tutorial-9"
          override={{
            posts: MemberTimelinePostsDummy(),
            loading: false,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 0, y: 0 },
              pointer: { position: 'top', offset: { x: 0, y: 0 } },
              onClickRef: () => undefined,
              scrollTarget: 'tooltip',
              autoHeight: true,
              msg: { top: 'timelineDummyPosted', bottom: 'sampleDisplay' },
              onWhat: 'sample',
              button: {
                text: '次へ',
                onClick: () => {
                  setStage(10)
                  unmountTooltip()
                },
              },
              ownControl: 'timelineSample',
            },
          }}
        />
      )
    case 10:
      return (
        <ActionDetail
          key="tutorial-10"
          override={{
            action: actionForTutorial,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: -150, y: 0 },
              pointer: { position: 'bottom' },
              onClickRef: () => undefined,
              onWhat: 'done',
            },
            next: () => setStage(11),
          }}
        />
      )
    case 11:
      return (
        <ActionList
          key="tutorial-11"
          override={{
            activeActions: [],
            actionsFeedback: actionsFeedbackForTutorial,
            loadingActions: false,
            userId: props.teamMember.userId,
          }}
          tutorial={{
            tooltip: {
              ...text,
              offset: { x: 0, y: -40 },
              pointer: { position: 'top', offset: { x: isSmDown ? 0 : -230, y: -40 } },
              onClickRef: () => undefined,
              scrollTarget: 'tooltip',
              autoHeight: true,
              msg: { top: 'actionAchieved', bottom: 'sampleDisplay' },
              button: { text: '次へ', onClick: () => setStage(12) },
              ownControl: 'actionAchieved',
            },
            scene: 'actionAchieved',
          }}
        />
      )
    case 12:
      return <Complete key="tutorial-12" {...props} />
    case 13:
      return (
        <DailyCheck
          key="tutorial-13"
          tutorial={{
            modal: {
              ...text,
              buttonText: 'はじめる',
              onClickButton: () => props.endTutorial(),
            },
          }}
        />
      )
    default:
      return (
        <Loader
          timeout={10000}
          onTimeout={() => {
            alert('ロード中に問題が発生しました。')
            props.endTutorial()
          }}
        />
      )
  }
}

export default MemberTutorial
