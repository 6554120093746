import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { BackCard } from 'components/BackCard'
import { Breadcrumb } from 'components/Breadcrumb'
import { TitleRenewal } from 'components/TitleRenewal'
import { TopRenewalWrapper } from 'components/TopRenewalWrapper'
import { downloadFiles } from 'pages/documents/assets/documents.data'
import { HubSpotForm, BREAKPOINT } from 'pages/documents/components/HubSpotForm'

import { constants } from 'assets'

export const DownloadPage: React.FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const formId = downloadFiles.filter((item) => item.link === location.pathname.split('/').slice(-1)[0])[0]
    .hubSpotFormId

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TopRenewalWrapper>
      <div className={classes.root}>
        <Breadcrumb
          items={[
            { link: constants.PAGE_TOP, name: 'トップ' },
            { link: constants.PAGE_DOCUMENTS, name: 'お役立ち資料' },
          ]}
          endItem="資料ダウンロード"
        />
        <TitleRenewal
          category="Download"
          title="資料ダウンロード"
          description={
            <>
              下記フォームに必要事項を入力し、
              <br />
              「ダウンロード」ボタンを押してください。
            </>
          }
        />
        <div className={classes.cardContainer}>
          <BackCard>
            <HubSpotForm formId={formId} />
          </BackCard>
        </div>
      </div>
    </TopRenewalWrapper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: constants.COLOR_WHITE,
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    maxWidth: BREAKPOINT,
    margin: '0 auto',
    paddingBottom: 140,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      paddingBottom: 100,
    },
  },
  cardContainer: {
    marginTop: 64,
    [theme.breakpoints.down(BREAKPOINT)]: {
      marginTop: 32,
    },
  },
}))
