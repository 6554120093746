import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = {
  barWidth: number //mim 0 - max 100 for use percent, Infinity is exception
  counter: number | string
  exceptionMsg?: React.ReactNode
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const ProgressBar: React.FC<Props & StyleProps> = ({ barWidth, counter, exceptionMsg = '', ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  return (
    <>
      {/* handle isException */}
      {barWidth === Infinity ? (
        <div className={classes.exception}>{exceptionMsg}</div>
      ) : (
        <>
          <div className={classes.barBack}>
            <div className={classes.barWrapper}>
              <div className={classes.bar} style={{ width: `${barWidth}%` }}>
                {barWidth >= 20 && <span>{counter}</span>}
              </div>
              {barWidth < 20 && (
                <div className={classes.narrowCount}>
                  <span style={{ color: barWidth === 0 ? constants.COLOR_DANGER : constants.TEXT_GRAY_DARK }}>
                    {counter}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    exception: {
      whiteSpace: 'pre-wrap',
      width: '100%',
      flex: 1,
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    barBack: ({ ownStyles }: StyleProps) => ({
      position: 'relative',
      flex: 1,
      minWidth: 0,
      width: '100%',
      height: 16,
      borderRadius: 8,
      overflow: 'hidden',
      backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
      ...ownStyles,
      [theme.breakpoints.down('sm')]: {
        minHeight: 16,
      },
    }),
    barWrapper: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    bar: {
      display: 'flex',
      justifyContent: 'right',
      alignItems: 'center',
      top: 0,
      left: 0,
      height: '100%',
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: constants.COLOR_WHITE,
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 1,
      overflow: 'hidden',
      '& span': {
        position: 'relative',
        right: 8,
      },
    },
    narrowCount: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      marginLeft: 8,
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
  { name: 'ProgressBar' }
)
