import * as React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import { TopSectionTitle } from './topPageModules/TopSectionTitle'

import * as constants from 'assets/constants'

type Props = {}

export const TopRecommend: React.FC<Props> = () => {
  const classes = useStyles({})

  const theme = useTheme()
  const is900down = useMediaQuery(theme.breakpoints.down(constTop.breakPointsSP + 300))
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div className={classes.root} id={constTop.anchorLinkId[5]}>
      {is900down ? (
        <>
          <TopSectionTitle title="プロダクト推薦者" />
          {isSmDown ? (
            <div className={classes.img}>
              <img src={process.env.PUBLIC_URL + '/img/topPage/recommend/shimazu.jpg'} alt="shimazu" />
            </div>
          ) : (
            <></>
          )}
          <div className={classes.textBox}>
            <div className={classes.topicTitle}>{constTop.recommendComment.topic}</div>
            <div className={classes.topicText}>{constTop.recommendComment.text}</div>
            {isSmDown ? (
              <></>
            ) : (
              <div className={classes.img}>
                <img src={process.env.PUBLIC_URL + '/img/topPage/recommend/shimazu.jpg'} alt="shimazu" />
              </div>
            )}
            <div className={classes.personBox}>
              <div className={classes.topicWrapper}>
                <div className={classes.personPosition}>{constTop.recommendPerson.topic2}</div>
                <div className={classes.personName}>{constTop.recommendPerson.topic}</div>
                <div className={classes.personDetail}>{constTop.recommendPerson.text}</div>
              </div>
              <div className={classes.textWrapper}>
                <div className={classes.auther}>主な著書</div>
                <div className={classes.bookTitles}>
                  <div className={classes.bookTitle}>
                    ワーク・エンゲイジメント 〜ポジティブメンタルヘルスで活力のある毎日を〜
                  </div>
                  <div>労働調査会（2014年）</div>
                  <div className={classes.bookTitle}>
                    ワーク・エンゲイジメント 〜基本理論と研究のためのハンドブック〜
                  </div>
                  <div>星和書店（2014年）</div>
                  <div className={classes.bookTitle}>
                    職場のストレスマネジメント セルフケア教育の企画・実施マニュアル
                  </div>
                  <div>誠信書房（2014年）他多数</div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <TopSectionTitle title="プロダクト推薦者" />
          <div className={classes.contents}>
            <div className={classes.img}>
              <img src={process.env.PUBLIC_URL + '/img/topPage/recommend/shimazu.jpg'} alt="shimazu" />
            </div>
            <div className={classes.textBox}>
              <div className={classes.topicTitle}>{constTop.recommendComment.topic}</div>
              <div className={classes.topicText}>{constTop.recommendComment.text}</div>
            </div>
          </div>
          <div className={classes.personBox}>
            <div className={classes.topicWrapper}>
              <div className={classes.personPosition}>{constTop.recommendPerson.topic2}</div>
              <div className={classes.personName}>{constTop.recommendPerson.topic}</div>
              <div className={classes.personDetail}>{constTop.recommendPerson.text}</div>
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.auther}>主な著書</div>
              <div className={classes.bookTitles}>
                <div className={classes.bookTitle}>
                  {'ワーク・エンゲイジメント 〜ポジティブメンタルヘルスで活力のある毎日を〜 \n労働調査会（2014年）'}
                </div>
                <div className={classes.bookTitle}>
                  {'ワーク・エンゲイジメント 〜基本理論と研究のためのハンドブック〜 \n星和書店（2014年）'}
                </div>
                <div className={classes.bookTitle}>
                  {'職場のストレスマネジメント セルフケア教育の企画・実施マニュアル \n誠信書房（2014年）他多数'}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&::before': {
      ...constTop.fixAnchorLinkPosition,
    },
  },
  textBox: {
    margin: '45px 0 0 0',
    padding: '0 24px 80px',
    '& .topic': {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
    [theme.breakpoints.down('md')]: {
      margin: '8px 0 0 0',
    },
  },
  topicTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    paddingTop: '32px',
    color: constants.COLOR_ONBOARDING_MAIN,
  },
  topicText: {
    fontSize: 13,
    lineHeight: 1.9,
    paddingTop: '24px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '24px',
    },
  },
  img: {
    margin: '24px auto 0',
    padding: '0 0 8px 0',
    textAlign: 'center',
    overflow: 'hidden',
    '& img': {
      width: 250,
      height: 250,
      margin: '0 0 -14px 0',
      objectFit: 'cover',
      objectPosition: 'top',
      borderRadius: '50%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '45px auto 0',
    },
  },
  personBox: {
    backgroundColor: constTop.COLOR_WHITE,
    margin: '0 -24px',
    padding: '32px 24px 40px',
    borderRadius: 8,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  topicWrapper: {
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  textWrapper: {
    width: '600px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  personPosition: {
    fontSize: 14,
  },
  personName: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '8px 0 0 0',
  },
  personDetail: {
    fontSize: 14,
    lineHeight: 1.9,
    margin: '24px 24px 0 0',
    [theme.breakpoints.down('md')]: {
      marginBottom: '50px',
    },
  },
  auther: {
    fontSize: 14,
    fontWeight: 'bold',
    margin: '0 0 0 24px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  bookTitles: {
    fontSize: 14,
    margin: '16px 0 0 24px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      margin: '16px 40px 0 0',
    },
  },
  bookTitle: {
    margin: '12px 0 0 24px',
    lineHeight: 1.5,
    [theme.breakpoints.down('md')]: {
      margin: '12px 0 0 0px',
    },
  },
  [theme.breakpoints.up(constTop.breakPointsSP + 300)]: {
    contents: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 auto',
    },
    img: {
      margin: '24px 0 0',
      padding: '0 0 8px 0',
      textAlign: 'center',
      '& img': {
        width: '250px',
      },
    },
    textBox: {
      width: '70%',
      margin: '0 0 0 24px',
      padding: '0 0 0 0',
    },
    personBox: {
      margin: '32px 0 80px',
      padding: '24px',
    },
    bookTitles: {
      margin: '24px 0 0 0',
      fontSize: 14,
    },
    bookTitle: {
      margin: '0 0 8px 24px',
      lineHeight: 1.5,
    },

    greenBackground: {
      background: constants.COLOR_ONBOARDING_MAIN,
      width: '100px',
      height: '200px',
      margin: '-213px 0 0 -24px',
    },
  },
  [theme.breakpoints.up(constTop.breakPointsPC + 48)]: {
    contents: {
      margin: '24px auto 0 0',
    },
    textBox: {
      margin: '0 0 0 24px',
    },
    autherBox: {
      backgroundColor: 'transparent',
      margin: '-14px 0 0 -49px',
      padding: 48,
    },
    greenBackground: {
      background: constants.COLOR_ONBOARDING_MAIN,
      width: '100%',
      height: 200,
      margin: '-213px 0 0 calc((1200px - 100vw)/2)',
    },
  },
}))
