import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

export type Option = {
  value: string
  label: string
}

type Props = {
  name?: string
  value?: string
  options: Option[]
  style?: React.CSSProperties | undefined
  imgStyle?: React.CSSProperties
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const Select = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const classes = useStyles()
  const { name, value, onChange } = props

  return (
    <div className={classes.selectWrap}>
      <select name={name} className={classes.select} onChange={onChange} value={value} ref={ref} style={props.style}>
        {props.options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <img
        className={classes.selectArrow}
        src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/icon_arrow_select.svg'}
        alt=""
        style={props.imgStyle}
      />
    </div>
  )
})

const useStyles = makeStyles({
  selectWrap: {
    position: 'relative',
  },
  select: {
    width: '100%',
    appearance: 'none',
    backgroundColor: '#fff',
    height: 48,
    border: '1px solid #E9E9E9',
    borderRadius: 8,
    padding: 16,
    lineHeight: 1,

    '&:focus-visible': {
      outline: `${constants.COLOR_ONBOARDING_MAIN} solid 1px`,
    },
  },
  selectArrow: {
    position: 'absolute',
    right: 8,
    top: 0,
    bottom: 0,
    margin: 'auto',
    pointerEvents: 'none',
  },
})
