import * as React from 'react'

import { Box, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    width: '100%',
    height: '100%',
    display: 'block',

    '&.animate': {
      width: '100%',
      height: '100%',
      opacity: 1,
      animation: `$modalslide 500ms ${theme.transitions.easing.easeInOut}`,
    },
  },

  '@keyframes modalslide': {
    '0%': {
      opacity: 0,
      transform: 'translateY(10%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0px)',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  in: boolean
  direction: 'down' | 'left' | 'right' | 'up'
  children: React.ReactNode
}

export const Slide = (props: IProps) => {
  const classes = useStyles()

  return (
    <Box className={classes.root + ` ${props.in ? 'animate' : ''}`}>
      <Box>
        <Box>{props.children}</Box>
      </Box>
    </Box>
  )
}
