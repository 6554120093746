import React from 'react'

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { ClassNameMap } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = {
  button: React.ReactNode
  errMsg?: React.ReactNode | null
  isCancelEditButton?: boolean
  // if isCancelEditButton is true use it ↓
  CancelEditButtonProps?: {
    text: string
    onClick?: () => void
  }
}
type StyleProps = {
  buttonWidth?: number | string | undefined
}

export const ButtonContainer: React.FC<Props & StyleProps> = ({
  button,
  buttonWidth,
  errMsg,
  isCancelEditButton,
  CancelEditButtonProps,
}) => {
  const theme = useTheme()
  const isBreakPointsUp = useMediaQuery(theme.breakpoints.up('sm'))
  const classes = useStyles({ buttonWidth: isBreakPointsUp ? buttonWidth ?? '100%' : buttonWidth ?? 350 })
  const cancelButtonProps = { classes, CancelEditButtonProps }

  return (
    <>
      <div className={classes.root}>
        {isCancelEditButton && isBreakPointsUp ? <CancelEditButton {...cancelButtonProps} /> : <></>}
        <div className={classes.buttonWrapper}>
          {errMsg ? <div className={classes.errMsg}>{errMsg}</div> : <></>}
          <div className={classes.base}>{button}</div>
          {isCancelEditButton && !isBreakPointsUp ? <CancelEditButton {...cancelButtonProps} /> : <></>}
        </div>
      </div>
    </>
  )
}

type CancelButtonProps = {
  classes: ClassNameMap
  // if isCancelEditButton is true use it ↓
  CancelEditButtonProps?: {
    text: string
    onClick?: () => void
  }
}
const CancelEditButton: React.FC<CancelButtonProps> = ({ classes, CancelEditButtonProps }) => {
  return (
    <div
      className={CancelEditButtonProps?.text === 'スキップ' ? classes.cancelLabelTutrial : classes.cancelLabel}
      onClick={CancelEditButtonProps?.onClick}
    >
      {CancelEditButtonProps?.text}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100vw',
      left: 0,
      bottom: 0,
      position: 'fixed',
      padding: '26px',
      backgroundColor: constants.COLOR_WHITE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '40px',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      color: constants.COLOR_ONBOARDING_ALERT,
    },
    errMsg: ({ buttonWidth }: StyleProps) => ({
      width: buttonWidth,
    }),
    base: ({ buttonWidth }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: buttonWidth,
    }),
    cancelLabel: {
      fontSize: 12,
      fontWeight: 'bold',
      color: constants.COLOR_GRAY_DARK,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        margin: '24px 0 0',
        alignSelf: 'center',
      },
    },

    cancelLabelTutrial: {
      fontSize: 16,
      fontWeight: 'bold',
      color: constants.COLOR_GRAY_DARK,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        margin: '24px 0 0',
        alignSelf: 'center',
      },
    },
  }),
  { name: 'ButtonContainer' }
)
