import React from 'react'

import { GraphQLResult } from '@aws-amplify/api'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { LoadingCircular } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts'
import { useManageTeamBuilding } from 'pages/teams/hooks'
import { subscriptionUpdatedTeamBuilding, subscriptionUpdatedTeamBuildingMember } from 'pages/teams/hooks/graphql'
import { getTeamMemberRole } from 'pages/teams/utils/teamMember'
import { TeamBuilding, TeamBuildingMember, TeamBuildingStatus, TeamMemberRole } from 'utils/generated'

import { Completed } from './Completed'
import { Created } from './Created'
import { Q1 } from './Q1'
import { Q1End } from './Q1End'
import { Q1Presen } from './Q1Presen'
import { Q1Select } from './Q1Select'
import { Q2 } from './Q2'
import { Q2End } from './Q2End'
import { Q2Presen } from './Q2Presen'
import { Q2Result } from './Q2Result'
import { Q2Result02 } from './Q2Result02'
import { Q2Select } from './Q2Select'
import { Q2Vote02 } from './Q2Vote02'
import { Q3 } from './Q3'
import { Q3Adjust } from './Q3Adjust'
import { Q3End } from './Q3End'
import { Q3LP } from './Q3LP'
import { Q3Vote1 } from './Q3Vote1'
import { Q3Vote2 } from './Q3Vote2'
import { Q4 } from './Q4'
import { Q4Adjust } from './Q4Adjust'
import { Q4End } from './Q4End'
import { Q5 } from './Q5'
import { Q5Actions } from './Q5Actions'

type Props = RouteComponentProps<{ teamId: string }>

export const TeamsToolsBuildingHomePage: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { currentTeam, teamBuilding, refreshTeamBuilding, loadedTeamBuilding, currentTeamMember, isLeader } =
    React.useContext(HooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const { refreshTeamBuildingMembers, refreshTeamBuildingActions } = React.useContext(BuildingHooksContext)

  React.useEffect(() => {
    // リーダーかつ、チームビルディングのレコードがない時、作成する
    if (loadedTeamBuilding && isLeader && !teamBuilding) {
      updateTeamBuilding({
        id: props.match.params.teamId,
        status: TeamBuildingStatus.Created,
        createdAt: new Date().toISOString(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding, isLeader, loadedTeamBuilding])

  // Q5のアクションをサブスク更新
  React.useEffect(() => {
    if (teamBuilding) {
      if (teamBuilding.status === TeamBuildingStatus.Q5Actions) {
        refreshTeamBuildingActions()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamBuilding])

  const subscribeUpdatedTeamBuilding = () => {
    const sub = subscriptionUpdatedTeamBuilding().subscribe({
      next: ({ value }: { value: GraphQLResult<{ updatedTeamBuilding: TeamBuilding }> }) => {
        try {
          if (value.data?.updatedTeamBuilding && value.data?.updatedTeamBuilding.id === props.match.params.teamId) {
            refreshTeamBuilding()
          }
        } catch (error: any) {
          console.error(`sub Error: ${error.message}`)
        }
      },
    })
    const unsubscribe = () => {
      sub.unsubscribe()
    }
    return unsubscribe
  }

  const subscribeUpdatedTeamBuildingMember = () => {
    const sub = subscriptionUpdatedTeamBuildingMember().subscribe({
      next: ({ value }: { value: GraphQLResult<{ updatedTeamBuildingMember: TeamBuildingMember }> }) => {
        try {
          if (
            value.data?.updatedTeamBuildingMember &&
            value.data?.updatedTeamBuildingMember.teamId === props.match.params.teamId
          ) {
            refreshTeamBuildingMembers()
          }
        } catch (error: any) {
          console.error(`sub Error: ${error.message}`)
        }
      },
    })
    const unsubscribe = () => {
      sub.unsubscribe()
    }
    return unsubscribe
  }

  const onClickStart = () => {
    //
  }

  React.useEffect(() => {
    subscribeUpdatedTeamBuilding()
    subscribeUpdatedTeamBuildingMember()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [role, setRole] = React.useState<TeamMemberRole | undefined>()
  React.useEffect(() => {
    if (!role) {
      setRole(getTeamMemberRole(currentTeamMember))
    }
  }, [currentTeamMember, role])

  const renderContent = () => {
    if (loadedTeamBuilding) {
      switch (teamBuilding?.status) {
        case TeamBuildingStatus.Created:
          return <Created onClickStart={onClickStart} {...props} />
        case TeamBuildingStatus.Q1:
          return <Q1 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q1Select:
          return <Q1Select teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q1Presen:
          return <Q1Presen teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q1End:
          return <Q1End teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2:
          return <Q2 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2Select:
          return <Q2Select teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2Presen:
          return <Q2Presen teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2Result:
          return <Q2Result teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2Vote_02:
          return <Q2Vote02 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2Result_02:
          return <Q2Result02 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q2End:
          return <Q2End teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3Lp:
          return <Q3LP teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3:
          return <Q3 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3Vote1:
          return <Q3Vote1 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3Vote2:
          return <Q3Vote2 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3Adjust:
          return <Q3Adjust teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q3End:
          return <Q3End teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q4:
          return <Q4 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q4Adjust:
          return <Q4Adjust teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q4End:
          return <Q4End teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q5:
          return <Q5 teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Q5Actions:
          return <Q5Actions teamBuilding={teamBuilding} {...props} />
        case TeamBuildingStatus.Completed:
          return <Completed currentTeam={currentTeam} teamBuilding={teamBuilding} {...props} />
        default:
          return <Created onClickStart={onClickStart} {...props} />
      }
    } else {
      return <LoadingCircular loading={true} />
    }
  }

  return <div className={classes.root}>{renderContent()}</div>
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
