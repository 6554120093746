import React from 'react'
import { useLocation } from 'react-router-dom'

import { useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import {
  DefaultInputWithLabel,
  Card,
  DefaultButton,
  CardFooter,
  IconEditor,
  SearchAddressButton,
} from 'pages/teams/components'
import { AdminSwitchTab } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamAdmin } from 'pages/teams/hooks'
import { UpdateTeamAdminInput, Addresses } from 'utils/generated'

import { PatternZipCode, PatternPhone } from 'assets/patterns'
import { theme } from 'assets/theme'


type Props = RouteComponentProps<{ teamId: string }>

export const TeamsCorpPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const params = useLocation().pathname
  const array = params.split('/')
  const url = array[3]

  const { currentTeam, refreshTeam, currentTeamAdmin, currentUser, refreshTeamAdmin } = React.useContext(HooksContext)

  const { control, errors, handleSubmit, setValue } = useForm<UpdateTeamAdminInput>()

  // Search by zipcode ====>
  const [zipcode, setZipcode] = React.useState<string | undefined | null>(undefined)
  React.useEffect(() => {
    if (currentTeamAdmin) {
      setZipcode(currentTeamAdmin?.companyZipCode)
    }
  }, [currentTeamAdmin])
  const handleOnClickSearchAddress = async (result: Addresses) => {
    const { address1, address2, address3 } = result
    setValue('companyAddress1', `${address1}${address2}${address3}`)
  }
  // Search by zipcode <====

  const { updateTeamAdmin, loading: processing } = useManageTeamAdmin()

  const onSubmit = async (input: UpdateTeamAdminInput) => {
    const updated = await updateTeamAdmin(input)
    if (updated) {
      refreshTeam()
      refreshTeamAdmin()
    }
  }

  const isMdUP = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isMdUP ? <></> : <AdminSwitchTab url={url} />}
        <Card
          headerTitle={'企業情報'}
          loading={currentTeam && currentTeamAdmin ? false : true}
          style={{ padding: '30px 24px 40px' }}
        >
          <div className={classes.iconContainer}>
            <Controller
              name={'companyIconUrl'}
              control={control}
              defaultValue={currentTeamAdmin?.companyIconUrl}
              render={({ onChange, value }) => (
                <>
                  <IconEditor
                    name={currentTeamAdmin?.companyName}
                    kind="companies"
                    keyPrefix={currentTeamAdmin?.id || ''}
                    imageUrl={value}
                    onChange={onChange}
                  />
                </>
              )}
            />
          </div>

          <DefaultInputWithLabel
            label={'法人名'}
            htmlFor={'companyName'}
            name={'companyName'}
            control={control}
            rules={{ required: '法人名は必須です' }}
            error={errors.companyName}
            defaultValue={currentTeamAdmin?.companyName}
          />
          <div className={classes.postalCode}>
            <div className={classes.input}>
              <DefaultInputWithLabel
                label={'郵便番号'}
                name={'companyZipCode'}
                htmlFor={'postCode'}
                control={control}
                rules={{
                  required: '郵便番号は必須です',
                  pattern: {
                    value: PatternZipCode,
                    message: '郵便番号は正しくありません。',
                  },
                }}
                error={errors.companyZipCode}
                defaultValue={currentTeamAdmin?.companyZipCode}
                onChange={(e) => setZipcode(e.target.value)}
                marginBottom={0}
              />
            </div>
            <div className={classes.address}>
              <SearchAddressButton zipcode={zipcode} callBack={handleOnClickSearchAddress} />
            </div>
          </div>
          <DefaultInputWithLabel
            label={'所在地'}
            name={'companyAddress1'}
            htmlFor={'address'}
            control={control}
            rules={{ required: '所在地は必須です' }}
            error={errors.companyAddress1}
            defaultValue={currentTeamAdmin?.companyAddress1}
          />
          <DefaultInputWithLabel
            label={'電話番号'}
            name={'companyPhone'}
            htmlFor={'phoneNumber'}
            control={control}
            rules={{
              required: '電話番号は必須です',
              pattern: {
                value: PatternPhone,
                message: '電話番号は正しくありません。',
              },
            }}
            error={errors.companyPhone}
            defaultValue={currentTeamAdmin?.companyPhone}
          />
          <DefaultInputWithLabel
            label={'契約日'}
            name={'contractAt'}
            htmlFor={'contractAt'}
            defaultValue={currentTeamAdmin?.contractAt}
            readOnly
          />
          {currentUser && currentUser.id === currentTeam?.ownerUserId ? (
            <CardFooter>
              <DefaultButton
                title={'更新'}
                color={'darkPrimary'}
                style={{ boxShadow: 'none' }}
                type="submit"
                loading={processing}
              />
            </CardFooter>
          ) : (
            <></>
          )}
        </Card>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    paddingBottom: 20,
    marginBottom: 20,
  },

  postalCode: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 34,
    gap: '16px',
  },

  input: {
    [theme.breakpoints.down('sm')]: {
      width: 164,
    },
  },

  address: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
}))
