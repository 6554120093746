import * as React from 'react'

import Avatar from '@mui/material/Avatar'
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'
import { GhostResearch } from 'utils/generated'

type NextActionBoxProps = {
  action: GhostResearch & { fullName: string; imageUrl: string | undefined }
  hasDivision: boolean
  isOpenList: boolean
}

export const NextActionBox: React.FC<NextActionBoxProps> = ({ action, hasDivision, isOpenList }) => {
  const classes = useStyles()
  const [isOpenText, setIsOpenText] = React.useState(false)
  const [hasAccordionButton, setHasAccordionButton] = React.useState(false)

  const handleCloseText = () => {
    setIsOpenText(false)
  }
  const handleOpenText = () => {
    setIsOpenText(true)
  }

  React.useEffect(() => {
    const el = document.getElementById(action.id)
    // テキストが省略されているか判定し、省略されている場合はボタンを表示する。
    // scrollHeight: 要素の全高さ。overflow などで非表示になっている部分も含む。
    // clientHeight: 要素の表示されている高さ。
    if (el && el.scrollHeight > el.clientHeight) {
      setHasAccordionButton(true)
    }
  }, [action.id])

  return (
    <div className={classNames(classes.nextActionCell, { hasDivision })}>
      <div className={classes.member}>
        <Avatar src={action.imageUrl} alt="プロフィール画像" className={classes.avatar} />
        <p className={classes.memberName}>{action.fullName}</p>
      </div>
      <div className={classes.nextActionWrapper}>
        <p id={action.id} className={classNames(classes.nextAction, { shortening: !isOpenText })}>
          {action.report || '_'}
        </p>
      </div>

      {isOpenList && hasAccordionButton && (
        <div className={classes.textOpenButton}>
          <NoStyleButton onClick={isOpenText ? handleCloseText : handleOpenText}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/svg/${isOpenText ? 'minus' : 'plus'}.svg`}
              alt={isOpenText ? 'テキスト短縮表示' : 'テキスト全文表示'}
            />
          </NoStyleButton>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    nextActionCell: {
      position: 'relative',
      display: 'flex',
      columnGap: 8,
      alignItems: 'flex-start',
      height: '100%',
      '&.hasDivision': {
        borderTop: '2px solid #fff',
      },
    },
    member: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      minWidth: 152,
      width: 152,
      padding: 16,
    },
    avatar: {
      width: 24,
      height: 24,
    },
    memberName: {
      margin: 0,
      fontWeight: 'bold',
    },
    nextActionWrapper: {
      alignSelf: 'stretch',
      padding: '13px 23px 21px 16px',
      borderLeft: '2px solid #fff',
    },
    nextAction: {
      margin: 0,
      fontSize: 10,
      whiteSpace: 'pre-wrap',
      '&.shortening': {
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
      },
    },
    textOpenButton: {
      position: 'absolute',
      right: 4,
      bottom: 4,
      width: 18,
      height: 18,
      '& button': {
        width: '100%',
        height: '100%',
        padding: 4,
      },
      '& img': {
        width: 10,
        height: 10,
        verticalAlign: 'top',
      },
    },
  }),
  { name: 'NextActionBox' }
)
