import React, { useContext } from 'react'
import { useState } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ButtonContainer, WidthControlContainer } from 'pages/ghost/components/modules'
import { Step4ReportBox } from 'pages/ghost/components/modules/Step4ReportBox'
import { GhostCausesAndRemedies } from 'pages/ghost/components/organisms/GhostCausesAndRemedies'
import { GhostDetailBox } from 'pages/ghost/components/organisms/GhostDetailBox'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'
import { GhostHistory, GhostTeamMember } from 'utils/generated'

import { GrayBackGround, BackResponsiveCard, Font, LeadText, Flexbox, ButtonForGhost } from '../components/atoms'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { HistoryContext } from '../contexts/history'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'
import { ErrorSnapshot } from '../utils/error'

import * as constGhost from '../assets/constGhost'

export const Step4Report: React.FC = () => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const { currentHistory } = useContext(HistoryContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMember } = useContext(ghostTeamMemberContext)
  const { ghosts } = useContext(GhostContext)
  const { runCreateResearch } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer' })

  const [formState, setFormState] = useState({ feeling: '', nextAction: '' })
  const [hasFeelingError, setHasFeelingError] = useState<boolean>(false)
  const [hasNextActionError, setHasNextActionError] = useState<boolean>(false)

  const ghost = ghosts.find((g) => g.id === currentHistory?.ghostId)

  const handleChange = (value: string, type: 'feeling' | 'nextAction') => {
    if (value.length > 499)
      return window.alert(
        `${
          type === 'feeling' ? '気づいたこと / 感じたこと' : '明日から出来そうなアクション'
        }は500文字以内で入力してください。`
      )

    type === 'feeling' && formState['feeling'] && setHasFeelingError(false)
    type === 'nextAction' && formState['nextAction'] && setHasNextActionError(false)
    type && setFormState({ ...formState, [type]: value })
  }

  const [updating, setUpdating] = useState<boolean>(false)

  const onSubmit = React.useCallback(() => {
    const feelingFormError = !formState['feeling'] || !formState['feeling'].match(/\S/g)
    const nextActionFormError = !formState['nextAction'] || !formState['nextAction'].match(/\S/g)
    const isMember = teamMember?.role === 'MEMBER'

    isMember && feelingFormError && setHasFeelingError(true)
    isMember && nextActionFormError && setHasNextActionError(true)

    if (isMember && (feelingFormError || nextActionFormError)) return

    if (updating) return
    setUpdating(true)

    const runUpdate = async () => {
      await runCreateResearch(undefined, undefined, formState['feeling'], formState['nextAction'], teamMember)
      setUpdating(false)
    }
    runUpdate()
  }, [formState, runCreateResearch, teamMember, updating])

  const MainButton = (
    <ButtonForGhost buttonBodyColor="green" bodyText="個人ワーク完了" onClick={onSubmit} isResizeBasic />
  )

  if (!teamMember || !(currentHistory && currentHistory.ghostId))
    return <LoadingIndicator snapshot={inspectErrorState(teamMember, currentHistory)} />

  return (
    <GrayBackGround>
      <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
        <WidthControlContainer>
          <Flexbox ownStyles={{ justifyContent: isSmDown ? 'center' : 'flex-start' }}>
            <Font fontSize={16} bold color={constGhost.COLOR_MAIN_NEW}>
              個人ワーク
            </Font>
          </Flexbox>
          <LeadText ownStyles={{ marginTop: isSmDown ? 24 : 16 }}>
            {`今回のオバケ対話では12体の中から「`}
            <strong>{ghost?.name}</strong>
            {`」を選び、対話をしました。
            改めて「気づいたこと」「感じたこと」を入力してください。
            また、自分が「明日から出来そうなアクション」を１つ宣言してください。`}
          </LeadText>
          <GhostDetailBox ghostId={currentHistory.ghostId} />
          <GhostCausesAndRemedies />
          <Step4ReportBox
            submitResearch={onSubmit}
            handleChange={handleChange}
            formState={formState}
            hasFeelingError={hasFeelingError}
            hasNextActionError={hasNextActionError}
          />
          <ButtonContainer fixed buttons={[MainButton]} />
        </WidthControlContainer>
      </BackResponsiveCard>
      {team && <Timer from={team.timerS4Started ?? new Date().toISOString()} time={300} hasGhostStyle />}
    </GrayBackGround>
  )
}

const inspectErrorState = (
  teamMember: GhostTeamMember | undefined,
  currentHistory: GhostHistory | undefined
): ErrorSnapshot => {
  const snapshot: ErrorSnapshot = {
    teamId: teamMember?.teamId,
    teamMember: teamMember,
    because: 'undefined',
    vars: { teamMember, currentHistory },
  }
  if (!teamMember) {
    snapshot.because = 'member-not-fetched'
  } else {
    if (!currentHistory) {
      snapshot.because = 'history-not-fetched'
    } else if (currentHistory && !currentHistory.ghostId) {
      snapshot.because = 'history-missing-ghost'
    }
  }
  return snapshot
}
