import React from 'react'

import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { HooksContext } from 'pages/teams/contexts'

import * as constants from 'assets/constants'

const flowItems = [
  {
    title: 'エピソードトーク',
    body: '過去、自分が所属したチームを振り返ることで自分にとっての理想のチーム状態を考える材料にしましょう。',
  },
  {
    title: 'チームキーワード選択',
    body: '自分にとっての理想のチーム状態に当てはまりそうなキーワードを選択することで、今のチームに自分が求めていることを考えましょう。',
  },
  {
    title: 'チーム目標設定',
    body: '皆が選んだキーワードの中から、チームの最終日までどのようなチームでありたいかを皆で対話しすり合わせましょう。',
  },
  { title: '行動規範設定', body: '理想のチーム状態を目指すにあたって、守るべき行動規範を皆ですり合わせましょう。' },
  { title: 'アクション設定', body: '理想のチーム状態を目指すにあたって、チームで行うアクションを決めましょう。' },
]

type Props = {
  isSpFlow?: boolean
}

export const Flow: React.FC<Props> = ({ isSpFlow }) => {
  const classes = useStyles()
  const { teamBuilding } = React.useContext(HooksContext)

  const questionNum =
    teamBuilding?.status === 'CREATED' || teamBuilding?.status === 'Q5'
      ? 0
      : teamBuilding?.status === 'COMPLETED'
      ? 100 // Set big num assuming an increase in questions
      : teamBuilding?.status.startsWith('Q')
      ? Number(teamBuilding.status.slice(1, 2))
      : 0

  return (
    <div>
      {flowItems.map((flowItem, i) => {
        return (
          <React.Fragment key={`kickoff-flowItem-${i}`}>
            <div className={classes.boxTitle}>
              <div
                className={classNames([
                  classes.question,
                  questionNum >= i + 1 && classes.questionDone,
                  questionNum !== 0 && questionNum < i + 1 && classes.questionInactive,
                ])}
              >
                {questionNum <= i + 1 ? (
                  `Q${i + 1}`
                ) : (
                  <img src={process.env.PUBLIC_URL + '/assets/svg/teams/check.svg'} alt="check" />
                )}
              </div>
              <h4
                className={classNames([
                  classes.title,
                  questionNum !== 0 && questionNum !== i + 1 && classes.titleInactive,
                ])}
              >
                {flowItem.title}
              </h4>
            </div>
            <div className={classes.boxBody}>
              <div
                className={classNames([
                  classes.verticalLine,
                  isSpFlow && classes.verticalLineSpFlow,
                  questionNum !== 0 && questionNum <= i + 1 && classes.verticalLineInactive,
                  i === flowItems.length - 1 && classes.verticalLineNone,
                ])}
              ></div>
              <p
                className={classNames([
                  classes.body,
                  questionNum === i + 1 ? classes.textActive : questionNum !== 0 && classes.textInactive,
                ])}
              >
                {flowItem.body}
              </p>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles(
  {
    boxTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    question: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      borderRadius: 20,
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 16,
      fontWeight: 'bold',
      '& img': {
        width: 23,
        verticalAlign: 'top',
      },
    },
    questionDone: {
      color: constants.TEXT_WHITE,
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
    },
    questionInactive: {
      color: constants.COLOR_ONBOARDING_TEXT_GRAY,
      borderColor: constants.COLOR_ONBOARDING_TEXT_GRAY,
    },
    title: {
      margin: 0,
      paddingLeft: 16,
      color: constants.COLOR_ONBOARDING_MAIN,
      fontSize: 16,
    },
    titleInactive: {
      color: constants.COLOR_ONBOARDING_TEXT_GRAY,
    },
    boxBody: {
      display: 'flex',
    },
    verticalLine: {
      flexShrink: 0,
      margin: '8px 20px 8px -20px',
      width: 40,
      borderRight: `2px solid ${constants.COLOR_ONBOARDING_MAIN}`,
    },
    verticalLineSpFlow: {
      margin: '0 20px 0 -20px',
      borderRightWidth: 1,
      minHeight: 75,
    },
    verticalLineNone: {
      borderRight: 'none',
      minHeight: 'auto',
    },
    verticalLineInactive: {
      borderRightColor: constants.COLOR_ONBOARDING_TEXT_GRAY,
    },
    body: {
      margin: '0 0 16px',
      paddingLeft: 16,
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    textActive: {
      color: constants.COLOR_ONBOARDING_MAIN,
    },
    textInactive: {
      opacity: 0,
    },
  },
  { name: 'Flow' }
)
