import * as React from 'react'
import { useHistory } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles';

import checkSvg from 'pages/teams/assets/svg/check.svg'
import { DefaultButton } from 'pages/teams/components'

import { constants } from 'assets'

export const CompleteWithdraw: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleButtonClick = () => {
    history.push(constants.PAGE_TOP)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <div className={classes.heading}>
        <img src={checkSvg} alt="アイコン" />
        <h2>退会処理が完了しました</h2>
      </div>
      <div className={classes.textContainer}>
        <p>
          退会処理が完了しました。
          <br />
          Cocolaboのご利用ありがとうございました。
        </p>
        <p>またの機会にご利用お待ちしております。</p>
      </div>
      <DefaultButton
        title="トップページへ戻る"
        color="team_main"
        onClick={() => handleButtonClick()}
        style={{ width: '100%', height: 56, borderRadius: 28 }}
      />
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    heading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
      marginBottom: 48,
      '& h2': {
        margin: 0,
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
      '& img': {
        width: 40,
        height: 40,
      },
    },

    textContainer: {
      marginBottom: 48,
      '& p': {
        margin: '0 0 8px',
      },
    },
  }),
  { name: 'CompleteWithdraw' }
)
