import * as React from 'react'

import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from '../assets/constants'

type StyleProps = {
  flexNone?: boolean
  padding?: string
  bgColor?: string
  color?: string
  fontSize?: number
  fontWeight?: number
  borderLess?: boolean
  borderColor?: string
  borderRadius?: number
  boxShadow?: string
  hoverBgColor?: string
  hoverColor?: string
  breakpoints?: number
  width?: number | string
  widthSP?: number | string
  maxWidth?: number | 'none'
  maxWidthSP?: number | 'none'
  height?: number | '100%'
  heightSP?: number | '100%'
}

export type Props = {
  children: React.ReactNode
  onClick?: () => void
  variant?: 'contained' | 'outlined' | 'text'
  fullWidth?: boolean
  style?: React.CSSProperties
} & StyleProps

export const ButtonRenewal: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Button
      onClick={props.onClick}
      variant={props.variant ?? 'contained'}
      fullWidth={props.fullWidth}
      style={{ ...props.style }}
      className={classes.root}
    >
      {props.children}
    </Button>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    boxSizing: 'border-box',
    flex: props.flexNone ? undefined : 1,
    padding: props.padding ?? 0,
    backgroundColor: props.bgColor ?? constants.COLOR_MAIN_NEW,
    color: props.color ?? constants.COLOR_WHITE,
    fontSize: props.fontSize ?? 14,
    fontWeight: props.fontWeight ?? 'bold',
    border: props.borderLess
      ? '1px solid transparent'
      : props.borderColor
      ? `2px solid ${props.borderColor}`
      : `2px solid ${constants.COLOR_MAIN_NEW}`,
    borderRadius: props.borderRadius ?? 25,
    boxShadow: props.boxShadow ?? 'none',
    width: props.width ?? '47%',
    maxWidth: props.maxWidth === 'none' ? undefined : props.maxWidth ?? 200,
    height: props.height ?? 50,
    [theme.breakpoints.down(props.breakpoints ? 600 + props.breakpoints : 'sm')]: {
      width: props.widthSP ?? '48%',
      maxWidth: props.maxWidthSP === 'none' ? undefined : props.maxWidthSP ?? 176,
      height: props.heightSP ?? 46,
    },
    '&:hover': {
      backgroundColor: props.hoverBgColor ?? constants.COLOR_WHITE,
      color: props.hoverColor ?? constants.COLOR_MAIN_NEW,
      boxShadow: props.boxShadow ?? 'none',
    },
    textTransform: 'none',
  }),
}))
