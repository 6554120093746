import React from 'react'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IconButton } from '@mui/material'

import {
  COLOR_ONBOARDING_GRAY_LIGHT,
  COLOR_ONBOARDING_MAIN,
  COLOR_ONBOARDING_TEXT_GRAY,
  TEXT_WHITE,
} from 'assets/constants'

type Props = {
  setPage: React.Dispatch<React.SetStateAction<number>>
  nextPage?: number
  disabled?: boolean
}

export const DataRowHeaderNext: React.FC<Props> = ({ setPage, nextPage, disabled }) => {
  return (
    <IconButton
      size="small"
      style={{ backgroundColor: !disabled ? COLOR_ONBOARDING_MAIN : COLOR_ONBOARDING_GRAY_LIGHT }}
      onClick={!disabled && nextPage ? () => setPage(nextPage) : undefined}
    >
      <ChevronRightIcon
        style={{
          color: !disabled ? TEXT_WHITE : COLOR_ONBOARDING_TEXT_GRAY,
        }}
      />
    </IconButton>
  )
}
