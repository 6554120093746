import * as React from 'react'

import { Theme } from '@mui/material'
import Slider from '@mui/material/Slider'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import * as constants from '../../../../../assets/constants'

interface IProps {
  name: string
  value?: number | undefined
  options: { label: string; value: number }[]
  onChange: (value: number) => void
}

type Props = IProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes, options, onChange } = props

  if (options.length === 0) return null

  return (
    <div className={classes.container}>
      <div className="__slider">
        <Slider
          value={props.value || -1}
          valueLabelDisplay="off"
          step={1}
          marks={options}
          min={options[0].value}
          max={options[options.length - 1].value}
          classes={{
            thumb: props.value ? '__thumb' : '__thumb__hidden',
            track: '__track',
            rail: '__rail',
            mark: '__mark',
            markLabel: '__markLabel',
          }}
          onChange={(e, newValue) => onChange(newValue as number)}
          sx={{
            '& .MuiSlider-mark': {
              transition: 'none',
            },
            '& .MuiSlider-markActive': {
              transition: 'none',
            },
            '& .MuiSlider-thumb': {
              transition: 'none',
            },
            '& .MuiSlider-thumb:before': {
              '-webkit-box-shadow': 'none',
              boxShadow: 'none',
            },
            '& .MuiSlider-thumb:before:hover': {
              '-webkit-box-shadow': 'none',
              boxShadow: 'none',
            },
          }}
        />
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      '&__slider': {
        padding: '0 16px 16px',
      },
      '& .__thumb': {
        height: 28,
        width: 28,
        backgroundColor: constants.COLOR_SECOND,
        '&:focus, &:hover, &$active': {
          boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
          '@media (hover: none)': {},
        },
      },
      '& .__thumb__hidden': {
        display: 'none',
      },
      '& .__track': {
        // 選択位置より手前のラインの色を無効化。
        opacity: 0,
      },
      '& .__rail': {
        height: 2,
        opacity: 1,
        backgroundColor: constants.COLOR_BLACK,
      },
      '& .__mark': {
        backgroundColor: constants.COLOR_BLACK,
        height: 16,
        width: 2,
      },
      '& .__markLabel': {
        fontSize: '8px',
        whiteSpace: 'pre',
        textAlign: 'center',
      },
    },
  })

export default withStyles(useStyles)(Index)
