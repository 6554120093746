import React from 'react'
import { useParams } from 'react-router-dom'

import { Chart as ChartJS, ChartOptions, registerables, ChartDataset } from 'chart.js'
import { Chart as ReactChart } from 'react-chartjs-2'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { HooksContext } from 'pages/teams/contexts'
import { useTeamMembers, useTeam } from 'pages/teams/hooks'
import { TeamMember, TeamAnketBuildingSummary, TeamMemberStatusUsage, TeamMemberRole, Maybe } from 'utils/generated'

import { ModalAnswerMember } from './modules'

import horizontalLineSvg from '../assets/horizontalLine.svg'
import {
  COLOR_TEAMBUILDING_PRIMARY,
  COLOR_TEAMBUILDING_RED2,
  COLOR_TEAMBUILDING_TEXT,
  COLOR_TEAMBUILDING_NEUTRAL_500,
  ONBOARDING_FONT_FAMILY,
} from 'assets/constants'

ChartJS.register(...registerables)
const chartId = 'lineChart'

type Props = {
  summaryList: Maybe<(TeamAnketBuildingSummary & { label: string })[]> | undefined
  currentAnketLabel: string | null | undefined
  dateSelectOption: { value: string; label: string }[]
  setCurrentAnketLabel: React.Dispatch<React.SetStateAction<string | null | undefined>>
  setCurrentAnket: React.Dispatch<React.SetStateAction<TeamAnketBuildingSummary | undefined>>
  teamIdLabel: string
}

type ExtendedChartDataset = ChartDataset & {
  images?: string[]
}

export const DateChart: React.FC<Props> = ({
  summaryList,
  currentAnketLabel,
  dateSelectOption,
  setCurrentAnketLabel,
  setCurrentAnket,
  teamIdLabel,
}) => {
  const { teamId } = useParams<{ teamId: string }>()
  const { teamMembers } = useTeamMembers(teamId)
  const isSmDown = useCustomMediaQuery('down', 'md')

  const listLen = summaryList?.length ?? 0
  const TBcheckMembers = teamMembers.filter(
    (member) =>
      member.statusUsage === TeamMemberStatusUsage.Active &&
      member.role.some((role) => [TeamMemberRole.Leader, TeamMemberRole.Member].includes(role))
  )

  const [answeredMembers, setAnsweredMembers] = React.useState<TeamMember[]>([])
  const [unansweredMembers, setUnansweredMembers] = React.useState<TeamMember[]>([])
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const clickableScales = React.useCallback(
    (
      canvas: HTMLElement,
      click: MouseEvent,
      chart: ChartJS,
      checkInRange: (x: number, y: number, index: number) => boolean
    ) => {
      const resetCoordinates = canvas.getBoundingClientRect()
      const x = click.clientX - resetCoordinates.left
      const y = click.clientY - resetCoordinates.top

      for (let i = 0; i < chart.scales.x.ticks.length; i++) {
        if (checkInRange(x, y, i) && summaryList) {
          const list = summaryList[i].anketList
          // summary の anketList から回答者の teamMember id を生成する。
          const listMemberIds = list?.map((anket) => `${anket.teamId}-${anket.userId}`) ?? []

          // 回答者と未回答者にメンバーを分ける。
          const dividedMembers = TBcheckMembers.reduce(
            (acc: { matches: TeamMember[]; notMatches: TeamMember[] }, cur) => {
              if (listMemberIds.includes(cur.id)) {
                acc.matches.push(cur)
              } else {
                acc.notMatches.push(cur)
              }
              return acc
            },
            { matches: [], notMatches: [] }
          )

          setAnsweredMembers(dividedMembers.matches)
          setUnansweredMembers(dividedMembers.notMatches)
          setIsModalOpen(true)
        }
      }
    },
    [summaryList, setIsModalOpen, TBcheckMembers]
  )

  const pointerScales = React.useCallback(
    (
      canvas: HTMLElement,
      mousemove: MouseEvent,
      chart: ChartJS,
      checkInRange: (x: number, y: number, index: number) => boolean
    ) => {
      const resetCoordinates = canvas.getBoundingClientRect()
      const x = mousemove.clientX - resetCoordinates.left
      const y = mousemove.clientY - resetCoordinates.top

      for (let i = 0; i < chart.scales.x.ticks.length; i++) {
        if (checkInRange(x, y, i)) {
          canvas.style.cursor = 'pointer'
          break
        } else {
          canvas.style.cursor = 'default'
        }
      }
    },
    []
  )

  const { isAdmin, isLeader } = React.useContext(HooksContext)
  const { team } = useTeam(teamIdLabel)
  const isShowLabel = isAdmin || (isLeader && team?.statusViewTbCheckRespondents === 'ENABLED')

  React.useEffect(() => {
    if (summaryList) {
      const latest = listLen > 0 ? summaryList[listLen - 1] : null
      setCurrentAnketLabel(latest?.label)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryList])

  React.useEffect(() => {
    if (summaryList && currentAnketLabel) {
      const current = summaryList.find(({ label }) => label === currentAnketLabel)
      setCurrentAnket(current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryList, currentAnketLabel])

  React.useEffect(() => {
    /**
     * canvas 内の click イベントと mousemove イベントを管理するため、座標を計算して対応する。
     * 下記2種類の event listener を設定する。
     *   1. クリック（モーダルオーブン）
     *   2. マウスオーバー（pointer 管理）
     *
     * 参照
     * クリック
     * https://www.youtube.com/watch?v=HCLkAlm9lPI
     * マウスオーバー
     * https://www.youtube.com/watch?v=NY00qv2KqPc
     */

    const chart = chartRef.current as ChartJS | null
    const ctx = document.getElementById(chartId)

    if (!chart || !ctx) {
      return
    }

    const top = chart.scales.x.top
    const bottom = chart.scales.x.bottom
    const left = chart.scales.x.left
    const right = chart.scales.x.maxWidth / chart.scales.x.ticks.length

    // x軸: デフォルトだとラベルだけでなく scales の範囲すべてに反応するので、"回答者を見る" ラベルの位置に合うように調整する。
    // y軸: "回答者を見る" ラベルはx軸の第2ラベルなので、その位置に合うように調整する。
    const checkInRange = (x: number, y: number, index: number) =>
      // x軸 始点
      // data が一つの場合微妙にラベル位置がずれるため、調整する。
      x >= left + (listLen === 1 ? 18 : 24) + right * index &&
      // x軸 終点
      // data が一つの場合微妙にラベル位置がずれるため、調整する。
      x <= right - (listLen === 1 ? 12 : 15) + right * index &&
      // y軸 始点
      y >= top + 42 &&
      // y軸 終点
      y <= bottom + 17

    const handleChartClick = (e: MouseEvent) => {
      // ラベルを表示しない場合は、クリックイベントを無効にする
      if (!isShowLabel) {
        return
      }
      clickableScales(ctx, e, chart, checkInRange)
      chart.resize()
    }
    const handleChartMouseMove = (e: MouseEvent) => {
      // ラベルを表示しない場合は、マウスオーバーを無効にする
      if (!isShowLabel) {
        return
      }
      pointerScales(ctx, e, chart, checkInRange)
      chart.resize()
    }

    ctx.addEventListener('click', handleChartClick)
    ctx.addEventListener('mousemove', handleChartMouseMove)

    return () => {
      ctx.removeEventListener('click', handleChartClick)
      ctx.removeEventListener('mousemove', handleChartMouseMove)
    }
  }, [listLen, clickableScales, pointerScales, isShowLabel])

  // Chart
  const dateData = React.useMemo(() => {
    const dateValues = summaryList?.map((summary) =>
      typeof summary?.average === 'number' ? Math.round(summary.average * 10) / 10 : undefined
    )
    // 二次元配列にすることで、ラベルを改行して表示する。
    const dateLabels =
      summaryList?.map((summary) => [summary.label.replace(/ \(.+\)/g, ''), summary.label.replace(/.+\(/g, '(')]) || []

    return {
      labels: dateLabels,
      datasets: [
        {
          label: '総合評価推移',
          data: dateValues,

          pointRadius: 4,
          pointBackgroundColor: COLOR_TEAMBUILDING_PRIMARY,

          borderWidth: isSmDown ? 1 : 2,

          segment: {
            borderColor: (ctx: any) =>
              ctx.p0.parsed.y <= ctx.p1.parsed.y ? COLOR_TEAMBUILDING_PRIMARY : COLOR_TEAMBUILDING_RED2,
          },
          images: isShowLabel ? summaryList?.map((_) => horizontalLineSvg) ?? [] : [],
        },
      ],
    }
  }, [summaryList, isSmDown, isShowLabel])

  const handleClickDateChart = (dateLabel: string) => {
    const selected = dateSelectOption.find(({ label }) => label === dateLabel)
    if (selected) {
      setCurrentAnketLabel(selected.value)
    }
  }

  const lineOptions: ChartOptions = {
    layout: {
      padding: { bottom: 16 },
    },
    interaction: {
      mode: 'x',
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    onClick: (ev, el, chart) => {
      if (ev.x) {
        const xLabel = chart.scales.x.getValueForPixel(ev.x)
        if (typeof xLabel === 'number') {
          const label = chart.scales.x.ticks[xLabel]?.label
          // label は2行で表示しているため配列化している。
          if (Array.isArray(label)) {
            const uniqueLabel = `${label[0]} ${label[1]}`
            handleClickDateChart(uniqueLabel)
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: '#2CBBC620',
          lineWidth: (ctx) => {
            // label は2行で表示しているため配列化している。
            const label = ctx.tick?.label
            return Array.isArray(label) && `${label[0]} ${label[1]}` === currentAnketLabel ? (isSmDown ? 20 : 40) : 0
          },
          drawBorder: false,
        },
        offset: true,
        ticks: {
          font: {
            family: ONBOARDING_FONT_FAMILY,
            size: isSmDown ? 10 : 10,
            lineHeight: 1.6,
          },
          color: COLOR_TEAMBUILDING_TEXT,
        },
      },
      xAxis2: {
        type: 'category',
        labels: summaryList ? summaryList.map((_) => '回答者を見る') : [],
        offset: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        // 参照
        // https://www.chartjs.org/docs/latest/axes/styling.html#tick-configuration
        ticks: {
          display: isShowLabel,
          color: COLOR_TEAMBUILDING_PRIMARY,
          font: {
            family: ONBOARDING_FONT_FAMILY,
            size: 10,
            weight: 'bold',
            lineHeight: 1,
          },
        },
      },
      y: {
        min: -5,
        max: 5,
        ticks: {
          display: false,
        },
        grid: {
          color: (ctx) =>
            ctx.tick.value === 0 ? COLOR_TEAMBUILDING_NEUTRAL_500 : `${COLOR_TEAMBUILDING_NEUTRAL_500}4D`,
          lineWidth: (ctx) => (ctx.tick.value === 0 || ctx.tick.value === 5 || ctx.tick.value === -5 ? 1.4 : 0.7),
          drawBorder: false,
        },
        offset: true,
      },
    },
    maintainAspectRatio: false,
  }

  /**
   * x軸の第2ラベルにアンダーラインを引く（スタイルをあてられないので画像の挿入で対応）。
   *
   * 参照
   * https://www.youtube.com/watch?v=erkTPJ4HfK4
   */
  const xScaleImage = {
    id: 'xScaleImage',
    afterDatasetsDraw(chart: ChartJS) {
      const {
        ctx,
        data,
        scales: { x },
      } = chart

      ctx.save()

      const datasets0: ExtendedChartDataset = data.datasets[0]
      datasets0.images?.forEach((image: any, i: number) => {
        const label = new Image()
        label.src = image
        const width = 63
        ctx.drawImage(label, x.getPixelForValue(i) - width / 2, x.top + 60, width, 1)
      })
    },
  }

  const linePlugins = [xScaleImage]

  const chartRef = React.useRef(null)

  return (
    <>
      <ReactChart id={chartId} type="line" data={dateData} options={lineOptions} plugins={linePlugins} ref={chartRef} />
      <ModalAnswerMember
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        answeredMembers={answeredMembers}
        unansweredMembers={unansweredMembers}
      />
    </>
  )
}
