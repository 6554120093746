import React, { useCallback, useEffect, useRef, useState, VoidFunctionComponent } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop'

import * as constants from '../../../../../assets/constants'
import Button from '../components/Button'

import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canvasPreview'

import imageIcon from '../assets/imageIcon.svg'

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  aspect: { height: number; width: number }
  src: string
  previewCanvasRef: React.RefObject<HTMLCanvasElement>
  onCroppedFile: (file?: File) => void
  onChangeThumbnail: () => void
}

const ImageCropper: VoidFunctionComponent<Props> = ({
  open,
  setOpen,
  aspect,
  src,
  previewCanvasRef,
  onCroppedFile,
  onChangeThumbnail,
}) => {
  const classes = useStyles()
  const imgRef = useRef<HTMLImageElement>(null)

  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

  const handleChange = useCallback((_: PixelCrop, percentCrop: PercentCrop) => {
    setCrop(percentCrop)
  }, [])

  const handleComplete = useCallback((c: PixelCrop) => {
    setCompletedCrop(c)
  }, [])

  const handleImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect.width / aspect.height))
    },
    [aspect]
  )

  const CroppedFile = useCallback(() => {
    previewCanvasRef.current?.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], 'croppedImage.png', { type: 'image/png' })
        onCroppedFile(file)
      }
    })
    setOpen(false)
  }, [previewCanvasRef, setOpen, onCroppedFile])

  useEffect(() => {
    if (imgRef.current && previewCanvasRef?.current && completedCrop) {
      canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0)
    }
  }, [completedCrop, previewCanvasRef])

  return (
    <>
      {open && (
        <div className={classes.flex}>
          <div className={classes.CropperBox}>
            <ReactCrop
              crop={crop}
              onChange={handleChange}
              onComplete={handleComplete}
              aspect={aspect.width / aspect.height}
              circularCrop
              style={{ borderRadius: 5 }}
            >
              <img
                className={classes.imageEditor}
                ref={imgRef}
                alt="cropped-img"
                src={src}
                onLoad={handleImageLoad}
                crossOrigin="anonymous"
              />
            </ReactCrop>
          </div>
          <div className={classes.editWrapper} onClick={onChangeThumbnail}>
            <img src={imageIcon} alt={imageIcon} className={classes.imageImg} />
            <div className={classes.imageText}>写真変更</div>
          </div>
          <Button
            bgColor={constants.COLOR_ONBOARDING_WHITE}
            textColor={constants.COLOR_ONBOARDING_MAIN}
            borderColor={constants.COLOR_ONBOARDING_MAIN}
            square={true}
            fontSize={16}
            fullWidth={true}
            body={'完了'}
            noShadow={true}
            height={40}
            style={{ marginBottom: 10 }}
            onClick={CroppedFile}
          />
          <div className={classes.cancelText} onClick={() => setOpen(false)}>
            キャンセル
          </div>
        </div>
      )}
    </>
  )
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CropperBox: {
    marginBottom: 16,
  },
  imageEditor: {
    width: '100%',
  },
  editWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto 24px 0',
    fontSize: 14,
    fontWeight: 'bold',
    color: constants.COLOR_ONBOARDING_MAIN,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageImg: {
    display: 'block',
  },
  imageText: {
    margin: '2px 0 0 8px',
  },
  cancelText: {
    fontSize: 12,
    fontWeight: 'bold',
    color: constants.COLOR_GRAY_DARK,
    margin: '16px 0 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default ImageCropper
