import React, { useCallback, Dispatch, SetStateAction } from 'react'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone, FileRejection } from 'react-dropzone'

import { NoStyleButton } from 'components/NoStyleButton'
import { FILE_MIME, IMG_MIME, VIDEO_MIME } from 'pages/onboarding/pages/timeline/hooks/teamStorage'
import { fileIcon, fileSize } from 'pages/onboarding/utils/attachmentFile'

import docxIcon from '../../../assets/fileTypeIcons/docxIcon.svg'
import pdfIcon from '../../../assets/fileTypeIcons/pdfIcon.svg'
import pptxIcon from '../../../assets/fileTypeIcons/pptxIcon.svg'
import txtIcon from '../../../assets/fileTypeIcons/txtIcon.svg'
import Button from '../components/Button'

import clear from '../assets/clear.svg'
import excel from '../assets/excel.svg'
import * as constants from 'assets/constants'

type Props = {
  uploadFile: File | undefined
  setUploadFile: Dispatch<SetStateAction<File | undefined>>
}

const fileLimit = { label: '10MB', byte: 10000000 }

export const DropZone = ({ uploadFile, setUploadFile }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      setUploadFile(acceptedFiles[0])
    },
    [setUploadFile]
  )

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    if (fileRejections[0].errors[0].code === 'file-too-large') {
      alert(`ファイルサイズが上限（${fileLimit.label}）を超えています。`)
      return
    }
    if (fileRejections[0].errors[0].code === 'file-invalid-type') {
      alert(
        `無効なファイル形式です。\n下記いずれかの形式でアップロードしてください。\n${[
          ...FILE_MIME,
          ...IMG_MIME,
          ...VIDEO_MIME,
        ].join(' ')}`
      )
      return
    }
    alert('このファイルはアップロードできません。別のファイルでお試しください。')
  }, [])

  const handleClear = useCallback(() => {
    if (window.confirm('アップロードを取り消しますか？')) {
      setUploadFile(undefined)
    }
  }, [setUploadFile])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    multiple: false,
    maxFiles: 1,
    maxSize: fileLimit.byte,
    accept: { 'application/octet-stream': FILE_MIME, 'image/*': IMG_MIME, 'video/*': VIDEO_MIME },
  })

  return (
    <>
      {!uploadFile ? (
        <div
          className={`${classes.dropzoneContainer}${isDragActive ? ' ' + classes.droppingBorder : ''}`}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <div className={classes.fileIconWrapper}>
            <img src={excel} alt="excel icon" className={classes.excelIcon} />
            <img src={pptxIcon} alt="Ppoint icon" className={classes.icons} />
            <img src={pdfIcon} alt="PDF icon" />
            <img src={docxIcon} alt="doc icon" className={classes.icons} />
            <img src={txtIcon} alt="text icon" />
          </div>
          <p className={classes.msg}>
            {uploadFile ? 'アップロードされたファイル' : '目標に関する資料があればファイルをアップロードして下さい'}
          </p>
          {isSmUp && (
            <>
              {isDragActive ? (
                <p className={classes.description}>ファイルをドロップしてください</p>
              ) : (
                <p className={classes.description}>ドラッグ＆ドロップでファイルをアップロード</p>
              )}
            </>
          )}

          {!isDragActive && (
            <div className={classes.selectButtonWrapper}>
              <Button
                bgColor={constants.COLOR_WHITE}
                textColor={constants.COLOR_MAIN_NEW}
                borderColor={constants.COLOR_MAIN_NEW}
                square={true}
                fontSize={12}
                body="ファイルを選択する"
                noShadow={true}
                height={31}
                onClick={open}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={classes.uploadedContainer}>
          <div className={classes.file}>
            <img src={fileIcon(uploadFile.name)} alt={'attachmentFile'} className={classes.fileIcon} />
            <div className={classes.fileInfo}>
              <div className={classes.fileName}>{uploadFile.name}</div>
              <div className={classes.fileSize}>サイズ：{fileSize(uploadFile.size)}</div>
            </div>
          </div>
          <NoStyleButton className={classes.clearButton} type="button" onClick={handleClear}>
            <img src={clear} alt={'attachmentFile'} className={classes.fileIcon} />
          </NoStyleButton>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    dropzoneContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '16px',
      backgroundColor: constants.COLOR_WHITE2,
      border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
      borderRadius: 4,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: '24px 16px',
        height: 160,
      },
    },
    droppingBorder: {
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
    },
    msg: {
      margin: '6px 0 0',
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
    },
    description: {
      margin: '4px 0 0',
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,
    },
    selectButtonWrapper: {
      marginTop: 8,
    },

    uploadedContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      height: 56,
      padding: '13px 16px',
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
    },
    file: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      flex: 1,
      minWidth: 0,
    },
    fileIcon: {
      height: 24,
    },
    fileInfo: {
      minWidth: 0,
      width: '100%',
    },
    fileName: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
      fontWeight: 'bold',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    fileSize: {
      marginTop: 2,
      color: constants.COLOR_GRAY3,
      fontSize: 10,
    },
    clearButton: {
      width: 24,
      height: 24,
      '& img': {
        width: 24,
      },
    },
    fileIconWrapper: {
      display: 'flex',
    },
    icons: {
      margin: '0 10px',
    },
    excelIcon: {
      width: 25,
    },
  }),
  { name: 'DropZone' }
)
