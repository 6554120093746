import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { LoadingCircular } from 'pages/teams/components'
import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'
import { useTeamMembers } from 'pages/teams/hooks'
import { ButtonToPlans } from 'pages/teams/pages/_tools/karte/components/ButtonToPlans'
import { useTeamKarteList } from 'pages/teams/pages/_tools/karte/hooks/teamKarte'
import { DataList } from 'pages/teams/pages/dashboard/components/tabs/contents/karte/components/DataList'
import { DescriptionTools } from 'pages/teams/pages/list/components'

import { Pages } from 'assets'
import { replacePathParams } from 'assets/history'

export const Karte: React.FC = () => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { teamMembers } = useTeamMembers(teamId)
  const { teamKarteList, loaded, loading } = useTeamKarteList(teamId)
  const { isLeader } = React.useContext(TeamsHooksContext)

  const handleOpenKarte = React.useCallback(async () => {
    if (teamId) {
      window.open(replacePathParams(Pages.TeamsToolKarteDiagnose, { teamId }))
    }
  }, [teamId])

  if (loading) return <LoadingCircular loading={loading} />

  // チームで一人もカルテを完了していない場合
  if (teamKarteList.length === 0) {
    return (
      <>
        <DescriptionTools
          title="チーム登録が完了したらカルテをはじめましょう。"
          recommendedTime="（診断時間：3分　対話推奨時間：6人で約1時間30分）"
          description={
            'カルテは「働きがい診断」でもあるので、働く上で大事にしている互いの価値観を知ることは、\nお互いを尊重し、チームの働きがいを高めることにもつながります。\n信頼関係を構築するための第一歩、相互理解を深めるために実施しましょう！'
          }
          buttonLabel="カルテを始める"
          handleOpenTool={handleOpenKarte}
          isMainColorBtn={isLeader}
        />
        {isLeader && (
          <div className={classes.buttonBox}>
            <ButtonToPlans isFirstPage />
          </div>
        )}
      </>
    )
  }

  return <DataList teamMemberList={teamMembers} teamKarteList={teamKarteList} loaded={loaded} />
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    buttonBox: {
      [theme.breakpoints.down('md')]: {
        display: 'grid',
        placeContent: 'center',
        marginTop: -8,
        paddingBottom: 24,
        backgroundColor: '#fff',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 16,
      },
    },
  }),
  { name: 'Karte' }
)
