import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import { useClipboardText } from '../useClipboardText'
import { IuseSocials } from '../useSocials'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: 0,
  },

  list: {
    listStyle: 'none',
    marginLeft: '21px',
  },

  button: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: 0,

    '&:focus': {
      outline: 'none',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  presenter: IuseSocials
}

// -----------------------------
// Component
// -----------------------------
export const SocialsShare = (props: IProps) => {
  const classes = useStyles()
  const location = useLocation()
  const { textRef } = useClipboardText(`${window.location.protocol}//${window.location.host}${location.pathname}`)

  return (
    <ul className={classes.root}>
      <li className={classes.list}>
        <TwitterShareButton url={`${window.location.protocol}//${window.location.host}${location.pathname}`}>
          <img src={'/assets/svg/hint/twitter_icn.svg'} alt={'twitter'} />
        </TwitterShareButton>
      </li>
      <li className={classes.list}>
        <FacebookShareButton url={`${window.location.protocol}//${window.location.host}${location.pathname}`}>
          <img src={'/assets/svg/hint/facebook_icn.svg'} alt={'facebook'} />
        </FacebookShareButton>
      </li>
      <li className={classes.list}>
        <button
          ref={textRef}
          className={classes.button}
          type="button"
          onClick={() => props.presenter?.onOpenSharePopup('web')}
        >
          <img src={'/assets/svg/hint/web_icn.svg'} alt={'web'} />
        </button>
      </li>
    </ul>
  )
}
