import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { GhostSample } from 'pages/obakesagashi/components/GhostSample'

import { constants } from 'assets'

export const GhostGuide: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')

  return (
    <section className={classes.root}>
      <WidthControlWrapper containerWidth={756}>
        <h2 className={classes.title}>
          <span>特徴や退治方法がわかる</span>
        </h2>
        <h3 className={classes.subtitle}>
          <span>オバケ探しでわかること・できること</span>
        </h3>
        {isMdUp && (
          <img
            className={classes.titleSideGhost}
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/guide-ghost.svg`}
            alt="タイトル横の黒いオバケ"
          />
        )}
        <div className={classes.desc}>
          このツールでは、
          <span className="marker">あなたが職場で見かけたオバケの中から、ランダムに最大3種類のオバケ</span>
          について、特徴や起こる現象、出没シーンや経緯、オバケへの対処方法・アドバイスを知ることができます。
          {isMdUp && <br />}
          {isMdUp && <br />}
          オバケがいることは、不利益なことばかりではなく、職場にご利益をもたらしている場合もあります。まずはあなたの職場やチームの状況を思い出しながらオバケを見つけていき、対処方法を学びましょう。
        </div>
      </WidthControlWrapper>
      <GhostSample />
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      backgroundColor: constants.COLOR_WHITE3,
      color: '#000',
    },
    title: {
      fontSize: 40,
      fontWeight: 'bold',
      textAlign: 'center',
      margin: 0,
      marginTop: 100,
      marginBottom: 20,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
        marginTop: 40,
        marginBottom: 8,
      },
    },
    subtitle: {
      fontSize: 24,
      textAlign: 'center',
      margin: 0,
      marginBottom: 40,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    titleSideGhost: {
      position: 'absolute',
      top: 45,
      right: '8%',
    },
    desc: {
      maxWidth: 756,
      fontSize: 16,
      lineHeight: 2,
      marginBottom: 70,
      '& .marker': {
        background: `linear-gradient(transparent 80%, ${constants.COLOR_YELLOW4} 0%)`,
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: 40,
      },
    },
  }),
  { name: 'GhostGuide' }
)
