import React, { useEffect, useState } from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ErrorSnapshot, handleRenderError } from 'pages/ghost/utils/error'

import { ErrorDialog, ForceResultDialog, ReloadDialog } from './Dialog'

const LOAD_TIMEOUT = 3000

export const LoadingIndicator: React.FC<{ snapshot: ErrorSnapshot }> = ({ snapshot }) => {
  const styles = useStyles()

  const [dialog, setDialog] = useState<ErrorDialog>()
  useEffect(() => {
    let ok = true
    const timer = setTimeout(async () => {
      if (ok) {
        const dialog = await handleRenderError(snapshot)
        setDialog(dialog)
      }
    }, LOAD_TIMEOUT)
    return () => {
      ok = false
      clearTimeout(timer)
    }
  }, [snapshot])

  const [bar, setBar] = useState(0)
  useEffect(() => {
    let ok = true
    setTimeout(() => {
      if (ok) setBar((bar) => bar + 1)
    }, 700)
    return () => {
      ok = false
    }
  }, [bar])

  if (!dialog)
    return (
      <div className={styles.container}>
        <div className={styles.indicator} data-testid="load-indicator">
          {Array.from(
            Array(3)
              .fill(0)
              .map((_, idx) => (
                <div key={`bar${idx}`} className={classNames([styles.bar, (bar % 4) - 1 >= idx ? 'on' : ''])}></div>
              ))
          )}
        </div>
      </div>
    )
  else {
    return (
      <div className={styles.dialogContainer}>
        {dialog === 'forceResult' ? <ForceResultDialog snapshot={snapshot} /> : <ReloadDialog />}
      </div>
    )
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '50vh',
  },
  indicator: {
    margin: '30vh auto 0',
    width: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bar: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: constGhost.COLOR_GRAY,
    '&.on': {
      backgroundColor: constGhost.COLOR_DARKBLUE,
    },
  },
  dialogContainer: {
    width: '100vh',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
  },
}))
