import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { useHandleTeamAnketStart } from 'services/api/anket'

import { NoStyleButton } from 'components/NoStyleButton'
import { DefaultButton } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import closeSvg from 'pages/teams/pages/dashboard/assets/close.svg'
import { Modal } from 'pages/teams/pages/list/components/atoms'
import { TeamAnketAnswerStatus as AnswerStatus, TeamMember, Maybe } from 'utils/generated'

import { Button } from './Button'

import { constants } from 'assets'

type AnketOpenProps = {
  isLeader: boolean | undefined
  isMember: boolean | undefined
  summary: {
    summaryCount: number
    answerStatus: Maybe<AnswerStatus> | undefined
    unansweredMemberList: Maybe<Array<TeamMember>> | undefined
    handleClickCloseSummary: () => void
    handleSendEmailToUnansweredTeamAnketMembers: () => void
    isSendingMail: boolean
  }
}

export const AnketOpen = ({ isLeader, isMember, summary }: AnketOpenProps) => {
  const {
    summaryCount,
    answerStatus,
    unansweredMemberList,
    handleClickCloseSummary,
    handleSendEmailToUnansweredTeamAnketMembers,
    isSendingMail,
  } = summary
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false)
  const { currentTeamMember, currentTeam } = React.useContext(HooksContext)
  const { handleTeamAnketStart, isLoading } = useHandleTeamAnketStart(currentTeamMember, currentTeam?.id)
  const answered = answerStatus === AnswerStatus.Answered

  const handleOpenModal = () => {
    setIsOpen(true)
  }

  const handleCloseModal = () => setIsOpen(false)

  const handleOpenWindowForAnswered = () => window.alert('アンケートは回答済みです')

  const handleCloseTBCheck = () => {
    if (
      window.confirm(
        'TBチェックを終了しますか？\n終了すると、未回答のメンバーは今回のTBチェックに回答できなくなります。'
      )
    ) {
      handleClickCloseSummary()
      setIsOpen(false)
    }
  }

  const handleSendMail = () => {
    if (window.confirm('未回答者に回答依頼のメールを送信しますか？')) {
      handleSendEmailToUnansweredTeamAnketMembers()
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div>{`第${summaryCount + 1}回目 開催中`}</div>

        {isLeader || isMember ? (
          <div className={classes.buttonWrapper}>
            <Button
              disabled={isLoading || answered}
              onClick={answered ? handleOpenWindowForAnswered : handleTeamAnketStart}
            >
              {answered ? '回答済み' : 'チェック開始'}
            </Button>
          </div>
        ) : null}

        {isLeader ? (
          <>
            <div className={classes.closeDesc}>
              TBチェックを終了する場合は、下記のボタンを押してください。{'\n'}
              確定する前に、未回答のメンバーを確認できます。
            </div>
            <div className={classes.buttonWrapper}>
              <Button onClick={handleOpenModal}>未回答メンバー確認</Button>
            </div>
          </>
        ) : null}
      </div>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen} paperStyle={{ maxWidth: 368, padding: '40px 24px' }}>
        <div className={classes.modal}>
          <NoStyleButton className={classes.closeButton} type="button" onClick={handleCloseModal}>
            <img src={closeSvg} alt="close" />
          </NoStyleButton>
          <div className={classes.modalTitle}>TBチェック回答状況</div>

          {unansweredMemberList && unansweredMemberList.length ? (
            <>
              <p>
                <strong>未回答</strong>のメンバーは以下のとおりです。
              </p>
              <div className={classes.memberList}>
                {unansweredMemberList.map((member) => (
                  <div key={member.id} className={classes.member}>
                    {member.username ?? member.fullName}
                  </div>
                ))}
              </div>

              <p>未回答者に回答依頼をお願いする場合は下のボタンをクリックしてください。</p>

              <div className={classes.modalButton}>
                <DefaultButton
                  title={isSendingMail ? '送信中...' : '未回答者再送メール送付'}
                  color={isSendingMail ? 'team_disabled' : 'team_main'}
                  onClick={handleSendMail}
                  style={{
                    padding: '8px 20px',
                    height: 48,
                    borderRadius: 24,
                  }}
                  disabled={isSendingMail}
                />
              </div>
            </>
          ) : (
            <p className={classes.allAnsweredMessage}>全員回答済みです</p>
          )}

          <div className={classes.modalButton}>
            <NoStyleButton className={classes.endButton} onClick={handleCloseTBCheck}>
              TBチェック終了
            </NoStyleButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 12,
      fontWeight: 'bold',
    },
    buttonWrapper: {
      marginTop: 16,
    },
    closeDesc: {
      marginTop: 32,
      color: constants.TEXT_GRAY_DARK,
      fontWeight: 'normal',
      whiteSpace: 'pre-wrap',
    },

    modal: {
      position: 'relative',
      width: '100%',
      color: constants.COLOR_GRAY_DARK,
      fontSize: 12,

      '& p': {
        margin: 0,
      },
    },
    closeButton: {
      position: 'absolute',
      top: -32,
      right: -16,
      width: 24,
      height: 24,

      '& img': {
        width: 14,
        verticalAlign: 'top',
      },
    },
    modalTitle: {
      marginBottom: 28,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    memberList: {
      margin: '8px 0',
      padding: '20px 16px',
      backgroundColor: constants.COLOR_WHITE2,
      color: constants.TEXT_GRAY_DARK,
      borderRadius: 8,
    },
    member: {
      marginBottom: 12,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:last-of-type': {
        marginBottom: 0,
      },
    },
    modalButton: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    allAnsweredMessage: {
      textAlign: 'center',
    },
    endButton: {
      color: constants.COLOR_RED4,
      fontSize: 12,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  }),
  { name: 'AnketOpen' }
)
