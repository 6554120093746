import React, { useContext } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { CircularProgress } from 'components/CircularProgress'
import { WaitingContainer } from 'pages/ghost/components/modules/WaitingContainer'
import { usePBForFixedUIs } from 'pages/ghost/hooks/fixedUI'
import { CommandContext } from 'pages/ghost/service/commands'
import { GhostMemberRole } from 'utils/generated'

import { GrayBackGround, BackResponsiveCard, UsefulCard, ButtonForGhost } from '../../components/atoms'
import { ButtonContainer } from '../../components/modules'
import { ghostTeamContext } from '../../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../../contexts/ghostTeamMember'

import * as constants from 'assets/constants'

export const User: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isBreakpointsUp = useMediaQuery(theme.breakpoints.up('md'))

  const { team } = useContext(ghostTeamContext)
  const { teamMember, loading } = useContext(ghostTeamMemberContext)
  const { runGoToIntro } = useContext(CommandContext)
  const isLeader = teamMember?.role === GhostMemberRole.Leader

  const pBForFixedUIs = usePBForFixedUIs({ status: 'break' })

  const onClick = () => {
    if (!team) return
    runGoToIntro(team)
  }

  if (loading) return <CircularProgress margin="150px auto" />

  return (
    <div>
      <GrayBackGround>
        <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
          <UsefulCard
            backgroundColor={'white'}
            ownStyles={
              isBreakpointsUp
                ? { margin: '0 auto', padding: '40px 40px 0 40px', maxWidth: 800 }
                : { margin: '0', padding: '24px 16px 0' }
            }
          >
            <div className={classes.container}>
              <img src={process.env.PUBLIC_URL + '/assets/ghost/personIcon.svg'} alt={'personIcon'} />
            </div>
            {isLeader ? (
              <div className={classes.wrapper}>
                <div className={classes.title}>あなたはファシリテーターです</div>
                <div>
                  <div className={classes.subTitle}>今回のオバケ対話のファシリーテーターです。</div>
                  <div className={classes.subTitle}>
                    ファシリテーターの主な仕事は以下の通りです。
                    <div className={classes.text}>・オバケ対話の司会進行</div>
                    <div className={classes.text}>・タイムマネジメント</div>
                  </div>
                  <div>準備ができたらスタートしましょう。</div>
                </div>
              </div>
            ) : (
              <div className={classes.wrapper}>
                <div className={classes.title}>あなたはメンバーです</div>
                <div className={classes.subTitle}>
                  ファシリテーターの進行に従って、オバケの対話を行って下さい。
                  <br />
                  ファシリテーターや他のメンバーが対話を進めるにあたって{isBreakpointsUp && <br />}
                  何か困っていたらチームで協力して合いましょう。
                </div>
              </div>
            )}
            <div className={classes.container}>
              <img
                src={process.env.PUBLIC_URL + '/assets/svg/ghostList.svg'}
                alt={`ghostList`}
                style={{ paddingTop: 24 }}
              />
            </div>
          </UsefulCard>
        </BackResponsiveCard>
      </GrayBackGround>
      {isLeader ? (
        <ButtonContainer
          fixed
          buttons={[
            <ButtonForGhost
              key="q1-end-1"
              buttonBodyColor={'green'}
              bodyText={<>スタート</>}
              onClick={onClick}
              isResizeBasic
            />,
          ]}
        />
      ) : (
        <WaitingContainer />
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    title: {
      fontSize: 16,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
      textAlign: 'center',
      marginBottom: 24,
      marginTop: 24,
    },
    subTitle: {
      fontSize: 14,
      color: constants.TEXT_GRAY_DARK,
      marginBottom: 24,
      lineHeight: 1.6,
    },
    text: {
      fontWeight: 'bold',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
    },
    wrapper: {
      padding: '0 16px 16px 16px ',
      [theme.breakpoints.up('sm')]: {
        padding: '0 150px',
      },
    },
    position: {
      top: 0,
      position: 'sticky',
    },
  }),
  { name: 'User' }
)
