import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootStateType } from 'store'

import { BackButton } from 'pages/hint/_shared/components/buttons/backButton/Index'
import { useBackButton } from 'pages/hint/_shared/components/buttons/backButton/useBackButton'
import { CommentList } from 'pages/hint/_shared/components/commentList/Index'
import { DialogComment } from 'pages/hint/_shared/components/dialogs/dialogComment/Index'
import { useDialogComment } from 'pages/hint/_shared/components/dialogs/dialogComment/useDialogComment'
import { DialogCopy } from 'pages/hint/_shared/components/dialogs/dialogCopy/Index'
import { Slide } from 'pages/hint/_shared/components/slide/Index'
import { SocialsShare } from 'pages/hint/_shared/components/socials/socialsShare/Index'
import { useSocials } from 'pages/hint/_shared/components/socials/useSocials'
import { hintContext } from 'pages/hint/_shared/context/useHintContext'
import { useDocument } from 'pages/hint/_shared/hooks/useDcoment'
import { useGetConsultationEpic } from 'pages/hint/_shared/hooks/useGetConsultationEpic'
import { WrapperComment, WrapperForm, WrapperInFaq, WrapperSocial } from 'pages/hint/_shared/layouts/article/Index'
import { Container } from 'pages/hint/_shared/layouts/container/Index'
import { Main } from 'pages/hint/_shared/layouts/main/Index'
import { Wrapper } from 'pages/hint/_shared/layouts/wrapper/Index'



import { Modal } from '../_shared/components/modal/modal/Index'

import { Article } from './components/article/Index'
import CommentForm from './components/commentForm/Index'
import { useFaqArticle } from './hooks/useFaqArticle'

import * as constants from 'assets/constants'

// -----------------------------
// Component
// -----------------------------
const Index = () => {
  // -----------------------------
  // Hooks
  // -----------------------------
  const document = useDocument()
  const consultationEpic = useGetConsultationEpic()
  const dialogComment = useDialogComment()
  const socials = useSocials()
  const back = useBackButton()
  const location = useLocation()
  const faqArticle = useFaqArticle()
  const context = React.useContext(hintContext)

  const hintSelector = useSelector((state: RootStateType) => state.hint)
  const commonSelector = useSelector((state: RootStateType) => state.hint.common)

  // -----------------------------
  // LifeCycle
  // -----------------------------
  React.useEffect(() => {
    if (commonSelector.error) {
      alert(commonSelector.error.error)
    }
  }, [commonSelector])

  React.useEffect(() => {
    document.onSetDocumentHint(hintSelector.consultation.title, hintSelector.consultation.contentPreview)
    const consultationID = location.pathname.split('/').pop()
    if (consultationID) {
      consultationEpic.getConsultationEpic(consultationID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <>
      {/** Modal */}
      {context.isShowModal && (
        <Slide in={context.isShowModal} direction={'up'}>
          <Modal />
        </Slide>
      )}

      {socials.getWeb() && <DialogCopy open={socials.getWeb()} onClose={socials.onCloseDialog} />}

      {dialogComment.getOpen() && (
        <DialogComment
          open={dialogComment.getOpen()}
          onClose={dialogComment.onClose}
          presenter={dialogComment}
          params={faqArticle.getCommentParams()}
        />
      )}

      {/** Wrapper */}
      <Wrapper color={constants.COLOR_WHITE}>
        <WrapperInFaq>
          <Container maxWidth={780}>
            {/** Main */}
            <Main>
              <Box mb="40px">
                <BackButton onClick={() => back.goBack()} />
              </Box>

              <Article consultationData={hintSelector.consultation} />

              <WrapperSocial>
                <SocialsShare presenter={socials} />
              </WrapperSocial>

              {faqArticle.isShowForm() && (
                <WrapperForm>
                  <CommentForm presenter={faqArticle} handler={dialogComment.onSubmitHandler} />
                </WrapperForm>
              )}

              {faqArticle.isShowComments() && (
                <WrapperComment>
                  <CommentList
                    commentsHeading={'回答'}
                    commentsNum={hintSelector?.consultationCommentList?.length}
                    comments={hintSelector?.consultationCommentList}
                  />
                </WrapperComment>
              )}
            </Main>
          </Container>
        </WrapperInFaq>
      </Wrapper>
    </>
  )
}

export default Index
