import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { ButtonForGhost } from 'pages/ghost/components/atoms'
import { ButtonContainer, WaitingMessage } from 'pages/ghost/components/modules'
import { MemberCards } from 'pages/teams/components'
import { FeatureStatus } from 'utils/generated'

import { IceBreakContext } from '../contexts/iceBreakContext'

import { Histories } from './Histories'

import { constants } from 'assets'

export const Start: React.FC = () => {
  const classes = useStyles()
  const {
    isLoading,
    isLeader,
    handleCancelLeader,
    handleCancelMember,
    handleUpdateByLeader,
    iceBreakContents,
    teamMembers,
    own,
    histories,
    isRefreshedHistories,
  } = React.useContext(IceBreakContext)

  const disabled = iceBreakContents.activeUserIds.length < 2 || isLoading
  const propsMemberCards = {
    members: teamMembers,
    activeMemberIds: iceBreakContents.activeUserIds,
    ownUserId: own?.userId,
    leaderUserId: iceBreakContents.leaderUserId,
  }

  if (!isRefreshedHistories) return

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>メンバーの参加を待っています。</h1>
      <p className={classes.body}>
        2人以上のメンバーが揃ったら「開始する」をクリックしてください。
        <br />
        アイスブレイクを実施しない場合は、画面下の「キャンセル」ボタンを押してください。
        <br />
        まだ記載がないメンバーがいる場合は、メンバーが画面上に表示されるまでお待ち下さい。
        <br />
        <br />
        なお、1人目にこのページにアクセスしたメンバーが、アイスブレイクのテーマ設定を行う担当になっています。
      </p>
      <WaitingMessage ownStyles={{ marginTop: 32 }} />

      <div className={classes.memberCardsWrapper}>
        <MemberCards {...propsMemberCards} />
      </div>
      <div className={classes.historiesWrapper}>
        {histories && histories.length > 0 ? (
          <div className={classes.historiesBox}>
            <Histories histories={histories} />
          </div>
        ) : null}
      </div>

      <ButtonContainer
        fixed
        buttons={
          isLeader
            ? [
                <ButtonForGhost
                  key="leader-start"
                  buttonBodyColor={disabled ? 'gray' : 'green'}
                  bodyText={isLoading ? '読込中' : '開始する'}
                  onClick={() => handleUpdateByLeader({ status: FeatureStatus.SelectTheme })}
                  disabled={disabled}
                  isResizeBasic
                />,
                <ButtonForGhost
                  key="leader-cancel"
                  buttonBodyColor="white"
                  bodyText="キャンセル"
                  onClick={handleCancelLeader}
                  isResizeBasic
                />,
              ]
            : [
                <ButtonForGhost
                  key="member-cancel"
                  buttonBodyColor="white"
                  bodyText="参加を辞退する"
                  onClick={handleCancelMember}
                  isResizeBasic
                />,
              ]
        }
      />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      paddingBottom: 132,
      [theme.breakpoints.up('sm')]: {
        paddingBottom: 86,
      },
    },
    title: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 'bold',
    },
    body: {
      margin: '24px 0 0',
      fontSize: 14,
    },
    memberCardsWrapper: {
      marginTop: 24,
    },
    historiesWrapper: {
      margin: '32px 0 24px',
    },
    historiesBox: {
      padding: '24px 16px',
      backgroundColor: '#fff',
      borderRadius: 8,
      [theme.breakpoints.up('sm')]: {
        padding: 24,
      },
    },
  }),
  { name: 'Start' }
)
