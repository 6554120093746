import * as React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useLink } from '../../hooks/useLink'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '50%',
    backgroundColor: '#e3e3e3',
  },

  img: {
    objectFit: 'cover',
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  src: string | null
  alt: string
  size?: number
  path?: string
}

// -----------------------------
// Component
// -----------------------------
export const Avatar = (props: IProps) => {
  const link = useLink()
  const classes = useStyles()

  return (
    <>
      <div
        className={classes.root}
        onClick={props.path ? () => link.onLinkHanlder(props.path ? props.path : '') : undefined}
        style={{
          width: props.size,
          height: props.size,
        }}
      >
        {props.src ? (
          <img
            className={classes.img}
            src={props.src ? props.src : ''}
            alt={props.alt}
            width={`${props.size}px`}
            height={`${props.size}px`}
          />
        ) : null}
      </div>
    </>
  )
}
