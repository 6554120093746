import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

const contentReasons = {
  title: '出てきた理由とオバケの特徴',
  imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/sample-black-ghost.svg`,
  descItems: [
    {
      subtitle: 'オバケの出没経緯',
      desc: '個人に目標を渡し、個人だけで業務が遂行できる仕組みを作り、個人のパフォーマンス発揮を重視したことから、オバケが発生したと思われます',
    },
    {
      subtitle: 'オバケのご利益',
      desc: 'このオバケには、「個人のパフォーマンスを高める」という強みもあり、組織全体としてのパフォーマンスも結果的に高くなる可能性もあります。',
    },
    {
      subtitle: 'オバケの悪さ',
      desc: 'このオバケがいることで、支援が得られにくく、また一体感がも感じづらくなるため、部署に対する貢献意欲が下がる可能性があります。',
    },
  ],
}

const contentActions = {
  title: '出てきたらどうしたらいい？',
  imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/sample-black-ghost.svg`,
  descItems: [
    {
      subtitle: 'オバケを退治するアクション例',
      desc: '部署で一丸となる目標を明確にし、部署全体で認識する場を設ける',
      exampleItems: [
        '・ペア制やチーム制等、組織体制を変更してみる',
        '・部署で集まり、達成したことを皆で盛大に承認し合う機会を作る',
        '・自身が行っている仕事内容や成果を定期的に発信し、周囲へ理解してもらうよう促す',
      ],
    },
    {
      subtitle: 'オバケがいなくなったときの副作用',
      desc: 'このオバケには、「多様な人を受け入れることができる」という強みもあるため、オバケに対処しすぎると、以下のようなリスクもあります。',
      exampleItems: [
        '・チーム単位で動くことから情報共有や意思疎通に工数がかかる',
        '・スピード対応が求められる時などに意思決定スピードが遅くなる',
        '・一人で仕事を進めていく上で培われる能力が減り、成長スピードが下がる',
      ],
    },
  ],
}

export const GhostSample: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useCustomMediaQuery('up', 'md')

  return (
    <div className={classes.contents}>
      <WidthControlWrapper containerWidth={1000}>
        <div className={classes.contentCard}>
          <img
            className={classes.ghostImg}
            src={`${process.env.PUBLIC_URL}/assets/ghost/ghost7.svg`}
            alt="個人プレイヤーオバケ"
          />
          <div>
            <h3 className={classes.contentTitle}>オバケの特徴例</h3>
            <div className={classes.cardDesc}>
              <span className={classes.number}>FILE NO.7</span>
              <span className={classes.name}>個人プレイヤーオバケ</span>
              <p className={classes.text}>
                このオバケがいることで、支援が得られにくく、また一体感も感じづらくなるため、部署に対する貢献意欲が下がる可能性があります。
              </p>
            </div>
          </div>
          <img
            className={classes.sampleWhite}
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/sample-white-ghost.svg`}
            alt="サンプル白オバケ"
          />
        </div>
        <div className={classes.contentGuide}>
          <div className={classes.section}>
            <h3 className={classes.sectionTitle}>{contentReasons.title}</h3>
            <img className={classes.sampleBlack} src={contentReasons.imageSrc} alt="サンプル黒オバケ" />
            {contentReasons.descItems.map((item, index) => (
              <div key={index}>
                <div className={classes.descItemTitle}>{item.subtitle}</div>
                <p className={classes.descItemDesc}>{item.desc}</p>
              </div>
            ))}
          </div>
          <div className={classes.section}>
            <h3 className={classes.sectionTitle}>{contentActions.title}</h3>
            <img className={classes.sampleBlack} src={contentActions.imageSrc} alt="サンプル黒オバケ" />
            {contentActions.descItems.map((item, index) => (
              <div key={index}>
                <div className={classes.descItemTitle}>{item.subtitle}</div>
                <p className={classes.descItemDesc}>{item.desc}</p>
                <ul>
                  {item.exampleItems.map((example, index) => (
                    <li key={index} className={classes.exampleItem}>
                      {example}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {isMdUp && (
          <img
            className={classes.sideGhost}
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/side-ghost.svg`}
            alt="左にいる白いオバケ"
          />
        )}
      </WidthControlWrapper>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    contents: {
      position: 'relative',
    },
    //----------------------------------
    //  contentCard
    //----------------------------------
    contentCard: {
      position: 'relative',
      display: 'flex',
      gap: 40,
      justifyContent: 'flex-start',
      marginBottom: 38,
      padding: '45px 120px',
      background: `no-repeat bottom / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/brick-sample-card-back.png)`,
      borderRadius: 16,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
      [theme.breakpoints.down('md')]: {
        padding: '35px 55px',
        borderRadius: 6,
      },
      [theme.breakpoints.down('sm')]: {
        gap: 15,
        marginBottom: 12,
        padding: '15px',
      },
    },
    ghostImg: {
      width: '100%',
      maxWidth: 300,
      [theme.breakpoints.down('md')]: {
        width: '35%',
      },
    },
    contentTitle: {
      margin: 0,
      marginBottom: 20,
      color: constants.COLOR_WHITE,
      fontSize: 30,
      fontFamily: '"MOBO SemiBold"',
      [theme.breakpoints.down('md')]: {
        marginBottom: 10,
        fontSize: 'clamp(10px, 3vw, 30px)',
      },
    },
    cardDesc: {
      width: 350,
      padding: '32px 56px',
      background: 'rgba(0, 0, 0, 0.3)',
      borderRadius: 17,
      [theme.breakpoints.down('md')]: {
        width: '85%',
        maxWidth: 350,
        padding: '16px 28px',
        borderRadius: 6,
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        maxWidth: 250,
        padding: '8px 16px',
      },
    },
    number: {
      display: 'block',
      color: constants.COLOR_WHITE,
      fontSize: 12,
      fontFamily: "'din-2014', sans-serif",
      [theme.breakpoints.down('md')]: {
        fontSize: 'clamp(5px, 1.5vw, 12px)',
      },
    },
    name: {
      color: constants.COLOR_YELLOW4,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: 'clamp(7px, 2vw, 16px)',
      },
    },
    text: {
      margin: 0,
      marginTop: 18,
      lineHeight: 1.7,
      color: constants.COLOR_WHITE,
      fontSize: 14,
      [theme.breakpoints.down('md')]: {
        marginTop: 4,
        fontSize: 'clamp(6px, 1.5vw, 14px)',
      },
    },
    sampleWhite: {
      position: 'absolute',
      right: '30px',
      bottom: '10px',
      [theme.breakpoints.down('md')]: {
        width: 250,
      },
      [theme.breakpoints.down('sm')]: {
        right: '10px',
        width: '30%',
      },
    },
    //----------------------------------
    //  contentGuide
    //----------------------------------
    contentGuide: {
      display: 'flex',
      gap: 33,
      color: '#000',
      [theme.breakpoints.down('md')]: {
        gap: 11,
      },
    },
    section: {
      position: 'relative',
      padding: '24px 28px',
      width: '50%',
      background: constants.COLOR_WHITE,
      borderRadius: 16,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        padding: '8px 10px',
        borderRadius: 6,
      },
      '& ul': {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
      '& li': {
        lineHeight: 1.7,
      },
    },
    sectionTitle: {
      margin: 0,
      color: constants.COLOR_NAVY,
      fontSize: 20,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: 'clamp(6px, 1.8vw, 20px)',
      },
    },
    descItemTitle: {
      margin: '16px 0 8px',
      fontSize: 14,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        margin: '6px 0 3px',
        fontSize: 'clamp(5px, 1.5vw, 14px)',
      },
    },
    descItemDesc: {
      margin: 0,
      marginBottom: 16,
      fontSize: 14,
      lineHeight: 1.6,
      [theme.breakpoints.down('md')]: {
        marginBottom: 2,
        fontSize: 'clamp(4px, 1.2vw, 14px)',
      },
    },
    exampleItem: {
      fontSize: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: 'clamp(4px, 1.2vw, 14px)',
      },
    },
    sampleBlack: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      [theme.breakpoints.down('md')]: {
        width: '70%',
      },
    },
    sideGhost: {
      position: 'absolute',
      width: 151,
      top: '-100px',
      left: 0,
      [theme.breakpoints.up('lg')]: {
        top: '-200px',
      },
    },
  }),

  { name: 'GhostSample' }
)
