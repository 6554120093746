import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Control } from 'react-hook-form'

import { TeamBuildingReviewHooksContext } from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { TeamBuildingReviewTeamAnswerType } from 'utils/generated'

import { Answers } from '..'

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentSection } from './generic/ContentSection'
import { Step2QuestionSlider } from './step2/Step2QuestionSlider'




type Props = {
  title: string
  subtitle: string
  control: Control<Answers>
}

export const Step2Input: React.FC<Props> = ({ title, subtitle, control }) => {
  const classes = useStyles()
  const { currentUser, summaryBuilding, teamBuildingReviews } = React.useContext(TeamBuildingReviewHooksContext)

  const prevTeamBuildingReview = React.useMemo(() => {
    if (teamBuildingReviews && teamBuildingReviews.length > 1) {
      return teamBuildingReviews[1]
    }
    return undefined
  }, [teamBuildingReviews])

  const getPrevValue = React.useCallback(
    (type: TeamBuildingReviewTeamAnswerType, index: number) => {
      const targetAnswer = prevTeamBuildingReview?.answers?.find(
        (answer) => answer.type === type && answer.index === index
      )
      return targetAnswer?.items.find((item) => item.userId === currentUser?.id)
    },
    [currentUser, prevTeamBuildingReview]
  )
  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection title="チーム目標">
        <Content>
          {summaryBuilding?.goal && (
            <Step2QuestionSlider
              control={control}
              error={undefined}
              label={summaryBuilding?.goal}
              name={'goal'}
              prevValue={getPrevValue(TeamBuildingReviewTeamAnswerType.TeamGoal, 0)}
            />
          )}
        </Content>
      </ContentSection>
      <ContentSection title="行動規範">
        {summaryBuilding?.codeOfConducts?.map((codeOfConduct, i) => (
          <Content key={`codeOfConducts[${i}]`}>
            <Step2QuestionSlider
              control={control}
              error={undefined}
              label={codeOfConduct}
              name={`codeOfConducts[${i}]`}
              prevValue={getPrevValue(TeamBuildingReviewTeamAnswerType.CodeOfConduct, i)}
            />
          </Content>
        ))}
      </ContentSection>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}))
