import * as React from 'react'
import { RouteComponentProps, useHistory, Link } from 'react-router-dom'

import { Dialog } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useForm } from 'react-hook-form'
import { useUtils } from 'services/api/utils'

import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { SignInLocationState } from 'pages/signin/index.component'

import * as constants from '../../assets/constants'
import { CODE_MISMATCH_EXCEPTION, EXPIRED_CODE_EXCEPTION, USERNAME_EXISTS_EXCEPTION } from '../../assets/errorCode'
import MessagePanel from '../../components/MessagePanel'
import { useConvertTeamMember } from '../teams/hooks/convertTeamMember'

import { connector, ContainerProps } from './confirm.container'
import { useAnket } from './hooks/anket'
import { storageKeySignIn } from './index.component'

export interface ISignupConfirm {
  email: string
  code: string
}

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const { getEncryptString } = useUtils()
  const history = useHistory()

  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')
    const code = params.get('code')

    if (email) {
      getEncryptString({ encrypt: false, text: email }).then((decrypted) => {
        if (decrypted) {
          setValue('email', decrypted)

          if (code) {
            setValue('code', code)
            props.confirmSignUp({
              code,
              email: decrypted,
            })
          }
        }
      })
    } else {
      props.history.push('/')
      window.scrollTo(0, 0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { classes } = props
  const { errors, clearErrors, setValue } = useForm<ISignupConfirm>()

  const { convertFakeUserOnboardingTeamMember } = useTeamManage()
  const { convertTeamMember } = useConvertTeamMember()
  const { createSingupAnket } = useAnket()
  const query = new URLSearchParams(props.location.search)

  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const email = params.get('email')

    if (props.user.confirmed) {
      if (props.user.fetched && props.user.email) {
        createSingupAnket(props.user.email)
        convertFakeUserOnboardingTeamMember()
        convertTeamMember()
        props.history.push(query.get('r') || constants.PAGE_TOP)
      } else {
        const r = localStorage.getItem(storageKeySignIn)
        props.history.push({
          pathname: constants.PAGE_SIGNIN + (r ? `?r=${r}` : ''),
          state: { confirmedEmail: email },
        } as { pathname: string; state: SignInLocationState })
        localStorage.removeItem(storageKeySignIn)
      }

      window.scrollTo(0, 0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])

  const handleClose = () => {
    history.replace(constants.PAGE_SIGNIN)
    clearErrors()
    props.refreshErrors()
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        <div className="__signup_confirm_container">
          <Dialog
            open={!props.user?.error && !errors.code ? false : true}
            onClose={handleClose}
            className={classes.infoDialog}
          >
            <div
              style={{
                display: !props.user?.error && !errors.code ? 'none' : 'block',
              }}
            >
              <MessagePanel
                failure={true}
                handleClose={handleClose}
                body={
                  errors.code ? (
                    errors.code.message
                  ) : props.user?.error && props.user?.error?.code === CODE_MISMATCH_EXCEPTION ? (
                    <>
                      <p>コードが認証できませんでした。</p>
                      <p>確認コードを確認の上再度認証してください。</p>
                    </>
                  ) : props.user?.error?.code === EXPIRED_CODE_EXCEPTION ? (
                    <>
                      <p>コードの有効期限が切れています。</p>
                      <p>確認コードを再送信して認証してください。</p>
                    </>
                  ) : props.user?.error?.code === USERNAME_EXISTS_EXCEPTION ? (
                    <>
                      <p>
                        このメールアドレスは既に登録されています。
                        <Link to={constants.PAGE_SIGNIN} className={classes.link}>
                          ログイン
                        </Link>
                        してご利用ください。
                      </p>
                    </>
                  ) : (
                    <>
                      <p>認証できませんでした。</p>
                      <p>ユーザーが登録済みや、認証URLが正しくない場合は正常に完了しません。</p>
                    </>
                  )
                }
              />
            </div>
          </Dialog>
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      marginTop: -64,
      minHeight: '100vh',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,

      '& .__signup_confirm_container': {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
          maxWidth: 1000,
        },
      },
    },

    infoDialog: {
      '& .MuiDialog-paper': {
        margin: '32px 24px',
        borderRadius: 8,
        maxWidth: 327,
      },
      '& .__buttonsWrapper': {
        display: 'flex',
        justifyContent: 'center',
        '& .__buttons': {
          margin: '40px auto 0',
          width: 200,
          [theme.breakpoints.down('md')]: {
            width: 250,
          },
        },
      },
    },

    link: {
      color: constants.COLOR_MAIN_NEW,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  })

export default withStyles(useStyles)(connector(Index))
