import * as React from 'react'

import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { ghostTeamMemberContext } from 'pages/ghost/contexts/ghostTeamMember'

import { Font, UsefulCard } from '../atoms'

import { constants } from 'assets'

type Props = {
  submitResearch: () => void
  handleChange: (value: string, type: 'feeling' | 'nextAction') => void
  formState: { feeling: string; nextAction: string }
  hasFeelingError: boolean
  hasNextActionError: boolean
}

type Step4FormBoxProps = {
  handleChange: (val: string, type: 'feeling' | 'nextAction') => void
  text: string
  placeholder: string
  type: 'feeling' | 'nextAction'
  isSmDown: boolean
}

const Step4Form: React.FC<Step4FormBoxProps> = ({ handleChange, text, placeholder, type, isSmDown }) => {
  const classes = useStyles(isSmDown)

  return (
    <textarea
      maxLength={500}
      className={classes.textArea}
      value={text}
      onChange={(e) => handleChange(e.target.value, type)}
      placeholder={placeholder}
    />
  )
}

export const Step4ReportBox: React.FC<Props> = ({ handleChange, formState, hasFeelingError, hasNextActionError }) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles(isSmDown)
  const { teamMember } = React.useContext(ghostTeamMemberContext)

  return (
    <div className={classes.root}>
      <UsefulCard
        backgroundColor="white"
        borderRadius={8}
        ownStyles={{ marginTop: 24, padding: isSmDown ? '16px' : '40px' }}
      >
        <Font fontSize={14} bold>
          気づいたこと / 感じたこと
        </Font>
        <Font fontSize={10}>※この後全体に公開されます</Font>
        {teamMember?.role === 'LEADER' && <Font fontSize={10}>※ファシリテーターは入力しなくても先へ進めます</Font>}
        <Step4Form
          handleChange={handleChange}
          text={formState['feeling']}
          placeholder="今回のオバケ対話をして気づいたことは…"
          type="feeling"
          isSmDown={isSmDown}
        />
      </UsefulCard>
      {hasFeelingError && (
        <Box className={classes.errorMessageBox}>「気づいたこと」「感じたこと」を記入してください</Box>
      )}
      <UsefulCard
        backgroundColor="white"
        borderRadius={8}
        ownStyles={{ marginTop: 24, padding: isSmDown ? '16px' : '40px' }}
      >
        <Font fontSize={14} bold>
          明日から出来そうなアクション
        </Font>
        <Font fontSize={10}>※この後全体に公開されます</Font>
        {teamMember?.role === 'LEADER' && <Font fontSize={10}>※ファシリテーターは入力しなくても先へ進めます</Font>}
        <Step4Form
          handleChange={handleChange}
          text={formState['nextAction']}
          placeholder="明日から出来そうなアクションは…"
          type="nextAction"
          isSmDown={isSmDown}
        />
      </UsefulCard>
      {hasNextActionError && (
        <Box className={classes.errorMessageBox}>「明日から出来そうなアクション」を記入してください</Box>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      marginBottom: 110,
    },
    textArea: (isSmDown) => ({
      marginTop: 10,
      padding: 16,
      border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
      color: constGhost.COLOR_BLACK3,
      fontSize: 14,
      width: '100%',
      height: isSmDown ? 203 : 177,
      display: 'block',
      borderRadius: 4,
      resize: 'none',
      fontFamily: constants.ONBOARDING_FONT_FAMILY,
      '&:focus-visible': {
        outline: 'none',
      },
      '&::placeholder': {
        color: constGhost.COLOR_GRAYTEXT,
      },
    }),
    errorMessageBox: {
      textAlign: 'center',
      color: constGhost.COLOR_RED,
      marginTop: 16,
      marginBottom: 16,
    },
  }),
  { name: 'Step4ReportBox' }
)
