import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

type Props = {
  style?: React.CSSProperties
  parentPadding?: number
}

// チーム追加・一括追加ページで共通している padding 値
const DEFAULT_PARENT_PADDING = 24
const DEFAULT_SP_PARENT_PADDING = 16

export const HorizontalLine: React.FC<Props> = ({ style, parentPadding }) => {
  const isSmDown = useCustomMediaQuery('down', 'md')
  const classes = useStyles({
    parentPadding: parentPadding ?? (isSmDown ? DEFAULT_SP_PARENT_PADDING : DEFAULT_PARENT_PADDING),
  })

  return <hr className={classes.root} style={style} />
}

type StyleProps = {
  parentPadding: number
}

const useStyles = makeStyles<Theme, StyleProps>(
  {
    root: ({ parentPadding }) => ({
      height: 2,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      border: 'none',
      margin: 0,

      width: `calc(100% + ${parentPadding * 2}px)`, // 両側の padding の合計を加える
      marginLeft: `-${parentPadding}px`, // 左側の padding の分だけマイナスする
    }),
  },
  { name: 'HorizontalLine' }
)
