import React from 'react'

import { Button } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Addresses } from 'utils/generated'

import { useUtilAddress } from '../hooks'

import { constants } from 'assets'

interface Props {
  zipcode?: string | null
  callBack: (result: Addresses) => void
}

export const SearchAddressButton: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { getAddresses } = useUtilAddress()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)

  const handleOnClick = async () => {
    setError(false)

    if (props.zipcode) {
      setLoading(true)
      const addresses = await getAddresses(props.zipcode)

      if (addresses) {
        props.callBack(addresses)
      } else {
        setError(true)
      }
      setLoading(false)
    }
  }

  return (
    <div className={classes.btnContainer}>
      <Button
        className={`${classes.root} ${loading ? classes.loading : ''}`}
        onClick={!loading ? handleOnClick : undefined}
      >
        住所検索
      </Button>
      {error ? <label className={classes.errorLabel}>住所が取得できませんでした</label> : <></>}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  btnContainer: {
    '& .MuiButton-root:hover': {
      backgroundColor: `${constants.COLOR_WHITE}`,
    },
  },
  root: {
    height: 50,
    fontSize: 14,
    fontWeight: 'bold',
    color: constants.COLOR_MAIN_NEW,
    padding: '2px 12px',
    backgroundColor: constants.TEXT_WHITE,
    borderRadius: 80,
    cursor: 'pointer',
    border: 'solid 1px #00838C',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: '2px 7px',
      height: 45,
      width: '100%',
      maxWidth: 150,
    },
  },

  loading: {
    opacity: 0.3,
  },

  errorLabel: {
    fontSize: 11,
    color: constants.COLOR_RED,
    marginLeft: 8,
  },
}))
