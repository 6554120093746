import * as React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Modal, OutlinedInput, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import { Comment } from 'pages/onboarding/components/comment'
import { useOnboardingPostActions } from 'pages/onboarding/hooks/timeline'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { dateStringToLPassedTime } from 'utils/date'
import { OnboardingPostComment, OnboardingTeamMember } from 'utils/generated'


import NoAvatarSvg from './list-item/assets/noAvatar.svg'

import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'


export type Props = {
  teamId: string
  teamMembers: OnboardingTeamMember[]
  item: OnboardingPostComment
  userId?: string
}

interface IInput {
  text: string
}

export const CommentListItem: React.FC<Props> = ({ teamId, teamMembers, item, userId }) => {
  const classes = useStyles()
  const history = useOnbHistory()

  const [openEdit, setOpenEdit] = React.useState(false)
  const handleEdit = () => {
    setOpenEdit(true)
  }

  const { deleteComment, updateComment } = useOnboardingPostActions()

  const handleDelete = () => {
    const confirm = window.confirm('本当に削除しますか？')

    if (confirm) {
      deleteComment(item.id, item.postId)
      setOpenEdit(false)
    }
  }

  const { register, handleSubmit } = useForm<IInput>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data: IInput) => {
    await updateComment(item.id, item.postId, data.text)
    setOpenEdit(false)
  }

  return (
    <div className={classes.userContainer}>
      {item.teamMember?.imageUrl ? (
        <img
          className={classes.userAvatar}
          src={item.teamMember?.imageUrl}
          alt={'avatar'}
          onClick={() => history.push(OnbPages.TeamMember, { teamId, userId: item.userId })}
        />
      ) : (
        <img
          className={classes.userAvatar}
          src={NoAvatarSvg}
          alt={'no-avatar'}
          onClick={() => history.push(OnbPages.TeamMember, { teamId, userId: item.userId })}
        />
      )}
      <div className={classes.userContent}>
        <div className={classes.userNameContainer}>
          <div className={classes.userName}>{item.teamMember?.nickname}</div>
          <div className={classes.userTime}>
            {item.createdAt ? dateStringToLPassedTime(item.createdAt, 'time') : ''}
          </div>
        </div>
        <div className={classes.userTitleContainer}>
          <div className={classes.buttonContainer}>
            {item.userId === userId ? (
              <>
                <IconButton size={'small'} onClick={handleEdit}>
                  <EditIcon
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>

                <IconButton size={'small'} onClick={handleDelete}>
                  <DeleteIcon
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>

                <Modal open={openEdit} className={classes.modal}>
                  <div className={classes.modalCard}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <OutlinedInput
                        name="text"
                        className={classes.modalInput}
                        inputRef={register({
                          required: true,
                        })}
                        multiline={true}
                        defaultValue={item.text}
                      />
                      <div className={classes.modalButtonContainer}>
                        <Button
                          body={'キャンセル'}
                          bgColor={constants.COLOR_SECOND}
                          onClick={() => {
                            setOpenEdit(false)
                          }}
                          style={{ width: '50%', marginRight: 8 }}
                        />
                        <Button
                          body={'保存'}
                          submit={true}
                          bgColor={constants.COLOR_MAIN}
                          onClick={handleSubmit}
                          style={{ width: '50%', marginLeft: 8 }}
                        />
                      </div>
                    </form>
                  </div>
                </Modal>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <Comment text={item.text} teamMembers={teamMembers} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  userAvatar: {
    marginRight: 16,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: constants.COLOR_WHITE,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  userContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  userNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  userName: {
    fontSize: 12,
    fontWeight: 'bold',
    color: constants.TEXT_GRAY_DARK,
    marginRight: 8,
    wordBreak: 'break-all',
  },
  userTime: {
    fontSize: 10,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    marginTop: 6,
  },
  userTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    justifyItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  // Modals
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modalCard: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    width: 400,
  },

  modalInput: {
    width: '100%',
    marginBottom: 16,
  },

  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  message: {
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    marginTop: 8,
    padding: 8,
    borderRadius: 8,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    overflowWrap: 'anywhere',

    '& a': {
      color: '#2CBBC6',
      textDecoration: 'underline',
    },
  },
}))
