import React from 'react'
import { useLocation } from 'react-router-dom'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { DefaultInputWithLabel, DefaultButton, Card, CardFooter, ButtonContainer } from 'pages/teams/components'
import { AdminSwitchTab } from 'pages/teams/components'
import { HooksContext } from 'pages/teams/contexts'
import { useManageTeamAdmin } from 'pages/teams/hooks'
import { UpdateTeamAdminInput } from 'utils/generated'

import { constants, Pages, useHistory } from 'assets'
import { PatternPhone } from 'assets/patterns'



type Props = RouteComponentProps<{ teamId: string }>

export const TeamsAdminPage: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { currentTeam, currentUser, currentTeamAdmin, refreshTeamAdmin } = React.useContext(HooksContext)

  const { control, errors, handleSubmit } = useForm<UpdateTeamAdminInput>()

  const { updateTeamAdmin, loading: processing } = useManageTeamAdmin()
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = async (input: UpdateTeamAdminInput) => {
    await updateTeamAdmin(input)
    refreshTeamAdmin()
  }
  const { route } = useHistory()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const params = useLocation().pathname
  const array = params.split('/')

  const url = array[3]

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {isMdUp ? <></> : <AdminSwitchTab url={url} />}
        <Card
          headerTitle={'管理者情報'}
          headerRightNode={
            currentUser && currentUser.id === currentTeam?.ownerUserId ? (
              <label
                className={classes.transferLabel}
                onClick={() => route.push(Pages.TeamsAdminTransfer, { teamId: props.match.params.teamId })}
              >
                {!isSmDown ? <div>管理者を移譲する</div> : <></>}
              </label>
            ) : (
              <></>
            )
          }
          loading={!currentTeamAdmin ? true : false}
          style={{ padding: '30px 24px 40px' }}
        >
          <DefaultInputWithLabel
            label={'氏名'}
            htmlFor={'fullName'}
            name={'fullName'}
            control={control}
            rules={{ required: '氏名は必須です' }}
            error={errors.fullName}
            defaultValue={currentTeamAdmin?.fullName}
          />
          <DefaultInputWithLabel
            label={'ユーザー名'}
            htmlFor={'username'}
            name={'username'}
            control={control}
            rules={{ required: 'ユーザー名は必須です' }}
            error={errors.username}
            defaultValue={currentTeamAdmin?.username}
          />
          <DefaultInputWithLabel
            label={'ログインID（メールアドレス）'}
            htmlFor={'email'}
            name={'email'}
            control={control}
            readOnly
            defaultValue={currentTeamAdmin?.email}
          />
          <DefaultInputWithLabel
            label={'電話番号'}
            htmlFor={'phone'}
            name={'phone'}
            control={control}
            rules={{
              required: '電話番号は必須です',
              pattern: {
                value: PatternPhone,
                message: '電話番号は正しくありません。',
              },
            }}
            error={errors.phone}
            defaultValue={currentTeamAdmin?.phone}
          />

          {currentUser && currentUser.id === currentTeam?.ownerUserId ? (
            <>
              <CardFooter>
                <ButtonContainer
                  buttons={[
                    <DefaultButton
                      key="button-reflesher-1"
                      title={'更新'}
                      color={'darkPrimary'}
                      style={{ boxShadow: 'none' }}
                      type="submit"
                      loading={processing}
                    />,
                  ]}
                />
              </CardFooter>
              {isSmDown ? (
                <div
                  className={classes.transferSP}
                  onClick={() => route.push(Pages.TeamsAdminTransfer, { teamId: props.match.params.teamId })}
                >
                  管理者を移譲する
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Card>
      </form>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {},

  transferLabel: {
    color: constants.COLOR_RED,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  transferSP: {
    textAlign: 'center',
    marginTop: 40,
    color: constants.COLOR_RED,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}))
