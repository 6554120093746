import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form'
import { useGetTeamAnketBuildingDataForExport } from 'services/api/anket'

import { CircularProgress } from 'components/CircularProgress'
import { CloseButton } from 'components/CloseButton'
import { GenericModal } from 'components/Modal'
import { NoStyleButton } from 'components/NoStyleButton'
import { useGetTeamsManagedByAdmin } from 'pages/teams/hooks/teamAdmin'
import { exportCSVTeamAnketBuilding } from 'pages/teams/pages/dashboard/components/tabs/contents/TB/hooks/exportCSVTeamAnketBuilding'

import { constants } from 'assets'

type Props = {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
type SubmitData = {
  managedTeamIds: string[]
}

const checkboxName: keyof SubmitData = 'managedTeamIds'

export const ModalCsvDownload: React.FC<Props> = ({ isModalOpen, setIsModalOpen }) => {
  const classes = useStyles()
  const { register, handleSubmit, setValue, watch } = useForm<SubmitData>({ mode: 'onChange' })
  const { managedTeams, isLoading, error } = useGetTeamsManagedByAdmin()
  const isFetching = isLoading

  const {
    getTeamAnketBuildingData,
    isLoading: isFetchingTeamAnketBuilding,
    error: errorTeamAnketBuildingData,
  } = useGetTeamAnketBuildingDataForExport()

  // react-hook-form の仕様により、チェックボックスの数によって型が変わる。
  //  1. チェックボックスが1つの場合
  //     checked: string
  //     unchecked: boolean (false)　※初期値のみ空配列
  //  2. チェックボックスが2つ以上の場合
  //     checked: string[]
  //     unchecked: string[] (空配列)
  const watchCheckbox: string[] | string | boolean = watch(checkboxName, [])

  const isArray = Array.isArray(watchCheckbox)
  const isFullChecked = Array.isArray(watchCheckbox)
    ? watchCheckbox.length === managedTeams.length
    : typeof watchCheckbox === 'string'

  const selectedCount = () => {
    if (isArray) {
      return watchCheckbox.length
    }
    if (typeof watchCheckbox === 'string') {
      return 1
    }
    if (typeof watchCheckbox === 'boolean') {
      return watchCheckbox ? 1 : 0
    }
    return '_'
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  const toggleAllCheck = () => {
    // 全選択されている場合は全てのチェックを外す。
    // そうでない場合は全てのチェックをつける。
    if (isFullChecked) {
      setValue(checkboxName, isArray ? [] : false)
      return
    } else {
      setValue(
        checkboxName,
        managedTeams.map((team) => team.id)
      )
    }
  }

  const onSubmit = async (data: SubmitData) => {
    if (isArray && watchCheckbox.length > 100) {
      alert(
        '一度にダウンロード可能なチーム数は100チームまでとなっています。チームの選択を減らしてから再度お試しください。'
      )
      return
    }
    if (watchCheckbox.length === 0 || !watchCheckbox) {
      alert('チームを選択してください。')
      return
    }

    const response = await getTeamAnketBuildingData(
      typeof data.managedTeamIds === 'string' ? [data.managedTeamIds] : data.managedTeamIds
    )
    if (response) {
      await exportCSVTeamAnketBuilding(response)
      setIsModalOpen(false)
    }
  }

  return (
    (<GenericModal
      isModalOpen={isModalOpen}
      handleModalClose={handleModalClose}
      styleProps={{
        width: '100%',
        maxWidth: 368,
        height: '',
        maxHeight: '100%',
      }}
    >
      <div className={classes.root}>
        <div className={classes.closeButtonBox}>
          <CloseButton onClose={handleModalClose} />
        </div>

        <h2 className={classes.title}>チームデータダウンロード</h2>
        <div className={classes.infoWrapper}>
          {isFetching ? (
            // loading 後のちらつき抑止のための要素
            (<p> </p>)
          ) : (
            <>
              <p>{`${managedTeams.length}チーム中${selectedCount()}選択`}</p>
              <NoStyleButton type="button" onClick={toggleAllCheck}>
                {isFullChecked ? '全て解除' : '全て選択'}
              </NoStyleButton>
            </>
          )}
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.teamsBox}>
            <div className={classes.scrollOuter}>
              {isFetching ? (
                <CircularProgress />
              ) : error ? (
                <p className={classes.errorText}>{error}</p>
              ) : (
                managedTeams?.map((team) => (
                  <div key={team.id} className={classes.checkbox}>
                    <input type="checkbox" id={team.id} name="managedTeamIds" value={team.id} ref={register()} />
                    <label className={classes.label} htmlFor={team.id}>
                      {team.name}
                    </label>
                  </div>
                )) ?? null
              )}
            </div>
          </div>
          <div className={classes.dlButtonBox}>
            {errorTeamAnketBuildingData?.errors && errorTeamAnketBuildingData.errors.length > 0 && (
              <div className={classes.errorMessage}>
                {errorTeamAnketBuildingData?.errors?.map((e) => {
                  return <span key={e.massage}>{e.message}</span>
                })}
              </div>
            )}
            <NoStyleButton disabled={isFetchingTeamAnketBuilding} type="submit">
              {isFetchingTeamAnketBuilding ? (
                <CircularProgress size={20} color={constants.COLOR_WHITE} margin="0px auto" />
              ) : (
                <span>CSVダウンロード</span>
              )}
            </NoStyleButton>
          </div>
        </form>
      </div>
    </GenericModal>)
  );
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: '40px 24px 24px',
      height: '100%',
    },
    closeButtonBox: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    title: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    infoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 28,

      '& > p': {
        margin: 0,
        color: constants.COLOR_GRAY_DARK,
        fontSize: 12,
        fontWeight: 'bold',
      },
      '& > button': {
        color: constants.COLOR_MAIN_NEW,
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    teamsBox: {
      height: 270,
      marginTop: 8,
      border: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      borderRadius: 8,
      overflow: 'hidden',
    },
    scrollOuter: {
      height: '100%',
      overflowY: 'auto',
      overscrollBehavior: 'contain',
    },
    errorText: {
      margin: 0,
      color: constants.COLOR_ALERT,
      fontSize: 12,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      gap: 14,
      padding: 12,
      '&:not(:last-child)': {
        borderBottom: `1px solid ${constants.COLOR_GRAY_LIGHT4}`,
      },

      '& input': {
        flexShrink: 0,
      },
      '& input[type="checkbox"]': {
        position: 'relative',
        width: 24,
        height: 24,
        border: `1px solid ${constants.COLOR_GRAY_DARK}`,
        borderRadius: 4,
        appearance: 'none',
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
      },
      '& input[type="checkbox"]:checked': {
        border: 'none',
        backgroundColor: constants.COLOR_MAIN_NEW,
      },
      '& input[type="checkbox"]:checked::before': {
        content: '""',
        position: 'absolute',
        top: 4,
        left: 8,
        width: 7,
        height: 12,
        borderRight: '2px solid #fff',
        borderBottom: '2px solid #fff',
        transform: 'rotate(45deg)',
      },
    },
    label: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 12,
    },
    dlButtonBox: {
      marginTop: 24,
      width: '100%',

      '& > button': {
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.7,
          },
        },
        width: '100%',
        height: 48,
        backgroundColor: constants.COLOR_MAIN_NEW,
        color: '#fff',
        fontSize: 14,
        fontWeight: 'bold',
        borderRadius: 24,
      },
    },
    errorMessage: {
      marginBottom: 8,
      color: constants.COLOR_ALERT,
      wordBreak: 'break-all',
    },
  }),
  { name: 'ModalCsvDownload' }
)
