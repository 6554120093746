import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { SocialsShare } from './socials/socialsShare/Index'
import { useSocials } from './socials/useSocials'

import { COLOR_MAIN, COLOR_WHITE } from 'assets/constants'

const SocialsSnackbar = () => {
  const classes = useStyles()
  const socials = useSocials()

  return (
    <div className={classes.root}>
      この喜びを誰かにシェアしましょう！
      <div>
        <SocialsShare presenter={socials} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '70%',
    backgroundColor: COLOR_MAIN,
    padding: '8px 8px 0',
    fontWeight: 'bold',
    color: COLOR_WHITE,
    borderRadius: 5,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
}))

export default SocialsSnackbar
