import * as React from 'react'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  flexDirection?: 'column' | 'row-reverse'
  ownStyles?: React.CSSProperties
  onClick?: (e: React.MouseEvent) => void
  children?: React.ReactNode
}

export const Flexbox: React.FC<Props> = (props) => {
  const classes = useStyles({ flexDirection: props.flexDirection })
  return (
    <div className={classes.flexboxRoot} onClick={props.onClick} style={{ ...props.ownStyles }}>
      {props.children}
    </div>
  )
}

type StyleProps = {
  flexDirection?: 'column' | 'row-reverse'
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme: Theme) => ({
    flexboxRoot: ({ flexDirection }) => ({
      flexDirection: flexDirection ?? 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }),
  { name: 'Flexbox' }
)

type ResponsiveProps = {
  isOneItem?: boolean
}

export const FlexboxResponsive: React.FC<Props & ResponsiveProps> = (props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Flexbox
      flexDirection={isSmDown ? 'column' : 'row-reverse'}
      ownStyles={{
        marginTop: 40,
        width: isSmDown ? '100%' : 516,
        justifyContent: props.isOneItem && !isSmDown ? 'center' : 'space-between',
        ...props.ownStyles,
      }}
    >
      {props.children}
    </Flexbox>
  )
}
