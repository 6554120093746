import * as React from 'react'
import { Link } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'
import { ulid } from 'ulid'

import { WaitingMessage } from 'pages/ghost/components/modules'
import { LoadingCircular } from 'pages/teams/components'
import { FeatureStatus } from 'utils/generated'

import { Completed } from '../components/Completed'
import { NextStep } from '../components/NextStep'
import { PreparePresentation } from '../components/PreparePresentation'
import { Presentation } from '../components/Presentation'
import { SelectTheme } from '../components/SelectTheme'
import { Start } from '../components/Start'
import { IceBreakContext } from '../contexts/iceBreakContext'

import { constants, Pages } from 'assets'
import { replacePathParams, useHistory } from 'assets/history'

export const Container: React.FC = () => {
  const classes = useStyles()
  const { route } = useHistory()
  const {
    teamId,
    isLoadingMembers,
    own,
    isLoadingMember,
    iceBreak,
    iceBreakContents,
    isRefreshed,
    isLoading,
    isLeader,
    handleUpdateByLeader,
    iceBreakTheme,
    memberCount,
    minute,
    selectUserIds,
    isTimerEnd,
    isCanceling,
  } = React.useContext(IceBreakContext)

  const isPresentationEnd = React.useMemo(
    () => iceBreakContents?.presentation?.selectedUserIds.length >= iceBreakContents?.presentation?.memberCount,
    [iceBreakContents?.presentation]
  )

  const buttonOrWaiting = React.useCallback(
    (button: React.ReactNode, hide = false) => {
      const waiting = hide ? null : <WaitingMessage ownStyles={{ marginTop: 32 }} />
      return isLeader ? button : waiting
    },
    [isLeader]
  )

  const errorRender = React.useCallback(
    ({ teamId, infoMsg, linkMsg = 'ダッシュボードへ戻る' }: { teamId: string; infoMsg: string; linkMsg?: string }) => (
      <>
        <p className={classes.errText}>{infoMsg}</p>
        <div className={classes.toDashboard}>
          <Link to={replacePathParams(Pages.TeamsDashboard, { teamId })}>{linkMsg}</Link>
        </div>
      </>
    ),
    [classes]
  )

  const renderContent = (): { body: React.ReactNode; footer?: React.ReactNode } => {
    if (!teamId || !own || !isRefreshed || isLoadingMember || isLoadingMembers || isCanceling)
      return { body: <LoadingCircular size={30} loading /> }

    if (teamId !== own.teamId) return { body: <p className={classes.errText}>チーム情報が正しくありません。</p> }

    if (iceBreak?.status === FeatureStatus.Cancel)
      return { body: errorRender({ teamId, infoMsg: 'このアイスブレイクは主催者によって中止されました。' }) }

    if (!iceBreak || !iceBreakContents)
      return { body: errorRender({ teamId, infoMsg: 'アイスブレイクの情報を取得できませんでした。' }) }

    if (!iceBreakContents?.activeUserIds.includes(own.userId))
      return {
        body: errorRender({ teamId, infoMsg: 'アイスブレイクに未参加です。ダッシュボードから参加してください。' }),
      }

    switch (iceBreak?.status) {
      case FeatureStatus.Start:
        return {
          body: <Start />,
        }
      case FeatureStatus.SelectTheme:
        return {
          body: <SelectTheme />,
          footer: buttonOrWaiting(
            <NextStep
              onClick={() =>
                handleUpdateByLeader({
                  status: FeatureStatus.PreparePresentation,
                  contents: { ...iceBreakContents, theme: iceBreakTheme ?? '' },
                })
              }
              label="内容を確定する"
              disabled={!iceBreakTheme || isLoading}
            />
          ),
        }
      case FeatureStatus.PreparePresentation:
        return {
          body: <PreparePresentation />,
          footer: buttonOrWaiting(
            <NextStep
              onClick={() =>
                handleUpdateByLeader({
                  status: FeatureStatus.Presentation,
                  contents: {
                    ...iceBreakContents,
                    presentation: {
                      memberCount,
                      minute,
                      selectedUserIds: selectUserIds(),
                      startedAt: new Date().toISOString(),
                    },
                  },
                })
              }
              label="内容を確定する"
              disabled={isLoading}
            />
          ),
        }
      case FeatureStatus.Presentation:
        return {
          body: <Presentation />,
          footer: buttonOrWaiting(
            <NextStep
              onClick={async () => {
                await handleUpdateByLeader(
                  isPresentationEnd
                    ? {
                        status: FeatureStatus.Completed,
                        contents: {
                          ...iceBreakContents,
                          nextPlayId: ulid().toLowerCase(),
                        },
                      }
                    : {
                        contents: {
                          ...iceBreakContents,
                          presentation: {
                            ...iceBreakContents.presentation,
                            selectedUserIds: selectUserIds(iceBreakContents.presentation.selectedUserIds),
                            startedAt: new Date().toISOString(),
                          },
                        },
                      }
                )
                !isPresentationEnd && window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              label={isTimerEnd ? '次へ' : 'スキップ'}
              disabled={isLoading}
            />,
            true
          ),
        }
      case FeatureStatus.Completed:
        return {
          body: <Completed />,
          footer: (
            <NextStep
              onClick={() => route.push(Pages.TeamsDashboard, { teamId })}
              label="HOMEへ"
              disabled={isLoading}
            />
          ),
        }
      default:
        return { body: null }
    }
  }

  return (
    <>
      <div className={classes.body}>{renderContent().body}</div>
      {renderContent().footer ?? null}
    </>
  )
}

const useStyles = makeStyles(
  () => ({
    // renderContent
    errText: {
      margin: 0,
      fontSize: 16,
      color: constants.COLOR_DANGER,
      textAlign: 'center',
    },
    toDashboard: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
      fontSize: 16,
      '& a': {
        display: 'inline-block',
        fontSize: 14,
        color: constants.COLOR_MAIN_NEW,
        textDecoration: 'underline',
      },
    },

    // Container
    body: {
      margin: '0 auto',
      padding: '16px 0',
      width: '100%',
      maxWidth: 600,
    },
  }),
  { name: 'Container' }
)
