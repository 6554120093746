import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { Theme } from '@mui/material';
import Rating from '@mui/material/Rating'
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import 'date-fns'
import { FontAwesome } from 'react-web-vector-icons'

import * as constants from '../../../../../assets/constants'
import { isStatusCloseByObject, isStatusFailedByObject } from '../../../../../services/utils/weekday'
import { IActionItem } from '../../../../../stores/setupaction/reducer'

interface IOwnProps {
  item: IActionItem
  basePeriod: number
}

type Props = IOwnProps & RouteComponentProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = (props) => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <div
        className={`__list_card_container ${isStatusCloseByObject(props.basePeriod, props.item) ? 'statusClose' : ''} ${
          isStatusFailedByObject(props.basePeriod, props.item) ? 'statusFailed' : ''
        }`}
      >
        <div className="__list_card_container__content">
          <div className="__list_card_container__content__image">
            <img
              src={
                process.env.PUBLIC_URL +
                `/assets/svg/onboarding/${
                  props.item.category ? `search_${props.item.category.toLowerCase()}` : 'category_anket'
                }.svg`
              }
              alt={props.item.category ? props.item.category : 'img'}
            />
          </div>
          <div className="__list_card_container__content__body">
            <div className="__list_card_container__content__body__ratings">
              <Rating name="size-small" value={props.item.importance} max={5} size="small" readOnly={true} />

              {isStatusCloseByObject(props.basePeriod, props.item) && (
                <>
                  <img
                    className="__list_card_container__content__body__ratings__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/setupList/statusClose.svg'}
                    alt={'close'}
                  />
                  <span className="__list_card_container__content__body__ratings__description statusClose">
                    期限間近
                  </span>
                </>
              )}
              {isStatusFailedByObject(props.basePeriod, props.item) && (
                <>
                  <img
                    className="__list_card_container__content__body__ratings__icon"
                    src={process.env.PUBLIC_URL + '/assets/svg/setupList/statusFailed.svg'}
                    alt={'failed'}
                  />
                  <span className="__list_card_container__content__body__ratings__description statusFailed">
                    期限超過
                  </span>
                </>
              )}
            </div>
            <div className="__list_card_container__content__body__title">{props.item.mission}</div>
            <div className="__list_card_container__content__body__description">{props.item.why}</div>
          </div>
          <div
            className={`__list_card_container__content__action ${
              props.item.missionValues || props.item.anketValues ? 'active' : ''
            }`}
          >
            {(props.item.missionValues && props.item.missionValues.value && props.item.missionValues.value > 0) ||
            (props.item.anketValues && props.item.anketValues.total !== undefined) ? (
              <>
                {props.item.missionValues && (
                  <img
                    className={`__list_card_container__content__action__valueIcon`}
                    src={`${process.env.PUBLIC_URL}/assets/svg/setupList/face-${props.item.missionValues.value}-a.svg`}
                    alt={'face'}
                  />
                )}

                {props.item.anketValues && <div className="__list_card_container__content__action__value"></div>}
              </>
            ) : (
              <FontAwesome name="check" color={constants.COLOR_MAIN_DARK} size={18} />
            )}
          </div>
        </div>
      </div>

      <div
        className={`__list_card_container  ${
          isStatusCloseByObject(props.basePeriod, props.item) ? 'statusClose' : ''
        } ${isStatusFailedByObject(props.basePeriod, props.item) ? 'statusFailed' : ''}`}
      >
        <div className="__list_card_container__title">アクション</div>
        <div className="__list_card_container__description">{props.item.what}</div>
        <div className="__list_card_container__title">達成基準</div>
        <div className="__list_card_container__description">{props.item.how}</div>
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      marginBottom: '8px',
      position: 'relative',

      '& .__list_card_container': {
        padding: '16px',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',

        '&__title': {
          color: constants.COLOR_MAIN,
          borderBottom: '1px solid',
          marginBottom: '8px',
          paddingBottom: '4px',
        },
        '&.statusClose': {
          background: constants.COLOR_ACTION_CLOSE,
        },
        '&.statusFailed': {
          background: constants.COLOR_ACTION_FAILED,
        },

        '&__content': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',

          '&__image': {
            width: '100px',
            height: '100px',
            display: 'flex',
            background: '#e5e5e5',
            color: '#b1b1b1',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '100px',
            minHeight: '100px',
            marginRight: '16px',
            backgroundColor: '#fffed9',
            borderRadius: 10,
            padding: 4,

            '& img': {
              width: '100%',
              maxHeight: '100%',
              objectFit: 'cover',
              borderRadius: 10,
            },
          },

          '&__body': {
            width: '100%',
            paddingRight: 8,

            '&__ratings': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4,
              '& .MuiRating-root': {
                marginRight: 8,
              },

              '&__icon': {
                width: '10px',
                height: '10px',
                margin: '0 4px',
              },
              '&__description': {
                fontSize: '10px',
                fontWeight: 'bold',
                '&.statusClose': {
                  color: constants.COLOR_ACTION_CLOSE_DARK,
                },
                '&.statusFailed': {
                  color: constants.COLOR_ACTION_FAILED_DARK,
                },
              },
            },
            '&__title': {
              fontSize: '16px',
              fontWeight: 'bold',
            },
          },

          '&__action': {
            background: constants.COLOR_MAIN_LIGHT,

            minWidth: '40px',
            minHeight: '40px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&.active': {
              backgroundColor: '#B9FCFF',
            },
            '&__value': {
              fontSize: '18px',
              fontWeight: 'bold',
              color: constants.COLOR_MAIN_DARK,
            },
            '&__valueIcon': {
              width: '100%',
              padding: '4px',
            },
          },
        },
      },
    },

    div: {
      margin: theme.spacing(1),
    },

    container: {
      display: 'flex',
    },
  })

export default withRouter(withStyles(useStyles)(Index))
