import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Breadcrumb } from 'components/Breadcrumb'
import { MenuList } from 'components/MenuList'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from '../../assets/constants'

import { FileBox } from './components/FileBox'

import { anchorLinks, downloadFiles } from './assets/documents.data'


export const ResourcesPage: React.FC = () => {
  const classes = useStyles()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.back}>
      <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="お役立ち資料" />
      <div className={classes.topContainer}>
        <h5>Document</h5>
        <h1>お役立ち資料</h1>
        <p>
          エンゲージメントの高いチーム作りのためのノウハウや
          <br />
          お役立ち資料をダウンロードいただけます。
        </p>
        <MenuList
          page={constants.PAGE_DOCUMENTS}
          items={anchorLinks}
          buttonLabel={'カテゴリから選ぶ'}
          styleMdDown={{ margin: '64px 0 20px' }}
          styleMdUp={{ width: '60%', margin: '64px 0 20px' }}
          smooth
        />
      </div>
      {anchorLinks.map((anchorLink) => (
        <React.Fragment key={`resources-${anchorLink.id}`}>
          <div className={classes.casePageTitle} id={anchorLink.id}>
            ー　{anchorLink.label}
          </div>
          <div className={classes.items}>
            {downloadFiles
              .filter((files) => files.category === anchorLink.id)
              .map((files) => (
                <FileBox
                  key={`resources-list-${files.title}`}
                  imgURL={files.imgURL}
                  title={files.title}
                  detail={files.detail}
                  link={files.link}
                />
              ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  back: {
    padding: '8px 7% 100px',
    backgroundColor: constants.COLOR_WHITE,
    maxWidth: '100%',
    fontFamily: constants.FONT_FAMILY_TOP_TEXT,
    [theme.breakpoints.up(constTop.breakPointsPC + 242)]: {
      padding: `0 calc((100% - 1248px)/2) 140px`,
    },
    '& h2': {
      marginBottom: 32,
      color: constants.COLOR_MAIN_SECOND,
      fontSize: 30,
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.up('sm')]: {
        fontSize: 44,
      },
    },
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h5': {
      color: constants.COLOR_MAIN_NEW,
      fontFamily: constants.FONT_FAMILY_TOP_TITLE,
      fontSize: 14,
      fontWeight: 'normal',
      margin: '82px 0 0',
      [theme.breakpoints.down('sm')]: {
        margin: '26px 0 0',
      },
    },
    '& h1': {
      fontSize: 32,
      fontWeight: 'bold',
      margin: '25px 0 0',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
    '& p': {
      margin: '30px 0 0',
      textAlign: 'center',
      lineHeight: 1.8,
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0 0',
      },
    },
  },
  casePageTitle: {
    margin: '0 0 48px',
    padding: '80px 0 0',
    fontSize: 20,
    fontWeight: 'bold',
    color: constants.COLOR_MAIN_NEW,
  },
  carouselWrapper: {
    position: 'relative',
  },
  carousel: {
    backgroundColor: constants.COLOR_GRAY,
    padding: '25px 0',
    overflow: 'hidden',
  },
  items: {
    display: 'grid',
    gap: '24px 30px',
    width: '100%',
    margin: '0',
    gridTemplateColumns: ' repeat(auto-fill, minmax(300px, 1fr))',
  },
  item: {
    margin: '0 auto',
    width: '90%',
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  itemLink: {
    '&:hover': {
      border: '1px solid ' + constants.COLOR_MAIN_SECOND,
    },
  },
  itemImg: {
    position: 'relative',
    width: '100%',
    '& img': {
      borderRadius: '16px 16px 0 0',
      width: '100%',
    },
    '& p': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      padding: 10,
      color: constants.TEXT_WHITE,
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1.3,
      textShadow: '1px 0 0 #464646, 0 1px 0 #464646, -1px 0 0 #464646, 0 -1px 0 #464646',
      [theme.breakpoints.up('sm')]: {
        fontSize: 18,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
  },
  itemCompany: {
    height: '100%',
  },
  itemCompanyTitle: {
    padding: '18px 20px 15px',
    color: constants.COLOR_GRAY_DARK2,
    fontFamily: '"Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif',
    fontSize: 16,
    fontWeight: 700,
  },
  itemCompanyName: {
    padding: '0 20px 22px',
    color: constants.COLOR_GRAY_DARK2,
    fontFamily: '"Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif',
    fontSize: 16,
    fontWeight: 300,
  },
  comingSoon: {
    fontWeight: 300,
  },

  userTitle: {
    marginTop: 60,
  },
}))
