import React, { useContext, useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import * as constGhost from 'pages/ghost/assets/constGhost'
import { Timer } from 'pages/teams/pages/_tools/buildings/components'

import { GrayBackGround, ButtonForGhost, BackResponsiveCard, PageTitle, LeadText } from '../components/atoms/index'
import { ButtonContainer } from '../components/modules'
import { WidthControlContainer } from '../components/modules/WidthControlContainer'
import { GhostSawBox } from '../components/organisms'
import { LoadingIndicator } from '../components/organisms/LoadingIndicator'
import { ghostTeamContext } from '../contexts/ghostTeam'
import { ghostTeamMemberContext } from '../contexts/ghostTeamMember'
import { ReportContext } from '../contexts/report'
import { usePBForFixedUIs } from '../hooks/fixedUI'
import { CommandContext } from '../service/commands'

export const Vote: React.FC = () => {
  const { reportsToVote } = useContext(ReportContext)
  const { team } = useContext(ghostTeamContext)
  const { teamMemberList } = useContext(ghostTeamMemberContext)
  const { runVoteReports } = useContext(CommandContext)
  const pBForFixedUIs = usePBForFixedUIs({ status: 'hasTimer2Btn' })

  const [usersVotes, setUsersVotes] = useState<boolean[]>([])
  const vote = async (up: boolean) => setUsersVotes([...usersVotes, up])

  const [processing, setProcessing] = useState(false) // prevent re-run flag
  useEffect(() => {
    if (usersVotes.length === 3 && reportsToVote.length === 3 && !processing) {
      setProcessing(true)
      runVoteReports(reportsToVote, usersVotes)
    }
  }, [usersVotes, processing, teamMemberList, reportsToVote, runVoteReports])

  // UI

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))
  const isXsDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (reportsToVote.length === 3 && (usersVotes.length === 1 || usersVotes.length === 2)) {
      window.scroll({ top: isSmDown ? 165 : 155, behavior: 'smooth' })
    }
  }, [usersVotes, reportsToVote, isSmDown])

  if (reportsToVote.length !== 3 || usersVotes.length > 2)
    return <LoadingIndicator snapshot={{ vars: { reportsToVote, usersVotes } }} />
  return (
    <>
      <GrayBackGround>
        <BackResponsiveCard ownStyles={{ paddingBottom: pBForFixedUIs }}>
          <WidthControlContainer>
            <PageTitle>メンバーが見かけたオバケ確認</PageTitle>
            <LeadText>
              自分以外の他メンバーが選択したオバケが出ています。
              {!isSmDown && <br />}
              「特徴と症状」「おばけ出没シーン」を参考に、あなたも「見かけた」「見かけなかった」の判断をしてください。
              {!isSmDown && <br />}
              3体分表示されます。 3体選択した後、全員の投票結果をもとに、12体から1体オバケが選ばれます。
              {!isSmDown && <br />}
            </LeadText>
            <LeadText ownStyles={{ color: constGhost.COLOR_WARNING_RED, fontWeight: 'bold', marginTop: 0 }}>
              ※ 他のメンバーには異なるオバケが出ています
            </LeadText>
            <GhostSawBox
              ghostId={reportsToVote[usersVotes.length].ghostId}
              bodyDetails={'featuresAndThemes'}
              usersVotes={usersVotes}
              ownStyles={{ marginTop: 24, marginBottom: isXsDown ? 180 : 86 }}
            />

            <ButtonContainer
              fixed
              buttons={[
                <ButtonForGhost
                  key="vote-visible"
                  buttonBodyColor="green"
                  bodyText="見かけた"
                  onClick={() => vote(true)}
                  isResizeBasic
                />,
                <ButtonForGhost
                  key="vote-none-visible"
                  buttonBodyColor="white"
                  bodyText="見かけなかった"
                  onClick={() => vote(false)}
                  isResizeBasic
                />,
              ]}
            />
          </WidthControlContainer>
        </BackResponsiveCard>

        {team && (
          <Timer
            from={team.timerS2Started ?? new Date().toISOString()}
            time={180}
            hasGhostStyle
            ghostWrapBottom={isXsDown ? 140 : 110}
          />
        )}
      </GrayBackGround>
    </>
  )
}
