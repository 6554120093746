import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../assets/constants'

interface IOwnProps {}

type Props = IOwnProps &
  WithStyles<typeof useStyles> & {
    children: React.ReactNode
  }

const Index: React.FC<Props> = ({ classes, children }) => {
  return <div className={classes.container}>{children}</div>
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
    },
  })

export default withStyles(useStyles)(Index)
