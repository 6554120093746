import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme, useMediaQuery, Divider } from '@mui/material'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useIsTeam } from 'pages/plan/hooks/useIsTeam'
import { OnboardingTeamMember } from 'utils/generated'

import { IconBox, RoleMenuBox, OverviewBox, ChangePlanButton } from './components'

import * as constants from 'assets/constants'

interface Props {
  teamId: string | undefined
  countNotification?: number
  children: React.ReactNode
}

export const Body: React.FC<Props> = ({ children, teamId, countNotification }) => {
  const classes = useStyles()
  const theme = useTheme()

  const pathname = useLocation().pathname
  const isOverView = pathname.includes('overview')

  const isSidebarApply: boolean = useMediaQuery(theme.breakpoints.up('sm')) // this mediaQuery is used for apply sidebar or not
  const isMdup: boolean = useMediaQuery(theme.breakpoints.up('md')) // this mediaQuery is used for apply sidebar or not
  const isDetailsApply: boolean = useMediaQuery(theme.breakpoints.up(901)) // this mediaQuery is used for apply hamburgerMenus detail or not
  const isDisplayDetail = !isOverView === false || isDetailsApply === false ? false : true
  const isOverViewOverMd = isOverView && isMdup

  const { teamMember, loading } = useTeamMember(teamId)
  const { teams, loading: loadingTeams } = useIsTeam()
  const isChangePlanButton: boolean | undefined = !loadingTeams && teams && teams.items.length > 0

  return (
    <>
      <div className={classes.container} style={{ marginRight: isOverViewOverMd ? 130 : 0 }}>
        {isSidebarApply ? (
          <div className={isDisplayDetail ? classes.pcMenuBase : classes.spMenuBase}>
            <div className={isDisplayDetail ? classes.pcMenuContainer : classes.spMenuContainer}>
              {!loading ? (
                <>
                  <div className={classes.boxMenu}>
                    <IconBox
                      teamId={teamId}
                      teamMember={teamMember}
                      classes={classes}
                      visibleDetail={isDisplayDetail}
                    />
                    <Divider classes={{ root: classes.divider }} />
                    <RoleMenuBox
                      teamId={teamId}
                      teamMember={teamMember}
                      classes={classes}
                      visibleDetail={isDisplayDetail}
                      countNotification={countNotification}
                    />
                  </div>
                  <div className={classes.boxMenu}>
                    <OverviewBox
                      teamId={teamId}
                      teamMember={teamMember || ({} as OnboardingTeamMember)}
                      classes={classes}
                      visibleDetail={isDisplayDetail}
                    />
                  </div>

                  {isChangePlanButton ? (
                    <>
                      <ChangePlanButton teams={teams} classes={classes} visibleDetail={isDisplayDetail} />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={classes.bodyContainer}
          style={{
            margin: !isSidebarApply ? '0 auto' : 0,
          }}
        >
          {children}
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 62,
    paddingBottom: 32,
  },
  pcMenuBase: {
    position: 'relative',
    marginTop: 18,
    marginRight: 8,
    marginLeft: 16,
    width: 228,
    [theme.breakpoints.down(901)]: {
      width: 92,
    },
  },
  spMenuBase: {
    position: 'relative',
    marginTop: 18,
    marginRight: 8,
    marginLeft: 16,
    width: 92,
  },
  pcMenuContainer: {
    position: 'fixed',
    width: 228,
    height: '100%',
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
    [theme.breakpoints.down(901)]: {
      width: 92,
    },
  },
  spMenuContainer: {
    position: 'fixed',
    width: 92,
    height: '100%',
    maxHeight: 'calc(100vh - 140px)',
    overflowY: 'auto',
  },

  boxMenu: {
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 8,
  },
  profile: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '24px 16px',
    [theme.breakpoints.down(901)]: {
      margin: '24px 0',
      justifyContent: 'center',
    },
  },
  avatar: {
    height: 60,
    width: 60,
    marginRight: 16,
    cursor: 'pointer',
    [theme.breakpoints.down(901)]: {
      marginRight: 0,
    },
  },
  profileName: {
    marginTop: 13,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 18,
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  profilePosition: {
    marginTop: 4,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontSize: 10,
    wordBreak: 'break-all',
  },
  divider: {
    backgroundColor: constants.COLOR_ONBOARDING_GRAY_LIGHT,
  },
  menuList: {
    paddingTop: 16,
    '& ul': {
      listStyleType: 'none',
      padding: '0 0 16px',
      margin: 0,
      '& li': {
        margin: 0,
        padding: '20px 16px',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: constants.TEXT_GRAY_DARK,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fafafa',
        },
        [theme.breakpoints.down(901)]: {
          justifyContent: 'center',
        },
      },
      '& span': {
        padding: '1px 0 0 16px',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  },
  overviewList: {
    marginTop: 24,
    '& ul': {
      listStyleType: 'none',
      padding: '16px 0',
      margin: 0,
      borderRadius: 8,
      '& li': {
        margin: 0,
        padding: '20px 16px',
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: constants.TEXT_GRAY_DARK,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#fafafa',
        },
        [theme.breakpoints.down(901)]: {
          justifyContent: 'center',
        },
      },
      '& span': {
        padding: '1px 0 0 16px',
        fontSize: 16,
        fontWeight: 'bold',
      },
    },
  },
  notification: {
    position: 'relative',
  },
  notificationSm: {
    position: 'relative',
    justifyContent: 'center',
  },
  notificationBatch: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  buttonPlan: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 24,
    paddingLeft: 16,
    height: 56,
    backgroundColor: constants.COLOR_WHITE,
    color: constants.TEXT_GRAY_DARK,
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 8,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: constants.COLOR_WHITE,
      boxShadow: 'none',
    },
    [theme.breakpoints.down(901)]: {
      justifyContent: 'center',
    },
  },
  labelPlan: {
    marginLeft: 16,
  },
  paperPlan: {
    width: 237,
    backgroundColor: constants.COLOR_WHITE,
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 8,
    '& .MuiList-root': {
      padding: 0,
    },
  },
  menuItemPlan: {
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '18px 24px',
  },
  activePlan: {
    color: constants.COLOR_MAIN_NEW,
  },

  bodyContainer: {
    minWidth: 0,
    maxWidth: 560,
    flex: 1,
  },
}))
