import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';


import { LoginButton } from 'components/LoginButton'

import * as constants from '../../assets/constants'
import MessagePanel from '../../components/MessagePanel'

import { connector, ContainerProps } from './reset_password_complete.container'

type Props = ContainerProps & WithStyles<typeof useStyles> & RouteComponentProps<{}>

const Index: React.FC<Props> = (props: Props) => {
  const theme = useTheme()
  const isSMDown = useMediaQuery(theme.breakpoints.down('md'))

  React.useEffect(() => {
    if (!props.user?.reset) {
      props.history.push(constants.PAGE_TOP)
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user?.reset])

  const { classes } = props

  const handlePage = () => {
    props.history.push(constants.PAGE_SIGNIN)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div className={classes.main}>
        {props.user?.reset && (
          <div className="__signup_complete_container">
            <MessagePanel
              complete
              noShadow
              body={
                <div className={classes.messageBody}>
                  <p>新しいパスワードを設定しました。</p>
                  <p>ログインしてCocolaboをご利用ください。</p>
                </div>
              }
              footer={
                <div className={classes.buttonWrapper}>
                  <LoginButton
                    label="ログイン"
                    onClick={handlePage}
                    ownStyles={isSMDown ? { height: 50, width: 250 } : { height: 50, width: 200 }}
                  />
                </div>
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    main: {
      padding: '40px 24px 80px',
      minHeight: 'calc(100vh - 78px)',
      backgroundColor: constants.COLOR_WHITE,
      color: constants.TEXT_GRAY_DARK,

      '& .__signup_complete_container': {
        margin: '0 auto',
        padding: '36px 0 16px',
        width: '100%',
        maxWidth: 540,
        borderRadius: 8,
        boxShadow: '1px 3px 9px 4px rgba(21, 27, 38, 0.15)',
      },
    },

    messageBody: {
      fontSize: '14px',
    },
    buttonWrapper: {
      marginTop: 24,
      display: 'flex',
      justifyContent: 'center',
    },
  })

export default withStyles(useStyles)(connector(Index))
