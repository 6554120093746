import * as React from 'react'

import { Box } from '@mui/material'

import { Container as BaseContainer } from 'pages/hint/_shared/layouts/container/Index'
import { Wrapper as BaseWrapper } from 'pages/hint/_shared/layouts/wrapper/Index'

import * as constants from 'assets/constants'

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  children: React.ReactNode
}

// -----------------------------
// Component
// -----------------------------
export const Wrapper = (props: IProps) => {
  return (
    <BaseWrapper color={constants.COLOR_GRAY}>
      <Box pt={{ xs: '24px', lg: '50px' }}>
        <BaseContainer maxWidth={1080}>
          <Box display="flex" alignItems="flex-start">
            {props.children}
          </Box>
        </BaseContainer>
      </Box>
    </BaseWrapper>
  )
}
