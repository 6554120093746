import React from 'react'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { mutationUpdateTeamBuildingActionDate } from 'pages/teams/hooks/graphql'
import { TeamBuildingActionDate, TeamBuildingActionDateStatus } from 'utils/generated'

import { constants } from 'assets'

interface Props {
  item: TeamBuildingActionDate
}

export const ActionStatusUpdater: React.FC<Props> = ({ item }) => {
  const [completed, setCompleted] = React.useState(item.status === TeamBuildingActionDateStatus.Completed)

  const classes = useStyles({ completed })

  const handleOnClick = async () => {
    setCompleted(!completed)

    const nextStatus =
      item.status === TeamBuildingActionDateStatus.Created
        ? TeamBuildingActionDateStatus.Completed
        : TeamBuildingActionDateStatus.Created
    await mutationUpdateTeamBuildingActionDate({
      input: {
        id: item.id,
        status: nextStatus,
      },
    })
  }

  return (
    <div className={classes.root} onClick={handleOnClick}>
      {completed ? (
        <>
          <span className={classes.label}>完了</span>
          <CheckCircleRoundedIcon style={{ color: constants.COLOR_ONBOARDING_MAIN }} />
        </>
      ) : (
        <>
          <span className={classes.label}>完了にする</span>
          <CheckCircleOutlineRoundedIcon style={{ color: '#707070' }} />
        </>
      )}
    </div>
  )
}

type StyleProps = {
  completed: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    marginLeft: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    minWidth: 100,
    cursor: 'pointer',
  },

  label: ({ completed }) => ({
    fontWeight: completed ? 600 : 'normal',
    color: completed ? constants.COLOR_ONBOARDING_MAIN : '#707070',
  }),
}))
