import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import { SummaryBox } from 'pages/onboarding/components'
import { OnboardingPost } from 'utils/generated'

import ArrowLeft from '../assets/Icons-ArrowLeft.svg'
import * as constants from 'assets/constants'

type Props = {
  teamId: string
  post: OnboardingPost
}
type StyleProps = {}

export const WeeklySummary: React.FC<Props & StyleProps> = ({ teamId, post }) => {
  const classes = useStyles()

  return (
    <>
      <SummaryBox renderType={'timeline'} teamId={teamId} post={post} />
      <div className={classes.moreWrapper}>
        <div className={classes.more}>もっと見る</div>
        <img src={ArrowLeft} alt={'arrow'} />
      </div>
    </>
  )
}

const useStyles = makeStyles(
  {
    moreWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    more: {
      fontSize: 14,
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
      margin: '20px 8px 20px 0',
    },
  },
  { name: 'WeeklySummary' }
)
