import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import CheckIcon from '../../assets/check.svg'

export const StepLabel: React.FC<{ label: string; completed?: boolean | undefined }> = ({ label, completed }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {completed ? (
        <img className="check" alt="check" src={CheckIcon} />
      ) : (
        <>
          <div className={classes.step}>STEP</div>
          <div className={classes.count}>{label}</div>
        </>
      )}
    </div>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 40,
    height: 40,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00838C',
    color: '#FFFFFF',
    lineHeight: '1em',
    zIndex: 2,
    '@media (max-width: 632px)': {
      marginBottom: 38,
    },
  },
  step: {
    fontSize: 8,
  },
  count: {
    fontSize: 16,
  },
}))
