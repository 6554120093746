import React, { useContext, useState } from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { StyleRules, WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import { format } from 'date-fns'

import { SearchedNothing } from 'pages/onboarding/components'
import { SearchBar } from 'pages/onboarding/components/search-bar'
import { useTeamMembers } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { onboardingActionCategoryLabel } from 'pages/onboarding/utils/onboardingActionCategoryLabel'
import {
  OnboardingTeamMemberRole,
  OnboardingTeamMemberStatus,
  OnboardingTeamMember,
  OnboardingRadarValue,
} from 'utils/generated'

import NoImage from '../../assets/noImage.svg'
import { OnbContext } from '../PagesRoot'

import Toolbar from './components/toolbar'

import IconsArrowLeft from './assets/Icons-ArrowLeft.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

export const MemberManageBloc = {
  useAdapter: () => {
    const { teamId } = useContext(OnbContext)
    const { teamMembers } = useTeamMembers(teamId)
    return {
      teamId,
      teamMembers,
    }
  },
  gatherRadarValue: (valueList: OnboardingRadarValue[]) => {
    const data: number[] = [0, 0, 0, 0, 0]
    for (const value of valueList) {
      data[0] = (data[0] + (value.category2 || 0.5)) / (data[0] === 0 ? 1 : 2)
      data[1] = (data[1] + (value.category3 || 0.5)) / (data[1] === 0 ? 1 : 2)
      data[2] = (data[2] + (value.category4 || 0.5)) / (data[2] === 0 ? 1 : 2)
      data[3] = (data[3] + (value.category5 || 0.5)) / (data[3] === 0 ? 1 : 2)
      data[4] = (data[4] + (value.category1 || 0.5)) / (data[4] === 0 ? 1 : 2)
    }
    return data
  },
}

const Index: React.FC<WithStyles<typeof useStyles>> = ({ classes }) => {
  // deps
  const { teamId, teamMembers } = MemberManageBloc.useAdapter()
  const history = useOnbHistory()

  const [input, setInput] = useState('')
  const [search, setSearch] = useState('')

  const handleStartInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }
  const handleStartEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearch(input)
    }
  }
  const handleStartSearch = () => {
    setSearch(input)
  }
  const handleStartMemberActions = (member: OnboardingTeamMember, teamId?: string) => {
    if (member.status === OnboardingTeamMemberStatus.Accepted || member.status === OnboardingTeamMemberStatus.Pending) {
      history.push(OnbPages.MemberManageActions, { teamId, userId: member.userId })
    }
  }

  const searchedTeamMembers = teamMembers
    .filter((member) => member.role === OnboardingTeamMemberRole.Member)
    .filter((member) => member.nickname && member.nickname.indexOf(search) !== -1)

  return (
    <>
      <div className={classes.title}>メンバーアクション状況</div>
      <Toolbar teamId={teamId} />
      <div className={classes.template}>
        <div
          style={{
            padding: '0 16px 16px 16px',
            width: '100%',
          }}
        >
          <Box className={classes.boxGray}>
            <div className="__title">メンバー 一覧</div>
            <SearchBar
              placeholder="名前で検索"
              borderColor={constants.COLOR_ONBOARDING_GRAY_LIGHT}
              borderRadius={8}
              iconMarginLeft={16}
              iconMarginRight={16}
              onClickImg={handleStartSearch}
              onChangeInput={handleStartInput}
              onKeyPressInput={handleStartEnter}
              inputValue={input}
              setInputValue={setInput}
              searchValue={search}
              setSearchValue={setSearch}
            />
          </Box>
          {/* Member card */}
          {teamMembers.length ? (
            <>
              {searchedTeamMembers.length ? (
                searchedTeamMembers.map((member) => {
                  return (
                    <Box
                      paddingTop={2}
                      className={
                        member.status === OnboardingTeamMemberStatus.Stopped ? classes.memberBoxGray : classes.memberBox
                      }
                      key={member.id}
                      onClick={() => handleStartMemberActions(member, teamId)}
                    >
                      <div>
                        <img
                          className={classes.userAvatar}
                          src={member.imageUrl ? member.imageUrl : NoImage}
                          alt={'avatar'}
                        />
                      </div>
                      <div className={classes.userRightCard}>
                        <div className={classes.userName}>
                          {member.nickname && member.nickname !== '名無し' ? member.nickname : member.email ?? ''}
                        </div>
                        <div className={classes.position}>
                          {member.position ? member.position : member.email || '役職/部署など肩書'}
                        </div>
                        <div>
                          <Grid container sx={{ marginBottom: '27px', fontSize: '14px' }}>
                            <Grid size={{ xs: 6 }} sx={{ textAlign: 'left' }}>
                              <span className="__role">{teamMemberRoleLabel(member.role)}</span>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginBottom: 13 }}>
                            <Grid size={{ xs: 8 }} sx={{ textAlign: 'left' }}>
                              達成したアクション
                            </Grid>
                            <Grid size={{ xs: 4 }} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                              <span data-testid="complete-count">{member.completeActionCount || 0} 件</span>
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginBottom: 13 }}>
                            <Grid size={{ xs: 7 }} sx={{ textAlign: 'left' }}>
                              達成率の高いカテゴリー
                            </Grid>
                            <Grid size={{ xs: 5 }} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                              {onboardingActionCategoryLabel(member.completeActionMostCategory) || '関係行動構築'}
                            </Grid>
                          </Grid>
                          <Grid container style={{}}>
                            <Grid size={{ xs: 5 }} sx={{ textAlign: 'left' }}>
                              オンボーディング開始日
                            </Grid>
                            <Grid size={{ xs: 7 }} sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                              {member.startedAt ? format(new Date(member.startedAt), 'yyyy年MM月dd日') : '---'}
                            </Grid>
                          </Grid>
                          <Grid size={{ xs: 12 }} sx={{ textAlign: 'right', marginTop: '29px' }}>
                            <span
                              style={{
                                color:
                                  member.status === OnboardingTeamMemberStatus.Accepted ||
                                  member.status === OnboardingTeamMemberStatus.Pending
                                    ? constants.COLOR_ONBOARDING_MAIN
                                    : constants.TEXT_WHITE,
                              }}
                            >
                              {member.status === OnboardingTeamMemberStatus.Accepted ||
                              member.status === OnboardingTeamMemberStatus.Pending ? (
                                <div className="__link">
                                  アクションリストへ
                                  <img src={IconsArrowLeft} alt={'icon'} style={{ paddingLeft: 12 }} />
                                </div>
                              ) : (
                                <></>
                              )}
                            </span>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  )
                })
              ) : (
                <SearchedNothing />
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  )
}

const useStyles = (): StyleRules =>
  createStyles({
    template: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    title: {
      textAlign: 'center',
      padding: '27px 0',
      color: constants.TEXT_GRAY_DARK,
      fontWeight: 'bold',
      fontSize: 16,
    },
    box: {
      backgroundColor: '#FFFF',
      padding: '19px 16px',
      margin: '0 16px 16px 16px',
      '& .__title': {
        fontWeight: 'bold',
      },
    },
    boxGray: {
      backgroundColor: constants.COLOR_ONBOARDING_WHITE,
      width: '100%',
      marginBottom: 24,
      fontWeight: 'bold',
      fontSize: 12,
      position: 'relative',
      '& .__title': {
        fontSize: 14,
        marginBottom: 18,
      },
      '& .__filter': {
        display: 'flex',
        alignItems: 'flex-end',
        flexFlow: 'column',
        position: 'absolute',
        top: 37,
        right: 0,
        '& .__img': {
          width: 42,
          height: 42,
        },
        '& .__list': {
          display: 'none',
          width: 170,
          listStyle: 'none',
          boxSizing: 'border-box',
          backgroundColor: constants.COLOR_WHITE,
          margin: 0,
          padding: 0,
          fontWeight: 'initial',
          boxShadow: `0 1px 2px ${constants.COLOR_ONBOARDING_GRAY_DARK}`,
          borderRadius: 4,
          '&.__active': {
            display: 'block',
          },
          '& .__item': {
            position: 'relative',
            padding: '18px 16px 18px 28px',
          },
          '& .__label.__active': {
            color: constants.COLOR_ONBOARDING_MAIN,
          },
        },
      },
    },
    memberBox: {
      backgroundColor: constants.COLOR_WHITE,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '24px 16px',
      marginBottom: 20,
      width: '100%',
      borderRadius: 3,
      cursor: 'pointer',
      '& .__link': {
        fontSize: 14,
        fontWeight: 'bold',
      },
    },
    memberBoxGray: {
      backgroundColor: 'rgba(31, 24, 24, 0.3)',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '24px 16px',
      marginBottom: 20,
      width: '100%',
      borderRadius: 3,
      fontSize: 14,
    },
    userAvatar: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      backgroundColor: '#FFFFFF',
      borderColor: '#DDDDDD',
      borderWidth: 1,
      borderStyle: 'solid',
      marginRight: 16,
      objectFit: 'cover',
    },
    userRightCard: {
      width: '90%',
      '& .__role': {
        padding: '5px 8px',
        fontSize: 10,
        fontWeight: 'bold',
        color: constants.COLOR_ONBOARDING_MAIN,
        border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
        borderRadius: 4,
      },
    },
    userName: {
      fontSize: 18,
      fontWeight: 'bold',
      '& span': {
        marginRight: 10,
        float: 'right',
      },
    },
    position: {
      marginBottom: 8,
      fontSize: 10,
    },
    radio: {
      '&.MuiFormGroup-row': {
        flexDirection: 'column',
      },
      '& .MuiIconButton-root': {
        display: 'none',
      },
      '& .MuiFormControlLabel-root': {
        margin: 0,
        position: 'relative',
        padding: '18px 0 17px 37px',
      },
    },
  })

export default withStyles(useStyles)(Index)
