/**
 * 配列からランダムに1つの要素を選択する関数。
 *
 * @param arr - ランダムに選択したい要素を含む配列。
 * @returns ランダムに選択された要素。配列が空の場合は undefined。
 */
export const randomPickupEl = <T>(arr: T[]): T | undefined => {
  // 配列が空の場合は undefined を返す
  if (arr.length === 0) return undefined

  // 配列からランダムに1つ選択して返す
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * 配列からランダムに1つの要素を選択する関数。
 * 除外リストに含まれている要素は選択されません。
 *
 * @param arr - ランダムに選択したい要素を含む配列。
 * @param excludeArr - 選択対象から除外したい要素の配列。
 * @returns ランダムに選択された要素。全て除外された場合は undefined。
 */
export const randomPickupElWithExclusion = <T>(arr: T[], exclusionArr: T[]): T | undefined => {
  // 除外リストに含まれていない要素をフィルタリング
  const filteredArr = arr.filter((el) => !exclusionArr.includes(el))

  if (filteredArr.length === 0) {
    return undefined // 除外されていない要素がない場合は undefined を返す
  }

  // フィルタリング後の配列からランダムに1つ選択して返す
  return filteredArr[Math.floor(Math.random() * filteredArr.length)]
}

/**
 * 配列の要素数を指定した上限で制限する関数。
 * 配列が指定された上限以下ならそのまま返し、超える場合は先頭の要素を上限数だけ返す。
 *
 * @param arr - 処理対象の配列
 * @param limit - 配列の要素数の上限（デフォルトは100）
 * @returns 配列の要素数が上限以下ならそのまま、それ以上の場合は先頭の要素を上限数だけ返す
 */
export const sliceArrayFromTop = <T>(arr: T[], limit: number): T[] => {
  return arr.length <= limit ? arr : arr.slice(0, limit)
}
