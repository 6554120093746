import * as React from 'react'

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles';

import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { Content } from 'pages/hint/_shared/components/content/Index'
import { ProfileName } from 'pages/hint/_shared/components/profileName/Index'
import { IUserResult } from 'pages/hint/_shared/stores/apis/getUser'

import { IuseSocials } from '../../socials/useSocials'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '32px 24px',
    backgroundColor: '#F5F5F5',
    [theme.breakpoints.down('lg')]: {
      padding: '12px 15px',
    },
  },

  pc: {
    display: 'block',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  sp: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  name: {
    fontSize: '18px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  content: {
    marginTop: '0px',
    fontSize: '14px',
    lineHeight: 1.6667,
    color: '#292626',
    [theme.breakpoints.down('lg')]: {
      marginTop: '16px',
      fontSize: '12px',
      display: 'flex',
      lineHeight: 1.4166,
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = {
  isCard?: boolean
  presenter: IuseSocials
  data: IUserResult
}

// -----------------------------
// Component
// -----------------------------
export const ProfileAuthor = (props: IProps) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.pc}>
        <Box
          className={classes.root}
          display="flex"
          alignItems="flex-start"
          style={{ borderRadius: props.isCard ? '30px' : '' }}
        >
          <Box mr={{ xs: '25px', md: '24px' }}>
            <Avatar src={props.data.imageUrl} alt="avatar" size={108} />
          </Box>

          <Box className={classes.content}>
            {props.data?.lastName && props.data?.firstName && (
              <Box className={classes.name}>
                <ProfileName
                  name={`${props.data.lastName} ${props.data.firstName}`}
                  position={props.data.shortDescription}
                />
              </Box>
            )}
            <Box className={classes.content}>
              <Content text={props.data.description} ellipsis={false} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={classes.sp}>
        <Box className={classes.root} style={{ borderRadius: '30px' }}>
          <Box display="flex" alignItems="center">
            <Box mr="32px">
              <Avatar src={props.data.imageUrl} alt="avatar" size={108} />
            </Box>
            {props.data?.lastName && props.data?.firstName && (
              <Box className={classes.name}>
                <ProfileName
                  name={`${props.data.lastName} ${props.data.firstName}`}
                  position={props.data.shortDescription}
                />
              </Box>
            )}
          </Box>
          <Box className={classes.content}>
            <Content text={props.data.description} ellipsis={false} color={'#292626'} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
