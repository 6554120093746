import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'
import dayjs from 'dayjs'

import { NoStyleButton } from 'components/NoStyleButton'
import { ButtonCSV } from 'pages/teams/components/GhostPastListTable/ButtonCSVDownload'
import { Ghost, GhostHistoryConnection, GhostResearch } from 'utils/generated'

import { NextActions } from './NextActions'

import { constants } from 'assets'

type ListRowProps = {
  ghostItem: GhostHistoryConnection
  times: number
  ghosts: Ghost[]
  actionFilter: (
    nextActionList: GhostResearch[] | undefined | null
  ) => Array<GhostResearch & { fullName: string; imageUrl: string | undefined }> | undefined
}

export const ListRow: React.FC<ListRowProps> = ({ ghostItem, times, ghosts, actionFilter }) => {
  const classes = useStyles()
  const [isOpenList, setIsOpenList] = React.useState(false)

  const filteredActions = actionFilter(ghostItem?.research?.nextAction)

  const handleCloseList = () => {
    setIsOpenList(false)
  }
  const handleOpenList = () => {
    setIsOpenList(true)
  }

  return (
    <tr key={ghostItem.history?.id} className={classes.bodyRow}>
      <th className={classNames(classes.times, { [classes.openBack]: isOpenList })} scope="row">
        <div className={classes.ghostTimesWrapper}>
          {times}回目の探索結果（
          {dayjs(ghostItem.history?.createdAt).format('YY/MM/DD')}）
          <ButtonCSV ghostItem={ghostItem} times={times} ghosts={ghosts} actionFilter={actionFilter} />
        </div>
      </th>
      <td className={classNames({ [classes.openBack]: isOpenList })}>
        <div className={classes.ghostCellWrapper}>
          <div className={classes.ghostCell}>
            <img
              className={classes.ghostImg}
              src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${ghostItem.history?.ghostId}.svg`}
              alt="オバケ"
            />
            <p className={classes.ghostName}>{ghosts.find((ghost) => ghost.id === ghostItem.history?.ghostId)?.name}</p>
          </div>
        </div>
      </td>
      <td className={classNames(classes.actionListTd, { [classes.openBack]: isOpenList })}>
        <div className={classes.actionListBox}>
          <NextActions filteredActions={filteredActions} isOpenList={isOpenList} />
        </div>

        {filteredActions && filteredActions.length > 0 ? (
          <>
            <div className={classes.buttonSpace}></div>

            <div className={classes.actionListButton}>
              <NoStyleButton onClick={isOpenList ? handleCloseList : handleOpenList}>
                <span>{isOpenList ? '閉じる' : '続きを見る'}</span>
                <img src={`${process.env.PUBLIC_URL}/assets/svg/arrow-${isOpenList ? 'up' : 'down'}.svg`} alt="" />
              </NoStyleButton>
            </div>
          </>
        ) : null}
      </td>
    </tr>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    bodyRow: {
      '&:not(:first-of-type)': {
        borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
      '& th': {
        padding: 16,
        verticalAlign: 'top',
      },
      '& td': {
        padding: 0,
        verticalAlign: 'top',
      },
      '& td:last-of-type': {
        borderLeft: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      },
    },
    openBack: {
      background: constants.COLOR_WHITE2,
    },
    times: {
      fontWeight: 'bold',
      [theme.breakpoints.up('lg')]: {
        width: 250,
      },
    },
    ghostTimesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 6,
    },
    ghostCellWrapper: {
      height: '100%',
      borderLeft: '2px solid #fff',
      borderRight: '2px solid #fff',
    },
    ghostCell: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      width: 144,
      padding: 16,
      [theme.breakpoints.up('lg')]: {
        width: 168,
      },
    },
    ghostImg: {
      height: 32,
    },
    ghostName: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    actionListTd: {
      position: 'relative',
    },
    actionListBox: {
      maxHeight: 258,
      borderLeft: '2px solid #fff',
      overflowY: 'auto',
    },
    buttonSpace: {
      background: '#fff',
      height: 27,
    },
    actionListButton: {
      position: 'absolute',
      right: 16,
      bottom: 5,
      '& button': {
        padding: '2px 4px',
      },
      '& span': {
        color: constants.COLOR_MAIN_NEW,
        fontSize: 10,
        fontWeight: 'bold',
      },
      '& img': {
        position: 'relative',
        top: -1,
        marginLeft: 3,
        width: 10,
      },
    },
  }),
  { name: 'ListRow' }
)
