import React, { useContext, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Typography, Theme, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'
import makeStyles from '@mui/styles/makeStyles'

import { Avatar } from 'pages/hint/_shared/components/avatar/Index'
import { SearchedNothing } from 'pages/onboarding/components'
import { SearchBar } from 'pages/onboarding/components/search-bar'
import { useTeam, useTeamMembers } from 'pages/onboarding/hooks/team'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { teamMemberRoleLabel } from 'pages/onboarding/utils/authLabel'
import { convertUrl } from 'pages/onboarding/utils/convertText'
import { OnboardingTeamMember, OnboardingTeamMemberRole, OnboardingTeamMemberStatus } from 'utils/generated'

import { OnbContext } from '../PagesRoot'
import { useTutorial } from '../tutorial/logics/hooks'
import { TutorialProps } from '../tutorial/Tutorial'

import { Modal, Toolbar } from './components'

import NoAvatarSvg from './assets/no-avatar.svg'
import RestartSvg from './assets/restart.svg'
import StopSvg from './assets/stop.svg'
import * as constants from 'assets/constants'
import { OnbPages } from 'assets/pages'

const roles: { value: string; label: string }[] = [
  { value: OnboardingTeamMemberRole.Admin, label: teamMemberRoleLabel(OnboardingTeamMemberRole.Admin) },
  { value: OnboardingTeamMemberRole.Supporter, label: teamMemberRoleLabel(OnboardingTeamMemberRole.Supporter) },
  { value: OnboardingTeamMemberRole.Member, label: teamMemberRoleLabel(OnboardingTeamMemberRole.Member) },
]

type TutorialOverride = {
  teamMembers: OnboardingTeamMember[]
  refresh: () => Promise<any>
}

export const TeamBloc = {
  useAdapter: (override?: TutorialOverride) => {
    const onbStore = useContext(OnbContext)
    const onbTeamMembers = useTeamMembers(onbStore.teamId)
    const { team } = useTeam(onbStore.teamId)
    const { updateTeamMember, deleteMember } = useTeamManage()

    return {
      ...onbStore,
      ...onbTeamMembers,
      team,
      updateTeamMember,
      deleteMember,
      ...override,
    }
  },
  useControl: () => {
    const [input, setInput] = useState<string>('')
    // ...other state logics here , after writing unit tests #later
    return {
      input,
      setInput,
    }
  },
}

export const Index: React.FC<{
  tutorial?: TutorialProps
  override?: TutorialOverride
}> = (props) => {
  // deps
  const { ttRef } = useTutorial(props.tutorial)
  const {
    team,
    teamId,
    teamMember,
    teamMembers,
    currentToken,
    currentPage,
    LIMIT,
    refresh,
    next,
    prev,
    updateTeamMember,
    deleteMember,
  } = TeamBloc.useAdapter(props.override)

  // presentation

  const teamLabel = team ? `${team.title}(${team.memberCount || 0})` : ''

  // UI control & events
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [selectedMemberId, setSelectedMemberId] = useState<string | undefined>(undefined)
  const { input, setInput } = TeamBloc.useControl()
  const [search, setSearch] = useState<string>('')
  type InvitedKey = 'done' | 'changeReserved' | 'alreadyInvited' | 'unknownError'
  const invitedMsg: { [k in InvitedKey]: string } = {
    done: '該当のメールアドレスに招待メールを\n送信しました。',
    changeReserved: '予約時間を変更しました。',
    alreadyInvited: '招待したメンバーは\n既に招待されております。',
    unknownError: 'エラーが発生しました。\nお手数ですがもう一度操作を行なってください。',
  }
  const [isInvitedModal, setIsInvitedModal] = useState<InvitedKey | undefined>(undefined)

  const handleStartSearch = () => {
    setSearch(input)
  }

  const handleStartInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value)
  }

  const handleStartEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setSearch(input)
    }
  }

  // For modals
  const [isShownToSuspendModal, setIsShownToSuspendModal] = useState(false)
  const [isShownToNotSuspendModal, setIsShownToNotSuspendModal] = useState(false)
  const [isShownToDeleteModal, setIsShownToDeleteModal] = useState(false)
  const [selectedTeamMember, setSelectedTeamMember] = useState<null | string>(null)

  const handleOpenNotSuspendModal = (memberId: string) => {
    setSelectedTeamMember(memberId)
    setIsShownToNotSuspendModal(true)
  }

  const handleOpenSuspendModal = (memberId: string) => {
    setSelectedTeamMember(memberId)
    setIsShownToSuspendModal(true)
  }

  const handleStopTeamMember = async () => {
    if (teamId && selectedTeamMember) {
      await updateTeamMember(
        {
          teamId,
          status: OnboardingTeamMemberStatus.Stopped,
        },
        selectedTeamMember
      )
      refresh()
      setIsShownToSuspendModal(false)
    }
  }

  const handleDeleteTeamMember = async () => {
    if (teamId && selectedTeamMember) {
      await deleteMember({
        teamId,
        userId: selectedTeamMember,
      })
      refresh()
      setIsShownToDeleteModal(false)
    }
  }

  const handleRestoreTeamMember = async () => {
    if (teamId && selectedTeamMember) {
      await updateTeamMember(
        {
          teamId,
          status: OnboardingTeamMemberStatus.Accepted,
        },
        selectedTeamMember
      )
      refresh()
      setIsShownToNotSuspendModal(false)
    }
  }

  const handleDescription = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const el = e.target as HTMLElement
    if (el.tagName === 'A') {
      e.stopPropagation()
    }
  }

  const handleCloseSuspend = useCallback(() => {
    setIsShownToSuspendModal(false)
  }, [])

  const handleOpenDelete = useCallback(() => {
    setIsShownToSuspendModal(false)
    setIsShownToDeleteModal(true)
  }, [])

  const handleCloseDelete = useCallback(() => {
    setIsShownToDeleteModal(false)
  }, [])

  const handleCloseNotSuspend = useCallback(() => {
    setIsShownToNotSuspendModal(false)
  }, [])

  const inviteCloseSuspend = useCallback(() => {
    setIsInvitedModal(undefined)
  }, [])

  const { state } = useLocation<{ invFlg: InvitedKey } | undefined>()
  const invFlg = state?.invFlg
  const getSearchParams = useCallback(() => {
    if (state?.invFlg !== undefined) {
      setIsInvitedModal(invFlg)
    }
  }, [invFlg, state?.invFlg])
  const classes = useStyles()
  const history = useOnbHistory()

  React.useEffect(() => {
    getSearchParams()
    window.history.replaceState({}, ``)
    return () => {
      setIsInvitedModal(undefined)
    }
  }, [getSearchParams])

  const searchedTeamMembers = teamMembers
    .filter((member) => (selectedMemberId !== undefined ? member.role === selectedMemberId : true))
    .filter((member) => member.nickname && member.nickname.indexOf(search) !== -1)

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h6" className={classes.title}>
          メンバーリスト
        </Typography>
        <Toolbar
          teamId={teamId}
          teamLabel={teamLabel}
          members={roles}
          selectedMemberId={selectedMemberId}
          handleOnClickMember={(value) => {
            setSelectedMemberId(value === '' ? undefined : value)
          }}
          handleOnClickAdd={() => {
            history.push(OnbPages.MemberManageAddMember, { teamId })
          }}
          teamMember={teamMember}
          ttRef={ttRef}
          displayValue={teamMemberRoleLabel(selectedMemberId) ? teamMemberRoleLabel(selectedMemberId) : '全員'}
          ownStyles={isMdUp ? {} : { margin: '0 16px 16px' }}
        />
      </div>
      <div className={classes.searchBar}>
        <SearchBar
          placeholder="名前で検索"
          borderRadius={8}
          iconMarginLeft={16}
          iconMarginRight={21}
          onClickImg={handleStartSearch}
          onChangeInput={handleStartInput}
          onKeyPressInput={handleStartEnter}
          inputValue={input}
          setInputValue={setInput}
          searchValue={search}
          setSearchValue={setSearch}
        />
      </div>

      {teamMembers.length ? (
        <>
          <Box className={classes.list}>
            {searchedTeamMembers.length ? (
              searchedTeamMembers.map((member) => (
                <div
                  key={member.id}
                  onClick={() => {
                    member.status === 'EMAILRESERVED'
                      ? history.push(OnbPages.MemberManageInviteEdit, { teamId, userId: member.userId })
                      : history.push(OnbPages.TeamMember, { teamId, userId: member.userId })
                  }}
                  style={{ marginBottom: 16, cursor: 'pointer' }}
                >
                  <Box className={classes.root}>
                    <Box className={classes.avatar}>
                      <Avatar src={member.imageUrl || NoAvatarSvg} alt="avatar" size={60} />
                    </Box>
                    <Grid container>
                      <Grid size={{ xs: 12 }}>
                        <Box className={classes.name}>
                          {member.user && member.nickname && member.nickname !== '名無し'
                            ? member.nickname
                            : member.email ?? ''}
                        </Box>
                        {member.position ? <Box className={classes.position}>{member.position}</Box> : <></>}
                        <Box className={classes.labelWrapper}>
                          <Box className={classes.role__label}>
                            <div>{teamMemberRoleLabel(member.role)}</div>
                          </Box>
                          {member.status === 'PENDING' && (
                            <Box className={classes.inInvitationLabel}>
                              <div>招待中</div>
                            </Box>
                          )}
                          {member.status === 'STOPPED' && (
                            <Box className={classes.stoppedLabel}>
                              <div>一時停止</div>
                            </Box>
                          )}
                          {member.status === 'EMAILRESERVED' && (
                            <>
                              <Box className={classes.bookedInvitationLabel}>
                                <div>予約招待</div>
                              </Box>
                              <div className={classes.bookedInvitationTime}>
                                {member?.reserveWillSendEmailAt}に配信予定
                              </div>
                            </>
                          )}
                        </Box>
                      </Grid>

                      <Grid size={{ xs: 12 }}>
                        <div
                          className={classes.description}
                          dangerouslySetInnerHTML={{ __html: convertUrl(member.description || '') }}
                          onClick={(e) => handleDescription(e)}
                        />
                      </Grid>
                    </Grid>

                    {teamMember?.role === OnboardingTeamMemberRole.Admin &&
                    member.role !== OnboardingTeamMemberRole.Admin ? (
                      <div className={classes.memberManage}>
                        {member.status === OnboardingTeamMemberStatus.Stopped ? (
                          <img
                            className={`${classes.manageButton} restart`}
                            src={RestartSvg}
                            alt={'restart-icon'}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleOpenNotSuspendModal(member.userId)
                            }}
                          />
                        ) : (
                          <img
                            className={`${classes.manageButton} stop`}
                            src={StopSvg}
                            alt={'stop-icon'}
                            onClick={(e) => {
                              e.stopPropagation()
                              handleOpenSuspendModal(member.userId)
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </Box>
                </div>
              ))
            ) : (
              <SearchedNothing />
            )}
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              {currentPage !== 0 ? (
                <span style={{ cursor: 'pointer' }} onClick={prev}>{`前の${LIMIT}件`}</span>
              ) : (
                <span></span>
              )}
              {currentToken && <span style={{ cursor: 'pointer' }} onClick={next}>{`次の${LIMIT}件`}</span>}
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}

      {/* modals */}
      <Modal
        open={isShownToSuspendModal}
        handleClose={handleCloseSuspend}
        msg={{ main: 'メンバーの一時停止をしますか？' }}
        firstBtnBody="キャンセル"
        firstBtnHandleClick={handleCloseSuspend}
        secondBtnBody="はい"
        secondBtnHandleClick={handleStopTeamMember}
        bottom={{ body: 'チームから完全に削除する', onClick: handleOpenDelete }}
      />

      <Modal
        open={isShownToDeleteModal}
        handleClose={handleCloseDelete}
        msg={{ main: 'チームから完全に削除しますか？' }}
        firstBtnBody="キャンセル"
        firstBtnHandleClick={handleCloseDelete}
        secondBtnBody="はい"
        secondBtnHandleClick={handleDeleteTeamMember}
      />

      <Modal
        open={isShownToNotSuspendModal}
        handleClose={handleCloseNotSuspend}
        msg={{ main: 'メンバーの一時停止を解除しますか？', sub: '「はい」を押すとそのメンバーはチームに戻ります。' }}
        firstBtnBody="いいえ"
        firstBtnHandleClick={handleCloseNotSuspend}
        secondBtnBody="はい"
        secondBtnHandleClick={handleRestoreTeamMember}
      />
      <Modal
        open={Boolean(isInvitedModal)}
        handleClose={inviteCloseSuspend}
        msg={{
          main: invFlg === 'done' || invFlg === 'changeReserved' ? '招待が完了しました。' : `招待できませんでした。`,
          sub: invFlg ? invitedMsg[invFlg] : '',
        }}
        firstBtnBody="OK"
        firstBtnHandleClick={inviteCloseSuspend}
        openInvFlg={true}
        invFlg={invFlg}
      />
    </>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    titleWrapper: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 16px',
      },
    },
    title: {
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 21,
      marginBottom: 26,
    },

    searchBar: {
      display: 'flex',
      justifyContent: 'center',
      margin: '0px 16px 24px 16px',
      border: 'solid 1px #E9E9E9',
      borderRadius: 8,
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 16px',
    },

    avatar: {
      marginRight: 16,
    },

    root: {
      position: 'relative',
      display: 'flex',
      borderRadius: 3,
      padding: '24px 16px 51px 16px',
      backgroundColor: constants.COLOR_WHITE,
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
      [theme.breakpoints.down('md')]: {
        paddingBottom: '27px',
      },
    },

    labelWrapper: {
      display: 'flex',
      columnGap: '8px',
      flexWrap: 'wrap',
      alignItems: 'center',
    },

    role__label: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      paddingLeft: 8,
      paddingRight: 8,
      color: constants.COLOR_ONBOARDING_MAIN,
      border: `1px solid ${constants.COLOR_ONBOARDING_MAIN}`,
      height: '20px',
      fontWeight: 'bold',
    },

    bookedInvitationTime: {
      fontSize: 10,
      margin: '6px 0',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      color: constants.COLOR_BLACK,
    },

    inInvitationLabel: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      padding: '0 10px',
      backgroundColor: constants.COLOR_ONBOARDING_ALERT,
      color: constants.COLOR_WHITE,
      height: '20px',
      fontWeight: 'bold',
    },

    stoppedLabel: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      padding: '0 10px',
      backgroundColor: constants.COLOR_ACTION_FAILED_DARK,
      color: constants.COLOR_WHITE,
      height: '20px',
      fontWeight: 'bold',
    },

    bookedInvitationLabel: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      fontSize: 10,
      padding: '0 10px',
      backgroundColor: constants.COLOR_ONBOARDING_WARNING,
      color: constants.COLOR_BLACK,
      height: '20px',
      fontWeight: 'bold',
    },

    name: {
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: '18px',
      marginTop: 3,
      marginBottom: 8,
      paddingRight: 16,
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
    },

    position: {
      fontSize: 10,
      color: constants.COLOR_ONBOARDING_GRAY_DARK,
      marginBottom: 9,
    },

    description: {
      fontSize: 14,
      padding: '8px 0px 0px',
      whiteSpace: 'pre-wrap',
      textAlign: 'justify',
      '& a': {
        color: constants.COLOR_MAIN,
        textDecoration: 'underline',
      },
    },

    linkToActionList: {
      textAlign: 'right',
    },

    linkToActionList__message: {
      fontSize: 11,
      color: constants.COLOR_MAIN,
      paddingRight: '16px',
    },

    memberManage: {
      position: 'absolute',
      top: 8,
      right: 8,
    },
    manageButton: {
      cursor: 'pointer',
      '&.restart': {
        width: 24,
      },
      '&.stop': {
        width: 26,
      },
    },
  }),
  { name: 'Team' }
)

export default Index
