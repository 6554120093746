import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { TeamBuilding } from 'utils/generated'

import { Button, ButtonContainer, QuestionContainer } from '../../components'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q3: React.FC<Props> = (props) => {
  const classes = useStyles()

  // TODO: adjust
  // const [complete, setComplete] = React.useState(false)

  const handleOnNext = () => {
    // setComplete(true)
  }

  return (
    <>
      <QuestionContainer active={3} questionHeadBar colorTitleText>
        <div className={classes.root}>
          <div
            style={{
              textAlign: 'center',
              marginBottom: 80,
            }}
          >
            デザイン無し
          </div>

          <ButtonContainer
            fixed
            buttons={[
              <Button key="q3-1" type={'submit'} onClick={handleOnNext}>
                次へ
              </Button>,
            ]}
          />
        </div>
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}))
