import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

interface Props {
  container?: boolean
}

export const Logo: React.FC<Props> = ({ container }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.logo} ${container ? classes.container : ''}`}>
      <img src={process.env.PUBLIC_URL + '/assets/svg/teams/logo_kickoff.svg'} alt="Cocolabo キックオフ" />
    </div>
  )
}

const useStyles = makeStyles({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 56,
  },

  container: {
    marginBottom: 40,
  },
})
