import * as React from 'react'

import { StyleSheet, View } from '@react-pdf/renderer'

import * as constants from '../../assets/constants'

const styles = StyleSheet.create({
  bubble: {
    fontSize: 12,
    backgroundColor: constants.COLOR_WHITE,
    padding: '8px 16px',
    borderRadius: 12,
    border: '1px solid #C9C9C9',
    position: 'relative',
  },
  bubbleBefore: {
    position: 'absolute',
    bottom: 6,
    left: -10,
    marginTop: -15,
    border: '6px solid #EBEBEB',
    borderRight: '10px solid #C9C9C9',
    zIndex: 0,
    borderLeft: 0,
    borderTop: 0,
    transform: 'rotate(-25deg)',
  },
  bobbleAfter: {
    position: 'absolute',
    bottom: 6,
    left: -8,
    marginTop: -15,
    border: '6px solid #EBEBEB',
    borderRight: '10px solid #FFF',
    zIndex: 0,
    borderLeft: 0,
    borderTop: 0,
    transform: 'rotate(-25deg)',
  },
})

type Props = {
  children: React.ReactNode
}

export const SpeechBubbles: React.FC<Props> = ({ children }) => {
  return (
    <View style={styles.bubble}>
      {children}
      <View style={styles.bubbleBefore} />
      <View style={styles.bobbleAfter} />
    </View>
  )
}
