import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { NoStyleButton } from 'components/NoStyleButton'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import tooltipSvg from '../../assets/tooltip.svg'

import { constants } from 'assets'

type Props = {
  balloonId: string
  title: string
  body: string
} & StyleProps

type StyleProps = {
  width?: number
  positions?: { top?: Position; right?: Position; bottom?: Position; left?: Position }
}

type Position = number | string

export const Tooltip: React.FC<Props> = ({
  balloonId,
  title,
  body,
  width = 300,
  positions = { top: '100%', left: 0 },
}) => {
  const classes = useStyles({ width, positions })
  const isSmDown = useCustomMediaQuery('down', 'md')

  const clickHandler = React.useCallback(
    (e: MouseEvent) => {
      // クリックした場所が balloon の外側の場合は非表示にする
      // closest(): 自身の要素から始めて祖先に向けて照合する（引数のセレクターが見つからない場合、null を返す）
      if ((e.target as HTMLElement).closest(`#${balloonId}`) === null) {
        const balloon = document.getElementById(balloonId)
        balloon?.style.setProperty('display', 'none')
      }
    },
    [balloonId]
  )

  React.useEffect(() => {
    // SP サイズのみ click イベントを追加（PC サイズは hover で対応）
    if (isSmDown) {
      document.addEventListener('click', clickHandler)
    }

    return () => {
      const balloon = document.getElementById(balloonId)
      balloon?.style.setProperty('display', 'none')
      document.removeEventListener('click', clickHandler)
    }
  }, [isSmDown, clickHandler, balloonId])

  // --------------------
  // for SP - クリック（タップ）で表示・非表示を切り替える

  const handleToggle = (e: React.MouseEvent) => {
    // addEventListener のイベントをキャンセル
    e.stopPropagation()

    if (isSmDown) {
      const balloon = document.getElementById(balloonId)

      if (balloon?.style.display === 'none') {
        balloon?.style.setProperty('display', 'block')
      } else {
        balloon?.style.setProperty('display', 'none')
      }
    }
  }
  // --------------------
  // for PC - hover で表示・非表示を切り替える

  const handleOn = () => {
    if (!isSmDown) {
      const balloon = document.getElementById(balloonId)
      balloon?.style.setProperty('display', 'block')
    }
  }

  const handleOff = () => {
    if (!isSmDown) {
      const balloon = document.getElementById(balloonId)
      balloon?.style.setProperty('display', 'none')
    }
  }
  // --------------------

  return (
    <div className={classes.container}>
      <NoStyleButton
        type="button" // form内でクリックしても submit が発火しないよう、type="button" を指定
        className={classes.icon}
        onClick={handleToggle}
        onMouseEnter={handleOn}
        onMouseLeave={handleOff}
      >
        <img src={tooltipSvg} alt="tooltip" className={classes.img} />
      </NoStyleButton>

      {/* 画面サイズが PC・SP で切り替わっても問題がないよう、css ではなく style 属性で一括管理する */}
      {/* ※ hover のみ css で管理すると、style 属性での変更が優位になるため、画面サイズが SP から PC に切り替わったときに挙動がうまくいかない */}
      <div id={balloonId} className="balloon" style={{ display: 'none' }}>
        <p className={classes.title}>{title}</p>
        <p className={classes.body}>{body}</p>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: ({ width, positions }: StyleProps) => ({
      position: 'relative',

      '& .balloon': {
        position: 'absolute',
        top: positions?.top,
        right: positions?.right,
        bottom: positions?.bottom,
        left: positions?.left,
        padding: 16,
        width,
        backgroundColor: constants.COLOR_TOOLTIP_CONTENT,
        fontSize: 14,
        borderRadius: 8,
        transform: 'translateZ(0)', // filter: drop-shadow の safari 対策
        filter: 'drop-shadow(0 3px 6px #00000029)', // 矢印にも影をつけるため boxShadow は使用しない
        whiteSpace: 'pre-wrap',
        zIndex: 100,
      },
      // balloon の矢印部分
      '& .balloon::before': {
        position: 'absolute',
        content: '""',
        top: -10,
        // 4 は ? icon との位置関係の微調整
        left: typeof positions?.left === 'number' ? -positions.left + 4 : undefined,
        right: typeof positions?.right === 'number' ? -positions.right + 4 : undefined,
        borderStyle: 'solid',
        borderWidth: '0 8px 10px 8px',
        borderColor: `transparent transparent ${constants.COLOR_TOOLTIP_CONTENT} transparent`,
      },
    }),
    icon: {
      padding: 4,
    },
    img: {
      verticalAlign: 'top',
    },
    title: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontWeight: 'bold',
    },
    body: {
      margin: '8px 0 0',
    },
  }),
  { name: 'Tooltip' }
)
