import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import dayjs from 'dayjs'

import { constants } from 'assets'

type Props = {
  from?: string | null // ISODateString
  time?: number // seconds
  container?: boolean
  setIsPresenEnd: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReviewTimer: React.FC<Props> = ({
  from = new Date().toISOString(),
  time = 300,
  container,
  setIsPresenEnd,
}) => {
  const [intervalTimer, setIntervalTimer] = React.useState<NodeJS.Timeout | undefined>(undefined)
  const [count, setCount] = React.useState<number | undefined>(undefined)
  const minutes = React.useMemo(() => {
    if (count !== undefined) {
      return Math.floor(count / 60)
    } else {
      return undefined
    }
  }, [count])

  const seconds = React.useMemo(() => {
    if (count !== undefined) {
      return count % 60
    } else {
      return undefined
    }
  }, [count])

  const label = React.useMemo(() => {
    if (minutes !== undefined && seconds !== undefined) {
      return `${zeroPadding(minutes, 2)}:${zeroPadding(seconds, 2)}`
    } else {
      return undefined
    }
  }, [minutes, seconds])

  const isEnd = React.useMemo(() => count === 0, [count])

  const classes = useStyles({ isEnd, container })

  React.useEffect(() => {
    if (intervalTimer) {
      clearInterval(intervalTimer)
    }

    const interval = setInterval(() => {
      setCount(() => {
        const diff = -dayjs(from).diff(dayjs(new Date().toISOString()), 'second')
        if (time > diff) {
          return time - diff
        } else {
          clearInterval(interval)
          return 0
        }
      })
    }, 1000)

    setIntervalTimer(interval)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, time])
  React.useEffect(() => {
    setIsPresenEnd(isEnd)
  }, [isEnd, setIsPresenEnd])
  return (
    <>
      {label && (
        <div className={classes.timerWrap}>
          <span className={classes.label}>残り時間</span>
          <span className={classes.time}>{label}</span>
        </div>
      )}
    </>
  )
}

interface StyleProps {
  isEnd: boolean
  container?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  timerWrap: (props: StyleProps) => ({
    width: props.container ? '100%' : undefined,
    textAlign: props.container ? 'center' : undefined,
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    marginTop: props.container ? 40 : undefined,
    marginBottom: props.container ? 40 : undefined,
    [theme.breakpoints.down('sm')]: {
      marginBottom: props.container ? 20 : undefined,
    },
    // '@media (min-width: 631px)': {
    //   position: 'absolute',
    //   width: 'auto',
    //   display: 'flex',
    //   alignItems: 'center',
    //   padding: 12,
    //   bottom: 0,
    //   right: 0,
    //   margin: 0,
    //   backgroundColor: '#FFFFFF',
    //   boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    // },
  }),

  label: {
    color: constants.COLOR_TEAMBUILDING_TEXT,
    fontSize: '12px',
    fontWeight: 'bold',
    // '@media (min-width: 631px)': {
    //   fontSize: '14px',
    // },
  },

  time: (props: StyleProps) => ({
    display: 'inline-block',
    marginLeft: '16px',
    color: props.isEnd ? constants.COLOR_TEAMBUILDING_RED : constants.COLOR_TEAMBUILDING_PRIMARY,
    fontSize: '48px',
    fontWeight: 'bold',
    // '@media (min-width: 631px)': {
    //   fontSize: '38px',
    // },
  }),
}))

const zeroPadding = (num: number, len: number) => {
  return (Array(len).join('0') + num).slice(-len)
}
