import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { WidthControlWrapper } from 'components/WidthControlWrapper'

import { constants } from 'assets'
const UsageSteps = [
  {
    imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/step1.svg`,
    num: 'step1',
    title: 'メンバーそれぞれでオバケを探してみる',
    text: 'まずは、チームメンバー全員で「職場のオバケ探し」を使ってみて、メンバーそれぞれで、職場にいるオバケを見つけてみましょう。',
  },
  {
    imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/step2.svg`,
    num: 'step2',
    title: '見つけたオバケをみんなで共有する',
    text: 'それぞれが見つけたオバケを共有して、オバケの特徴や出没シーンを見ながら、どうしてそのオバケがいると思ったのか、理由を共有しましょう。',
  },
  {
    imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/step3.svg`,
    num: 'step3',
    title: 'オバケがでてきた原因をみんなで考えてみる',
    text: 'みなさんが見つけたオバケが出てきてしまった原因を、出没経緯やオバケの悪さ・ご利益を見ながら考えてみて、それぞれで共有しながら対話しましょう。',
  },
  {
    imageSrc: `${process.env.PUBLIC_URL}/img/obakesagashi/step4.svg`,
    num: 'step4',
    title: '対処方法をみんなで話し合う',
    text: 'みんなで見つけたオバケを退治するアクション例やオバケがいなくなった後の副作用を読みながら、今後の対処方法やアクションについて話し合いましょう。',
  },
]

export const GhostUsage: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <WidthControlWrapper containerWidth={1000}>
        <h2 className={classes.title}>
          <span>
            チームで対話しながら、
            <br />
            職場のオバケを探してみましょう
          </span>
        </h2>
        <h3 className={classes.subtitle}>
          <span>オバケ探しの使いかた</span>
        </h3>
        <div className={classes.desc}>
          オバケに対処するためには職場のチームメイトとの協力は欠かせません。チームメイトと一緒にオバケ探しをしながら対話を深めていくことで、それぞれのオバケと上手に付き合っていく方法をみんなで理解していきましょう。
        </div>

        <div>
          {UsageSteps.map((step, index) => (
            <div key={step.title} className={classes.usageCard}>
              <div className={classes.cardWrapper}>
                <div className={classes.usageDesc}>
                  <div className={classes.usageTitle}>
                    <span className={classes.number}>{step.num}</span>
                    <span className={classes.ttl}>{step.title}</span>
                  </div>
                  <div className={classes.usageText}>
                    <p className={classes.text}>{step.text}</p>
                  </div>
                </div>
                {index !== UsageSteps.length - 1 && <div className={classes.triangle}></div>}
              </div>
              <div className={classes.usageImg}>
                <img src={step.imageSrc} alt={step.title} />
              </div>
            </div>
          ))}
        </div>

        <div className={classes.descBottom}>
          オバケの出現は
          <span className="marker">
            特定の誰かのせいではなく、“関係性や環境から生まれた目に見えないもの” が原因であることがほとんどです
          </span>
          。 <br />
          チームのみんなでオバケと上手に付き合っていくための対話を重ねていきましょう。
        </div>
      </WidthControlWrapper>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      color: '#000',
    },
    title: {
      margin: '145px 0 57px',
      fontSize: 40,
      fontWeight: 'bold',
      fontFamily: '"MOBO SemiBold"',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 22,
        margin: '86px 0 40px',
      },
    },
    subtitle: {
      fontSize: 24,
      margin: 0,
      marginBottom: 10,
      fontFamily: '"MOBO SemiBold"',
      lineHeight: '32px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
        fontSize: 16,
      },
    },
    desc: {
      paddingBottom: 40,
      fontSize: 16,
      lineHeight: '32px',
      [theme.breakpoints.down('md')]: {
        paddingBottom: 23,
      },
    },
    descBottom: {
      marginTop: 44,
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '32px',
      '& .marker': {
        background: `linear-gradient(transparent 80%, ${constants.COLOR_YELLOW4} 0%)`,
      },
    },

    //----------------------------------
    //  usageSteps
    //----------------------------------

    usageCard: {
      display: 'flex',
      marginBottom: 10,
      [theme.breakpoints.up('md')]: { gap: 57 },
      [theme.breakpoints.down('md')]: {
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        marginTop: 87,
      },
    },
    cardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down('md')]: {
        gap: 23,
      },
    },
    usageDesc: {
      display: 'flex',
      flexDirection: 'column',
      height: 131,
      maxWidth: 755,
      backgroundColor: '#fff',
      borderRadius: 16,
      filter: 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    usageTitle: {
      display: 'flex',
      alignItems: 'center',
      height: 50,
      padding: '0 28px',
      color: constants.COLOR_YELLOW4,
      backgroundColor: `${constants.COLOR_NAVY}`,
      borderRadius: '16px 16px 0 0',
      '& span': {
        display: 'inline-block',
        paddingTop: 8,
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: 80,
        padding: '11px 15px',
        '& span': {
          paddingTop: 0,
        },
      },
    },
    number: {
      fontSize: 20,
      fontFamily: '"MOBO SemiBold"',
      textTransform: 'uppercase',
      paddingRight: 60,
    },
    ttl: {
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    usageText: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    text: {
      margin: 0,
      padding: '0 28px',
      lineHeight: '25px',
      [theme.breakpoints.down('md')]: {
        padding: '16px 14px 13px',
        lineHeight: '22px',
      },
    },
    usageImg: {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        top: -70,
        '& img': {
          width: 110,
          height: 110,
        },
      },
    },
    triangle: {
      height: 19,
      width: 26,
      clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
      backgroundColor: constants.COLOR_NAVY,
    },
  }),

  { name: 'GhostUsage' }
)
