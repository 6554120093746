import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useForm, useWatch } from 'react-hook-form'
import { updateTeamBuildingReview, updateTeamBuildingReviewMember } from 'services/api/review'

import {
  MIN_PRESENTATION_TIME,
  TeamBuildingReviewHooksContext,
} from 'pages/teams/contexts/HooksContextTeamBuildingReview'
import { isLeader } from 'pages/teams/utils/teamMember'
import {
  TeamBuildingReviewMemberConnection,
  TeamBuildingReviewStatus,
  TeamBuildingReviewTeamAnswerType,
  TeamMember,
} from 'utils/generated'

import { ContentButton } from './components/generic/ContentButton'
import { RenderByTeamBuildingReviewStatus } from './components/RenderByTeamBuildingReviewStatus'
import { getNextStatus } from './lib'

import PleaseWait from './assets/pleaseWait.svg'
import { Pages, useHistory } from 'assets'



export type Answers = {
  goal: number
  codeOfConducts: number[]
}

const pickupRandomReviewMember = (data: TeamBuildingReviewMemberConnection, excludeUserIds: string[]) => {
  let i = 0
  while (i < 100) {
    const picked = data.items[Math.floor(Math.random() * data.items.length)]
    if (excludeUserIds.length === 0) {
      return picked
    } else {
      let duplicate = false
      for (const e of excludeUserIds) {
        if (picked.userId === e) {
          duplicate = true
        }
      }
      if (!duplicate) {
        return picked
      }
    }
    i++
  }
}

export const TeamsReviewPage: React.FC<{}> = () => {
  const classes = useStyles()
  const { reviewId, teamId } = useParams<{ teamId: string; reviewId: string }>()
  const { route } = useHistory()

  const [waitingOther, setWaitingOther] = React.useState<boolean>(false)
  const [initialized, setInitialized] = React.useState<boolean>(false)
  const {
    nextAt,
    icebreak,
    iceBreakNumber,
    iceBreakMinute,
    isPresenEnd,
    setIceBreak,
    setIceBreakMinute,
    setIceBreakNumber,
    setTeamId,
    setReviewId,
    currentTeamMember,
    role,
    loading,
    currentUser,
    summaryBuilding,
    teamMembers,
    teamBuildingReview,
    teamBuildingReviewMembers,
    teamBuildingReviewSummary,
    refreshTeamBuildingReviews,
    subscribeUpdatedTeamBuildingReview,
    subscribeUpdatedTeamBuildingReviewMember,
  } = React.useContext(TeamBuildingReviewHooksContext)

  const { control, setValue } = useForm<Answers>()
  const { goal, codeOfConducts } = useWatch<Answers>({ control })
  React.useEffect(() => {
    if (summaryBuilding?.goal && summaryBuilding?.codeOfConducts) {
      setValue('goal', 5)
      setValue(
        'codeOfConducts',
        summaryBuilding?.codeOfConducts.map((_) => 5)
      )
    }
  }, [setValue, summaryBuilding])

  const selfReviewMemberData = React.useMemo(
    () => teamBuildingReviewMembers?.items.find((item) => item.userId && item.userId === currentUser?.id),
    [currentUser, teamBuildingReviewMembers]
  )

  const waitingStatus = React.useMemo(() => {
    const isWaitingInCreated = teamBuildingReview?.status === TeamBuildingReviewStatus.Created && !!selfReviewMemberData
    const isWaitingInStep2Input =
      teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Input &&
      !!selfReviewMemberData &&
      selfReviewMemberData.answers.length > 0
    return {
      isWaitingInCreated,
      isWaitingInStep2Input,
    }
  }, [selfReviewMemberData, teamBuildingReview])

  React.useEffect(() => {
    if (teamId) {
      setTeamId(teamId)
      setReviewId(reviewId)
      refreshTeamBuildingReviews()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  const setInitMember = React.useCallback(async () => {
    await updateTeamBuildingReviewMember({
      teamBuildingReviewId: reviewId,
      answers: [],
    })
    await refreshTeamBuildingReviews()
  }, [reviewId, refreshTeamBuildingReviews])

  React.useEffect(() => {
    if (selfReviewMemberData) {
      const isWaiting = waitingStatus.isWaitingInCreated || waitingStatus.isWaitingInStep2Input

      if (selfReviewMemberData && waitingStatus.isWaitingInStep2Input) {
        setValue(
          'goal',
          selfReviewMemberData.answers.find((answer) => answer.type === TeamBuildingReviewTeamAnswerType.TeamGoal)
            ?.value
        )
        setValue(
          'codeOfConducts',
          selfReviewMemberData.answers
            .filter((answer) => answer.type === TeamBuildingReviewTeamAnswerType.CodeOfConduct)
            .map((answer) => answer.value)
        )
      }
      setWaitingOther(isWaiting)
    } else {
      if (teamBuildingReviewMembers?.items) {
        setInitMember()
      }
      setWaitingOther(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfReviewMemberData, waitingStatus, teamBuildingReviewMembers?.items])

  const presenterLastNum = React.useMemo(() => {
    return teamMembers.length >= iceBreakNumber ? iceBreakNumber : teamMembers.length
  }, [iceBreakNumber, teamMembers])

  const iceBreakUserIds = React.useMemo(() => {
    if (loading) {
      return []
    }

    if (teamBuildingReview?.iceBreakUserIds?.length) {
      return teamBuildingReview?.iceBreakUserIds
    }

    return teamMembers
      .reduce((_, cur, i) => {
        const rand = Math.floor(Math.random() * (i + 1))
        teamMembers[i] = teamMembers[rand]
        teamMembers[rand] = cur
        return teamMembers
      }, [] as TeamMember[])
      .map((member) => member.userId)
      .filter((_, index) => presenterLastNum >= index)
  }, [loading, teamBuildingReview, teamMembers, presenterLastNum])

  const nextStatus = React.useMemo(
    () => getNextStatus(teamBuildingReview, presenterLastNum, role, !!teamBuildingReviewSummary?.histories?.length),
    [presenterLastNum, teamBuildingReview, role, teamBuildingReviewSummary]
  )
  const updatedReviewInput = React.useMemo(
    () => ({
      id: reviewId,
      status: nextStatus,
      iceBreak: icebreak,
      iceBreakNumber,
      iceBreakUserIds,
      iceBreakMinute,
      nextAt,
    }),
    [iceBreakMinute, iceBreakNumber, iceBreakUserIds, icebreak, nextAt, nextStatus, reviewId]
  )
  const skipNextAtToCompleted = async () => {
    if (window.confirm('次回開催日時を設定せず終了します。本当によろしいですか')) {
      await updateTeamBuildingReview({
        id: reviewId,
        status: nextStatus,
        iceBreak: icebreak,
        iceBreakNumber,
        iceBreakUserIds,
        iceBreakMinute,
      })
    }
  }
  React.useEffect(() => {
    if (!initialized && !loading && teamBuildingReview) {
      setInitialized(true)
      subscribeUpdatedTeamBuildingReview()
      subscribeUpdatedTeamBuildingReviewMember()
      setIceBreak(teamBuildingReview.iceBreak)
      setIceBreakNumber(teamBuildingReview.iceBreakNumber ?? 1)
      setIceBreakMinute(teamBuildingReview.iceBreakMinute ?? MIN_PRESENTATION_TIME)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, loading, teamBuildingReview])

  const buttonOrLanding = React.useCallback(
    (button: React.ReactNode, hide = false) => {
      const landing = hide ? null : (
        <div style={{ textAlign: 'center', width: '100%', height: 22, marginTop: 30 }}>
          <img src={PleaseWait} alt="" />
        </div>
      )
      return isLeader(currentTeamMember) ? button : landing
    },

    [currentTeamMember]
  )

  const needResetPresenMember = React.useMemo(() => {
    return (
      teamBuildingReview?.status === TeamBuildingReviewStatus.Step1Select ||
      teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Select
    )
  }, [teamBuildingReview])

  const needSetPresenMember = React.useMemo(() => {
    return (
      needResetPresenMember ||
      teamBuildingReview?.status === TeamBuildingReviewStatus.Step1Presen ||
      teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Presen
    )
  }, [needResetPresenMember, teamBuildingReview])

  const callUpdate = React.useCallback(async () => {
    await updateTeamBuildingReview({
      ...updatedReviewInput,
      status: teamBuildingReview?.status,
    })
  }, [teamBuildingReview, updatedReviewInput])

  const nextStepButton: React.ReactNode = React.useMemo(() => {
    const onClick = async () => {
      if (!teamBuildingReview) {
        return
      }

      if (teamBuildingReview.status === TeamBuildingReviewStatus.Completed) {
        route.push(Pages.TeamsDashboard, { teamId })
        return
      }

      if (needSetPresenMember) {
        const ids =
          needResetPresenMember || !teamBuildingReview.iceBreakUserIds ? [] : teamBuildingReview.iceBreakUserIds

        if (teamBuildingReviewMembers && ids.length < presenterLastNum) {
          const picked = pickupRandomReviewMember(teamBuildingReviewMembers, ids)
          if (picked) {
            await updateTeamBuildingReview({ ...updatedReviewInput, iceBreakUserIds: [...ids, picked.userId] })
            window.scrollTo(0, 0)
          }
        } else {
          await updateTeamBuildingReview({ ...updatedReviewInput, iceBreakUserIds: [] })
          window.scrollTo(0, 0)
        }

        return
      }

      if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Input) {
        if (goal !== undefined && codeOfConducts !== undefined) {
          const answers = [goal, ...codeOfConducts].map((answer, i) => ({
            type: i === 0 ? TeamBuildingReviewTeamAnswerType.TeamGoal : TeamBuildingReviewTeamAnswerType.CodeOfConduct,
            index: i === 0 ? i : i - 1,
            value: answer,
          }))

          await updateTeamBuildingReviewMember({
            teamBuildingReviewId: reviewId,
            answers,
          })
          setWaitingOther(true)
          return
        }
      }

      await updateTeamBuildingReview(updatedReviewInput)
      window.scrollTo(0, 0)
    }

    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Created) {
      return isLeader(currentTeamMember) ? (
        buttonOrLanding(<ContentButton onClick={onClick} label="はじめる" />)
      ) : (
        (<></>)
        // <ContentButton
        //   onClick={() => {
        //     if (teamBuildingReview?.status === TeamBuildingReviewStatus.Created && !waitingOther) {
        //       setWaitingOther(true)
        //       return
        //     }
        //   }}
        //   label="参加する"
        // />
      );
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step1) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="内容を確定する" disabled={!icebreak} />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step1Select) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="内容を確定する" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step1Presen) {
      return buttonOrLanding(<ContentButton onClick={onClick} label={isPresenEnd ? '次へ' : 'スキップ'} />, true)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step1End) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="STEP2へ進む" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="次へ" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Input) {
      return waitingOther ? (
        buttonOrLanding(<ContentButton onClick={onClick} label="次へ" />, true)
      ) : (
        <ContentButton onClick={onClick} label="回答する" />
      )
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Confirm) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="次へ" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Select) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="次へ" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2Presen) {
      return buttonOrLanding(<ContentButton onClick={onClick} label={isPresenEnd ? '次へ' : 'スキップ'} />, true)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step2End) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="STEP3へ進む" />)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step3) {
      return buttonOrLanding(<ContentButton onClick={onClick} label="次へ" />, true)
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Step3Select) {
      return buttonOrLanding(
        <div style={{ margin: '0 16px' }}>
          <ContentButton onClick={onClick} label="日時を確定する" disabled={!nextAt} />
          <ContentButton onClick={skipNextAtToCompleted} label="設定をスキップして終了" type="border" minMargin />
        </div>
      )
    }
    if (teamBuildingReview?.status === TeamBuildingReviewStatus.Completed) {
      return <ContentButton onClick={onClick} label="HOMEへ" />
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    teamBuildingReview,
    presenterLastNum,
    updatedReviewInput,
    route,
    teamId,
    goal,
    codeOfConducts,
    reviewId,
    isPresenEnd,
    icebreak,
    needSetPresenMember,
  ])

  if (!loading && !teamBuildingReview) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <RenderByTeamBuildingReviewStatus
          teamBuildingReview={teamBuildingReview}
          reviewMemberControl={control}
          waitingOther={waitingOther}
          callUpdate={callUpdate}
        />
      </div>
      <div className={classes.footerButtons}>{nextStepButton}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 632px)': {
      justifyContent: 'space-between',
    },

    backgroundColor: '#F5F5F5',
    width: '100vw',
    minHeight: '100vh',
    padding: '32px 0',
    color: '#333333',
  },
  container: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 600,
    padding: '16px 0',
    '@media (max-width: 632px)': {
      padding: 16,
    },
  },
  footerButtons: {},
}))
