import React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { FormProvider, useForm } from 'react-hook-form'
import { RouteComponentProps } from 'react-router'

import { useCustomMediaQuery } from 'hooks/mediaQuery'
import { DropZoneCsv } from 'pages/onboarding/components'
import {
  Card,
  CardFooter,
  CSVTemplateDownloadBox,
  DefaultButton,
  ButtonContainer,
  ResultFramework,
  ResultWithIcon,
  HorizontalLine,
  ReservationFramework,
} from 'pages/teams/components'
import { useTeamMembersCsvUpload } from 'pages/teams/hooks/teamMembers'
import { formatDateTime } from 'pages/teams/utils/dateTimeHandler'

import { useHistory } from 'assets/history'
import { Pages } from 'assets/pages'

type Props = RouteComponentProps<{ teamId: string }>

interface IInput {
  sendEmailDateAt?: string
  sendEmailTimeAt?: string
}

type DateBox = {
  date: string
  dayOfWeek: string
  time: string
}

const defaultValues = {
  sendEmailDateAt: '',
  sendEmailTimeAt: '',
}

export const TeamsMembersAddByCsvPage: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { teamId } = props.match.params
  const methods = useForm<IInput>()
  const { handleSubmit } = methods
  const [dateBox, setDateBox] = React.useState<DateBox>()

  const { route } = useHistory()
  const {
    complete,
    processing,
    uploaded,
    uploadFile,
    setUploadFile,
    setCsvData,
    uploadErrors,
    disabled,
    handleBack,
    handleToMembers,
    onSubmit: onUploadMembers,
  } = useTeamMembersCsvUpload(teamId)

  const isSmDown = useCustomMediaQuery('down', 'md')
  const isXsDown = useCustomMediaQuery('down', 'sm')

  const onSubmit = (input: IInput) => {
    let sendEmailAt = ''

    if (input.sendEmailDateAt && !input.sendEmailTimeAt) {
      alert('招待メールの送信時間を入力してください。')
      return
    }
    if (!input.sendEmailDateAt && input.sendEmailTimeAt) {
      alert('招待メールの送信日を入力してください。')
      return
    }

    if (input.sendEmailDateAt && input.sendEmailTimeAt) {
      const { formattedDate, formattedDateJP, formattedTime, dayOfWeek } = formatDateTime(
        input.sendEmailDateAt,
        input.sendEmailTimeAt
      )
      sendEmailAt = `${formattedDate} ${formattedTime}`

      if (new Date(sendEmailAt) < new Date()) {
        alert('予約の時間は既に過ぎております。')
        return
      }

      setDateBox({
        date: formattedDateJP,
        dayOfWeek: `${dayOfWeek}曜日`,
        time: formattedTime,
      })
    }

    onUploadMembers(sendEmailAt)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          headerTitle={'メンバー 一括追加'}
          headerRightNode={
            !isSmDown && !complete ? <CSVTemplateDownloadBox type={!complete ? 'team-member' : undefined} /> : <></>
          }
          style={{
            padding: isSmDown ? '16px 16px 24px' : 24,
            justifyContent: isSmDown ? 'center' : 'space-between',
            alignItems: 'flex-start',
          }}
          headerStyles={{ justifyContent: 'space-between' }}
          titleStyles={{ height: isSmDown ? 22 : 40, fontSize: 14, display: 'flex', alignItems: 'center' }}
          headerMb={0}
          titleContainerPb={0}
        >
          {!complete ? (
            <>
              {isSmDown && <CSVTemplateDownloadBox type={!complete ? 'team-member' : undefined} />}
              <HorizontalLine style={{ marginTop: isSmDown ? 16 : 24, marginBottom: 24 }} />
              <ReservationFramework
                defaultValues={defaultValues}
                isShowForm
                isReserved={false}
                isHideRightNode={!uploadFile}
              >
                <div className={`${classes.dropZoneItem} ${uploadFile ? 'uploaded' : ''}`}>
                  <DropZoneCsv
                    uploadFile={uploadFile}
                    setUploadFile={setUploadFile}
                    setCsvData={setCsvData}
                    rootStyle={{ padding: 0 }}
                  />
                </div>
              </ReservationFramework>
              {(!isXsDown || !uploadFile) && <HorizontalLine style={{ marginTop: 24, marginBottom: 24 }} />}

              <CardFooter>
                <ButtonContainer
                  marginTopPcLayout={0}
                  buttons={[
                    <DefaultButton
                      key="buttons-csvAddPage-3"
                      title={'アップロード'}
                      color={disabled ? 'team_disabled' : 'team_main'}
                      type="submit"
                      disabled={disabled}
                      loading={processing}
                      style={{ minWidth: 190, height: 56, borderRadius: 28, opacity: disabled ? 1 : undefined }}
                    />,
                    <DefaultButton
                      key="buttons-csvAddPage-4"
                      title={'キャンセル'}
                      color={'team_white'}
                      onClick={() => route.push(Pages.TeamsMembers, { teamId })}
                      style={{ minWidth: 190, height: 56, borderRadius: 28, borderWidth: 2 }}
                    />,
                  ]}
                />
              </CardFooter>
            </>
          ) : (
            <ResultFramework>
              {uploadErrors ? (
                <ResultWithIcon
                  topText="アップロードエラー"
                  iconType="warning"
                  message={`CSVファイルの内容を再度確認してください。\n\nエラーが発生しました\n${uploadErrors.join(
                    '\n'
                  )}${uploaded > 0 ? `\n残りのデータは正常に登録されました（${uploaded}件）` : ''}`}
                  dateBox={uploaded ? dateBox : undefined}
                  button={{
                    label: '戻る',
                    onClick: handleBack,
                  }}
                />
              ) : (
                <ResultWithIcon
                  topText="アップロード完了"
                  iconType="success"
                  message={`データが正常に登録されました（${uploaded}件）`}
                  dateBox={dateBox}
                  button={{
                    label: 'メンバーリストに戻る',
                    onClick: handleToMembers,
                  }}
                />
              )}
            </ResultFramework>
          )}
        </Card>
      </form>
    </FormProvider>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    dropZoneItem: {
      width: '100%',

      '&.uploaded': {
        [theme.breakpoints.down('sm')]: {
          marginBottom: 24,
        },
      },
    },
  }),
  { name: 'TeamsMembersAddByCsvPage' }
)
