import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { LoadingCircular } from 'pages/teams/components'
import { TeamsHooksContext } from 'pages/teams/contexts/HooksContextTeams'

import { Container } from '../../components/Container'
import { useTeamKarte } from '../../hooks/teamKarte'
import { KarteResult } from '../diagnose/utils'


import { KarteResults } from './KarteResults'

import { constants } from 'assets'

type Props = RouteComponentProps<{ teamId: string; karteId: string }>

export const TeamsToolsKarteResultPage: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  const { teamId } = React.useContext(TeamsHooksContext)
  const { teamKarte, loaded } = useTeamKarte(props.match.params.karteId)

  const [results, setResults] = React.useState<KarteResult | undefined>(undefined)

  React.useEffect(() => {
    if (teamKarte?.data) {
      setResults(JSON.parse(teamKarte.data) as KarteResult)
    }
  }, [teamKarte])

  return (
    <>
      <Container>
        <div className={classes.main}>
          {loaded && results ? (
            <KarteResults results={results} teamId={teamId} />
          ) : (
            <>
              <LoadingCircular loading={true} />
            </>
          )}
        </div>
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: `calc(80vh - ${constants.FOOTER_MINI_HEIGHT}px)`,
    backgroundColor: '#F5F5F5',
  },
}))
