import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

interface Props {
  onClick?: () => void
}

export const MoreButton: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.root} onClick={props.onClick}>
      <div className={classes.button}>MORE</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },

  button: {
    padding: 16,
    fontSize: 14,
    fontWeight: 'bold',
    color: constants.COLOR_MAIN,
    cursor: 'pointer',
    textDecoration: 'underline',

    '&:hover': {
      opacity: 0.7,
    },
  },
}))
