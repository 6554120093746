import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { RouteComponentProps } from 'react-router'

import { HooksContext } from 'pages/teams/contexts'
import { BuildingHooksContext } from 'pages/teams/contexts/HooksContextBuilding'
import { useManageTeamBuilding, useManageTeamBuildingMember } from 'pages/teams/hooks'
import { queryGetMasters } from 'pages/teams/hooks/graphql'
import { TeamBuilding, MasterType, Master, TeamBuildingStatus } from 'utils/generated'

import {
  InputCard,
  QuestionContainer,
  Button,
  Waiting,
  FormLabel,
  SelectItems,
  ButtonContainer,
  QuestionPcLayout,
  Timer,
} from '../../components'
import { SelectItem } from '../../components/SelectItems'

type Props = { teamBuilding?: TeamBuilding } & RouteComponentProps<{ teamId: string }>

export const Q4: React.FC<Props> = (props) => {
  const classes = useStyles()

  const { isLeader, teamBuilding } = React.useContext(HooksContext)
  const { currentTeamBuildingMember, refreshTeamBuildingMember, teamBuildingMembers } =
    React.useContext(BuildingHooksContext)
  const { updateTeamBuilding } = useManageTeamBuilding()
  const { updateTeamBuildingMember } = useManageTeamBuildingMember()

  const [keywordItems, setKeywordItems] = React.useState<SelectItem[]>([])

  const getMasters = async () => {
    const response = await queryGetMasters({ type: MasterType.TeamBuildingQ4Keyword, limit: 100 })
    const items: Master[] = response?.items || []
    const initialItems = items.reduce((items: SelectItem[], i) => {
      if (i.title) {
        items.push({
          name: i.id,
          label: i.title,
          value: false,
        })
      }
      return items
    }, [])

    setKeywordItems(initialItems)
  }

  React.useEffect(() => {
    getMasters()
  }, [])

  const handleOnClickItem = (name: string, value: boolean) => {
    let count = 0
    if (value) {
      for (const i of keywordItems) {
        if (i.value) {
          count++
        }
      }
    }

    if (count < 3) {
      const updated = keywordItems.reduce((items: SelectItem[], i) => {
        if (i.name === name) {
          items.push({
            ...i,
            value,
          })
        } else {
          items.push(i)
        }
        return items
      }, [])
      setKeywordItems(updated)
    }
  }

  const onSubmit = async () => {
    const updated = await updateTeamBuildingMember({
      teamId: props.match.params.teamId,
      resultQ4: JSON.stringify(keywordItems.filter((i) => i.value)),
    })

    if (updated) {
      refreshTeamBuildingMember()
    }
  }

  const onNext = async () => {
    await updateTeamBuilding({
      id: props.match.params.teamId,
      status: TeamBuildingStatus.Q4Adjust,
      timeQ4Adjust: new Date().toISOString(),
    })
  }

  return (
    <>
      <QuestionContainer
        active={4}
        progressDotsProps={!currentTeamBuildingMember?.resultQ4 ? { maxLength: 3, currentProgress: 1 } : {}}
        questionHeadBar
        colorTitleText={!currentTeamBuildingMember?.resultQ4}
      >
        {!currentTeamBuildingMember?.resultQ4 ? (
          <>
            <div className={classes.cardContainer}>
              <InputCard noRadius>
                <QuestionPcLayout questionNumber={4} />
                <FormLabel>最大3つまで選択してください。</FormLabel>
                <SelectItems items={keywordItems} icon={'check'} onClick={handleOnClickItem} activateGrid />
              </InputCard>
            </div>

            {teamBuilding?.timeQ4 && <Timer from={teamBuilding.timeQ4} time={120} container />}

            <ButtonContainer
              fixed
              buttons={[
                <Button key="q4-1" onClick={onSubmit} disabled={!(keywordItems.filter((i) => i.value).length > 0)}>
                  次へ
                </Button>,
              ]}
            />
          </>
        ) : (
          <Waiting
            who={isLeader ? 'leader' : 'member'}
            progress={{
              child: teamBuildingMembers.filter((t) => !!t.resultQ4).length,
              parent: teamBuildingMembers.length,
            }}
            onNext={onNext}
            status={teamBuilding?.status}
          />
        )}
      </QuestionContainer>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  cardContainer: {
    width: '100%',
    marginBottom: 40,
  },
}))
