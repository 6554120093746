import * as React from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Select, MenuItem, Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import * as constants from 'assets/constants'

type Props = {
  name: string
  value: string
  onChange: () => void
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const FormTime: React.FC<Props & StyleProps> = ({ name, value, onChange, ownStyles }) => {
  const classes = useStyles({ ownStyles: ownStyles })

  const options = () => {
    const items = [] as string[]
    for (const hour of [...Array(24).keys()]) {
      items.push(`${hour}:00`)
    }
    return items
  }

  return (
    <div className={classes.selectBoxRoot}>
      <Select
        variant="standard"
        name={name}
        value={value}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          classes: { paper: classes.muiPaperRoot },
        }}
      >
        {options()?.map((item) => (
          <MenuItem key={`menuItem-${item}`} value={item} classes={{ root: classes.muiListItemRoot }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    selectBoxRoot: ({ ownStyles }: StyleProps) => ({
      //for init
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
      //
      // === for style ===
      //classes.selectBoxRoot
      width: '100%',
      height: 42,
      borderRadius: 8,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      overflow: 'hidden',
      ...ownStyles,
      [theme.breakpoints.down('sm')]: {
        height: 54,
      },

      '& .MuiInputBase-root': {
        width: '100%',
        height: '100%',
      },
      '& .MuiSelect-select': {
        width: '100%',
        height: 'calc(100% - 12px)',
        padding: '8px 8px 0 16px',
        color: constants.COLOR_TEAMBUILDING_TEXT,
        fontSize: 16,
        fontWeight: 400,

        '&:focus': {
          background: constants.COLOR_TEAMBUILDING_WHITE,
        },
        [theme.breakpoints.down('sm')]: {
          padding: '20px 16px 0',
          fontSize: 14,
          fontWeight: 300,
        },
      },
      '& .MuiSelect-icon': {
        right: 8,
        fill: constants.COLOR_TEAMBUILDING_PRIMARY,
      },
    }),

    //for popOverPosition
    muiPaperRoot: {
      maxHeight: 200,
      '& .MuiMenu-list': {
        padding: 0,
      },
    },
    //for menuItems
    muiListItemRoot: {
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontWeight: 400,
      '&.Mui-selected': {
        color: constants.COLOR_TEAMBUILDING_PRIMARY,
        background: constants.COLOR_TEAMBUILDING_WHITE,
      },
    },
  }),
  { name: 'FormTime' }
)
