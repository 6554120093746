import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { Content } from './generic/Content'
import { ContentHead } from './generic/ContentHead'
import { ContentModal } from './generic/ContentModal'
import { ContentSection } from './generic/ContentSection'
import { useReviewPoints, PointData } from './hooks/useReviewPoints'
import { Step2ConfirmContent } from './step2/Step2ConfirmContent'
import { Step2ConfirmModalContent } from './step2/Step2ConfirmModalContent'

type Props = {
  title: string
  subtitle: string
}

export const Step2Confirm: React.FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [modalPointData, setModalPointData] = React.useState<PointData | undefined>(undefined)
  const { goalPoints, codeOfConductPoints } = useReviewPoints()

  const onOpen = React.useCallback((data) => {
    setModalPointData(data)
    setModalOpen(true)
  }, [])
  const onModalCose = () => {
    setModalPointData(undefined)
    setModalOpen(false)
  }

  return (
    <div className={classes.root}>
      <ContentHead title={title} subtitle={subtitle} steps={3} currentStep={1} />
      <ContentSection title="チーム目標">
        <Content>
          <div style={{ marginBottom: 8, fontWeight: 'bold' }}>{goalPoints.label}</div>
          <Step2ConfirmContent onOpen={onOpen} pointData={goalPoints} />
        </Content>
      </ContentSection>
      <ContentSection title="行動規範">
        {codeOfConductPoints.map((data, i) => (
          <Content key={`code-of-conduct-${i}`}>
            <div style={{ marginBottom: 8, fontWeight: 'bold' }}>{data.label}</div>
            <Step2ConfirmContent onOpen={onOpen} pointData={data} />
          </Content>
        ))}
      </ContentSection>
      <ContentModal open={modalOpen} onClose={onModalCose}>
        <Step2ConfirmModalContent setModalOpen={setModalOpen} pointData={modalPointData} />
      </ContentModal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  modalWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modalSection: {
    display: 'flex',
    marginBottom: 16,
    gap: '16px',
    '@media (max-width: 632px)': {
      width: '100%',
      flexDirection: 'column',
    },
  },
  pointList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    fontSize: 12,
    height: 176,
    maxHeight: 176,
    overflow: 'auto',
    flex: 1,
    minWidth: 234,
    textAlign: 'center',
    padding: '16px',
    backgroundColor: '#F9F9F9',
    borderRadius: 8,
  },

  label: {
    color: '#00838C',
  },
  point: {
    color: '#00838C',
    fontWeight: 'bold',
  },
}))
