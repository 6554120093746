import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type Props = { style?: React.CSSProperties; children: React.ReactNode }

export const UsefulContainer: React.FC<Props> = ({ children, style }) => {
  const classes = useStyles({})

  return (
    <div className={classes.inputContainer} style={style}>
      {children}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    margin: '0 0 16px',
    padding: '24px 16px',
    width: '100%',
    background: constants.COLOR_WHITE,
    [theme.breakpoints.up('md')]: {
      padding: 40,
    },
  },
}))
