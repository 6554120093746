import * as React from 'react'

import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ArrowDownSvg from './assets/arrow-down.svg'
import ArrowUpSvg from './assets/arrow-up.svg'
import CheckSvg from './assets/check.svg'
import MemberChangeSvg from './assets/member-change.svg'
import * as constants from 'assets/constants'

type DropdownItem = {
  label: string
  value: string
}

type Props = {
  items: DropdownItem[]
  value?: string
  displayValue?: string
  keyPrefix: string
  iconList?: 'change'
  handleOnClick?: (arg: string) => void
  teamLabel?: string
  borderRadius?: number
  border?: string
  paperHeightMinus?: number
}

type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
}

export const Dropdown: React.FC<Props & StyleProps> = ({
  items,
  value,
  displayValue,
  keyPrefix,
  iconList,
  handleOnClick,
  teamLabel,
  borderRadius,
  border,
  paperHeightMinus,
  ownStyles,
}) => {
  const classes = useStyles({ ownStyles: ownStyles })

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [anchorWidth, setAnchorWidth] = React.useState(0)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  React.useEffect(() => {
    if (anchorRef.current) {
      setAnchorWidth(anchorRef.current.offsetWidth)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorRef.current?.offsetWidth])

  return (
    <>
      <div
        className={iconList ? classes.iconWrapper : classes.formWrapper}
        aria-label="dropdown-list"
        data-testid="dropdown-icon"
        ref={anchorRef}
        aria-controls={open ? 'dropdown-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          borderRadius: borderRadius ?? 4,
          border: border && `1px solid ${border}`,
        }}
      >
        {displayValue ? displayValue : ''}
        {iconList ? (
          <img src={MemberChangeSvg} alt={`list-icon-${iconList}`} />
        ) : (
          <>
            <div>{teamLabel ? teamLabel : ''}</div>
            <img src={open ? ArrowUpSvg : ArrowDownSvg} alt={open ? `arrow-up` : `arrow-down`} />
          </>
        )}
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        style={{ width: !iconList ? anchorWidth : undefined, marginTop: 2 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              className={classes.paper}
              elevation={3}
              classes={{ elevation3: classes.elevation3 }}
              style={{ maxHeight: `calc(${window.innerHeight}px - ${paperHeightMinus || 210}px)` }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {items.map((item, index) => (
                    <MenuItem
                      key={`${keyPrefix}-${index}`}
                      value={item.value}
                      onClick={(e) => {
                        if (handleOnClick) {
                          handleOnClick(item.value)
                          handleClose(e)
                        }
                      }}
                    >
                      <div className={classes.listCheck}>
                        {(!item.value && !value) || item.value === value ? (
                          <img className={classes.listHead} src={CheckSvg} alt="check-mark" />
                        ) : (
                          <></>
                        )}
                      </div>
                      <span
                        data-testid={item.label}
                        style={{
                          fontSize: 14,
                          color:
                            (!item.value && !value) || item.value === value
                              ? constants.COLOR_ONBOARDING_MAIN
                              : constants.TEXT_GRAY_DARK,
                        }}
                      >
                        {item.label}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: ({ ownStyles }: StyleProps) => ({
    width: 42,
    height: 42,
    borderRadius: '50%',
    cursor: 'pointer',
    ...ownStyles,
  }),
  formWrapper: ({ ownStyles }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 42,
    backgroundColor: constants.COLOR_WHITE,
    cursor: 'pointer',
    color: constants.TEXT_GRAY_DARK,
    fontSize: 14,
    paddingRight: 8,
    paddingLeft: 16,
    border: 'solid 1px #E9E9E9',
    ...ownStyles,
  }),

  paper: {
    borderRadius: 8,
    overflow: 'auto',
  },
  elevation3: {
    boxShadow: '0px 3px 3px -8px rgb(0 0 0 / 15%), 0px 3px 4px 0px rgb(0 0 0 / 10%), 0px 1px 8px 0px rgb(0 0 0 / 9%)',
  },
  listCheck: {
    display: 'inline-block',
    width: 21,
  },
  listHead: {
    position: 'relative',
    top: 1,
    width: 13,
    height: 13,
  },
}))
