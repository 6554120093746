import React, { useState } from 'react'
import { createRoot, Root } from 'react-dom/client'

import { Fade, Modal, Paper, Theme, useTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'

import { SubmitButton } from '../button-large'

import * as constants from 'assets/constants'
import { theme } from 'assets/theme'

type ScreenModalProps = {
  head?: string
  body?: string
  buttonText?: string
  onClickButton?: () => void
  altButtonText?: string
  onClickAltButton?: () => void
  outerText?: string
  root?: Root
}

// Modal 用の Root を宣言
let modalRoot: Root | null = null

export const callScreenModal = async (props: ScreenModalProps) => {
  await new Promise((r) => setTimeout(r, 100)) // unmount buffer
  const doc = document.getElementById('modal-tooltip-div')

  if (!doc) return

  if (!modalRoot) {
    modalRoot = createRoot(doc)
  }

  modalRoot.render(
    <ThemeProvider theme={theme}>
      <ScreenModal
        key={props.head}
        {...props}
        onClickButton={props.onClickButton}
        onClickAltButton={props.onClickAltButton}
        root={modalRoot}
      />
    </ThemeProvider>
  )
}

export const ScreenModal = ({
  head,
  body,
  buttonText,
  onClickButton,
  altButtonText,
  onClickAltButton,
  outerText,
  root,
}: ScreenModalProps) => {
  const classes = useStyles({ outerText })
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'))

  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleExited = () => {
    if (root) {
      root.unmount()
      modalRoot = null // modalRoot をリセット
    }
  }

  return (
    <Modal open={open} className={classes.modal} disableAutoFocus={true}>
      <>
        <Fade in={open} timeout={400} onExited={handleExited}>
          <Paper className={classes.modalPaper}>
            {head && <div className={classes.head}>{head}</div>}
            {body && <div className={classes.body}>{body}</div>}
            {altButtonText && (
              <>
                {altButtonText === 'スキップ' ? (
                  <div
                    className={classes.altButton}
                    onClick={() => {
                      onClickAltButton && onClickAltButton()
                      handleClose()
                    }}
                  >
                    {altButtonText}
                  </div>
                ) : (
                  <div
                    className={classes.altButtonOriginal}
                    onClick={() => {
                      onClickAltButton && onClickAltButton()
                      handleClose()
                    }}
                  >
                    {altButtonText}
                  </div>
                )}
              </>
            )}
            {outerText && (
              <div className={classes.outerText}>
                <img src={require('./assets/time.svg').default} alt="time" />
                <span>{outerText}</span>
              </div>
            )}
          </Paper>
        </Fade>
        <div className={classes.footer}>
          <SubmitButton
            SPwidth={isSmDown ? true : false}
            placeholder={buttonText || 'OK'}
            disabled={false}
            onPress={() => {
              onClickButton && onClickButton()
              handleClose()
            }}
          />
        </div>
      </>
    </Modal>
  )
}

const useStyles = makeStyles<Theme, ScreenModalProps>((theme: Theme) => ({
  wrapper: {
    opacity: 0.4,
    transition: 'all 3s ease-in',
    '&.appeared': {
      opacity: 1,
      backgroundColor: 'red',
    },
  },
  footer: {
    minWidth: '100%',
    position: 'absolute',
    bottom: '0',
    background: constants.COLOR_WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },

  head: {
    color: constants.COLOR_ONBOARDING_MAIN,
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '1em',
    marginTop: 8,
    marginBottom: 21,
    '& ~ button': {
      marginTop: 8,
    },
  },

  body: {
    fontSize: 14,
    width: '100%',
    lineHeight: '24px',
    marginTop: 3,
    marginBottom: 22,
  },

  altButton: {
    fontSize: 12,
    color: constants.COLOR_WHITE,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: ({ outerText }) => (outerText ? 80 : 28),
    cursor: 'pointer',
    position: 'absolute',
    minWidth: 88,

    left: '35%',
    border: 'solid 2px white',
    borderRadius: '8px',
    padding: 10,
  },
  altButtonOriginal: {
    fontSize: 12,
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    marginTop: 28,
    marginBottom: 10,
    cursor: 'pointer',
  },

  outerText: {
    color: '#fff',
    fontWeight: 'bold',
    position: 'absolute',
    fontSize: 14,
    bottom: -40,
    left: 0,

    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: 24,
      height: 24,
      display: 'block',
    },
    '& span': {
      display: 'block',
      marginLeft: 8,
    },
  },

  modalPaper: {
    position: 'relative',
    width: '100%',
    padding: 16,
    maxWidth: 350,
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
    '& button': {
      width: '100%',
    },
  },
}))
