import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { LogoWhiteText } from 'components/icon/LogoWhiteText'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

export const Hero: React.FC = () => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'sm')

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <LogoWhiteText width={isXsDown ? 100 : 142} height={isXsDown ? 16 : 18} />
      </div>
      <div className={classes.content}>
        <div className={classes.description}>
          <img
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/hero-description.svg`}
            alt="チームがなんとなくうまくいかないそんなとき、邪魔しているのはあのオバケかも..."
          />
        </div>
        <h1 className={classes.title}>
          <img src={`${process.env.PUBLIC_URL}/img/obakesagashi/hero-logo.svg`} alt="職場のオバケ探し" />
        </h1>
        <div className={classes.subTitle}>
          <img
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/hero-subtitle${isXsDown ? '-sp' : ''}.svg`}
            alt="推せる職場づくりの対話促進ツール"
          />
        </div>
        <div className={classes.contentImage}>
          <img
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/hero-workers${isXsDown ? '-sp' : ''}.png`}
            alt="職場の人々とオバケ"
          />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      paddingBottom: 120,
      backgroundImage: `
      url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-lozenge.png),
      url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-spotlight.png),
        linear-gradient(${constants.COLOR_NAVY}, ${constants.COLOR_NAVY})
      `,
      backgroundRepeat: 'repeat-x, no-repeat, repeat',
      backgroundPosition: 'center top, center top',
      backgroundSize: '1400px, cover',
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `
        url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-lozenge.png),
        url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-spotlight-sp.png),
          linear-gradient(${constants.COLOR_NAVY}, ${constants.COLOR_NAVY})
        `,
        backgroundPosition: 'center top, center 50px',
        backgroundSize: '1400px, contain',
        paddingBottom: 100,
      },
    },
    logo: {
      position: 'absolute',
      top: 32,
      left: 47,
      [theme.breakpoints.down('sm')]: {
        top: 16,
        left: 16,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 84,
      [theme.breakpoints.down('sm')]: {
        marginTop: 115,
      },
      width: '100%',
      '& img': {
        width: '100%',
      },
    },
    description: {
      marginRight: 20,
      marginLeft: 20,
      [theme.breakpoints.up('sm')]: {
        maxWidth: '60vw',
      },
    },
    title: {
      margin: '40px 20px 0',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '50vw',
      },
    },
    subTitle: {
      marginRight: 42,
      marginLeft: 42,
      [theme.breakpoints.up('sm')]: {
        maxWidth: '45vw',
      },
    },
    contentImage: {
      marginTop: 45,
      [theme.breakpoints.up('sm')]: {
        marginTop: 20,
        width: `calc(100vw * 1100 / 1400)`,
      },
    },
  }),
  { name: 'Hero' }
)
