import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ModalBuildingContext } from 'pages/teams/contexts'
import { TeamBuildingStatus } from 'utils/generated'

import { Button, ButtonContainer, AlertContainer, LinearProgressWaiting } from '.'

import { constants } from 'assets'

type Props = {
  who: 'leader' | 'member'
  progress?: Progress
  onNext?: () => void
  status?: TeamBuildingStatus
}

type Progress = {
  child: number
  parent: number
}

export const Waiting: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { progress } = props

  const { onModal, onClose } = React.useContext(ModalBuildingContext)

  const handleOnForceNext = () => {
    onModal(
      <AlertContainer>
        <>
          <div
            style={{
              fontFamily: 'Hiragino Kaku Gothic Pro',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '0.01em',
              lineHeight: '24px',
              textAlign: 'center',
              color: constants.COLOR_ONBOARDING_MAIN,
              marginBottom: 30,
              whiteSpace: 'pre-wrap',
            }}
          >
            未回答の人がいます。 {'\n'}本当に次に進んでもいいですか？
          </div>
          <Button
            full
            buttonType={'primary'}
            onClick={() => {
              if (props.onNext) {
                props.onNext()
                onClose()
              }
            }}
            style={{ marginBottom: 16 }}
          >
            次に進む
          </Button>

          <Button full buttonType={'light'} onClick={onClose}>
            キャンセル
          </Button>
        </>
      </AlertContainer>
    )
  }

  return (
    <div className={classes.root}>
      <div
        className={classes.textMargin}
        style={{
          textAlign: 'center',
          fontFamily: 'Hiragino Kaku Gothic Pro',
          fontSize: 14,
          fontWeight: 'bold',
          color: '#333',
        }}
      >
        全員が完了するまでお待ちください
      </div>
      <div
        className={classes.lgMarginBottom}
        style={{
          textAlign: 'center',
          height: 137,
        }}
      >
        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/waitingPeople.svg'} alt="" />
      </div>

      {progress && (
        <>
          <div className={classes.progressContainer}>
            <div
              style={{
                backgroundColor: constants.COLOR_ONBOARDING_MAIN,
                width: `${(progress.child / progress.parent) * 100}%`,
                height: '100%',
              }}
            />
          </div>
          <div
            style={{
              fontFamily: 'Hiragino Kaku Gothic Pro',
              fontSize: 14,
              lineHeight: '24px',
              color: constants.COLOR_ONBOARDING_MAIN,
              textAlign: 'center',
              marginBottom: 80,
            }}
          >
            {progress.child}/{progress.parent}人完了
          </div>

          {props.who === 'leader' ? (
            <ButtonContainer
              fixed
              buttons={[
                <Button key={'waiting-1'} disabled={progress.child !== progress.parent} onClick={props.onNext}>
                  次へ
                </Button>,
                <Button
                  key={'waiting-2'}
                  buttonType={'light'}
                  disabled={progress.child === progress.parent}
                  onClick={handleOnForceNext}
                >
                  全員の回答を待たずに先に進む
                </Button>,
              ]}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },

  progressContainer: {
    backgroundColor: constants.COLOR_WHITE,
    width: 350,
    height: 8,
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 18,
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },

  textMargin: {
    margin: '44px 0 42px',
  },
  lgMarginBottom: {
    marginBottom: 40,
  },
  forceNextButton: {},
}))

type WaitingForLeaderProps = {
  fullWidth?: boolean
  fixed?: boolean
  status?: TeamBuildingStatus
  wrapperBottom?: number
}

export const WaitingForLeader: React.FC<WaitingForLeaderProps> = (props) => {
  const classes = useWaitingForLeaderStyles({
    fullWidth: props.fullWidth,
    fixed: props.fixed,
    wrapperBottom: props.wrapperBottom,
  })

  return (
    <div className={classes.root}>
      <div
        style={{
          textAlign: 'center',
          marginBottom: 40,
          height: 22,
        }}
      >
        <div className={classes.waitBox}>
          <p className={classes.wait}>PLEASE WAIT</p>
          <LinearProgressWaiting />
        </div>
      </div>
      <div
        style={{
          fontFamily: 'Hiragino Kaku Gothic Pro',
          fontWeight: 'bold',
          fontSize: 12,
          textAlign: 'center',
          color: constants.COLOR_GRAY_DARK,
          whiteSpace: 'pre-wrap',
        }}
      >
        {(() => {
          switch (props.status) {
            case TeamBuildingStatus.Q1:
            case TeamBuildingStatus.Q2:
            case TeamBuildingStatus.Q3Vote2:
            case TeamBuildingStatus.Q4:
              return 'みんなの投稿を待っています\n'
            case TeamBuildingStatus.Q1Select:
            case TeamBuildingStatus.Q2Select:
              return '発表の準備をしています\n'
            default:
              return ''
          }
        })()}
        リーダーの進行をお待ち下さい
      </div>
    </div>
  )
}

type WaitingForLeaderStyleProps = {
  fullWidth?: boolean
  fixed?: boolean
  wrapperBottom?: number
}

const useWaitingForLeaderStyles = makeStyles(
  (theme: Theme) => ({
    root: ({ fullWidth, fixed, wrapperBottom = 40 }: WaitingForLeaderStyleProps) => ({
      width: fullWidth ? '100vw' : 'auto',
      left: fixed ? 0 : undefined,
      bottom: fixed ? 100 : undefined,
      position: fixed ? 'fixed' : undefined,
      margin: `24px 0 ${wrapperBottom}px`,
    }),
    waitBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    wait: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      margin: 0,
      fontSize: 16,
      fontWeight: 'bold',
      fontFamily: 'Helvetica, "Helvetica Neue", Arial, Verdana, sans-serif',
      letterSpacing: '0.1em',
    },
  }),
  { name: 'Waiting' }
)
