import React from 'react'

import makeStyles from '@mui/styles/makeStyles';

import ProfileIconSmall from 'pages/onboarding/components/header/assets/ProfileIconSmall.svg'
import { TeamBuildingMember } from 'utils/generated'

import { constants } from 'assets'

type Props = {
  isComposing: boolean
  filterText: string
  teamBuildingMembers: TeamBuildingMember[]
}

export const IdealTeamsList: React.FC<Props> = ({ filterText, isComposing, teamBuildingMembers }) => {
  const classes = useStyles()

  // メンバーのリストを名前順に並び替える処理
  const sortedTeamBuildingMembers = React.useMemo(() => {
    // teamBuildingMembersのコピーを作成した上で、sortを実行
    return [...teamBuildingMembers].sort((a, b) => {
      const nameA = a.teamMember?.fullName ?? ''
      const nameB = b.teamMember?.fullName ?? ''
      return nameA.localeCompare(nameB)
    })
  }, [teamBuildingMembers])

  const filteredTeamBuildingMembers = React.useMemo(() => {
    if (isComposing) {
      return sortedTeamBuildingMembers
    } else {
      return sortedTeamBuildingMembers.filter((member) =>
        member.teamMember?.fullName.toLowerCase().includes(filterText.toLowerCase())
      )
    }
  }, [filterText, isComposing, sortedTeamBuildingMembers])

  return (
    <div className={classes.root}>
      {filteredTeamBuildingMembers.map((teamBuildingMember) => (
        <IdealTeamsPerUser key={teamBuildingMember.id} teamBuildingMember={teamBuildingMember} />
      ))}
    </div>
  )
}

const IdealTeamsPerUser: React.FC<{ teamBuildingMember: TeamBuildingMember }> = ({ teamBuildingMember }) => {
  const classes = useStyles()
  const resultQ2 = teamBuildingMember?.resultQ2
    ? (JSON.parse(teamBuildingMember.resultQ2) as { label: string; name: string }[])
    : undefined

  return (
    <div className={classes.idealTeamsWrapper}>
      <div className={`${classes.memberName} ${classes.idealTeamsItem}`}>
        <img src={teamBuildingMember?.user?.imageUrl ?? ProfileIconSmall} alt="" />
        <span>{teamBuildingMember.teamMember?.fullName}</span>
      </div>
      <ul className={`${classes.idealsWrapper} ${classes.idealTeamsItem}`}>
        {resultQ2?.map((answer) => {
          return <li key={answer.label}>・{answer.label}</li>
        })}
      </ul>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', // 3点リーダー表示を考慮して、最小幅を250pxにする
      gridGap: 16,
    },

    // IdealTeamsPerUser Component
    idealTeamsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    idealTeamsItem: {
      padding: 8,
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
    },
    memberName: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,

      '& img': {
        width: 24,
        height: 24,
        borderRadius: '50%',
      },

      '& span': {
        fontSize: 12,
        fontWeight: 'bold',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    idealsWrapper: {
      margin: 0, // remove default margin
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_100,
      listStyle: 'none',

      '& li': {
        paddingBottom: 8,
        fontSize: 10,
      },
      '& li:last-child': {
        paddingBottom: 0,
      },
    },
  }),
  { name: 'IdealTeamsList' }
)
