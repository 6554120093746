import React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns'

import { constants } from 'assets'

type Props = {
  answeredAt: string | undefined
}

export const DisplayId: React.FC<Props> = ({ answeredAt }) => {
  const classes = useStyles()
  const currentUrl = window.location.href
  const urlParams = useParams<{ id: string }>()
  const [buttonLabel, setButtonLabel] = React.useState<'URLをコピー' | 'コピーしました'>('URLをコピー')

  const answeredAtDate = new Date(answeredAt ?? '')
  // 閲覧可能期間が30日なので、回答日時から29日後の日付を取得
  const expirationDate = answeredAtDate.setDate(answeredAtDate.getDate() + 29)
  const expirationDateTime = format(expirationDate, 'yyyy年MM月dd日 23:59')

  const onCopy = async () => {
    // iOSの仕様上、localhostかhttpで実行した場合、エラーが発生する
    await navigator.clipboard.writeText(currentUrl)
    setButtonLabel('コピーしました')
  }

  // onClickでコピーした際に、ボタンのラベルを元に戻す
  React.useEffect(() => {
    if (buttonLabel === 'コピーしました') {
      const timer = setTimeout(() => {
        setButtonLabel('URLをコピー')
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [buttonLabel])

  return (
    <div className={classes.root}>
      <div className={classes.headingBox}>
        <h2 className={classes.heading}>
          <span className="start-sentence">あなたのオバケ探しIDは、</span>
          <span className="id">{urlParams.id}</span>
          <span className="end-sentence">です。</span>
        </h2>
      </div>

      <div className={classes.body}>
        <div className={classes.deadline}>
          診断IDの有効期限：
          <br />
          {expirationDateTime}まで
        </div>
        <p className={classes.description}>
          診断日から30日間は診断ページTOPから、診断IDを使って、結果を閲覧することができます。
          <br />
          または、以下のURLにアクセスすることでも閲覧できます
        </p>
        <div className={classes.urlContainer}>
          <div className={classes.url}>{currentUrl}</div>
          <button type="button" disabled={buttonLabel === 'コピーしました'} onClick={() => onCopy()}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-start',
      },
    },
    heading: {
      margin: 0,
      width: '100%',
      color: '#000',
      fontWeight: 'bold',
      fontSize: 40,
      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },
      '& .start-sentence': {
        display: 'block',
        fontFamily: '"MOBO SemiBold"',
        lineHeight: '48px',
        [theme.breakpoints.up('md')]: {
          letterSpacing: 4,
        },
        [theme.breakpoints.down('md')]: {
          lineHeight: '32px',
        },
      },
      '& .id': {
        color: constants.COLOR_NAVY,
        [theme.breakpoints.up('md')]: {
          letterSpacing: 4,
        },
        [theme.breakpoints.down('md')]: {
          paddingTop: 3,
          fontWeight: 500,
          lineHeight: 1,
        },
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
      '& .end-sentence': {
        fontSize: 20,
        [theme.breakpoints.up('sm')]: {
          paddingLeft: 4,
          letterSpacing: 2,
        },
        [theme.breakpoints.down('md')]: {
          fontSize: 16,
          fontWeight: 500,
          lineHeight: 1,
        },
      },
    },
    body: {
      width: '100%',
      maxWidth: 739,
      color: constants.TEXT_GRAY_DARK,
    },
    deadline: {
      marginTop: 36,
      padding: '8px 16px',
      fontSize: 16,
      letterSpacing: 1.6,
      background: '#F0F0F0',
      border: `1px solid ${constants.COLOR_GRAY}`,
      borderRadius: 80,
      [theme.breakpoints.up('md')]: {
        height: 40,
        '& br': {
          display: 'none',
        },
      },
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        placeItems: 'center',
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
      },
      [theme.breakpoints.down('sm')]: {
        padding: '20px 16px',
      },
    },
    description: {
      margin: '16px 0 0',
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 1.5,
      [theme.breakpoints.down('md')]: {
        margin: '24px 0 0',
      },
    },
    urlContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 8,
      marginTop: 16,
      padding: '4px 4px 4px 18px',
      borderRadius: 80,
      [theme.breakpoints.up('sm')]: {
        background: '#F0F0F0',
      },
      [theme.breakpoints.down('md')]: {
        padding: '4px 4px 4px 18px',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        rowGap: 16,
        marginTop: 24,
        padding: 0,
      },
      '& button': {
        width: 137,
        flexShrink: 0,
        padding: '4px 8px',
        cursor: 'pointer',
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 80,
        border: '1px solid var(--gray400, #E9E9E9)',
        background: '#1C2858',
        letterSpacing: 0.8,
        [theme.breakpoints.up('sm')]: {
          alignSelf: 'stretch',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: 350,
          height: 42,
          borderRadius: 80,
        },
      },
    },
    url: {
      fontSize: 16,
      width: '100%',
      overflowX: 'auto',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      letterSpacing: 1.5,
      [theme.breakpoints.down('sm')]: {
        padding: '18px 16px',
        background: '#F0F0F0',
        borderRadius: 80,
      },
    },
  }),
  { name: 'DisplayId' }
)
