import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  title?: string
  split?: boolean
  separate?: boolean
  children: React.ReactNode
}
export const ContentSection: React.FC<Props> = ({ title, split, separate, children }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.root}${separate ? ' separate' : ''}`}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={`${classes.contents}${split ? ' split' : ''}`}>{children}</div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '&.separate': {
      borderTop: `1px solid ${constants.BORDER_GRAY}`,
      paddingTop: 16,
    },
    '& + &': {
      marginTop: 16,
    },
  },
  title: {
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#00838C',
  },
  contents: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    width: '100%',
    maxWidth: 600,
    '@media (max-width: 632px)': {
      flexDirection: 'column',
    },
    '&.split': {
      '&>*': {
        width: 'calc(50% - 8px)',
        maxWidth: 'calc(50% - 8px)',
        '@media (max-width: 632px)': {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },
  },
}))
