import React from 'react'

import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import makeStyles from '@mui/styles/makeStyles';
import { Control, Controller, FieldError, RegisterOptions } from 'react-hook-form'

import { teamConstants } from 'pages/teams/assets/constants'

import { FormError } from './FormError'

import { constants } from 'assets'

interface Props {
  label?: string
  htmlFor?: string
  name: string
  control?: Control
  readOnly?: boolean
  hasCheckMark?: boolean
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  error?: FieldError
  defaultValue?: string | null
  value?: string
  optional?: string

  rootStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties

  endAdornment?: React.ReactNode
  onChange?: (...event: any[]) => void
}

interface StyleProps {
  marginBottom?: number
}

export const DefaultInputWithLabel: React.FC<Props & StyleProps> = (props) => {
  const classes = useStyles(props)
  const {
    label,
    htmlFor,
    name,
    control,
    rules,
    error,
    defaultValue,
    value,
    readOnly,
    hasCheckMark,
    rootStyle,
    inputStyle,
    endAdornment,
    optional,
  } = props

  return (
    <div className={classes.root} style={rootStyle}>
      {label ? <InputLabel htmlFor={htmlFor}>{label}</InputLabel> : <></>}
      {optional ? <span className={classes.optionLabel}>{optional}</span> : <></>}
      {control ? (
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue ?? ''}
          render={({ onChange, name, value }) => (
            <>
              <Input
                id={htmlFor}
                className={`${classes.input} ${readOnly ? classes.inputReadOnly : ''} ${
                  hasCheckMark ? classes.hasCheckMark : ''
                }`}
                style={inputStyle}
                name={name}
                readOnly={readOnly}
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  if (props.onChange) {
                    props.onChange(e)
                  }
                }}
                error={error ? true : false}
              />
              {error ? (
                <div className={classes.errorContainer}>
                  <FormError error={error} />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        />
      ) : (
        <Input
          className={`${classes.input} ${readOnly ? classes.inputReadOnly : ''} ${
            hasCheckMark ? classes.hasCheckMark : ''
          }`}
          style={inputStyle}
          name={name}
          readOnly={readOnly}
          defaultValue={defaultValue}
          value={value}
          endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : <></>}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles(
  {
    root: {
      marginBottom: (props: StyleProps) => props.marginBottom ?? 34,
    },

    labelContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    },

    input: {
      //for init

      '& .MuiInputBase-input': {
        cursor: 'default',
      },
      '&.MuiInput-underline:before': {
        content: 'none',
      },
      '&.MuiInput-underline:after': {
        content: 'none',
      },
      //
      // === for style ===
      // input
      width: '100%',
      height: 49,
      borderRadius: 8,
      paddingLeft: 16,
      color: constants.COLOR_TEAMBUILDING_TEXT,
      fontSize: 14,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,

      '&.Mui-focused': {
        borderColor: (props: Props) => (props.readOnly ? 'none' : constants.COLOR_MAIN),
      },
    },

    inputReadOnly: {
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_300,
      cursor: 'default',
      '& .MuiInputBase-input': {
        cursor: 'default',
        width: 'auto',
        minWidth: '100%',
        marginRight: '-150px',
      },
    },
    hasCheckMark: {
      // only overWrite inputReadOnly CSS
      '& .MuiInputBase-input': {
        minWidth: 200,
      },
    },

    errorContainer: {
      marginTop: 4,
    },

    optionLabel: {
      fontSize: 9,
      fontWeight: 600,
      borderRadius: 4,
      padding: '2px 4px',
      backgroundColor: constants.COLOR_TEAMBUILDING_NEUTRAL_500,
      color: constants.COLOR_WHITE,
      verticalAlign: 2,
    },
  },
  { name: 'DefaultInputWithLabel' }
)

interface InputLabelProps {
  children: React.ReactNode
  htmlFor?: string
}

export const InputLabel: React.FC<InputLabelProps> = (props) => {
  const classes = useInputLabelStyles()

  return (
    <div className={classes.labelWrap}>
      <label htmlFor={props?.htmlFor} className={classes.label}>
        {props.children}
      </label>
    </div>
  )
}

const useInputLabelStyles = makeStyles(
  {
    labelWrap: {
      display: 'inline-block',
      paddingRight: 8,
    },
    label: {
      display: 'inline-block',
      ...teamConstants.inputLabelStyles,
    },
  },

  { name: 'InputLabel' }
)
