import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { Breadcrumb } from 'components/Breadcrumb'
import { TitleRenewal } from 'components/TitleRenewal'
import * as constTop from 'pages/landing/components/topPageModules/data/constTop'

import * as constants from '../../assets/constants'
import { links } from '../../components/casePageData/static'

import { CaseCard } from './components/CaseCard'

type Props = WithStyles<typeof useStyles> & RouteComponentProps<{ id: string }>

const Index: React.FC<Props> = (props: Props) => {
  const { classes } = props

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.back}>
      <Breadcrumb items={[{ link: constants.PAGE_TOP, name: 'トップ' }]} endItem="導入事例" />
      <TitleRenewal category="Case" title="導入事例" description={<>Cocolaboの導入事例をご紹介します。</>} />
      <div>
        <div className={classes.casePageTitle}>ー　企業導入事例</div>
      </div>
      <div className={classes.items}>
        {links.map((item, i) => (
          <div key={`case-list-company-${i}`}>
            <CaseCard imgURL={item.image} title={item.title} name={item.user} link={item.link} isOpenNewTab={true} />
          </div>
        ))}
      </div>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    back: {
      padding: '8px 7% 100px',
      backgroundColor: constants.COLOR_WHITE,
      width: '100%',
      fontFamily: constants.FONT_FAMILY_TOP_TEXT,
      [theme.breakpoints.up(constTop.breakPointsPC + 48)]: {
        padding: `0 calc((100% - 1072px)/2) 140px`,
      },
    },
    topContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& h5': {
        color: constants.COLOR_MAIN_NEW,
        fontFamily: constants.FONT_FAMILY_TOP_TITLE,
        fontSize: 14,
        fontWeight: 'normal',
        margin: '82px 0 0',
        [theme.breakpoints.down('sm')]: {
          margin: '26px 0 0',
        },
      },
      '& h1': {
        fontSize: 32,
        fontWeight: 'bold',
        margin: '25px 0 0',
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
      '& p': {
        margin: '30px 0 0',
        textAlign: 'center',
        lineHeight: 1.8,
        [theme.breakpoints.down('sm')]: {
          margin: '20px 0 0',
        },
      },
    },
    casePageTitle: {
      margin: '0 0 48px',
      padding: '80px 0 0',
      fontSize: 20,
      fontWeight: 'bold',
      color: constants.COLOR_MAIN_NEW,
    },
    items: {
      display: 'grid',
      gap: '24px 30px',
      width: '100%',
      margin: '0',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    },
  })

export default withStyles(useStyles)(Index)
