import * as React from 'react'

import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import { mergeClasses } from 'pages/onboarding/utils/styles'

interface IOwnProps {
  className?: string
  color?: string
}

type Props = IOwnProps & WithStyles<typeof useStyles>

const Index: React.FC<Props> = ({ classes, className, color }) => {
  const localColor = color || '#000'
  return (
    <div className={mergeClasses([classes.container, className])}>
      <svg xmlns="http://www.w3.org/2000/svg" width="6.211" height="10.235" viewBox="0 0 6.211 10.235">
        <path
          d="M-2.941-4.9a.563.563,0,0,0,0,.8L1.614.453a.563.563,0,0,0,.8,0l.531-.531a.562.562,0,0,0,0-.795L-.668-4.5l3.61-3.627a.563.563,0,0,0,0-.795L2.41-9.453a.563.563,0,0,0-.8,0Z"
          transform="translate(3.106 9.617)"
          fill={localColor}
        />
      </svg>
    </div>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    container: {},
  })

export default withStyles(useStyles)(Index)
