import React from 'react'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { SocialsShare } from '../../components/result/socials/socialsShare/Index'
import { useSocials } from '../../components/result/socials/useSocials'

import { COLOR_MAIN } from 'assets/constants'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      ref={ref}
      {...props}
      sx={{ '& .MuiAlert-action': { alignItems: 'center' } }}
    />
  )
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    backgroundColor: COLOR_MAIN,
    padding: '2px 16px 0',
  },
}))

export const SocialsSnackbar = () => {
  const classes = useStyles()
  const socials = useSocials()
  const [open, setOpen] = React.useState(true)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <Snackbar open={open} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert className={classes.alert} onClose={handleClose}>
          この内容をSNSでシェアしよう
          <div>
            <SocialsShare presenter={socials} />
          </div>
        </Alert>
      </Snackbar>
    </div>
  )
}
