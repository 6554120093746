import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box, TextField, Avatar, Modal, Select, MenuItem, Switch } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Storage } from 'aws-amplify'
import classNames from 'classnames'

import cautionIcon from 'pages/onboarding/assets/cautionIcon.svg'
import NoImage from 'pages/onboarding/assets/noImage.svg'
import { FormLabelMark, ButtonContainer } from 'pages/onboarding/components'
import { FormCharCount } from 'pages/onboarding/components/formCharCount'
import { useTeamMember } from 'pages/onboarding/hooks/team'
import { useTeamManage } from 'pages/onboarding/hooks/teamManage'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMember } from 'utils/generated'
import { OnboardingTeamMemberRole } from 'utils/generated'

import * as constants from '../../../../../assets/constants'
import { OnbPages } from '../../../../../assets/pages'
import { DownloadDrop } from '../../../components/file/DownloadDrop'
import { useFiles } from '../../../utils/files'
import { OnbContext } from '../../PagesRoot'
import { TutorialProps } from '../../tutorial/Tutorial'
import Button from '../components/Button'
import { DropZone } from '../components/DropZone'
import { Target } from '../components/Target'

import ImageCropper from './ImageCropper'

import arrowIcon from '../assets/arrow.svg'
import closeIcon from '../assets/closeIcon.svg'
import EditImage from '../assets/image-edit.svg'
import EditedImage from '../assets/image-edited.svg'

const inputNames = { nickname: 'nickname', position: 'position', description: 'description', target: 'target' }
const maxLengths = { nickname: 50, position: 50, description: 10000 }

const hourList: number[] = Array.from(Array(24), (v, k) => k)
const minuteList: number[] = Array.from(Array(60), (v, k) => k)

const initFormState = (memberInfo: OnboardingTeamMember) => ({
  imageUrl: memberInfo.imageUrl,
  nickname: memberInfo.nickname || '',
  position: memberInfo.position || '',
  description: memberInfo.description || '',
  target: memberInfo.target || '',
  targetFileNames: memberInfo.targetFileNames || [],
  notificationSettings: { ...memberInfo.notificationSettings },
  emailNotifyHour: memberInfo.emailNotifyHour ? `${memberInfo.emailNotifyHour}` : '8',
  emailNotifyMinute: memberInfo.emailNotifyMinute ? `${memberInfo.emailNotifyMinute}` : '0',
})

export const MemberEditBloc = {
  useAdapter: () => {
    const { teamId, teamMember } = useContext(OnbContext)
    const { updateTeamMember, updateTeamMemberWithoutRefresh } = useTeamManage()
    const { userId: targetUserId } = useParams<{ userId: string }>()
    const { teamMember: targetMemberInfo } = useTeamMember(teamId, targetUserId)
    const { file } = useFiles(
      targetMemberInfo?.targetFileNames ? targetMemberInfo?.targetFileNames[0] : undefined,
      teamId,
      targetMemberInfo?.userId
    )
    return {
      teamId,
      teamMember,
      updateTeamMember,
      updateTeamMemberWithoutRefresh,
      file,
    }
  },
  uploadThumbnail: async (file: File, teamMemberId: string) => {
    const extension = file.name?.split('.').pop()
    const key = `onboarding-team-member/${teamMemberId}-${new Date().toISOString()}.${extension}`
    await Storage.put(key, file, {
      contentType: file.type,
      level: 'public',
    })
    const [bucketName, region] = [process.env.REACT_APP_AWS_S3_BUCKET_UPLOAD, process.env.REACT_APP_AWS_REGION]
    return `https://${bucketName}.s3.${region}.amazonaws.com/public/${key}`
  },
  validate: (formState: ReturnType<typeof initFormState>) => {
    return Object.entries(maxLengths).every(([k, v]) => {
      const field = k as keyof typeof maxLengths
      return formState[field] !== '' && formState[field].length < v
    })
  },
  executeUpload: async (file: File | undefined, teamId: string, userId: string) => {
    if (!file) return
    //helpers -->
    const uploadTeamFile = async (file: File, teamId: string, userId: string) => {
      const res = await Storage.put(
        `/${teamId}/${userId}/${file.name}`, // s3 key
        file,
        {
          contentType: file.type.includes('image') ? 'application/octet-stream' : file.type,
          level: 'public',
          customPrefix: { public: 'onboarding-team-files' },
        }
      )
      return res
    }
    // <-- helpers

    const res = (await uploadTeamFile(file, teamId, userId)) as { key: string }
    return res.key.split('/').pop() // store these fileNames on Dynamo.
  },
}

type SubStyleProps = {
  tutorial?: TutorialProps
}

export const Index: React.FC<{ tutorial?: TutorialProps }> = ({ tutorial }) => {
  // deps
  const { teamId, teamMember, updateTeamMember, updateTeamMemberWithoutRefresh, file } = MemberEditBloc.useAdapter()

  const [formState, setFormState] = useState(initFormState(teamMember))
  const [formErr, setFormErr] = useState<React.ReactNode | null>(null)
  const validToSubmit = MemberEditBloc.validate(formState)
  //handleErrMsg
  useEffect(() => {
    if (validToSubmit) {
      setFormErr(null)
    } else {
      setFormErr(
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: constants.COLOR_ONBOARDING_ALERT,
            fontSize: 14,
            margin: '0 0 8px',
          }}
        >
          <img src={cautionIcon} alt={'cautionIcon'} style={{ display: 'inline-block', margin: '0 4px 0 0 ' }} />
          必須項目を入力してください。
        </div>
      )
    }
  }, [formState, validToSubmit])

  const [uploadFile, setUploadFile] = useState<File>()
  const [isDeleteFile, SetIsDeleteFile] = useState(true)

  const [formImageUpdating, setFormImageUpdating] = useState<boolean>(false)

  useEffect(() => {
    setFormImageUpdating(false)
  }, [formState])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.currentTarget.name]: e.currentTarget.value })
  }

  const onChangeSelect = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (e.target.name) {
      setFormState({ ...formState, [e.target.name]: `${e.target.value}` })
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)
  const onChangeThumbnail = () => {
    if (inputRef.current === null) {
      return
    }
    inputRef.current.click()
  }

  const previewCanvasRef = useRef(null)
  const [src, setSrc] = useState('')
  const [open, setOpen] = useState(false)

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files !== null && e.target.files[0] ? e.target.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        setSrc(reader.result?.toString() || '')
        setOpen(true)
      })
      reader.readAsDataURL(file)
    }
  }

  const onCroppedFile = useCallback(
    async (file?: File) => {
      setFormImageUpdating(true)
      if (teamMember && file) {
        const imageUrl = await MemberEditBloc.uploadThumbnail(file, teamMember.id)
        setFormState({ ...formState, imageUrl })
      } else {
        console.info('no file in member-edit')
      }
    },
    [teamMember, formState]
  )

  const onChangeEmailNotification = () => {
    setFormState({
      ...formState,
      notificationSettings: {
        ...formState.notificationSettings,
        action: {
          notice: false,
          email: !formState.notificationSettings.action?.email,
        },
      },
    })
  }

  const history = useOnbHistory()
  const gotoMypage = () => {
    history.push(OnbPages.Mypage, { teamId })
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // for File upload
    const willUploadFileName = await MemberEditBloc.executeUpload(uploadFile, teamId, teamMember.userId)
    const targetFileNames = willUploadFileName ? [willUploadFileName] : isDeleteFile ? undefined : ['']
    const payload = {
      ...formState,
      teamId,
      targetFileNames,
      notificationSettings: formState.notificationSettings,
      emailNotifyHour: parseInt(formState.emailNotifyHour, 0),
      emailNotifyMinute: parseInt(formState.emailNotifyMinute, 0),
    }
    if (tutorial) {
      updateTeamMemberWithoutRefresh(payload)
      tutorial.next && tutorial.next()
      setUploadFile(undefined)
      return
    }
    updateTeamMember(payload)
    setUploadFile(undefined)
    gotoMypage()
  }

  const classes = useStyles({ tutorial: tutorial })
  const title1 = '目標'
  const message1 = '半年後や1年後の自分のあるべき姿・ありたい姿を上司やOJTトレーナーと相談し記入しましょう。'
  const title2 = '添付ファイル'
  const message2 =
    'PPTやExcelなどで目標を作成している方はこちらでデータ添付を行うことで誰でも閲覧できる状態となります。'
  const [isOpen, setIsOpen] = useState(false)
  const toolTip = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <>
      {teamMember ? (
        <>
          {tutorial ? (
            <>
              <Box className={classes.titleTutorial}>プロフィールを入力しよう</Box>
              <div className={classes.textTutorial}>
                下記必須項目の入力とオンボーディングのお知らせをメールで受け取る場合の設定を行ってください。
                メール通知については後にプロフィールより設定し直しすることができます。
              </div>
            </>
          ) : (
            <>
              <span>
                <Box className={classes.cancel} onClick={gotoMypage}>
                  {!tutorial ? (
                    <div className={classes.iconArrowSP}>
                      <img src={arrowIcon} alt="arrow" />
                      <span className={classes.iconLeft}>戻る</span>
                    </div>
                  ) : (
                    ''
                  )}
                </Box>
                <Box className={classes.title}>{'プロフィールを編集'}</Box>
              </span>
            </>
          )}
          <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <Box className={classes.container}>
              <Box className={classes.avatar}>
                <Avatar alt="avatar" src={formState.imageUrl || NoImage} style={{ width: 60, height: 60 }} />
                <Box
                  className={classes.iconContainer}
                  onClick={onChangeThumbnail}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {formState.imageUrl ? (
                    <img src={EditedImage} alt="editImage" />
                  ) : (
                    <img src={EditImage} alt="editedImage" />
                  )}
                  <input hidden ref={inputRef} type="file" accept="image/*" onChange={onFileInputChange} />
                </Box>
                <p
                  className="__txt"
                  onClick={onChangeThumbnail}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {formImageUpdating ? (
                    <>ファイルをアップロード中…</>
                  ) : (
                    <>
                      プロフィール写真を
                      {formState.imageUrl ? <>変更</> : <>登録</>}
                    </>
                  )}
                </p>
              </Box>
            </Box>
            {/*
             *
             * cropArea
             *
             */}
            <Modal
              open={open}
              className={classes.modal}
              BackdropProps={{ style: { backgroundColor: 'rgba(51,51,51, 0.7)' } }}
              onClose={() => setOpen(false)}
            >
              <>
                <div className={classes.modalInner}>
                  <img src={closeIcon} alt={closeIcon} className={classes.closeIcon} onClick={() => setOpen(false)} />
                  <ImageCropper
                    open={open}
                    setOpen={setOpen}
                    aspect={{ width: 60, height: 60 }}
                    src={src}
                    previewCanvasRef={previewCanvasRef}
                    onCroppedFile={onCroppedFile}
                    onChangeThumbnail={onChangeThumbnail}
                  />

                  <canvas hidden className={classes.imageCanvas} ref={previewCanvasRef} />
                </div>
                <div className={classes.hiddenCropArea}>
                  <img className={classes.hiddenImgArea} src={src} alt="画像" />
                </div>
              </>
            </Modal>
            {/*
             *
             * cropArea
             *
             */}
            <Box className={classNames([classes.container, classes.separator])}>
              <Box className={classes.root}>
                <Grid>
                  <div className={classes.textLabelContainer}>
                    <span className={classes.textLabel}>名前</span>
                    <FormLabelMark markType={'required'} ownStyles={{ margin: '0 0 0 8px' }} />
                  </div>
                  <Grid>
                    <TextField
                      variant="standard"
                      name={inputNames.nickname}
                      placeholder="田中新一"
                      inputProps={{ 'aria-label': 'name' }}
                      fullWidth={true}
                      className={classes.input}
                      value={formState.nickname}
                      onChange={onChange}
                    />
                  </Grid>
                  <FormCharCount length={formState.nickname.length} maxLength={maxLengths.nickname} />
                </Grid>
              </Box>
              <Box className={classes.root}>
                <Grid>
                  <div className={classes.textLabelContainer}>
                    <span className={classes.textLabel}>役職/部署など肩書</span>
                    <FormLabelMark markType={'required'} ownStyles={{ margin: '0 0 0 8px' }} />
                  </div>
                  <Grid>
                    <TextField
                      variant="standard"
                      name={inputNames.position}
                      placeholder="新入社員、Cocolabo事業部/リーダー"
                      inputProps={{ 'aria-label': 'position' }}
                      fullWidth={true}
                      className={classes.input}
                      value={formState.position}
                      onChange={onChange}
                    />
                  </Grid>
                  <FormCharCount length={formState.position.length} maxLength={maxLengths.position} />
                </Grid>
              </Box>
              <Box className={classes.root}>
                <Grid>
                  <div className={classes.textLabelContainer}>
                    <span className={classes.textLabel}>自己紹介</span>
                    <FormLabelMark markType={'required'} ownStyles={{ margin: '0 0 0 8px' }} />
                  </div>
                  <Grid>
                    <TextField
                      variant="standard"
                      name={inputNames.description}
                      placeholder="Cocolabo事業部でリーダーをしています。社歴は10年で主に〇〇を中心に仕事をしてきました。比較的〇〇の分野は詳しいので、わからないことがあればいつでも聞いて下さい。
                      出身は山口で、東京には就職のタイミングで来ました。趣味はサーフィン、スキー、釣りなど山も海も川も好きです。"
                      inputProps={{ 'aria-label': 'description' }}
                      fullWidth={true}
                      multiline
                      minRows={10}
                      className={classes.textarea}
                      value={formState.description}
                      onChange={onChange}
                    />
                  </Grid>
                  <FormCharCount length={formState.description.length} maxLength={maxLengths.description} />
                </Grid>
              </Box>
            </Box>
            <Box className={classes.descriptionExample}>
              自己紹介の例：
              <br />
              ・社歴やご自身の仕事の得意分野
              <br />
              ・出身や趣味、今ハマっていること
              <br />
              ・みなさんへの一言など
            </Box>
            {teamMember.role === OnboardingTeamMemberRole.Member && (
              <>
                <Box className={classNames([classes.container, classes.separator])}>
                  <Box className={classes.root}>
                    <Grid container className="__container">
                      <Grid size={{ xs: 4 }}>
                        <span className={classes.textLabel}>
                          アクション期限連絡
                          <br />
                          メール通知機能
                        </span>
                      </Grid>
                      <Grid size={{ xs: 8 }}>
                        <Switch
                          className={classNames([classes.setRight, classes.checkbox])}
                          checked={!!formState.notificationSettings.action?.email}
                          onChange={onChangeEmailNotification}
                          name={'emailNotifyMypage'}
                          disableRipple={true}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className={classes.root}>
                    <Grid container className="__container">
                      <Grid size={{ xs: 4 }}>
                        <span className={classes.textLabel}>
                          通知時間 <span style={{ fontSize: 10 }}>※変更不可</span>
                        </span>
                      </Grid>
                      <Grid size={{ xs: 8 }}>
                        <Box className={classes.setRight}>
                          <Box className="__select">
                            <Select
                              variant="standard"
                              // className={classes.select}
                              value={formState.emailNotifyHour ?? 10}
                              name="emailNotifyHour"
                              onChange={onChangeSelect}
                              readOnly
                            >
                              {hourList.map((_item, index) => (
                                <MenuItem key={index} value={index}>
                                  {index.toString().padStart(2, '0')}
                                </MenuItem>
                              ))}
                            </Select>
                            :
                            <Select
                              variant="standard"
                              // className={classes.select}
                              value={formState.emailNotifyMinute}
                              name="emailNotifyMinute"
                              onChange={onChangeSelect}
                              readOnly
                            >
                              {minuteList.map((_item, index) => (
                                <MenuItem key={index} value={index}>
                                  {index.toString().padStart(2, '0')}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className={classes.container}>
                  <Box className={classes.list}>
                    <Target
                      toolTip={toolTip}
                      isOpen={isOpen}
                      titleFirst={title1}
                      messageFirst={message1}
                      titleSecond={title2}
                      messageSecond={message2}
                      optionalIcon
                      target={formState.target}
                      name={inputNames.target}
                      onChange={onChange}
                    />
                    {file && isDeleteFile
                      ? teamMember.role === OnboardingTeamMemberRole.Member && (
                          <div className={classes.DownloadDropRoot}>
                            <DownloadDrop uploadedFile={file} isEditPage SetIsDeleteFile={SetIsDeleteFile} />
                          </div>
                        )
                      : teamMember.role === OnboardingTeamMemberRole.Member && (
                          <div className={classes.root}>
                            <DropZone uploadFile={uploadFile} setUploadFile={setUploadFile} />
                          </div>
                        )}
                  </Box>
                </Box>
              </>
            )}
            <Box className={classNames([classes.buttonBox, classes.separator, classes.bottomBtnWrapper])}>
              <ButtonContainer
                errMsg={formErr}
                buttonWidth={350}
                button={
                  <Button
                    disabled={!validToSubmit}
                    bgColor={validToSubmit ? constants.COLOR_WHITE : constants.COLOR_ONBOARDING_WHITE}
                    textColor={validToSubmit ? constants.COLOR_ONBOARDING_MAIN : constants.COLOR_ONBOARDING_TEXT_GRAY}
                    borderColor={validToSubmit ? constants.COLOR_ONBOARDING_MAIN : constants.COLOR_GRAY}
                    square={true}
                    fontSize={16}
                    fullWidth={true}
                    body={'保存する'}
                    noShadow={true}
                    height={50}
                    submit
                  />
                }
              />
            </Box>
          </form>
        </>
      ) : null}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  DownloadDropRoot: {
    marginTop: 16,
  },
  iconArrow: {
    display: 'flex',
  },
  iconArrowSP: {
    display: 'flex',
    left: 20,
    top: 40,
    position: 'relative',
  },

  bottomBtnWrapper: {
    position: 'static',
  },

  container: {
    boxSizing: 'border-box',
    padding: '27px 16px',
    backgroundColor: constants.COLOR_WHITE,
  },

  profileWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },

  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    padding: 24,
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
  },

  titleTutorial: {
    textAlign: 'center',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    fontSize: 16,
    padding: 24,
    backgroundColor: constants.COLOR_ONBOARDING_WHITE,
  },

  textTutorial: {
    padding: '0 16px 24px',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    backgroundColor: constants.COLOR_WHITE,
    margin: '0 0 100px 0',
  },

  setRight: {
    float: 'right',
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    fontWeight: 'bold',
  },

  root: {
    paddingTop: '27px',
    backgroundColor: constants.COLOR_WHITE,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '24px',
    },
    '&:first-child': {
      paddingTop: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
    },
  },

  buttonBox: {
    backgroundColor: '#F9F9F9',
    marginBottom: 38,
    [theme.breakpoints.down('lg')]: {
      // padding: '32px 48px',
    },
  },

  textLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 16px',
  },

  textLabel: {
    color: constants.TEXT_GRAY_DARK,
    fontWeight: 'bold',
    fontSize: '14px',
    textAlign: 'center',
  },

  iconLeft: {
    marginLeft: 8,
  },

  gridLabel: {
    marginBottom: 10,
  },

  avatar: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: 'auto',
    '& .__txt': {
      marginLeft: 16,
      fontWeight: 'bold',
      color: constants.COLOR_ONBOARDING_MAIN,
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    outline: 'none',
  },
  modalInner: {
    padding: '48px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: 5,
    maxWidth: 300,
    position: 'relative',
  },
  closeIcon: {
    display: 'block',
    position: 'absolute',
    top: 16,
    right: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageCanvas: {
    objectFit: 'cover',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
  },
  hiddenCropArea: {
    position: 'absolute',
    bottom: 10,
    left: 10,
    maxWidth: 270,
    maxHeight: 500,
  },
  hiddenImgArea: {
    width: '100%',
    display: 'none',
  },

  separator: ({ tutorial }: SubStyleProps) => ({
    borderTop: '1px solid #F5F5F5',
    marginTop: tutorial ? 1 : 15,
  }),

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: constants.COLOR_ONBOARDING_MAIN,
    width: 24,
    height: 24,
    borderRadius: 50,
    position: 'absolute',
    left: 34,
    bottom: 0,
  },

  description: {
    width: '100%',
    fontSize: '16px',
    resize: 'none',
    padding: '16px',
    boxSizing: 'border-box',
    border: 'solid 1px#ADADAD',
    borderRadius: '4px',

    [theme.breakpoints.down('lg')]: {
      height: '170px',
    },
  },

  descriptionExample: {
    boxSizing: 'border-box',
    padding: '23px 24px',
    color: constants.COLOR_GRAY_DARK,
    backgroundColor: constants.COLOR_WHITE,
  },

  cancel: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: constants.COLOR_ONBOARDING_GRAY_DARK,
    cursor: 'pointer',
    marginRight: 15,
  },

  input: {
    fontSize: 14,
    padding: 16,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 5,
    '& .MuiInputBase-input': {
      padding: 0,
      fontSize: 14,
    },
    '& .MuiInput-underline::before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },

  textarea: {
    padding: 16,
    fontSize: 14,
    border: `1px solid ${constants.COLOR_ONBOARDING_GRAY_LIGHT}`,
    borderRadius: 5,
    '& .MuiInput-underline:before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
    '& .MuiInputBase-multiline': {
      padding: 0,
      fontSize: 14,
    },
    '& .MuiInputBase-inputMultiline': {
      lineHeight: 1.6,
    },
  },
  checkbox: {
    width: 82,
    height: 36,
    padding: 0,
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      borderRadius: 27,
      backgroundColor: constants.COLOR_ONBOARDING_GRAY,
    },
    '& .MuiSwitch-colorSecondary': {
      color: constants.COLOR_WHITE,
    },
    '& .MuiSwitch-colorPrimary.Mui-checked': {
      color: '#fff',
    },
    '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: constants.COLOR_ONBOARDING_MAIN,
    },
    '& .MuiSwitch-thumb': {
      width: 30,
      height: 30,
      boxShadow: 'inherit',
    },
    '& .MuiSwitch-switchBase': {
      top: -6,
      left: -6,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(46px)',
    },
  },

  notificationEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: constants.COLOR_ONBOARDING_MAIN,
    alignItems: 'center',
    cursor: 'pointer',
    '& .__txt': {
      marginLeft: 16,
      fontWeight: 'bold',
    },
  },
}))

export default Index
