import React, { useContext } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ResearchContext } from '../../contexts/research'
import { Font, UsefulCard } from '../atoms/index'

export const GhostCausesAndRemedies: React.FC = () => {
  const theme = useTheme()
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'))
  const { researches1, researches2 } = useContext(ResearchContext)

  const itemLists = [
    { title: 'オバケが発生したと思われる原因', researches: researches1 },
    { title: '今後の対処方法', researches: researches2 },
  ]

  return (
    <>
      {itemLists.map(({ title, researches }) => {
        return (
          <div key={title} style={{ marginTop: 24 }}>
            <UsefulCard backgroundColor="white" borderRadius={8} ownStyles={{ padding: isMdUp ? 24 : 16 }}>
              <Font fontSize={14} bold>
                {title}
              </Font>
              {researches &&
                researches.map((researche, index) => (
                  <React.Fragment key={`reseach_1_${index}`}>
                    <UsefulCard
                      backgroundColor="lightGray2"
                      noShadow
                      ownStyles={{ marginTop: 14, padding: 16, whiteSpace: 'pre-wrap' }}
                    >
                      {researche.report}
                    </UsefulCard>
                  </React.Fragment>
                ))}
            </UsefulCard>
          </div>
        )
      })}
    </>
  )
}
