import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

import * as constants from 'assets/constants'

type Props = {
  ownStyles?: React.CSSProperties
  onChange: (val: string) => void
  text: string
  placeholder: string
}

export const FormBox: React.FC<Props> = ({ onChange, ownStyles, text, placeholder }) => {
  const classes = useStyles({})

  return (
    <textarea
      maxLength={500}
      className={classes.textArea}
      style={ownStyles}
      value={text}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder || `個人の目標を重視するあまり…`}
    ></textarea>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  textArea: {
    marginTop: 5,
    padding: 16,
    border: `1px solid ${constGhost.COLOR_LIGHTGRAY3}`,
    color: constGhost.COLOR_BLACK3,
    fontSize: 14,
    width: '100%',
    display: 'block',
    borderRadius: 4,
    resize: 'none',
    fontFamily: constants.ONBOARDING_FONT_FAMILY,
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: constGhost.COLOR_GRAYTEXT,
    },
  },
}))
