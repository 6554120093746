import * as React from 'react'

import LinearProgress from '@mui/material/LinearProgress'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'

export const LinearProgressWaiting: React.FC = () => {
  const classes = useStyles()

  return <LinearProgress className={classes.linearProgress} color="primary" />
}

const useStyles = makeStyles((theme: Theme) => ({
  linearProgress: {
    width: 128,
    '&.MuiLinearProgress-root': {
      height: 2,
    },
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: constGhost.COLOR_MAIN_NEW + '80',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: constGhost.COLOR_MAIN_NEW,
    },
  },
}))
