import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import * as constTop from 'pages/landing/components/topPageModules/data/constTop'
import { TeamConnection } from 'utils/generated'

import * as constants from 'assets/constants'
import { replacePathParams } from 'assets/history'
import { Pages, KARTE_LANDING, PAGE_SETUP_LANDING } from 'assets/pages'

interface Props {
  label: string
  type: 'login' | 'signUp' | 'toDashboard' | 'toTeamStart' | 'contact'
  teams: TeamConnection | undefined
  isMoveAnimation: boolean
  ownStyles?: React.CSSProperties
  className?: string
}

export const TopButton: React.FC<Props> = ({ label, type, isMoveAnimation, ownStyles, className, teams }) => {
  const classes = useStyles({})
  const location = useLocation()

  const typeOf = (login: string, signUp: string, toDashboard: string, toTeamStart: string, contact: string): string => {
    switch (type) {
      case 'login':
        return login
      case 'signUp':
        return signUp
      case 'toDashboard':
        return toDashboard
      case 'toTeamStart':
        return toTeamStart
      case 'contact':
        return contact
    }
  }

  const links = () => {
    if (teams && teams?.items?.length) {
      return typeOf(
        constants.PAGE_SIGNIN,
        `${constants.PAGE_SIGNUP}?su_flg=personal`,
        replacePathParams(Pages.TeamsDashboard, { teamId: teams.items[0].id }),
        Pages.TeamsStart,
        constants.PAGE_CONTACT
      )
    } else {
      return typeOf(
        location.pathname === KARTE_LANDING || location.pathname === PAGE_SETUP_LANDING
          ? `${constants.PAGE_SIGNIN}?r=${location.pathname}`
          : constants.PAGE_SIGNIN,
        `${constants.PAGE_SIGNUP}?su_flg=personal`,
        '/',
        Pages.TeamsStart,
        constants.PAGE_CONTACT
      )
    }
  }

  return (
    <>
      <Link
        to={links()}
        target={typeOf('_blank', '', '_blank', '_blank', '')}
        rel={typeOf('noopener noreferrer', '', 'noopener noreferrer', 'noopener noreferrer', '')}
      >
        <Button
          variant="contained"
          style={{
            ...ownStyles,
          }}
          className={classNames([
            typeOf(
              classes.whiteButton,
              classes.orangeButton,
              classes.orangeButton,
              classes.orangeButton,
              classes.orangeButton
            ),
            isMoveAnimation && 'hoverAnimation',
            className,
          ])}
        >
          <div className={classes.buttonBody}>{label}</div>
        </Button>
      </Link>
    </>
  )
}

const useStyles = makeStyles(
  {
    buttonBody: {
      height: 'auto',
    },
    orangeButton: {
      backgroundColor: constTop.COLOR_BUTTONORANGE,
      color: constants.COLOR_WHITE,
      border: 'none',
      width: 200,
      height: 50,
      fontSize: 14,
      fontWeight: 'bold',
      boxShadow: 'none',
      borderRadius: 25,
      '&:hover': {
        backgroundColor: constTop.COLOR_WHITE,
        border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
        color: constTop.COLOR_BUTTONORANGE,
        cursor: 'pointer',
        boxShadow: 'none',
        '&.hoverAnimation': {
          '&:hover': {
            animation: '$signUpButtonAnime 300ms cubic-bezier(0,0.5,0.5,1) 0s 1 normal forwards running',
            '@global': {
              '@keyframes signUpButtonAnime': {
                '0%': {
                  backgroundColor: constTop.COLOR_BUTTONORANGE,
                  color: constants.COLOR_WHITE,
                  border: 'none',
                },
                '100%': {
                  backgroundColor: constTop.COLOR_WHITE,
                  border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
                  color: constTop.COLOR_BUTTONORANGE,
                },
              },
            },
          },
        },
      },
    },
    whiteButton: {
      boxShadow: 'none',
      width: 200,
      height: 50,
      borderRadius: 25,
      fontSize: 14,
      fontWeight: 'bold',
      border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
      background: constTop.COLOR_WHITE,
      color: constTop.COLOR_BUTTONORANGE,
      '&:hover': {
        cursor: 'pointer',
        border: `2px solid ${constTop.COLOR_WHITE}`,
        background: constTop.COLOR_BUTTONORANGE,
        color: constTop.COLOR_WHITE,
        boxShadow: 'none',
        '&.hoverAnimation': {
          '&:hover': {
            background: constants.COLOR_WHITE,
            animation: '$signInButtonAnime 300ms cubic-bezier(0,0.5,0.5,1) 0s 1 normal forwards running',
            '@global': {
              '@keyframes signInButtonAnime': {
                '0%': {
                  border: `2px solid ${constTop.COLOR_BUTTONORANGE}`,
                  background: constTop.COLOR_WHITE,
                  color: constTop.COLOR_BUTTONORANGE,
                },
                '100%': {
                  border: `2px solid ${constTop.COLOR_WHITE}`,
                  background: constTop.COLOR_BUTTONORANGE,
                  color: constTop.COLOR_WHITE,
                },
              },
            },
          },
        },
      },
    },
  },
  { name: 'TopButton' }
)
