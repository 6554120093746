import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { IuseTitleProps } from './useTitle'

// -----------------------------
// Styles
// -----------------------------
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: 'none',
    width: '100%',
    fontSize: '24px',
    fontWeight: 800,
    resize: 'none',
    boxSizing: 'border-box',

    '&:focus': {
      border: 'none',
      outline: 'none',
    },

    '&::placeholder': {
      color: '#E6E6E6',
    },
  },
}))

// -----------------------------
// Props
// -----------------------------
export type IProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string
  className?: string
  presenter: IuseTitleProps
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

// -----------------------------
// Props
// -----------------------------
export const Title = React.forwardRef<HTMLTextAreaElement | null, IProps>(({ ...props }, ref) => {
  const classes = useStyles()

  React.useEffect(() => {
    if (props.value) {
      props.presenter.onUpdateSetTitle(props.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  return (
    <textarea
      {...props}
      ref={ref}
      name={props.name}
      value={props.presenter.getTitle()}
      placeholder={props.presenter.getPlaceholder()}
      className={`${props.className} ${classes.root}`}
      onKeyDown={props.presenter.onPreventEnter}
      onChange={(e) => {
        props.onChange(e)
        props.presenter.onTitleEnter(e)
      }}
      maxLength={props.presenter.getMaxTitleLen(50)}
    />
  )
})
