import React, { MutableRefObject, useContext, useState } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { ButtonIcon } from 'pages/onboarding/assets/button-icon'
import { SearchedNothing } from 'pages/onboarding/components'
import { Dropdown } from 'pages/onboarding/components/dropdown-new'
import { Loader } from 'pages/onboarding/components/loader'
import { SearchBar } from 'pages/onboarding/components/search-bar/alt'
import { TitleWithBack } from 'pages/onboarding/components/title-with-back'
import { useOnboardingActionManageList } from 'pages/onboarding/hooks/action'
import { useTeams } from 'pages/onboarding/hooks/team'
import { useOnbHistory } from 'pages/onboarding/navigation/route'
import { OnboardingTeamMemberRole, OnboardingActionCategory, OnboardingTeam } from 'utils/generated'

import * as constants from '../../../../assets/constants'
import { ActionCard } from '../../components/cards/ActionCard'
import { categories } from '../../feature/action/domain'
import { useCognito } from '../../hooks/cognito'
import { OnbContext } from '../PagesRoot'
import { useTutorial } from '../tutorial/logics/hooks'
import { TutorialProps } from '../tutorial/Tutorial'

import { OnbPages } from 'assets/pages'

export const ActionListManageBloc = {
  useAdapter: () => {
    const { teamId, setTeamId } = useContext(OnbContext)
    const { teams: teamsOfAdminRole } = useTeams(OnboardingTeamMemberRole.Admin)
    const { teams: teamsOfSupporterRole } = useTeams(OnboardingTeamMemberRole.Supporter)
    const { actions } = useOnboardingActionManageList(teamId)

    const teams: OnboardingTeam[] = [...teamsOfAdminRole, ...teamsOfSupporterRole]
    const currentTeam = teams.find((t) => t.id === teamId)

    const [cognito] = useCognito()
    const userId = cognito ? cognito.getUsername() : undefined

    return {
      teamId,
      setTeamId,
      teams,
      actions,
      userId,
      currentTeam,
    }
  },
}

export const Index: React.FC<{ tutorial?: TutorialProps }> = (props) => {
  // deps
  const { ttRef } = useTutorial(props.tutorial)
  const { teamId, setTeamId, teams, actions, userId, currentTeam } = ActionListManageBloc.useAdapter()

  const history = useOnbHistory()

  const [category, setCategory] = useState<OnboardingActionCategory | undefined>()
  const [searchQuery, setQuery] = useState('')
  const classes = useStyles()

  const searchedActions = actions
    .filter((itm) => (category ? itm.category === category : true))
    .filter((itm) => itm.mission.includes(searchQuery))

  const newSearchedActions = searchedActions.sort((a, b) => {
    if (a.period === b.period) return 0
    return a.period < b.period ? -1 : 1
  })
  if (!teamId || !actions || !userId) return <Loader />
  return (
    <>
      <TitleWithBack
        title="アクション管理"
        omitBack={true}
        styleRightItem={{ zIndex: 1100, backgroundColor: constants.COLOR_WHITE }}
      />
      <div className={classes.dropdownRoot}>
        <div className={classes.dropdownContainer}>
          <Dropdown
            items={teams.map((team) => ({ value: team.id, label: `${team.title}` }))}
            value={teamId}
            keyPrefix={'teams'}
            handleOnClick={(value) => setTeamId(value)}
            teamLabel={currentTeam?.title}
          />
        </div>

        <div className={classes.iconContainer}>
          <Dropdown
            items={categories.map((category) => ({ value: category.value, label: category.label }))}
            value={category}
            iconList={'change'}
            handleOnClick={(value) => {
              if (value === 'ALL') {
                setCategory(undefined)
              } else {
                setCategory(value as OnboardingActionCategory)
              }
            }}
            keyPrefix={'action-manage-categories'}
          />
        </div>

        <div className={classes.iconContainer} ref={ttRef as MutableRefObject<HTMLDivElement>}>
          <ButtonIcon
            isClass
            iconName="heart-plus"
            onPress={() => history.push(OnbPages.ActionManageAdd, { teamId })}
          />
        </div>
      </div>

      <div className={classes.dropdownRoot}>
        <SearchBar
          placeholder={'アクションを検索'}
          borderColor={constants.COLOR_ONBOARDING_GRAY_LIGHT}
          borderRadius={8}
          iconMarginLeft={16}
          iconMarginRight={16}
          setSearchQuery={(val) => setQuery(val)}
        />
      </div>

      <div className={classes.list}>
        {actions.length ? (
          <>
            {newSearchedActions.length ? (
              newSearchedActions.map((action) => (
                <ActionCard key={action.id} teamId={teamId} userId={userId} item={action} />
              ))
            ) : (
              <SearchedNothing />
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 16px 80px 16px',
  },
  buttonLabel: {
    fontSize: '12px',
    paddingLeft: '4px',
  },

  dropdownRoot: {
    display: 'flex',
    width: '100%',
    padding: '8px 16px',
  },

  dropdownContainer: {
    minWidth: '50%',
    flex: 1,
  },

  teams: {
    flex: 1,
  },

  persons: {
    flex: 1,
    marginLeft: 16,
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 30,
    minHeight: 30,
    marginLeft: 16,
  },
}))

export default Index
