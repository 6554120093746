import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import infomationIcon from '../assets/infomationIcon.svg'
import { constants } from 'assets/'

type Props = {
  resultTitle?: string
  resultDetails?: JSX.Element
  nextQuestion: string
}

export const PanelMessage: React.FC<Props> = ({ resultTitle, resultDetails, nextQuestion }) => {
  const classes = useStyles()

  return (
    <div className={classes.panelMessageRoot}>
      <div className={classes.doneText}>ワーク完了</div>
      <div className={classes.imageContainer}>
        <img src={process.env.PUBLIC_URL + '/assets/svg/teamBuilding/success.svg'} alt="checkedIcon" />
      </div>
      {resultTitle && resultDetails ? (
        <div className={classes.result}>
          <div className={classes.resultTitle}>{resultTitle}</div>
          <div className={classes.resultDetails}>{resultDetails}</div>
        </div>
      ) : (
        <></>
      )}
      <div className={classes.infomationBox}>
        <img src={infomationIcon} alt={'infomationIcon'} />
        <div className={classes.nextQuestion}>{nextQuestion}</div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    panelMessageRoot: {
      textAlign: 'center',
    },
    doneText: {
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      fontSize: 14,
      fontWeight: 600,
      margin: '75px 0 0',
      [theme.breakpoints.up('md')]: {
        margin: '12px 0 0',
      },
    },
    imageContainer: {
      textAlign: 'center',
      margin: '26px 0 40px',
      width: '100%',
    },
    result: {
      padding: '26px 16px',
      fontSize: '14px',
      background: constants.COLOR_WHITE,
      margin: '0 16px 24px',
      textAlign: 'start',
    },
    resultTitle: {
      fontWeight: 'bold',
      paddingBottom: 17,
      borderBottom: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
      color: constants.TEXT_GRAY_DARK,
    },
    resultDetails: {
      paddingTop: 19,
      fontWeight: 'bold',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
      lineHeight: 1.7,
    },
    infomationBox: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      background: constants.COLOR_WHITE,
      padding: 16,
      margin: '0 16px',
    },
    nextQuestion: {
      fontSize: '14px',
      textAlign: 'start',
      fontWeight: 'bold',
      color: constants.COLOR_TEAMBUILDING_PRIMARY,
    },
  }),
  { name: 'PanelMessage' }
)
