import React from 'react'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as constants from 'assets/constants'

type BatchProps = {
  count: number
  withWrapper?: boolean
}

export const NotificationBatch: React.FC<BatchProps> = ({ count, withWrapper }) => {
  const classes = useStyles()

  const [countFlg, setCountFlg] = React.useState<number>(count)

  React.useEffect(() => {
    setCountFlg(count)
  }, [count])

  return (
    <>
      {countFlg ? (
        <div className={withWrapper ? classes.wrapper : undefined}>
          <div className={classes.count}>
            <>{count > 99 ? '99+' : count}</>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export const NotificationMark: React.FC<BatchProps> = ({ count }) => {
  const classes = useStyles()

  const [countFlg, setCountFlg] = React.useState<number>(count)

  React.useEffect(() => {
    setCountFlg(count)
  }, [count])

  return <>{countFlg ? <div className={classes.mark} data-testid="unread"></div> : <></>}</>
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'right',
    flex: 1,
  },
  count: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: constants.COLOR_ONBOARDING_ALERT,
    fontSize: 10,
    color: constants.TEXT_WHITE,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mark: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: constants.COLOR_ONBOARDING_ALERT,
  },
}))
