import * as React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { NoStyleButton } from 'components/NoStyleButton'

import { constants } from 'assets'

type Props = {
  label: string
  onClick: () => void
  disabled?: boolean
}

export const NextStep: React.FC<Props> = ({ label, onClick, disabled }) => {
  const classes = useStyles()
  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }
  return (
    <div>
      <NoStyleButton className={classNames([classes.button, disabled && 'disabled'])} onClick={handleClick}>
        {label}
      </NoStyleButton>
    </div>
  )
}

const useStyles = makeStyles(
  () => ({
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      cursor: 'pointer',
      margin: '40px auto 0',
      padding: 16,
      backgroundColor: constants.COLOR_MAIN_NEW,
      color: '#fff',
      fontSize: 14,
      fontWeight: 'bold',
      width: '100%',
      maxWidth: 382,
      borderRadius: 8,
      border: `1px solid ${constants.COLOR_MAIN_NEW}`,
      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.8,
        },
      },
      '&.disabled': {
        pointerEvents: 'none',
        cursor: 'not-allowed',
        color: constants.TEXT_GRAY,
        border: `1px solid ${constants.COLOR_GRAY_LIGHT}`,
        backgroundColor: constants.COLOR_GRAY_LIGHT,
      },
    },
  }),
  { name: 'NextStep' }
)
