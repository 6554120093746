import React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Chart as ChartJS, ChartData, ChartOptions, registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Chart as ReactChart } from 'react-chartjs-2'

ChartJS.register(...[...registerables, ChartDataLabels])

type Props = {
  type: 'step2Confirm' | 'step2'
  data: number[]
}
const BAR_COLORS = ['#00C87A', '#009059', '#00838C', '#00636C']

export const getKeyFromValue = (num: number) => {
  if (num < 3) {
    return '0~2'
  }
  if (num < 6) {
    return '3~5'
  }
  if (num < 9) {
    return '6~8'
  }
  return '9~10'
}

export const Step2Chart: React.FC<Props> = ({ data, type }) => {
  const classes = useStyles()
  const formattedData = React.useMemo(
    () =>
      type === 'step2Confirm'
        ? data.reduce(
            (prev, val) => {
              const key = getKeyFromValue(val)
              if (prev[key]) {
                prev[key] = prev[key] + 1
              } else {
                prev[key] = 1
              }
              return prev
            },
            {
              '0~2': 0,
              '3~5': 0,
              '6~8': 0,
              '9~10': 0,
            }
          )
        : [],
    [data, type]
  )
  const labels = React.useMemo(() => (type === 'step2' ? data.map((_, i) => `${i + 1}回目`) : ['回答数']), [data, type])

  const currentData: ChartData = React.useMemo(
    () => ({
      labels,
      datasets:
        type === 'step2'
          ? [
              {
                label: '',
                data: data.map((v) => Math.round(v * 10) / 10),
                barThickness: 24,
                backgroundColor: '#00838C',
              },
            ]
          : Object.entries(formattedData).map(([key, val], i) => ({
              label: `${key}点`,
              data: [val],
              barThickness: 24,
              backgroundColor: BAR_COLORS[i] ?? '#00838C',
            })),
    }),
    [data, formattedData, labels, type]
  )
  const barOptions: ChartOptions = React.useMemo(
    () => ({
      indexAxis: type === 'step2Confirm' ? 'y' : 'x',
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20,
        },
      },
      plugins: {
        legend: {
          display: type === 'step2Confirm',
        },
        datalabels: {
          display: true,
          formatter: function (value) {
            return value > 0 ? (type === 'step2Confirm' ? `${value}人` : value) : ''
          },
          anchor: type === 'step2' ? 'end' : 'center',
          align: type === 'step2' ? 'end' : 'center',
          color: type === 'step2' ? '#00838C' : '#FFFFFF',
        },
        tooltip: { enabled: false },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          stacked: type === 'step2Confirm',
          ticks: {
            stepSize: 1,
          },
        },
        y:
          type === 'step2Confirm'
            ? {
                stacked: true,
              }
            : {
                min: 0,
                max: 10,
                ticks: {
                  color: '#868686',
                  callback: (value) => {
                    if (typeof value === 'number' && value % 10 === 0) {
                      return value
                    } else {
                      return ''
                    }
                  },
                },
                grid: {
                  color: '#2CBBC60A',
                  lineWidth: (ctx) => (ctx.tick.value % 10 === 0 ? 2 : 1),
                },
              },
      },
    }),
    [type]
  )

  const chartWidth = data.length < 2 ? data.length * 52 + 20 : data.length * 52

  return (
    <div className={classes.root}>
      <div className={classes.chartScroller} style={type === 'step2' ? { padding: '0 0 17px' } : {}}>
        <div className={classes.chartWrapper} style={{ width: type === 'step2' ? chartWidth : 'auto' }}>
          <ReactChart type="bar" data={currentData} options={barOptions} />
        </div>
      </div>
      <div className={classes.yAxis}>
        {type === 'step2' && (
          <>
            <p>10点</p>
            <p>0点</p>
          </>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
  },
  chartScroller: {
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  chartWrapper: {
    height: 160,
  },
  yAxis: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 0 26px',
    background: 'white',
    color: '#868686',
    height: '100%',
    textAlign: 'center',
    fontSize: 12,
  },
}))
