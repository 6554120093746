import React from 'react'

import { Modal as MaterialModal, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  open: boolean
  setOpen?(isOpen: boolean): void
  content?: React.ReactNode
  children?: React.ReactNode
}

export const BuildingModal: React.FC<Props> = ({ children, open = false, setOpen = () => undefined, content }) => {
  const classes = useStyles()

  return (
    <MaterialModal open={open} onClose={() => setOpen(false)}>
      <div className={classes.modalWrap}>
        <div className={classes.modalBody}>{content || children}</div>
      </div>
    </MaterialModal>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  modalWrap: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',
  },
  modalBody: {
    width: '100%',
    maxWidth: '350px',
    padding: '24px 16px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
}))
