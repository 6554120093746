import * as React from 'react'

import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { useCustomMediaQuery } from 'hooks/mediaQuery'

import { constants } from 'assets'

type Props = {
  resultGhostsCount: number
}

export const ResultHero: React.FC<Props> = ({ resultGhostsCount }) => {
  const classes = useStyles()
  const isXsDown = useCustomMediaQuery('down', 'sm')
  const isSmDown = useCustomMediaQuery('down', 'md')

  const isOserushokuba = resultGhostsCount === 0

  return (
    <section>
      <div className={classes.hero}>
        <h1 className={classes.title}>結果発表</h1>
        {isOserushokuba ? (
          <>
            <img className={classes.sparkle} src={`${process.env.PUBLIC_URL}/img/obakesagashi/sparkle.svg`} alt="" />
            <div className={classes.oseruBalloonWrapper}>
              <div className={classes.oseruBalloon}>
                あなたの職場は
                <br />
                「推せる職場」かも！
                <span className="triangle"></span>
              </div>
            </div>

            <p className={classes.oseruTitleMessage}>
              どうやらあなたの職場にはまだ、{isSmDown && <br />}オバケたちはでてきていないようですね。
              <br />
              オバケたちがいないということは、
              <br />
              あなたの職場は「推せる職場」を目指せるかもしれません！
            </p>
          </>
        ) : (
          <>
            <p className={classes.titleSub}>あなたがみつけたオバケの数</p>
            <div className={classes.fire}>
              <span className="num">
                {resultGhostsCount}
                <span>体</span>
              </span>
            </div>

            <p className={classes.titleMessage}>
              職場に出てくるオバケたちには、{isSmDown && <br />}悪さをする一面と、
              <br />
              ご利益をもたらしている一面があります。
              <br />
              {resultGhostsCount < 3 ? (
                <>あなたがみつけた{resultGhostsCount}体の詳しい情報を見てみましょう。</>
              ) : (
                <>
                  あなたがみつけた{resultGhostsCount}体のオバケのうち、
                  <br />
                  3体の詳しい情報を見てみましょう。
                </>
              )}
            </p>
          </>
        )}

        <div className={classes.crossingGhosts}>
          <img
            src={`${process.env.PUBLIC_URL}/img/obakesagashi/search-ghosts${isXsDown ? '-sp' : ''}.png`}
            alt="職場のオバケたち"
          />
        </div>
      </div>

      {isOserushokuba && (
        <div className={classes.oserushokubaBack}>
          <WidthControlWrapper>
            <div className={classes.oserushokuba}>
              <img
                className={classes.oseruLabImage}
                src={`${process.env.PUBLIC_URL}/img/obakesagashi/oseru-people.svg`}
                alt="推せる職場ラボ"
              />
              <div>
                <div className={classes.oseruTitle}>推せる職場とは？</div>

                <p className={classes.oseruMessage}>
                  私たちは、働きやすくて働きがいがある会社は、誰かにオススメしたくなるほど”推せる職場”
                  になることができると考えています。
                  <br />
                  <br />
                  オバケが0体だということは、あなたが日頃働いている職場は、とても働きやすくて、働きがいも感じられる職場ではないでしょうか？
                  <br />
                  <br />
                  働きやすくて、働きがいもあるんだけど、まだまだ推すほどではないな...と思ったそこのあなた。
                  <br />
                  「推せる職場のつくり方」を調査研究している推せる職場ラボでは、推せる職場を目指したい皆さまに向けた多くのコンテンツを公開中です。
                  <br />
                  <br />
                  ぜひ、職場の皆さんと覗いてみてください。
                </p>

                <div className={classes.oseruLinks}>
                  <a className="text" href="https://oserushokuba.jp" target="_blank" rel="noopener noreferrer">
                    https://oserushokuba.jp
                  </a>
                  <div className={classes.oseruSns}>
                    <a className="icon" href="https://x.com/oserushokuba" target="_blank" rel="noopener noreferrer">
                      <img src={`${process.env.PUBLIC_URL}/img/hataraku/icon-x.svg`} alt="X" />
                    </a>
                    <a
                      className="icon"
                      href="https://www.instagram.com/oserushokuba"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={`${process.env.PUBLIC_URL}/img/hataraku/icon-instagram.svg`} alt="instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </WidthControlWrapper>
        </div>
      )}
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    hero: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: `linear-gradient(${constants.COLOR_NAVY_DARK}, ${constants.COLOR_NAVY})`,
      mixBlendMode: 'multiply',
      animation: `$fadeIn 500ms forwards`,
      '@global': {
        '@keyframes fadeIn': {
          from: {
            opacity: '0',
          },
          to: {
            opacity: '1',
          },
        },
      },
      [theme.breakpoints.up('sm')]: {
        '&::before': {
          position: 'absolute',
          content: '""',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/obakesagashi/bg-ghost-town.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom 87px center',
          backgroundSize: 'contain',
        },
      },
    },
    title: {
      position: 'relative',
      display: 'inline-block',
      margin: '74px 0 0',
      color: constants.COLOR_YELLOW4,
      fontFamily: '"MOBO SemiBold"',
      fontSize: 40,
      fontWeight: 600,
      lineHeight: 1,
      [theme.breakpoints.down('md')]: {
        margin: '80px 0 0',
        fontSize: 32,
      },
      '&::before, &::after': {
        position: 'absolute',
        top: 18,
        content: '""',
        width: '33px',
        height: '1.5px',
        backgroundColor: constants.COLOR_YELLOW4,
        [theme.breakpoints.down('md')]: {
          top: 16,
        },
      },
      '&::before': {
        left: -38,
        transform: 'rotate(55deg)',
        [theme.breakpoints.down('md')]: {
          left: -54,
        },
      },
      '&::after': {
        left: 'calc(100% + 3px)',
        transform: 'rotate(-55deg)',
        [theme.breakpoints.down('md')]: {
          left: 'calc(100% + 19px)',
        },
      },
    },
    titleSub: {
      margin: '60px 0 0',
      textAlign: 'center',
      fontFamily: '"MOBO SemiBold"',
      fontSize: 30,
      fontStyle: 'normal',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        margin: '40px 0 0',
        fontSize: 24,
      },
    },
    sparkle: {
      marginTop: 90,
      width: 160,
      [theme.breakpoints.down('md')]: {
        marginTop: 120,
      },
    },
    oseruBalloonWrapper: {
      marginTop: 18,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        marginTop: 12,
        padding: '0 16px',
      },
    },
    oseruBalloon: {
      position: 'relative',
      display: 'grid',
      placeItems: 'center',
      width: 388,
      height: 129,
      borderRadius: 80,
      background: '#02BBC9',
      textAlign: 'center',
      fontSize: 34,
      fontWeight: 'bold',
      lineHeight: '46px',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 360,
        height: 82,
        fontSize: 24,
        lineHeight: '32px',
      },
      '& .triangle': {
        position: 'absolute',
        bottom: -20,
        left: 'calc(50% - 10px)',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '20px 11.5px 0 11.5px',
        borderColor: '#02BBC9 transparent transparent transparent',
        [theme.breakpoints.down('md')]: {
          bottom: -11,
          left: 'calc(50% - 5.5px)',
          borderWidth: '12px 7px 0 7px',
        },
      },
    },
    oseruTitleMessage: {
      margin: '72px 0 24px',
      fontSize: 16,
      lineHeight: 1.5,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        margin: '64px 16px 0',
      },
    },
    fire: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      flexShrink: 0,
      marginTop: 32,
      width: 140,
      height: 187,
      background: `no-repeat top / cover url(${process.env.PUBLIC_URL}/img/obakesagashi/pink-fire.svg)`,
      [theme.breakpoints.down('md')]: {
        marginTop: 36,
      },
      '& .num': {
        fontFamily: '"MOBO SemiBold"',
        fontSize: 64,
        fontWeight: 'bold',
        lineHeight: 1.2,
        paddingBottom: 28,
        '& span': {
          fontSize: 24,
          marginLeft: 3,
        },
      },
    },
    titleMessage: {
      margin: '44px 0 0',
      fontSize: 16,
      lineHeight: 1.5,
      textAlign: 'center',
    },
    crossingGhosts: {
      marginTop: 54,
      paddingBottom: 40,
      width: 917,
      zIndex: 2,
      [theme.breakpoints.down('sm')]: {
        marginTop: 46,
        width: '100%',
      },
      '& img': {
        width: '100%',
        verticalAlign: 'bottom',
      },
    },
    oserushokubaBack: {
      padding: '50px 0 88px',
      background: constants.COLOR_NAVY,
      [theme.breakpoints.down('md')]: {
        padding: '10px 0 75px',
      },
    },
    oserushokuba: {
      display: 'flex',
      columnGap: 51,
      background: '#fff',
      padding: '40px 40px 47px',
      borderRadius: 16,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 24,
        padding: '24px 16px 40px',
      },
    },
    oseruLabImage: {
      width: 300,
      [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
        width: 250,
      },
    },
    oseruTitle: {
      color: constants.COLOR_MAIN_NEW,
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1,
    },
    oseruMessage: {
      margin: '32px 0 0',
      color: '#000',
      fontSize: 16,
      lineHeight: 1.5,
      [theme.breakpoints.down('md')]: {
        margin: '16px 0 0',
      },
    },
    oseruLinks: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 20,
      marginTop: 32,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 16,
        marginTop: 24,
      },
      '& .text': {
        color: constants.COLOR_BLUE_DARK2,
        fontSize: 16,
        letterSpacing: 1.6,
        textDecoration: 'none',
        '@media (hover: hover)': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    oseruSns: {
      display: 'flex',
      columnGap: 16,
      '& .icon': {
        display: 'grid',
        placeItems: 'center',
        background: constants.COLOR_BLUE_DARK2,
        width: 40,
        height: 40,
        borderRadius: '50%',
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.8,
          },
        },
        '& img': {
          width: 23,
        },
      },
    },
  }),
  { name: 'ResultHero' }
)
