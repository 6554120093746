import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { GhostListWithCount } from 'pages/teams/components'

export const GhostList: React.FC = () => {
  const classes = useStyles()

  return (
    <div>
      <p className={classes.desc}>12体のオバケリストです。過去に見つけたオバケの数を閲覧できます。</p>

      <GhostListWithCount />
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    desc: {
      margin: 0,
      fontSize: 12,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
      },
    },
  }),
  { name: 'GhostList' }
)
