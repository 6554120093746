import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHandleTeamAnketStart } from 'services/api/anket'

import { NoStyleButton } from 'components/NoStyleButton'

import { HooksContext } from '../contexts'
import { useTeamBuilding } from '../hooks'
import { useStartIceBreak } from '../pages/_tools/iceBreak/hooks/iceBreak'

import { ReflectionButton } from './ContentHeader'
import { useContentHeader } from './ContentHeader/hooks/contentHeader'

import { Pages, constants } from 'assets'
import { replacePathParams } from 'assets/history'

type Props = {
  //
}
export const HeaderSpContents: React.FC<Props> = React.memo(({}) => {
  const classes = useStyles()
  const { teamId } = useParams<{ teamId: string }>()
  const { currentTeam, currentTeamMember } = React.useContext(HooksContext)
  const { loaded: loadedTeamBuilding } = useTeamBuilding(teamId)
  const { handleTeamAnketStart } = useHandleTeamAnketStart(currentTeamMember, currentTeam?.id)
  const { toWaiting, toolsStates } = useContentHeader({ teamId })
  const { handleStart: handleStartIceBreak } = useStartIceBreak({ teamId, currentTeamMember })
  const { isTB, isGhost, isKarte, isBuilding, isBuildingReview, isIceBreak } = toolsStates

  const handleKarte = () =>
    window.open(replacePathParams(Pages.TeamsToolKarteDiagnose, { teamId: currentTeam?.id }), '_team-karte')

  const handleTeamBuilding = loadedTeamBuilding
    ? () => window.open(replacePathParams(Pages.TeamsToolBuilding, { teamId }), '_team-building')
    : undefined

  if (!currentTeam?.teamTools || currentTeam.teamTools.length === 0) return null

  return (
    <div className={classes.root}>
      {isIceBreak && (
        <div className={classes.content}>
          <NoStyleButton onClick={handleStartIceBreak}>アイスブレイク</NoStyleButton>
        </div>
      )}
      {isKarte && (
        <div className={classes.content}>
          <NoStyleButton onClick={handleKarte}>カルテ</NoStyleButton>
        </div>
      )}
      {isBuilding && (
        <div className={classes.content}>
          <NoStyleButton onClick={handleTeamBuilding}>キックオフ</NoStyleButton>
        </div>
      )}
      {isGhost && (
        <div className={classes.content}>
          <NoStyleButton onClick={toWaiting}>オバケ</NoStyleButton>
        </div>
      )}

      {isBuildingReview && (
        <div className={classes.content}>
          <ReflectionButton isSp teamId={teamId as string} isMdUp={false} />
        </div>
      )}

      {isTB && (
        <div className={classes.content}>
          <NoStyleButton onClick={handleTeamAnketStart}>TBチェック</NoStyleButton>
        </div>
      )}
    </div>
  )
})

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderTop: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    content: {
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: '#0000000a',
        },
      },

      '& button': {
        width: '100%',
        height: 44,
        padding: '0 16px 0 40px',
        color: constants.TEXT_GRAY_DARK,
        fontSize: 12,
        textAlign: 'left',
      },
    },
  }),
  { name: 'HeaderSpContents' }
)
