import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames'

import { WidthControlWrapper } from 'components/WidthControlWrapper'
import { Ghost, NoSignInGhostWithTotal } from 'utils/generated'

import { BasicButton } from './BasicButton'
import { GhostBalloon } from './GhostBalloon'

import { constants, Pages } from 'assets'

type Props = {
  result: NoSignInGhostWithTotal | null | undefined
  findGhostById: (id: number) => Ghost | undefined
}

export const SimilarTypeGhosts: React.FC<Props> = ({ result, findGhostById }) => {
  const classes = useStyles()

  const ghostCount = React.useMemo(() => result?.own?.resultGhostIdList?.length, [result])

  const totalTopGhosts = React.useMemo(() => {
    if (result?.totalTopGhostId) {
      return [
        { ghost: findGhostById(result.totalTopGhostId.industry), text: '似てる業種', label: 'industry' },
        { ghost: findGhostById(result.totalTopGhostId.position), text: '似てる立場', label: 'position' },
        { ghost: findGhostById(result.totalTopGhostId.person), text: '似てる人', label: 'person' },
      ]
    } else {
      return null
    }
  }, [result, findGhostById])

  const handleToStart = () => {
    window.open(Pages.Obakesagashi, '_blank', 'noopener,noreferrer')
  }

  return (
    <section className={classes.section}>
      {ghostCount && ghostCount >= 4 ? (
        <GhostBalloon className={classes.balloonContainer}>
          <p className={classes.balloonMsg}>
            もっと多くのオバケについて理解・対話したい場合は、
            <br />
            正式版Cocolaboオバケをご利用ください
          </p>
        </GhostBalloon>
      ) : null}

      <WidthControlWrapper>
        <h2 className={classNames(classes.title, { topShort: !(ghostCount && ghostCount >= 4) })}>
          あなたに似たタイプのみんなが、
          <br />
          よく見つけているオバケはこちら！
        </h2>
        <p className={classes.titleSub}>
          あなたがはたらく職場や、いま置かれている立場など、それぞれの場所やタイミング、世代によって、見えてくるオバケがまったく違うこともがあります。同じ職場・同じチームでも、あなたと全然違うオバケが見えている人もいます。それぞれからの視点の違いを理解することも、職場のオバケたちと上手に付きあっていく方法の１つです。
        </p>

        {totalTopGhosts?.map((ghostItem) => (
          <div key={ghostItem.text} className={classes.topGhostBox}>
            <div className={classes.topGhostImgBox}>
              <div className={classNames(classes.number1, ghostItem.label)}>
                あなたに
                <br />
                {ghostItem.text}
                <br />
                <span className="no1">No.1</span>
                <img className="crown" src={`${process.env.PUBLIC_URL}/img/obakesagashi/crown.svg`} alt="王冠" />
              </div>

              {ghostItem?.ghost ? (
                <img
                  className={classes.ghostImg}
                  src={`${process.env.PUBLIC_URL}/assets/ghost/ghost${ghostItem.ghost.id}.svg`}
                  alt={ghostItem.ghost.name}
                />
              ) : null}
            </div>

            <div>
              {ghostItem?.ghost ? (
                <div>
                  <span className={classes.fileNo}>FILE NO.{ghostItem.ghost.fileNo}</span>
                  <p className={classes.ghostName}>{ghostItem.ghost.name}</p>
                  <p className={classes.ghostCon}>{ghostItem.ghost.con}</p>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </WidthControlWrapper>

      <GhostBalloon className={classes.balloonContainer} isReverse>
        <p className={classes.balloonMsg}>
          出現率No.1オバケは、リアルタイムに更新されていきます。
          <br />
          また、オバケ探しにきてみて、みんなの見つけたオバケを見に来てください！
        </p>
      </GhostBalloon>

      <div className={classes.toTopButtonBox}>
        <BasicButton className={classes.toTopButton} backColor="tertiary" onClick={handleToStart}>
          最初からはじめる
          <span>（トップページへ）</span>
        </BasicButton>
      </div>
    </section>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    section: {
      padding: '0 0 217px',
      color: '#000',
      [theme.breakpoints.down('md')]: {
        padding: '0 0 170px',
      },
    },
    balloonContainer: {
      marginTop: 80,
      [theme.breakpoints.down('md')]: {
        marginTop: 40,
      },
    },
    balloonMsg: {
      margin: 0,
      padding: '40px 8px 32px',
      fontFamily: '"MOBO SemiBold"',
      fontSize: 24,
      fontWeight: 600,
      textAlign: 'center',
      lineHeight: '32px',
      [theme.breakpoints.down('md')]: {
        padding: '20px 24px',
        fontSize: 16,
        textAlign: 'left',
        lineHeight: '24px',
        '& br': {
          display: 'none',
        },
      },
    },
    title: {
      margin: '115px 0 0',
      textAlign: 'center',
      fontFamily: '"MOBO SemiBold"',
      fontSize: 40,
      fontWeight: 600,
      lineHeight: '48px',
      [theme.breakpoints.down('md')]: {
        margin: '110px 0 0',
        padding: '0 8px',
        fontSize: 24,
        lineHeight: '32px',
        textAlign: 'center',
        '& br': {
          display: 'none',
        },
      },
      '&.topShort': {
        margin: '107px 0 0',
        [theme.breakpoints.down('md')]: {
          margin: '39px 0 0',
        },
      },
    },
    titleSub: {
      margin: '40px 0',
      fontSize: 16,
      lineHeight: '32px',
      [theme.breakpoints.down('md')]: {
        margin: '24px 0 40px',
      },
    },
    topGhostBox: {
      display: 'flex',
      columnGap: 24,
      marginTop: 24,
      padding: '32px 24px 24px',
      background: '#fff',
      borderRadius: 16,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        rowGap: 24,
        alignItems: 'center',
        marginTop: 16,
        padding: '24px 16px',
        boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
      },
    },
    topGhostImgBox: {
      display: 'flex',
      columnGap: 24,
      [theme.breakpoints.down('md')]: {
        columnGap: 16,
      },
    },
    number1: {
      position: 'relative',
      flexShrink: 0,
      width: 147,
      height: 147,
      paddingTop: 32,
      borderRadius: '50%',
      color: '#fff',
      fontSize: 16,
      fontWeight: 700,
      textAlign: 'center',
      lineHeight: '20px',
      letterSpacing: 1.6,
      '&.industry': {
        background: '#E07682',
      },
      '&.position': {
        background: '#7BB7C4',
      },
      '&.person': {
        background: '#98AC3E',
      },
      '& .no1': {
        display: 'block',
        marginTop: 16,
        textAlign: 'center',
        fontFamily: "'din-2014', sans-serif",
        fontSize: 40,
        fontWeight: 400,
        letterSpacing: 0.8,
      },
      '& .crown': {
        position: 'absolute',
        top: -10,
        left: 0,
        width: 43,
      },
    },
    ghostImg: {
      width: 147,
      border: `1px solid ${constants.COLOR_TEAMBUILDING_NEUTRAL_200}`,
    },
    fileNo: {
      color: constants.COLOR_GRAY_DARK,
      fontFamily: "'din-2014', sans-serif",
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '16px',
    },
    ghostName: {
      margin: 0,
      color: constants.COLOR_MAIN_NEW,
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '22px',
    },
    ghostCon: {
      margin: '16px 0 0',
      color: constants.TEXT_GRAY_DARK,
      fontSize: 16,
      lineHeight: '24px',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        lineHeight: '22px',
      },
    },
    toTopButtonBox: {
      display: 'grid',
      placeItems: 'center',
      margin: '80px 24px 0',
      [theme.breakpoints.down('md')]: {
        margin: '110px 16px 0',
      },
    },
    toTopButton: {
      fontSize: 24,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: 350,
        height: 100,
      },
      '& span': {
        display: 'block',
        fontSize: 16,
        lineHeight: '20px',
      },
    },
  }),
  { name: 'SimilarTypeGhosts' }
)
