import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { constants } from 'assets'

type Props = {
  tendencyId?: string
}

export const Hero: React.FC<Props> = ({ tendencyId }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.heroImgContainer}>
        <img
          className={classes.hero}
          src={`${process.env.PUBLIC_URL}/img/hataraku/tendency-${tendencyId}.png`}
          alt="あなたの傾向"
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 50,
      padding: '44px 0',
      backgroundColor: constants.COLOR_BLUE_LIGHT,
      [theme.breakpoints.down('md')]: {
        marginBottom: 24,
        padding: '24px 16px',
      },
    },

    heroImgContainer: {
      maxWidth: 880,
    },

    hero: {
      width: '100%',
      borderRadius: '40px',
      boxShadow: '7px 7px 9px 0px rgba(0, 0, 0, 0.25)',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'top',
      },
    },
  }),
  { name: 'Hero' }
)
