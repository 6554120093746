import * as React from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { WidthControlWrapper } from './WidthControlWrapper'

import { constants } from 'assets'

type StyleProps = {
  isGhost?: boolean
}

export const WorkplaceLab: React.FC<StyleProps> = ({ isGhost }) => {
  const classes = useStyles({ isGhost })

  return (
    <WidthControlWrapper className={classes.root} containerWidth={isGhost ? 1000 : 1226}>
      <div className={classes.container}>
        <div className={classes.content}>
          <img
            className={classes.labImg}
            src={`${process.env.PUBLIC_URL}/img/hataraku/work-place-lab.png`}
            alt="推せる職場ラボ"
          />

          <div>
            <div className={classes.logo}>
              <img src={`${process.env.PUBLIC_URL}/img/hataraku/work-place-lab-logo.svg`} alt="推せる職場ラボのロゴ" />
              <span>について</span>
            </div>
            <p className={classes.subTitle}>
              「働きがい」✕「働きやすさ」＝<span className="main_color">「推せる職場」</span>
            </p>

            <p className={classes.message}>
              {isGhost ? (
                <>
                  私たちNEWONEは、社員それぞれの仕事に対する価値観を尊重しながら「働きがい」と「働きやすさ」を両立させていくことが、これからの企業運営や職場におけるチーム運営にはかかせない要素になっていくと考えています。
                  <br />
                  この「職場のオバケ探し」で見つけた職場のオバケたちを職場やチームの全員でコミュニケーションを取りながら退治していくことで、あなたが「働きがい」や「働きやすさ」を感じることができる職場へと変えていくための1つのきっかけとして活用することができます。
                  <br />
                  推せる職場ラボWebサイトでは「職場のオバケ探し」以外にも、これまでの職場をあなたにとって働きがいがあって働きやすい「推せる職場」に変えることができるソリューションなどの情報をご紹介しています。
                </>
              ) : (
                <>
                  社員それぞれの仕事に対する価値観を尊重しながら「働きがい」と「働きやすさ」を両立させていくことが、これからの企業運営や、職場におけるチーム運営にはかかせないと考えています。
                  <br />
                  この「はたらく価値観診断」でわかる、あなたが持っている働くことへの価値観を知り、価値観に合うチームで働いたり、価値観を理解してくれる職場に変えていくことも、あなたが「働きがい」や「働きやすさ」を感じることができるようになる、ひとつの大きな要素になります。
                  <br />
                  私たちNEWONEは、いつもの職場をあなたにとって働きがいがあって働きやすい「推せる職場」に変えることができるソリューションや情報を、推せる職場ラボ
                  Webサイトでご紹介しています。
                </>
              )}
            </p>
            <div className={classes.links}>
              <a className="text" href="https://oserushokuba.jp" target="_blank" rel="noopener noreferrer">
                https://oserushokuba.jp
              </a>
              <div className={classes.sns}>
                <a className="icon" href="https://x.com/oserushokuba" target="_blank" rel="noopener noreferrer">
                  <img src={`${process.env.PUBLIC_URL}/img/hataraku/icon-x.svg`} alt="X" />
                </a>
                <a
                  className="icon"
                  href="https://www.instagram.com/oserushokuba"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={`${process.env.PUBLIC_URL}/img/hataraku/icon-instagram.svg`} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WidthControlWrapper>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    root: ({ isGhost }) => ({
      marginTop: isGhost ? 78 : 139,
      [theme.breakpoints.down('md')]: {
        marginTop: isGhost ? 40 : 110,
      },
    }),
    container: ({ isGhost }) => ({
      padding: isGhost ? '50px 40px' : '113px 119px 83px',
      background: '#fff',
      border: '4px solid #00AEBB',
      // 四隅に三角形を配置。
      backgroundImage: `linear-gradient(45deg, #00AEBB 22px, transparent 0),
        linear-gradient(-45deg, #00AEBB 22px, transparent 0),
        linear-gradient(135deg, #00AEBB 22px, transparent 0),
        linear-gradient(-135deg, #00AEBB 22px, transparent 0)`,
      [theme.breakpoints.down(1150)]: {
        padding: '113px 32px 83px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '40px 16px',
        backgroundImage: `linear-gradient(45deg, #00AEBB 16px, transparent 0),
          linear-gradient(-45deg, #00AEBB 16px, transparent 0),
          linear-gradient(135deg, #00AEBB 16px, transparent 0),
          linear-gradient(-135deg, #00AEBB 16px, transparent 0)`,
      },
    }),
    content: {
      display: 'flex',
      columnGap: 36,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 24,
      },
    },
    labImg: {
      width: 405,
      height: 297,
      [theme.breakpoints.down(1150)]: {
        width: 350,
        height: 257,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: 300,
        height: 'auto',
      },
    },
    logo: {
      display: 'flex',
      alignItems: 'flex-end',
      columnGap: 12,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      '& img': {
        width: 251,
        [theme.breakpoints.down('sm')]: {
          width: 160,
        },
      },
      '& span': {
        fontSize: 32,
        fontWeight: 'bold',
        lineHeight: 1.4,
        [theme.breakpoints.down('sm')]: {
          fontSize: 20,
        },
      },
    },
    subTitle: {
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 8,
      margin: '18px 0 0',
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 1.4,
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '20px 0 0',
        justifyContent: 'center',
      },
      '& .main_color': {
        color: constants.COLOR_BLUE_DARK2,
      },
    },
    message: {
      margin: '18px 0 0',
      fontSize: 16,
      lineHeight: 1.7,
      letterSpacing: 1.6,
    },
    links: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: 24,
      marginTop: 18,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        rowGap: 12,
      },
      '& .text': {
        color: constants.COLOR_BLUE_DARK2,
        fontSize: 16,
        letterSpacing: 1.6,
        textDecoration: 'none',
        '@media (hover: hover)': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    sns: {
      display: 'flex',
      columnGap: 16,
      '& .icon': {
        display: 'grid',
        placeItems: 'center',
        background: constants.COLOR_BLUE_DARK2,
        width: 40,
        height: 40,
        borderRadius: '50%',
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 0.8,
          },
        },
        '& img': {
          width: 23,
        },
      },
    },
  }),
  { name: 'WorkplaceLab' }
)
