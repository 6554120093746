import * as React from 'react'

import { Typography } from '@mui/material'
import { Theme } from '@mui/material/styles';
import { StyleRules, WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import * as constants from '../../../assets/constants'

type Props = WithStyles<typeof useStyles> & {
  topTitle: string
  mainTitle: string
  mainTitleLine2?: string
  caption?: string
  setMarginBottom?: number
}

const SectionTitle: React.FC<Props> = (props: Props) => {
  const { classes } = props
  return (
    <>
      <div className={classes.root} style={{ marginBottom: props.setMarginBottom }}>
        <div className={classes.topTitle}>
          <div className={classes.horizontalBar}></div>
          {props.topTitle}
        </div>
        <Typography className={classes.mainTitle}>
          {props.mainTitle}
          {props.mainTitleLine2 && (
            <>
              <br />
              {props.mainTitleLine2}
            </>
          )}
        </Typography>
        {props.caption && <div className={classes.caption}>{props.caption}</div>}
      </div>
    </>
  )
}

const useStyles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: 50,
      },
    },
    topTitle: {
      display: 'flex',
      color: constants.COLOR_MAIN,
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        letterSpacing: 1.5,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        letterSpacing: 2,
      },
      fontWeight: 'bold',
      alignItems: 'center',
    },
    mainTitle: {
      color: '#1F1818',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        fontSize: '20px',
        lineHeight: 1.5,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        marginTop: 14,
        lineHeight: 1.2,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        marginTop: 14,
        lineHeight: 1.2,
      },
    },
    caption: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
      },
      [theme.breakpoints.up('sm')]: {
        marginTop: 19,
      },
      color: '#3B3B3B',
    },
    horizontalBar: {
      marginRight: 7,
      borderTop: '1px solid',
      content: '',
      [theme.breakpoints.down('sm')]: {
        width: '22px',
      },
      [theme.breakpoints.up('sm')]: {
        width: '30px',
      },
    },
  })

export default withStyles(useStyles)(SectionTitle)
