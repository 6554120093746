import * as React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as constGhost from 'pages/ghost/assets/constGhost'
import { GhostContext } from 'pages/ghost/contexts/ghost'
import { Maybe } from 'utils/generated'

import { Font } from '../atoms'

type Props = {
  filenumber: string
  name: string
  ghostFoundedCount?: Maybe<number>
  isShowFoundedCount?: boolean
  ownStyles?: React.CSSProperties
}

export const GhostFileNumberAndTitle: React.FC<Props> = ({
  filenumber,
  name,
  ghostFoundedCount = 0,
  isShowFoundedCount = false,
  ownStyles,
}) => {
  const classes = useStyles()
  const { modal, setModal, directClose } = React.useContext(GhostContext)

  return (
    <div className={classes.root}>
      <div className={classes.modalHeader}>
        <div>
          <Font fontSize={10} ownStyles={{ ...ownStyles }}>
            FILE NO.
            {filenumber}
          </Font>
          <Font fontSize={16} bold ownStyles={{ color: constGhost.COLOR_MAIN_NEW, fontWeight: 'bold', ...ownStyles }}>
            {name}
          </Font>
        </div>
        <CloseIcon
          onClick={() => (directClose ? setModal('') : setModal('list'))}
          className={
            modal === 'detail-no-tab' || modal === 'detail-no-tab-no-select' || modal === 'detail'
              ? classes.closeIconForNotab
              : classes.closeIcon
          }
        />
      </div>
      {isShowFoundedCount && (
        <Font fontSize={14}>
          これまでの発見数
          <span style={{ padding: '0 8px', fontSize: 24, fontWeight: 'bold', color: constGhost.COLOR_MAIN_NEW }}>
            {ghostFoundedCount}
          </span>
          回
        </Font>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: 18,
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    closeIcon: {
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: -10,
        right: -20,
      },
    },
    closeIconForNotab: {
      cursor: 'pointer',
      position: 'absolute',
      top: 10,
      right: 10,
    },
  }),
  { name: 'GhostFileNumberAndTitle' }
)
