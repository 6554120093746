import React, { useState } from 'react'

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import arrow from 'pages/onboarding/assets/arrowTooltip.svg'
import QuestionsIcon from 'pages/onboarding/assets/QuestionIcon.svg'

import * as constants from 'assets/constants'

type Props = {
  children: React.ReactNode
}
type StyleProps = {
  ownStyles?: { [k: string]: string | number | undefined }
  detailStyles?: { [k: string]: string | number | undefined }
  arrowPosition?: number
}

export const Tooltip: React.FC<Props & StyleProps> = ({ children, ownStyles, detailStyles, arrowPosition }) => {
  const classes = useStyles({ ownStyles: ownStyles, detailStyles: detailStyles, arrowPosition: arrowPosition })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleIsOpen = () => {
    setIsOpen(true)
  }
  const handleIsClose = () => {
    setIsOpen(false)
  }

  return (
    <div className={classes.iconWrapper}>
      <img
        onMouseEnter={handleIsOpen}
        onMouseLeave={handleIsClose}
        src={QuestionsIcon}
        className={classes.iconImg}
        alt="questionsIcon"
      />
      {isOpen ? (
        <div className={classes.detail}>
          <span className={classes.arrow}>
            <img src={arrow} alt="arrow" />
          </span>
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    iconWrapper: ({ ownStyles }: StyleProps) => ({
      ...ownStyles,
    }),
    iconImg: {
      display: 'block',
      width: 16,
      height: 16,
      cursor: 'pointer',
    },
    detail: ({ detailStyles }: StyleProps) => ({
      background: constants.COLOR_TOOLTIP_CONTENT,
      borderRadius: 8,
      padding: '17px 16px 19px 16px',
      position: 'absolute',
      top: 55,
      zIndex: 10,
      left: -20,
      boxShadow: '0px 4px 10px -5px rgba(0,0,0,0.5)',
      borderTop: '1px solid #E9E9E9',
      ...detailStyles,
      [theme.breakpoints.up('md')]: {
        minWidth: 530,
      },
    }),
    arrow: ({ arrowPosition }: StyleProps) => ({
      position: 'absolute',
      top: -15,
      left: 118 + (arrowPosition ?? 0),
      [theme.breakpoints.down('md')]: {
        left: 70 + (arrowPosition ?? 0),
      },
    }),
  }),
  { name: 'Tooltip' }
)
